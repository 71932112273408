// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Popup from '../../../Components/_Layout/Popup';
import Spinner from '../../../Components/_BaseUI/Spinner/Spinner';
import LOCALIZATION from '../../../Localization';
import AnalyticsGameArchive from '../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import TeamRefWithImageSelect from '../../../Components/_BaseUI/TeamRefWithImageSelect';
import HorizGrid from '../../../Components/_Layout/HorizGrid';
import AnalyticsSportTypeSelect from '../../../Components/_BaseUI/AnalyticsSportTypeSelect';
import EventsListTable, { DEFAULT_EVENTS } from './EventsListTable';
import CameraStreamPreview, { VideoPlayerCommands } from '../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import { makeAnalyticsVideoImageUrl, makeAnalyticsVideoPlayerUrl } from '../../../Data/Analytics/AnalyticsConfig/AnalyticsConfig';
import { FileType } from '../../../Data/VideoArchive';
import { useAnalyticsConfig } from '../../../Data/Analytics/AnalyticsConfig/hook';
import AnalyticsVideoArchive from '../../../Data/Analytics/AnalyticsVideoArchive/AnalyticsVideoArchive';
import useFetchAllPagesTeamRefs from '../../../Data/Analytics/AnalyticsTeamRef/hook';
import { AnalyticsType } from '../../../Data/Analytics/AnalyticsTypes';
import uuid from '../../../Tools/uuid';
import useMutationGame, { Action, ActionType as GameActionType  } from '../../../Data/Analytics/AnalyticsGameArchive/fetch/useMutation';
import { AnalyticsGameEvent } from '../../../Data/Analytics/AnalyticsGameEvent/AnalyticsGameEvent';
import useGameEventsPagedData from '../../../Data/Analytics/AnalyticsGameEvent/hook';
import VertGrid from '../../../Components/_Layout/VertGrid';
import { ResultStatus } from '../../../Data/_Networking/ReactQuery/template';
import { ScissorsCircleGrayBtn } from '../../../Icons/Icons';
import { Colors } from '../../../Configuration/Styles/Colors';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../Configuration/Styles/Fonts';
import { AnalyticsAnalyticsStatus, analyticsStatusName, isCanEditAnalyticsWithStatus } from '../../../Data/Analytics/AnalyticsStatuses';
import useMutationGameEvents, { ActionType } from '../../../Data/Analytics/AnalyticsGameEvent/fetch/useMutation';
import EditTitleSubtitlePopup, { EditTitleSubtitleFields } from '../../../Components/_PopupControls/EditTitleSubtitlePopup';
import AnalyticsTeamsColorPalettePicker from '../../../Components/_BaseUI/AnalyticsTeamsColorPalettePicker';
import TeamRef from '../../../Data/Analytics/AnalyticsTeamRef';

const SX = {
  helpText: {
    whiteSpace: "pre-line",
    color: Colors.accentGrey,
    fontSize: Sizes.subtitle,
    fontFamily: Fonts.main
  },
  stateText: {
    color: Colors.mainTitle,
    fontSize: Sizes.title,
    fontFamily: Fonts.main
  },
  stateWarningsText: {
    color: Colors.mainRed,
    fontSize: Sizes.subtitle,
    fontFamily: Fonts.main
  }
};

type Props = Readonly<{
  game?: AnalyticsGameArchive;
  gameVideoInfo?: AnalyticsVideoArchive;
  // page: number;
  onPostedSuccess: () => void;
  onClose: () => void;
} & WrappedComponentProps>;

const DEFAULT_VALUE = (record_id: string | undefined): AnalyticsGameArchive => {
  return {
    type: AnalyticsType.Game,
    id : uuid(),
    record_id,
    analytics_status: AnalyticsAnalyticsStatus.NotStarted
  }
};

const AddAnalyticsGamePopup: React.FC<Props> = (props: Props) => {    
  const theme = useTheme();
  const useHorisLayout = useMediaQuery(theme.breakpoints.up('lg'));

  const [renameItem, setRenameItem] = React.useState<AnalyticsGameArchive | undefined>(undefined);

  const [game, setGame] = React.useState<AnalyticsGameArchive>(props.game || DEFAULT_VALUE(props.gameVideoInfo?.id));
  const [events, setEvents] = React.useState<AnalyticsGameEvent[] | undefined>(undefined);

  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);
  const isCreateNewGame = !props.game || props.game.copyId;

  const { data: analyticsConfig } = useAnalyticsConfig({});
  const { allCached: teams, } = useFetchAllPagesTeamRefs();
  const { mutateAsync: mutateGamesConfig } = useMutationGame();

  const [videoPlayerCommands, setVideoPlayerCommands] = React.useState<VideoPlayerCommands | undefined>(undefined);

  // streaming/settings.json load
  const {
    status: fetchEventsStatus,
    data: eventsData,
    handleFlushData: handleFlushEventsData
  } = useGameEventsPagedData({
    parentGameId: game.copyId || game.id // if make copy then first load by old game id and next save with new id
  });
  const { mutateAsync: mutateGameEvents } = useMutationGameEvents();

  React.useEffect(() => {
    if (fetchEventsStatus === ResultStatus.Success) {
      setIsLoaderShowing(false);
      const loadedEvents = eventsData?.data;
      if (!loadedEvents?.length) {
        setEvents(DEFAULT_EVENTS(props.gameVideoInfo));
      }
      else {
        setEvents(loadedEvents);
      }
    }
    else if (fetchEventsStatus === ResultStatus.Loading) {
      setIsLoaderShowing(true);
    }
    else if (fetchEventsStatus === ResultStatus.Error) {
      setIsLoaderShowing(false);
      setEvents(DEFAULT_EVENTS(props.gameVideoInfo));
    }
    // eslint-disable-next-line
  }, [fetchEventsStatus, eventsData?.data]);

  const latestVideoPlayerCommands = React.useRef(videoPlayerCommands);

  function handleKeyDown(event: KeyboardEvent) {
    if (!(event.target instanceof HTMLMediaElement)) {
      if (event.key ===  "ArrowRight" || event.key === "ArrowLeft" || event.code === "Space") {
        const player = latestVideoPlayerCommands.current?.getVjsPlayer()
        player?.player_?.focus()
      }        
    }
  }

  React.useEffect(() => {
    handleFlushEventsData();

    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeamByExternalId = (externalTeamId: string | undefined): TeamRef | undefined => {
    if (externalTeamId) {
      const foundTeam = teams?.data.find((team: TeamRef) => (team.externalTeamId === externalTeamId))
      return foundTeam;
    }
  };
  const getTeamNameByExternalId = (externalTeamId: string | undefined, teamIndex: 1 | 2): string => {
    const name = getTeamByExternalId(externalTeamId)?.name;
    if (name) {
      return name;
    }
    return props.intl.formatMessage({ id: (teamIndex === 1) ? LOCALIZATION.event_home_team : LOCALIZATION.event_guest_team })
  };
  
  const getTeamShortNameByExternalId = (externalTeamId: string | undefined, teamIndex: 1 | 2): string => {
    const name = getTeamByExternalId(externalTeamId)?.shortName;
    if (name) {
      return name;
    }
    return props.intl.formatMessage({ id: (teamIndex === 1) ? LOCALIZATION.event_home_team : LOCALIZATION.event_guest_team })
  };
  const team1Name = getTeamNameByExternalId(game?.team1, 1);
  const team1ShortName = getTeamShortNameByExternalId(game?.team1, 1);
  const team1Color = game.team1_color || getTeamByExternalId(game?.team1)?.color
  const team2Name = getTeamNameByExternalId(game?.team2, 2);
  const team2ShortName = getTeamShortNameByExternalId(game?.team2, 2);
  const team2Color = game.team2_color || getTeamByExternalId(game?.team2)?.color

  const isApplyHidden = !(game && isCanEditAnalyticsWithStatus(game.analytics_status));
  const isApplyDisabled = !(game && game.sport_type?.length && game.team1?.length && game.team2?.length && game.team1 !== game.team2 && events?.length && isCanEditAnalyticsWithStatus(game.analytics_status));
  const handleSaveGame = async () => {
    if (!isApplyDisabled) {
      setIsLoaderShowing(true);

      try {
        await mutateGamesConfig(
          {
            type: isCreateNewGame ? GameActionType.Add : GameActionType.Patch,
            archive: {
              id: game.id,
              game
            }
          }, {
            onSuccess: async (newGameArchive: Action | Error) => {
              if (newGameArchive instanceof Error) {
                alert(newGameArchive);
                setIsLoaderShowing(false);
              }
              else {
                try {
                  await mutateGameEvents(
                    {
                      type: ActionType.Patch,
                      archives: {
                        id: newGameArchive.archive?.game?.id || game.id,
                        events
                      }
                    }, {
                      onSuccess: props.onPostedSuccess
                    }
                  )
                } catch (error) {
                  alert(error);
                  setIsLoaderShowing(false);
                }
              }
            },
          },
        );
      } catch (error) {
        setIsLoaderShowing(false);
        alert(error);
      }
    }
  };

  const renderTable = () => {
    return (
      <EventsListTable
        sx = {{
          maxHeight: "100%" ,
        }}
        sportType={ game.sport_type }
        key={ 'Title' }
        team1Name={ team1Name }
        team2Name={ team2Name }
        team1ShortName={ team1ShortName }
        team2ShortName={ team2ShortName }
        events={ events }
        stickyHeader = {true}
        onEventsUpdate={ (events) => {
          setEvents(events);
        } }
        videoPlayerCommands={ videoPlayerCommands }
      />
    )
  }

  const renderCameraPreview = () => {
    return (
      <CameraStreamPreview
        previewImageUrl={ makeAnalyticsVideoImageUrl(analyticsConfig, props.gameVideoInfo?.preview) }
        videoUrl={ makeAnalyticsVideoPlayerUrl(analyticsConfig, props.gameVideoInfo?.video) }
        fileType={ FileType.Panorama }
        bigPreview={true}
        preventControlsHide={ true }
        onPlayerCommandsUpdate={(commands: VideoPlayerCommands | undefined)=> {
          setVideoPlayerCommands(() => {
            latestVideoPlayerCommands.current = commands
            return commands
          })
        }}
      />
    )
  }

  const renderScissorsHelp = () => {
    return (
      <HorizGrid spacing={ 1 }>
        <ScissorsCircleGrayBtn/>
        <Box sx={ SX.helpText }>
          { props.intl.formatMessage({ id: LOCALIZATION.game_edit_scissors_help }) }
        </Box>
      </HorizGrid>
    )
  }

  const renderGameEdit = () => {
    return (
      <VertGrid
        spacing={ 2 }
        sx={{
          height: "100%"
        }}
      >
        {
          (isApplyHidden) &&
          <Box>
            <Typography sx={ SX.stateText } display="inline">            
              { props.intl.formatMessage({ id: LOCALIZATION.analytics_game_in_readonly_state }, {"state": analyticsStatusName(game.analytics_status, game.analytics_progress)}) } 
            </Typography>
            <Typography sx={ SX.stateWarningsText } display="inline">            
              { ` (${props.intl.formatMessage({ id: LOCALIZATION.analytics_game_in_readonly })})`} 
            </Typography>
          </Box>
        }
        <HorizGrid spacing={ 2 }>
          <AnalyticsSportTypeSelect 
            label={ props.intl.formatMessage({ id: LOCALIZATION.event_sport_type }) } 
            selectedAnalyticsSportType = { game.sport_type }
            onSelect={(sportType) => {
              setGame({
                ...game,
                sport_type: sportType || undefined,
              })
            }} 
          />
          <HorizGrid spacing={ 0.5 }>
            {(game.team1) &&
              <AnalyticsTeamsColorPalettePicker 
                mainTeamName={ team1ShortName }
                mainTeamColor={ team1Color }
                otherTeamName={ team2ShortName }
                otherTeamColor={ team2Color }
                onMainTeamColorChange={(newColor) => {
                  setGame({
                    ...game,
                    team1_color: newColor,
                  })
                }}
              />
            }
            <TeamRefWithImageSelect 
              label={ props.intl.formatMessage({ id: LOCALIZATION.event_home_team }) }
              selectedExternalTeamID={ game.team1 }
              onSelect={(teamId) => {
                setGame({
                  ...game,
                  team1: teamId || undefined,
                  team1_color: getTeamByExternalId(teamId || undefined)?.color, // reset team color to deafult when change teamId
                })
              }} 
            />
          </HorizGrid>
          <HorizGrid spacing={ 0.5 }>
            {(game.team2) &&
              <AnalyticsTeamsColorPalettePicker 
                mainTeamName={ team2ShortName }
                mainTeamColor={ team2Color }
                otherTeamName={ team1ShortName }
                otherTeamColor={ team1Color }
                onMainTeamColorChange={(newColor) => {
                  setGame({
                    ...game,
                    team2_color: newColor,
                  })
                }}
              />
            }
            <TeamRefWithImageSelect 
              label={ props.intl.formatMessage({ id: LOCALIZATION.event_guest_team }) }
              selectedExternalTeamID={ game.team2 }
              onSelect={(teamId) => {
                setGame({
                  ...game,
                  team2: teamId || undefined,
                  team2_color: getTeamByExternalId(teamId || undefined)?.color, // reset team color to deafult when change teamId
                })
              }} 
            />
          </HorizGrid>
        </HorizGrid>


        {(useHorisLayout) ?
            <Box
              sx={{
                position: "relative",
                height: "100%",
              }}
            >
              <Grid container direction="row" spacing={ 2 }
                // alignItems="flex-start"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <Grid item  xs={ 6 } xl={ 5 }
                  sx={{
                    maxHeight: "100%"
                  }}
                >
                  { renderTable() }
                </Grid>
                <Grid item  xs={ 6 } xl={ 7 }
                  sx={{
                    maxHeight: "100%",
                    marginTop: 2,
                  }}
                >
                <VertGrid spacing={2}>
                  { renderCameraPreview() }
                  { renderScissorsHelp() }
                </VertGrid>
                </Grid>
              </Grid>
          </Box>
            :
          <VertGrid>
            { renderCameraPreview() }
            { renderTable() }
          </VertGrid>
        }
      </VertGrid>
    )
  }

  const handleTitleRename = () => {
    setRenameItem(game);
  };
  const handleCloseTitleRenamePopup = () => {
    setRenameItem(undefined);
  };
  const applyTitleRename = async (editedItem: EditTitleSubtitleFields) => {
    setGame({
      ...game,
      title: editedItem.title,
      subtitle: editedItem.subtitle,
    })
    handleCloseTitleRenamePopup();
  };

  let popupTitle = props.intl.formatMessage({ id: LOCALIZATION.game_for_analytics })
  if (game.title?.length) {
    popupTitle += `: ${game.title}`
  }

  return (
    <Popup
      isFullScreen={ true}
      headerTitle={ popupTitle }
      applyTitle={ props.intl.formatMessage({ id: LOCALIZATION.save }) }
      closeTitle={ props.intl.formatMessage({ id: (isApplyHidden) ? LOCALIZATION.close_btn : LOCALIZATION.cancel }) }
      isApplyDisabled={ (isApplyDisabled || isLoaderShowing) }
      isCloseDisabled={ isLoaderShowing }
      onApply={ (isApplyHidden) ? undefined : handleSaveGame }
      onClose={ props.onClose }
      disableScroll={true}
      onEditTitleClick={ (game.title === undefined || isApplyHidden) ? undefined : handleTitleRename }
    >
      {
        (isLoaderShowing) ?
        <>
        <br /><br /><Spinner /><br /><br />
        </>
        : 
        renderGameEdit()
      }
      {(renameItem) &&
          <EditTitleSubtitlePopup
            popupTitle={ props.intl.formatMessage({ id: LOCALIZATION.rename }) }
            item={ renameItem }
            onSubmit={ applyTitleRename }
            onClose={ handleCloseTitleRenamePopup }
          />
      }
    </Popup>
  );
};


export default injectIntl(AddAnalyticsGamePopup);
