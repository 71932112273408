// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { AccordionSummary } from '@mui/material';

import { Colors } from '../../../Configuration/Styles/Colors';
import { Sizes } from '../../../Configuration/Styles/Sizes';


type Props = Readonly<{
  expandIcon?: React.ReactNode;
  children?: React.ReactNode;
}>;


const MenuUISectonContent: React.FC<Props> = (props: Props) => (
  <AccordionSummary
    expandIcon={ props.expandIcon }
    sx={ {
      width: '100%',
      margin: 0,
      marginBottom: 0,
      minHeight: 42,
      maxHeight: 42,
      backgroundColor: Colors.menuSectionBackground,
      color: Colors.menuSectonText,
      fontWeight: Sizes.boldWeight,
      '&:hover': {
        background: Colors.mainGrey,
      },
      paddingLeft: '16px',
      '&.Mui-expanded': {
        minHeight: 42,
        maxHeight: 42,
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        marginRight: -1,
      },
      // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      //   // transform: 'rotate(90deg)',
      //   marginRight: -1,
      // },
    } }
  >
    { props.children }
  </AccordionSummary>
);


export default MenuUISectonContent;
