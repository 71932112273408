import styled from "@emotion/styled";
import { useMemo, ReactNode, Fragment } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { useCamerasStatistic } from "../../../Data/Camera/AvaibleCamerasStatistic/hook";
import LOCALIZATION from "../../../Localization";

type Props = Readonly<{
} & WrappedComponentProps>;

const StatusTextContainer = styled.span`
  white-space:nowrap;
  * {
    white-space:nowrap;
  }
`

const BoldText = styled.strong`
  color: #334D5E;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const Critical = styled.span`
  color: #EB5757;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const Warning = styled.span`
  color: #F2994A;;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`


const CameraListStatusMessage: React.FC<Props> = (props: Props) => {
    const camerasStatisticQuery = useCamerasStatistic()

    const camerasOverroundInfo = camerasStatisticQuery.data;
    const statusMessage = useMemo(() => {
        const message: ReactNode[] = [];
        const totalText = `${props.intl.formatMessage({ id: LOCALIZATION.total })}: ${camerasOverroundInfo?.totalCameras}`;
        message.push(totalText)
      const OffText = camerasOverroundInfo?.offlineCameras ? `${props.intl.formatMessage({ id: LOCALIZATION.cameras_list_statuses_off })}: ${camerasOverroundInfo?.offlineCameras}` : undefined;
        const ErrorText = camerasOverroundInfo?.errorCameras ? <Warning>{`${props.intl.formatMessage({ id: LOCALIZATION.cameras_list_statuses_error })}: ${camerasOverroundInfo?.errorCameras}`}</Warning> : undefined
        const WarningText = camerasOverroundInfo?.onlineWarningCameras ? <Warning>{`${props.intl.formatMessage({ id: LOCALIZATION.cameras_list_statuses_warning })}: ${camerasOverroundInfo?.onlineWarningCameras}`}</Warning> : undefined
        const CriticalText = camerasOverroundInfo?.onlineCriticalCameras ? <Critical>{`${props.intl.formatMessage({ id: LOCALIZATION.cameras_list_statuses_critical })}: ${camerasOverroundInfo?.onlineCriticalCameras}`}</Critical> : undefined
      const arrOfAditinalMesgs = [OffText, WarningText, ErrorText, CriticalText].filter(Boolean);
      if (arrOfAditinalMesgs.length) {
        message.push(' ( ');
        message.push(arrOfAditinalMesgs
          .map((element, index, array) => <Fragment key={index}>
            {element}
            {index !== array.length - 1 && ', '}
          </Fragment>));
        message.push(' )')
        }
        return message;
    }, [camerasOverroundInfo?.errorCameras, camerasOverroundInfo?.offlineCameras, camerasOverroundInfo?.onlineCriticalCameras, camerasOverroundInfo?.onlineWarningCameras, camerasOverroundInfo?.totalCameras, props.intl])
    return <StatusTextContainer>
        <BoldText>
            {statusMessage}
        </BoldText>
    </StatusTextContainer>
}
export default injectIntl(CameraListStatusMessage);
