// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import fetchJSONData, { ErrorResponse } from '../../../_Networking/ReactQuery/fetch';
import { JAVA_BACKEND_URL } from '../../../../constants';
import { queryCache, queryClient } from '../../../../AppProviders';
import { AnalyticsConfig, AnalyticsConfigConfig } from '../AnalyticsConfig';
import { getCachedDataAllPagedItems } from '../../../_Networking/ReactQuery/template';

type FetchProps = Readonly<{
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'analytics-config';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
]);

const getAllCached = () => (
  getCachedDataAllPagedItems<AnalyticsConfig>(getKeyWithoutPage())
);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<AnalyticsConfig>(getKey(props))
);

const flushCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};

const patchConfig = async (config: AnalyticsConfigConfig): Promise<AnalyticsConfig | Error> => {
  try {
    const data = await fetchJSONData<AnalyticsConfig | ErrorResponse>(
        `${JAVA_BACKEND_URL}/settings/analytics`,
        {
          method: 'PATCH',
          body: JSON.stringify({ ...config }),
        }
    );

    const configData = data as AnalyticsConfig
    const errorData = data as ErrorResponse
    if (configData?.urls) {
      return configData
    } else {
      throw new Error(errorData?.errorType || 'Error When Trying Get Analytics Config');
    }
  } catch (error) {
    throw error;
  }
};

const fetchData = async (props: FetchProps): Promise<AnalyticsConfig> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  return await fetchJSONData<AnalyticsConfig>(
    `${JAVA_BACKEND_URL}/settings/analytics`,
    {
      method: 'GET',
    }
  );
};


type UseFetchProps = Readonly<{
  enableToFetch: boolean;
} & FetchProps>;


const useFetch = (props: UseFetchProps): UseQueryResult<AnalyticsConfig> => {
  const { enableToFetch = true } = props;

  const queryKey = getKey({});

  return useQuery<AnalyticsConfig>({
    queryKey,
    queryFn: () => fetchData({}),
    enabled: !!enableToFetch,
    cacheTime: 60 * (60 * 1000), // 60 mins 
  });
};


export {
  getKey,
  useFetch,
  getCached,
  flushCache,
  getAllCached,
  patchConfig,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
