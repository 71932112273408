// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>


import React from 'react'
import ConfigurableMetricsChartView from '../../../../Components/_ChartsAndBars/ConfigurableMetricsChartView'
import { HardwarePiCardDefaultMetricsSource } from '../../../../Data/Camera/Metrics/Sources/Collection'

export default function PiChartView() {
    const defaultSource = HardwarePiCardDefaultMetricsSource
    return <ConfigurableMetricsChartView defaultSource={defaultSource}/>
}
