// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { StorageService } from '../../../../Data/Common/StorageService'
import { MetricOutputTimeNow, MetricPeriodHour, MetricsChartSourceInterface, MetricsDataProviderUniqueKey } from '../../../../Data/Camera/Metrics/Types'
import { MetricsChartConfigurationDataCollectionInterface, MetricsChartConfigurationDataInterface, MetricsChartConfigurationFormInterface } from './Types'

interface MetricsChartConfigurationStorageServiceInterface {
    configurationData: MetricsChartConfigurationDataCollectionInterface
}

class MetricsChartConfigurationStorageService extends StorageService<MetricsChartConfigurationDataCollectionInterface> implements MetricsChartConfigurationStorageServiceInterface {
    key: string = 'MetricsChartConfigurationStorage.Data'

    get configurationData(): MetricsChartConfigurationDataCollectionInterface {
        return this.data ?? {}
    }

    set configurationData(newData: MetricsChartConfigurationDataCollectionInterface) {
        this.data = newData
    }

    static reset(): void {
        new MetricsChartConfigurationStorageService().reset()
    }
}

export class MetricsChartConfigurationHelper {
    private static storage = new MetricsChartConfigurationStorageService()

    static getInitialConfigurableForm(source: MetricsChartSourceInterface): MetricsChartConfigurationFormInterface {
        let configuration = this.storage.configurationData[source.uniqueKey]
        if (!configuration) {
            configuration = {
                selected: source.defaultSelected,
                outputPeriod: MetricPeriodHour,
                outputTime: MetricOutputTimeNow
            }
        }
        const initialForm: MetricsChartConfigurationFormInterface = {
            ...source,
            ...configuration
        }
        return initialForm
    }

    static setMetricsChartConfiguration(uniqueKey: MetricsDataProviderUniqueKey, configuration: MetricsChartConfigurationDataInterface) {
        const data = this.storage.configurationData
        data[uniqueKey] = configuration

        this.storage.configurationData = data
    }

    static reset() {
        this.storage.reset()
    }
}
