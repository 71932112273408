// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import AuthorisationApiService from '../../../Auth/Networking'
import SessionStorageService from '../../../Auth/Session/Storage'
import { SessionInterface } from '../../../Auth/Types'
import AuthorisationManager from '../../../Auth/AuthorisationManager'
import { ApiLocalErrorBuilder } from '../Errors/Builders'
import ApiError from '../Errors/Types'
import { intl } from '../../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../../Localization';

interface ApiSessionHandlerProtocol {
    // Рефрешер сессии. Критическая секция.
    refreshSession(succeeded: () => void, failed: (apiError: ApiError) => void): void

    // Вызовится, если не удалось обновить сессиию и нужно выйти.
    // Либо, если от сервера сразу пришло 401 без предложения обновления сессии
    // Обязательный параметр - причина логаута в виде ошибки
    logout(reasonError: Error): void
}

export default class ApiSessionHandler implements ApiSessionHandlerProtocol {
    private authorisationApiService = new AuthorisationApiService()
    private sessionStorageService = new SessionStorageService()
    private static isSessionRefreshInProgress: boolean = false
    private static lastSessionRefreshTimestamp?: number
    private static sessionRefreshError?: ApiError
    private static isLoggingOut: boolean = false
    private static loggedOutAction: (error: Error) => void;
    private translate = intl().formatMessage;

    // Используйте эту функцию, чтобы поймать событие, когда пользователя выкинуло из авторизации
    static onHandleLogout(action: (error: Error) => void): void {
        ApiSessionHandler.loggedOutAction = action
    }

    // MARK: Session Refresh
    refreshSession(succeeded: () => void, failed: (apiError: ApiError) => void): void {
        this.refreshSessionPromise().then(succeeded).catch(failed)
    }

    private async sessionRefreshingCompleted() {
        return new Promise<void>((resolve, reject) => {
            if (ApiSessionHandler.isSessionRefreshInProgress) {
                setTimeout(() => {
                    this.sessionRefreshingCompleted().then(resolve)
                }, 500)
            } else {
                resolve()
            }
        })
    }

    private async waitSessionRefreshing(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.sessionRefreshingCompleted().then(() => {
                if (ApiSessionHandler.sessionRefreshError) {
                    // console.log('Session > refresh failed. Continue')
                    reject(ApiSessionHandler.sessionRefreshError)
                } else {
                    // console.log('Session > refresh completed. Continue.')
                    resolve()
                }
            })
        })
    }

    private async refreshSessionPromise(): Promise<void> {
        if (ApiSessionHandler.isSessionRefreshInProgress) {
            // console.log('Session > refresh in progress. Waiting')
            return this.waitSessionRefreshing()
        } else {
            // console.log('Session > going to refresh session. This should be single')

            ApiSessionHandler.isSessionRefreshInProgress = true
            ApiSessionHandler.sessionRefreshError = undefined

            return new Promise((resolve, reject) => {
                const session = this.sessionStorageService.session
                if (session) {
                    this.authorisationApiService.refreshSession(session, (newSession: SessionInterface) => {
                        // console.log('Session > refreshed. This should be single')
                        this.sessionStorageService.session = newSession
                        ApiSessionHandler.sessionRefreshError = undefined
                        ApiSessionHandler.isSessionRefreshInProgress = false
                        if (ApiSessionHandler.lastSessionRefreshTimestamp) {
                            const now = Date.now();
                            var timeDiff = now - ApiSessionHandler.lastSessionRefreshTimestamp; //in ms
                            if (timeDiff < 1000) {// if from prev success refresh passed less 1 sec then cancel this request
                                ApiSessionHandler.lastSessionRefreshTimestamp = undefined;
                                reject({
                                    error: "Rate limit",
                                    statusCode: 500,
                                    scope: 'refresh_session'
                                });
                                return;
                            }
                        }
                        ApiSessionHandler.lastSessionRefreshTimestamp = Date.now();
                        resolve()
                    }, (apiError: ApiError) => {
                        // console.log('Session > failed to refresh. This should be single')
                        ApiSessionHandler.sessionRefreshError = apiError
                        ApiSessionHandler.isSessionRefreshInProgress = false
                        ApiSessionHandler.lastSessionRefreshTimestamp = undefined;
                        reject(apiError)
                    })
                } else {
                    // console.log('Session > can\'t find any active session. Fail.')

                    const error = new ApiLocalErrorBuilder(this.translate({ id: LOCALIZATION.session_missing_error }), 'refresh_session').build()
                    ApiSessionHandler.sessionRefreshError = error
                    ApiSessionHandler.isSessionRefreshInProgress = false
                    reject(error)
                }
            })
        }
    }

    // MARK: Logout
    logout(reasonError: Error): void {
        this.logoutPromise().then(() => {
            ApiSessionHandler.loggedOutAction(reasonError)
        }).catch((error) => {
            // console.log('Session > logout failed:' + error)
        })
    }

    private async logoutCompleted() {
        return new Promise<void>((resolve, reject) => {
            if (ApiSessionHandler.isLoggingOut) {
                setTimeout(() => {
                    this.logoutCompleted().then(resolve)
                }, 100)
            } else {
                reject(new ApiLocalErrorBuilder(this.translate({ id: LOCALIZATION.logout_in_progress }), 'logout').build())
            }
        })
    }

    private async waitLoggingOut(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.logoutCompleted().then(resolve)
        })
    }

    private async logoutPromise(): Promise<void> {
        if (ApiSessionHandler.isLoggingOut) {
            // console.log('Session > logout in progress. Waiting')
            return this.waitLoggingOut()
        } else {
            // console.log('Session > going to logout. This should be single')

            ApiSessionHandler.isLoggingOut = true

            return new Promise((resolve, reject) => {
                AuthorisationManager.shared.logoutLocally()
                // Подождем секунду, так как в это время могут придти логауты от других запросов
                const complete = () => {
                    ApiSessionHandler.isLoggingOut = false
                    resolve()
                }
                setTimeout(complete, 1000)
            })
        }
    }
}
