// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import Order from '../../../_Networking/ReactQuery/Order';
import fetchJSONData from '../../../_Networking/ReactQuery/fetch';
import { PAGE_LIMIT, ANALYTICS_BACKEND_URL } from '../../../../constants';
import { queryCache, queryClient } from '../../../../AppProviders';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import ResponseSingle from '../../../_Networking/ReactQuery/ResponseSingle';
import AnalyticsVideoArchive from '../AnalyticsVideoArchive';
import { ArchiveForActionProps, Action, ActionType } from './useMutation';
import { getCachedDataAllPagedItems, Result, usePagedItems } from '../../../_Networking/ReactQuery/template';
import dayjs from 'dayjs';

type FetchProps = Readonly<{
  itemId?: string;
  page?: number;
  limit?: number;
  filterByDate?: string;
  filterByCameraId?: string;
  order?: Order;
  orderBy?: 'date';
  showShared?: boolean;
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'analytics-video-archive';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.itemId,
  props.filterByDate,
  props.filterByCameraId,
  props.page || 0,
  props.limit || PAGE_LIMIT,
  props.orderBy,
  props.order,
]);

const getAllCached = () => (
  getCachedDataAllPagedItems<AnalyticsVideoArchive>(getKeyWithoutPage())
);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<ResponseMany<AnalyticsVideoArchive[]>>(getKey(props))
);

const flushCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};

const patchArchive = async (archive: ArchiveForActionProps): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<boolean>>(
        `${ANALYTICS_BACKEND_URL}/records/${archive.id}`,
        {
          method: 'PATCH',
          body: JSON.stringify({ ...archive.archive }),
        }
    );

    if (!data?.error) {
      return ({
        archives: [archive],
        type: ActionType.Patch,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Edit Archive');
    }
  } catch (error) {
    throw error;
  }
};

const deleteArchives = async (archive: ArchiveForActionProps): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<boolean>>(
        `${ANALYTICS_BACKEND_URL}/records/${archive.id}`,
        {
          method: 'DELETE',
          body: JSON.stringify({ id: archive.id }),
        }
    );

    if (!data?.error) {
      return ({
        archives: [archive],
        type: ActionType.Delete,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Delete Archives');
    }
  } catch (error) {
    throw error;
  }
};

const fetchData = async (props: FetchProps): Promise<ResponseMany<AnalyticsVideoArchive[]>> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  if (!!props.itemId?.length) {// get only one video by id
    return await fetchJSONData<ResponseMany<AnalyticsVideoArchive[]>>(
      `${ANALYTICS_BACKEND_URL}/records/${ props.itemId }`,
      {
        method: 'GET',
      }
    );
  }

  const { limit = PAGE_LIMIT } = props;
  const offset = (props.page || 0) * limit;
  let params = `?limit=${ limit }&offset=${ offset }`;

  switch (props.order) {
    case Order.ASC: {
      params += `&sort_by=time_asc`; break;
    }
    case Order.DESC: {
      params += `&sort_by=time_desc`; break;
    }
  }

  if (props.filterByDate) {
    const date = dayjs(props.filterByDate);
    const startAt = date.startOf('day').toISOString();
    const endAt = date.endOf('day').toISOString();
    params += `&start_at=${ startAt }&end_at=${ endAt }`;
  }

  if (props.filterByCameraId?.length) {
    params += `&camera_ids_filter=${ props.filterByCameraId }`;
  }

  return await fetchJSONData<ResponseMany<AnalyticsVideoArchive[]>>(
    `${ANALYTICS_BACKEND_URL}/records${ params }`,
    {
      method: 'GET',
    }
  );
};


type UseFetchProps = Readonly<{
  enableToFetch: boolean;
} & FetchProps>;


const useFetch = (props: UseFetchProps): Result<AnalyticsVideoArchive> => {
  const { enableToFetch = true, page: pg = 0, limit = PAGE_LIMIT } = props;
  const fetchTemplate = async (queryKey: QueryKey, page: number = 0) => fetchData({
    itemId: props.itemId,
    page,
    limit,
    order: props.order,
    orderBy: props.orderBy,
    filterByDate: props.filterByDate,
    filterByCameraId: props.filterByCameraId,
  });

  return usePagedItems<AnalyticsVideoArchive>({
    page: pg,
    fetch: fetchTemplate,
    queryKey: getKey({
      itemId: props.itemId,
      page: pg,
      limit,
      order: props.order,
      orderBy: props.orderBy,
      filterByDate: props.filterByDate,
      filterByCameraId: props.filterByCameraId,
    }),
    config: {
      enabled: !!enableToFetch,
    },
  });
};


export {
  getKey,
  useFetch,
  getCached,
  flushCache,
  getAllCached,
  patchArchive,
  deleteArchives,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
