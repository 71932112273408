// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>


export const borderValue = (val: number | undefined | null, min?: number, max?: number) : number => {
    const newVal = val || 0
    if (min !== undefined && newVal < min) {
        return min;
    }
    if (max !== undefined && newVal > max) {
        return max;
    }
    return newVal;
}

export enum AnalyticsConvertedDataUnit {
    Number = 'Number',
    Percent = 'Percent',
    Seconds = 'Seconds',
    Minutes = 'Minutes',
    Hours = 'Hours',
    KM = 'KM',
    Meters = 'Meters',
    KmPerHour = 'KmPerHour',
    MeterPerSecond = 'MeterPerSecond',
    MeterPerMinute = 'MeterPerMinute',
}

export type AnalyticsConvertedPreferedUnits = Readonly<{
    distance?: AnalyticsConvertedDataUnit.KM | AnalyticsConvertedDataUnit.Meters; // default KM
    avgSpeed?: AnalyticsConvertedDataUnit.KmPerHour | AnalyticsConvertedDataUnit.MeterPerSecond | AnalyticsConvertedDataUnit.MeterPerMinute; // default KmPerHour
    time?: AnalyticsConvertedDataUnit.Seconds | AnalyticsConvertedDataUnit.Minutes | AnalyticsConvertedDataUnit.Hours; // default Seconds
}>;

export const analyticsDistanceDefMin = 0.01 // use default to prevent 0.0
export const analyticsSpeedDefMin = 0.01 // use default to prevent 0.0
export const analyticsTimeDefMin = 0.01 // use default to prevent 0.0

/*************** DISTANCE ****************/

// analytics distance in meters. -> km round to 0.1 (100.1 km)
export const analyticsDistanceConvertToKM = (val: number | undefined | null, min?: number, max?: number) : number => {
    const newVal = Math.round((val || 0) / 100 ) / 10 //to make 100.1 km
    return borderValue(newVal, (min === undefined) ? analyticsDistanceDefMin : min, max);
}

// analytics distance in meters. -> meters round to 0.1 (100.1 m)
export const analyticsDistanceConvertToMeters = (val: number | undefined | null, min?: number, max?: number) : number => {
    const newVal = Math.round((val || 0) * 10 ) / 10 //to make 100.1 m
    return borderValue(newVal, (min === undefined) ? analyticsDistanceDefMin : min, max);
}

// analytics distance in meters. -> defined units round to 0.1 (default = km)
export const analyticsDistanceConvertTo = (
    val: number | undefined | null, 
    units?: AnalyticsConvertedDataUnit.KM | AnalyticsConvertedDataUnit.Meters, 
    min?: number, max?: number) : number => 
{
    switch (units) {
        case AnalyticsConvertedDataUnit.KM: return analyticsDistanceConvertToKM(val, min, max);
        case AnalyticsConvertedDataUnit.Meters: return analyticsDistanceConvertToMeters(val, min, max);
    }
    return analyticsDistanceConvertToKM(val, min, max);
}

/*************** SPEED ****************/

// analytics speed in meters/sec. -> km/h round to 0.1 (100.1 km/h) (m/s / 1000 * 60 * 60 = m/c * 3.6)
export const analyticsSpeedConvertToKMpH = (val: number | undefined | null, min?: number, max?: number) : number => {
    const newVal = Math.round((val || 0) * 36 ) / 10
    return borderValue(newVal, (min === undefined) ? analyticsSpeedDefMin : min, max);
}

// analytics speed in meters/sec. -> meters/sec round to 0.1
export const analyticsSpeedConvertToMpS = (val: number | undefined | null, min?: number, max?: number) : number => {
    const newVal = Math.round((val || 0) * 10 ) / 10 
    return borderValue(newVal, (min === undefined) ? analyticsSpeedDefMin : min, max);
}

// analytics speed in meters/sec. -> meters/minute round to 0.1
export const analyticsSpeedConvertToMpMin = (val: number | undefined | null, min?: number, max?: number) : number => {
    const newVal = Math.round((val || 0) * 600 ) / 10 
    return borderValue(newVal, (min === undefined) ? analyticsSpeedDefMin : min, max);
}

// analytics speed in meters/sec. -> defined units round to 0.1 (default = m/h)
export const analyticsSpeedConvertTo = (
    val: number | undefined | null, 
    units?: AnalyticsConvertedDataUnit.KmPerHour | AnalyticsConvertedDataUnit.MeterPerSecond | AnalyticsConvertedDataUnit.MeterPerMinute, 
    min?: number, max?: number) : number => 
{
    switch (units) {
        case AnalyticsConvertedDataUnit.KmPerHour: return analyticsSpeedConvertToKMpH(val, min, max);
        case AnalyticsConvertedDataUnit.MeterPerSecond: return analyticsSpeedConvertToMpS(val, min, max);
        case AnalyticsConvertedDataUnit.MeterPerMinute: return analyticsSpeedConvertToMpMin(val, min, max);
    }
    return analyticsSpeedConvertToKMpH(val, min, max);
}

/*************** PERCENTS ****************/

// analytics ration in 0..1 -> percent 0..100
export const analyticsRatioConvertToPercent100 = (val: number | undefined | null, min?: number, max?: number) : number => {
    const percent = Math.round((val || 0) * 100 );
    return borderValue(percent, (min === undefined) ? 0 : min, (max === undefined) ? 100 : max );
}

/*************** TIME ****************/

// analytics time in seconds -> seconds round to 0.1
export const analyticsTimeConvertToSec = (val: number | undefined | null, min?: number, max?: number) : number => {
    const newVal = Math.round((val || 0) * 10 ) / 10 //to make 100.1
    return borderValue(newVal, (min === undefined) ? analyticsTimeDefMin : min , max);
}

// analytics time in seconds -> minutes round to 0.1
export const analyticsTimeConvertToMinutes = (val: number | undefined | null, min?: number, max?: number) : number => {
    const newVal = Math.round((val || 0) / 6 ) / 10 //to make 100.1
    return borderValue(newVal, (min === undefined) ? analyticsTimeDefMin : min, max);
}

// analytics time in seconds -> hours round to 0.1
export const analyticsTimeConvertToHours = (val: number | undefined | null, min?: number, max?: number) : number => {
    const newVal = Math.round((val || 0) / 360 ) / 10 //to make 100.1
    return borderValue(newVal, (min === undefined) ? analyticsTimeDefMin : min, max);
}

// analytics time in seconds -> defined units round to 0.1 (default = seconds)
export const analyticsTimeConvertTo = (
    val: number | undefined | null, 
    units?: AnalyticsConvertedDataUnit.Seconds | AnalyticsConvertedDataUnit.Minutes | AnalyticsConvertedDataUnit.Hours, 
    min?: number, max?: number) : number => 
{
    switch (units) {
        case AnalyticsConvertedDataUnit.Seconds: return analyticsTimeConvertToSec(val, min, max);
        case AnalyticsConvertedDataUnit.Minutes: return analyticsTimeConvertToMinutes(val, min, max);
        case AnalyticsConvertedDataUnit.Hours: return analyticsTimeConvertToHours(val, min, max);
    }
    return analyticsTimeConvertToSec(val, min, max);
}