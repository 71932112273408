// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { memo } from 'react';
import { Grid } from '@mui/material';

import Camera from '../../Data/Camera/ActiveCamera/Camera';
import CameraCardBlock from './CameraCardBlock';
import { useCamerasHardwareStatusesList, } from '../../Data/Camera/RemoteCameraState/hook';


type Props = Readonly<{
  camera: Camera;
}>;


const ActiveCamera: React.FC<Props> = (props: Props) => {
  const camerasStates = useCamerasHardwareStatusesList({
    enableToFetch: true,
    page: 0,
    filterByCameraID: props.camera.cameraId
  });
  const cameraHardware = camerasStates.data?.data.find(({ cameraId }) => (cameraId === props.camera.cameraId)) || props.camera.state;

  return (
    <Grid
      item
      xs={ 12 }
    >
      <CameraCardBlock
        isServer
        hideActiveCameraBorder={true}
        camera={ props.camera }
        activeCamera={ props.camera }
        cameraHardware={ cameraHardware }
      />
    </Grid>
  );
};


export default memo(ActiveCamera);
