// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import {
  useFetch,
  flushCache,
  getCached,
} from '../fetch';
import { AnalyticsConfig } from '../AnalyticsConfig';

type Props = Readonly<{
  enableToFetch?: boolean
}>;

type Result = Readonly<{
  status: string;
  data: AnalyticsConfig | undefined;
  handleFlushData: () => void;
}>;


export const useAnalyticsConfig = (props: Props): Result => {
  const { enableToFetch = true } = props;
  const fetchedArchive = useFetch({
    enableToFetch
  });
  const data = getCached({
  });

  return ({
    status: fetchedArchive.status,
    data,
    handleFlushData: flushCache,
  });
};

