// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

// Для получения актульаных значений
import { metricMultiplier } from '../../../Tools/Tools'
import { PlatformConfiguration } from '../../../Configuration/PlatformConfiguration'
import { MetricsDataProviderBase } from './DataProvider'
import MetricsApiService from './Networking'
import { MetricsValuesSourceInterface, MetricsDataItem, MetricTypeInterface, MetricRequestParametersInterface, MetricRequestSortParametersInterface, MetricRequestRangeParametersInterface } from './Types'

export class MetricsValuesDataProvider extends MetricsDataProviderBase {
    get name(): string {
        return this.source.uniqueKey
    }

    constructor(private source: MetricsValuesSourceInterface) {
        super()
        // console.log(`MetricsValuesDataProvider > created with uniqueKey: ${source.uniqueKey}`)
    }

    get updateInterval(): number {
        return PlatformConfiguration.metricsDataTimeoutInterval
    }

    updateSource(source: MetricsValuesSourceInterface): void {
        const existingUniqueKey = this.source.uniqueKey

        if (existingUniqueKey !== source.uniqueKey) {
            // console.log(`MetricsValuesDataProvider > wrong source. Existing key = ${existingUniqueKey}, new key = ${source.uniqueKey}`)
        } else {
            this.source = source
        }
    }

    private apiService = new MetricsApiService()

    async update() {
        const source = this.source
        let data: MetricsDataItem | undefined

        for (const metric of source.metrics) {
            const requestParameters = this.buildParameters(metric, source)
            try {
                const items = (await this.apiService.get1DMetric(requestParameters))
                if (items.length === 1) {
                    const item = items[0]
                    const result: MetricsDataItem = data ?? {
                        date: item.sampleTime * PlatformConfiguration.metricsSample
                    }

                    result[metric.dataKey] = item.value * metricMultiplier(metric)
                    data = result
                }
            } catch (error) {
                // console.log(`MetricsValuesDataProvider > failed to fetch values: ${error}`)
            }
        }
        if (data) {
            this.data = [data]
        }
    }

    private buildParameters(metric: MetricTypeInterface, source: MetricsValuesSourceInterface): MetricRequestParametersInterface {
        const identificationParameters = metric.identificationParameters

        const sortParameters: MetricRequestSortParametersInterface = {
            sort: 'whenCreated',
            direction: 'desc'
        }

        const rangeParameters: MetricRequestRangeParametersInterface = {
            max: 1
        }

        const requestParameters: MetricRequestParametersInterface = { ...identificationParameters, ...sortParameters, ...rangeParameters }

        return requestParameters
    }
}
