// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useMutation } from 'react-query';

import { flushCache } from '.';
import Team from '../Team';
import { deleteTeam, postTeam } from './index';
import TeamWithoutID from '../TeamWithoutID';


export enum ActionType {
  Post = 'post',
  Delete = 'delete',
}

export type Action = Readonly<{
  team?: Team | TeamWithoutID;
  IDs?: number[];
  type: ActionType;
}>;


const useMutationTeam = () => {
  return useMutation(
    (action: Action) => {
      switch (action.type) {
        case ActionType.Post: {
          return postTeam(action.team as Team);
        }
        case ActionType.Delete: {
          return deleteTeam(action.IDs as number[]);
        }
        default:
          return Promise.reject(new Error('Invalid Account Mutation Action'));
      }
    },
    {
      onSuccess: flushCache,
    },
  );
};


export default useMutationTeam;
