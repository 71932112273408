// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Colors } from '../../../Configuration/Styles/Colors';
import ErrorMessage from '../../_BaseUI/LastErrorMessageLabel/ErrorMessage';
import { createStyles, makeStyles } from '@mui/styles';
import { Grid, SxProps, Theme } from '@mui/material';


type Props = Readonly<{
  text?: string;
  button?: any;
  error?: Error;
  sx?: SxProps<Theme>;
}>;


const useStyles = makeStyles(() => createStyles({
  root: {
    flexGrow: 1,
  },
  header: {
    fontWeight: Sizes.boldWeight,
    fontFamily: Fonts.main,
    fontSize: Sizes.caption,
    color: Colors.headingMain,
  },
}));

const Header: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={ 12 }>
        <Grid container spacing={ 2 } sx = { props.sx } justifyContent="space-between">
          {
            (props.text) &&
              <Grid item>
                <Grid container spacing={ 2 }>
                  <Grid item>
                    <div
                      className={ classes.header }
                    >
                      { props.text }
                    </div>
                  </Grid>
                </Grid>
              </Grid>
          }
          {
            (props.button) &&
              <Grid item>
                <Grid container spacing={ 2 }>
                  <Grid item>
                    { props.button }
                  </Grid>
                </Grid>
              </Grid>
          }
        </Grid>
      </Grid>

      { 
      (props.error) &&
        <Grid item xs={ 12 }>
          <ErrorMessage
            error={ props.error }
          />
          {/*<Spinner isHidden = { !isLoading }/>*/}
        </Grid>
      }
    </>
  );
};


export default Header;
