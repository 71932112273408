// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import { HeadCell } from './SortedTableHead';
import { RenderCellContent } from './SortedTableBodyRow';
import { SxProps, TableCell, TableCellProps, Theme } from '@mui/material';


export type RenderCellContentValue = {
  cellProps?: TableCellProps;
  content: JSX.Element | string | number;
};

type Props<T> = Readonly<{
  cell: HeadCell<T>;
  index: number;
  labelId: string;
  row: T;
  renderCellContent?: RenderCellContent<T>;
  sx?: SxProps<Theme>;
}>;


const SortedTableBodyCell = <T, >(props: Props<T>): JSX.Element => {
  let cellProps: TableCellProps = {
    id: (props.index === 0) ? props.labelId as string : props.cell.id as string,
    component: 'th',
    scope: 'row',
    padding: 'none',
    sx: props.sx,
  };
  let content: any = '';
  if ((props?.renderCellContent)) {
    const render = props.renderCellContent(props.row, props.cell.id, props.index);

    content = render.content;
    cellProps = {
      ...cellProps,
      ...render.cellProps,
    };
  } else {
    content = props.row?.[props.cell.id] || '';
  }

  return (
    <TableCell
      { ...cellProps }
    >
      { content }
    </TableCell>
  );
};


export default SortedTableBodyCell;
