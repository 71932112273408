// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from '../../Configuration/Styles/Colors';
import { Sizes } from '../../Configuration/Styles/Sizes';
import { Fonts } from '../../Configuration/Styles/Fonts';


const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    padding: 20,
    paddingTop: 48,
    [theme.breakpoints.up('md')]: {
      paddingTop: 200
    }
  },
  none: {
    display: 'none'
  },
  error: {
    color: Colors.mainTitle,
    textAlign: 'center'
  },
  errorText: {
    fontSize: Sizes.subtitle,
    fontFamily: Fonts.main
  }
}));


export default useStyles;
