// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import BaseCommandExecutor from '../../../_Networking/AxiosFetch/Executor'
import { PiRestApi } from '../../../_Networking/AxiosFetch/RestApi'
import { CameraSettingsPatchInfoInterface } from '../CameraSettingsTemperature'
import { CameraSettingsInterface } from '../CameraSettings'


interface CameraSettingsApiServiceInterface {
    shutdown(): Promise<void>
    getCameraSettings(): Promise<CameraSettingsInterface>
    setCameraSettings(info: CameraSettingsPatchInfoInterface): Promise<CameraSettingsInterface>
}

export default class CameraSettingsApiService implements CameraSettingsApiServiceInterface {
    shutdown(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const command = PiRestApi.pressActionCommand('poweroff')
            const executor = new BaseCommandExecutor<void>(command)
            executor.execute(data => resolve(data), error => reject(error))
        })
    }

    getCameraSettings(): Promise<CameraSettingsInterface> {
        return new Promise<CameraSettingsInterface>((resolve, reject) => {
            const command = PiRestApi.fetchCameraSettingsCommand()
            const executor = new BaseCommandExecutor<CameraSettingsInterface>(command)
            executor.execute(data => resolve(data), error => reject(error))
        })
    }

    setCameraSettings(info: CameraSettingsPatchInfoInterface): Promise<CameraSettingsInterface> {
        return new Promise<CameraSettingsInterface>((resolve, reject) => {
            const command = PiRestApi.patchCameraSettingsCommand(info)
            const executor = new BaseCommandExecutor<CameraSettingsInterface>(command)
            executor.execute(data => resolve(data), error => reject(error))
        })
    }
}
