// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useState, useEffect } from 'react'
import { MetricsDataHelper } from './Helper'
import { MetricsValuesDataProvider } from './MetricsValuesDataProvider'
import { MetricsDataProviderInterface, MetricsOwnerInterface } from './DataProvider'
import { MetricsDataItem, MetricsSourceInterface, MetricsValuesSourceInterface, MetricTypeInterface, MetricsDataProviderUniqueKey } from './Types'

export interface MetricsValuesData {
    readonly items: MetricsDataItem[]
}

class MetricsChardDataProviderOwner implements MetricsOwnerInterface {
    createDataProvider(source: MetricsSourceInterface): MetricsDataProviderInterface {
        // тут мы имеем дело с экземпляром MetricsValuesSourceInterface
        return new MetricsValuesDataProvider(source as MetricsValuesSourceInterface)
    }
}

export function useMetricValuesData(source: MetricsValuesSourceInterface): MetricsValuesData {
    const owner = new MetricsChardDataProviderOwner()
    const [items, setItems] = useState<MetricsDataItem[]>(MetricsDataHelper.items(source, owner))

    useEffect(() => {
        function handleDataChange(items: MetricsDataItem[]) {
            setItems(items)
        }

        MetricsDataHelper.subscribeToMetricsChange(source, handleDataChange, owner)
        return () => {
            MetricsDataHelper.unsubscribeFromMetricsChange(source, handleDataChange)
        }
    })

    return {
        items: items
    }
}

export function useSingleMetricValue(metric: MetricTypeInterface, uniqueKey: MetricsDataProviderUniqueKey): number | undefined {
    const data = useMetricValuesData({
        metrics: [metric],
        uniqueKey: uniqueKey,
        defaultSelected: [] // при получении значений мы не выбираем значения. Пока. Поэтому []
    })

    if (data.items.length === 1) {
        const item = data.items[0]
        const value = item[metric.dataKey]
        return value
    }

    return undefined
}
