// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { createStyles, makeStyles } from '@mui/styles';

import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';


const useStyles = makeStyles(createStyles({
  sectionContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    fontWeight: 'inherit',
  },
  sectionContainerSelected: {
    '& $text': {
      // fontFamily: Fonts.main,
      // fontSize: Sizes.subtitle,
      // fontWeight: 'inherit',
      // color: 'inherit',
    },
    '& $icon': {
      // fill: 'none',
      // fill: Colors.headingMain,
    },
  },
  sectionContainerRegular: {
    '& $text': {
      // fontFamily: Fonts.main,
      // fontSize: Sizes.subtitle,
      // fontWeight: 'inherit',
      // color: 'inherit',
    },
    '& $icon': {
      // fill: 'currentColor',
      // fill: 'none',
      // fill: Colors.headingMain,
    },
  },
  icon: {
    minWidth: 18,
    maxWidth: 18,
    minHeight: 18,
    maxHeight: 18,
    marginLeft: 4,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    width: 18,
    height: 18,
    // fill: 'inherit',
  },
  sectionIcon: {
    minWidth: 24,
    maxWidth: 24,
    minHeight: 24,
    maxHeight: 24,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    width: 24,
    height: 24,
    // fill: 'inherit',
  },
  text: {
    color: 'inherit',
    fontFamily: Fonts.main,
    fontSize: Sizes.subtitle,
    fontWeight: 'inherit',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  mainHeaderText: {
    color: 'inherit',
    fontFamily: Fonts.main,
    fontSize: Sizes.subtitle,
    fontWeight: 'inherit',
    overflow: 'hidden',
    // whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  },
}));


export default useStyles;
