// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import { FieldSetInputType } from '.';
import { createStyles, makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';


export type ChangeNumber = (value: number | null) => void;

export type FieldSetInputNumber = Readonly<{
  type: FieldSetInputType.Number | FieldSetInputType.Float;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  onChange: ChangeNumber;
}>;

type Props = Readonly<{
  input: FieldSetInputNumber;
}>;


export const useStylesInputBox = makeStyles(() => createStyles({
  input: {
    height: '38px',
    paddingTop: '0px',
    paddingBottom: '0px',
    lineHeight: '38px',
  },
}));

const FieldSetNumber: React.FC<Props> = (props: Props) => {
  const classes = useStylesInputBox();

  const handleTextValueChanged = (event: React.SyntheticEvent) => {
    const textValue = (event.target as HTMLInputElement).value
    let value = (props.input.type === FieldSetInputType.Float) ?
      parseFloat(textValue || '')
      :
      parseInt((textValue || ''), 10);

    if (!isNaN(value)) {
      if ((props.input.min !== undefined) && (value < props.input.min)) value = props.input.min;
      else if ((props.input.max !== undefined) && (value > props.input.max)) value = props.input.max;

      props.input.onChange(value);
    }
    else if (textValue === '') {
      props.input.onChange( props.input.min ?? 0);
      
    }
    else {
      props.input.onChange(null);
    }
  };

  return (
    <TextField
      fullWidth
      type={ FieldSetInputType.Number }
      variant="outlined"
      value={ props.input.value }
      InputProps={ { className: classes.input } }
      inputProps={ { step: props.input.step, min: props.input.min, max: props.input.max } }
      onChange={ handleTextValueChanged }
    />
  );
};


export default FieldSetNumber;
