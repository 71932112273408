// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Checkbox, Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import MainStreamSource from './Source';
import DestTypeBlock from './DestTypeBlock';
import { ItemStreamProps } from '../index';
import BoxComponent from '../../../Box';
import MainStreamVideoResolution from './VideoResolution';
import FieldSet, { FieldSetInputType } from '../../../../../Components/_BaseUI/FieldSet';
import { DestStreamBlock } from '../../../../../Data/NapiStreamingProfile';
import { StreamBlock, StreamType, VideoResolution } from '../../../../../Data/NapiStreamingProfileOptions';
import { EMPTY_ARRAY } from '../../../../../constants';
import FieldSetWrapper from '../FieldSetWrapper';
import LOCALIZATION from '../../../../../Localization';
import CameraConfig from '../../../../../Data/NapiCameraConfig';
import { clone } from 'lodash';
import { DestType } from '../../../../../Data/NapiCameraConfig/Dest';
import MainStreamFps from './MainStreamFps';
import uuid from '../../../../../Tools/uuid';

type Props = Readonly<{
  item: ItemStreamProps;
  defaultStreamingProfileConfig: CameraConfig;
  videoResolution: VideoResolution[];
  streamBlock: StreamBlock[];
  isNotProxyMode: boolean;
  onChange: (item: ItemStreamProps) => void;
} & WrappedComponentProps>;


const MainStream: React.FC<Props> = (props: Props) => {
  const handleChangeVideoBitRate = (value: number | null) => {
    let bitrate = (value !== null && !isNaN(value)) ? value : 0;
    if (bitrate <= 0) {
      bitrate = 0.1;
    }
    if (bitrate > 200) {
      bitrate = 200;
    }

    props.onChange({
      ...props.item,
      encoder: {
        ...props.item?.encoder,
        bitrate: Math.ceil(bitrate * 1000000),// mbit -> bit
      },
    });
  };
  const handleChangeSyncOperatorStream = (event: any, syncOperator: boolean) => {
    props.onChange({
      ...props.item,
      custom: {
        ...props.item.custom,
        syncOperator,
      },
    });
  };

  const storeOperatorLocaly = (props.item.custom?.destStreamBlock !== undefined) && Array.isArray(props.item.custom?.destStreamBlock) && (props.item.custom.destStreamBlock.findIndex((item) => (item.id === StreamType.LocalFile && item.enabled)) >= 0);

  const handleChangeStoreOperatorLocaly = (event: any, storeOperatorLocal: boolean) => {

    let destStreamBlock = clone(props.item.custom?.destStreamBlock) || []
    let item = destStreamBlock.find((item) => item.id === StreamType.LocalFile);
    if (!item) {
      item = {
        key: uuid(),
        type: DestType.MP4,
        id: StreamType.LocalFile,
        enabled: true,
      };
      destStreamBlock.push(item);
    }

    if (item) {
      item.enabled = storeOperatorLocal;
    }

    props.onChange({
      ...props.item,
      custom: {
        ...props.item.custom,
        destStreamBlock,
      },
    });
  };

  const handleChangeDest = (destStreamBlock: DestStreamBlock[]) => {

    let item = destStreamBlock.find((item) => item.id === StreamType.LocalFile);
    if (!item) {
      destStreamBlock.push({
        key: uuid(),
        type: DestType.MP4,
        id: StreamType.LocalFile,
        enabled: storeOperatorLocaly,
      });
    }
    else {
      item.enabled = storeOperatorLocaly;
    }

    props.onChange({
      ...props.item,
      custom: {
        ...props.item.custom,
        destStreamBlock,
      },
    });
  };

  return (
    <BoxComponent
      title={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_title }) }
    >
      <Grid
        container
        spacing={ 4 }
      >
        {(props.isNotProxyMode) &&
          <>
            <MainStreamSource
              item={ props.item }
              defaultStreamingProfileConfig={ props.defaultStreamingProfileConfig }
              onChange={ props.onChange }
            />
            <MainStreamVideoResolution
              item={ props.item }
              videoResolutions={ props.videoResolution }
              onChange={ props.onChange }
            />
            <FieldSetWrapper>
              <FieldSet
                key={ 'VideoBitRate' }
                label={ `${ props.intl.formatMessage({ id: LOCALIZATION.stream_video_bitrate_mbps }) }:` }
                input={ {
                  type: FieldSetInputType.Float,
                  value: (props.item?.encoder?.bitrate) ? props.item?.encoder?.bitrate / 1000000 : 10, // bit -> mbit
                  onChange: handleChangeVideoBitRate,
                } }
              />
            </FieldSetWrapper>
            <MainStreamFps
              item={ props.item }
              onChange={ props.onChange }
            />
            <FieldSetWrapper>
              <FieldSet
                label={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_store_local_file }) }
                input={ {
                  type: FieldSetInputType.Other,
                  children: (
                    <Checkbox
                      sx = {{ marginLeft : -1 }}
                      defaultChecked={ storeOperatorLocaly }
                      value={ storeOperatorLocaly }
                      color="primary"
                      onChange={ handleChangeStoreOperatorLocaly }
                    />
                  ),
                } }
              />
            </FieldSetWrapper>
            <FieldSetWrapper>
              <FieldSet
                label={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_sync_to_server }) }
                input={ {
                  type: FieldSetInputType.Other,
                  children: (
                    <Checkbox
                      sx = {{ marginLeft : -1 }}
                      defaultChecked={ !!props.item.custom?.syncOperator }
                      value={ !!props.item.custom?.syncOperator }
                      color="primary"
                      onChange={ handleChangeSyncOperatorStream }
                    />
                  ),
                } }
              />
            </FieldSetWrapper>
          </>
        }
        <DestTypeBlock
          key="DestTypeBlock"
          destStreamBlocksArr={ props.item.custom?.destStreamBlock || EMPTY_ARRAY }
          streamBlocksArr={ props.streamBlock }
          onChange={ handleChangeDest }
        />
      </Grid>
    </BoxComponent>
  );
};


export default injectIntl(MainStream);
