// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useMutation } from 'react-query';

import StreamingProfile from '..';
import { flushStreamingProfilesCache, deleteStreamingProfiles, postStreamingProfiles } from '../fetch/index';


export enum ActionType {
  Post = 'post',
  Delete = 'delete',
}

export type Action = Readonly<{
  streamingSettings?: StreamingProfile;
  IDs?: string[];
  type: ActionType;
}>;


const useMutationStreamingSettings = () => {
  return useMutation(
    (action: Action) => {
      switch (action.type) {
        case ActionType.Post: {
          return postStreamingProfiles(action.streamingSettings as StreamingProfile);
        }
        case ActionType.Delete: {
          return deleteStreamingProfiles(action.IDs as string[]);
        }
        default:
          return Promise.reject(new Error('Invalid Account Mutation Action'));
      }
    },
    {
      onSuccess: flushStreamingProfilesCache,
    },
  );
};


export default useMutationStreamingSettings;
