// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import Meta from '../_Networking/ReactQuery/Meta';


export enum FileType {
    Operator = 'operator',
    Panorama = 'panorama',
    Scoreboard = 'scoreboard',
}

type Archive = Readonly<{
    type: FileType;
    meta: Meta;
    size: number;
    screenshot: string;
    video: string;
}>;


export default Archive;
