// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import Hjson, { DeserializeOptions, SerializeOptions } from 'hjson';

export default class HjsonEx {

    static defSaveOptions: SerializeOptions =  {
        /**
         * controls how strings are displayed. (setting separator implies "strings")
         * "min": no quotes whenever possible (default)
         * "keys": use quotes around keys
         * "strings": use quotes around string values
         * "all": use quotes around keys and string values
         */
        quotes: 'all',
        /**
         * controls how multiline strings are displayed. (setting quotes implies "off")
         * "std": strings containing \n are shown in multiline format (default)
         * "no-tabs": like std but disallow tabs
         * "off": show in JSON format
         */
        multiline:'off',
        /**
         * output a comma separator between elements. Default false
         */
        separator: true,
        /**
         * specifies the indentation of nested structures.
         * If it is a number, it will specify the number of spaces to indent at each level.
         * If it is a string (such as '\t' or ' '), it contains the characters used to indent at each level.
         */
        space: 4,
      }

    // parse json or hjson with comments
    static parse(text: string, options?: DeserializeOptions): any {
        return Hjson.parse(text, {keepWsc: true, ...options})
    }
    static parseToJson(text: string, options?: DeserializeOptions): any {
        return Hjson.parse(text, {...options, keepWsc: false})
    }

    // will produce json with comments. other hjson features disabled
    static stringify(value: any, options?: SerializeOptions): string {
        return Hjson.stringify(value, {keepWsc: true, ...HjsonEx.defSaveOptions, ...options})
    }

    // will produce json with comments. other hjson features disabled
    static stringifyToHjson(value: any): string {
        return HjsonEx.stringify(value)
    }

    // will produce regular json
    static stringifyToJson(value: any): string {
        return Hjson.stringify(value, {keepWsc: false, ...HjsonEx.defSaveOptions})
    }
}
