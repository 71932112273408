// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { AnalyticsStatScoreTeam } from "../AnalyticsStatScore/AnalyticsStatScore";
import { AnalyticsSportType, AnalyticsTimeIntervalTypeHeader, AnalyticsType } from "../AnalyticsTypes";
import { AnalyticsAnalyticsStatus } from "../AnalyticsStatuses";
import AnalyticsVideoArchive from "../AnalyticsVideoArchive/AnalyticsVideoArchive";
import { Colors } from "../../../Configuration/Styles/Colors";
import { findNearestColorInPalette } from "../../../Components/_BaseUI/AnalyticsTeamsColorPalettePicker/helper";
import { analyticsTeamsColors } from "../../../Components/_BaseUI/AnalyticsTeamsColorPalettePicker";
import { patchGame } from "./fetch";

/** Shared values for Game Report.*/
export const AnalyticsGameShared = {
    /** gameId used to update colors only if need */
    gameIdForColors: "default",
    /** shared color used in Analytics for team1 color. when open new report value chnaged to match selected game */
    team1Color: Colors.analyticsTeam1,
    /** shared color used in Analytics for team2 color. when open new report value chnaged to match selected game */
    team2Color: Colors.analyticsTeam2,
};

export function updateAnalyticsGameSharedColors(team1Color: string | undefined, team2Color: string | undefined, gameId?: string, makePostIfGameColorsChanged?: boolean) {
    if (gameId === AnalyticsGameShared.gameIdForColors) {
        return false;
    }
    if (gameId) {
        AnalyticsGameShared.gameIdForColors = gameId;
    }
    let colorsChanged = false

    let team1PaletteColor = (team1Color) ? findNearestColorInPalette(analyticsTeamsColors, team1Color, AnalyticsGameShared.team2Color) : undefined
    if (team1PaletteColor && AnalyticsGameShared.team1Color !== team1PaletteColor) {
        AnalyticsGameShared.team1Color = team1PaletteColor ?? Colors.analyticsTeam1;
        colorsChanged = true
    }

    let team2PaletteColor = (team2Color) ? findNearestColorInPalette(analyticsTeamsColors, team2Color, AnalyticsGameShared.team1Color) : undefined
    if (team2PaletteColor && AnalyticsGameShared.team2Color !== team2PaletteColor) {
        AnalyticsGameShared.team2Color = team2PaletteColor ?? Colors.analyticsTeam2;
        colorsChanged = true
    }

    // if colors changed update values in game record
    if (makePostIfGameColorsChanged && colorsChanged && AnalyticsGameShared.gameIdForColors) {
        try {
            patchGame({
                id: AnalyticsGameShared.gameIdForColors,
                game: {
                    type: AnalyticsType.Game,
                    id: AnalyticsGameShared.gameIdForColors,
                    team1_color: AnalyticsGameShared.team1Color, 
                    team2_color: AnalyticsGameShared.team2Color, 
                }
            })
        }
        catch(ignore){
        }
    }

    return colorsChanged;
}

/** Represent Entry place for Analytics Report for Game */
export type AnalyticsGame = Readonly<{
    /** title for game report. defaults by server to "${team1_name} - ${team2_name}" */
    title?: string;
    /** subtitle for game report. defaults by server to video event name when create new AnalyticsGame */
    subtitle?: string;
    /** path for jpeg relative to nginx images folder path */
    preview?: string;
    /** id for AnalyticsVideoArchive connected with game */
    record_id?: AnalyticsVideoArchive['id'];
    /** path for streaming relative to nginx video folder path */
    video?: string;
    /** "00:00:00" - when game video start inside whole video recod. video record contain several games */
    video_start?: string;
    /** "01:00:00", - when game video end inside whole video recod. video record contain several games */
    video_end?: string;
    /** team1 external id */
    team1?: string;
    /** team1 hex color in analytics report */
    team1_color?: string;
    /** team2 external id */
    team2?: string;
    /** team2 hex color in analytics report */
    team2_color?: string;
    /** game start in real world time: "2012-05-01T21:05:25Z" */
    start?: string;
    /** game end in real world time: "2012-05-01T21:05:25Z" */
    end?: string;
    score?: AnalyticsStatScoreTeam;
    sport_type?: AnalyticsSportType;
    /** when analytics processed on server represent percent of task read. range 0..100 */
    analytics_progress?: number;
    analytics_status?: AnalyticsAnalyticsStatus;
    time_periods?: AnalyticsTimeIntervalTypeHeader[];// after events process contail how system split events
} & AnalyticsGameTeamMapping>;

export type AnalyticsGameTeamMapping = Readonly<{
    /** used to connect player images with team */
    team1_markup_id?: number; 
    /** used to connect player images with team */
    team2_markup_id?: number; 
}>;

type AnalyticsGameArchive = Readonly<{
    type: AnalyticsType;
    id: string;
    copyId?: string; // use to make game copy in app 
} & AnalyticsGame>;

export const getGameTitle = (game: AnalyticsGame | undefined): string => {
    if (!!game?.title?.length) {
        return game.title
    }
    return `${ game?.team1 || "" } - ${ game?.team2 || "" }`
}

export default AnalyticsGameArchive;
