// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { createStyles, makeStyles } from '@mui/styles';

import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Colors } from '../../../Configuration/Styles/Colors';


const useStyles = makeStyles(() => createStyles({
  regularText: {
    paddingTop: 1,
    paddingBottom: 1,
    color: Colors.mainTitle,
    fontSize: Sizes.subtitle,
    fontFamily: Fonts.main
  },
  icon: {
    display: 'flex',
    width: 12,
    height: 12,
    borderRadius: '50%'
  },
  iconContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}));


export default useStyles;
