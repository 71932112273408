// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

const LOCALIZATION = {
  NONE: 'no translation',
  app_title: "app_title",
  add_cam_login_pass_help: "add_cam_login_pass_help",
  add_cam_name_help: "add_cam_name_help",
  add_cam_ip_address: "add_cam_ip_address",
  add_cam_login: "add_cam_login",
  add_cam_success_alert: "add_cam_success_alert",
  add_teamref_success_alert: "add_teamref_success_alert",
  alert_only_png_images: "alert_only_png_images",
  tooltip_team_short_name: "tooltip_team_short_name",
  tooltip_team_color_use: "tooltip_team_color_use",
  page_status_title: "page_status_title",
  page_status_heading: "page_status_heading",
  page_hardware_title: "page_hardware_title",
  page_hardware_heading: "page_hardware_heading",
  page_camera_api_title: "page_camera_api_title",
  page_camera_configuration_title: "page_camera_configuration_title",
  page_camera_api_heading: "page_camera_api_heading",
  page_events_title: "page_events_title",
  page_events_heading: "page_events_heading",
  page_panorama_title: "page_panorama_title",
  page_panorama_heading: "page_panorama_heading",
  page_sync_settings_title: "page_sync_settings_title",
  page_sync_settings_heading: "page_sync_settings_heading",
  page_users_administration_title: "page_users_administration_title",
  page_analytics_teams_title: "page_analytics_teams_title",
  page_analytics_video_archive_title: "page_analytics_video_archive_title",
  page_analytics_games_archive_title: "page_analytics_games_archive_title",
  analytics_possession: "analytics_possession",
  analytics_zone_possession: "analytics_zone_possession",
  analytics_zone_possession_draw: "analytics_zone_possession_draw",
  analytics_tab_overall: "analytics_tab_overall",
  analytics_tab_events: "analytics_tab_events",
  analytics_tab_heatmap: "analytics_tab_heatmap",
  analytics_tab_attacking: "analytics_tab_attacking",
  analytics_tab_attacking_defence: "analytics_tab_attacking_defence",
  analytics_tab_defending: "analytics_tab_defending",
  analytics_tab_defese_attack: "analytics_tab_defese_attack",
  analytics_tab_overall_name: "analytics_tab_overall_name",
  analytics_tab_attacking_name: "analytics_tab_attacking_name",
  analytics_tab_attacking_defence_name: "analytics_tab_attacking_defence_name",
  analytics_tab_defending_name: "analytics_tab_defending_name",
  analytics_tab_defese_attack_name: "analytics_tab_defese_attack_name",
  analytics_score: "analytics_score",
  analytics_no_players: "analytics_no_players",
  analytics_seconds_short: "analytics_seconds_short",
  analytics_distance_covered: "analytics_distance_covered",
  analytics_avg_speed: "analytics_avg_speed",
  analytics_avg_time_in_possession: "analytics_avg_time_in_possession",
  analytics_attack_time: "analytics_attack_time",
  analytics_defense_time: "analytics_defense_time",
  analytics_walk: "analytics_walk",
  analytics_run: "analytics_run",
  analytics_sprint: "analytics_sprint",
  analytics_attacking: "analytics_attacking",
  analytics_defending: "analytics_defending",
  analytics_intensity: "analytics_intensity",
  analytics_intensity_short: "analytics_intensity_short",
  analytics_type_time1: "analytics_type_time1",
  analytics_type_time2: "analytics_type_time2",
  analytics_type_time3: "analytics_type_time3",
  analytics_type_time4: "analytics_type_time4",
  analytics_type_overtime1: "analytics_type_overtime1",
  analytics_type_overtime2: "analytics_type_overtime2",
  analytics_type_overtime3: "analytics_type_overtime3",
  analytics_type_penalty1: "analytics_type_penalty1",
  analytics_type_penalty2: "analytics_type_penalty2",
  analytics_ganularity: "analytics_ganularity",
  analytics_ganularity_0: "analytics_ganularity_0",
  analytics_ganularity_2: "analytics_ganularity_2",
  analytics_ganularity_4: "analytics_ganularity_4",
  analytics_ganularity_8: "analytics_ganularity_8",
  analytics_visualize_mode: "analytics_visualize_mode",
  analytics_visualize_mode_bar: "analytics_visualize_mode_bar",
  analytics_visualize_mode_line: "analytics_visualize_mode_line",
  analytics_compare: "analytics_compare",
  analytics_unit_percent: "analytics_unit_percent",
  analytics_unit_seconds: "analytics_unit_seconds",
  analytics_unit_minutes: "analytics_unit_minutes",
  analytics_unit_hours: "analytics_unit_hours",
  analytics_unit_km: "analytics_unit_km",
  analytics_unit_meters: "analytics_unit_meters",
  analytics_unit_km_per_hour: "analytics_unit_km_per_hour",
  analytics_unit_meter_per_second: "analytics_unit_meter_per_second",
  analytics_unit_meter_per_minute: "analytics_unit_meter_per_minute",
  analytics_unit_percent_short: "analytics_unit_percent_short",
  analytics_unit_seconds_short: "analytics_unit_seconds_short",
  analytics_unit_minutes_short: "analytics_unit_minutes_short",
  analytics_unit_hours_short: "analytics_unit_hours_short",
  analytics_unit_km_short: "analytics_unit_km_short",
  analytics_unit_meters_short: "analytics_unit_meters_short",
  analytics_unit_km_per_hour_short: "analytics_unit_km_per_hour_short",
  analytics_unit_meter_per_second_short: "analytics_unit_meter_per_second_short",
  analytics_unit_meter_per_minute_short: "analytics_unit_meter_per_minute_short",
  analytics_unit_percent_format: "analytics_unit_percent_format",
  analytics_unit_seconds_format: "analytics_unit_seconds_format",
  analytics_unit_minutes_format: "analytics_unit_minutes_format",
  analytics_unit_hours_format: "analytics_unit_hours_format",
  analytics_unit_km_format: "analytics_unit_km_format",
  analytics_unit_meters_format: "analytics_unit_meters_format",
  analytics_unit_km_per_hour_format: "analytics_unit_km_per_hour_format",
  analytics_unit_meter_per_second_format: "analytics_unit_meter_per_second_format",
  analytics_unit_meter_per_minute_format: "analytics_unit_meter_per_minute_format",
  analytics_stat_team_distance: "analytics_stat_team_distance",
  analytics_stat_avg_players_speed: "analytics_stat_avg_players_speed",
  analytics_stat_distance_walked_by_players: "analytics_stat_distance_walked_by_players",
  analytics_stat_avg_walking_speed: "analytics_stat_avg_walking_speed",
  analytics_stat_distance_run_by_players: "analytics_stat_distance_run_by_players",
  analytics_stat_avg_running_speed: "analytics_stat_avg_running_speed",
  analytics_stat_distance_sprinted_by_players: "analytics_stat_distance_sprinted_by_players",
  analytics_stat_avg_sprinting_speed: "analytics_stat_avg_sprinting_speed",
  analytics_stat_avg_intensity: "analytics_stat_avg_intensity",
  analytics_help_walking: "analytics_help_walking",
  analytics_help_running: "analytics_help_running",
  analytics_help_sprinting: "analytics_help_sprinting",
  analytics_left_side: "analytics_left_side",
  analytics_right_side: "analytics_right_side",
  analytics_game_teams: "analytics_game_teams",
  analytics_game_score: "analytics_game_score",
  analytics_game_start: "analytics_game_start",
  analytics_game_end: "analytics_game_end",
  analytics_game_time_periods: "analytics_game_time_periods",
  analytics_game_state: "analytics_game_state",
  analytics_game_state_in_progress: "analytics_game_state_in_progress",
  analytics_game_state_failed: "analytics_game_state_failed",
  analytics_game_state_not_started: "analytics_game_state_not_started",
  analytics_game_start_mapping_team_id: "analytics_game_start_mapping_team_id",
  analytics_team_mapping: "analytics_team_mapping",
  analytics_game_state_done: "analytics_game_state_done",
  analytics_game_in_readonly_state: "analytics_game_in_readonly_state",
  analytics_game_in_readonly: "analytics_game_in_readonly",
  analytics_select_player_match_team: "analytics_select_player_match_team",
  analytics_start_analysis_help: "analytics_start_analysis_help",
  analytics_events_number: "analytics_events_number",
  analytics_events_event: "analytics_events_event",
  analytics_events_time: "analytics_events_time",
  analytics_events_details: "analytics_events_details",
  analytics_team_side_left: "analytics_team_side_left",
  analytics_team_side_right: "analytics_team_side_right",
  analytics_game_edit_time_invalid: "analytics_game_edit_time_invalid",
  games_for_analytics: "games_for_analytics",
  game_for_analytics: "game_for_analytics",
  game_edit_scissors_help: "game_edit_scissors_help",
  game_event_game: "game_event_game",
  game_event_training: "game_event_training",
  game_event_free_time: "game_event_free_time",
  game_event_game_start: "game_event_game_start",
  game_event_game_end: "game_event_game_end",
  game_event_time: "game_event_time",
  game_event_time_short: "game_event_time_short",
  game_event_time_start: "game_event_time_start",
  game_event_time_end: "game_event_time_end",
  game_event_timeout: "game_event_timeout",
  game_event_timeout_start: "game_event_timeout_start",
  game_event_timeout_end: "game_event_timeout_end",
  game_event_goal: "game_event_goal",
  game_event_overtime: "game_event_overtime",
  game_event_overtime_start: "game_event_overtime_start",
  game_event_overtime_short: "game_event_overtime_short",
  game_event_overtime_end: "game_event_overtime_end",
  game_event_penalty_series: "game_event_penalty_series",
  game_event_penalty_start: "game_event_penalty_start",
  game_event_penalty_short: "game_event_penalty_short",
  game_event_penalty_end: "game_event_penalty_end",
  game_extra_event_penalty: "game_extra_event_penalty",
  game_event_end_postfix: "game_event_end_postfix",
  add_game: "add_game",
  hardware: "hardware",
  main_dc_ac: "main_dc_ac",
  main_dc_battery: "main_dc_battery",
  state_on: "state_on",
  state_off: "state_off",
  minutes: "minutes",
  minute_number: "minute_number",
  hardware_buttons: "hardware_buttons",
  configure: "configure",
  analytics: "analytics",
  configure_chart: "configure_chart",
  stop_recording: "stop_recording",
  start_recording: "start_recording",
  stop_storage_sync: "stop_storage_sync",
  recording_to_storage: "recording_to_storage",
  operator_stream: "operator_stream",
  email_address: "email_address",
  password: "password",
  login: "login",
  register: "register",
  copyright: "copyright",
  cc_uri: "cc_uri",
  error_wrong_signin_credentials: "error_wrong_signin_credentials",
  menu_logout: "menu_logout",
  not_implemented: "not_implemented",
  camera_count: "camera_count",
  teamref_count: "teamref_count",
  online: "online",
  offline: "offline",
  critical: "critical",
  on: "on",
  off: "off",
  shutting_down: "shutting_down",
  auto: "auto",
  streaming: "streaming",
  active_epg_event: "active_epg_event",
  no_active_epg_event: "no_active_epg_event",
  stream: "stream",
  custom: "custom",
  cpu_name_j1: "cpu_name_j1",
  cpu: "cpu",
  gpu: "gpu",
  outdoor_temp: "outdoor_temp",
  internal_temp: "internal_temp",
  fan: "fan",
  shut_down_camera: "shut_down_camera",
  maintenance_mode: "maintenance_mode",
  storage_left: "storage_left",
  storage_sync: "storage_sync",
  panorama: "panorama",
  game_video: "game_video",
  team_physical_report: "team_physical_report",
  team_tactical_report: "team_tactical_report",
  details: "details",
  video_analytics: "video_analytics",
  scoreboard: "scoreboard",
  storage: "storage",
  statistics: "statistics",
  get: "get",
  post: "post",
  reload: "reload",
  error_app_failed: "error_app_failed",
  ssh_console: "ssh_console",
  shut_down: "shut_down",
  reboot: "reboot",
  turn_on: "turn_on",
  start_firmware_update: "start_firmware_update",
  error_general: "error_general",
  error_wrong_parameters: "error_wrong_parameters",
  power: "power",
  power_hw_btn: "power_hw_btn",
  reset_hw_btn: "reset_hw_btn",
  factory_reset_hw_btn: "factory_reset_hw_btn",
  start_when_cpu_more: "start_when_cpu_more",
  stop_when_cpu_less: "stop_when_cpu_less",
  critical_cpu_temp: "critical_cpu_temp",
  state: "state",
  mode: "mode",
  case_heater: "case_heater",
  source: "source",
  source_operator: "source_operator",
  source_panorama: "source_panorama",
  stream_fps: "stream_fps",
  start_when_source_less: "start_when_source_less",
  stop_when_source_more: "stop_when_source_more",
  critical_source_temp: "critical_source_temp",
  tilt_angle: "tilt_angle",
  camera_direction: "camera_direction",
  empty_metrics_data: "empty_metrics_data",
  loading_metrics: "loading_metrics",
  action_reload: "action_reload",
  chart_voltage_name: "chart_voltage_name",
  chart_cpu_load_name: "chart_cpu_load_name",
  chart_gpu_load_name: "chart_gpu_load_name",
  chart_outdoor_temperature_name: "chart_outdoor_temperature_name",
  chart_internal_temperature_name: "chart_internal_temperature_name",
  chart_dew_point_temperature_name: "chart_dew_point_temperature_name",
  chart_dc_temperature_name: "chart_dc_temperature_name",
  chart_heater_temperature_name: "chart_heater_temperature_name",
  chart_internal_humidity_name: "chart_internal_humidity_name",
  chart_cpu_power_name: "chart_cpu_power_name",
  chart_cpu_voltage_name: "chart_cpu_voltage_name",
  chart_soc_power_name: "chart_soc_power_name",
  chart_gpu_power_name: "chart_gpu_power_name",
  chart_sys5v_voltage_name: "chart_sys5v_voltage_name",
  chart_sys5v_power_name: "chart_sys5v_power_name",
  chart_vddrq_power_name: "chart_vddrq_power_name",
  chart_cv_power_name: "chart_cv_power_name",
  cv: "cv",
  vddrq: "vddrq",
  sys5v: "sys5v",
  soc: "soc",
  connection_error: "connection_error",
  session_missing_error: "session_missing_error",
  session_failed: "session_failed",
  logout_in_progress: "logout_in_progress",
  default_pi_name: "default_pi_name",
  board: "board",
  voltage: "voltage",
  camera_main_view: "camera_main_view",
  recording: "recording",
  error_latest_metrics_empty: "error_latest_metrics_empty",
  chart_cpu_temp_name: "chart_cpu_temp_name",
  chart_board_temp_name: "chart_board_temp_name",
  chart_gpu_temp_name: "chart_gpu_temp_name",
  chart_case_heater_temp_name: "chart_case_heater_temp_name",
  chart_case_heater1_temp_name: "chart_case_heater1_temp_name",
  chart_case_heater2_temp_name: "chart_case_heater2_temp_name",
  chart_case_heater3_temp_name: "chart_case_heater3_temp_name",
  chart_case_heater4_temp_name: "chart_case_heater4_temp_name",
  chart_case_heater_cam1_temp_name: "chart_case_heater_cam1_temp_name",
  chart_case_heater_cam2_temp_name: "chart_case_heater_cam2_temp_name",
  chart_case_heater_cam3_temp_name: "chart_case_heater_cam3_temp_name",
  j1name: "j1name",
  j2name: "j2name",
  ok: "ok",
  problems: "problems",
  internal: "internal",
  dew_point_short: "dew_point_short",
  outdoor: "outdoor",
  sensor_place_name_outdoor: "sensor_place_name_outdoor",
  sensor_place_name_cameraCase: "sensor_place_name_cameraCase",
  sensor_place_name_DC: "sensor_place_name_DC",
  sensor_place_name_heater: "sensor_place_name_heater",
  sensor_place_name_heater1: "sensor_place_name_heater1",
  sensor_place_name_heater2: "sensor_place_name_heater2",
  sensor_place_name_heater3: "sensor_place_name_heater3",
  sensor_place_name_heater4: "sensor_place_name_heater4",
  sensor_place_name_heater_cam1: "sensor_place_name_heater_cam1",
  sensor_place_name_heater_cam2: "sensor_place_name_heater_cam2",
  sensor_place_name_heater_cam3: "sensor_place_name_heater_cam3",
  itself: "itself",
  sensor_place_name_jetson1: "sensor_place_name_jetson1",
  sensor_place_name_jetson2: "sensor_place_name_jetson2",
  sensor_place_name_pi: "sensor_place_name_pi",
  resource_is_unreachable: "resource_is_unreachable",
  wrong_resource_name: "wrong_resource_name",
  confirm_shut_down: "confirm_shut_down",
  confirm_shut_down_jetson: "confirm_shut_down_jetson",
  confirm_reboot_jetson: "confirm_reboot_jetson",
  confirm_turn_on_jetson: "confirm_turn_on_jetson",
  confirm_start_firmware_update_jetson: "confirm_start_firmware_update_jetson",
  confirm_shut_down_pi: "confirm_shut_down_pi",
  confirm_reboot_pi: "confirm_reboot_pi",
  confirm_reset_jetson: "confirm_reset_jetson",
  confirm_power_jetson: "confirm_power_jetson",
  confirm_recovery_jetson: "confirm_recovery_jetson",
  post_camera_api_confirm: "post_camera_api_confirm",
  network_request_not_found: "network_request_not_found",
  undefined_camera_api_response_status: "undefined_camera_api_response_status",
  empty_camera_api_response: "empty_camera_api_response",
  apply: "apply",
  cancel: "cancel",
  abr_milliseconds: "abr_milliseconds",
  duration: "duration",
  confirm_actions: "confirm_actions",
  confirm_remove: "confirm_remove",
  confirm_sync_selected_video: "confirm_sync_selected_video",
  success_alert_sync_selected_video: "success_alert_sync_selected_video",
  used_volume: "used_volume",
  ssd: "ssd",
  updating: "updating",
  no_connection_to_device_off: "no_connection_to_device_off",
  no_connection_to_device: "no_connection_to_device",
  azymuth: "azymuth",
  shut_down_disclaimer: "shut_down_disclaimer",
  reboot_disclaimer: "reboot_disclaimer",
  turn_on_disclaimer: "turn_on_disclaimer",
  firmware_update_disclaimer: "firmware_update_disclaimer",
  power_source_battery: "power_source_battery",
  power_source_adapter: "power_source_adapter",
  version: "version",
  chart_power_consumption: "chart_power_consumption",
  cameras: "cameras",
  jetson_status_firmware_update: "jetson_status_firmware_update",
  camera_off: "camera_off",
  turn_odd_to_start_firmware_update: "turn_odd_to_start_firmware_update",
  camera_not_available: "camera_not_available",
  supply: "supply",
  volume: "volume",
  total: "total",
  total_power: "total_power",
  dc: "dc",
  heater: "heater",
  heater1: "heater1",
  heater2: "heater2",
  heater3: "heater3",
  heater4: "heater4",
  heater_cam1: "heater_cam1",
  heater_cam2: "heater_cam2",
  heater_cam3: "heater_cam3",
  tilt: "tilt",
  empty_metrics_warning: "empty_metrics_warning",
  hour: "hour",
  twoHours: "twoHours",
  fourHours: "fourHours",
  eightHours: "eightHours",
  halfDay: "halfDay",
  day: "day",
  output_period: "output_period",
  output_time: "output_time",
  now: "now",
  today: "today",
  ended: "ended",
  tomorrow: "tomorrow",
  every_day: "every_day",
  yesterday: "yesterday",
  unit_w: "unit_w",
  unit_v: "unit_v",
  events: "events",
  events_with_numbers: "events_with_numbers",
  event: "event",
  archive: "archive",
  history: "history",
  teams: "teams",
  team: "team",
  add_event: "add_event",
  copy: "copy",
  date: "date",
  date_from: "date_from",
  date_to: "date_to",
  game_date: "game_date",
  copy_item: "copy_item",
  remove: "remove",
  recover_remove_btn: "recover_remove_btn",
  import_epg_btn: "import_epg_btn",
  disable_btn: "disable_btn",
  enable_btn: "enable_btn",
  close_btn: "close_btn",
  remove_selected_items: "remove_selected_items",
  recover_selected_items: "recover_selected_items",
  disable_selected_items: "disable_selected_items",
  enable_selected_items: "enable_selected_items",
  show_deleted_events: "show_deleted_events",
  send_to_sync_server: "send_to_sync_server",
  refresh: "refresh",
  event_name: "event_name",
  event_name_help: "event_name_help",
  event_sub_title: "event_sub_title",
  event_sub_title_help: "event_sub_title_help",
  event_description: "event_description",
  event_description_help: "event_description_help",
  event_key_override_item: "event_key_override_item",
  event_key_override_header: "event_key_override_header",
  event_profile_nothing_override_error: "event_profile_nothing_override_error",
  event_warning: "event_warning",
  event_priority: "event_priority",
  event_priority_help: "event_priority_help",
  event_priority_low: "event_priority_low",
  event_priority_imported: "event_priority_imported",
  event_priority_base: "event_priority_base",
  event_priority_high: "event_priority_high",
  event_priority_critical: "event_priority_critical",
  event_game_type: "event_game_type",
  event_game_type_help: "event_game_type_help",
  event_game_type_game: "event_game_type_game",
  event_game_type_train: "event_game_type_train",
  event_game_type_free_time: "event_game_type_free_time",
  event_default_sport_type: "event_default_sport_type",
  event_default_sport_type_hint: "event_default_sport_type_hint",
  event_sport_type: "event_sport_type",
  event_sport_type_football: "event_sport_type_football",
  event_sport_type_hockey: "event_sport_type_hockey",
  event_sport_type_basketball: "event_sport_type_basketball",
  event_sport_type_hockey_with_ball: "event_sport_type_hockey_with_ball",
  event_sport_type_multiple_sports: "event_sport_type_multiple_sports",
  event_sport_type_handball: "event_sport_type_handball",
  event_sport_type_mini_football: "event_sport_type_mini_football",
  event_sport_type_unknown: "event_sport_type_unknown",
  event_start_date: "event_start_date",
  event_time_period: "event_time_period",
  event_end_date: "event_end_date",
  event_no_event_date: "event_no_event_date",
  event_home_team: "event_home_team",
  event_guest_team: "event_guest_team",
  event_teams_help: "event_teams_help",
  event_stream_type: "event_stream_type",
  event_stream_type_help: "event_stream_type_help",
  event_analytics_promocode: "event_analytics_promocode",
  event_analytics_promocode_hint: "event_analytics_promocode_hint",
  import_epg_notes: "import_epg_notes",
  import_epg_def_stream_type: "import_epg_def_stream_type",
  import_epg_def_stream_type_help: "import_epg_def_stream_type_help",
  import_epg_file_type: "import_epg_file_type",
  import_epg_default_game_type: "import_epg_default_game_type",
  import_epg_default_game_type_help: "import_epg_default_game_type_help",
  import_epg_default_sport_type: "import_epg_default_sport_type",
  import_epg_default_sport_type_help: "import_epg_default_sport_type_help",
  import_epg_upload_file: "import_epg_upload_file",
  import_epg_upload_file_help: "import_epg_upload_file_help",
  import_epg_upload_file_btn: "import_epg_upload_file_btn",
  import_epg_auto_import: "import_epg_auto_import",
  import_epg_auto_import_active: "import_epg_auto_import_active",
  import_epg_auto_import_disabled: "import_epg_auto_import_disabled",
  import_epg_url: "import_epg_url",
  import_epg_url_help: "import_epg_url_help",
  import_epg_update_period: "import_epg_update_period",
  import_epg_update_period_help: "import_epg_update_period_help",
  import_epg_last_import_result: "import_epg_last_import_result",
  import_epg_last_import_success: "import_epg_last_import_success",
  import_epg_last_import_error: "import_epg_last_import_error",
  stream_profile: "stream_profile",
  stream_profile_apply_progress_header: "stream_profile_apply_progress_header",
  stream_profile_apply_progress_text: "stream_profile_apply_progress_text",
  event_disabled: "event_disabled",
  event_deleted: "event_deleted",
  disabled: "disabled",
  deleted: "deleted",
  analytics_games_added_number: "analytics_games_added_number",
  analytics_uploaded: "analytics_uploaded",
  analytics_uploading: "analytics_uploading",
  analytics_analytics_progress: "analytics_analytics_progress",
  analytics_ready_analysis: "analytics_ready_analysis",
  analytics_waiting_team_mapping: "analytics_waiting_team_mapping",
  analytics_uploading_stoped: "analytics_uploading_stoped",
  analytics_waiting_markup: "analytics_waiting_markup",
  analytics_done: "analytics_done",
  analytics_state_analytics: "analytics_state_analytics",
  analytics_error: "analytics_error",
  analytics_canceled: "analytics_canceled",
  analytics_filter_by_done: "analytics_filter_by_done",
  analytics_filter_by_canceled: "analytics_filter_by_canceled",
  analytics_filter_by_failed: "analytics_filter_by_failed",
  analytics_filter_by_progress: "analytics_filter_by_progress",
  analytics_filter_by_ready_analysis: "analytics_filter_by_ready_analysis",
  analytics_filter_by_waiting_team_mapping: "analytics_filter_by_waiting_team_mapping",
  event_type: "event_type",
  event_teams: "event_teams",
  event_time: "event_time",
  event_repeat: "event_repeat",
  event_repeat_no: "event_repeat_no",
  event_repeat_every_week: "event_repeat_every_week",
  event_invalid_start_date: "event_invalid_start_date",
  event_invalid_start_date_description: "event_invalid_start_date_description",
  event_curr_duration: "event_curr_duration",
  event_invalid_max_duration: "event_invalid_max_duration",
  event_end_before_start_date: "event_end_before_start_date",
  event_status_start_in_past: "event_status_start_in_past",
  event_status_ended_event: "event_status_ended_event",
  event_status_recurring_end_in_past: "event_status_recurring_end_in_past",
  event_status_disabled_event: "event_status_disabled_event",
  weekday_short_sunday: "weekday_short_sunday",
  weekday_short_monday: "weekday_short_monday",
  weekday_short_tuesday: "weekday_short_tuesday",
  weekday_short_wednesday: "weekday_short_wednesday",
  weekday_short_thursday: "weekday_short_thursday",
  weekday_short_friday: "weekday_short_friday",
  weekday_short_saturday: "weekday_short_saturday",
  button_ok: "button_ok",
  button_accept: "button_accept",
  button_cancel: "button_cancel",
  table_rows_per_page: "table_rows_per_page",
  table_of: "table_of",
  history_event_streaming: "history_event_streaming",
  history_event_records: "history_event_records",
  team_id: "team_id",
  team_id_help: "team_id_help",
  team_id_add_help: "team_id_add_help",
  add_team_id: "add_team_id",
  team_name: "team_name",
  team_short_name: "team_short_name",
  team_logo: "team_logo",
  team_notes: "team_notes",
  team_color: "team_color",
  team_upload_new_logo: "team_upload_new_logo",
  page_streaming_configuration_title: "page_streaming_configuration_title",
  page_streaming_api_heading: "page_streaming_api_heading",
  page_video_archive_heading: "page_video_archive_heading",
  page_video_archive_tab_operator: "page_video_archive_tab_operator",
  page_video_archive_tab_panorama: "page_video_archive_tab_panorama",
  page_video_archive_tab_scoreboard: "page_video_archive_tab_scoreboard",
  page_video_archive_tab_select_all_option: "page_video_archive_tab_select_all_option",
  page_video_archive_empty_list: "page_video_archive_empty_list",
  add: "add",
  edit: "edit",
  rename: "rename",
  failed_load_data: "failed_load_data",
  stream_audio_title: "stream_audio_title",
  stream_audio_channels: "stream_audio_channels",
  stream_audio_bitrate: "stream_audio_bitrate",
  stream_audio_enable: "stream_audio_enable",
  stream_audio_sample_rate: "stream_audio_sample_rate",
  stream_audio_volume: "stream_audio_volume",
  stream_audio_control_title: "stream_audio_control_title",
  stream_audio_control_rms_peak: "stream_audio_control_rms_peak",
  stream_audio_control_attack_time: "stream_audio_control_attack_time",
  stream_audio_control_release_time: "stream_audio_control_release_time",
  stream_audio_control_threshold_level: "stream_audio_control_threshold_level",
  stream_audio_control_ratio: "stream_audio_control_ratio",
  stream_audio_control_knee_radius: "stream_audio_control_knee_radius",
  stream_audio_control_knee_makeup_gain: "stream_audio_control_knee_makeup_gain",
  stream_video_title: "stream_video_title",
  stream_video_secondary_title: "stream_video_secondary_title",
  stream_video_resolution: "stream_video_resolution",
  stream_video_resolution_width: "stream_video_resolution_width",
  stream_video_resolution_height: "stream_video_resolution_height",
  stream_video_bitrate_mbps: "stream_video_bitrate_mbps",
  stream_video_add_destination: "stream_video_add_destination",
  stream_video_store_local_file: "stream_video_store_local_file",
  stream_video_sync_to_server: "stream_video_sync_to_server",
  stream_video_panorama_source: "stream_video_panorama_source",
  stream_video_rtmp_help: "stream_video_rtmp_help",
  stream_video_rtmp_url: "stream_video_rtmp_url",
  stream_video_rtmp_key: "stream_video_rtmp_key",
  stream_video_hint_cybercamera: "stream_video_hint_cybercamera",
  stream_video_hint_youtube: "stream_video_hint_youtube",
  stream_video_hint_rutube: "stream_video_hint_rutube",
  stream_video_hint_vkvideo: "stream_video_hint_vkvideo",
  stream_video_hint_twitch: "stream_video_hint_twitch",
  stream_video_title_hint: "stream_video_title_hint",
  stream_video_youtube_login: "stream_video_youtube_login",
  stream_video_vkvideo_login: "stream_video_vkvideo_login",
  stream_video_cybercamera_login: "stream_video_cybercamera_login",
  stream_video_youtube_logout: "stream_video_youtube_logout",
  stream_video_vkvideo_logout: "stream_video_vkvideo_logout",
  stream_video_cybercamera_logout: "stream_video_cybercamera_logout",
  stream_video_stream_target: "stream_video_stream_target",
  stream_video_stream_target_hint: "stream_video_stream_target_hint",
  stream_video_stream_target_user_page: "stream_video_stream_target_user_page",
  stream_video_playlist_name: "stream_video_playlist_name",
  stream_video_playlist_name_hint: "stream_video_playlist_name_hint",
  stream_video_name_prefix: "stream_video_name_prefix",
  stream_video_name_prefix_hint: "stream_video_name_prefix_hint",
  stream_video_access_permision: "stream_video_access_permision",
  stream_video_access_permision_hint: "stream_video_access_permision_hint",
  stream_access_permision_all: "stream_access_permision_all",
  stream_access_permision_members: "stream_access_permision_members",
  stream_access_permision_payed: "stream_access_permision_payed",
  stream_access_permision_admin: "stream_access_permision_admin",
  stream_access_permision_by_link: "stream_access_permision_by_link",
  sort_by: "sort_by",
  sort_by_time: "sort_by_time",
  sort_asc: "sort_asc",
  sort_desc: "sort_desc",
  filter_by_team: 'filter_by_team',
  filter_by_analytics_status: 'filter_by_analytics_status',
  filter_no_filter: 'filter_no_filter',
  filter_by_date: 'filter_by_date',
  filter_by_date_off: 'filter_by_date_off',
  filter_by_camera: 'filter_by_camera',
  filter_by_all_camera: 'filter_by_all_camera',
  filter_by_all_teams: 'filter_by_all_teams',
  user_popup_title: "user_popup_title",
  user_name: "user_name",
  user_group: "user_group",
  user_visible_cameras: "user_visible_cameras",
  user_visible_teamrefs: "user_visible_teamrefs",
  user_last_login: "user_last_login",
  page_user_title_web_admin: "page_user_title_web_admin",
  page_user_title_server_web_admin: "page_user_title_server_web_admin",
  user_login: "user_login",
  user_server_login: "user_server_login",
  user_password: "user_password",
  user_confirm_password: "user_confirm_password",
  user_confirm_incorrect_password: "user_confirm_incorrect_password",
  enter_confirm_password: "enter_confirm_password",
  user_old_password: "user_old_password",
  passwords_do_not_match: "passwords_do_not_match",
  password_restrictions: "password_restrictions",
  add_account: "add_account",
  users_filter_camera_label: "users_filter_camera_label",
  users_filter_teamref_label: "users_filter_teamref_label",
  cant_delete_your_account: "cant_delete_your_account",
  invalid_current_password: "invalid_current_password",
  password_will_be_generated_and_send_to_login_email_address: "password_will_be_generated_and_send_to_login_email_address",
  cameras_option_title_all: "cameras_option_title_all",
  cameras_option_title_root_access: "cameras_option_title_root_access",
  teamref_option_title_all: "teamref_option_title_all",
  teamref_option_title_root_access: "teamref_option_title_root_access",
  cameras_option_title_selected_list: "cameras_option_title_selected_list",
  cameras_option_title_none: "cameras_option_title_none",
  cameras_option_title_guest: "cameras_option_title_guest",
  password_reset: "password_reset",
  password_reset_successful: "password_reset_successful",
  invalid_server_login: "invalid_server_login",
  value_incorrect: "value_incorrect",
  filtered_data_empty: "filtered_data_empty",
  cameraError: "cameraError",
  cameraOnlineWarning: "cameraOnlineWarning",
  cameraOnlineCritical: "cameraOnlineCritical",
  unknown: "unknown",
  unknown_short: "unknown_short",
  saveToStorage: "saveToStorage",
  save: "save",
  camerasWarningCount: "camerasWarningCount",
  camerasErrorCount: "camerasErrorCount",
  storage_sync_short: "storage_sync_short",
  active_camera: "active_camera",
  no_active_camera: "no_active_camera",
  active_teamref: "active_teamref",
  no_active_teamref: "no_active_teamref",
  select_camera_as_active: "select_camera_as_active",
  camera_name: "camera_name",
  camera_address_pi: "camera_address_pi",
  camera_address_j1: "camera_address_j1",
  camera_address_j2: "camera_address_j2",
  camera_type: "camera_type",
  camera_type_hint: "camera_type_hint",
  camera_technical_info: "camera_technical_info",
  camera_notes: "camera_notes",
  home_address: "home_address",
  optional: "optional",
  storage_general: "storage_general",
  storage_free: "storage_free",
  storage_remove_old_video_when_storage_less_then_gb: "storage_remove_old_video_when_storage_less_then_gb",
  current_sync_speed_in_b_sec: "current_sync_speed_in_b_sec",
  wait: "wait",
  files_in_sync_queue_in_gb: "files_in_sync_queue_in_gb",
  clear_sync_queue: "clear_sync_queue",
  default_sync_speed_mbit: "default_sync_speed_mbit",
  sync_speed_when_streaming_active_mbit: "sync_speed_when_streaming_active_mbit",
  sync_speed_when_low_speed_network_mbit: "sync_speed_when_low_speed_network_mbit",
  maximum_sync_speed_mbit: "maximum_sync_speed_mbit",
  maximum_sync_speed_in: "maximum_sync_speed_in",
  select_streaming_settings: "select_streaming_settings",
  no_stream: "no_stream",
  stop_streaming: "stop_streaming",
  'rtmp:connecting': 'rtmp:connecting',
  'rtmp:reconnecting': 'rtmp:reconnecting',
  'rtmp:disconnected': 'rtmp:disconnected',
  'rtmp:failed': 'rtmp:failed',
  'rtmp:lastDataDrop': 'rtmp:lastDataDrop',
  'mp4:connecting': 'mp4:connecting',
  'mp4:reconnecting': 'mp4:reconnecting',
  'mp4:disconnected': 'mp4:disconnected',
  'mp4:failed': 'mp4:failed',
  'mp4:lastDataDrop': 'mp4:lastDataDrop',
  oldest_first: "oldest_first",
  newest_first: "newest_first",
  any: "any",
  cameras_list_statuses_critical: "cameras_list_statuses_critical",
  cameras_list_statuses_warning: "cameras_list_statuses_warning",
  cameras_list_statuses_error: "cameras_list_statuses_error",
  cameras_list_statuses_off: "cameras_list_statuses_off",
};

export default LOCALIZATION;
