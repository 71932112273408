// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Card from '../../../Components/_Layout/Card';
import Popup from '../../../Components/_Layout/Popup';
import Spinner from '../../../Components/_BaseUI/Spinner/Spinner';
import FieldSet, { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';
import LOCALIZATION from '../../../Localization';
import StreamAutocompleteSelect from '../../../Components/_AutocompleteSelect/StreamAutocompleteSelect';
import useMutationEvent, { ActionType } from '../../../Data/EPG/EpgSchedule/fetch/useMutation';
import { flushNapiStreamingSettingsCache } from '../../../Data/NapiStreamingSettings/fetch';
import { useNapiStreamingSettingsFetch } from '../../../Data/NapiStreamingSettings/hook';
import { isNaN } from 'lodash';
import { StreamingSettings } from '../../../Data/NapiStreamingSettings';
import moment from 'moment';
import { useAppConfig } from '../../../Localization/AppContextProvider/helpers';
import { StaticLabelTypes } from '../../../Components/_BaseUI/FieldSet/FieldSetStaticLabel';
import { EPGFormatType, EPGFormatTypeArr, localizedEPGFormatType } from '../../../Data/EPG/EpgSchedule/fetch';
import { GameType, SportType } from '../../../Data/EPG/EpgSchedule/EventWithoutID';
import { GAME_TYPE_ID_ARR, localizedGameTypeById, localizedSportNameById, SPORT_ID_ARR } from '../AddEvent/helpers';

type Props = Readonly<{
  streamProfileID?: string;
  // page: number;
  onSubmit: () => void;
  onClose: () => void;
} & WrappedComponentProps>;

const ImportEpgPopup: React.FC<Props> = (props: Props) => {    
  const { localization: { locale } } = useAppConfig();

  const { mutateAsync: mutateConfig } = useMutationEvent();

  const [streamProfileID, setStreamProfileID] = React.useState<string | null | undefined>(undefined);
  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);

  const [autoImportUrl, setAutoImportUrl] = React.useState<string | null>(null);
  const [autoImportPeriodHours, setAutoImportPeriodHours] = React.useState<number | null | undefined>(undefined);
  const [streamingSettings, setStreamingSettings] = React.useState<StreamingSettings>();
  const [epgFormatType, setEpgFormatType] = React.useState<EPGFormatType | null>(null);
  const [epgDefaultGameType, setEpgDefaultGameType] = React.useState<GameType | null>(null);
  const [epgDefaultSportType, setEpgDefaultSportType] = React.useState<SportType | null>(null);
 

  // streaming/settings.json load
  const streamingSettingsFetchState = useNapiStreamingSettingsFetch();
  React.useEffect(() => {
    if (streamingSettingsFetchState.status === 'success') {
      const settings = streamingSettingsFetchState.data?.data;
      if (settings && !streamingSettings) {
        setStreamingSettings(settings);
        
        // always set value. because else autoset from checkbox will do this
        setStreamProfileID(settings.epg_auto_import_streaming_profile_id);
        
        setEpgFormatType(settings.epg_auto_import_format_type as EPGFormatType);
        setEpgDefaultGameType(settings.epg_auto_import_default_game_type as GameType);
        setEpgDefaultSportType(settings.epg_auto_import_default_sport_type as SportType);

        if (!autoImportUrl && settings.epg_auto_import_url) {
          setAutoImportUrl(settings.epg_auto_import_url);
        }
        if (autoImportPeriodHours === undefined) {
          const perioadSec = parseInt(settings.epg_auto_import_period_sec || "0", 10);
          if (!isNaN(perioadSec)) {
            const periodInHours = Math.round((perioadSec / 60.0 / 60.0) * 10) / 10.0;
            setAutoImportPeriodHours(periodInHours);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [streamingSettingsFetchState.status, streamingSettingsFetchState.data?.data]);

  // clear cache
  React.useEffect(() => {
    return (
      () => {
        flushNapiStreamingSettingsCache();
      }
    );
  }, []);

  const isApplyDisabled = false;
  const handleSubmit = async () => {
    setIsLoaderShowing(true);
    try {
      await mutateConfig(
        {
          type: ActionType.ImportEpg,
          streamingProfileId: streamProfileID,
          // import by URL
          epgFileDataUrl: autoImportUrl || null,
          epgFileUpdatePeriodInSec: (autoImportPeriodHours || 0) * 60 * 60, // hours to sec
          epgFormatType: epgFormatType,
          epgDefaultGameType: epgDefaultGameType,
          epgDefaultSportType: epgDefaultSportType,
        }, {
          onSuccess: () => {
            props.onSubmit();
          },
        },
      );
    } catch (error) {
      alert(error);
      props.onClose();
    }
  };
  const handleSetEpgInBase64 = async (epgInBase64?: string) => {
    setIsLoaderShowing(true);
    try {
      await mutateConfig(
        {
          type: ActionType.ImportEpg,
          streamingProfileId: streamProfileID,
          // import by file in base64
          epgFileDataInBase64: epgInBase64,
          epgFormatType: epgFormatType,
          epgDefaultGameType: epgDefaultGameType,
          epgDefaultSportType: epgDefaultSportType,
        }, {
          onSuccess: () => {
            props.onSubmit();
          },
        },
      );
    } catch (error) {
      alert(error);
      props.onClose();
    }
  };

  const lastImportResultStr: string | undefined = (() => {
    if (streamingSettings) {
      var successAt = (streamingSettings.epg_auto_import_by_url_last_success_date_at) ? moment(streamingSettings.epg_auto_import_by_url_last_success_date_at) : undefined;
      var errorAt = (streamingSettings.epg_auto_import_by_url_last_error_date_at) ? moment(streamingSettings.epg_auto_import_by_url_last_error_date_at) : undefined;
      if (successAt && errorAt) {
        if (successAt.isBefore(errorAt)) {
          successAt = undefined;
        }
        else {
          errorAt = undefined;
        }
      }

      if (successAt) {
        successAt.locale(locale);
        const diff = successAt.diff(moment());
        const duration = moment.duration(diff).locale(locale);
        return `${props.intl.formatMessage({ id: LOCALIZATION.import_epg_last_import_success })} ${duration.humanize(true).capitalizeFirstLetter()} (${successAt.format('llll').capitalizeFirstLetter()})`;
      }
      else if (errorAt) {
        errorAt.locale(locale);
        const diff = errorAt.diff(moment());
        const duration = moment.duration(diff).locale(locale);
        return `${props.intl.formatMessage({ id: LOCALIZATION.import_epg_last_import_error })} ${duration.humanize(true).capitalizeFirstLetter()} (${errorAt.format('llll').capitalizeFirstLetter()})`;
      }
    }
    return undefined;
  })()

  var activeImportStateText = props.intl.formatMessage({ id: LOCALIZATION.import_epg_auto_import_disabled });
  var activeImportState: StaticLabelTypes = "error";
  if (streamingSettings && streamingSettings.epg_auto_import_url && streamingSettings.epg_auto_import_period_sec) {
    if (streamingSettings.epg_auto_import_url.length !== 0) {
      const perioadSec = parseInt(streamingSettings.epg_auto_import_period_sec, 10);
      if (!isNaN(perioadSec) && perioadSec > 60) {
        activeImportStateText = props.intl.formatMessage({ id: LOCALIZATION.import_epg_auto_import_active });
        activeImportState = "warning";
      }
    }
  }


  return (
    <Popup
      isWide
      headerTitle={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_btn }) }
      isApplyDisabled={ (isApplyDisabled || isLoaderShowing) }
      isCloseDisabled={ isLoaderShowing }
      closeTitle={ props.intl.formatMessage({ id: LOCALIZATION.close_btn }) }
      onApply={ !isLoaderShowing ? handleSubmit : undefined }
      onClose={ props.onClose }
    >
      <Grid
        item
        xs={ 12 }
      >
        <Card>
          {
            (isLoaderShowing) ?
            <>
            <br /><br /><Spinner /><br /><br />
            </>
              :
              <form
                action={ window.location.href }
                encType={ 'multipart/form-Data' }
                onSubmit={ handleSubmit }
              >
                <FieldSet
                  key={ 'Notes' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_notes }) }
                />
                <FieldSet
                  key={ 'Stream' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_def_stream_type }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_def_stream_type_help }) }                    
                  input={ {
                    type: FieldSetInputType.Other,
                    children: (
                      <StreamAutocompleteSelect
                        selectedStreamProfileID={ streamProfileID }
                        autoselectFirstIfNoId={ (!props.streamProfileID) }
                        onSelect={ (streamProfileID) => ( setStreamProfileID(streamProfileID) ) }
                      />
                    ),
                  } }
                />
                <FieldSet
                  key={ 'Default Game Type' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_default_game_type }) }
                  // helperText={ 'Short team name' }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_default_game_type_help }) }
                  input={ {
                    type: FieldSetInputType.Select,
                    value: (epgDefaultGameType) ? epgDefaultGameType as string : GameType.FreeTime,
                    options: GAME_TYPE_ID_ARR.map((gameTypeId) => ( {id: gameTypeId, name: localizedGameTypeById(gameTypeId) } )),
                    onChange: (gameType: string) => ( setEpgDefaultGameType(gameType as GameType)),
                  } }
                />
                <FieldSet
                  key={ 'Default Sport Type' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_default_sport_type }) }
                  // helperText={ 'Short team name' }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_default_sport_type_help }) }
                  input={ {
                    type: FieldSetInputType.Select,
                    value: (epgDefaultSportType) ? epgDefaultSportType as string : SportType.Unknown,
                    options: SPORT_ID_ARR.map((sportId) => ( {id: sportId, name: localizedSportNameById(sportId) } )),
                    onChange: (sportType: string) => ( setEpgDefaultSportType(sportType as SportType) ),
                  } }
                />
                <FieldSet
                    key={ 'Import File Type' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_file_type }) }
                    // helperText={ 'Short team name' }
                    input={ {
                      type: FieldSetInputType.Select,
                      value: (epgFormatType) ? epgFormatType as string : EPGFormatType.Xmltv,
                      options: EPGFormatTypeArr.map((epgType) => ( {id: epgType, name: localizedEPGFormatType(epgType) } )),
                      onChange: (type: string) => ( setEpgFormatType(type as EPGFormatType) ),
                    } }
                />
                <FieldSet
                  key={ 'Import File' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_upload_file }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_upload_file_help })}
                  input={ {
                    type: FieldSetInputType.File,
                    fileFormatCheckType: ["text/xml", "application/json"],
                    invalidFileFormatMsg: props.intl.formatMessage({ id: LOCALIZATION.import_epg_upload_file_btn }),
                    buttonText: props.intl.formatMessage({ id: LOCALIZATION.import_epg_upload_file_btn }),
                    onChange: handleSetEpgInBase64,
                  } }
                />
                <FieldSet
                  key={ 'AutoImportState' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_auto_import }) }
                  // helperText={ 'Team name' }
                  input={ {
                    type: FieldSetInputType.StaticLabel,
                    valueType: activeImportState,
                    value: activeImportStateText,
                  } }
                />
                <FieldSet
                  key={ 'AutoImportUrl' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_url }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_url_help }) }
                  input={ {
                    type: FieldSetInputType.Text,
                    value: autoImportUrl || "",
                    onChange: (url: string) => ( setAutoImportUrl(url) ),
                  } }
                />
                <FieldSet
                  key={ 'AutoImportPeriod' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_update_period }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_update_period_help }) }
                  input={ {
                    type: FieldSetInputType.Float,
                    value: (autoImportPeriodHours === undefined) ? 24 : (autoImportPeriodHours || 0),
                    min: 0,
                    max: 168, // 7 days
                    onChange: (period: number | null) => ( setAutoImportPeriodHours(period) ),
                  } }
                />
                { (lastImportResultStr) &&
                  <FieldSet
                    key={ 'LastDownloadResult' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_last_import_result }) }
                    input={ {
                      type: FieldSetInputType.StaticLabel,
                      value: lastImportResultStr,
                    } }
                  />
                }
              </form>
          }
        </Card>
      </Grid>
    </Popup>
  );
};


export default injectIntl(ImportEpgPopup);
