// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';

import AnyChildren from '../Tools/AnyChildren';
import { PlatformConfiguration } from '../Configuration/PlatformConfiguration';


type Props = Readonly<{
  children: AnyChildren;
}>;


const MenuContainer = (props: Props) => (
  <Grid
    sx={ {
      width: PlatformConfiguration.menuWidth,
      overflow: 'auto',
    } }
  >
    { props.children }
  </Grid>
);


export default MenuContainer;
