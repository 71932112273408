// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { MeteoGrade, MeteoInterface } from './Types'

function combineMeteoGrades(gr1: MeteoGrade, gr2: MeteoGrade): MeteoGrade {
    const meteoWeight = (grade: MeteoGrade) => {
        switch (grade) {
            case 'awefull':
                return 0
            case 'bad':
                return 1
            case 'normal':
                return 2
            case 'good':
                return 3
            case undefined:
                return -1
        }
    }
    const gr1Weight = meteoWeight(gr1)
    const gr2Weight = meteoWeight(gr2)

    if (gr1Weight < gr2Weight && gr1Weight >= 0) {
        return gr1
    } else if (gr2Weight < gr1Weight && gr2Weight >= 0) {
        return gr2
    } else {
        return gr1 ?? gr2
    }
}

// Параметры комрфортной внутренней температуры и влажности для электроники на собственном опыте.
export function getInternalMeteoGrade(meteo: MeteoInterface): MeteoGrade {
    const temp = meteo.temperature

    const humidityGrade: (tempGrade: MeteoGrade) => MeteoGrade = (tempGrade: MeteoGrade) => {
        const humidity = meteo.humidity

        if (humidity !== undefined) {
            if (humidity < 0) { return undefined } // типа если меньше нуля - какая-то ошибка.
            if (humidity < 10) { return combineMeteoGrades(tempGrade, 'normal') }
            if (humidity < 40) { return combineMeteoGrades(tempGrade, 'good') }
            if (humidity < 60) { return combineMeteoGrades(tempGrade, 'normal') }
            if (humidity < 80) { return combineMeteoGrades(tempGrade, 'bad') }
            if (humidity < 100) { return combineMeteoGrades(tempGrade, 'awefull') }
        }

        return tempGrade
    }

    if (temp !== undefined) {
        if (temp < 0) { return humidityGrade('awefull') }
        if (temp < 10) { return humidityGrade('bad') }
        if (temp < 30) { return humidityGrade('normal') }
        if (temp < 50) { return humidityGrade('good') }
        if (temp < 75) { return humidityGrade('normal') }
        if (temp < 100) { return humidityGrade('bad') }

        return 'awefull'
    }

    return humidityGrade(undefined)
}

export function getOutdoorMeteoGrade(meteo: MeteoInterface): MeteoGrade {
    const temp = meteo.temperature

    const humidityGrade: (tempGrade: MeteoGrade) => MeteoGrade = (tempGrade: MeteoGrade) => {
        const humidity = meteo.humidity

        if (humidity !== undefined) {
            if (humidity < 0) { return undefined } // типа если меньше нуля - какая-то ошибка.
            if (humidity < 10) { return combineMeteoGrades(tempGrade, 'bad') }
            if (humidity < 40) { return combineMeteoGrades(tempGrade, 'normal') }
            if (humidity < 60) { return combineMeteoGrades(tempGrade, 'good') }
            if (humidity < 80) { return combineMeteoGrades(tempGrade, 'normal') }
            if (humidity < 100) { return combineMeteoGrades(tempGrade, 'bad') }
        }

        return tempGrade
    }

    if (temp !== undefined) {
        if (temp < -20) { return humidityGrade('awefull') }
        if (temp < 0) { return humidityGrade('bad') }
        if (temp < 15) { return humidityGrade('normal') }
        if (temp < 25) { return humidityGrade('good') }
        if (temp < 35) { return humidityGrade('normal') }
        if (temp < 40) { return humidityGrade('bad') }

        return 'awefull'
    }
}
