// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

type SortingDirection = 'desc' | 'asc'
type SortingField = 'whenCreated'

export interface SortingInterface {
    direction: SortingDirection
    field: SortingField
}

export const recentSorting: SortingInterface = {
    direction: 'desc',
    field: 'whenCreated'
}

export type BaseCommandMethod = 'GET' | 'POST' | 'PATCH'
export type BaseCommandScope = 'signin' | 'create_app' | 'cameras' | 'metrics' | 'logout' | 'refresh_session' |
  'camera_settings' | 'camera_api' | 'press_actions' | 'users' | 'cameras-states' | 'current_user' | 'teamref';

export class BaseCommand {
    constructor(public path: string,
        public scope: BaseCommandScope,
        public method: BaseCommandMethod = 'GET',
        public params: any = null,
        public data: any = null,
        public isAuthorisationRequired: boolean = true) {
    }
}
