// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { JetsonId } from '../../../../../Data/Camera/Jetson/Types'
import { HardwareJetsonCardDefaultMetricsSource } from '../../../../../Data/Camera/Metrics/Sources/Collection'
import ConfigurableMetricsChartView from '../../../../../Components/_ChartsAndBars/ConfigurableMetricsChartView'

export default function JetsonChartView(props: { id: JetsonId }) {
    const id = props.id
    const defaultSource = HardwareJetsonCardDefaultMetricsSource(id)

    return (
        <ConfigurableMetricsChartView defaultSource={defaultSource}/>
    )
}
