// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import packageJson from '../../package.json'
import { getJavaBackend } from './ENV';


export namespace PlatformConfiguration {
    type Platform = 'WEB'

    export const siteUrl = (): string => {
        try {
            if (window.location.hostname) {
                const index = window.location.hostname.lastIndexOf("cybercamera.");
                if (index !== -1) {
                    const hostName = window.location.hostname.substring(index);
                    if (hostName) {
                        return `https://${ hostName }`;
                    }
                }
            }
        }
        catch(ignore) {            
        }

        return 'https://cybercamera.ru'
    }
    export const apiKey = '102626f7e6c943e18cf37b8ec684a5a0'
    export const apiVersion = '1'
    export const platform: Platform = 'WEB'
    export const host: string = window.location.hostname
    export const baseApiUrl: string = getJavaBackend();

    export const appName: string = packageJson.name ?? ''
    export const appVersion: string = packageJson.version ?? ''

    export function isTestEnvironment(): boolean { return true }

    export const metricsSample = 60000

    export const settingsUpdatesTimeInterval = 5000
    export const camerasUpdatesTimeInterval = 5000
    export const metricsDataTimeoutInterval = 10000
    export const chartsUpdateTimeInterval = metricsSample
    export const gpioActionsUpdatesTimeInterval = 60000
    export const stopServicesDelay = 1000

    export const menuWidth = 240
    // Система будет пытаться отображать примерно такое количество точек
    export const expectedMetricsPerGraphic = 480
    export const minimumMetricsPerChart = 120

    export const defaultHardwareButtonsTime = 1000
}
