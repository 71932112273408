// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {  SxProps, Theme, Typography } from '@mui/material';

import AnalyticsGameArchive, { AnalyticsGameShared } from '../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import Tabs, { TabItemInterface } from '../../../Components/_Layout/Tabs';
import LOCALIZATION from '../../../Localization';
import VertGrid from '../../../Components/_Layout/VertGrid';
import { VideoPlayerCommands } from '../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import useFetchAllPagesTeamRefs from '../../../Data/Analytics/AnalyticsTeamRef/hook';
import { ResultStatus } from '../../../Data/_Networking/ReactQuery/template';
import Spinner from '../../../Components/_BaseUI/Spinner/Spinner';
import { AnalyticsHorizBarVisualiseMode, createBarLineChartConfigForPerMinuteReportDetails } from './AnalyticsMinutesLineChartView/helper';
import { analyticsAnalyticsConvertedDataTypeName, analyticsConvertAllData, AnalyticsConvertedDataType } from '../../../Data/Analytics/AnalyticsConverters';
import AnalyticsMinutesLineChartView from './AnalyticsMinutesLineChartView';
import { useAnalyticsStatDistancePerMinute } from '../../../Data/Analytics/AnalyticsStatDistancePerMinute/hook';
import { useAnalyticsStatSpeedPerMinute } from '../../../Data/Analytics/AnalyticsStatSpeedPerMinute/hook';
import { useAnalyticsStatPossessionPerMinute } from '../../../Data/Analytics/AnalyticsStatPossessionPerMinute/hook';
import { AnalyticsGameEvent } from '../../../Data/Analytics/AnalyticsGameEvent/AnalyticsGameEvent';

export type TabItemDetails = Readonly<{
  getTabContent: (() => JSX.Element | null) | undefined,
} & TabItemInterface>;

export type AnalyticsMintesTabsCommands = Readonly<{
  updateVideoPlaybackPos: (videoPlaybackPosSec?: number)=> void
}>;

type Props = Readonly<{
  gameInfo: AnalyticsGameArchive | undefined
  sx?: SxProps<Theme>;
  team1Name: string  | undefined;
  team2Name: string  | undefined;
  videoPlayerCommands: VideoPlayerCommands | undefined
  events: AnalyticsGameEvent[] | undefined;
  onCommandsUpdate?: (commands: AnalyticsMintesTabsCommands | undefined) => void
} & WrappedComponentProps>;

const AnalyticsMintesTabsCard: React.FC<Props> = (props: Props) => {
  const [videoPlaybackPosSec, setVideoPlaybackPosSec] = React.useState<number | undefined>(undefined);

  const apiCommands: AnalyticsMintesTabsCommands = {
    updateVideoPlaybackPos(videoPlaybackPosSec) {
      setVideoPlaybackPosSec(videoPlaybackPosSec)
    },
  }
  React.useEffect(() => {
    props.onCommandsUpdate?.(apiCommands)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gameId = props.gameInfo?.id || ""

  const { data: distancePerMinuteStat } = useAnalyticsStatDistancePerMinute({ gameId });
  const { data: speedPerMinuteStat } = useAnalyticsStatSpeedPerMinute({ gameId });
  const { data: possessionPerMinuteStat } = useAnalyticsStatPossessionPerMinute({ gameId });

  const {
    getTeamRefByExternalId,
    status: teamRefStatus,
  } = useFetchAllPagesTeamRefs();

  const teamName = (teamId: string | undefined) => {
    return getTeamRefByExternalId?.(teamId)?.name || teamId || ""
  }
  const teamShortName = (teamId: string | undefined) => {
    return getTeamRefByExternalId?.(teamId)?.shortName || teamId || ""
  }

  const team1Name = teamName(props.gameInfo?.team1);
  const team2Name = teamName(props.gameInfo?.team2);

  const {data} = createBarLineChartConfigForPerMinuteReportDetails(
    props.intl,
    possessionPerMinuteStat,
    distancePerMinuteStat,
    speedPerMinuteStat,
  )

  const convertedData = analyticsConvertAllData(
    team1Name,
    team2Name,
    undefined,
    null,//possessionPerMinuteStat?.game_stat,
    null,//distancePerMinuteStat?.game_stat,
    null,//speedPerMinuteStat?.game_stat,
  )

  const TABS: TabItemDetails[] = [
    {
      id: 0,
      title: props.intl.formatMessage({ id: LOCALIZATION.analytics_possession }),
      Content: AnalyticsMinutesLineChartView,
      getTabContent() {
          const mainDataIndex = AnalyticsConvertedDataType.possession;
          return <AnalyticsMinutesLineChartView
            events={ props.events }
            videoPlayerPosSec= { videoPlaybackPosSec }
            videoPlayerCommands={ props.videoPlayerCommands }
            visualiseMode={ AnalyticsHorizBarVisualiseMode.Line }
            value1Name={ teamShortName(props.gameInfo?.team1)  } 
            value2Name={ teamShortName(props.gameInfo?.team2)  } 
            value1Color={ AnalyticsGameShared.team1Color }
            value2Color={ AnalyticsGameShared.team2Color }
            data= {data}        
            mainDataIndex={ mainDataIndex }
            mainDataIndexName={ analyticsAnalyticsConvertedDataTypeName(mainDataIndex, convertedData[mainDataIndex]) }
            // secondDataIndex={ secondDataIndex }
            // secondDataIndexName={ secondDataIndexName }
        />
      },
    },
    {
      id: 1,
      title: props.intl.formatMessage({ id: LOCALIZATION.analytics_distance_covered }),
      Content: AnalyticsMinutesLineChartView,
      getTabContent() {
          const mainDataIndex = AnalyticsConvertedDataType.distanceCovered;
          return <AnalyticsMinutesLineChartView
            events={ props.events }
            videoPlayerPosSec= { videoPlaybackPosSec }
            videoPlayerCommands={ props.videoPlayerCommands }
            visualiseMode={ AnalyticsHorizBarVisualiseMode.Line }
            value1Name={ teamShortName(props.gameInfo?.team1)  } 
            value2Name={ teamShortName(props.gameInfo?.team2)  } 
            value1Color={ AnalyticsGameShared.team1Color }
            value2Color={ AnalyticsGameShared.team2Color }
            data= {data}        
            mainDataIndex={ mainDataIndex }
            mainDataIndexName={ analyticsAnalyticsConvertedDataTypeName(mainDataIndex, convertedData[mainDataIndex]) }
            // secondDataIndex={ secondDataIndex }
            // secondDataIndexName={ secondDataIndexName }
        />
      },
    },
    {
      id: 2,
      title: props.intl.formatMessage({ id: LOCALIZATION.analytics_avg_speed }),
      Content: AnalyticsMinutesLineChartView,
      getTabContent() {
          const mainDataIndex = AnalyticsConvertedDataType.avgSpeed;
          return <AnalyticsMinutesLineChartView
            events={ props.events }
            videoPlayerPosSec= { videoPlaybackPosSec }
            videoPlayerCommands={ props.videoPlayerCommands }
            visualiseMode={ AnalyticsHorizBarVisualiseMode.Line }
            value1Name={ teamShortName(props.gameInfo?.team1)  } 
            value2Name={ teamShortName(props.gameInfo?.team2)  } 
            value1Color={ AnalyticsGameShared.team1Color }
            value2Color={ AnalyticsGameShared.team2Color }
            data= {data}        
            mainDataIndex={ mainDataIndex }
            mainDataIndexName={ analyticsAnalyticsConvertedDataTypeName(mainDataIndex, convertedData[mainDataIndex]) }
            // secondDataIndex={ secondDataIndex }
            // secondDataIndexName={ secondDataIndexName }
        />
      },
    },
  ];
  const [selectedTabID, setSelectedTabID] = React.useState<number>(TABS[0].id);

  const renderContent = () => (
    TABS.map((tab: TabItemDetails) => {
      const hidden = (selectedTabID !== tab.id);

      if (hidden) { return null; }
      
      const getTabContent = tab.getTabContent;
      if (!getTabContent) { return null; }

      return (
        <Typography
          key={ tab.id }
          component="div"
          role="tabpanel"
          // hidden={ hidden }
          id={ `simple-tab-${tab.id}` }
          aria-labelledby={ `simple-tabpanel-${tab.id}` }
          sx={{
            minHeight: 0,
            marginTop: 1,
          }}
        >
          { getTabContent() }
        </Typography>
      );
    })
  );

  if (teamRefStatus === ResultStatus.Loading) {
    return (
      <>
      <br /><br /><Spinner /><br /><br />
      </>
    )
  }

  return (
    <VertGrid
      sx={{
        height: "100%",
        ...props.sx,
      }}
    >
      <Tabs
        items={ TABS }
        selectedTabID={ selectedTabID }
        onChangeTab={ setSelectedTabID }
      />
      { renderContent() }
    </VertGrid>
  );
};


export default injectIntl(AnalyticsMintesTabsCard);
