// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import fetchJSONData from '../../../_Networking/ReactQuery/fetch';
import { ANALYTICS_BACKEND_URL } from '../../../../constants';
import { queryCache, queryClient } from '../../../../AppProviders';
import ResponseSingle from '../../../_Networking/ReactQuery/ResponseSingle';
import { AnalyticsStatPossessionPerMinute } from '../AnalyticsStatPossessionPerMinute';
import { AnalyticsStatAction } from '../../AnalyticsTypes';

type FetchProps = Readonly<{
  gameId: string;
  action?: AnalyticsStatAction;
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'analytics-stat-possession-per-minute';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.gameId,
  props.action,
]);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<ResponseSingle<AnalyticsStatPossessionPerMinute>>(getKey(props))
);

const flushCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};

const fetchData = async (props: FetchProps): Promise<ResponseSingle<AnalyticsStatPossessionPerMinute>> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  let params = `?game_id=${ props.gameId }`;

  if (props.action) {
    params += `&action=${ props.action }`;
  }

  return await fetchJSONData<ResponseSingle<AnalyticsStatPossessionPerMinute>>(
    `${ANALYTICS_BACKEND_URL}/statistics/possession_per_min${ params }`,
    {
      method: 'GET',
    }
  );
};


export type UseFetchProps = Readonly<{
  enableToFetch?: boolean;
} & FetchProps>;

const useFetchStatPossessionPerMinute = (props: UseFetchProps): UseQueryResult<ResponseSingle<AnalyticsStatPossessionPerMinute>> => {
  const { enableToFetch = true } = props;
  const queryKey = getKey(props);

  return useQuery<ResponseSingle<AnalyticsStatPossessionPerMinute>>({
    queryKey,
    queryFn: () => fetchData({
      gameId: props.gameId,
      action: props.action,
    }),
    enabled: !!enableToFetch,
  });
};


export {
  getKey,
  useFetchStatPossessionPerMinute,
  getCached,
  flushCache,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
