// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { PlatformConfiguration } from '../../../../Configuration/PlatformConfiguration'
import {
    CameraOperationMode,
    CameraSettingsPatchInfoInterface,
    HeaterTemperatureControllerDataInterface,
    HeaterTemperatureControllerFormInterface,
    TemperatureControllerDataInterface,
    TemperatureControllerFormInterface
} from '../CameraSettingsTemperature'
import { CameraSettingsInterface } from '../CameraSettings';
import { CameraInstance, CameraInterface, CameraStatus } from '../Camera';
import { ObservableDataProvider } from '../../../Common/ObservableDataProvider'
import { JetsonId } from '../../Jetson/Types'
import CameraSettingsApiService from './Networking'
import { HeaterId, HeaterIdAllSelf, SensorPlace } from '../../Metrics/Types'
import CameraContext from './Context'
import { removeCameraSettingsCache, setCameraSettingsToCache } from '.';

export interface CameraDataProviderInterface {
    readonly camera: CameraInterface

    softShutDownCamera(): Promise<void>
    setMode(mode: CameraOperationMode): Promise<void>
    setJetsonTemperatureControllerData(id: JetsonId, data: TemperatureControllerFormInterface): Promise<TemperatureControllerDataInterface>
    setHeaterTemperatureControllerData(id: SensorPlace, data: HeaterTemperatureControllerFormInterface): Promise<HeaterTemperatureControllerDataInterface|undefined>
    setTemperatureControllerData(id: SensorPlace, data?: TemperatureControllerFormInterface): Promise<TemperatureControllerFormInterface|undefined>
}

export class CameraDataProvider extends ObservableDataProvider<CameraInterface> implements CameraDataProviderInterface {
    private apiService = new CameraSettingsApiService()
    data?: CameraInstance
    activeHeaterIdArr?: HeaterId[]
    get camera(): CameraInstance {
        return this.data!
    }

    private internalStatus?: CameraStatus

    get name(): string {
        return this.data?.name ?? 'Camera'
    }

    get updateInterval(): number {
        return PlatformConfiguration.settingsUpdatesTimeInterval
    }

    constructor(camera: CameraInterface) {
        super()
        this.data = CameraInstance.fromInterface(camera)
    }

    reset() {
        super.reset()
        removeCameraSettingsCache()
    }

    async update() {
        try {
            const cameraSettings = await this.apiService.getCameraSettings()
            // Сбросим internalStatus, когда это нужно
            const statuses: CameraStatus[] = [CameraStatus.On, CameraStatus.Off]
            if (this.internalStatus) {
                if (statuses.includes(this.internalStatus)) {
                    this.internalStatus = undefined
                }
            }

            this.updateCameraSettings(cameraSettings)
        } catch (error) {
            // console.log(`CameraDataProvider > failed to fetch camera settings: ${error}`)
            this.setInternalStatus(CameraStatus.Off);
        }
    }

    async softShutDownCamera() {
        await this.apiService.shutdown()
        this.setInternalStatus(CameraStatus.ShuttingDown);
        this.informSubscribers()
    }

    async setJetsonTemperatureControllerData(id: JetsonId, data?: TemperatureControllerFormInterface) {
        return await this.setTemperatureControllerData(id, data) as TemperatureControllerDataInterface
    }

    async setHeaterTemperatureControllerData(id: HeaterId, data?: HeaterTemperatureControllerFormInterface) {
        return await this.setTemperatureControllerData(id, data) as HeaterTemperatureControllerDataInterface
    }

    async setTemperatureControllerData(id: SensorPlace, data?: TemperatureControllerFormInterface) {

        const info: CameraSettingsPatchInfoInterface = {
            temperatureControllerDataJ1: id === 'jetson1' ? data : undefined,
            temperatureControllerDataJ2: id === 'jetson2' ? data : undefined,
            temperatureControllerDataHeater: id === 'heater' ? data : undefined,
            temperatureControllerDataHeater1: id === 'heater1' ? data : undefined,
            temperatureControllerDataHeater2: id === 'heater2' ? data : undefined,
            temperatureControllerDataHeater3: id === 'heater3' ? data : undefined,
            temperatureControllerDataHeater4: id === 'heater4' ? data : undefined,
            temperatureControllerDataHeaterCam1: id === 'heater_cam1' ? data : undefined,
            temperatureControllerDataHeaterCam2: id === 'heater_cam2' ? data : undefined,
            temperatureControllerDataHeaterCam3: id === 'heater_cam3' ? data : undefined,
        }

        const cameraSettings = await this.apiService.setCameraSettings(info)
        this.updateCameraSettings(cameraSettings)

        
        return this.camera.getTemperatureControllerData(id)
    }

    async setMode(mode: CameraOperationMode) {
        const info: CameraSettingsPatchInfoInterface = {
            mode: mode
        }

        const cameraSettings = await this.apiService.setCameraSettings(info)
        this.updateCameraSettings(cameraSettings)
    }

    // // MARK: Private
    private updateCameraSettings(cameraSettings: CameraSettingsInterface) {
        this.data = new CameraInstance(this.data?.name ?? "", this.internalStatus ?? CameraStatus.On, cameraSettings)
        this.updateActiveHeatersIfNeed(cameraSettings)
        setCameraSettingsToCache(cameraSettings)
    }

    setInternalStatus(status: CameraStatus) {
        this.internalStatus = status
        const data = this.data
        if (data) {
            this.data = new CameraInstance(this.data?.name ?? "", status, this.data?.settings)
        }
    }

    updateActiveHeatersIfNeed(cameraSettings: CameraSettingsInterface | undefined) {
        if (Array.isArray(this.activeHeaterIdArr) && this.activeHeaterIdArr.length > 0) {
            return
        }
        if (Array.isArray(cameraSettings?.temperatureControllerDataHeater.sources) && (cameraSettings?.temperatureControllerDataHeater.sources.length ?? 0) > 0) {
            const existingHeatersIdArr:HeaterId[] = [];
            // use order of sensors in main list
            const sources = cameraSettings?.temperatureControllerDataHeater.sources
            var heaterCount = 0
            var heaterCamCount = 0
            HeaterIdAllSelf.forEach(function (value) {
                if (sources?.includes(value)) {

                    // detect missing heaters. by hw problem array may not include some heaters. restore missing
                    var numStr = value.replace(/[^0-9]/g, ''); 
                    var number =  parseInt(numStr,10); 
                    if (!isNaN(number)) {
                        if (value.startsWith('heater_cam')) {
                            heaterCamCount++
                            while (heaterCamCount < number) {
                                const newValue = `heater_cam${heaterCamCount}` as HeaterId
                                existingHeatersIdArr.push(newValue)
                                heaterCamCount++
                            }
                        }
                        else {
                            heaterCount++
                            while (heaterCount < number) {
                                const newValue = `heater${heaterCount}` as HeaterId
                                existingHeatersIdArr.push(newValue)
                                heaterCount++
                            }
                        }
                    }

                    existingHeatersIdArr.push(value)
                }
            })

            if (existingHeatersIdArr.length > 0) {
                this.activeHeaterIdArr = existingHeatersIdArr
                CameraContext.informActiveHeatersSubscribers()
            }
        }
    }
}
