// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

const getTimezoneInSec = (): number => (
  -(new Date().getTimezoneOffset() * 60) // return in seconds. make "-" because getTimezoneOffset() for example for UTC+3 return -180 
);

export const getTimezoneInMinutes = (): number => (
  -(new Date().getTimezoneOffset()) // return in minutes. make "-" because getTimezoneOffset() for example for UTC+3 return -180 
);

export default getTimezoneInSec;
