// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { queryCache, queryClient } from '../../../../AppProviders';
import { VKResponseItems, VKUserGroupFilter, VKUserGoupItems } from '../VKUserGoup';
import fetchJsonp from "fetch-jsonp";

type FetchProps = Readonly<{
  accessToken?: string;
  filter?: VKUserGroupFilter;
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'oauth-vk-user-group';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.accessToken,
  props.filter,
]);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<VKResponseItems<VKUserGoupItems>>(getKey(props))
);

const flushCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};

const fetchData = async (props: FetchProps): Promise<VKResponseItems<VKUserGoupItems>> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  let params = `?v=5.131&extended=1&count=100&access_token=${ props.accessToken }`;

  if (props.filter) {
    params += `&filter=${ props.filter }`;
  }
  // use jsonP because of cors. jsonp allow to call api from other site
  return fetchJsonp(`https://api.vk.com/method/groups.get${ params }`)
    .then((res) => res.json<VKResponseItems<VKUserGoupItems>>())
};


export type UseFetchProps = Readonly<{
  enableToFetch?: boolean;
} & FetchProps>;

const useFetchVKUserGroups = (props: UseFetchProps): UseQueryResult<VKResponseItems<VKUserGoupItems>> => {
  const { enableToFetch = true } = props;
  const queryKey = getKey(props);

  return useQuery<VKResponseItems<VKUserGoupItems>>({
    queryKey,
    queryFn: () => fetchData({
      accessToken: props.accessToken,
      filter: props.filter,
    }),
    enabled: !!enableToFetch,
  });
};


export {
  getKey,
  useFetchVKUserGroups,
  getCached,
  flushCache,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
