// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import { Action, ActionType } from '../hook/useMutation';
import fetchJSONData from '../../_Networking/ReactQuery/fetch';
import { NODE_JS_BACKEND_URL } from '../../../constants';
import { queryCache, queryClient } from '../../../AppProviders';
import ResponseMany from '../../_Networking/ReactQuery/ResponseMany';
import ResponseSingle from '../../_Networking/ReactQuery/ResponseSingle';
import StreamingProfile from '..';


const PREFIX_OF_A_COMPOSITE_KEY = 'streaming-profiles';

export const getKey = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

export const flushStreamingProfilesCache = () => {
  const queries = queryCache.findAll(getKey());

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};

export const getStreamingProfilesCached = () => (
  queryClient.getQueryData<ResponseMany<StreamingProfile[]>>(getKey())
);

export const postStreamingProfiles = async (streamingSettingsProfile: StreamingProfile): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<StreamingProfile>>(
      `${NODE_JS_BACKEND_URL}/streaming/profiles.json`,
      {
        method: 'POST',
        body: JSON.stringify({ streamingSettingsProfile }),
      }
    );

    if (data.error) {
      throw new Error(data.error);
    } else {
      return ({
        type: ActionType.Post,
        streamingSettings: data.data,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const deleteStreamingProfiles = async (IDs: string[]): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<StreamingProfile>>(
      `${NODE_JS_BACKEND_URL}/streaming/profiles.json`,
      {
        method: 'DELETE',
        body: JSON.stringify({ IDs }),
      }
    );

    if (!data?.error) {
      return ({
        IDs,
        type: ActionType.Delete,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Delete Streaming Profiles');
    }
  } catch (error) {
    throw error;
  }
};

export const fetchData = async (): Promise<ResponseMany<StreamingProfile[]>> => {
  const cacheData = getStreamingProfilesCached();

  if (cacheData) { return cacheData; }

  return await fetchJSONData<ResponseMany<StreamingProfile[]>>(
    `${NODE_JS_BACKEND_URL}/streaming/profiles.json`,
    {
      method: 'GET',
    }
  );
};

