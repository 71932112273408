// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Divider, Grid } from '@mui/material';

import useStyles from './useStyles';
import { MainCardHeaderActionInterface, MainCardHeaderModelInterface } from '../Types';
import CardHeaderStatus from './Status';
import CardHeaderStatusName from './Name';
import { SX as MainButtonSX } from '../../../_BaseUI/MainButton';
import Button from '../../../_BaseUI/Button';


const SX = {
  button: {
    width: 'auto',
    // minWidth: 'initial',
    padding: '0 15px',
  },
  iconButton: {
    width: 'initial',
    minWidth: 'initial',
    padding: 0,
  },
};

type Props = Readonly<{
  model: MainCardHeaderModelInterface;
}>;

const MainCardHeader: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const model = props.model;
  const isStatusHidden = (model.statusName === undefined) && (model.statuses.length === 0);

  const buttonSX = { ...MainButtonSX.main, ...MainButtonSX.plain, ...SX.button };
  const borderedButtonSX = { ...MainButtonSX.main, ...MainButtonSX.outline, ...SX.button };
  const iconButtonSX = { ...MainButtonSX.icon, ...SX.iconButton };

  const arr = model.rightActions?.map((item: MainCardHeaderActionInterface, index) => {
    if (!item.onAction) {
      return undefined
    }

    const sx = (item.bordered) ? borderedButtonSX : ((item.icon) ? iconButtonSX :  buttonSX)

    return (
      <Button
        key={ index }
        sx={ sx }
        variant={ (item.icon) ? "outlined" : "contained" }
        onClick={ item.onAction }
      >
        { item.icon ?? item.title }
      </Button>
    )
  }) 

  const renderRightActions = (model.rightActions?.length) ?
    <Grid item>
      { arr }
    </Grid>
    :
    undefined

  const renderNameRight = (model.nameRight) ?
    <Grid item>
      <div className={ classes.name }>{ model.nameRight }</div>
    </Grid>
    :
    undefined

  const renderStatusesName = [model.statusName].filter(text => !!text)
    .map((text?: string) => (
      <CardHeaderStatusName
        key={ text || 'statusName' }
        className={ classes.statusName }
        text={ text }
      />
    ));
  const renderStatuses = model.statuses.filter(text => !!text)
    .map((text: string) => (
      <CardHeaderStatus
        key={ text }
        className={ classes.status }
        text={ text }
        statusColor={ model.statusColor }
      />
    ));

  return (
    <div className={ classes.root }>
      <div className={ classes.toolbar } style={ { background: model.barColor } }>
        <Grid container spacing={ 1 } direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs>
            <div className={ classes.name }>{ model.name }</div>
          </Grid>
          { !isStatusHidden && (
            <Grid item>
              <Grid container spacing={ 0 } direction="row" alignItems="center">
                { renderStatusesName }
                { renderStatuses }
              </Grid>
            </Grid>
          ) }
          { renderNameRight }
          { renderRightActions } 
        </Grid>
      </div>
      {(!model?.hideDivider) && 
        <Divider light />
      }
    </div>
  );
};


export default MainCardHeader;
