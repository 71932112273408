// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import TeamRef from '..';
import { PAGE_LIMIT } from '../../../../constants';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import { getAllCached, useTeamRefsFetch } from '../fetch';

export type GetTeamRefById = (externalTeamId: string | undefined) => TeamRef | undefined;

const useFetchAllPagesTeamRefs = () => {
  const [page, setPage] = React.useState<number>(0);
  const allCached: ResponseMany<TeamRef[]> | undefined = getAllCached();

  const handleFlushPage = () => {
    setPage(0);
  };

  const getTeamRefById: GetTeamRefById = (teamId: string | undefined): TeamRef | undefined => {
    return allCached?.data?.find(({ id }) => (id === teamId));
  }

  const getTeamRefByExternalId: GetTeamRefById = (externalTeamId: string | undefined): TeamRef | undefined => {
    return allCached?.data?.find((item) => (item.externalTeamId === externalTeamId));
  }

  const fetchedTeams = useTeamRefsFetch({
    page,
    enableToFetch: true,
  });

  React.useEffect(() => {
    if (
      fetchedTeams.status === 'success'
      && fetchedTeams.data?.meta.pagination?.total
    ) {
      const maxPage = Math.ceil(fetchedTeams.data?.meta.pagination.total / PAGE_LIMIT);
      const isFetchedAllPages = (
        (page + 1) === maxPage
        || (page + 1) > maxPage
      );

      if (!isFetchedAllPages) {
        setPage(page + 1);
      }
    }
  }, [fetchedTeams.status, fetchedTeams.data?.meta.pagination?.total, page]);

  return ({
    allCached,
    handleFlushPage,
    getTeamRefById,
    getTeamRefByExternalId,
    status: fetchedTeams.status,
  });
};


export default useFetchAllPagesTeamRefs;
