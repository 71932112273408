// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';

import AnyChildren from '../../../../../Tools/AnyChildren';


type Props = Readonly<{
  children: AnyChildren;
}>;


const SX = {
  wrapper: {
    paddingLeft: 4,
    paddingRight: 0,
  },
  line: {},
};

const FieldSetWrapper: React.FC<Props> = (props: Props) => {
  return (
    <Grid
      container
      sx={ SX.wrapper }
    >
      <Grid
        item
        xs={ 12 }
        sx={ SX.line }
      >
        { props.children }
      </Grid>
    </Grid>
  );
};


export default FieldSetWrapper
