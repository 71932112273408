// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import BaseCommandExecutor from '../_Networking/AxiosFetch/Executor'
import { PiRestApi } from '../_Networking/AxiosFetch/RestApi'
import { PlatformConfiguration } from '../../Configuration/PlatformConfiguration'
import { AppInterface, AuthorisationInterface, SessionInterface, UserInterface } from './Types'
import ApiError from '../_Networking/AxiosFetch/Errors/Types'
import { AppLocale } from '../../Localization/AppContextProvider/helpers';


interface AuthorisationApiServiceInterface {
    createApp(locale: AppLocale, succeeded: (app: AppInterface) => void, failed: (error: ApiError) => void): void

    signIn(username: string, password: string, succeeded: (authorisation: AuthorisationInterface) => void, failed: (error: ApiError) => void): void
    logout(succeeded: () => void, failed: (error: ApiError) => void): void
    refreshSession(session: SessionInterface, succeeded: (session: SessionInterface) => void, failed: (error: ApiError) => void): void
}

export default class AuthorisationApiService implements AuthorisationApiServiceInterface {
    createApp(locale: AppLocale, succeeded: (app: AppInterface) => void, failed: (error: ApiError) => void): void {
        const data = PlatformConfiguration.appName + ', ' + PlatformConfiguration.appVersion

        const command = PiRestApi.createAppCommand(locale, data)
        const executor = new BaseCommandExecutor<AppInterface>(command)

        executor.execute(succeeded, failed)
    }

    signIn(username: string, password: string, succeeded: (authorisation: AuthorisationInterface) => void, failed: (error: ApiError) => void): void {
        const command = PiRestApi.signInCommand(username, password)
        const executor = new BaseCommandExecutor<AuthorisationInterface>(command)

        executor.execute(succeeded, failed)
    }

    fetchCurrentUser(succeeded: (user: UserInterface) => void, failed: (error: ApiError) => void): void {
        const command = PiRestApi.currentUser()
        const executor = new BaseCommandExecutor<UserInterface>(command)

        executor.execute(succeeded, failed)
    }

    logout(succeeded: () => void, failed: (error: ApiError) => void): void {
        const command = PiRestApi.logout()
        const executor = new BaseCommandExecutor(command)

        executor.execute(succeeded, failed)
    }

    refreshSession(session: SessionInterface, succeeded: (session: SessionInterface) => void, failed: (error: ApiError) => void): void {
        const command = PiRestApi.refreshAccessToken(session.refreshToken)
        const executor = new BaseCommandExecutor<SessionInterface>(command)

        executor.execute(succeeded, failed)
    }
}
