// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import 'moment/locale/ru';
import 'moment/locale/eu';

import User from '../../Data/AccountUsers/User';
import moment from '../../Tools/moment';
import { AppLocale } from '../../Localization/AppContextProvider/helpers';
import { UserTeamRefAccess, UserCameraAccess, UserType } from '../../Data/AccountUsers/UserWithoutID';
import { RenderCellContentValue } from '../../Components/SortedTable/SortedTableBodyRow';
import { intl } from '../../Localization/LocalizationProvider';
import LOCALIZATION from '../../Localization';
import AuthorisationManager from '../../Data/Auth/AuthorisationManager';
import { getCameraName } from '../CamerasPage/CameraCardBlock/helper';
import isSuperAdmin from '../../Data/AccountUsers/tools/isSuperAdmin';
import { getCachedCameras } from '../../Data/Camera/ActiveCamera/fetch';
import { CameraType } from '../../Data/Camera/ActiveCamera/Camera';
import { getAllCached } from '../../Data/Analytics/AnalyticsTeamRef/fetch';
import { getTeamRefName } from '../../Data/Analytics/AnalyticsTeamRef';


const renderEventsTabCell = (locale: AppLocale) => (item: User, cellName: keyof User): RenderCellContentValue => {
  const user = AuthorisationManager.shared.user
  const cameras = getCachedCameras()?.filter((camera) => (camera.type === CameraType.Remote));
  const teamRefs = getAllCached();
  
  try {
    switch (cellName) {
      case 'userAvailableCameraIdList': {
        if (item.cameraAccess === UserCameraAccess.All && user?.cameraAccess ===  UserCameraAccess.All) { // show that user have root only is current user is root
          return ({
            content: (
              <div>
                { intl().formatMessage({ id: LOCALIZATION.cameras_option_title_root_access }) }
              </div>
            ),
          });
        }
        else if (item.cameraAccess === UserCameraAccess.None) {
          return ({
            content: (
              <div>
                -
              </div>
            ),
          });
        }
        else if (item.cameraAccess === UserCameraAccess.Local) {
          return ({
            content: (
              <div>
                -
              </div>
            ),
          });
        }

        // List
        return ({
          content: (
            <div style={{ whiteSpace: 'pre-line' }}>
              { // eslint-disable-next-line
                item.userAvailableCameraIdList?. // eslint-disable-next-line
                filter((cId) => ( (user) && (user.userAvailableCameraIdList?.indexOf(cId) >= 0))).
                flatMap((cId) => {
                  const camera = cameras?.find((cam) => (cam.cameraId === cId))  
                  if (!camera) {
                    return undefined
                  }
                  return getCameraName (camera, isSuperAdmin(user) , user?.type === UserType.Server) // eslint-disable-next-line
                }).  // eslint-disable-next-line
                filter(camera => camera). // this need to remove undefined
                join(',\n') || "-"
              }
            </div>            
          ),
        });
      }
      case 'userAvailableTeamRefIdList': {
        if (item.teamRefAccess === UserTeamRefAccess.All && user?.teamRefAccess ===  UserTeamRefAccess.All) { // show that user have root only is current user is root
          return ({
            content: (
              <div>
                { intl().formatMessage({ id: LOCALIZATION.teamref_option_title_root_access }) }
              </div>
            ),
          });
        }
        else if (item.teamRefAccess === UserTeamRefAccess.Guest) {
          return ({
            content: (
              <div>
                { intl().formatMessage({ id: LOCALIZATION.cameras_option_title_guest }) }
              </div>
            ),
          });
        }
        else if (item.teamRefAccess === UserTeamRefAccess.None) {
          return ({
            content: (
              <div>
                -
              </div>
            ),
          });
        }

        // List
        return ({
          content: (
            <div style={{ whiteSpace: 'pre-line' }}>
              { // eslint-disable-next-line
                item.userAvailableTeamRefIdList?. // eslint-disable-next-line
                filter((cId) => ( (user) && (user.userAvailableTeamRefIdList) && (user.userAvailableTeamRefIdList?.indexOf(cId) >= 0))).
                flatMap((cId) => {
                  const teamRef = teamRefs?.data?.find((teamRef) => (teamRef.id === cId))  
                  if (!teamRef) {
                    return undefined
                  }
                  return getTeamRefName (teamRef, isSuperAdmin(user)) // eslint-disable-next-line
                }).  // eslint-disable-next-line
                filter(teamRef => teamRef). // this need to remove undefined
                join(',\n') || "-"
              }
            </div>            
          ),
        });
      }
      case 'lastLogin': {
        moment.locale(locale);

        return ({
          content: (
            <div>
              { (item.lastLogin) ? moment(item.lastLogin).format('llll') : '-' }
            </div>
          ),
        });
      }
      default: {
        return ({
          content: (item?.[cellName] || '') as string,
        });
      }
    }
  } catch (ignore) {
    return ({
      content: (item?.[cellName] || '') as string,
    });
  }
};


export default renderEventsTabCell;
