// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { ErrorMessageChangeSubscriber } from './ErrorMessageType'

export class ErrorMessageHelper {
    static recentError?: Error

    static handleError(error?: Error, time?: number) {
        this.recentError = error
        this.informSubscribers()
        const t = (time === undefined) ? 3000 : undefined
        if (t) {
            setTimeout(() => { this.clearError() }, t)
        }
    }

    static clearError() {
        this.recentError = undefined
        this.informSubscribers()
    }

    static subscribeToErrorChange(subscriber: ErrorMessageChangeSubscriber) {
        this.subscribers.push(subscriber)
    }

    static unsubscribeFromErrorChange(subscriber: ErrorMessageChangeSubscriber) {
        // const count = this.subscribers.length
        this.subscribers = this.subscribers.filter(s => s !== subscriber)
        // const removed = count - this.subscribers.length
        // console.log(`ErrorMessageHelper > unsubscribed ${removed}`)
    }

    static reset(): void {
        this.recentError = undefined
        this.subscribers = []
    }

    private static subscribers: ErrorMessageChangeSubscriber[] = []

    private static informSubscribers() {
        this.subscribers.forEach(subsriber => subsriber(this.recentError))
    }
}
