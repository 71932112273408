// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { styled } from '@mui/material/styles';


type Props = Readonly<{
  tag: any | string;
  styles: object;
}>;


const createStyledComponent = (props: Props) => styled(props.tag as any)((() => (props.styles) as any));


export default createStyledComponent;
