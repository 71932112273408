// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';


type Props = Readonly<{
  className?: string;
  text?: string;
}>;


const CardHeaderStatusName: React.FC<Props> = (props: Props) => (
  <Grid
    item
    key={ props.text || 'statusName' }
  >
    <div
      className={ props.className }
    >
      { props.text + ':\u00A0' }
    </div>
  </Grid>
);


export default CardHeaderStatusName;
