// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';

import { getLocale } from './helpers';
import { FieldSetInputType } from '.';
import { useStylesInputBox } from './FieldSetText';
import { useAppConfig } from '../../../Localization/AppContextProvider/helpers';


export type FieldSetTime = Readonly<{
  type: FieldSetInputType.Time;
  noWrapper?: boolean;
  dateFormat?: string;
  value: {
    time: string | null;
  };
  errorCheckPattern?: (value: string) => boolean;
  onChangeTime:  (time: string | null) => void;
}>;

type Props = Readonly<{
  input: FieldSetTime;
  isError?: boolean;
  onIsError?: (isError: boolean) => void;
}>;


const FieldSetTimeComponent: React.FC<Props> = (props: Props) => {
  const classes = useStylesInputBox();
  const now = new Date();
  const { localization: { locale } } = useAppConfig();
  // const { dateFormat = 'yyyy.MM.dd'} = props.input;

  const dateTime = (props.input.value?.time && props.input.value.time.length) ?
    new Date(`${format(now, 'yyyy-MM-dd')}T${props.input.value.time}`)
    :
    now;

  const handleTimeValueChanged = (time: any) => {
    try {
      const newTime = format(time || new Date(), 'HH:mm');

      props.input.onChangeTime(newTime);

      if (props.input.errorCheckPattern && props.onIsError) {
        props.onIsError(props.input.errorCheckPattern(newTime));
      }
    } catch (ignore) {
      // ignore
    }
  };

  const renderInput = (params: MuiTextFieldPropsType): React.ReactElement => (
    <TextField
      { ...params }
      InputProps={ {
        className: cn(classes.input, {
          [classes.error]: props.isError,
        }),
      } }
      sx={ { width: '110px' } }
    />
  );

  if (props.input?.noWrapper === true) {
    return (
      <LocalizationProvider
        locale={ getLocale(locale) }
        dateAdapter={ AdapterDateFns }
      >
        <MobileTimePicker
          value={ dateTime }
          onChange={ handleTimeValueChanged }
          renderInput={ renderInput }
        />
      </LocalizationProvider>
    );
  }

  return (
    <LocalizationProvider
      locale={ getLocale(locale) }
      dateAdapter={ AdapterDateFns }
    >
      <MobileTimePicker
        value={ dateTime }
        onChange={ handleTimeValueChanged }
        renderInput={ renderInput }
      />
    </LocalizationProvider>
  );
};


export default FieldSetTimeComponent;
