// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import {  Box, Grid } from '@mui/material';

import MainCard from '../../../Components/_Layout/Cards/Main/Card'
import { injectIntl, WrappedComponentProps } from 'react-intl';
import VertGrid from '../../../Components/_Layout/VertGrid';
import AnalyticsGameArchive, { AnalyticsGameShared } from '../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import { createStyles, makeStyles } from '@mui/styles';
import MainCardContent from '../../../Components/_Layout/Cards/Main/Content';

import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Colors } from '../../../Configuration/Styles/Colors';

import { analyticsTimeIntervalName } from '../../../Data/Analytics/AnalyticsTypes';
import { AnalyticsStatPossessionHeatmapForTime } from '../../../Data/Analytics/AnalyticsStatPossessionHeatmap/AnalyticsStatPossessionHeatmap';
import { AnalyticsStatScoresForTime } from '../../../Data/Analytics/AnalyticsStatScore/AnalyticsStatScore';
import { analyticsSplitLeftRightTeam } from '../../../Data/Analytics/AnalyticsSplitLeftRightTeam';
import AnalyticsHeatmapView from '../../../Components/_ChartsAndBars/AnalyticsHeatmapView';
import { AnalyticsStatDistanceForTime } from '../../../Data/Analytics/AnalyticsStatDistance/AnalyticsStatDistance';
import { AnalyticsStatSpeedForTime } from '../../../Data/Analytics/AnalyticsStatSpeed/AnalyticsStatSpeed';
import { AnalyticsStatPossessionForTime } from '../../../Data/Analytics/AnalyticsStatPossession/AnalyticsStatPossession';
import { createPhysicalReportVertBarChartConfig } from '../../../Components/_ChartsAndBars/AnalyticsVertBarView/createPhysicalReportVertBarChartConfig';
import AnalyticsVertBarView from '../../../Components/_ChartsAndBars/AnalyticsVertBarView';
import { createTeamMainReportBarChartConfig } from '../../../Components/_ChartsAndBars/AnalyticsVertOppositeBarView/createTeamMainReportBarChartConfig';
import AnalyticsVertOppositeBarView from '../../../Components/_ChartsAndBars/AnalyticsVertOppositeBarView';
import { swapValue1and2InBarChartArray } from '../../../Components/_ChartsAndBars/AnalyticsVertOppositeBarView/helper';
import LOCALIZATION from '../../../Localization';
import { GetTeamRefById } from '../../../Data/Analytics/AnalyticsTeamRef/hook';

const useStyles = makeStyles(() => createStyles({
  titleText: {
    fontFamily: Fonts.main,
    fontSize: Sizes.caption,
    fontWeight: Sizes.boldWeight,
    color: Colors.mainTitle,
  },
  icon: {
    display: 'flex',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    border: `1px solid ${Colors.analyticsCircleBorder}`,
    // backgroundColor: '#FFFFFF',
    marginRight: '12pt',
  },
  valueText: {
    fontFamily: Fonts.main,
    fontWeight: Sizes.regularWeight,
    textAlign: 'center'
  },

  teamTextLeft: {
    fontFamily: Fonts.main,
    fontSize: Sizes.heading,
    fontWeight: Sizes.mediumWeight,
    color: Colors.mainTitle,
    textAlign: 'left',
  },
  teamSubtitleLeft: {
    fontFamily: Fonts.main,
    fontSize: Sizes.title,
    fontWeight: Sizes.regularWeight,
    color: Colors.mainTitle,
    textAlign: 'left',
  },

  teamTextRight: {
      fontFamily: Fonts.main,
      fontSize: Sizes.heading,
      fontWeight: Sizes.mediumWeight,
      color: Colors.mainTitle,
      textAlign: 'right',
  },
  teamSubtitleRight: {
      fontFamily: Fonts.main,
      fontSize: Sizes.title,
      fontWeight: Sizes.regularWeight,
      color: Colors.mainTitle,
      textAlign: 'right',
  },

  scoreTextLeft: {
      fontFamily: Fonts.main,
      fontSize: Sizes.analyticsScore,
      fontWeight: Sizes.mediumWeight,
      color: Colors.mainTitle,
      textAlign: 'left',
  },
  scoreTextRight: {
      fontFamily: Fonts.main,
      fontSize: Sizes.analyticsScore,
      fontWeight: Sizes.mediumWeight,
      color: Colors.mainTitle,
      textAlign: 'right',
  },
}));


type Props = Readonly<{
  gameResultInfo?: AnalyticsGameArchive
  heatmapInfo: AnalyticsStatPossessionHeatmapForTime | undefined
  scoreInfo: AnalyticsStatScoresForTime | undefined
  distanceInfo: AnalyticsStatDistanceForTime | undefined
  avgSpeedInfo: AnalyticsStatSpeedForTime | undefined
  avgSpeedInAttackInfo: AnalyticsStatSpeedForTime | undefined
  avgSpeedInDefenseInfo: AnalyticsStatSpeedForTime | undefined
  possessionInfo: AnalyticsStatPossessionForTime | undefined
  getTeamRefByExternalId: GetTeamRefById | undefined;
} & WrappedComponentProps>;

const TacticalReportCard: React.FC<Props> = (props: Props) => {

  const classes = useStyles();

  const teamShortName = (teamId: string | undefined) => {
    return props.getTeamRefByExternalId?.(teamId)?.shortName || teamId || ""
  }

  const team1Name = teamShortName(props.gameResultInfo?.team1);
  const team2Name = teamShortName(props.gameResultInfo?.team2);

  let oppositeBarChartData = createTeamMainReportBarChartConfig(
    props.gameResultInfo, 
    undefined, 
    undefined, 
    props.possessionInfo?.interval_stat, 
    undefined,
    props.getTeamRefByExternalId,
  );

  const vertBarChartData = createPhysicalReportVertBarChartConfig(
    props.gameResultInfo, 
    props.distanceInfo?.interval_stat,
    props.avgSpeedInfo?.interval_stat, 
    props.avgSpeedInAttackInfo?.interval_stat, 
    props.avgSpeedInDefenseInfo?.interval_stat,
    props.getTeamRefByExternalId,
    );

  const teamsInfo = analyticsSplitLeftRightTeam(props.gameResultInfo, props.scoreInfo, props.heatmapInfo, props.getTeamRefByExternalId)

  if (teamsInfo.isTeam1And2Swapped) { // is this time half teams chnage default order
    oppositeBarChartData = swapValue1and2InBarChartArray(oppositeBarChartData);
  }

  return (
    <MainCard>
      <MainCardContent margin={ 4 }>
        <Box className={ classes.titleText }>
          { analyticsTimeIntervalName(props.scoreInfo?.type, props.scoreInfo?.type_order_num) }
        </Box>

        <Grid container direction="row" alignItems="flex-start" spacing={ 3 } 
          sx={{
            paddingTop: 2
          }}
        >

          <Grid item xs={ 12 } lg={ 6 }>
              <VertGrid alignItems='stretch'>
                <Grid container direction="row" alignItems="baseline" justifyContent="space-between" spacing={ 1 } >
                    <Grid item xs={ 6 }>
                        <Box className={ classes.teamTextLeft }>
                            { teamsInfo.teamLeft?.title }
                        </Box>
                        <Box className={ classes.teamSubtitleLeft }>
                            { props.intl.formatMessage({ id: LOCALIZATION.analytics_left_side })  }
                        </Box>
                    </Grid>

                    <Grid item xs={ 6 }>
                        <Box className={ classes.teamTextRight }>
                            { teamsInfo.teamRight?.title }
                        </Box>
                        <Box className={ classes.teamSubtitleRight }>
                        { props.intl.formatMessage({ id: LOCALIZATION.analytics_right_side })  }
                      </Box>
                    </Grid>        
                </Grid>

                <Box
                  sx={ {
                    height: 200,
                  } }
                >
                  <AnalyticsVertOppositeBarView 
                    data={ oppositeBarChartData } 
                    team1Color={ teamsInfo.teamLeft?.color }
                    team2Color={ teamsInfo.teamRight?.color }
                  />
                </Box>
                <AnalyticsHeatmapView 
                  maxWidth={ 550 }
                  data={ props.heatmapInfo?.interval_stat }
                  sportType={ props.gameResultInfo?.sport_type }
                  team1Name={ team1Name }
                  team2Name={ team2Name }
                  team1Color={AnalyticsGameShared.team1Color}
                  team2Color={AnalyticsGameShared.team2Color}
                />
              </VertGrid>
          </Grid>

          <Grid item xs={ 12 } lg={ 6 }
            sx={ {
              height: 360,
            } }
          >
            <AnalyticsVertBarView 
              data={ vertBarChartData } 
              team1Color={ AnalyticsGameShared.team1Color }
              team2Color={ AnalyticsGameShared.team2Color }
            />
          </Grid>
        </Grid> 
      </MainCardContent>
    </MainCard>
  )
};

export default injectIntl(TacticalReportCard);
