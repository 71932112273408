// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import Order from '../../_Networking/ReactQuery/Order';
import Archive, { FileType } from '..';
import ResponseMany from '../../_Networking/ReactQuery/ResponseMany';
import {
  useFetch,
  flushCache,
  getCached,
} from '../fetch';
import { ResultStatus } from '../../_Networking/ReactQuery/template';


type Props = Readonly<{
  type: FileType;
  page: number;
  limit: number;
  order: Order;
  orderBy?: 'date';
  date?: string;
}>;

type Result = Readonly<{
  status: ResultStatus;
  data: ResponseMany<Archive[]> | undefined;
  handleFlushData: () => void;
}>;


const usePagedData = (props: Props): Result => {
  const fetchedArchive = useFetch({
    type: props.type,
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    date: props.date,
    enableToFetch: true,
  });
  const data = getCached({
    type: props.type,
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    date: props.date,
  });

  return ({
    status: fetchedArchive.status,
    data,
    handleFlushData: flushCache,
  });
};


export default usePagedData;
