// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { TemperatureControllerDataInterface } from '../HWCameraSettings/CameraSettingsTemperature'

export enum JetsonNames {
    Jetson1 = "jetson1",
    Jetson2 = "jetson2",
}
export const JetsonIdAll = [JetsonNames.Jetson1, JetsonNames.Jetson2] as const
export type JetsonId = typeof JetsonIdAll[number]

export interface JetsonStatusReviewInterface {
    id: JetsonId
    name: string
    isCritical: boolean
    statuses: string[]
}

export interface FanInterface {
    name: string
    isOn: boolean
}

export interface JetsonInterface {
    readonly jetsonId: JetsonId
    readonly temperatureControllerData?: TemperatureControllerDataInterface
    readonly name: string,
    readonly cpuLoad?: number,
    readonly cpuTemp?: number,
    readonly gpuLoad?: number,    
    readonly gpuTemp?: number,
    readonly boardTemp?: number,
    readonly powerVoltage?: number,
    readonly state: boolean
}

export interface JetsonStorageInterface {
    readonly rootVolume?: number,
    readonly ssdVolume?: number
}
export interface CameraStorageReviewInterface {
    readonly jetson1?: JetsonStorageInterface
    readonly jetson2?: JetsonStorageInterface
}

export const JetsonHardwareButtonTypesAll = ['power', 'recovery', 'reset'] as const
export type JetsonHardwareButtonType = typeof JetsonHardwareButtonTypesAll[number]

export type JetsonHardwareButtonsStates = {
    // eslint-disable-next-line no-unused-vars
    [buttonType in JetsonHardwareButtonType]: boolean
}
export type JetsonHardwareButtonsNames = {
    // eslint-disable-next-line no-unused-vars
    [buttonType in JetsonHardwareButtonType]: string
}

export interface JetsonHardwareButtonsCollectionInterface {
    states: JetsonHardwareButtonsStates
    duration: number
}

export interface JetsonHardwareButtonsFormInterface extends JetsonHardwareButtonsCollectionInterface {
}

export type JetsonHardwareButtonsAvailability = Readonly<JetsonHardwareButtonsStates>
