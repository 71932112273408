// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import Event from '../../../Data/EPG/EpgSchedule/Event';
import EventWithoutID, { EventPriority, GameRepeatType, GameType, SportType, weekDaysMondayToFidayArray } from '../../../Data/EPG/EpgSchedule/EventWithoutID';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { intl } from '../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../Localization';
import { getDefaultCameraSportType } from '../../../Data/Camera/ActiveCamera/fetch';

dayjs.extend(customParseFormat)

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
require('dayjs/locale/ru')


export const SPORT_ID_ARR = Object.values(SportType)

export const localizedSportNameById = (sportId: string): string => {
  switch (sportId) {
    case SportType.Football:
      return intl().formatMessage({ id: LOCALIZATION.event_sport_type_football }) || '';
    case SportType.Hockey:
      return intl().formatMessage({ id: LOCALIZATION.event_sport_type_hockey }) || '';
    case SportType.Basketball:
      return intl().formatMessage({ id: LOCALIZATION.event_sport_type_basketball }) || '';
    case SportType.HockeyWithBall:
      return intl().formatMessage({ id: LOCALIZATION.event_sport_type_hockey_with_ball }) || '';
    case SportType.Handball:
      return intl().formatMessage({ id: LOCALIZATION.event_sport_type_handball }) || '';
    case SportType.MiniFootball:
      return intl().formatMessage({ id: LOCALIZATION.event_sport_type_mini_football }) || '';
    case SportType.MultipleSports:
      return intl().formatMessage({ id: LOCALIZATION.event_sport_type_multiple_sports }) || '';
    case SportType.Unknown:
      return intl().formatMessage({ id: LOCALIZATION.event_sport_type_unknown }) || '';
  }
  return sportId || 'Unknown'
}

export const GAME_TYPE_ID_ARR = Object.values(GameType)

export const EVENT_PRIORITY_ID_ARR = Object.values(EventPriority)

export const localizedGameTypeById = (gameTypeId: string): string => {
  switch (gameTypeId) {
    case GameType.Game:
      return intl().formatMessage({ id: LOCALIZATION.event_game_type_game }) || '';
    case GameType.Train:
      return intl().formatMessage({ id: LOCALIZATION.event_game_type_train }) || '';
    case GameType.FreeTime:
      return intl().formatMessage({ id: LOCALIZATION.event_game_type_free_time }) || '';
  }
  return gameTypeId || 'Unknown'
}

export const priorityColorCharById = (priority: string): string => {
  switch (priority) {
    case EventPriority.Low:
      return "🔵";
    case EventPriority.Imported:
      return "🟣";
    case EventPriority.Base:
      return "🟢";
    case EventPriority.High:
      return "🟡";
    case EventPriority.Critical:
      return "🔴";
  }
  return '⚫️'
}

export const priorityToNumber = {
  [EventPriority.Low]: 0,
  [EventPriority.Imported]: 1,
  [EventPriority.Base]: 2,
  [EventPriority.High]: 3,
  [EventPriority.Critical]: 4,
}

export const localizedPriorityById = (priority: string): string => {
  switch (priority) {
    case EventPriority.Low:
      return `🔵 ${intl().formatMessage({ id: LOCALIZATION.event_priority_low }) || ''}`;
    case EventPriority.Imported:
      return `🟣 ${intl().formatMessage({ id: LOCALIZATION.event_priority_imported }) || ''}`;
    case EventPriority.Base:
      return `🟢 ${intl().formatMessage({ id: LOCALIZATION.event_priority_base }) || ''}`;
    case EventPriority.High:
      return `🟡 ${intl().formatMessage({ id: LOCALIZATION.event_priority_high }) || ''}`;
    case EventPriority.Critical:
      return `🔴 ${intl().formatMessage({ id: LOCALIZATION.event_priority_critical }) || ''}`;
  }
  return `⚫️ ${priority || 'Unknown'}`;
}

const DEFAULT_VALUE: EventWithoutID = {
  name: '',
  subTitle: '',
  description: '',
  priority: EventPriority.Base,
  gameType: GameType.Game,
  sportType: "",
  homeTeamID: null,
  guestTeamID: null,
  startDate: '',
  startTime: '',
  endDate: null,
  endTime: '',
  repeatType: GameRepeatType.NoRepeat,
  repeatDays: weekDaysMondayToFidayArray,
  streamProfileID: null,
  streamOverride: null,
  immediateMarkupPromocode: null,
  extraJson: null,
  disabled: false,

};

const dateFormat = 'YYYY.MM.DD';
const timeFormat = 'HH:mm';

const initialEventState = () => {
  const item = {
    ...DEFAULT_VALUE,
    sportType: getDefaultCameraSportType() || "",
  };

  const defaultStart = dayjs().add(1, 'hour')

  const startDate = (!item?.startDate || item.startDate.length === 0) ?
    defaultStart.format(dateFormat)
    :
    item.startDate;
  const startTime = (!item?.startTime || item.startTime.length === 0) ?
    defaultStart.format(timeFormat)
    :
    item.startTime;
  let endTime = item.endTime || '';
  if (endTime.length === 0) {    
    endTime = defaultStart.add(1, 'hour').format(timeFormat)
  }

  return ({
    ...item,
    startDate,
    startTime,
    endTime,
  });
};

const isItemValidToSend = (item: Event | EventWithoutID) : boolean => !(
    item
    && item.name.length
    && item.gameType.length
    && item.sportType.length
    && item.startDate.length
    && item.startTime.length
    && item.endTime.length
    && (
      (
        item.repeatType === GameRepeatType.EveryWeek
        && item.repeatDays.length
      ) || item.repeatType === GameRepeatType.NoRepeat
    )
  );


export enum EventPopupErrors {
  EventInvalidStartDate = 'event_invalid_start_date',
}

const instanceOfEvent = (object: Event | EventWithoutID): object is Event => 'id' in object;

const isStartDateTimeInPast = (startDate: string, startTime: string): boolean => {
  const start = dayjsFrom("start", startDate, startTime)
  const now = dayjs()
  if (start?.isBefore(now)) {
    return true;
  }
  return false;
};

const dateStrFormat = 'YYYY.MM.DD HH:mm'

export const dayjsFromDateStr = (dateStr: string) => {
  let day = dayjs(dateStr)
  if (!day.isValid()) {
    day = dayjs(dateStr, dateStrFormat)
  }
  return day
}

const dayjsFrom = (type: "start" | "end" | "endDate", startDate?: string | null, startTime?: string | null, endDate?: string | null, endTime?: string | null) : Dayjs | undefined => {
  try {
    switch (type) {
      case "start": {
        const dateStr = (startDate || "") + " " + (startTime || "")
        const day = dayjsFromDateStr(dateStr)
        return day
      }
      case "end": {
        // commented because end date is only affect when recurent event
        // if (endDate) {
        //   const dateStr = (endDate || "") + " " + (endTime || "")
        //   const day = dayjsFromDateStr(dateStr)
        //   return day  
        // }
        // else 
        if (startDate) {// not end date. sount from start date
          
          const dateStr = (startDate || "") + " " + (endTime || "")
          let day = dayjsFromDateStr(dateStr)

          const startT = dayjsFromDateStr("1970.01.01 " + startTime)
          const endT = dayjsFromDateStr("1970.01.01 " + endTime)
          if (startT.isAfter(endT)) {// startTime > endTime -> end time in next day
            day = day.add(1, 'day')
          }
          return day  
        }
        break
      }
      case "endDate": {
        if (endDate) {
          const dateStr = (endDate || "") + " " + (endTime || "")
          const day = dayjsFromDateStr(dateStr)
          return day  
        }
        break
      }
    }
  }
  catch (error) {    
  }
}


export {
  instanceOfEvent,
  isItemValidToSend,
  isStartDateTimeInPast,
  initialEventState,
  dayjsFrom,
};
