// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { CalibrationDataProvider } from './DataProvider'
import { MagnetometerCalibrationDataSubscriber } from './Types'

export class CalibrationHelper {
    static subscribeToCalibrationChange(subscriber: MagnetometerCalibrationDataSubscriber) {
        const dataProvider = CalibrationDataProvider.shared
        dataProvider.subscribeOnChanges(subscriber)
    }

    static unsubscribeFromCalibrationChange(subscriber: MagnetometerCalibrationDataSubscriber) {
        const dataProvider = CalibrationDataProvider.shared
        dataProvider.unsubscribeFromChanges(subscriber)
    }

    static reset() {
        CalibrationDataProvider.shared.reset()
    }
}
