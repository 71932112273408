// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

const b2Mb = (bytes: number): number => (bytes / 1048576);

export function formatBytes(bytes?: number, decimals: number = 2): string {
    if ((!bytes) || (!+bytes)) return '0 Bytes'

    const k = 1000
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function formatBitrate(bitrate?: number, decimals: number = 2): string {
    if ((!bitrate) || (!+bitrate)) return '0 bit/s'

    const k = 1000
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['bit/s', 'KBit/s', 'MBit/s', 'GBit/s', 'TBit/s', 'PBit/s', 'EBit/s', 'ZBit/s', 'YBit/s']

    const i = Math.floor(Math.log(bitrate) / Math.log(k))

    return `${parseFloat((bitrate / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export default b2Mb;
