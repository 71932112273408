// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { HeaterId, SensorPlace, TemperatureControllerID } from '../Metrics/Types';


export enum CameraOperationMode {
    Operational = 'operational',
    Maintenance = 'maintenance',
}

//MARK:- Temperatere

export enum TemperatureControllerState {
    On = 'on',
    Off = 'off',
}

export const TemperatureControllerStateAll = Object.values(TemperatureControllerState)

export enum TemperatureControllerMode {
    Auto = 'auto',
    On = 'on',
    Off = 'off',
}
export const TemperatureControllerModeAll = Object.values(TemperatureControllerMode)

export enum TemperatureControllerType {
    Fan = 'FAN',
    Heater = 'HEATER',
}
export interface TemperatureControllerFormInterface {
    start: number
    stop: number
    critical: number
    mode: TemperatureControllerMode
}

export type CoolerTemperatureControllerFormInterface = TemperatureControllerFormInterface


export enum TemperatureControllerPowerLevel {
    Unknown = 'unknown',
    Off = 'off',
    Normal = 'normal',
    Warning = 'warning',
    Critical = 'critical',
}

export interface TemperatureControllerPayloadInterface {
    readonly id: TemperatureControllerID;
    readonly channel: number;
    readonly powerLevel: TemperatureControllerPowerLevel;
    readonly state: TemperatureControllerState
    readonly powerLoadPercent?: number
}

export interface TemperatureControllerDataInterface extends TemperatureControllerFormInterface, TemperatureControllerPayloadInterface {
}

export function isTemperatureControllerDataCorrect(model: TemperatureControllerDataInterface): boolean {
    return (model.stop !== undefined && model.start !== undefined && model.critical !== undefined) && (model.stop < model.start) && (model.start < model.critical)
}

export type TemperatureControllerLastDataInterface = {
    // eslint-disable-next-line no-unused-vars
    [key in SensorPlace]: number
}

export interface HeaterTemperatureControllerFormInterface extends TemperatureControllerFormInterface{
    currentSource?: SensorPlace
}

export interface HeaterTemperatureControllerDataInterface extends Readonly<HeaterTemperatureControllerFormInterface>, TemperatureControllerPayloadInterface {
    readonly type: TemperatureControllerType;
    readonly sources: SensorPlace[]
    readonly lastData: TemperatureControllerLastDataInterface
}

//MARK:- States

export interface LedStatesInterface {
    readonly jetson1: number
    readonly jetson1OffValue?: number
    readonly jetson2: number
    readonly jetson2OffValue?: number
    readonly pi: number
    readonly piOffValue?: number
}

export enum DeviceStatus {
    On = 'on',
    Off = 'off',
  }
export enum DCDeviceStatus {
    Off = 'off',
    Battery = 'battery',
    AC = 'AC',
  }
export interface DeviceStatesInterface {
    readonly jetson1: DeviceStatus
    readonly jetson2: DeviceStatus
    readonly pi: DeviceStatus
    readonly mainDC: DCDeviceStatus
}

export interface CameraSettingsTemperatureInterface {
    temperatureControllerDataJ1: TemperatureControllerDataInterface
    temperatureControllerDataJ2: TemperatureControllerDataInterface
    temperatureControllerDataHeater: HeaterTemperatureControllerDataInterface
    temperatureControllerDataHeater1?: HeaterTemperatureControllerDataInterface
    temperatureControllerDataHeater2?: HeaterTemperatureControllerDataInterface
    temperatureControllerDataHeater3?: HeaterTemperatureControllerDataInterface
    temperatureControllerDataHeater4?: HeaterTemperatureControllerDataInterface
    temperatureControllerDataHeaterCam1?: HeaterTemperatureControllerDataInterface
    temperatureControllerDataHeaterCam2?: HeaterTemperatureControllerDataInterface
    temperatureControllerDataHeaterCam3?: HeaterTemperatureControllerDataInterface
}

export type CameraSettingsPatchInfoInterface = {
    readonly mode?: CameraOperationMode
    readonly temperatureControllerDataHeater?: HeaterTemperatureControllerFormInterface
    readonly temperatureControllerDataJ1?: TemperatureControllerFormInterface
    readonly temperatureControllerDataJ2?: TemperatureControllerFormInterface
    readonly temperatureControllerDataHeater1?: HeaterTemperatureControllerFormInterface
    readonly temperatureControllerDataHeater2?: HeaterTemperatureControllerFormInterface
    readonly temperatureControllerDataHeater3?: HeaterTemperatureControllerFormInterface
    readonly temperatureControllerDataHeater4?: HeaterTemperatureControllerFormInterface
    readonly temperatureControllerDataHeaterCam1?: HeaterTemperatureControllerFormInterface
    readonly temperatureControllerDataHeaterCam2?: HeaterTemperatureControllerFormInterface
    readonly temperatureControllerDataHeaterCam3?: HeaterTemperatureControllerFormInterface
}

export type ActiveHeatersChangeSubscriber = (reference: HeaterId[] | undefined) => void


export interface TemperatureControllerReviewInterface {
    readonly isOn: boolean
    readonly powerTitle?: string
    readonly power?: number
    readonly temperatureTitle?: string
    readonly temperature?: number
    readonly isDisabled?: boolean
}
