// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { TemperatureControllerMode } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature'
import TemperatureControllerModeSelector from '../../Cards/Jetson/TemperatureController/Compoments/ModeSelector/View'
import InputAnyForm from '../../../../Components/_Layout/InputAnyForm/View'

export default function InputTemperatureControllerModeForm(props: {
    mode: TemperatureControllerMode,
    isCorrect?: boolean,
    name: string,
    onModeChanged: (mode: TemperatureControllerMode) => void}) {
    return (
        <InputAnyForm name={props.name} form={(
            <TemperatureControllerModeSelector
                mode = { props.mode }
                isCorrect = { props.isCorrect }
                onChanged = { props.onModeChanged }/>
        )}/>
    )
}
