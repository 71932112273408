// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { SelectPreSet } from './TeamRefsPreSet';
import TeamRef from '../../../../Data/Analytics/AnalyticsTeamRef';
import { EMPTY_ARRAY } from '../../../../constants';
import { UserTeamRefAccess } from '../../../../Data/AccountUsers/UserWithoutID';


const calculateInitialValueCheckedIDs = (userTeamRefs: string[]): string[] => {
  if (userTeamRefs && userTeamRefs.length !== 0) {
    return userTeamRefs;
  }

  return EMPTY_ARRAY;
};

const calculateInitialValueTeamRefsPreSet = (selfTeamRefAccess: UserTeamRefAccess, teamRefsCount: number, checkedIDsCount: number, userTeamRefAccess: UserTeamRefAccess, isEditMode: boolean): SelectPreSet => {
  if (teamRefsCount > 0 && teamRefsCount <= checkedIDsCount) {
    return ((selfTeamRefAccess === UserTeamRefAccess.All && userTeamRefAccess === UserTeamRefAccess.All) ? SelectPreSet.RootAccess : SelectPreSet.All);
  }
  if (userTeamRefAccess === UserTeamRefAccess.All) {
    return SelectPreSet.RootAccess;
  }
  if (userTeamRefAccess === UserTeamRefAccess.Guest) {
    return SelectPreSet.Guest;
  }
  if (checkedIDsCount !== 0) {
    return SelectPreSet.SelectedList;
  }
  if (isEditMode && checkedIDsCount === 0) {
    return SelectPreSet.None;
  }

  return SelectPreSet.All;
}

const calculateTeamRefAccessByPreSet = (teamRefsPreSet: SelectPreSet): UserTeamRefAccess => {
  switch (teamRefsPreSet) {
    case SelectPreSet.SelectedList:
    case SelectPreSet.All:
      return UserTeamRefAccess.List;
    case SelectPreSet.RootAccess:
      return UserTeamRefAccess.All;
    case SelectPreSet.None:
      return UserTeamRefAccess.None;
    case SelectPreSet.Guest:
      return UserTeamRefAccess.Guest;
    default:
      return UserTeamRefAccess.None;
  }
};

const calculateSelectedTeamRefsByPreSet = (teamRefs: TeamRef[], editorTeamRefIdList: string[], teamRefsPreSet: SelectPreSet, withRootAccess: boolean): string[] | undefined => {
  switch (teamRefsPreSet) {
    case SelectPreSet.All:
      if (withRootAccess) {
        return [ ...teamRefs.map(({ id }) => id) ];
      }
      return [ ...editorTeamRefIdList ];
    case SelectPreSet.RootAccess:
      return [ ...teamRefs.map(({ id }) => id) ];
    case SelectPreSet.None:
    case SelectPreSet.Guest:
      return [ ...EMPTY_ARRAY ];
  }

  return undefined;
};

const calculateTeamRefPreSetBySelectedIDs = (teamRefsCount: number, checkedIDsCount: number): SelectPreSet => {
  if (teamRefsCount === checkedIDsCount) {
    return SelectPreSet.All;
  }
  if (checkedIDsCount !== 0) {
    return SelectPreSet.SelectedList;
  }

  return SelectPreSet.None;
};


export {
  calculateInitialValueCheckedIDs,
  calculateInitialValueTeamRefsPreSet,
  calculateTeamRefAccessByPreSet,
  calculateSelectedTeamRefsByPreSet,
  calculateTeamRefPreSetBySelectedIDs,
};
