// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import LOCALIZATION from "../../Localization";
import { intl } from "../../Localization/LocalizationProvider";

export enum AnalyticsUploadStatus {
    NotStarted = 'not_started', 
    Cancelled = 'cancelled', 
    Progress = 'progress', 
    Done = 'done', 
    Failed = 'failed'
}
  
export enum AnalyticsAnalyticsStatus {
    NotStarted = 'not_started', 
    Cancelled = 'cancelled', 
    Progress = 'progress', 
    Done = 'done', 
    WaitingTeamMapping = 'waiting_team_mapping',
    Failed = 'failed'
}

export let ANALYTICS_ANALYTICS_STATUS_ARR: AnalyticsAnalyticsStatus[] = [
    AnalyticsAnalyticsStatus.Done, 
    AnalyticsAnalyticsStatus.NotStarted, 
    AnalyticsAnalyticsStatus.WaitingTeamMapping, 
    AnalyticsAnalyticsStatus.Progress, 
    AnalyticsAnalyticsStatus.Cancelled, 
    AnalyticsAnalyticsStatus.Failed, 
];

export const isCanEditAnalyticsWithStatus = (status: AnalyticsAnalyticsStatus | undefined): boolean => {
    switch (status) {
        case AnalyticsAnalyticsStatus.WaitingTeamMapping:
        case AnalyticsAnalyticsStatus.Progress:
        case AnalyticsAnalyticsStatus.Done: {
            return false;
        }
        case AnalyticsAnalyticsStatus.NotStarted:
        case AnalyticsAnalyticsStatus.Failed: 
        case AnalyticsAnalyticsStatus.Cancelled: {
            return true;
        }
    }
    return false;
}

export const analyticsStatusName = (status: AnalyticsAnalyticsStatus | undefined, progress?: number) => {
    switch (status) {
        case AnalyticsAnalyticsStatus.Done: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_done });
        }
        case AnalyticsAnalyticsStatus.Cancelled: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_canceled });
        }
        case AnalyticsAnalyticsStatus.Failed: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_error });
        }
        case AnalyticsAnalyticsStatus.Progress: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_analytics_progress }, { percent: progress || 0 });
        }
        case AnalyticsAnalyticsStatus.NotStarted: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_ready_analysis });
        }
        case AnalyticsAnalyticsStatus.WaitingTeamMapping: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_waiting_team_mapping });
        }
    }
    return intl().formatMessage({ id: LOCALIZATION.unknown })
}

export const analyticsStatusNameForFilter = (status: AnalyticsAnalyticsStatus | undefined) => {
    switch (status) {
        case AnalyticsAnalyticsStatus.Done: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_filter_by_done });
        }
        case AnalyticsAnalyticsStatus.Cancelled: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_filter_by_canceled });
        }
        case AnalyticsAnalyticsStatus.Failed: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_filter_by_failed });
        }
        case AnalyticsAnalyticsStatus.Progress: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_filter_by_progress });
        }
        case AnalyticsAnalyticsStatus.NotStarted: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_filter_by_ready_analysis });
        }
        case AnalyticsAnalyticsStatus.WaitingTeamMapping: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_filter_by_waiting_team_mapping });
        }
    }
    return intl().formatMessage({ id: LOCALIZATION.unknown })
}

export const analyticsUploadStatusName = (status: AnalyticsUploadStatus | undefined, progress?: number) => {
    switch (status) {
        case AnalyticsUploadStatus.Done: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_waiting_markup });
        }
        case AnalyticsUploadStatus.Cancelled: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_canceled });
        }
        case AnalyticsUploadStatus.Failed: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_error });
        }
        case AnalyticsUploadStatus.Progress: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_uploading }, { percent: progress || 0 });
        }
        case AnalyticsUploadStatus.NotStarted: {
            return intl().formatMessage({ id: LOCALIZATION.analytics_ready_analysis });
        }
    }
    return intl().formatMessage({ id: LOCALIZATION.unknown })
}