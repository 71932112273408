// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import { queryCache } from '../../../AppProviders';
import ResponseMany from './ResponseMany';


function getAllFetchedItems<T>(key: QueryKey): ResponseMany<T[]> | undefined {
  const queries = queryCache.findAll(key);

  if (queries.length === 0) { return undefined; }

  const data: T[] = [];
  let total = 0;

  for (let i = 0; i < queries.length; i += 1) {
    const query = queries[i].state.data as ResponseMany<T[]> | undefined;

    if (query) {
      total = query.meta?.pagination?.total || 0;
      data.push.apply(data, query.data);
    }
  }

  return ({
    data,
    meta: {
      status: 200,
      pagination: {
        total,
        count: data.length,
        limit: 0,
        offset: 0,
      },
    },
  });
}


export {
  getAllFetchedItems,
};
