// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import cn from 'classnames';
import { TextField } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { FieldSetInputType } from '.';


export type ChangeText = (value: string) => void;

export type FieldSetInputText = Readonly<{
  readonly?: boolean;
  type?: FieldSetInputType.Text | FieldSetInputType.Password;
  rows?: number;
  maxRows?: number;
  value: string;
  errorCheckPattern?: (value: string) => boolean;
  onChange: ChangeText;
  onBlur?: () => void;
}>;

type Props = Readonly<{
  input: FieldSetInputText;
  isError?: boolean;
  disableAutocomplete?: boolean
  onIsError?: (isError: boolean) => void;
}>;


export const useStylesInputBox = makeStyles(() => createStyles({
  input: {
    paddingTop: '0px',
    paddingBottom: '0px',
    lineHeight: '38px',
    backgroundColor: '#fff',
  },
  singleLine: {
    height: '38px',
  },
  error: {
    border: 'solid 3px #EB5757',
  },
  errorLight: {
    border: 'solid 1px #EB5757',
  },
}));

const FieldSetText: React.FC<Props> = (props: Props) => {
  const [isError, setIsError] = React.useState<boolean>( false );

  const classes = useStylesInputBox();
  const {
    input: {
      rows = 1,
      maxRows,
    }
  } = props;
  const multiLine = (rows > 1);

  const checkOnValid = (value: string) => {
    if (props.input.errorCheckPattern) {
      const error = props.input.errorCheckPattern(value)
      if (props.onIsError) {
        props.onIsError(error);
      }
      else {
        setIsError(error)
      }
    }
  };

  if (props.input.errorCheckPattern && !props.onIsError) {
    const error = props.input.errorCheckPattern(props.input.value)
    if (error !== isError) {
      setIsError(error)
    }
  }

  // checkOnValid(props.input.value)
  
  const handleTextValueChanged = (event: React.SyntheticEvent) => {
    const value = ((event.target as HTMLInputElement).value || '');

    props.input.onChange(value);

    checkOnValid(value);
  };
  const handleBlur = () => {
    checkOnValid(props.input.value);

    if (props.input?.onBlur) {
      props.input.onBlur();
    }
  };

  const autoCompleteMode = (): string => {
    if (props.disableAutocomplete) {
      if (props.input.type === FieldSetInputType.Password) {
        return "new-password"
      }
      return "off"
    }
    else {
      if (props.input.type === FieldSetInputType.Password) {
        return "current-password"
      }
      return "on"
    }
  };

  return (
    <TextField
      fullWidth
      multiline={ multiLine }
      type={ props.input.type }
      variant="outlined"
      rows={ rows }
      maxRows={ maxRows }
      value={ props.input.value }
      InputProps={ {
        className: cn(classes.input, {
          [classes.singleLine]: !multiLine,
          [classes.error]: (props.isError && props.input.value) || isError,
          [classes.errorLight]: (props.isError && !props.input.value),
        }),
        readOnly: props.input.readonly,
      } }
      onChange={ handleTextValueChanged }
      onBlur={ handleBlur }
      autoComplete= { autoCompleteMode() }
    />
  );
};


export default FieldSetText;
