// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { SyntheticEvent } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

type Props = Readonly<{
    id: string | number;
    name?: string;
    icon?: OverridableComponent<any>
    onClick?: (id: string | number) => void;
} & WrappedComponentProps>;

const Item: React.FC<Props> = (props: Props) => {
    if (!props.onClick) { return null; }

    const Icon = props.icon

    const handleClick = (event: SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();
        if (props.onClick) {
          props.onClick(props.id);
        }
      };

    return (
        <MenuItem
            onClick={ handleClick }
        >
            { (Icon) &&
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
            }
            <ListItemText>
                { props.name }
            </ListItemText>
        </MenuItem>
    );
};


export default injectIntl(Item);
