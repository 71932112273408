// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import {
  UseFetchProps,
  useFetchStatSpeed,
  flushCache,
  getCached,
} from '../fetch';
import { AnalyticsStatSpeed } from '../AnalyticsStatSpeed';
import { ResultStatus } from '../../../_Networking/ReactQuery/template';


type Result = Readonly<{
  status: ResultStatus;
  data: AnalyticsStatSpeed | undefined;
  handleFlushData: () => void;
}>;


export const useAnalyticsStatSpeed = (props: UseFetchProps): Result => {
  const fetchedArchive = useFetchStatSpeed(props);
  const data = getCached(props);

  return ({
    status: fetchedArchive.status as ResultStatus,
    data: data?.data,
    handleFlushData: flushCache,
  });
};

