// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { ObservableDataProvider } from '../../Common/ObservableDataProvider'
import { MetricsDataItem, MetricsSourceInterface, MetricDataChangeSubscriber } from './Types'

export interface MetricsDataProviderInterface {
    readonly data: MetricsDataItem[]
    readonly updateInterval: number
    updateSource(source: MetricsSourceInterface): void
    subscribeOnChanges(listener: MetricDataChangeSubscriber): void
    unsubscribeFromChanges(listener: MetricDataChangeSubscriber): void
    reset(): void
}

export abstract class MetricsDataProviderBase extends ObservableDataProvider<MetricsDataItem[]> implements MetricsDataProviderInterface {
    data: MetricsDataItem[] = []
    abstract get updateInterval(): number
    abstract update(): Promise<void>
    abstract updateSource(source: MetricsSourceInterface): void
}

export interface MetricsOwnerInterface {
    createDataProvider(source: MetricsSourceInterface): MetricsDataProviderInterface
}
