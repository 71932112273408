// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { SyntheticEvent } from 'react';

import Item from './item';
import { Button, Menu } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export type AddMenuItem = Readonly<{
  id: number | string;
  title: string;
  icon?: OverridableComponent<any>
}>;

export type AddDropdownProps<T> = Readonly<{
  getAddMenuItems?: (parentItem: T) => AddMenuItem[] | undefined;
  onAddMenuItemSelect?: (parentItem: T, selectedMenuItem?: AddMenuItem) => void;
}>;

type Props<T> = Readonly<{
  buttonIcon?: OverridableComponent<any>
  parentItem: T;
} & AddDropdownProps<T>>;


const AddDropdownButton = <T, >(props: Props<T>): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event?: {}, reason?: "backdropClick" | "escapeKeyDown"): void => {
    if (event) {
      const e = event as SyntheticEvent
      if (e.stopPropagation) {
        e.stopPropagation();
      }
    }
    setAnchorEl(null);
  };

  const menuItems = props.getAddMenuItems?.(props.parentItem);

  const handleItemClick = (id: number | string): void => {
    if (props.onAddMenuItemSelect) {
      const selectedItem = menuItems?.find((item) => (item.id === id));
      props.onAddMenuItemSelect(props.parentItem, selectedItem);
    }

    setAnchorEl(null);
  };

  const MenuIcon = props.buttonIcon || AddCircleIcon

  return (
    <>
      <Button
        id="add-button"
        aria-controls="add-menu"
        aria-haspopup="true"
        aria-expanded={ open ? 'true' : undefined }
        disabled={ !menuItems?.length }
        onClick={ handleClick }
        sx={ {
          minWidth: 'auto',
          cursor: 'pointer',
        } }
      >
        <MenuIcon/>
      </Button>
      <Menu
        id="add-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
        MenuListProps={ {
          'aria-labelledby': 'basic-button',
        } }
      >
        {
          menuItems?.map((item) => {
            return <Item
                id={ item.id }
                name={ item.title }
                icon= { item.icon }
                onClick={ handleItemClick }
            />
          })
        }
      </Menu>
    </>
  );
};


export default AddDropdownButton;
