// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { SelectPreSet } from './CamerasPreSet';
import Camera from '../../../../Data/Camera/ActiveCamera/Camera';
import { EMPTY_ARRAY } from '../../../../constants';
import { UserCameraAccess } from '../../../../Data/AccountUsers/UserWithoutID';


const calculateInitialValueCheckedIDs = (userCameras: string[]): string[] => {
  if (userCameras && userCameras.length !== 0) {
    return userCameras;
  }

  return EMPTY_ARRAY;
};

const calculateInitialValueCamerasPreSet = (currentUserWithRootAccess:boolean, camerasCount: number, checkedIDsCount: number, userCameraAccess: UserCameraAccess, isEditMode: boolean): SelectPreSet => {
  if (camerasCount <= checkedIDsCount) {
    return ((currentUserWithRootAccess && userCameraAccess === UserCameraAccess.All) ? SelectPreSet.RootAccess : SelectPreSet.All);
  }
  if (checkedIDsCount !== 0) {
    return SelectPreSet.SelectedList;
  }
  if (isEditMode && checkedIDsCount === 0) {
    return SelectPreSet.None;
  }

  return SelectPreSet.All;
}

const calculateCameraAccessByPreSet = (camerasPreSet: SelectPreSet): UserCameraAccess => {
  switch (camerasPreSet) {
    case SelectPreSet.SelectedList:
    case SelectPreSet.All:
      return UserCameraAccess.List;
    case SelectPreSet.RootAccess:
      return UserCameraAccess.All;
    case SelectPreSet.None:
      return UserCameraAccess.None;
    default:
      return UserCameraAccess.None;
  }
};

const calculateSelectedCamerasByPreSet = (cameras: Camera[], editorCameraIdList: string[], camerasPreSet: SelectPreSet, withRootAccess: boolean): string[] | undefined => {
  switch (camerasPreSet) {
    case SelectPreSet.All:
      if (withRootAccess) {
        return [ ...cameras.map(({ cameraId }) => cameraId) ];
      }
      return [ ...editorCameraIdList ];
    case SelectPreSet.RootAccess:
      return [ ...cameras.map(({ cameraId }) => cameraId) ];
    case SelectPreSet.None:
      return [ ...EMPTY_ARRAY ];
  }

  return undefined;
};

const calculateCameraPreSetBySelectedIDs = (camerasCount: number, checkedIDsCount: number): SelectPreSet => {
  if (camerasCount === checkedIDsCount) {
    return SelectPreSet.All;
  }
  if (checkedIDsCount !== 0) {
    return SelectPreSet.SelectedList;
  }

  return SelectPreSet.None;
};


export {
  calculateInitialValueCheckedIDs,
  calculateInitialValueCamerasPreSet,
  calculateCameraAccessByPreSet,
  calculateSelectedCamerasByPreSet,
  calculateCameraPreSetBySelectedIDs,
};
