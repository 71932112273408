// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { IntlShape } from "react-intl";
import { cloneDeep } from 'lodash';
import { AnalyticsStatAttackDefenseForTime, AnalyticsStatAttackDefenseTeam } from "../../../Data/Analytics/AnalyticsStatAttackDefenseTime/AnalyticsStatAttackDefenseTime";
import { AnalyticsStatDistanceForTime, AnalyticsStatDistanceGame } from "../../../Data/Analytics/AnalyticsStatDistance/AnalyticsStatDistance";
import { AnalyticsStatPossessionForTime, AnalyticsStatPossessionTeam } from "../../../Data/Analytics/AnalyticsStatPossession/AnalyticsStatPossession";
import { AnalyticsStatScoreTeam, AnalyticsStatScoresForTime } from "../../../Data/Analytics/AnalyticsStatScore/AnalyticsStatScore";
import { AnalyticsStatSpeedForTime, AnalyticsStatSpeedTeam } from "../../../Data/Analytics/AnalyticsStatSpeed/AnalyticsStatSpeed";
import { AnalyticsStatGranularity, AnalyticsTimeIntervalTypeHeader, AnalyticsTimeIntervalTypeHeaderAny, analyticsTimeIntervalName } from "../../../Data/Analytics/AnalyticsTypes";
import LOCALIZATION from "../../../Localization";
import { intl } from "../../../Localization/LocalizationProvider";
import { AnalyticsConvertedDataType, AnalyticsConvertedItem, analyticsAnalyticsConvertedDataTypeName, analyticsConvertAttackTime, analyticsConvertAvgSpeed, analyticsConvertAvgTimeInPossession, analyticsConvertDefenseTime, analyticsConvertDistanceCovered, analyticsConvertIntensity, analyticsConvertPossession, analyticsConvertScore } from "../../../Data/Analytics/AnalyticsConverters";


export type BarLineChartItemData = Readonly<{
    name: string;
    value1: number;
    value2: number;
}>;

export type BarLineChartData = {
    [key in AnalyticsConvertedDataType]?: BarLineChartItemData
}

const analyticsConvertedDataToBarLineChartItemData = (name: string, convertedData?: AnalyticsConvertedItem): BarLineChartItemData => {
    return {
        name: name,
        value1: convertedData?.team1.value || 0,
        value2: convertedData?.team2.value || 0,
    }
}

export enum AnalyticsHorizBarVisualiseMode {
    Bar = 'bar',
    Line = 'line',
}
export const ANALYTICS_HOR_BAR_VISUALISE_MODE_ARR = [
    AnalyticsHorizBarVisualiseMode.Bar, AnalyticsHorizBarVisualiseMode.Line
]

export const analyticsHorizBarVisualiseModeName = (type?: AnalyticsHorizBarVisualiseMode): string => {
    switch (type) {
        case AnalyticsHorizBarVisualiseMode.Bar: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_visualize_mode_bar }) 
        case AnalyticsHorizBarVisualiseMode.Line: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_visualize_mode_line }) 
    }
    return intl().formatMessage({ id: LOCALIZATION.unknown }) 
}

export const isBarLineChartDataNotEmpty = (data?: BarLineChartData): boolean => {
    if (!data) {
        return false;
    }

    for(const val of Object.values(AnalyticsConvertedDataType)) {
        if (data[val]) {
            return true;
        }
    }
    return false;
}

export type BarLineChartItem = {
    id: number; // index to group together same time interval for all data
    timeName: string;// like: Half 1, Half 2,  Overtime
    itemName: string;// like: 1/2, 1/8, 2/8
    titleForTooltip: string;// like: "1-5 minutes statistics"
    data: BarLineChartData;
    child?: BarLineChartItem[];
    hiddenData?: BarLineChartData;// used when make fake items
};

export type BarLineChartStatType = {
    index: AnalyticsConvertedDataType;
    name: string;
};

export type BarLineChartConfig = {
    availableStats: BarLineChartStatType[];// if stat exist in data then is is presented in this list
    data: BarLineChartItem[];
};

function getGranulaNamesArr (
    granularity: AnalyticsStatGranularity,
    timeName: string,
    durationSec: number,
): string[] {
    let granulaNamesArr: string[] = []
    let granulaCount = 0;

    switch (granularity) {
        case AnalyticsStatGranularity.Granularity0: {
            break;
        }
        case AnalyticsStatGranularity.Granularity2: {// 2 = 1/2 ; Half
            // return ["1/2", timeName];
            granulaCount = 2;
            break;
        }
        case AnalyticsStatGranularity.Granularity4: {// 4 = 1/4 ; 1/2; 3/4; Half
            // return ["1/4", "1/2", "3/4", timeName];
            granulaCount = 4;
            break;
        }
        case AnalyticsStatGranularity.Granularity8: {// 8 = 1/8 ; 1/4; 3/8; 1/2
            // return ["1/8", "1/4", "3/8", "1/2", "5/8", "3/4", "7/8", timeName];
            granulaCount = 8;
            break;
        }
    }
    
    const granulaDurationSec = durationSec / granulaCount;
    for(let index = 0; index < granulaCount; index++) {
        const startMinutes = Math.floor(granulaDurationSec * index / 60);
        const endMinutes = Math.floor(granulaDurationSec * (index + 1) / 60);

        granulaNamesArr.push(`${startMinutes+1}-${endMinutes}`);
    }
    // granulaNamesArr.push(timeName);

    return granulaNamesArr;
}

export function createTemplateBarLineChartItemsArr(
    granularity: AnalyticsStatGranularity,
    scoresIntervals?: AnalyticsStatScoresForTime[],
    possessionIntervals?: AnalyticsStatPossessionForTime[],
    distanceIntervals?: AnalyticsStatDistanceForTime[],
    speedIntervals?: AnalyticsStatSpeedForTime[],
    attackDefenseIntervals?: AnalyticsStatAttackDefenseForTime[],
): BarLineChartItem[] {
    let tempItems: BarLineChartItem[] = [];

    let longestInterval: AnalyticsTimeIntervalTypeHeader[] | undefined =
        [scoresIntervals, possessionIntervals, distanceIntervals, speedIntervals, attackDefenseIntervals]
        .sort((a, b) => (a?.length || 0) - (b?.length || 0)).shift();

    // fill root items with longest interval
    if (longestInterval) {
        const emptyData: BarLineChartData = {};
        const emptyValue = {
            name: "",
            value1: 0,
            value2: 0,
        }
        for(const key of Object.values(AnalyticsConvertedDataType)) {
            emptyData[key] = emptyValue;
        }


        let intervalsArr = longestInterval as AnalyticsTimeIntervalTypeHeaderAny[];
        let index = 0; 
        for(const item of intervalsArr) {
            const timeName = analyticsTimeIntervalName(item.type, item.type_order_num);
            const durationSec = item.duration || 45 * 60;

            let child: BarLineChartItem[] = [];
            if (granularity !== AnalyticsStatGranularity.Granularity0 && item.granules) {
                const granulaNamesArr = getGranulaNamesArr(granularity, timeName, durationSec);
                for(let cIndex = 0; cIndex < granulaNamesArr.length; cIndex++) {
                    child.push({
                        id: index * 100 + cIndex,
                        timeName,
                        itemName: granulaNamesArr[cIndex] + "'",
                        titleForTooltip: `${timeName}, ${granulaNamesArr[cIndex]} ${intl().formatMessage({ id: LOCALIZATION.minutes })}`,
                        data: cloneDeep(emptyData),
                    })
                }
            }

            tempItems.push({
                id: index,
                timeName,
                itemName: timeName,
                titleForTooltip: timeName,
                data: cloneDeep(emptyData),
                child,
            })
            index ++;
        }
    }

    return tempItems;
}


export function createBarLineChartConfigForMainReportDetails(
    intl: IntlShape,
    granularity: AnalyticsStatGranularity,
    scoresIntervals?: AnalyticsStatScoresForTime[],
    possessionIntervals?: AnalyticsStatPossessionForTime[],
    distanceIntervals?: AnalyticsStatDistanceForTime[],
    speedIntervals?: AnalyticsStatSpeedForTime[],
    attackDefenseIntervals?: AnalyticsStatAttackDefenseForTime[],
): BarLineChartConfig {
    // create filled template array with all names
    let outItems: BarLineChartItem[] = createTemplateBarLineChartItemsArr(
        granularity, scoresIntervals, possessionIntervals, distanceIntervals, speedIntervals, attackDefenseIntervals );

    let availableStats: BarLineChartStatType[] =[];


    function fillDataWithIntervals<E, T extends {
        interval_stat?: E;
        granules?: E[];
    }>
        (type: AnalyticsConvertedDataType, 
        intervalsArr: Array<T> | undefined, 
        converter:((item: E | undefined) => AnalyticsConvertedItem | undefined) ) 
    {
        const getNameForDataIndex = (index: AnalyticsConvertedDataType, firstConverted: AnalyticsConvertedItem | undefined): string => {
            const name = analyticsAnalyticsConvertedDataTypeName(index, firstConverted);
            availableStats.push({
                index, name
            })
            return name;
        }

        if (intervalsArr && intervalsArr.length) {
            const name = getNameForDataIndex(type, converter(intervalsArr[0].interval_stat));
    
            let index = 0;
            for(const stat of intervalsArr) {
                const item = outItems[index];
    
                // score
                item.data[type] = analyticsConvertedDataToBarLineChartItemData(name, converter(stat.interval_stat));
    
                // have additonal granulas
                if (granularity !== AnalyticsStatGranularity.Granularity0 && stat.granules) {
                    const granulaCount = Math.min(item.child?.length || 0, stat.granules.length);
                    for(let cIndex = 0; cIndex < granulaCount; cIndex++) {
                        const subItem = item.child?.[cIndex];
                        const subStat = stat.granules[cIndex];
                        if (subItem && subStat) {
                            subItem.data[type] = analyticsConvertedDataToBarLineChartItemData(name, converter(subStat));
                        }
                    }                   
                }
    
                index ++;
            }
        }
    }

    // fill score
    fillDataWithIntervals<AnalyticsStatScoreTeam, AnalyticsStatScoresForTime>(
        AnalyticsConvertedDataType.score,
        scoresIntervals,
        (item) => analyticsConvertScore(item) );
 
    // fill possession
    fillDataWithIntervals<AnalyticsStatPossessionTeam, AnalyticsStatPossessionForTime>(
        AnalyticsConvertedDataType.possession,
        possessionIntervals,
        (item) => analyticsConvertPossession(item) );

    // fill avg time in possession
    fillDataWithIntervals<AnalyticsStatPossessionTeam, AnalyticsStatPossessionForTime>(
        AnalyticsConvertedDataType.avgTimeInPossession,
        possessionIntervals,
        (item) => analyticsConvertAvgTimeInPossession(item) );
   
    // fill distance
    fillDataWithIntervals<AnalyticsStatDistanceGame, AnalyticsStatDistanceForTime>(
        AnalyticsConvertedDataType.distanceCovered,
        distanceIntervals,
        (item) => analyticsConvertDistanceCovered(item) );
       
    // fill avg speed
    fillDataWithIntervals<AnalyticsStatSpeedTeam, AnalyticsStatSpeedForTime>(
        AnalyticsConvertedDataType.avgSpeed,
        speedIntervals,
        (item) => analyticsConvertAvgSpeed(item) );
              
    // fill intensity
    fillDataWithIntervals<AnalyticsStatSpeedTeam, AnalyticsStatSpeedForTime>(
        AnalyticsConvertedDataType.intensity,
        speedIntervals,
        (item) => analyticsConvertIntensity(item) );

    // fill attack time
    fillDataWithIntervals<AnalyticsStatAttackDefenseTeam, AnalyticsStatAttackDefenseForTime>(
        AnalyticsConvertedDataType.attackTime,
        attackDefenseIntervals,
        (item) => analyticsConvertAttackTime(item) );
       
    // fill defense time
    fillDataWithIntervals<AnalyticsStatAttackDefenseTeam, AnalyticsStatAttackDefenseForTime>(
        AnalyticsConvertedDataType.defenseTime,
        attackDefenseIntervals,
        (item) => analyticsConvertDefenseTime(item) );

    // flat all data if need
    if (granularity !== AnalyticsStatGranularity.Granularity0) {
        let flatOutItems: BarLineChartItem[] = []
        let index = 0;

        const emptyValue: BarLineChartItemData = {
            name: "",
            value1: 0,
            value2: 0,
        }

        for(const item of outItems) {
            if (item.child) {
                for (const subItem of item.child) {
                    if (subItem) { /*&& isBarLineChartDataNotEmpty(item.data)*/
                        flatOutItems.push({...subItem, id: index});
                        index ++;
                    }
                }
                // add fake divider for parent element
                const fakeEndItem = {
                    ...item, 
                    id: index,
                    hiddenData: cloneDeep(item.data)
                }
                for(const key of Object.values(AnalyticsConvertedDataType)) {
                    fakeEndItem.data[key] = emptyValue;
                }
                flatOutItems.push(fakeEndItem);
                index ++;
            }
            else {
                flatOutItems.push({...item, id: index});
                index ++;
            }
        }

        outItems = flatOutItems;
    }

    return {
        availableStats,
        data: outItems,
    }
}