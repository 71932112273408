// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useMutation } from 'react-query';

import Event from '../Event';
import { PREFIX_OF_A_COMPOSITE_KEY } from '.';
import { deleteEvent, disableEvent, EPGFormatType, importEpgEvent, postEvent, recoverDeleteEvent } from './index';
import { queryCache, queryClient } from '../../../../AppProviders';
import EventEditable, { GameType, SportType } from '../EventWithoutID';


export enum ActionType {
  Post = 'post',
  Delete = 'delete',
  RecoverDelete = 'recoverDelete',
  Disable = 'disable',
  Enable = 'enable',
  ImportEpg = 'importEpg',
}

export type Action = Readonly<{
  event?: Event | EventEditable;
  IDs?: number[];
  success?: boolean;
  streamingProfileId?: string | null;
  epgFileDataInBase64?: string | null;
  epgFileDataUrl?: string | null;
  epgFileUpdatePeriodInSec?: number | null;
  epgFormatType?: EPGFormatType | null;
  epgDefaultGameType?: GameType | null,
  epgDefaultSportType ?: SportType | null,
  type: ActionType;
}>;


const useMutationTeam = () => {
  return useMutation(
    (action: Action) => {
      switch (action.type) {
        case ActionType.Post: {
          return postEvent(action.event as Event);
        }
        case ActionType.Delete: {
          return deleteEvent(action.IDs as number[]);
        }
        case ActionType.RecoverDelete: {
          return recoverDeleteEvent(action.IDs as number[]);
        }
        case ActionType.Disable: {
          return disableEvent(action.IDs as number[], true);
        }
        case ActionType.Enable: {
          return disableEvent(action.IDs as number[], false);
        }
        case ActionType.ImportEpg: {
          return importEpgEvent(action.streamingProfileId, action.epgFileDataInBase64, action.epgFileDataUrl, action.epgFileUpdatePeriodInSec, action.epgFormatType, action.epgDefaultGameType, action.epgDefaultSportType);
        }
        default:
          return Promise.reject(new Error('Invalid Account Mutation Action'));
      }
    },
    {
      onSuccess: () => {
        const queries = queryCache.findAll([[PREFIX_OF_A_COMPOSITE_KEY]]);

        if (queries && queries.length) {
          queries.forEach(({ queryKey }) => {
            queryClient.removeQueries(queryKey);
          })
        }
      },
    },
  );
};


export default useMutationTeam;
