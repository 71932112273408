// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>


export type AnalyticsConfigUrls = Readonly<{
    analyticsVideoTemplate?: string;// "/an_data/dc4kUGJ9Bi6j79_HY93XyQ,1682498147/eyJjY2lkIjoiY2NhbS1ub3Ytc2VydmVyIn0/an_video/CC_PATH/{video_path}"
    analyticsVideoPlayerTemplate?: string; // "/an_data/dc4kUGJ9Bi6j79_HY93XyQ,1682498147/eyJjY2lkIjoiY2NhbS1ub3Ytc2VydmVyIn0/an_video/CC_PATH/player/iframe.html?url=../an_subdir/{video_dir}",
    analyticsVideoImageTemplate?: string; // "/an_data/Y5WS5tivoJhzPQZUC97U0Q,1682498147/eyJjY2lkIjoiY2NhbS1ub3Ytc2VydmVyIn0/an_image/an_video/CC_PATH/{an_video_image_path}",
    analyticsImageTemplate?: string; //  "/an_data/hUalVIE750nay_mSltciOg,1682498147/eyJjY2lkIjoiY2NhbS1ub3Ytc2VydmVyIn0/an_image/an_static/CC_PATH/{an_static_image_path}",
}>;

export type AnalyticsConfigConfig = Readonly<{
}>;
  
export type AnalyticsConfig = Readonly<{
    urls?: AnalyticsConfigUrls;
    config?: AnalyticsConfigConfig;
}>;
  
export const makeAnalyticsVideoImageUrl = (config: AnalyticsConfig | undefined, imageSubUrl:string | undefined) : string | undefined => {
    if (!imageSubUrl?.length || !config?.urls?.analyticsVideoImageTemplate) {
        return undefined
    }
    // remove start / because analyticsVideoImageTemplate already have it
    imageSubUrl = (imageSubUrl.charAt(0) === "/") ? imageSubUrl.substring(1) : imageSubUrl;

    return config.urls.analyticsVideoImageTemplate?.replace("{an_video_image_path}", imageSubUrl);
}
  
export const makeAnalyticsVideoPlayerUrl = (config: AnalyticsConfig | undefined, videoSubUrl:string | undefined) : string | undefined => {
    if (!videoSubUrl?.length || !config?.urls?.analyticsVideoPlayerTemplate) {
        return undefined
    }
    // remove start / because analyticsVideoImageTemplate already have it
    videoSubUrl = (videoSubUrl.charAt(0) === "/") ? videoSubUrl.substring(1) : videoSubUrl;

    return config.urls.analyticsVideoPlayerTemplate?.replace("{video_dir}", videoSubUrl);
}