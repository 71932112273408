// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import {
  HeaterTemperatureControllerDataInterface,
  HeaterTemperatureControllerFormInterface,
} from '../../../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature'
import {
  CameraInterface,
} from '../../../../../../Data/Camera/HWCameraSettings/Camera'
import { useCameraData } from '../../../../../../Data/Camera/HWCameraSettings/hook/Hooks'
import { CameraNotAvailable, safeNumber } from '../../../../../../Tools/Tools'
import { useHeaterTemperatureControllerForm } from './Hooks'
import CameraContext from '../../../../../../Data/Camera/HWCameraSettings/fetch/Context'
import InputTextForm from '../../../../Components/InputTextForm/View'
import InputTemperatureControllerModeForm from '../../../../Components/InputTemperatureControllerModeForm/View'
import InputHeaterTemperatureControllerSource from '../../../../Components/InputHeaterTemperatureControllerSource/View'
import { HeaterId } from '../../../../../../Data/Camera/Metrics/Types'
import { InputFieldType } from '../../../../../../Components/_BaseUI/InputField/InputField';
import LOCALIZATION from '../../../../../../Localization';


type Props = Readonly<{
  camera: CameraInterface;
  heaterId: HeaterId;
  originalData: HeaterTemperatureControllerDataInterface;
} & WrappedComponentProps>;

const Render: React.FC<Props> = (props: Props) => {
  const { camera, heaterId, originalData } = props;
  const isCameraOn = camera.status === 'on'

  // Если есть камера, то есть и контекст.
  const dataProvider = CameraContext.current!.cameraDataProvider

  // Нам не нужно ггонять лишние данные, только те, которые предусмотрены формой
  const initialForm: HeaterTemperatureControllerFormInterface = {
    start: originalData.start,
    stop: originalData.stop,
    critical: originalData.critical,
    mode: originalData.mode,
    currentSource: originalData.currentSource
  }

  const {
    onChangeMode,
    onChangeSource,
    onChangeStart,
    onChangeStop,
    onChangeCritical,
    form,
    validation
  } = useHeaterTemperatureControllerForm(submitForm, initialForm);

  if (!originalData) { return null; }

  async function submitForm(form: HeaterTemperatureControllerFormInterface): Promise<HeaterTemperatureControllerFormInterface> {
    const data = await dataProvider.setHeaterTemperatureControllerData(heaterId, form) as HeaterTemperatureControllerFormInterface
    return data
  }

  let isStartOk = validation.start
  let isStopOk = validation.stop
  let isCriticalOk = validation.critical
  const isModeOk = validation.mode
  const isSourceOk = validation.currentSource
  const sources = originalData.sources
  const lastData = originalData.lastData

  const handleStartChange = (text: string) => {
    const value = safeNumber(text)
    value !== undefined ? onChangeStart(value) : isStartOk = false
  }

  const handleStopChange = (text: string) => {
    const value = safeNumber(text)
    value !== undefined ? onChangeStop(value) : isStopOk = false
  }

  const handleCriticalChange = (text: string) => {
    const value = safeNumber(text)
    value !== undefined ? onChangeCritical(value) : isCriticalOk = false
  }

  const modeInput = (
    <InputTemperatureControllerModeForm name={ props.intl.formatMessage({ id: LOCALIZATION.mode }) } mode={ form.mode ?? 'auto' } isCorrect={ isModeOk }
                                        onModeChanged={ onChangeMode }/>
  )
  const sourceInput = (
    <InputHeaterTemperatureControllerSource name={ props.intl.formatMessage({ id: LOCALIZATION.source }) } sources={ sources } lastData={ lastData }
                                            currentSource={ form.currentSource } isCorrect={ isSourceOk }
                                            onSourceChanged={ onChangeSource }/>
  )
  const startInput = (
    <InputTextForm name={ props.intl.formatMessage({ id: LOCALIZATION.start_when_source_less }) } inputFieldType={ InputFieldType.Temperature }
                   defaultValue={ form.start?.toFixed(1) } isCorrect={ isStartOk } onTextChanged={ handleStartChange }/>
  )
  const stopInput = (
    <InputTextForm name={ props.intl.formatMessage({ id: LOCALIZATION.stop_when_source_more }) } inputFieldType={ InputFieldType.Temperature }
                   defaultValue={ form.stop?.toFixed(1) } isCorrect={ isStopOk } onTextChanged={ handleStopChange }/>
  )
  const criticalInput = (
    <InputTextForm name={ props.intl.formatMessage({ id: LOCALIZATION.critical_source_temp }) } inputFieldType={ InputFieldType.Temperature }
                   defaultValue={ form.critical?.toFixed(1) } isCorrect={ isCriticalOk }
                   onTextChanged={ handleCriticalChange }/>
  )

  return !isCameraOn
    ? (<></>)
    : (
      <Grid container spacing={ 1 }>
        { [modeInput, sourceInput, startInput, stopInput, criticalInput].map((element, index) => (
          <Grid item key={ index } xs={ 12 }>{ element }</Grid>
        )) }
      </Grid>
    )
};


const HeaterTemperatureController = (props: { id: HeaterId } & WrappedComponentProps) => {
  const id = props.id
  const camera = useCameraData()
  if (!camera) {
    return <CameraNotAvailable/>
  }

  const originalData = camera.getHeaterTemperatureControllerData(id)!

  if (!originalData) { return null; }

  return (
    <Render
      camera={ camera }
      heaterId={ id }
      originalData={ originalData }
      intl={ props.intl }
    />
  );
}

export default injectIntl(HeaterTemperatureController);
