// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Properties } from 'csstype';


type Props = Readonly<{
    gridTemplateColumns: string;
    style?: Properties;
    children: any;
}>;


const GridContainer: React.FC<Props> = (props: Props) => {
    const style: Properties = {
        display: 'inline-grid',
        width: '100%',
        padding: '16px 0',
        margin: '0 0 21px 0',
        gridTemplateColumns: props.gridTemplateColumns,
        gridGap: '10px',
        alignItems: 'center',
        ...props.style,
    };

    return (
        <div
            style={ style }
        >
            { props.children }
        </div>
    );
};


export default GridContainer;
