// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React  from 'react';
import { indexOf } from 'lodash';
import { useIntl } from 'react-intl';
import { createStyles, makeStyles } from '@mui/styles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TableCellProps, TableRow, TableCell, Checkbox } from '@mui/material';

import { TypeID } from './index';
import { HeadCell } from './SortedTableHead';
import SortedTableBodyCell from './SortedTableBodyCell';
import LOCALIZATION from '../../Localization';
import MenuDropdownButton, { MenuDropdownAction } from '../_BaseUI/MenuDropdownButton';
import AddDropdownButton, { AddDropdownProps } from '../_BaseUI/AddDropdownButton';


export type RenderCellContentValue = {
  cellProps?: TableCellProps;
  content: JSX.Element | string | number | React.ReactNode;
};

export type RenderCellContent<T> = (item: T, cellName: keyof T, index: number) => RenderCellContentValue;

type Props<T> = Readonly<{
  headCells: HeadCell<T>[];
  row: T;
  allItems: T[];
  selected?: T[];
  renderCellContent?: RenderCellContent<T>;
  rowClassName?: (row: T, allItems: T[]) => RowClassName | null;
  onClick?: (row: T) => void;
  onSelect?: (item: T) => void;
  showRemoveButton: boolean;
  showMenuButton: boolean;
  showAddButton: boolean;
  onRemoveIconButtonClicked?: (selected: T) => void;
  onMenuEdit?: (selected: T) => void;
  onMenuCopy?: (selected: T) => void;
  onMenuDelete?: (selected: T) => void;
  customActions?: MenuDropdownAction<T>[]
} & AddDropdownProps<T>>;

export enum RowClassName {
  Blue = 'blue',
  Red = 'red',
  LightGray = 'lightGray',
}


const useStyles = makeStyles(() => createStyles({
  blue: {
    backgroundColor: 'rgba(196, 243, 195, 0.24)',
  },
  red: {
    backgroundColor: 'rgba(243, 196, 195, 0.24)',
  },
  lightGray: {
    backgroundColor: 'rgba(235, 235, 235, 0.24)',
  },
}));

const SortedTableBodyRow = <T extends { id: TypeID }, >(props: Props<T>): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const isSelected = (item: T) => (props.selected && props.selected.length !== 0) ?
    (indexOf<T>(props.selected, item) !== -1)
    :
    false;
  const { id = 0 } = props.row as T;
  const isItemSelected = isSelected(props.row);
  const labelId = `enhanced-table-checkbox-row-${id}`;
  const handleSelect = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (props?.onSelect) {
      props.onSelect(props.row);
    }
  };
  const handleClick = (event: React.SyntheticEvent<HTMLTableRowElement, Event>) => {
    event.stopPropagation();
    if (props?.onClick) {
      props.onClick(props.row);
    }
  };

  const renderCells = (
    props.headCells.filter((item)=>( !item.hidden )).map((cell: HeadCell<T>, index: number) => (
      <SortedTableBodyCell<T>
        key={ cell.id as number }
        index={ index }
        cell={ cell }
        labelId={ labelId }
        row={ props.row }
        renderCellContent={ props.renderCellContent }
        sx= {{
          height: 53
        }}
      />
    ))
  );

  const userClassName = props?.rowClassName && props.rowClassName(props.row, props.allItems);
  const className = (userClassName && classes?.[userClassName]) ?
    classes[userClassName]
    :
    '';
  const renderRemoveButton = () => {
    if (!props.onRemoveIconButtonClicked) { return null; }

    const handleRemoveClicked = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.stopPropagation();
      if (props.onRemoveIconButtonClicked) {
        props.onRemoveIconButtonClicked(props.row);
      }
    };

    return (
      <TableCell
        align="center"
        padding="checkbox"
      >
        {
        (props.showRemoveButton) &&
          <HighlightOffIcon
            titleAccess={ intl.formatMessage({ id: LOCALIZATION.remove }) }
            sx={ {
              display: 'block',
              margin: '5px',
              fill: 'red',
              cursor: 'pointer',
            } }
            onClick={ handleRemoveClicked }
          />
        }
      </TableCell>
    );
  };

  const renderAddButton = () => {
    if (!props.getAddMenuItems) { 
      return null; 
    }

    return (
      <TableCell
        align="center"
        padding='none'
      >
        {
          (props.showAddButton) &&
            <AddDropdownButton<T>
              parentItem={ props.row }
              getAddMenuItems={ props.getAddMenuItems }
              onAddMenuItemSelect={ props.onAddMenuItemSelect }
            />
        }
      </TableCell>
    );
  };

  const renderMenuButton = () => {
    if (!props.onMenuCopy && !props.onMenuDelete && !props.onMenuEdit && !props.customActions?.find((action) => (!!action.onMenuClick))) { 
      return null; 
    }

    return (
      <TableCell
        align="center"
        padding='none'
      >
        {
          (props.showMenuButton) &&
            <MenuDropdownButton<T>
              item={ props.row }
              onEdit={ props.onMenuEdit }
              onCopy={ props.onMenuCopy }

              customActions={ props.customActions }

              onDelete={ props.onMenuDelete }
            />
        }
      </TableCell>
    );
  };

  return (
    <TableRow
      hover
      onClick={ (props?.onClick) ? handleClick : undefined }
      role="checkbox"
      aria-checked={ isItemSelected }
      tabIndex={ -1 }
      key={ id }
      id={ `TRow${id}` }
      selected={ isItemSelected }
      className={ className }
    >
      { renderAddButton() }
      {
        (props.onSelect) &&
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={ isItemSelected }
              inputProps={ {
                'aria-labelledby': labelId,
              } }
              onClick={ handleSelect }
            />
          </TableCell>
      }
      { renderCells }
      { renderMenuButton() }
      { renderRemoveButton() }
    </TableRow>
  );
};


export default SortedTableBodyRow;
