// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Box, Grid } from '@mui/material';

import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Colors } from '../../../Configuration/Styles/Colors';

import Card from '../../../Components/_Layout/Card';

type Props = Readonly<{
  title: string;
  children: any;
}>;


const SX = {
  page_heading: {
    marginTop: -1,
    paddingBottom: 4,
    fontWeight: Sizes.boldWeight,
    fontFamily: Fonts.main,
    fontSize: Sizes.heading,
    color: Colors.headingMain,
  },
};

const BoxComponent: React.FC<Props> = (props: Props) => (
  <Grid
    item
    xs={ 12 }
  >
      <Card>
        {
        (props.title) &&
          <Box
            sx={ SX.page_heading }
          >
            { props.title }
          </Box>
        }
        { props.children }
      </Card>
  </Grid>
);

export default BoxComponent;
