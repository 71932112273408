// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import {  Grid, SxProps, Theme } from '@mui/material';

import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import { injectIntl, WrappedComponentProps } from 'react-intl';
import LOCALIZATION from '../../../../Localization';
import VertGrid from '../../../../Components/_Layout/VertGrid';
import AnalyticsGameArchive from '../../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import MainButton from '../../../../Components/_BaseUI/MainButton';

import dayjs from 'dayjs';
import { AnalyticsAnalyticsStatus, analyticsStatusName } from '../../../../Data/Analytics/AnalyticsStatuses';
import { Sizes } from '../../../../Configuration/Styles/Sizes';
import AnaliticsTeamScoreInfoLineView from '../../../../Components/_Layout/AnaliticsTeamScoreInfoLineView';
import LineSeparator from '../../../../Components/_Layout/LineSeparator';
import { useAppConfig } from '../../../../Localization/AppContextProvider/helpers';
import { localizedAnalyticsSportNameById } from '../../../../Data/Analytics/AnalyticsTypes';
import { GetTeamRefById } from '../../../../Data/Analytics/AnalyticsTeamRef/hook';
import AnalyticsGameMarkupIdPopup from '../../../AnalyticsGameMarkupIdPopup';
import AnalyticsGameStartAnalyticsPopup from '../../../AnalyticsGameStartAnalyticsPopup';
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
require('dayjs/locale/ru')

type Props = Readonly<{
  gameResultInfo?: AnalyticsGameArchive
  sx?: SxProps<Theme>;
  getTeamRefByExternalId?: GetTeamRefById;
  onRequestUpdateGameData: () => void
} & WrappedComponentProps>;

const GameResultCard: React.FC<Props> = (props: Props) => {
  const { localization: { locale } } = useAppConfig();
  dayjs.locale(locale);
  const [showMarkupIdPopupForGame, setShowMarkupIdPopupForGame] = React.useState<AnalyticsGameArchive | undefined>(undefined);
  const [showStartAnalyticsPopupForGame, setShowStartAnalyticsPopupForGame] = React.useState<AnalyticsGameArchive | undefined>(undefined);

  const analyticsStatus = props.gameResultInfo?.analytics_status
  const startAt = dayjs(props.gameResultInfo?.start);
  const endAt = dayjs(props.gameResultInfo?.end);

  const diffMinutes = endAt.diff(startAt, 'minutes');
  const dateStr = `${ startAt.format('dd l').toUpperCase() } ${ startAt.format('LT') }, ${diffMinutes} ${props.intl.formatMessage({ id: LOCALIZATION.minutes })}`

  const analyticsStr = `${analyticsStatusName(analyticsStatus, props.gameResultInfo?.analytics_progress)} (${ endAt.format('dd l').toUpperCase() } ${ endAt.format('LT') })`

  const waitingTeamMapping = (analyticsStatus === AnalyticsAnalyticsStatus.WaitingTeamMapping)
  const waitingGameAnalysis = (analyticsStatus === AnalyticsAnalyticsStatus.NotStarted || analyticsStatus === AnalyticsAnalyticsStatus.Failed || analyticsStatus === AnalyticsAnalyticsStatus.Cancelled)

  const analyticsActiobPressed = () => {
    if (waitingTeamMapping) {
      setShowMarkupIdPopupForGame(props.gameResultInfo)
    }
    else if (waitingGameAnalysis) {
      setShowStartAnalyticsPopupForGame(props.gameResultInfo);
    }
  }

  const handleMarkupIdSetSuccess = async (game: AnalyticsGameArchive) => {
    try {
      setShowMarkupIdPopupForGame(undefined);
      props.onRequestUpdateGameData()
    } catch (error) {
      alert(error);
      handleCloseMarkupIdPopup();
      props.onRequestUpdateGameData()
    }
  };
  const handleCloseMarkupIdPopup = () => {
    setShowMarkupIdPopupForGame(undefined);
  };

  const handleStartAnalyticsSuccess = async (game: AnalyticsGameArchive | undefined) => {
    try {
      setShowStartAnalyticsPopupForGame(undefined);
      props.onRequestUpdateGameData()
    } catch (error) {
      alert(error);
      handleCloseMarkupIdPopup();
      props.onRequestUpdateGameData()
    }
  };
  const handleStartAnalyticsPopup = () => {
    setShowStartAnalyticsPopupForGame(undefined);
  };

  return (
    <MainCard>
      <VertGrid spacing={1.5} sx={{ margin: 3 }} >
        <AnaliticsTeamScoreInfoLineView
          valueAlign='center' 
          title={ props.intl.formatMessage({ id: LOCALIZATION.event }) } 
          value={ props.gameResultInfo?.subtitle }
        />
        <AnaliticsTeamScoreInfoLineView
          valueAlign='center' 
          title={ props.intl.formatMessage({ id: LOCALIZATION.event_sport_type }) } 
          value={ localizedAnalyticsSportNameById(props.gameResultInfo?.sport_type) }
        />
        <AnaliticsTeamScoreInfoLineView
          valueAlign='center' 
          title={ props.intl.formatMessage({ id: LOCALIZATION.game_date }) } 
          value={ dateStr }
        />
        <AnaliticsTeamScoreInfoLineView
          valueAlign='center' 
          title={ props.intl.formatMessage({ id: LOCALIZATION.analytics }) } 
          value={ analyticsStr }
        />
        <LineSeparator/>
        <AnaliticsTeamScoreInfoLineView
          valueAlign='center' 
          title={ props.getTeamRefByExternalId?.(props.gameResultInfo?.team1)?.name  } 
          value={ props.gameResultInfo?.score?.team1+""  } 
          iconCircleMarkerColor={{
            teamIndex: 1,
            team1ShortName: props.getTeamRefByExternalId?.(props.gameResultInfo?.team1)?.shortName,
            team2ShortName: props.getTeamRefByExternalId?.(props.gameResultInfo?.team2)?.shortName,
          }}
          valueFontSize={ Sizes.analyticsScore }
          sx={{ 
            marginTop: -1,  
            marginBottom: -1
          }}
        />
        <AnaliticsTeamScoreInfoLineView
          valueAlign='center' 
          title={ props.getTeamRefByExternalId?.(props.gameResultInfo?.team2)?.name  } 
          value={ props.gameResultInfo?.score?.team2+""  } 
          iconCircleMarkerColor={{
            teamIndex: 2,
            team1ShortName: props.getTeamRefByExternalId?.(props.gameResultInfo?.team1)?.shortName,
            team2ShortName: props.getTeamRefByExternalId?.(props.gameResultInfo?.team2)?.shortName,
          }}
          valueFontSize={ Sizes.analyticsScore }
          sx={{ 
            marginTop: -1,  
            marginBottom: -1
          }}
        />
        {(waitingTeamMapping || waitingGameAnalysis) &&
          <Grid container spacing={ 1 } 
              direction="row"
              justifyContent="center" 
              sx={ {
                marginTop: '15px',
              } }
          >
            <Grid item>
              <MainButton 
                onClicked={ analyticsActiobPressed } 
                title={ props.intl.formatMessage({ id: (waitingTeamMapping) ?  LOCALIZATION.analytics_game_start_mapping_team_id : LOCALIZATION.analytics_game_state_not_started }) } 
                sx={{ paddingLeft: 3, paddingRight: 3 }} 
              />
            </Grid>
          </Grid>
        }
      </VertGrid>
      {
        (!!showMarkupIdPopupForGame) &&
          <AnalyticsGameMarkupIdPopup
            game={ showMarkupIdPopupForGame }
            onSubmit={ handleMarkupIdSetSuccess }
            onClose={ handleCloseMarkupIdPopup }
          />
      }
      {
        (!!showStartAnalyticsPopupForGame) &&
          <AnalyticsGameStartAnalyticsPopup
            game={ showStartAnalyticsPopupForGame }
            onSubmit={ handleStartAnalyticsSuccess }
            onClose={ handleStartAnalyticsPopup }
          />
      }
    </MainCard>
  )
};

export default injectIntl(GameResultCard);
