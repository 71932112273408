// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {  Grid, SxProps, Theme } from '@mui/material';

import CameraStreamPreview from '../../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview'
import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content';
import LOCALIZATION from '../../../../Localization';
import { FileType } from '../../../../Data/VideoArchive';
import VertGrid from '../../../../Components/_Layout/VertGrid';
import AnalyticsGameArchive from '../../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import { useAnalyticsConfig } from '../../../../Data/Analytics/AnalyticsConfig/hook';
import { makeAnalyticsVideoImageUrl, makeAnalyticsVideoPlayerUrl } from '../../../../Data/Analytics/AnalyticsConfig/AnalyticsConfig';
import { GetTeamRefById } from '../../../../Data/Analytics/AnalyticsTeamRef/hook';
import MainButton from '../../../../Components/_BaseUI/MainButton';
import AnalyticsGameReportVideoDetailsPopup from '../../../AnalyticsGameReportVideoDetailsPopup';
import { AnalyticsAnalyticsStatus } from '../../../../Data/Analytics/AnalyticsStatuses';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import { getMenuLink } from '../../../../Menu/Data/MenuStructure';
import { useNavigate } from 'react-router-dom';
import { MenuID } from '../../../../Menu/Data/MenuElement';

type Props = Readonly<{
  gameInfo: AnalyticsGameArchive | undefined
  sx?: SxProps<Theme>;
  getTeamRefByExternalId?: GetTeamRefById;
} & WrappedComponentProps>;

const GameVideoCard: React.FC<Props> = (props: Props) => {
  const [isEditPopupOpened, setIsEditPopupOpened] = React.useState<boolean>(false);

  const {
    data: analyticsConfig
  } = useAnalyticsConfig({});

  const gameReportReady = props.gameInfo?.analytics_status === AnalyticsAnalyticsStatus.Done

  const analyticsDetailsShowBtnPressed = () => {
    setIsEditPopupOpened(true);
  }
  const analyticsDetailsHideBtnPressed = () => {
    setIsEditPopupOpened(false);
  }
  
  const navigate = useNavigate();
  const handleOpenVideoInArchive = () => {
    const menuLink = getMenuLink(MenuID.AnalyticsVideoArchiveDetails, [props.gameInfo?.record_id ?? ""]);
    if (menuLink) {
      navigate(menuLink);
    }
  }

  return (
    <MainCard>
      <RegularCardHeader 
        title={ props.intl.formatMessage({ id: LOCALIZATION.game_video }) }
        rightActions={[ {
            icon: <VideoSettingsIcon/>,
            onAction: handleOpenVideoInArchive
          } ]}
      />
      <MainCardContent>
        <VertGrid>
          <CameraStreamPreview
            previewImageUrl={ makeAnalyticsVideoImageUrl(analyticsConfig, props.gameInfo?.preview) }
            videoUrl={ makeAnalyticsVideoPlayerUrl(analyticsConfig, props.gameInfo?.video) }
            fileType={ FileType.Panorama }
            initialStartPositionSec={ props.gameInfo?.video_start }
          />
          <Grid container spacing={ 1 } 
            direction="row"
            justifyContent="center" 
            sx={ {
              marginTop: '15px',
            } }
          >
            {(gameReportReady) &&
              <Grid item>
                <MainButton 
                  onClicked={ analyticsDetailsShowBtnPressed } 
                  title={ props.intl.formatMessage({ id: LOCALIZATION.video_analytics }) } 
                  sx={{ paddingLeft: 3, paddingRight: 3 }} 
                />
              </Grid>
            }
          </Grid>
        </VertGrid>
        {
          (isEditPopupOpened) &&
            <AnalyticsGameReportVideoDetailsPopup
              game={ props.gameInfo }
              onClose={ analyticsDetailsHideBtnPressed }
            />
        }
      </MainCardContent>
    </MainCard>
  );
};


export default injectIntl(GameVideoCard);
