// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { generateUserError } from '../../../Data/_Networking/AxiosFetch/Errors/Tools'
import ApiError from '../../../Data/_Networking/AxiosFetch/Errors/Types'
import { ApiRequestConfigInterface, NetworkRequestInterface } from '../Types'
import CameraApiRequestsApiService from './Networking'
import CameraApiRequestsStorageService from './Storage'
import { intl } from '../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../Localization';
import HjsonEx from '../../../Tools/HjsonEx'

interface UpdatableInterface {
    update(): Promise<void>
}

// Этот репозиторий для работы с Camera API
interface CameraApiRequestsRepositoryInterface extends UpdatableInterface {
    readonly apiRequests: ApiRequestConfigInterface[]
    getRequest(name: string, succeeded: (value: any) => void, failed: (error: Error) => void): void
}

export default class CameraApiRequestsRepository implements CameraApiRequestsRepositoryInterface {
    private apiService = new CameraApiRequestsApiService()
    private storageService = new CameraApiRequestsStorageService()
    // private networkRequest: NetworkRequestInterface

    get apiRequests(): ApiRequestConfigInterface[] {
        return this.storageService.apiRequests
    }

    async update() {
        return new Promise<void>((resolve, reject) => {
            this.apiService.fetchApiRequests(apiRequests => {
                this.storageService.apiRequests = apiRequests
                resolve()
            }, error => { reject(generateUserError(error)) })
        })
    }

    getRequest(name: string, succeeded: (value: any) => void, failed: (error: Error) => void): void {
        this.apiService.getRequest(name, succeeded, (error: ApiError) => { failed(generateUserError(error)) })
    }

    postRequest(name: string, json: any, succeeded: (value: any, message?: string) => void, failed: (error: Error) => void): void {
        const checkCompletion = (networkRequest: NetworkRequestInterface) => {
            var response: any = null
            try {
                response = networkRequest.responseBody ? HjsonEx.parse(networkRequest.responseBody) : undefined;
            } catch (error) {
                // console.log('Post response json parsing error: ' + error)
                failed(Error(intl().formatMessage({ id: LOCALIZATION.empty_camera_api_response })))
                return
            }

            switch (networkRequest.state) {
                case 'pending':
                    // console.log('CameraApiRequestsRepository > network request is pending')
                    setTimeout(() => {
                        this.apiService.fetchNetworkRequest(networkRequest.id, networkRequest => { checkCompletion(networkRequest) }, failed)
                    }, 1000)
                    break

                case 'completed':
                    // console.log('CameraApiRequestsRepository > network request is completed. Will GET config')

                    if (response) {
                        if (response.status) {
                            switch (response.status) {
                                case 'error':
                                    failed(Error(response.message))
                                    break
                                case 'validated':
                                    this.getRequest(name, (value: any) => { succeeded(value, HjsonEx.stringifyToJson(response)) }, failed)
                                    break
                                default:
                                    failed(Error(intl().formatMessage({ id: LOCALIZATION.undefined_camera_api_response_status })))
                                    break
                            }
                        }
                        else {
                            if ((networkRequest.status) && (networkRequest.status >= 200) && (networkRequest.status < 400)) {
                                this.getRequest(name, (value: any) => { succeeded(value, intl().formatMessage({ id: LOCALIZATION.ok })) }, failed)
                            }
                            else {
                                failed(Error(`${intl().formatMessage({ id: LOCALIZATION.undefined_camera_api_response_status })} : ${networkRequest.status}` ))
                            }
                        }
                    } else {
                        failed(Error(intl().formatMessage({ id: LOCALIZATION.empty_camera_api_response })))
                    }
            }
        }

        this.apiService.postRequest(name, json, networkRequest => {
            // console.log('CameraApiRequestsRepository > network request url: ' + networkRequest.url)
            checkCompletion(networkRequest)
        }, (error) => { failed(generateUserError(error)) })
    }
}
