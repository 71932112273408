// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Colors } from "../../../Configuration/Styles/Colors";

export const hitmapDeadBallMinRatio = 0.95
export const hitmapDrawMaxPercent = 0.05 // 5%

type HeatmapItem = Readonly<{
  // • If any field non defined assume it equal to 0
  // • t1.ratio+t2.ratio in 0..1
  // • If t0.ratio > deadBallMinRatio(0.95) then Dead ball. no team was in rect -> gray background
  // • If t1.ratio - t2.ratio < 5% then Draw -> Diagonal Hatch background
  // • If t1.ratio > t2.ratio then team 1 win -> Team 1 color background
  // • If t1.ratio < t2.ratio then team 2 win -> Team 2 color background

  t1?: number[]; //Team1 [0.8,60] 0.8= ratio 0..1 team was in rect, 60 = seconds team was in rect
  t2?: number[]; //Team2 [0.2,10] 0.8= ratio 0..1 team was in rect, 60 = seconds team was in rect
  t0?: number[]; //Dead ball [0.9,1000] 0.9= ratio 0..1 no team was in rect, 10090 = seconds no team was in rect
}>;


type HeatmapItemObject = Readonly<{
  ratio: number; //ratio 0..1 team was in rect
  time: number; // 60 = seconds team was in rect
}>;

type HeatmapItemData = Readonly<{
  t1: HeatmapItemObject; // converted t1 from HeatmapItem
  t2: HeatmapItemObject; // converted t2 from HeatmapItem
  t0: HeatmapItemObject; // converted t0 from HeatmapItem
}>;

export type HeatmapData = Readonly<{
  heatmap: Array<Array<HeatmapItem>>;
}>;

const convertHeatmapItemToData = (item?: HeatmapItem): HeatmapItemData => {
  return {
    t0: {
      ratio: item?.t0?.[0] || 0,
      time: item?.t0?.[1] || 0,
    },
    t1: {
      ratio: item?.t1?.[0] || 0,
      time: item?.t1?.[1] || 0,
    },
    t2: {
      ratio: item?.t2?.[0] || 0,
      time: item?.t2?.[1] || 0,
    }
  }
}

export type HeatmapReachItem = Readonly<{
  orig: HeatmapItemData;
  x: number;
  y: number;
  value: number | undefined; // -1..+1. 0 - draw. +1 - team1=100%; -1 - team2=100%;

  team1_percent: number;
  team2_percent: number;
  deadBall_percent: number;

  team1_time_sec: number;
  team2_time_sec: number;
  deadBall_time_sec: number;
  total_time_sec: number;
}>;

const heatmapToReachItem = (dataset?: HeatmapData): HeatmapReachItem[] => {
  let outArr: HeatmapReachItem[] = [];

  dataset?.heatmap?.forEach((rowArr, y) => {
    rowArr.forEach((srcItem, x) => {
      const item = convertHeatmapItemToData(srcItem);
      let team1_ratio = Math.min(Math.max(item.t1.ratio, 0.0), 1.0); // 0..1.0
      let team2_ratio = Math.min(Math.max(item.t2.ratio, 0.0), 1.0); // 0..1.0
      let deadBall_ratio = Math.min(Math.max(item.t0.ratio, 0.0), 1.0); // 0..1.0
      const checkNorm = team1_ratio + team2_ratio;
      if (checkNorm > 1.0) {
        // if case invalid data
        team1_ratio /= checkNorm;
        team2_ratio /= checkNorm;
      }

      const total_time_sec = item.t0.time + item.t1.time + item.t2.time;
      const team1_time_sec = item.t1.time;
      const team2_time_sec = item.t2.time;
      const deadBall_time_sec = item.t0.time;

      let value:number | undefined = undefined;
      if (deadBall_ratio > hitmapDeadBallMinRatio) {// Dead ball. no team was in rect -> gray background
        value = undefined;
      }
      else {
        value = 0 ;// draw
        if (Math.abs(team1_ratio - team2_ratio) >= hitmapDrawMaxPercent) { // if diff >= hitmapDrawMaxPercent(5%) then
          const norm = team1_ratio + team2_ratio;
          const t1_norm = norm ? team1_ratio / norm : team1_ratio;
          const t2_norm = norm ? team2_ratio / norm : team2_ratio;
  
          if (t1_norm >= t2_norm) {
            // team1 win
            value = team1_ratio;
          } else {
            // team2 win
            value = -team2_ratio;
          }
        }
      }

      outArr.push({
        orig: item,
        x,
        y,
        value,

        team1_percent: Math.round(team1_ratio * 100),
        team2_percent: Math.round(team2_ratio * 100),
        deadBall_percent: Math.round(deadBall_ratio * 100),

        total_time_sec,
        team1_time_sec,
        team2_time_sec,
        deadBall_time_sec
      });
    });
  });

  return outArr;
};

type HeatmapGroupItem = Readonly<{
  label: string; // text to show on heatmap rect like "9", "8"
  deadBall?: boolean;
  min?: number;
  max?: number;
  alpha: number; // 0..1
  color: string;
  data: HeatmapReachItem[];
}>;
export type HeatmapGroups = Readonly<{
  width: number;
  height: number;
  data: HeatmapGroupItem[];
}>;

export const makeHeatmapGroups = ( dataset: HeatmapData, team1Color: string, team2Color: string ): HeatmapGroups => {
  let mappingArr: HeatmapGroupItem[] = [
    // order is important!!

    // Dead ball. no team was in rect -> gray background
    { label: "",  deadBall: true, alpha: 0.95, color: Colors.greyBackground, data: [] }, 

    // team1 win
    { label: "9", min: 0.9, alpha: 0.9, color: team1Color, data: [] },
    { label: "8", min: 0.8, alpha: 0.8, color: team1Color, data: [] },
    { label: "7", min: 0.7, alpha: 0.7, color: team1Color, data: [] },
    { label: "6", min: 0.6, alpha: 0.6, color: team1Color, data: [] },
    { label: "5", min: 0.3, alpha: 0.5, color: team1Color, data: [] },
    { label: "",  min: 0.0, alpha: 0.0, color: team1Color, data: [] }, // Draw

    // team2 win
    { label: "9", max: -0.9, alpha: 0.9, color: team2Color, data: [] },
    { label: "8", max: -0.8, alpha: 0.8, color: team2Color, data: [] },
    { label: "7", max: -0.7, alpha: 0.7, color: team2Color, data: [] },
    { label: "6", max: -0.6, alpha: 0.6, color: team2Color, data: [] },
    { label: "5", max: -0.3, alpha: 0.5, color: team2Color, data: [] },
    { label: "",  max: 0.0,  alpha: 0.0, color: team2Color, data: [] }, // Draw
  ];

  let width = 0;
  let height = 0;
  const remainingItemsArr = heatmapToReachItem(dataset);
  remainingItemsArr.forEach((value) => {
    if (value.x > width) {
      width = value.x;
    }
    if (value.y > height) {
      height = value.y;
    }

    const item = mappingArr.find((item) => (
        (value.value === undefined && item.deadBall) || // used for Dead ball. no team was in rect -> gray background
        ((value.value !== undefined) && (!item.deadBall) &&
         (item.min === undefined || value.value >= item.min) &&
         (item.max === undefined || value.value <= item.max))
      ))
    if (item) {
      item.data.push(value);
    }
  });

  return {
    width: width + 1,
    height: height + 1,
    data: mappingArr
  };
};