import { QueryKey, useQuery, useQueryClient } from 'react-query';

import { PAGE_LIMIT } from '../../../../constants';
import { fetchData, getKey } from '../fetch';
import { Result, usePagedItems } from '../../../_Networking/ReactQuery/template';
import RemoteCameraState, { RemoteCameraStateValue } from '..';
import { QueryHookReturnType } from '../../../types';
import { fetchJavaManyJSONData } from '../../../_Networking/ReactQuery/fetch';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import Order from '../../../_Networking/ReactQuery/Order';
import { debounce } from 'lodash';

export enum CameraHardwareListOrderByVariant {
  id = "id",
  cameraId = "cameraId",
  lastSettingsHttpCode = "lastSettingsHttpCode",
  lastSettingsResponseTimestamp = "lastSettingsResponseTimestamp",
  lastOnlineTime = "lastOnlineTime",
  state = "state",
  whenCreated = "whenCreated",
  whenUpdated = "whenUpdated",
}
type UseFetchProps = Readonly<{
  enableToFetch: boolean;
  page: number;
  limit?: number;
  filterByCameraID?: string;
  stateFilter?: RemoteCameraStateValue,
  sort?: {
    order: Order,
    by: CameraHardwareListOrderByVariant,
  },
  debouceTime?: number,
}>;

/**
 * hook for obtaining a list of camera hardware status information
 * @param props
 * @returns
 */
export const useCamerasHardwareStatusesList = (props: UseFetchProps): QueryHookReturnType<ResponseMany<RemoteCameraState[]>> => {
  const queryClient = useQueryClient();
  const { enableToFetch = true, page: pg = 0, limit = PAGE_LIMIT, filterByCameraID, stateFilter, sort, debouceTime = 100 } = props;
  const getKeyFn = () => getKey({
    page: pg,
    limit,
    filterByCameraID: props.filterByCameraID,
    sort: JSON.stringify(sort),
    stateFilter,
  })
  const debouncedCacheKey = debounce(getKeyFn, debouceTime)
  const cacheKey = debouncedCacheKey() ?? getKeyFn()
  const resetCache = () => {
    queryClient.invalidateQueries(cacheKey);
  };

  const offset = (pg * limit);
  let params = `?max=${limit}&first=${offset}`;

  if (filterByCameraID) {
    params += `&filter_cameraList.cameraId=${filterByCameraID}`;
  }

  if (stateFilter) {
    params += `&filter_state.state=${stateFilter}`
  }
  if (sort) {
    params += `&direction=${sort.order}`
    params += `&sort=${sort.by}`
  }
  const query = useQuery(cacheKey, async () => {
    return fetchJavaManyJSONData<RemoteCameraState>(
      `/cameras/states${params}`,
      'cameras-states',
      {
        limit,
        offset,
      },
      {
        method: 'GET',
      },
    );
  }, {
    enabled: !!enableToFetch,
    cacheTime: 0,
    staleTime: 0,
    refetchInterval: (15_000),       // 15 sec
    refetchIntervalInBackground: true,
    keepPreviousData: true,
    notifyOnChangeProps: ['data', 'isLoading', 'error'],
  });


  return { ...query, resetCache }

}

/**
 * @deprecated
 * @see {useCamerasHardwareStatusesList}
 * @param props
 * @returns
 */
export const useFetchCamerasStates = (props: UseFetchProps): Result<RemoteCameraState> => {
  const { enableToFetch = true, page: pg = 0, limit = PAGE_LIMIT } = props;
  const fetchTemplate = async (queryKey: QueryKey, page: number = 0) => fetchData({
    page,
    limit,
    filterByCameraID: props.filterByCameraID,
  });

  return usePagedItems<RemoteCameraState>({
    page: pg,
    fetch: fetchTemplate,
    keepPreviousData: false,
    queryKey: getKey({
      page: pg,
      limit,
      filterByCameraID: props.filterByCameraID,
    }),
    config: {
      enabled: !!enableToFetch,
      cacheTime: 0,
      staleTime: 0,
      refetchInterval: (15 * 1000),       // 15 sec
      refetchIntervalInBackground: true,
      keepPreviousData: true,
      notifyOnChangeProps: ['data', 'isLoading', 'error'],
    },
  });
};
