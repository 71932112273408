// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'

import ConfigurableMetricsChartView from '../../../../Components/_ChartsAndBars/ConfigurableMetricsChartView'
import { MetricsChartSourceInterface } from '../../../../Data/Camera/Metrics/Types'


type Props = Readonly<{
    defaultSource: MetricsChartSourceInterface;
    onSourceChanged?: (source: MetricsChartSourceInterface) => void;
}>;


const GeneralStatisticsChartView: React.FC<Props> = (props: Props) => {
    const defaultSource = props.defaultSource
    const proxySourceChange = (source: MetricsChartSourceInterface) => {
        const sourceChanged = props.onSourceChanged
        if (sourceChanged) {
            sourceChanged(source)
        }
    }

    return (
      <ConfigurableMetricsChartView
        defaultSource={ defaultSource }
        onSourceChanged={ proxySourceChange }
      />
    );
};


export default GeneralStatisticsChartView;
