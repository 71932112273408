// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { CameraSettingsInterface } from '../CameraSettings';
import CameraContext from './Context'
import { QueryKey, useQuery } from 'react-query';
import { queryCache, queryClient } from '../../../../AppProviders';
import { ResultSingle } from '../../../_Networking/ReactQuery/template';


const PREFIX_OF_A_COMPOSITE_KEY = 'camera-settings';
export const getKey = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

export const removeCameraSettingsCache = () => {
    queryClient.removeQueries(getKey());
};

export const setCameraSettingsToCache = (settings?: CameraSettingsInterface)  => (
    queryClient.setQueryData( getKey(), settings)
);

export const getCameraSettingsCached = (): undefined | CameraSettingsInterface => (
    queryClient.getQueryData<CameraSettingsInterface>(getKey())
);

export const flushCameraSettingsCache = () => {
    const queries = queryCache.findAll(getKey());
  
    if (queries && queries.length) {
      queries.forEach(({ queryKey }) => {
        queryClient.resetQueries(queryKey);
      })
    }
};
  
export const fetch = async (): Promise<CameraSettingsInterface | undefined> => {
    try {
      const cacheData = getCameraSettingsCached();
      if (cacheData) { return cacheData; }
      return CameraContext.currentContext()?.cameraDataProvider.camera.settings;
    } catch (error) {
      return undefined;
    }
};
  
export const useCameraSettingsFetch = (): ResultSingle<CameraSettingsInterface | undefined> => {
    const queryKey = getKey();
  
    // @ts-ignore
    return useQuery<CameraSettingsInterface | undefined>({
      queryKey,
      queryFn: fetch,
      // keepPreviousData: true,
    });
};
