// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import { NODE_JS_BACKEND_URL } from '../../../constants';
import fetchJSONData from '../../_Networking/ReactQuery/fetch';
import StorageInfo from '..';
import ResponseSingle from '../../_Networking/ReactQuery/ResponseSingle';
import { queryCache, queryClient } from '../../../AppProviders';


const PREFIX_OF_A_COMPOSITE_KEY = 'storage';

const getKeyStorageInfo = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getCachedStorageInfo = (): ResponseSingle<StorageInfo> | undefined => (
  queryClient.getQueryData<ResponseSingle<StorageInfo>>(getKeyStorageInfo())
);

const flushCacheStorageInfo = () => {
  const queries = queryCache.findAll(getKeyStorageInfo());

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};


const fetchDataStorageInfo = async (): Promise<ResponseSingle<StorageInfo>> => {
  const cacheData = getCachedStorageInfo();

  if (cacheData) { return cacheData; }

  return await fetchJSONData<ResponseSingle<StorageInfo>>(
    `${NODE_JS_BACKEND_URL}/storage`,
    {
      method: 'GET',
    }
  );
};

const postDataStorageInfo = async (settings: Partial<StorageInfo>): Promise<ResponseSingle<StorageInfo>> => {
  try {
    return await fetchJSONData<ResponseSingle<StorageInfo>>(
        `${NODE_JS_BACKEND_URL}/storage`,
        {
          method: 'POST',
          body: JSON.stringify(settings),
        }
    );
  } catch (error) {
    throw error;
  }
};

const clearSyncServerQueue = async (): Promise<ResponseSingle<StorageInfo>> => {
  try {
    return await fetchJSONData<ResponseSingle<StorageInfo>>(
        `${NODE_JS_BACKEND_URL}/storage/clearSyncQueue`,
        {
          method: 'POST',
        }
    );
  } catch (error) {
    throw error;
  }
};


export {
  getKeyStorageInfo,
  fetchDataStorageInfo,
  postDataStorageInfo,
  clearSyncServerQueue,
  getCachedStorageInfo,
  flushCacheStorageInfo,
  PREFIX_OF_A_COMPOSITE_KEY,
};
