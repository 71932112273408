// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import CameraContext from '../HWCameraSettings/fetch/Context'
import { MetricsDataProviderInterface, MetricsOwnerInterface } from './DataProvider'
import { MetricsDataProviderUniqueKey, MetricsSourceInterface, MetricsDataItem, MetricDataChangeSubscriber, MetricTypeInterface, HeaterId, MetricsChartSourceInterface } from './Types'

export class MetricsDataHelper {
    static dataProviders = new Map<MetricsDataProviderUniqueKey, MetricsDataProviderInterface>()

    static items(source: MetricsSourceInterface, owner: MetricsOwnerInterface): MetricsDataItem[] {
        const dataProvider = this.dataProvider(source, owner)
        return dataProvider.data ?? []
    }

    static subscribeToMetricsChange(source: MetricsSourceInterface, subscriber: MetricDataChangeSubscriber, owner: MetricsOwnerInterface) {
        const dataProvider = this.dataProvider(source, owner)
        dataProvider.subscribeOnChanges(subscriber)
    }

    static unsubscribeFromMetricsChange(source: MetricsSourceInterface, subscriber: MetricDataChangeSubscriber) {
        const dataProvider = this.dataProviders.get(source.uniqueKey)
        if (dataProvider) {
            dataProvider.unsubscribeFromChanges(subscriber)
        }
    }

    static reset(): void {
        this.dataProviders.forEach(dataProvider => {
            dataProvider.reset()
        })
        this.dataProviders.clear()
    }

    private static dataProvider(source: MetricsSourceInterface, owner: MetricsOwnerInterface): MetricsDataProviderInterface {
        const reusableDataProvider = this.dataProviders.get(source.uniqueKey)
        if (reusableDataProvider) {
            reusableDataProvider.updateSource(source)
        }

        const dataProvider = reusableDataProvider ?? owner.createDataProvider(source)
        this.dataProviders.set(source.uniqueKey, dataProvider)

        return dataProvider
    }
    
    static filterAvailableSourceMetrics(source: MetricsChartSourceInterface): MetricsChartSourceInterface {
        const metrics = MetricsDataHelper.filterAvailableMetrics(source.metrics)
        return { ...source, metrics: metrics }
    }

    static filterAvailableMetrics(merics: MetricTypeInterface[]): MetricTypeInterface[] {
        const heaterIdArr = CameraContext.current?.cameraDataProvider.activeHeaterIdArr
        if (heaterIdArr === undefined) {
            return merics
        }

        const validMerics = merics.filter(meric => {
            const sensorPlace = meric.identificationParameters.filter_sensorPlace
            if (sensorPlace?.startsWith('heater')) {
                return heaterIdArr?.includes(sensorPlace as HeaterId)
            }
            return true
        })
        return validMerics
    }
}
