// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

// import { Colors } from '../../Styles/Colors';
import createStyledComponent from '../../Tools/createStyledComponent';


const STYLES = {
  ROOT: {
    position: 'relative',
    display: 'inline-block',
    // maxWidth: '300px',
    // height: '1px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  PLAY_BUTTON: {
    position: 'absolute',
    zIndex: '10',
    top: '50%',
    left: '50%',
    display: 'block',
    height: 64,
    width: 64,
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
  },
  FS_BUTTON: {
    position: 'absolute',
    zIndex: '10',
    right: '10px',
    bottom: '5px',
    height: '60px',
    width: '60px',
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
  },
};

const Root = createStyledComponent({
  tag: 'div',
  styles: STYLES.ROOT,
});
// const Image = createStyledComponent({
//   tag: 'div',
//   styles: STYLES.IMAGE,
// });
const FullScreenButton = createStyledComponent({
  tag: 'div',
  styles: STYLES.FS_BUTTON,
});
const PlayButton = createStyledComponent({
  tag: 'div',
  styles: STYLES.PLAY_BUTTON,
});


export {
  Root,
  // Image,
  FullScreenButton,
  PlayButton,
};
