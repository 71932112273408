// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { ErrorMessageHelper } from '../../../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessageHelper'
import { CameraDataProvider } from './DataProvider'
import { ActiveHeatersChangeSubscriber } from '../CameraSettingsTemperature'
import { CameraInterface, CameraReferenceChangeSubscriber, CameraReferenceInterface } from '../Camera'
import { GpioPressActionsDataProvider } from '../../Gpio/DataProvider'
import { MetricsDataHelper } from '../../Metrics/Helper'
import CameraApiRequestsStorageService from '../../../../Pages/CameraAPIPage/Data/Storage'

export default class CameraContext {
    cameraDataProvider: CameraDataProvider
    gpioPressActionsDataProvider: GpioPressActionsDataProvider

    static current?: CameraContext

    static currentContext(): CameraContext | undefined {
        return this.current
    }

    private static cameraSubscribers: CameraReferenceChangeSubscriber[] = []
    private static activeHeatersSubscribers: ActiveHeatersChangeSubscriber[] = []

    static async reset() {
        const cameraContext = this.current
        if (cameraContext) {
            cameraContext.cameraDataProvider.reset()
            cameraContext.gpioPressActionsDataProvider.reset()
        }
        CameraApiRequestsStorageService.reset()
        MetricsDataHelper.reset()
        ErrorMessageHelper.reset()
    }

    static get isNotDefined(): boolean {
        return this.current === undefined
    }

    static use(camera: CameraInterface): void {
        CameraContext.reset()
        CameraContext.current = new CameraContext(camera)

        this.informCameraSubscribers(camera)
        this.informActiveHeatersSubscribers()
    }

    static subscribeToCameraReferenceChange(subscriber: CameraReferenceChangeSubscriber): void {
        this.cameraSubscribers.push(subscriber)
    }

    static unsubscribeFromCameraReferenceChange(subscriber: CameraReferenceChangeSubscriber): void {
        // const count = this.subscribers.length
        this.cameraSubscribers = this.cameraSubscribers.filter(s => s !== subscriber)
        // const removed = count - this.subscribers.length
        // console.log(`CameraContext > unsubscribed ${removed}`)
    }

    static subscribeToActiveHeatersChange(subscriber: ActiveHeatersChangeSubscriber): void {
        this.activeHeatersSubscribers.push(subscriber)
    }

    static unsubscribeFromActiveHeatersChange(subscriber: ActiveHeatersChangeSubscriber): void {
        this.activeHeatersSubscribers = this.activeHeatersSubscribers.filter(s => s !== subscriber)
    }
    
    constructor(camera: CameraInterface) {
        this.cameraDataProvider = new CameraDataProvider(camera)
        this.gpioPressActionsDataProvider = new GpioPressActionsDataProvider()
    }

    private static informCameraSubscribers(camera: CameraInterface) {
        const cameraReference: CameraReferenceInterface = {
            name: camera?.name
        }
        this.cameraSubscribers.forEach(subsriber => subsriber(cameraReference))
    }

    static informActiveHeatersSubscribers() {
        const heaterArr = this.current?.cameraDataProvider.activeHeaterIdArr
        this.activeHeatersSubscribers.forEach(subsriber => subsriber(heaterArr))
    }
}
