// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer,LabelList, CartesianGrid } from 'recharts';

import PlaceholderStyled, { ChartTooltipLabelStyle, ChartTooltipItemStyle } from '../Styled';
import LOCALIZATION from '../../../Localization';
import { LabelListContentText, BarChartItem, createVertBarChartSkinDataArr, vertBarChartTeamConfig } from './helper';
import { Colors } from '../../../Configuration/Styles/Colors';
import { Sizes } from '../../../Configuration/Styles/Sizes';

type Props = Readonly<{
  data: BarChartItem[] | undefined
  team1Color: string | undefined;
  team2Color: string | undefined;
} & WrappedComponentProps>;

const AnalyticsVertOppositeBarView: React.FC<Props> = (props: Props) => {

  if (!!props.data) {
    const data = createVertBarChartSkinDataArr(props.data);



    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          width={550}
          height={500}
          data={ data }
          stackOffset="expand"
          barCategoryGap="5%"
          barGap={2}
          barSize={10}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 10
          }}
        >
          <CartesianGrid vertical horizontal={false} 
            verticalFill = { Colors.analyticsBarBackgroundFill } 
            // fillOpacity={0.1} 
          />
          <XAxis hide type="number" domain={[0, "dataMax"]} />
          <YAxis
            hide
            dataKey="label"
            type="category"
          />
          <Tooltip
            formatter={(value: any, name: string, props: any) => {
              const valueKey = name.replace('.ratio', '');
              const newValue = props.payload[valueKey]?.value;
              const newName = props.payload[valueKey]?.valueName;
              return [newValue, newName];
            }}
            labelStyle={ ChartTooltipLabelStyle }
            itemStyle={ ChartTooltipItemStyle }
            wrapperStyle={ { background: Colors.mainBackground, zIndex: 2 } }
          />
          { vertBarChartTeamConfig(props.team1Color, props.team2Color).map((item, index) => (
            <Bar
              key={ index }
              dataKey={ item.dataKeyPrefix + ".ratio" }
              fill={ item.fillColor }
              isAnimationActive={false}
              stroke={ Colors.analyticsBarStroke }
              fillOpacity={ item.fillOpacity }
              strokeWidth={ Colors.analyticsBarStrokeWidth }
              stackId={ item.stackId }
              radius={index % 2 === 0 ? [10, 0, 0, 10] : [0, 10, 10, 0]}
            >

              {(index % 2 !== 0) &&
                <LabelList
                  position="center"
                  style={{
                    //  fill: Colors.analyticsGreyHeader, 
                     fontWeight: Sizes.mediumWeight 
                  }}
                  content={ <LabelListContentText data={ data } padding={2} /> }
                />
              }

              <LabelList
                position={(index % 2 === 0) ? "left" : "right" }
                content={ <LabelListContentText padding={2} /> }
                valueAccessor={(payload: any) => {
                  return payload[ item.dataKeyPrefix ]?.valueLabel;
                }}
              />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }

  return (
    <PlaceholderStyled>
      { props.intl.formatMessage({ id: LOCALIZATION.loading_metrics }) }
    </PlaceholderStyled>
  );
};

export default injectIntl(AnalyticsVertOppositeBarView);
