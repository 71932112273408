// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { IconButton, Theme } from '@mui/material';

import { Colors } from '../../Configuration/Styles/Colors';
import AnyChildren from '../../Tools/AnyChildren';


type Props = Readonly<{
  edge?: false | 'start' | 'end';
  onClick: () => void;
  children: AnyChildren;
}>;


const SX = (theme: Theme) => ({
  color: Colors.headingMain,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  }
});


const MenuButton: React.FC<Props> = (props: Props) => (
  <IconButton
    sx={ SX }
    edge={ props.edge }
    onClick={ props.onClick }
  >
    { props.children }
  </IconButton>
);


export default MenuButton;
