// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Grid } from '@mui/material';
import Card from '../../Components/_Layout/Card';

import ButtonsInRowElement, { ButtonsInRowArray } from '../../Components/_BaseUI/ButtonsInRowElement';
import Order from '../../Data/_Networking/ReactQuery/Order';
import SortedTable from '../../Components/SortedTable';
import TeamLogo from '../../Components/_BaseUI/TeamLogo';
import TeamColor from '../../Components/_BaseUI/TeamColor';
import { PAGE_LIMIT } from '../../constants';
import useTeamRefsPagedData from '../../Data/Analytics/AnalyticsTeamRef/hook/usePagedData';
import TeamsListPopup from './TeamsListPopup';
import getTemplateURLToImage from '../../Tools/getTemplateURLToImage';
import useMutationTeamRef, { ActionType } from '../../Data/Analytics/AnalyticsTeamRef/fetch/useMutation';
import LOCALIZATION from '../../Localization';
import { EMPTY_ARRAY } from '../../constants';
import RefreshIcon from '@mui/icons-material/Refresh';
// import { useMediaQuery, useTheme } from '@mui/material';
import { useCameraSettings } from '../../Data/Camera/HWCameraSettings/hook/Hooks';
import TeamRef, { NewTeamRef } from '../../Data/Analytics/AnalyticsTeamRef';
import PageContainer from '../../Components/_Layout/PageContainer';

type Props = Readonly<{} & WrappedComponentProps>;


const TeamsList: React.FC<Props> = (props: Props) => {
  
  const cameraSettings = useCameraSettings(true);
  
  // const theme = useTheme();
  // const mobileView = useMediaQuery(theme.breakpoints.down("md"))

  const headCells = [
    {
      id: 'name' as keyof TeamRef,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.team_name }),
    },
    {
      id: 'shortName' as keyof TeamRef,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.team_short_name }),
    },
    {
      id: 'logo' as keyof TeamRef,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.team_logo }),
    },
    {
      id: 'notes' as keyof TeamRef,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.team_notes }),
    },
    {
      id: 'color' as keyof TeamRef,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.team_color }),
    },
  ];
  const [isEditPopupOpened, setIsEditPopupOpened] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(PAGE_LIMIT);
  const [editItem, setEditItem] = React.useState<TeamRef | NewTeamRef | undefined>(undefined);
  const [orderBy, setOrderBy] = React.useState<keyof TeamRef>('name');
  const [order, setOrder] = React.useState<Order>(Order.ASC);
  const [selected, setSelected] = React.useState<TeamRef[]>(EMPTY_ARRAY);
  const { mutateAsync: mutateConfig } = useMutationTeamRef();
  const {
    // status,
    data: teams,
    handleFlushData,
  } = useTeamRefsPagedData({
    page,
    limit,
    order,
    orderBy,
  });
  // console.log('status:', status);

  const handleTeamRefSuccessUpdate = async () => {
    handleFlushData();
    handleCloseEditPopup();
  };
  const handleDeleteTeam = async (teamRef?: TeamRef) => {
    try {
      await mutateConfig(
        {
          teamRef,
          type: ActionType.Delete,
        }, {
          onSuccess: () => {
            setSelected(EMPTY_ARRAY);
            handleFlushData();
          },
        },
      );
    } catch (error) {
      alert(error);
    }
  };
  const handleAddClicked = () => {
    setIsEditPopupOpened(true);
  };
  const handlePeerPageChange = (newLimit: number) => {
    setSelected(EMPTY_ARRAY);
    setLimit(newLimit);
  };
  const handleCloseEditPopup = () => {
    setIsEditPopupOpened(false);
    setEditItem(undefined);
  };
  // const handleSelectIDs = (selectedTeams: TeamRef[]) => {
  //   setSelected(selectedTeams);
  // };
  const handleRequestSort = (newOrderField: keyof TeamRef) => {
    if (orderBy === newOrderField) {
      setOrder((order === Order.ASC) ? Order.DESC : Order.ASC);
    }

    setOrderBy(newOrderField);
  };
  const handleRowItemClicked = (item: TeamRef): void => {
    setEditItem(item);
  };
  const handleMenuCopy = (copyTeam?: TeamRef) => {
    if (copyTeam) {
      setEditItem({
        ...copyTeam,
        id: undefined,
        logo: null,
        name: `${copyTeam.name} <${props.intl.formatMessage({ id: LOCALIZATION.copy_item })}>`,
      });
    }
  };
  const handleMenuDelete = (teamRef?: TeamRef) => {
    if (teamRef) {
      handleDeleteTeam(teamRef);
    }
  };

  const renderCellContent = (item: TeamRef, cellName: keyof TeamRef, index: number) => {
    if (cellName === 'color' as keyof TeamRef) {
      return ({
        // cellProps: {
        //   align: ('center' as any),
        // },
        content: (
          <TeamColor
            color={ (item as TeamRef).color }
          />
        ),
      });
    } else if (cellName === 'logo' as keyof TeamRef) {
      const { logo = null } = item as TeamRef;

      if (logo !== null) {
        return ({
          content: (
            <TeamLogo
              src={ getTemplateURLToImage(cameraSettings?.urls?.eventsImageTemplate, "events_image_path", logo) }
            />
          ),
        });
      } else {
        return ({
          content: '—',
        });
      }
    }

    return ({
      content: (item?.[cellName] || ''),
    });
  };

  const buttons: ButtonsInRowArray = [
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.add_event }),
      onClick: handleAddClicked,
    },
    {
      space :-1 ,
    }, 
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.refresh }),
      iconButton: <RefreshIcon /> ,
      onClick: handleFlushData,
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => handleFlushData,[]);

  return (
    <PageContainer
      header={
      props.intl.formatMessage({
        id: LOCALIZATION.page_analytics_teams_title
      })
    }
    >
      <Grid
        item
        xs={ 12 }
      >
        <Card>
          <ButtonsInRowElement
            id = "buttonsRow1"
            buttons={ buttons }
          />
          <br/>
          {
            (!!teams?.data?.length) &&
              <SortedTable<TeamRef>
                page={ page }
                rowsPerPage={ limit }
                // rowsPerPageOptions={ [5, 10, 25, 50, 100] }
                orderBy={ orderBy }
                order={ order }
                headCells={ headCells }
                selected={ selected }
                manyItems={ teams }
                renderCellContent={ renderCellContent }
                onPageChange={ setPage }
                onPeerPageChange={ handlePeerPageChange }
                onRowClick={ handleRowItemClicked }
                // onSelect={  (!mobileView) ? handleSelectIDs : undefined }
                // onSelect={  handleSelectIDs }
                onRequestSort={ handleRequestSort }
                onMenuEdit={ handleRowItemClicked }
                onMenuCopy={ handleMenuCopy }
                onMenuDelete={ handleMenuDelete }
              />
          }
          {
            (isEditPopupOpened || editItem) &&
              <TeamsListPopup
                isServer={ true }
                teamRef={ editItem }
                onPostedSuccess={ handleTeamRefSuccessUpdate }
                onClose={ handleCloseEditPopup }
              />
          }
        </Card>
      </Grid>
    </PageContainer>
  );
};


export default injectIntl(TeamsList);
