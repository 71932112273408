// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import User from '../User';
import { UserRole } from '../UserWithoutID';


const isSuperAdmin = (user?: User): boolean => (user?.role === UserRole.SuperAdmin);


export default isSuperAdmin;
