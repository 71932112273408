// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import AnyChildren from '../../Tools/AnyChildren';
import {
  AppConfigContext,
  AppConfigDispatchContext,
  reducer,
  INITIAL_STATE,
} from './helpers';


type Props = Readonly<{
  children: AnyChildren;
}>;


const AppContextProvider: React.FC<Props> = (props: Props) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    INITIAL_STATE,
  );

  return (
    <AppConfigContext.Provider
      value={ state }
    >
      <AppConfigDispatchContext.Provider
        value={ dispatch }
      >
        { props.children }
      </AppConfigDispatchContext.Provider>
    </AppConfigContext.Provider>
  );
};


export default AppContextProvider;