// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import cn from 'classnames';
import { AppBar, Grid, Theme } from '@mui/material';
import { ChevronLeftRounded, MenuRounded } from '@mui/icons-material';

import AppToolbar from './AppToolbar';
import useStyles from './useStyles';
import MenuButton from './MenuButton';
import { Sizes } from '../../Configuration/Styles/Sizes';
import LanguageSelector from './LanguageSelector';
import CCLogo, { CCLogoStyle } from '../../Components/_BaseUI/CClogo/CCLogo';
import MaintenanceModeStatus from './MaintenanceModeStatus';
import { Colors } from '../../Configuration/Styles/Colors';


type Props = Readonly<{
  isMenuOpened: boolean;
  onMenuOpenedToggle: () => void;
  onLogout: () => void;
}>;

export const MainAppBarHeight = 50;


const MainAppBar: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      sx={ (theme: Theme) => ({
        color: Colors.darkBlue,
        zIndex: theme.zIndex.drawer + 1,
        height: MainAppBarHeight,
        background: Colors.mainAppBarBackground,
        boxShadow: '0px 4px 16px #00000014',
      }) }
      className={ cn({
        [classes.showing]: props.isMenuOpened,
        [classes.hiding]: !props.isMenuOpened,
      }) }
      elevation={ Sizes.elevation }
    >
      <AppToolbar>
        <Grid
          container
          spacing={ 0 }
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <MenuButton
              onClick={ props.onMenuOpenedToggle }
              edge="start"
            >
              { props.isMenuOpened ? (<ChevronLeftRounded />) : <MenuRounded /> }
            </MenuButton>
          </Grid>
          <Grid item xs>
            <CCLogo logoStyle={ CCLogoStyle.Toolbar } />
          </Grid>
          <Grid item>
            <MaintenanceModeStatus/>
          </Grid>
          <Grid item>
            <LanguageSelector />
          </Grid>
          {/* <Grid item>
            <AccountDetails onLogout={ props.onLogout } />
          </Grid> */}
        </Grid>
      </AppToolbar>
    </AppBar>
  )
};


export default MainAppBar;
