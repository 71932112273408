// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import ButtonsInRowElement, { ButtonsInRowArray } from '../../../Components/_BaseUI/ButtonsInRowElement';
// import { getTeamByID } from './helpers';
import Team from '../../../Data/EPG/Team/Team';
import Order from '../../../Data/_Networking/ReactQuery/Order';
import SortedTable from '../../../Components/SortedTable';
import TeamLogo from '../../../Components/_BaseUI/TeamLogo';
import TeamColor from '../../../Components/_BaseUI/TeamColor';
import { PAGE_LIMIT } from '../../../constants';
import TeamWithoutID from '../../../Data/EPG/Team/TeamWithoutID';
import usePagedData from '../../../Data/EPG/Team/hook/usePagedData';
import TeamsListPopup from './TeamsListPopup';
import getTemplateURLToImage from '../../../Tools/getTemplateURLToImage';
import useMutationTeam, { ActionType } from '../../../Data/EPG/Team/fetch/useMutation';
import LOCALIZATION from '../../../Localization';
import { EMPTY_ARRAY } from '../../../constants';
import RefreshIcon from '@mui/icons-material/Refresh';
// import { useMediaQuery, useTheme } from '@mui/material';
import { useCameraSettings } from '../../../Data/Camera/HWCameraSettings/hook/Hooks';

type Props = Readonly<{} & WrappedComponentProps>;


const TeamsList: React.FC<Props> = (props: Props) => {
  
  const cameraSettings = useCameraSettings(true);
  
  // const theme = useTheme();
  // const mobileView = useMediaQuery(theme.breakpoints.down("md"))

  const headCells = [
    {
      id: 'name' as keyof Team,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.team_name }),
    },
    {
      id: 'shortName' as keyof Team,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.team_short_name }),
    },
    {
      id: 'logo' as keyof Team,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.team_logo }),
    },
    {
      id: 'color' as keyof Team,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.team_color }),
    },
  ];
  const [isEditPopupOpened, setIsEditPopupOpened] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(PAGE_LIMIT);
  const [editItem, setEditItem] = React.useState<Team | TeamWithoutID | undefined>(undefined);
  const [orderBy, setOrderBy] = React.useState<keyof Team>('name');
  const [order, setOrder] = React.useState<Order>(Order.ASC);
  const [selected, setSelected] = React.useState<Team[]>(EMPTY_ARRAY);
  const { mutateAsync: mutateConfig } = useMutationTeam();
  const {
    // status,
    data: teams,
    handleFlushData,
  } = usePagedData({
    page,
    limit,
    order,
    orderBy,
  });
  // console.log('status:', status);

  const handlePostTeam = async (team: Team | TeamWithoutID) => {
    try {
      await mutateConfig(
        {
          team,
          type: ActionType.Post,
          // page: props.page,
        }, {
          onSuccess: () => {
            handleFlushData();
            handleCloseEditPopup();
          },
        },
      );
    } catch (error) {
      alert(error);

      handleFlushData();
      handleCloseEditPopup();
    }
  };
  const handleDeleteTeam = async (IDs: number[]) => {
    try {
      await mutateConfig(
        {
          IDs,
          type: ActionType.Delete,
        }, {
          onSuccess: () => {
            setSelected(EMPTY_ARRAY);
            handleFlushData();
          },
        },
      );
    } catch (error) {
      alert(error);
    }
  };
  const handleAddClicked = () => {
    setIsEditPopupOpened(true);
  };
  // const handleCopyClicked = () => {
  //   if (selected.length > 0) {
  //     const copyTeam = getTeamByID(selected[0].id, teams);
  //     handleMenuCopy(copyTeam);
  //   }
  // };
  const handleRemoveClicked = () => {
    if (selected.length !== 0 && window.confirm(props.intl.formatMessage({ id: LOCALIZATION.confirm_remove }))) {
      handleDeleteTeam(selected.map(({ id }) => id));
    }
  };
  const handlePeerPageChange = (newLimit: number) => {
    setSelected(EMPTY_ARRAY);
    setLimit(newLimit);
  };
  const handleCloseEditPopup = () => {
    setIsEditPopupOpened(false);
    setEditItem(undefined);
  };
  const handleSelectIDs = (selectedTeams: Team[]) => {
    setSelected(selectedTeams);
  };
  const handleRequestSort = (newOrderField: keyof Team) => {
    if (orderBy === newOrderField) {
      setOrder((order === Order.ASC) ? Order.DESC : Order.ASC);
    }

    setOrderBy(newOrderField);
  };
  const handleRowItemClicked = (item: Team): void => {
    setEditItem(item as Team);
  };
  const handleMenuCopy = (copyTeam?: Team) => {
    if (copyTeam) {
      setEditItem({
        ...copyTeam,
        id: undefined,
        logo: null,
        name: `${copyTeam.name} <${props.intl.formatMessage({ id: LOCALIZATION.copy_item })}>`,
      });
    }
  };
  const handleMenuDelete = (team?: Team) => {
    if (team) {
      handleDeleteTeam([team.id]);
    }
  };

  const renderCellContent = (item: Team, cellName: keyof Team, index: number) => {
    if (cellName === 'color' as keyof Team) {
      return ({
        // cellProps: {
        //   align: ('center' as any),
        // },
        content: (
          <TeamColor
            color={ (item as Team).color }
          />
        ),
      });
    } else if (cellName === 'logo' as keyof Team) {
      const { logo = null } = item as Team;

      if (logo !== null) {
        return ({
          content: (
            <TeamLogo
              src={ getTemplateURLToImage(cameraSettings?.urls?.eventsImageTemplate, "events_image_path", logo) }
            />
          ),
        });
      } else {
        return ({
          content: '—',
        });
      }
    }

    return ({
      content: (item?.[cellName] || ''),
    });
  };

  const buttons: ButtonsInRowArray = [
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.add_event }),
      onClick: handleAddClicked,
    },
    {
      space : 38 ,
    }, 
    {
      text: `${props.intl.formatMessage({ id: LOCALIZATION.remove })} ${ (selected.length) ? '(' + selected.length + ')' : '' }`,
      bordered: true,
      hidden: (selected.length === 0),
      disabled: (selected.length === 0),
      onClick: handleRemoveClicked,
    },
    // {
    //   space : 10 ,
    // }, 
    // {
    //   text: props.intl.formatMessage({ id: LOCALIZATION.copy }),
    //   bordered: true,
    //   disabled: (selected.length !== 1),
    //   onClick: handleCopyClicked,
    // },
    {
      space :-1 ,
    }, 
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.refresh }),
      iconButton: <RefreshIcon /> ,
      onClick: handleFlushData,
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => handleFlushData,[]);

  return (
    <>
      <ButtonsInRowElement
        id = "buttonsRow1"
        buttons={ buttons }
      />
      {
        (teams) &&
          <SortedTable<Team>
            page={ page }
            rowsPerPage={ limit }
            // rowsPerPageOptions={ [5, 10, 25, 50, 100] }
            orderBy={ orderBy }
            order={ order }
            headCells={ headCells }
            selected={ selected }
            manyItems={ teams }
            renderCellContent={ renderCellContent }
            onPageChange={ setPage }
            onPeerPageChange={ handlePeerPageChange }
            onRowClick={ handleRowItemClicked }
            // onSelect={  (!mobileView) ? handleSelectIDs : undefined }
            onSelect={  handleSelectIDs }
            onRequestSort={ handleRequestSort }
            onMenuEdit={ handleRowItemClicked }
            onMenuCopy={ handleMenuCopy }
            onMenuDelete={ handleMenuDelete }
          />
      }
      {
        (isEditPopupOpened || editItem) &&
          <TeamsListPopup
            team={ editItem }
            // page={ page }
            onSubmit={ handlePostTeam }
            onClose={ handleCloseEditPopup }
          />
      }
    </>
  );
};


export default injectIntl(TeamsList);
