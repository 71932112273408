// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useMutation } from 'react-query';

import { patchEvents, PREFIX_OF_A_COMPOSITE_KEY } from '.';
import { queryCache, queryClient } from '../../../../AppProviders';
import { AnalyticsGameEvent } from '../AnalyticsGameEvent';


export enum ActionType {
  Patch = 'patch',
}

export type ArchiveForActionProps = Readonly<{
  id: string;
  events?: AnalyticsGameEvent[]; // used by ActionType.Patch
}>;

export type Action = Readonly<{
  archives?: ArchiveForActionProps;
  type: ActionType;
}>;


const useMutationGameEvents = () => {
  return useMutation(
    (action: Action) => {
      switch (action.type) {
        case ActionType.Patch: {
          if (action.archives && Array.isArray(action.archives.events) && action.archives.events.length > 0) {//{ archive={type: "operator", startAt: "2022-01-02T12:30", event?: EPGEvent} }
            return patchEvents(action.archives);
          }

          return Promise.reject(new Error('Not all required fields are initialized'));
        }
        default:
          return Promise.reject(new Error('Invalid Account Mutation Action'));
      }
    },
    {
      onSuccess: () => {
        const queries = queryCache.findAll([[PREFIX_OF_A_COMPOSITE_KEY]]);

        if (queries && queries.length) {
          queries.forEach(({ queryKey }) => {
            queryClient.removeQueries(queryKey);
          })
        }
      },
    },
  );
};


export default useMutationGameEvents;
