// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import LOCALIZATION from '../../../Localization';
import { WeekDay } from '../../../Data/EPG/EpgSchedule/EventWithoutID';


const weekDaysShortNameLocalizationKey: { [key in WeekDay]: string } = {
  [WeekDay.Su]: LOCALIZATION.weekday_short_sunday,
  [WeekDay.Mo]: LOCALIZATION.weekday_short_monday,
  [WeekDay.Tu]: LOCALIZATION.weekday_short_tuesday,
  [WeekDay.We]: LOCALIZATION.weekday_short_wednesday,
  [WeekDay.Th]: LOCALIZATION.weekday_short_thursday,
  [WeekDay.Fr]: LOCALIZATION.weekday_short_friday,
  [WeekDay.Sa]: LOCALIZATION.weekday_short_saturday,
};


export default weekDaysShortNameLocalizationKey;
