// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>


export type CameraSettingsActiveOptions = Readonly<{
  havePi?: string // true/false
  haveJ1?: string // true/false
  haveJ2?: string // true/false

  haveInputVoltmeter?: string // true/false
  haveJetsonOnDetector?: string // true/false
  haveThermometers?: string // true/false
  haveHumiditySensor?: string // true/false
  haveFanHeaterPwm?: string // true/false
  haveGyroscope?: string // true/false
  haveScoreboardCamera?: string // true/false
  
  motherboardVersion?: string// "v2.0", "v2.1", "v3.0"
  backendVersion?: string// "1.4.5"

  isProxyMode?: string // true/false
  }>;

  export const isCameraSettingsOptionActive = (option?: string): boolean => {
    if (option && option === "true") {
        return true;
    }
    return false;
  }