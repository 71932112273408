// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

//*************** NAPI NODE JS BACKEND ***************

const NAPI_LOCAL_STORAGE_KEY = 'napi';
export const DEFAULT_NAPI = './napi';// relative path start with ./
export const DEFAULT_NAPI_FIN = '/api/napi';// make absolute from DEFAULT_NAPI and DEFAULT_JAPI

const getENVNodeBackend = (): string => {
  const { REACT_APP_API_NODE = DEFAULT_NAPI } = process.env;

  return REACT_APP_API_NODE;
};

export const getNodeJSBackend = (): string => {
  const napiFromLocalStorage = window.localStorage.getItem(NAPI_LOCAL_STORAGE_KEY);

  let backendUrl = ""
  if (typeof napiFromLocalStorage === 'string') {
    backendUrl = napiFromLocalStorage;
  }
  else {
    backendUrl = getENVNodeBackend();
  }

  if (backendUrl.startsWith("./")) {// relative path start with ./
    backendUrl = getJavaBackend() + backendUrl.substring(1);
  }

  return backendUrl;
};

export const setNodeJSBackend = (value: string): void => {
  window.localStorage.setItem(NAPI_LOCAL_STORAGE_KEY, value);
};
const resetNodeJSBackend = (): void => {
  window.localStorage.removeItem(NAPI_LOCAL_STORAGE_KEY);
};
//*************** AAPI ANALYTICS BACKEND ***************

const AAPI_LOCAL_STORAGE_KEY = 'aapi';
export const DEFAULT_AAPI = './aapi';// relative path start with ./
export const DEFAULT_AAPI_FIN = '/api/aapi';// make absolute from DEFAULT_AAPI and DEFAULT_JAPI

const getENVAnalyticsBackend = (): string => {
  const { ANALYTICS_API = DEFAULT_AAPI } = process.env;
  return ANALYTICS_API;
};

export const getAnalyticsBackend = (): string => {
  const aapiFromLocalStorage = window.localStorage.getItem(AAPI_LOCAL_STORAGE_KEY);

  let backendUrl = ""
  if (typeof aapiFromLocalStorage === 'string') {
    backendUrl = aapiFromLocalStorage;
  }
  else {
    backendUrl = getENVAnalyticsBackend();
  }

  if (backendUrl.startsWith("./")) {// relative path start with ./
    backendUrl = getJavaBackend() + backendUrl.substring(1);
  }

  return backendUrl;
};

export const setAnalyticsBackend = (value: string): void => {
  window.localStorage.setItem(AAPI_LOCAL_STORAGE_KEY, value);
};
const resetAnalyticsBackend = (): void => {
  window.localStorage.removeItem(AAPI_LOCAL_STORAGE_KEY);
};

//*************** JAVA BACKEND ***************

const JAPI_LOCAL_STORAGE_KEY = 'japi';
export const DEFAULT_JAPI = '/api';

const getENVJavaBackend = (): string => {
  const { REACT_APP_API_JAVA = DEFAULT_JAPI } = process.env;

  return REACT_APP_API_JAVA;
};

export const getJavaBackend = (): string => {
  const japiFromLocalStorage = window.localStorage.getItem(JAPI_LOCAL_STORAGE_KEY);

  if (typeof japiFromLocalStorage === 'string') {
    return japiFromLocalStorage;
  }

  return getENVJavaBackend();
};

export const setJavaBackend = (value: string): void => {
  window.localStorage.setItem(JAPI_LOCAL_STORAGE_KEY, value);
};
const resetJavaBackend = (): void => {
  window.localStorage.removeItem(JAPI_LOCAL_STORAGE_KEY);
};

//*************** SHARED ***************

export const resetToDefaultState = (): void => {
  resetNodeJSBackend();
  resetJavaBackend();
  resetAnalyticsBackend();
};
