// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useMemo } from 'react';
import { Box, Button, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { findNearestColorInPalette } from './helper';
import { Colors } from '../../../Configuration/Styles/Colors';
export const analyticsTeamsColors: string[] = [
// #RRGGBB
  "#69692b",// green
  "#5DCA8A",// green default team 1
  "#9BCA3E",//grass
  // "#85CBA8",//dew

  "#1F4F79",//deepsea
  "#2633ef",//blue
  "#2F7BBF",//marine
  // "#76C4E2",//sky

  "#9F1F21",//cherry
  "#BD2527",//apple
  "#EB5757",//red default team 2
  // "#F16975",//dawn

  "#f56500",//carrot
  "#FF7900",//tangerine
  "#ffcc00",// Apple Yellow
  // "#FEDB6F",//lightning

  "#8176B5",//twilight
  "#BA77B1",//sunset
  "#7D7D7D",//cement
  // "#4D4D4F",//dusk
];

const defaultPaletteColums = 3

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'none',
    borderRadius: 0,
    minWidth: '50pt',
    maxWidth: '50pt',
    aspectRatio: 1,
    cursor: 'pointer',
    overflow: 'hidden',
    textShadow: "0px 0px 4px black",
//    textShadow: "1px 0 black, -1px 0 black, 0 1px black, 0 -1px black, 1px 1px black, -1px -1px black, -1px 1px black, 1px -1px black",
    '&.Mui-disabled': {
      color: Colors.analyticsDisabledTeamColor,
      textShadow: "0px 0px 4px white",
    },
    '&:hover': {
      border: "1px solid #000000",
      color: '#FFF',
    }
  },
  hexPicker: {
    margin: '15px',
  },
  swatch: {
    display: 'flex',
    width: '25px',
    height: '25px',
    minWidth: '25px',
    minHeight: '25px',
    borderRadius: '50%',
    border: `1px solid ${Colors.analyticsCircleBorder}`,
    cursor: 'pointer',
  },
}));

type Props = Readonly<{
  mainTeamName?: string;
  mainTeamColor?: string;

  otherTeamName?: string;
  otherTeamColor?: string;

  /** default = defaultPaletteColums (4) */
  paletteColumnsCount?: number;

  className?: string | undefined;

  onMainTeamColorChange?: (mainTeamColor: string) => void;
}>;

type PaletteItem = {
  color: string;
  index: number;
  title?: string;

  /** if color selected by mainTeam=1 if by otherTeam=2 */
  teamIndex?: 1 | 2;
};

const AnalyticsTeamsColorPalettePicker = (props: Props) => {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (paletteItem: PaletteItem) => {
    props.onMainTeamColorChange?.(paletteItem.color);
    setTimeout(handleClose, 200);
  };

  const paletteColors = analyticsTeamsColors

  const paletteColumnsCount = (!props.paletteColumnsCount) ? defaultPaletteColums : props.paletteColumnsCount;

  let otherTeamPaletteColor = useMemo(() => findNearestColorInPalette(paletteColors, props.otherTeamColor) , [paletteColors, props.otherTeamColor] );
  let mainTeamPaletteColor = useMemo(() => findNearestColorInPalette(paletteColors, props.mainTeamColor, otherTeamPaletteColor) , [paletteColors, props.mainTeamColor, otherTeamPaletteColor] );

  // split pallette to row columnts  
  const colorRowColumnArr: Array<Array<PaletteItem>> = useMemo(() => {
    const rowColumnArr: Array<Array<PaletteItem>> = []
    paletteColors.forEach((color, index) => {
      const rowIndex = Math.floor(index / paletteColumnsCount);
      while (rowColumnArr.length < (rowIndex + 1)) {
        rowColumnArr.push([]);
      }
  
      let title: string | undefined = undefined
      let teamIndex: 1 | 2 | undefined = undefined
      if (mainTeamPaletteColor === color) {
        title = props.mainTeamName;
        teamIndex = 1;
      }
      else if (otherTeamPaletteColor === color) {
        title = props.otherTeamName;
        teamIndex = 2;
      }
  
      rowColumnArr[rowIndex].push({
        color,
        index,
        title,
        teamIndex,
      });
    })
    return rowColumnArr;
  }, [paletteColors, paletteColumnsCount, mainTeamPaletteColor, otherTeamPaletteColor, props.mainTeamName, props.otherTeamName]);

  // set best match colors

  const renderPalette = () => (
    colorRowColumnArr.map((row, rowIndex) => (
      <Box>
      {
        row.map((paletteItem, columnIndex) => (
          <Button 
            id={ `${paletteItem.index}` }
            key={`${rowIndex}x${columnIndex}`}
            variant="contained"
            disabled={ paletteItem?.teamIndex === 2 }
            onClick={ () => {
              handleClick(paletteItem)
            }}
            className={ classes.button }
            sx={ {
              backgroundColor: paletteItem.color,
              '&:hover': {
                backgroundColor: paletteItem.color,
              },
              '&.Mui-disabled': {
                backgroundColor: paletteItem.color,
              }
            } }
          >
            { paletteItem.title }
          </Button>
        ))
      }
      </Box>
    ))
  );


  const handlePopoverOpenClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box className={props.className} >
      <div
        className={ classes.swatch }
        style={{ backgroundColor: props.mainTeamColor }}
        onClick={ handlePopoverOpenClick }
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        >
          <div className={ classes.hexPicker }>
            { renderPalette() }
          </div>
      </Popover>
    </Box>
  );
};


export default AnalyticsTeamsColorPalettePicker;
