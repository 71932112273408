// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { MenuElPathArr, getMenuArrayFromUrlPath } from '../../../Menu/Data/MenuStructure';
import { MenuID } from '../../../Menu/Data/MenuElement';
import { useLocation, useParams } from 'react-router-dom';
import PageHeader2Row from '../PageHeader2Row';
import usePagedData from '../../../Data/Analytics/AnalyticsVideoArchive/hook';
import AnalyticsVideoArchive from '../../../Data/Analytics/AnalyticsVideoArchive/AnalyticsVideoArchive';
import { getResponseManyDataOne } from '../../../Data/_Networking/ReactQuery/ResponseMany';
import { useAppConfig } from '../../../Localization/AppContextProvider/helpers';

import dayjs from 'dayjs';
import { intl } from '../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../Localization';
import { MenuDropdownAction } from '../../_BaseUI/MenuDropdownButton';
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
require('dayjs/locale/ru')

type Props = Readonly<{
  onEditSecondLineClick?: () => void

  secondLineMenuActions?: MenuDropdownAction<AnalyticsVideoArchive>[];
}>;

const AnalyticsVideoPageHeader: React.FC<Props> = (props: Props) => {
  const { localization: { locale } } = useAppConfig();
  dayjs.locale(locale);

  let { AnalyticsVideoArchiveDetails: recordId } = useParams();
  
  const { status: fetchStatus, data: gameRecordsData } = usePagedData({ itemId: recordId });
  const gameRecord: AnalyticsVideoArchive | undefined = getResponseManyDataOne(gameRecordsData?.data)

  const startAt = (gameRecord?.start) ? dayjs(gameRecord?.start) : undefined;
  const endAt = (gameRecord?.end) ? dayjs(gameRecord?.end) : undefined;

  const videoDuration: string = (() => {
    const diffMinutes = endAt?.diff(startAt, 'minutes');
    return `${diffMinutes} ${intl().formatMessage({ id: LOCALIZATION.minutes })}`
  })()
  const videoTimeInfo = (startAt) ? ` / ${ startAt?.format('dd l').toUpperCase() } ${ startAt?.format('LT') }, ${ videoDuration }` : ""

  const location = useLocation();
  const dymanicIdTitlesDict = (recordId && gameRecord?.title) ? {[recordId] : `${gameRecord?.title}${videoTimeInfo}`} : undefined;
  const pathMenuArr: MenuElPathArr = getMenuArrayFromUrlPath(location.pathname, dymanicIdTitlesDict);

  if (fetchStatus === 'loading') {
    return null;
  }
  
  return (
    <PageHeader2Row<AnalyticsVideoArchive>
    id = "AnalyticsPageHeader"
    pathMenuArr={ pathMenuArr }  
    removeMenuIdsFromMenu={ [MenuID.Analytics] }
    onEditSecondLineClick={ props.onEditSecondLineClick }
    secondLineMenuItem={ gameRecord }
    secondLineMenuActions={ props.secondLineMenuActions }
    />
  )
};


export default AnalyticsVideoPageHeader;
