// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import Team from '../Team';
import { PAGE_LIMIT } from '../../../../constants';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import { getAllCached, useFetch } from '../fetch';


const useFetchAllPagesTeams = () => {
  const [page, setPage] = React.useState<number>(0);
  const allCached: ResponseMany<Team[]> | undefined = getAllCached();

  const handleFlushPage = () => {
    setPage(0);
  };

  const fetchedTeams = useFetch({
    page,
    enableToFetch: true,
  });

  React.useEffect(() => {
    if (
      fetchedTeams.status === 'success'
      && fetchedTeams.data?.meta.pagination?.total
    ) {
      const maxPage = Math.ceil(fetchedTeams.data?.meta.pagination.total / PAGE_LIMIT);
      const isFetchedAllPages = (
        (page + 1) === maxPage
        || (page + 1) > maxPage
      );

      if (!isFetchedAllPages) {
        setPage(page + 1);
      }
    }
  }, [fetchedTeams.status, fetchedTeams.data?.meta.pagination?.total, page]);

  return ({
    allCached,
    handleFlushPage,
    status: fetchedTeams.status,
  });
};


export default useFetchAllPagesTeams;
