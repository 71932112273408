// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Box, Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Card from '../../../Components/_Layout/Card';
import Popup from '../../../Components/_Layout/Popup';
import Spinner from '../../../Components/_BaseUI/Spinner/Spinner';
import TeamRef, { NewTeamRef } from '../../../Data/Analytics/AnalyticsTeamRef';
import LOCALIZATION from '../../../Localization';
import FieldSet, { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';
import useMutationTeamRef, { ActionType } from '../../../Data/Analytics/AnalyticsTeamRef/fetch/useMutation';
import getTemplateURLToImage from '../../../Tools/getTemplateURLToImage';
import { useCameraSettings } from '../../../Data/Camera/HWCameraSettings/hook/Hooks';
import AnalyticsExternalTeamAutocompleteSelect from '../../../Components/_AutocompleteSelect/AnalyticsExternalTeamAutocompleteSelect';
import Button from '../../../Components/_BaseUI/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SX as MainButtonSX } from '../../../Components/_BaseUI/MainButton';
import EditTitlePopup, { EditTitleFields } from '../../../Components/_PopupControls/EditTitlePopup';
import useMutationExternalTeam from '../../../Data/Analytics/AnalyticsExternalTeam/fetch/useMutation';
import { flushCache as flushAnalyticsExternalTeamsCache } from '../../../Data/Analytics/AnalyticsExternalTeam/fetch';

type Props = Readonly<{
  isServer: boolean;
  teamRef?: TeamRef | NewTeamRef;
  onPostedSuccess: () => void;
  onClose: () => void;
} & WrappedComponentProps>;


const initialState = (teamRef?: TeamRef| NewTeamRef) => (): TeamRef | NewTeamRef => ({
  ...teamRef,
  externalTeamId: teamRef?.externalTeamId || '',
  name: teamRef?.name || '',
  shortName: teamRef?.shortName || '',
  color: teamRef?.color || `#${ Math.floor(Math.random()*16777215).toString(16) }`, // random color for new team
  logo: teamRef?.logo || null,
  address: teamRef?.address,
  notes: teamRef?.notes,
});

const TeamRefPopup: React.FC<Props> = (props: Props) => {
  const cameraSettings = useCameraSettings(true);
  const [teamRef, setTeamRef] = React.useState<TeamRef | NewTeamRef>(initialState(props.teamRef));
  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);
  // Accounts with super_admin access can change all fields.
  // Admin and user accounts can change only name and notes.
  const { mutateAsync: mutateTeamRef } = useMutationTeamRef();
  const { mutateAsync: mutateExternalTeam } = useMutationExternalTeam();
  const [addingExternalTeamId, setAddingExternalTeamId] = React.useState<EditTitleFields | undefined>(undefined);

  const isAddingNewTeamRef = (!props.teamRef);

  const isRequredFiledsValid = () => {
    return !!teamRef.externalTeamId.length && !!teamRef.name.length && !!teamRef.shortName.length && !!teamRef.color.length
  }

  const isApplyEnabled = ((!props.isServer) || 
  (// add
    isAddingNewTeamRef &&
    isRequredFiledsValid()
  )||
  (// edit
    !isAddingNewTeamRef &&
    isRequredFiledsValid()
  )
  );

  const handleChangeExternalTeamId = (externalTeamId: string | null) => {
    if (externalTeamId) {
      setTeamRef({
        ...teamRef,
        externalTeamId,
      });
    }
  };
  const handleChangeName = (name: string) => {
    setTeamRef({
      ...teamRef,
      name,
    });
  };
  const handleChangeShortName = (shortName: string) => {
    setTeamRef({
      ...teamRef,
      shortName,
    });
  };
  const handleChangeColor = (color: string) => {
    setTeamRef({
      ...teamRef,
      color,
    });
  };
  const handleSetLogoBase64 = (logoInBase64?: string) => {
    setTeamRef({
      ...teamRef,
      logo: (logoInBase64 || null),
    });
  };

  const handleChangeTeamRefField = (fieldName: keyof TeamRef) => (value: string): void => {
    setTeamRef({
      ...teamRef,
      [fieldName]: value,
    });
  }
  const handleSubmit = async () => {
    try {
      if (isApplyEnabled) {

        setIsLoaderShowing(true);
        await mutateTeamRef({
          teamRef,
          type: (isAddingNewTeamRef) ? ActionType.Add : ActionType.Patch,
          }, {
          onSuccess: async () => {
            try {
              await mutateExternalTeam(
                {
                  team: {
                    id: teamRef.externalTeamId,
                    team: {
                      id: teamRef.externalTeamId,
                      full_name: teamRef.name,
                      short_name: teamRef.shortName,
                      color: teamRef.color,
                    }
                  },
                  type: ActionType.Patch,
                }, {
                  onSuccess: props.onPostedSuccess,
                },
              );
            } catch (error) {
              alert(error);
              setIsLoaderShowing(false);
            }
          }
        });
      }
    } catch (error) {
      alert(error);
      setIsLoaderShowing(false);
    }
  };
  const handleAddExternalTeam = async () => {
    setAddingExternalTeamId({})
  };

  const handleAddExternalTeamIdSubmit = async (editedItem: EditTitleFields) => {
    try {
      const newId = editedItem.title;
      if (!newId?.length) {
        alert("Error: Id can't be empty");
        return
      }

      await mutateExternalTeam(
        {
          team: {
            id: newId,
            team: {
              id: newId,
              full_name: teamRef.name || "New",
              short_name: teamRef.shortName || "New",
              color: teamRef.color,
            }
          },
          type: ActionType.Add,
        }, {
          onSuccess: () => {
            setAddingExternalTeamId(undefined);
            flushAnalyticsExternalTeamsCache();
            handleChangeExternalTeamId(newId)
          },
        },
      );
    } catch (error) {
      alert(error);
      setAddingExternalTeamId(undefined);
      flushAnalyticsExternalTeamsCache();
    }
  };

  const handleCloseAddExternalTeamIdPopup = () => {
    setAddingExternalTeamId(undefined);
  };

  return (
    <Popup
      isWide
      headerTitle={ props.intl.formatMessage({ id: LOCALIZATION.team }) }
      applyTitle={ props.intl.formatMessage({ id: (isAddingNewTeamRef) ? LOCALIZATION.add : LOCALIZATION.save }) }
      isApplyDisabled={ (!isApplyEnabled || isLoaderShowing) }
      isCloseDisabled={ isLoaderShowing }
      onApply={ handleSubmit }
      onClose={ props.onClose }
    >
      <Grid
        item
        xs={ 12 }
      >
        <Card>
          {
            (isLoaderShowing) ?
            <>
            <br /><br /><Spinner /><br /><br />
            </>
              :
              <form
                action={ window.location.href }
                onSubmit={ handleSubmit }
              >
                <FieldSet
                  key={ 'TeamId' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.team_id }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.team_id_help }) }                    
                  input={ {
                    type: FieldSetInputType.Other,
                    children: (
                      <Box display="flex" alignItems="center" flexDirection="row" width="100%" >
                        <AnalyticsExternalTeamAutocompleteSelect
                          selectedExternalTeamID={ teamRef.externalTeamId || "" }
                          onSelect={ handleChangeExternalTeamId }
                        />
                        <Button 
                          onClick={ handleAddExternalTeam }
                          variant={ "outlined" }
                          sx = {{
                            ...MainButtonSX.icon, ...{
                              width: 'initial',
                              minWidth: 'initial',
                              marginLeft: 1,
                              padding: 0,
                            }
                          }}
                         >
                          <AddCircleIcon/>
                        </Button>
                      </Box>
                    ),
                  } }
                />
                <FieldSet
                  key={ 'Name' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.team_name }) }
                  input={ {
                    type: FieldSetInputType.Text,
                    value: teamRef.name,
                    onChange: handleChangeName,
                  } }
                />
                <FieldSet
                  key={ 'Short Name' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.team_short_name }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.tooltip_team_short_name })}
                  input={ {
                    type: FieldSetInputType.Text,
                    value: teamRef.shortName,
                    onChange: handleChangeShortName,
                  } }
                />
                <FieldSet
                  key={ 'Team Color' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.team_color }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.tooltip_team_color_use })}
                  input={ {
                    type: FieldSetInputType.Color,
                    value: teamRef.color,
                    onChange: handleChangeColor,
                  } }
                />
                <FieldSet
                  key={ 'Team Logo' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.team_logo }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.alert_only_png_images })}
                  input={ {
                    type: FieldSetInputType.File,
                    value: (
                      getTemplateURLToImage(cameraSettings?.urls?.eventsImageTemplate, "events_image_path", teamRef.logo)
                    ),
                    buttonText: props.intl.formatMessage({ id: LOCALIZATION.team_upload_new_logo }),
                    onChange: handleSetLogoBase64,
                  } }
                />
                <FieldSet
                  label={ `${props.intl.formatMessage({ id: LOCALIZATION.home_address })} (${ props.intl.formatMessage({ id: LOCALIZATION.optional }) })` }
                  input={ {
                    type: FieldSetInputType.Text,
                    rows: 2,
                    value: teamRef.address || "",
                    onChange: handleChangeTeamRefField('address'),
                  } }
                  onErrorTooltipText={ props.intl.formatMessage({ id: LOCALIZATION.value_incorrect }) }
                />
                <FieldSet
                  label={ `${props.intl.formatMessage({ id: LOCALIZATION.camera_notes })} (${ props.intl.formatMessage({ id: LOCALIZATION.optional }) })` }
                  input={ {
                    type: FieldSetInputType.Text,
                    rows: 4,
                    value: teamRef.notes || "",
                    onChange: handleChangeTeamRefField('notes'),
                  } }
                  onErrorTooltipText={ props.intl.formatMessage({ id: LOCALIZATION.value_incorrect }) }
                />
              </form>
          }
          {
            (addingExternalTeamId) &&
              <EditTitlePopup
                popupTitle={ props.intl.formatMessage({ id: LOCALIZATION.add_team_id }) }
                titleLabel={ props.intl.formatMessage({ id: LOCALIZATION.team_id }) }
                titleHint={ props.intl.formatMessage({ id: LOCALIZATION.team_id_add_help }) }
                item={ addingExternalTeamId }
                onSubmit={ handleAddExternalTeamIdSubmit }
                onClose={ handleCloseAddExternalTeamIdPopup }
              />
          }
        </Card>
      </Grid>
    </Popup>
  );
};


export default injectIntl(TeamRefPopup);
