import { memo, useEffect, useState } from "react";
import { IconGrid, IconList } from "../../../Icons/Icons";
import { ViewType } from "./ViewType.enum";
import styled from "@emotion/styled";
import React from "react";

interface IProps {
    initialView?: ViewType,
    onChange?: (value: ViewType) => void
}

const Container = styled.div`
    border-radius: 6px;
    background: #C2CFE0;
    display: flex;
    width: fit-content;
`

const BasicOption = (props: { active?: boolean, children: React.ReactNode, className?: string } & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
    const { children } = props;
    const hmtlProps = { ...props };
    delete hmtlProps.active;
    return <button {...hmtlProps}>{children}</button>
}

const Option = styled(BasicOption)`
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: ${props => (props.active ? '#18A0FB' : '#C2CFE0')};
`

const ViewSwitcher = memo((props: Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement>, "onChange"> & IProps) => {
    const [view, setView] = useState<ViewType>(props.initialView ?? ViewType.LIST)
    const { onChange } = props;
    useEffect(() => {
        onChange?.(view)
    }, [view, onChange])
    return <>
        <Container {...props} onChange={undefined}>
            <Option active={view === ViewType.LIST} onClick={() => setView(ViewType.LIST)}><IconList></IconList></Option>
            <Option active={view === ViewType.GRID} onClick={() => setView(ViewType.GRID)}><IconGrid></IconGrid></Option>
        </Container>

    </>
})

export default ViewSwitcher;