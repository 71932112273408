// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Popup from '../../Components/_Layout/Popup';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import LOCALIZATION from '../../Localization';
import AnalyticsGameArchive from '../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import useMutationGame, { ActionType } from '../../Data/Analytics/AnalyticsGameArchive/fetch/useMutation';
import TeamImageSelectView from './TeamImageSelectView';
import Card from '../../Components/_Layout/Card';

type Props = Readonly<{
  game?: AnalyticsGameArchive;
  // page: number;
  onSubmit: (game: AnalyticsGameArchive) => void;
  onClose: () => void;
} & WrappedComponentProps>;

const AnalyticsGameMarkupIdPopup: React.FC<Props> = (props: Props) => {
  const [game, setGame] = React.useState<AnalyticsGameArchive | undefined>(props.game);

  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);

  const { mutateAsync: mutateGamesConfig } = useMutationGame();

  const isApplyDisabled = !(game && game.id?.length);
  const handleSaveGame = async () => {
    if (!isApplyDisabled) {
      setIsLoaderShowing(true);

      // if team colors changed also save them
      if (props.game?.team1_color !== game.team1_color || props.game?.team2_color !== game.team2_color) {
        try {
          await mutateGamesConfig(
            {
              type: ActionType.Patch,
              archive: {
                id: game.id,
                game
              }
            }, {
              onSuccess: () => {
              },
            },
          );
        } catch (ignore) {
        }
      }

      try {
        await mutateGamesConfig(
          {
            type: ActionType.PatchTeamMapping,
            archive: {
              id: game.id,
              gameTeamMapping: {
                team1_markup_id:
                  // is not null or undefinded could be wrote like (!=null), but the better way is to strictly set the conditions cos of the compilators that can "optimise" the solution
                  (game?.team1_markup_id !== null && game?.team1_markup_id !== undefined) ?
                    game?.team1_markup_id : 0,
                team2_markup_id: (game?.team2_markup_id !== null && game?.team1_markup_id !== undefined) ? game?.team2_markup_id : 1,
              }
            }
          }, {
            onSuccess: () => {
              setIsLoaderShowing(false);
              props.onSubmit(game);
            },
          },
        );
      } catch (error) {
        setIsLoaderShowing(false);
        alert(error);
      }
    }
  };

  const renderTeamImageSelectShowing = () => {
    if (!game) {
      return null;
    }

    return (
      <TeamImageSelectView
        game = {game}
        onTeamImageChange={(team1_markup_id, team2_markup_id) => {
          setGame({
            ...game,
            team1_markup_id,
            team2_markup_id,
          })
        }}
        onTeam1ColorChange={(team1_color) => {
          setGame({
            ...game,
            team1_color,
          })
        }}
        onTeam2ColorChange={(team2_color) => {
          setGame({
            ...game,
            team2_color,
          })
        }}
       />
    )
  }

  return (
    <Popup
      isMoreWide
      headerTitle={ props.intl.formatMessage({ id: LOCALIZATION.analytics_team_mapping }) }
      applyTitle={ props.intl.formatMessage({ id: LOCALIZATION.save }) }
      isApplyDisabled={ (isApplyDisabled || isLoaderShowing) }
      isCloseDisabled={ isLoaderShowing }
      onApply={ handleSaveGame }
      onClose={ props.onClose }
    >
      <Card>
        {
          (isLoaderShowing) ?
          <>
          <br /><br /><Spinner /><br /><br />
          </>
            :
          renderTeamImageSelectShowing()
        }
      </Card>
    </Popup>
  );
};


export default injectIntl(AnalyticsGameMarkupIdPopup);
