// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import CircledLabel, { LabelType } from '../../../../Components/_BaseUI/CircledLabel/CircledLabel'
import MainButton from '../../../../Components/_BaseUI/MainButton'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content'
import LOCALIZATION from '../../../../Localization';
import { getLabelFromUsedDiskSpacePercent } from '../../../CamerasPage/CameraCardBlock/CameraPreviewInfo/helpers';
import { getMenuLink } from '../../../../Menu/Data/MenuStructure';
import { MenuID } from '../../../../Menu/Data/MenuElement';
import { useNavigate } from 'react-router-dom';
import { formatBitrate } from '../../../../Tools/b2Mb';
import { ssdFreeSpaceString } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsMetrics';
import { useCameraData } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks';
import VertGrid from '../../../../Components/_Layout/VertGrid';
import AuthorisationManager from '../../../../Data/Auth/AuthorisationManager';
import { isUserRoleMinimum, UserRole } from '../../../../Data/AccountUsers/UserWithoutID';


const StorageCard: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const cameraSettings = useCameraData();
  const { intl: { formatMessage } } = props;
  const user = AuthorisationManager.shared.user
  
  const ssdFreeSpace = ssdFreeSpaceString(cameraSettings?.settings)
  const uploadForAnalitics = (cameraSettings?.settings?.syncConfig?.syncEnabled && cameraSettings?.settings?.syncState?.serverState === 'connected')

  let uploadForAnaliticsString = formatMessage({ id: uploadForAnalitics ? LOCALIZATION.on : LOCALIZATION.off })

  if (uploadForAnaliticsString && cameraSettings?.settings?.syncState?.currentSyncBitrate !== undefined) {// (5 mbit/s)
    uploadForAnaliticsString += ` (${ formatBitrate(cameraSettings?.settings?.syncState?.currentSyncBitrate) })`
  }
  
  const navigate = useNavigate();

  const handleConfigureStorage = () => {
    const menuLink = getMenuLink(MenuID.Streaming);
    if (menuLink) {
      navigate(menuLink);
    }
  }

  const handleStorageSyncChange = () => {
    const menuLink = getMenuLink(MenuID.Storage);
    if (menuLink) {
      navigate(menuLink);
    }
  }

  return (
    <MainCard>
      <RegularCardHeader title={ formatMessage({ id: LOCALIZATION.storage }) }/>
      <MainCardContent>
        <VertGrid>
          <Grid
            container
            spacing={ 1 }
            justifyContent="space-between"
            sx={ { flexGrow: 1 } }
            direction="column"
          >
            <Grid item xs={ 12 }>
              <CircledLabel
                key="ssdFreeSpace"
                labelType={ getLabelFromUsedDiskSpacePercent(cameraSettings?.settings?.lastMetricsData?.mainSsdDiskUsePercent) }
                title={ formatMessage({ id: LOCALIZATION.storage_left }) }
                stateText={ ssdFreeSpace }//Storage Left: 200 GB (2h 50min)
              />
            </Grid>
            <Grid item xs={ 12 }>
              <CircledLabel
                key="j2Volumes"
                labelType={ uploadForAnalitics ? LabelType.Green : LabelType.Grey }
                title={ formatMessage({ id: LOCALIZATION.storage_sync_short }) }
                stateText={ uploadForAnaliticsString }//Storage Sync: ON (5 mbit/s)
              />
            </Grid>
          </Grid>
          { (isUserRoleMinimum(user?.role, UserRole.Admin)) &&
            <Grid container spacing={ 1 } 
              direction="row"
              justifyContent="flex-end" 
              sx={ {
                marginTop: '15px',
              } }
            >
              <Grid item>
                <MainButton onClicked={ handleConfigureStorage } title={ formatMessage({ id: LOCALIZATION.configure }) } />
              </Grid>
              <Grid item>
                <MainButton onClicked={ handleStorageSyncChange } title={ formatMessage({ id: LOCALIZATION.storage_sync_short }) } />
              </Grid>
            </Grid>
          }
        </VertGrid>
      </MainCardContent>
    </MainCard>
  )
};


export default injectIntl(StorageCard);
