// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import ApiError from './Types'
import { intl } from '../../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../../Localization';

export function isInstanceOfApiError(object: any): object is ApiError {
    try {
        return 'error' in object && 'scope' in object
    } catch {
        return false
    }
}

export function generateUserError(apiError: ApiError): Error {
    let message: string;
    const translate = intl().formatMessage;

    const generalMessage = () => {
        const statusCode = apiError.statusCode

        switch (statusCode) {
            case 400:
                return translate({ id: LOCALIZATION.error_general }) + ' (' + translate({ id: LOCALIZATION.error_wrong_parameters }) + ')'
            case 401:
                return translate({ id: LOCALIZATION.session_failed })
            default:
                if (statusCode) {
                    return translate({ id: LOCALIZATION.error_general }) + ' (' + statusCode + ')'
                } else {
                    return translate({ id: LOCALIZATION.connection_error })
                }
        }
    }

    switch (apiError.scope) {
        case 'signin':
            switch (apiError.statusCode) {
                case 404:
                    message = translate({ id: LOCALIZATION.error_wrong_signin_credentials })
                    break
                default:
                    message = generalMessage()
                    break
            }
            break
        case 'camera_api':
            switch (apiError.statusCode) {
                case 504:
                    message = translate({ id: LOCALIZATION.resource_is_unreachable })
                    break
                case 400:
                    message = translate({ id: LOCALIZATION.wrong_resource_name })
                    break
                default:
                    message = generalMessage()
                    break
            }
            break
        default:
            message = generalMessage()
            break
    }

    return Error(message)
}
