// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useEffect, useState } from 'react';
import { Grid, SxProps, Theme } from '@mui/material';

import HardwareInfo from './Info'
import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import MainButton from '../../../../Components/_BaseUI/MainButton'
import MainCheckbox from '../../../../Components/_BaseUI/MainCheckbox/MainCheckbox'
import { useCameraData } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks'
import { CameraNotAvailable, CameraTools } from '../../../../Tools/Tools'
import CameraContext from '../../../../Data/Camera/HWCameraSettings/fetch/Context'
import {  CameraOperationMode } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature'
import { CameraInterface } from '../../../../Data/Camera/HWCameraSettings/Camera'
import { useGpioPressActionData } from '../../../../Data/Camera/Gpio/Hooks'
import { ErrorMessageHelper } from '../../../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessageHelper'
import { generateUserError } from '../../../../Data/_Networking/AxiosFetch/Errors/Tools'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content'
import { useNapiStreamingSettingsFetch } from '../../../../Data/NapiStreamingSettings/hook';
import useMutationNapiSettings, { ActionType } from '../../../../Data/NapiStreamingSettings/hook/useMutation';
import { StreamingSettingsKey } from '../../../../Data/NapiStreamingSettings';
import { useIntl } from 'react-intl';
import LOCALIZATION from '../../../../Localization';
import VertGrid from '../../../../Components/_Layout/VertGrid';
import AuthorisationManager from '../../../../Data/Auth/AuthorisationManager';
import { isUserRoleMinimum, UserRole } from '../../../../Data/AccountUsers/UserWithoutID';

type MaintenanceButtonProps = Readonly<{
  isChecked: boolean;
  isDisabled: boolean;
  onClicked: () => void;
  sx?: SxProps<Theme>;
}>;


const MaintenanceButton: React.FC<MaintenanceButtonProps> = (props: MaintenanceButtonProps) => {
  const intl = useIntl();

  return (
    <Grid
      sx={ { marginLeft: '4px', ...props.sx } }
    >
      <MainCheckbox
        isChecked={ props.isChecked }
        isDisabled={ props.isDisabled }
        title={ intl.formatMessage({ id: LOCALIZATION.maintenance_mode }) }
        onClicked={ props.onClicked }
      />
    </Grid>
  )
}

const ShutdownButton: React.FC = () => {
  const intl = useIntl();
  const camera = useCameraData()

  const gpioPressActions = useGpioPressActionData()

  if (!camera) {
    return <></>
  }

  const isAvaialbleShutdown = gpioPressActions.isAvaialbleSoftShutdownPi
  const dataProvider = CameraContext.current?.cameraDataProvider

  const handleShutdown = () => {
    if (!window.confirm(intl.formatMessage({ id: LOCALIZATION.confirm_shut_down }))) {
      return
    }
    dataProvider?.softShutDownCamera().then(() => {
      // console.log('success')
    }).catch(error => {
      ErrorMessageHelper.handleError(generateUserError(error))
    })
  }
  const isCameraOnline = camera.status === 'on'

  return (
    <Grid container spacing={ 1 } justifyContent="flex-end" style={ { display: isCameraOnline ? undefined : 'none' } }>
      <Grid item key="shutdown" style={ { display: isAvaialbleShutdown ? undefined : 'none' } }>
        <MainButton onClicked={ handleShutdown } title={ intl.formatMessage({ id: LOCALIZATION.shut_down_camera }) } />
      </Grid>
    </Grid>
  )
};


type HardwareCardProps = Readonly<{
  camera: CameraInterface;
}>;

let prevMaintenanceMode: CameraOperationMode | undefined

const RenderHardwareCard: React.FC<HardwareCardProps> = ({ camera }: HardwareCardProps) => {
  const intl = useIntl();
  const user = AuthorisationManager.shared.user

  useNapiStreamingSettingsFetch();
  // const storageSettings = getCached();
  // const isNodeMaintenanceModeOn = !!storageSettings?.data?.maintenance_mode;
  const { mutateAsync: mutateSettings } = useMutationNapiSettings();

  const currentMaintenanceMode = camera?.settings?.mode
  const isNotProxyMode = (camera?.settings?.activeOptions?.isProxyMode !== "true")

  const [activeMaintenanceMode, setActiveMaintenanceMode] = useState(currentMaintenanceMode)
  const handleMaintenanceCheckboxChanged = async () => {
      try {
        const isOn = !(activeMaintenanceMode === CameraOperationMode.Maintenance)
        const mode = isOn ? CameraOperationMode.Maintenance : CameraOperationMode.Operational

        setActiveMaintenanceMode(mode)

        await CameraContext.currentContext()?.cameraDataProvider?.setMode(mode)

        await mutateSettings({// node-pi backed also set to isMaintenanceModeOn
          type: ActionType.Post,
          settings: { [StreamingSettingsKey.MaintenanceMode]: isOn },
        });
      } catch (error) {
        alert(error);
      }  
  }

  useEffect(() => {// update MaintenanceMode to new value if chnaged on other browser
    if (currentMaintenanceMode) {
      if (prevMaintenanceMode === undefined) {
        prevMaintenanceMode = currentMaintenanceMode
      }
      else if (prevMaintenanceMode !== currentMaintenanceMode) {
        prevMaintenanceMode = currentMaintenanceMode
        setActiveMaintenanceMode(currentMaintenanceMode)
      }
    }
  }, [currentMaintenanceMode])

  const isOnline = camera.status === 'on'

  const title = (() => {
    if (!isOnline) {
      return [intl.formatMessage({ id: LOCALIZATION.hardware }), CameraTools.statusString(camera.status)].join(': ')
    } else {
      return intl.formatMessage({ id: LOCALIZATION.hardware })
    }
  })()

  return (
    <MainCard>
      <RegularCardHeader title={ title }/>
      <MainCardContent>
        <VertGrid>
          { (isNotProxyMode) &&
            <HardwareInfo/>
          }

          { (isUserRoleMinimum(user?.role, UserRole.SuperAdmin)) &&
            <MaintenanceButton
              sx={ {
                marginTop: '20px'
              } }
              isChecked={ activeMaintenanceMode === CameraOperationMode.Maintenance }
              isDisabled={ !isOnline }
              onClicked={ handleMaintenanceCheckboxChanged }
            />
          }

          { (isUserRoleMinimum(user?.role, UserRole.Admin) && isNotProxyMode) &&
            <Grid container spacing={ 1 } 
              direction="row"
              justifyContent="flex-end" 
              sx={ {
                marginTop: '15px',
              } }
            >
              <Grid item>
                <ShutdownButton/>
              </Grid>
            </ Grid>
          }
        </VertGrid>
      </MainCardContent>
    </MainCard>
  )
};

const HardwareCard: React.FC = () => {
  const camera = useCameraData()

  if (!camera) return (<CameraNotAvailable/>)

  return (
    <RenderHardwareCard
      camera={ camera }
    />
  );
};


export default HardwareCard;
