// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { TemperatureControllerReviewInterface } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature'
import { CameraInterface } from '../../../../Data/Camera/HWCameraSettings/Camera'
import { JetsonStatusReviewBuilder } from '../../../../Data/Camera/Jetson/Tools'
import { JetsonInterface } from '../../../../Data/Camera/Jetson/Types'
import { CameraTools, formattedMetricValue, formattedState } from '../../../../Tools/Tools'
import { Colors } from '../../../../Configuration/Styles/Colors'
import { MainCardHeaderModelInterface } from '../Types'
import { PiInterface } from '../../../../Pages/HardwarePage/Cards/PI/Types'
import { intl } from '../../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../../Localization';

export class PICardHeadingModelBuilder {
    constructor (private pi: PiInterface) { }

    build(): MainCardHeaderModelInterface {
        const pi = this.pi
        const name = pi.name ?? intl().formatMessage({ id: LOCALIZATION.default_pi_name })

        const stateString = CameraTools.statusString(pi.status)
        const barColor = pi.status === 'on' ? Colors.mainGreen : Colors.mainRed
        if (pi.status === 'on') {
            // Нет понятия критический температуры в настройках
            // Удалять пока не будем, мало ли, повесят кулер и добавят настройки

            const isCritical = false

            const criticalString = isCritical ? intl().formatMessage({ id: LOCALIZATION.critical }) : undefined
            const statusColor = isCritical ? Colors.mainRed : Colors.mainTitle
            const cpuLoadString = formattedMetricValue(pi.cpuLoad, '%', { name: intl().formatMessage({ id: LOCALIZATION.cpu }), multiplyUnit: false })
            const cpuTempString = formattedMetricValue(pi.cpuTemp, '˚C')
            const cpuString = [cpuLoadString, cpuTempString].filter(text => !!text).join(', ')
            const boardTempString = formattedMetricValue(pi.boardTemp, '˚C', { name: intl().formatMessage({ id: LOCALIZATION.board }) })
            const statuses: string[] = []
            if (isCritical) {
                statuses.push('⚠️')
            }
            statuses.push([[cpuString, boardTempString].filter(text => !!text).join(' | ')].filter(text => !!text).join(' '))

            return {
                name: [name, stateString].join(': '),
                barColor: barColor,
                statusName: criticalString,
                statuses: statuses,
                statusColor: statusColor
            }
        } else {
            return {
                name: [name, stateString].join(': '),
                barColor: barColor,
                statuses: []
            }
        }
    }
}

export class JetsonCardHeadingModelBuilder {
    private intl = (props: { id: string }) => '';
    constructor (private jetson: JetsonInterface, intl: any) {
        this.intl = intl;
    }

    build(): MainCardHeaderModelInterface {
        const jetson = this.jetson
        const name = jetson.name

        let stateString = jetson.state ? intl().formatMessage({ id: LOCALIZATION.on }) : intl().formatMessage({ id: LOCALIZATION.off })
        const barColor = jetson.state ? Colors.mainGreen : Colors.mainRed

        if (jetson.powerVoltage) {
            if (jetson.powerVoltage < 1.3 && jetson.powerVoltage > 0.5) {
                stateString = intl().formatMessage({ id: LOCALIZATION.jetson_status_firmware_update }).toUpperCase()
            }
        }

        if (jetson.state) {
            const jStatus = new JetsonStatusReviewBuilder(jetson, this.intl).build()
            const statusColor = jStatus.isCritical ? Colors.mainRed : Colors.mainTitle

            return {
                name: [name, stateString].join(': '),
                barColor: barColor,
                statusName: jStatus.isCritical ? intl().formatMessage({ id: LOCALIZATION.critical }) : undefined,
                statuses: jStatus.statuses,
                statusColor: statusColor
            }
        } else {
            return {
                name: [name, stateString].join(': '),
                barColor: barColor,
                statuses: [intl().formatMessage({ id: LOCALIZATION.no_connection_to_device_off })],
                statusColor: Colors.mainTitle
            }
        }
    }
}

export class TemperatureControllerHeadingModelBuilder {
    constructor (private review: TemperatureControllerReviewInterface) { }

    build(name: string): MainCardHeaderModelInterface {
        const isOn = this.review.isOn
        const stateString = formattedState(isOn, { symbolize: true })
        const powerString = isOn && this.review.power ? formattedMetricValue(this.review.power, '%', { multiplyUnit: false }) : undefined
        const powerTitle = powerString && this.review.powerTitle ? this.review.powerTitle + ":" : undefined
        const temperatureString = this.review.temperature ? formattedMetricValue(this.review.temperature, '˚C') : undefined
        const temperatureTitle = temperatureString && this.review.temperatureTitle ? this.review.temperatureTitle + ":" : undefined

        const detailsArr = [powerTitle, powerString, temperatureTitle, temperatureString].filter(text => text !== undefined)
        const details = detailsArr.length > 0 ? ` (${detailsArr.join(' ')})` : ""

        return {
            name: `${name}: ${stateString}${details}`,
            barColor: Colors.white,
            statuses: []
        }
    }
}

export class CameraDirectionCardHeadingModelBuilder {
    constructor (private camera: CameraInterface) { }

    build(): MainCardHeaderModelInterface {
        return {
            name: intl().formatMessage({ id: LOCALIZATION.camera_direction }),
            barColor: Colors.white,
            statuses: []
        }
    }
}

export class CameraCardHeadingModelBuilder {
    constructor (private camera: CameraInterface) { }

    build(): MainCardHeaderModelInterface {
        const camera = this.camera
        const name = camera.name
        const stateString = CameraTools.statusString(camera.status)
        const barColor = camera.status === 'on' ? Colors.mainGreen : Colors.mainRed

        return {
            name: [name, stateString].join(': '),
            barColor: barColor,
            statuses: []
        }
    }
}
