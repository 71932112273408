// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import InputAnyForm from '../../../_Layout/InputAnyForm/View'
import MetricsChartOutputTimeSelector from './MetricsChartOutputTimeSelector'
import { MetricOutputTimeInterface } from '../../../../Data/Camera/Metrics/Types'

export default function InputOutputTimeForm(props: {
    time: MetricOutputTimeInterface,
    name: string,
    onChanged: (mode: MetricOutputTimeInterface) => void}) {
    return (
        <InputAnyForm name={props.name} form={(
            <MetricsChartOutputTimeSelector
                time = { props.time }
                onChanged = { props.onChanged }/>
        )}/>
    )
}
