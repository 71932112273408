// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>


export const roundTo1 = (val: number | undefined | null) : number => {
    return Math.round((val || 0) * 10 ) / 10
}

export const roundTo2 = (val: number | undefined | null) : number => {
    return Math.round((val || 0) * 100 ) / 100
}