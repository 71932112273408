// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import ApiSessionHandler from '../Sessioning/Handlers'
import { ApiLocalErrorBuilder } from './Builders'
import { generateUserError } from './Tools'
import ApiError from './Types'
import { intl } from '../../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../../Localization';


interface ApiErrorProcessorProtocol {
    process(apiError: ApiError, retry: () => void, failure: (apiError: ApiError) => void): void
}

export default class ApiErrorProcessor implements ApiErrorProcessorProtocol {
    private apiSessionHandler = new ApiSessionHandler()

    process(apiError: ApiError, retry: () => void, failure: (apiError: ApiError) => void): void {
        const statusCode = apiError.statusCode
        switch (statusCode) {
            case 401:
                // console.log('Session > 401 status code detected. Need logout')
                this.apiSessionHandler.logout(generateUserError(apiError))
                failure(apiError)
                break
            case 403:
                // console.log('Session > 403 status code detected. Need refresh session')
                this.apiSessionHandler.refreshSession(retry, apiError => {
                    if (apiError.statusCode === 401 || apiError.statusCode === 403) {
                        // console.log('Session > ' + apiError.statusCode + ' status code detected after session refresh. Will fail commands')
                        this.apiSessionHandler.logout(generateUserError(apiError))
                    } else {
                        // console.log('Session > failed to refresh: ' + generateUserError(apiError))
                    }
                    failure(new ApiLocalErrorBuilder(intl().formatMessage({ id: LOCALIZATION.session_failed }), 'refresh_session').build())
                })
                break
            default:
                failure(apiError)
        }
    }
}
