// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { AxiosError } from 'axios'
import { BaseCommandScope } from '../Types'
import ApiError from './Types'

interface ApiErrorBuilderProtocol {
    build(): ApiError
}

export class ApiAxiosErrorBuilder implements ApiErrorBuilderProtocol {
    error: AxiosError
    statusCode?: number
    scope: BaseCommandScope

    constructor(error: AxiosError, scope: BaseCommandScope) {
        this.error = error
        this.statusCode = error.response?.status
        this.scope = scope
    }

    build(): ApiError {
        return {
            error: this.error,
            statusCode: this.error.response?.status,
            scope: this.scope
        }
    }
}

export class ApiLocalErrorBuilder implements ApiErrorBuilderProtocol {
    message: string
    scope: BaseCommandScope

    constructor(message: string, scope: BaseCommandScope) {
        this.message = message
        this.scope = scope
    }

    build(): ApiError {
        return {
            error: Error(this.message),
            scope: this.scope
        }
    }
}
