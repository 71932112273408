// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { getCameraName } from './helper';
import ButtonOptions from './ButtonOptions';
import CameraPreviewInfo from './CameraPreviewInfo';
import CameraHeader from './Card/Header';
import Camera  from '../../../Data/Camera/ActiveCamera/Camera';
import LOCALIZATION from '../../../Localization';
import { FileType } from '../../../Data/VideoArchive';
import MainCard from '../../../Components/_Layout/Cards/Main/Card';
import { Props as ButtonProps } from '../../../Components/_BaseUI/Button';
import isSuperAdmin from '../../../Data/AccountUsers/tools/isSuperAdmin';
import CardContent from '../../../Components/_Layout/Cards/Main/Content';
import { filterCriticalsStates, isCameraSettings } from '../helpers';
import CameraStreamPreview from '../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import CameraPopup from '../CameraPopup';
import { clearActiveCameraCache, clearCamerasCache } from '../../../Data/Camera/ActiveCamera/fetch';
import useMutationCamera, { ActionType } from '../../../Data/Camera/ActiveCamera/hook/useMutation';
import RemoteCameraState, { RemoteCameraStateValue } from '../../../Data/Camera/RemoteCameraState';
import VertGrid from '../../../Components/_Layout/VertGrid';
import useCurrentUser from '../../../Data/AccountUsers/hook/useCurrentUser';


type Props = Readonly<{
  isServer: boolean;
  hideActiveCameraBorder?: boolean;
  compact?: boolean;
  cameraHardware: RemoteCameraState;
  camera: Camera;
  activeCamera: Camera | undefined;
  onSetActiveCamera?: (camera: Camera) => void;
} & WrappedComponentProps>;


const useStyles = makeStyles(() => createStyles({
  notesWrapper: {
    display: 'flex',
    paddingBottom: '20px',
    fontSize: '14px',
    lineHeight: '20px',
  },
  notesRoWLimit: {
    textOverflow: 'ellipsis',
    overflow:'hidden',
    display: '-webkit-box !important',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal'
  },
}));

const CameraCardBlock: React.FC<Props> = (props: Props) => {
  const [isEditPopupOpened, setIsEditPopupOpened] = React.useState<boolean>(false);
  const classes = useStyles();
  const { mutateAsync: mutateCamera } = useMutationCamera();
  const theme = useTheme();
  const isPreviewInRow = useMediaQuery(theme.breakpoints.up('sm'));
  const { compact } = props;
  const lastSettingsResponse = (props.cameraHardware && isCameraSettings(props.cameraHardware.lastSettingsResponse)) ?
    props.cameraHardware.lastSettingsResponse
    :
    undefined;
  const criticalErrors =  filterCriticalsStates(lastSettingsResponse?.systemStates)

  const isOffline = (props.cameraHardware.state === RemoteCameraStateValue.Offline);
  const user = useCurrentUser();
  const isSuperAdminUser = isSuperAdmin(user);
  const isActiveCamera = (props.activeCamera?.cameraId === props.camera.cameraId)
  const isNotProxyMode = (lastSettingsResponse?.activeOptions?.isProxyMode !== "true")

  const handleCloseEditCamera = () => {
    setIsEditPopupOpened(false);
  };
  const handleEditCamera = () => {
    setIsEditPopupOpened(true);
  };
  const handlePostedSuccess = () => {
    clearCamerasCache();
    if (isActiveCamera) {
      clearActiveCameraCache();
    }
    setIsEditPopupOpened(false);
  };
  const handleRemoveCamera = async () => {
    if (props.isServer && isSuperAdminUser && window.confirm(props.intl.formatMessage({ id: LOCALIZATION.confirm_actions }))) {
      try {
        await mutateCamera({
          type: ActionType.Delete,
          camera: props.camera,
        });
        if (isActiveCamera) {
          clearActiveCameraCache();
        }
      } catch (error) {
        alert(error);
      }
    }
  };
  const handleSelectActiveCamera = () => {
    if (props.onSetActiveCamera) {
      props.onSetActiveCamera(props.camera);
    }
  };

  const options: ButtonProps[] = [{
    onClick: handleEditCamera,
    children: props.intl.formatMessage({ id: LOCALIZATION.edit }),
  }];
  if (props.isServer && props.activeCamera?.cameraId !== props.camera.cameraId) {
    options.unshift({
      onClick: handleSelectActiveCamera,
      children: props.intl.formatMessage({ id: LOCALIZATION.select_camera_as_active }),
    });
  }
  if (props.isServer && isSuperAdminUser) {
    options.push({
      onClick: handleRemoveCamera,
      children: props.intl.formatMessage({ id: LOCALIZATION.remove }),
    });
  }

  const operatorPreview = (
    <CameraStreamPreview
      title={ props.intl.formatMessage({ id: LOCALIZATION.operator_stream }) }
      fileType={ FileType.Operator }
      previewImageUrl={ lastSettingsResponse?.urls?.liveOperatorImage }
      videoUrl={ (isNotProxyMode) ? lastSettingsResponse?.urls?.liveOperatorVideoPlayer : undefined }
    />
  )

  const panoramaPreview = (
    <CameraStreamPreview
      title={ props.intl.formatMessage({ id: LOCALIZATION.panorama }) }
      fileType={ FileType.Panorama }
      previewImageUrl={ lastSettingsResponse?.urls?.livePanoramaImage }
      videoUrl={ (isNotProxyMode) ? lastSettingsResponse?.urls?.livePanoramaVideoPlayer :  undefined }
    />
  )

  return (
    <MainCard
      isActive={ (props.isServer && isActiveCamera && !props.hideActiveCameraBorder) || undefined }
    >
      <CameraHeader
        cameraHardwareState={ props.cameraHardware.state }
        name={ getCameraName(props.camera, isSuperAdminUser, props.isServer) }
      />
      <CardContent>
        {
          (props.camera.notes && props.camera.notes.length !== 0) &&
            <>
              <Grid
                item
                xs
                className={ classes.notesWrapper }
              >
                <div
                  className={ classes.notesRoWLimit }
                >
                  { props.camera.notes }
                </div>
              </Grid>
              <Divider style={ { margin: '0 0 20px 0' } } />
            </>
        }
        {(!isOffline) &&
          <VertGrid
            spacing={ 2 }
          >
            <CameraPreviewInfo
              compact={compact}
              // camera={ props.camera }
              lastSettingsResponse={ lastSettingsResponse }
              criticalErrors={ criticalErrors || undefined }
            />
            <Divider style={ { margin: '0 0 0 0' } } />
            {(isPreviewInRow) &&
              <Grid
                container
                spacing={ 2 }
                direction="row"
              >
                <Grid item xs={compact ? 12 : 6} >
                  { operatorPreview }
                </Grid>
                <Grid item xs={compact ? 12 : 6} >
                  { panoramaPreview }
                </Grid>
              </Grid>
            }
            {(!isPreviewInRow) ?
              operatorPreview
              :
              null
            }
            {(!isPreviewInRow) ?
              panoramaPreview
              :
              null
            }
          </VertGrid>
        }
        { (options) && <ButtonOptions items={ options } /> }
      </CardContent>
      {
        (isEditPopupOpened) &&
          <CameraPopup
            isServer={props.isServer}
            type="edit"
            camera={ props.camera }
            onPostedSuccess={ handlePostedSuccess }
            onClose={ handleCloseEditCamera }
          />
      }
    </MainCard>
  )
};


export default injectIntl(CameraCardBlock);
