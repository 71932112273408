// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useState, useEffect, Dispatch } from 'react'
import { JetsonTools } from '../../../../Tools/Tools'
import CameraContext from '../fetch/Context'
import { useJetsonsCriticals, useJetsonStorage } from '../../Jetson/Hooks'
import { CameraStorageReviewInterface, JetsonId, JetsonNames } from '../../Jetson/Types'
import { TemperatureControllerReviewInterface } from '../CameraSettingsTemperature'
import { CameraInterface, CameraInstance } from '../Camera'
import { HeaterId } from '../../Metrics/Types'
import { CameraSettingsInterface } from '../CameraSettings'
import { CameraDataChangeSubscriber } from '../Camera'

class CameraDataHelper {
    static subscribeToCameraChange(subscriber: CameraDataChangeSubscriber) {
        const dataProvider = CameraContext.current?.cameraDataProvider
        if (dataProvider) {
            dataProvider.subscribeOnChanges(subscriber)
        }
    }

    static unsubscribeFromCameraChange(subscriber: CameraDataChangeSubscriber) {
        const dataProvider = CameraContext.current?.cameraDataProvider
        // Получим опционал, так как при описывании объекта может уже не быть.
        if (dataProvider) {
            dataProvider.unsubscribeFromChanges(subscriber)
        }
    }
}

export function useCameraData(noUpdate?: boolean): CameraInterface | undefined {
    const [camera, setCamera] = useState(CameraContext.currentContext()?.cameraDataProvider.camera)

    useEffect(() => {
        function handleDataChange(camera: CameraInterface) {
            setCamera(CameraInstance.fromInterface(camera))
        }
        if ((!camera?.settings) || (!noUpdate)) {
            CameraDataHelper.subscribeToCameraChange(handleDataChange)
        }
        return () => {
            CameraDataHelper.unsubscribeFromCameraChange(handleDataChange)
        }
    })

    return camera
}

export function useCameraSettings(noUpdate?: boolean): CameraSettingsInterface | undefined {
    const [cameraSettings, setCameraSettings] = useState(CameraContext.current?.cameraDataProvider.camera.settings)

    useEffect(() => {
        function handleDataChange(camera: CameraInterface) {
            setCameraSettings(camera.settings)
        }

        if ((!cameraSettings) || (!noUpdate)) {
            CameraDataHelper.subscribeToCameraChange(handleDataChange)
        }
        return () => {
            CameraDataHelper.unsubscribeFromCameraChange(handleDataChange)
        }
    })

    return cameraSettings
}

export function useCameraSettingsState(noUpdate?: boolean): [CameraSettingsInterface | undefined, Dispatch<React.SetStateAction<CameraSettingsInterface | undefined>>] {
    const [cameraSettings, setCameraSettings] = useState(CameraContext.current?.cameraDataProvider.camera.settings)

    useEffect(() => {
        function handleDataChange(camera: CameraInterface) {
            setCameraSettings(camera.settings)
        }
        if ((!cameraSettings) || (!noUpdate)) {
            CameraDataHelper.subscribeToCameraChange(handleDataChange)
        }
        return () => {
            CameraDataHelper.unsubscribeFromCameraChange(handleDataChange)
        }
    })

    return [cameraSettings, setCameraSettings]
}

export function useActiveHeatersIds(): HeaterId[] | undefined {
    const [activeHeatersIds, setActiveHeatersIds] = useState(CameraContext.current?.cameraDataProvider.activeHeaterIdArr)

    useEffect(() => {
        function handleDataChange(activeHeatersIds: HeaterId[] | undefined) {
            setActiveHeatersIds(activeHeatersIds)
        }

        CameraContext.subscribeToActiveHeatersChange(handleDataChange)
        return () => {
            CameraContext.unsubscribeFromActiveHeatersChange(handleDataChange)
        }
    })

    return activeHeatersIds
}

export function useCameraCriticals(): string[] {
    const criticals: string[] = useJetsonsCriticals()
    const camera = useCameraData()
    if (camera) {
        JetsonTools.criticalStates(camera).forEach(critical => { criticals.push(critical) })
    }
    return criticals
}

export function useStorageReview(): CameraStorageReviewInterface | undefined {
    const jetson1StorageReview = useJetsonStorage(JetsonNames.Jetson1)
    const jetson2StorageReview = useJetsonStorage(JetsonNames.Jetson2)

    return {
        jetson1: jetson1StorageReview,
        jetson2: jetson2StorageReview
    }
}

export function useCaseHeaterReview(id: HeaterId, temperatureTitle: string): TemperatureControllerReviewInterface | undefined {
    const camera = useCameraData()
    if (!camera) { return undefined }
    const temperatureControllerDataHeater = camera.getHeaterTemperatureControllerData(id)
    const isCameraOn = camera.status === 'on'
    const loadPercents = temperatureControllerDataHeater?.powerLoadPercent

    return {
        isOn: temperatureControllerDataHeater?.state === 'on',
        power: loadPercents,
        temperatureTitle,
        temperature: temperatureControllerDataHeater?.lastData[id],
        isDisabled: !isCameraOn
    }
}

export function useJetsonFanReview(id: JetsonId): TemperatureControllerReviewInterface | undefined {
    const camera = useCameraData()
    if (!camera) { return undefined }

    const temperatureControllerData = JetsonTools.getTemperatureControllerData(camera, id)
    const isCameraOn = camera.status === 'on'

    const loadPercents = temperatureControllerData?.powerLoadPercent

    return {
        isOn: temperatureControllerData?.state === 'on',
        power: loadPercents ?? 0,
        isDisabled: !isCameraOn
    }
}
