// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Card from '../../Components/_Layout/Card';
import PageContainer from '../../Components/_Layout/PageContainer';
import VideoArchiveContent, { sessionStoreKey } from './VideoArchiveContent';
import { FileType } from '../../Data/VideoArchive';
import Tabs, { TabItemInterface } from '../../Components/_Layout/Tabs';
import LOCALIZATION from '../../Localization';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get'
import { useCameraData } from '../../Data/Camera/HWCameraSettings/hook/Hooks';
import { FieldSetDateComponent_StoredValue } from '../../Components/_BaseUI/FieldSet/FieldSetDateComponent';

type Props = Readonly<{} & WrappedComponentProps>;

const VideoArchivePage: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const filterDate = get(location, 'state.filterDate');// get filter date from events/history

  const [date, setDate] = React.useState<string | null>(filterDate || FieldSetDateComponent_StoredValue(sessionStoreKey));

  var TABS: TabItemInterface[] = [
    {
      id: 0,
      title: props.intl.formatMessage({ id: LOCALIZATION.page_video_archive_tab_operator }),
      Content: VideoArchiveContent,
    },
    {
      id: 1,
      title: props.intl.formatMessage({ id: LOCALIZATION.page_video_archive_tab_panorama }),
      Content: VideoArchiveContent,
    },
  ];

  const cameraSettings = useCameraData(true);

  if (cameraSettings?.settings?.urls?.scoreboardSrcImage) {
    TABS.push({
      id: 2,
      title: props.intl.formatMessage({ id: LOCALIZATION.page_video_archive_tab_scoreboard }),
      Content: VideoArchiveContent,
    });
  }

  const [selectedTabID, setSelectedTabID] = React.useState<number>(TABS[0].id);

  const handleChangeDate = (date: string) => {
    setDate(date);
  };

  const renderContent = () => (TABS.map((tab: TabItemInterface) => {
    const hidden = (selectedTabID !== tab.id);

    if (hidden) {
      return null;
    }

    const Content = TABS[tab.id].Content;

    return (
      <Typography
        key={ tab.id }
        component="div"
        role="tabpanel"
        id={ `simple-tab-${ tab.id }` }
        aria-labelledby={ `simple-tabpanel-${ tab.id }` }
      >
        <Content
          type={ (tab.id === 2) ? FileType.Scoreboard : (tab.id === 0) ? FileType.Operator : FileType.Panorama }
          date={ date }
          onChangeDate={ handleChangeDate }
        />
      </Typography>
    );
  }));

  return (
    <PageContainer>
      <div
        style={ { margin: '0 20px' } }
      >
        <Tabs
          items={ TABS }
          selectedTabID={ selectedTabID }
          onChangeTab={ setSelectedTabID }
        />
      </div>
      <Grid
        item
        xs={ 12 }
      >
        <Card>
          { renderContent() }
        </Card>
      </Grid>
    </PageContainer>
  );
};


export default injectIntl(VideoArchivePage);
