// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { uniq } from 'lodash';
import { Grid, List } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import TeamRefItem from './TeamRefItem';
import H2 from '../../../../Components/_Layout/H2';
import TeamRef from '../../../../Data/Analytics/AnalyticsTeamRef';
import { EMPTY_ARRAY } from '../../../../constants';
import TeamRefsPreSet, { SelectPreSet } from './TeamRefsPreSet';
import { UserTeamRefAccess, UserRole } from '../../../../Data/AccountUsers/UserWithoutID';
import {
  calculateTeamRefAccessByPreSet,
  calculateTeamRefPreSetBySelectedIDs, calculateInitialValueTeamRefsPreSet, calculateInitialValueCheckedIDs,
  calculateSelectedTeamRefsByPreSet
} from './helpers';
import LOCALIZATION from '../../../../Localization';


type Props = Readonly<{
  isEditMode: boolean;
  userTeamRefAccess: UserTeamRefAccess;
  userRole: UserRole;
  editorTeamRefIdList?: string[];
  userTeamRefIdList: string[];
  selfTeamRefAccess: UserTeamRefAccess;
  teamRefs: TeamRef[];
  onChange: (teamRefAccess: UserTeamRefAccess, selectedTeamRefsIDs: string[]) => void;
} & WrappedComponentProps>;

const TeamRefsList: React.FC<Props> = (props: Props) => {
  const selfWithRootAccess = props.selfTeamRefAccess === UserTeamRefAccess.All;
  const { userTeamRefIdList = EMPTY_ARRAY } = props;
  const [teamRefAccess, setTeamRefAccess] = React.useState<UserTeamRefAccess>(UserTeamRefAccess.All);
  const [checkedIDs, setCheckedIDs] = React.useState<string[]>(calculateInitialValueCheckedIDs(userTeamRefIdList));
  const [teamRefsPreSet, setTeamRefsPreSet] = React.useState<SelectPreSet>(calculateInitialValueTeamRefsPreSet(props.selfTeamRefAccess, props.teamRefs.length, calculateInitialValueCheckedIDs(userTeamRefIdList).length, props.userTeamRefAccess, props.isEditMode));
  const haveTeamRefList = (teamRefsPreSet !== SelectPreSet.Guest && !!props.teamRefs.length);

  const handleToggleTeamRef = (id: string) => {
    const index = checkedIDs.indexOf(id);
    const checkedIDsNewValue = (index === -1) ? [ ...checkedIDs, id ] : checkedIDs.filter((i) => (i !== id));

    setCheckedIDs(checkedIDsNewValue);

    setTeamRefsPreSet(calculateTeamRefPreSetBySelectedIDs(props.teamRefs.length, checkedIDsNewValue.length));
  };
  const handleChangeTeamRefsPreSet = (value: SelectPreSet) => {
    setTeamRefsPreSet(value);
  };

  const renderItem = (teamRef: TeamRef) => (
    <TeamRefItem
      key={ teamRef.id }
      isChecked={ checkedIDs.includes(teamRef.id) }
      teamRef={ teamRef }
      onClick={ handleToggleTeamRef }
    />
  );

  React.useEffect(() => {
    setCheckedIDs(uniq([ ...checkedIDs, ...userTeamRefIdList ]));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTeamRefIdList]);

  React.useEffect(() => {
    props.onChange(teamRefAccess, checkedIDs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamRefAccess, checkedIDs]);

  React.useEffect(() => {
    const calculatedSelectedTeamRefsByPreSet = calculateSelectedTeamRefsByPreSet(props.teamRefs, (props.editorTeamRefIdList || EMPTY_ARRAY), teamRefsPreSet, selfWithRootAccess);

    if (calculatedSelectedTeamRefsByPreSet !== undefined) {
      setCheckedIDs(calculatedSelectedTeamRefsByPreSet);
    }

    setTeamRefAccess(calculateTeamRefAccessByPreSet(teamRefsPreSet));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamRefsPreSet, props.teamRefs.length]);

  // React.useEffect(() => {
  //   setTeamRefsPreSet(calculateTeamRefPreSetBySelectedIDs(props.teamRefs.length, checkedIDs.length, selfWithRootAccess, props.userRole));
  // }, [props.userRole]);

  return (
    <Grid>
      <Grid
        container
        alignItems={ (haveTeamRefList) ? "" : "center" }
      >
        <Grid
          item
          xs={ 7 }
        >
          <H2>{ props.intl.formatMessage({ id: LOCALIZATION.user_visible_teamrefs }) }</H2>
        </Grid>
        <Grid
          item
          xs={ 5 }
        >
          <TeamRefsPreSet
            withRootAccess={ selfWithRootAccess }
            value={ teamRefsPreSet }
            onChange={ handleChangeTeamRefsPreSet }
          />
        </Grid>
      </Grid>
      {
        (haveTeamRefList) &&
        <Grid
          sx={ {
            maxHeight: '20vh',
            overflowY: 'scroll',
            backgroundColor: '#F5F6F8',
            borderRadius: '8px',
          } }
        >
          <List>
            { 
            props.teamRefs
            .filter((teamRef) => ( (selfWithRootAccess) || ((props.editorTeamRefIdList) && (props.editorTeamRefIdList?.indexOf(teamRef.id) >= 0))))
            .map(renderItem) 
            }
          </List>
        </Grid>
      }
    </Grid>
  );
};


export default injectIntl(TeamRefsList);
