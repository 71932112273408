// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import TeamRef from '..';
import Order from '../../../_Networking/ReactQuery/Order';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import {
  useTeamRefsFetch,
  clearTeamRefsCache,
  getCached,
} from '../fetch';


type Props = Readonly<{
  page: number;
  limit: number;
  order: Order;
  orderBy: keyof TeamRef;
}>;

type Result = Readonly<{
  status: string;
  data: ResponseMany<TeamRef[]> | undefined;
  handleFlushData: () => void;
}>;


const useTeamRefsPagedData = (props: Props): Result => {
  const fetchedTeams = useTeamRefsFetch({
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    enableToFetch: true,
  });
  const data = getCached({
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
  });

  return ({
    status: fetchedTeams.status,
    data,
    handleFlushData: clearTeamRefsCache,
  });
};


export default useTeamRefsPagedData;
