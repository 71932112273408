// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

const ru = {
  app_title: "CyberCamera",
  add_cam_login_pass_help: "Используйте логин и пароль локальново пользователя на камере с правами SuperAdmin для авторизации добавления камеры.",
  add_cam_name_help: "Любое имя для камеры, которое вам нравится, чтобы идентифицировать ее для всех пользователей. Пример: «МСК Баскетбол 800 лет», «СПБ Хоккей Шанс Арена».",
  add_cam_ip_address: "В целях безопасности лучше использовать VPN IP (DNS) адрес Pi, но также можно использовать внешний IP (DNS) самой камеры. По умолчанию используется 80 порт.  Пример: pi.ccam3.mscl.local, 192.168.10.105, 192.168.10.94:9900",
  add_cam_login: "Логин для существующей на камере учетной записи SuperAdmin",
  add_cam_success_alert: "Добавленная камера появится в списке примерно через 15 секунд.",
  add_teamref_success_alert: "Добавленная команда появится в списке примерно через 15 секунд.",
  alert_only_png_images: "Вы должны использовать изображение в формате PNG.\nМаксимальный размер изображения 500x500 пикселей.",
  tooltip_team_short_name: "Короткое название команды, используемое на табло в видеопотоке.",
  tooltip_team_color_use: "Цвет, используемый для обозначения команды на табло в видеопотоке.",
  page_status_title: "Статус",
  page_status_heading: "Состояние камеры",
  page_hardware_title: "Оборудование",
  page_hardware_heading: "Оборудование камеры",
  page_camera_api_title: "Отладка",
  page_camera_configuration_title: "Камера",
  page_camera_api_heading: "Camera API",
  page_events_title: "Расписание событий",
  page_events_heading: "События",
  page_panorama_title: "Панорама",
  page_panorama_heading: "Панорама",
  page_sync_settings_title: "Хранилище",
  page_sync_settings_heading: "Хранилище",
  page_users_administration_title: "Администрирование",
  page_analytics_teams_title: "Команды в Аналитике",
  page_analytics_video_archive_title: "Видео для Анализа",
  page_analytics_games_archive_title: "Отчеты аналитики",
  analytics_possession: "Владение",
  analytics_zone_possession: "Владение зоной",
  analytics_zone_possession_draw: "Ничья во владении зоной",
  analytics_tab_overall: "Общий",
  analytics_tab_events: "События",
  analytics_tab_heatmap: "Тепловая карта",
  analytics_tab_attacking: "В атаке",
  analytics_tab_attacking_defence: "Атака ➞ Защита",
  analytics_tab_defending: "В защите",
  analytics_tab_defese_attack: "Защита ➞ Атака",
  analytics_tab_overall_name: "Покрытая дистанция и интенсивность бега команды",
  analytics_tab_attacking_name: "Покрытая дистанция и интенсивность бега, когда команда атакует",
  analytics_tab_attacking_defence_name: "Покрытая дистанция и интенсивность бега, когда команда переходит от атаки к защите",
  analytics_tab_defending_name: "Покрытая дистанция и интенсивность бега, когда команда в защите",
  analytics_tab_defese_attack_name: "Покрытая дистанция и интенсивность бега, когда команда переходит от защиты к атаке",
  analytics_score: "Счёт",
  analytics_no_players: "Нет игроков",
  analytics_seconds_short: "сек",
  analytics_distance_covered: "Покрытая дистанция",
  analytics_avg_speed: "Сред. Скорость",
  analytics_avg_time_in_possession: "Сред. время во владении",
  analytics_attack_time: "Время в атаке",
  analytics_defense_time: "Время в защите",
  analytics_walk: "Ходили",
  analytics_run: "Бегали",
  analytics_sprint: "Спринтовали",
  analytics_attacking: "Атака",
  analytics_defending: "Защита",
  analytics_intensity: "Интенсивность (Бегали)",
  analytics_intensity_short: "Интенсивность",
  analytics_type_time1: "Первый тайм",
  analytics_type_time2: "Второй тайм",
  analytics_type_time3: "Третий тайм",
  analytics_type_time4: "Четвертый тайм",
  analytics_type_overtime1: "Первый овертайм",
  analytics_type_overtime2: "Второй овертайм",
  analytics_type_overtime3: "Третий овертайм",
  analytics_type_penalty1: "Серия пенальти",
  analytics_type_penalty2: "Второй серия пенальти",
  analytics_ganularity: "Уровень детализации",
  analytics_ganularity_0: "Тайм",
  analytics_ganularity_2: "1/2 тайма",
  analytics_ganularity_4: "1/4 тайма",
  analytics_ganularity_8: "1/8 тайма",
  analytics_visualize_mode: "Вид",
  analytics_visualize_mode_bar: "Гистограмма",
  analytics_visualize_mode_line: "Линейный график",
  analytics_compare: "Сравнить с",
  analytics_unit_percent: "Процент",
  analytics_unit_percent_short: "%",
  analytics_unit_seconds: "Секунды",
  analytics_unit_seconds_short: "Сек.",
  analytics_unit_minutes: "Минуты",
  analytics_unit_minutes_short: "Мин.",
  analytics_unit_hours: "Часы",
  analytics_unit_hours_short: "ч",
  analytics_unit_km: "Километр",
  analytics_unit_km_short: "КМ",
  analytics_unit_meters: "Метров",
  analytics_unit_meters_short: "м",
  analytics_unit_km_per_hour: "Километр в час",
  analytics_unit_km_per_hour_short: "КМ/Ч",
  analytics_unit_meter_per_second: "Метров в секунду",
  analytics_unit_meter_per_second_short: "М/Сек",
  analytics_unit_meter_per_minute: "Метов в минуту",
  analytics_unit_meter_per_minute_short: "М/Мин",
  analytics_unit_percent_format: "{value} %",
  analytics_unit_seconds_format: "{value} cек",
  analytics_unit_minutes_format: "{value} мин",
  analytics_unit_hours_format: "{value} ч",
  analytics_unit_km_format: "{value} км",
  analytics_unit_meters_format: "{value} м",
  analytics_unit_km_per_hour_format: "{value} км/ч",
  analytics_unit_meter_per_second_format: "{value} м/сек",
  analytics_unit_meter_per_minute_format: "{value} м/мин",
  analytics_stat_team_distance: "Дистанция всей команды",
  analytics_stat_avg_players_speed: "Средняя скорость всех игроков",
  analytics_stat_distance_walked_by_players: "Дистанция, когда игроки ходили",
  analytics_stat_avg_walking_speed: "Средняя скорость ходьбы",
  analytics_stat_distance_run_by_players: "Дистанция, которую игроки пробежали",
  analytics_stat_avg_running_speed: "Средняя скорость бега",
  analytics_stat_distance_sprinted_by_players: "Дистанция, когда игроки спиринтовали",
  analytics_stat_avg_sprinting_speed: "Средняя скорость спринта",
  analytics_stat_avg_intensity: "Средняя интенсивность (Бегали)",
  analytics_help_walking: "Ходьба (0-7 км/ч)",
  analytics_help_running: "Бег (7-20 км/ч)",
  analytics_help_sprinting: "Спринт (20+ км/ч)",
  analytics_left_side: "Левая половина",
  analytics_right_side: "Правая половина",
  analytics_game_teams: "Команды",
  analytics_game_score: "Счёт",
  analytics_game_start: "Начало",
  analytics_game_end: "Конец",
  analytics_game_time_periods: "Периоды времени",
  analytics_game_state: "Состояние",
  analytics_game_state_in_progress: "Анализ для аналитики в процессе {percent}",
  analytics_game_state_failed: "Ошибка аналитики",
  analytics_game_state_not_started: "Начать анализ",
  analytics_game_start_mapping_team_id: "Начать Сопоставление Команд",
  analytics_team_mapping: "Сопоставление Команд",
  analytics_game_state_done: "Посмотреть отчет",
  analytics_game_in_readonly_state: "Состояние анализа: {state}",
  analytics_game_in_readonly: "Эту игру нельзя редактировать. Если вам нужно её отредактировать, сделайте копию.",
  analytics_select_player_match_team: "Выберите игроков, которые больше соответствуют команде",
  analytics_start_analysis_help: "Игра готова к анализу. Процесс анализа займет от нескольких минут до часов, в зависимости от продолжительности игры. Как только вы начнете анализ, вы не сможете редактировать настройки этой игры. Чтобы отредактировать игру, вы должны сначала прервать начатый анализ.",
  games_for_analytics: "Игры для аналитики",
  game_for_analytics: "Игра для аналитики",
  game_edit_scissors_help: "Запустите видео и используйте ползунок времени и кнопки \"Влево\", \"Вправо\", \"Пробел\" на клавиатуре, чтобы найти время события.\nНажмите кнопку ✄ рядом с событием, чтобы сохранить время.",
  analytics_events_number: "№",
  analytics_events_event: "Событие",
  analytics_events_time: "Время",
  analytics_events_details: "Подробности",
  analytics_team_side_left: "Левая сторона",
  analytics_team_side_right: "Правая сторона",
  analytics_game_edit_time_invalid: "Введено неверное время",
  game_event_game: "Игра",
  game_event_training: "Тренировка",
  game_event_free_time: "Free Time",
  game_event_game_start: "Начало игры",
  game_event_game_end: "Конец игры",
  game_event_time: "Период",
  game_event_time_start: "Период №{number}",
  game_event_time_short: "П{number}",
  game_event_time_end: "Период №{number} - конец",
  game_event_timeout: "Таймаут",
  game_event_timeout_start: "Начало таймаута",
  game_event_timeout_end: "Конец таймаута",
  game_event_goal: "Гол",
  game_event_overtime: "Овертайм",
  game_event_overtime_start: "Овертайм №{number}",
  game_event_overtime_short: "О{number}",
  game_event_overtime_end: "Овертайм №{number} - конец",
  game_event_penalty_series: "Серия пенальти",
  game_event_penalty_start: "Серия пенальти №{number}",
  game_event_penalty_short: "Пн{number}",
  game_event_penalty_end: "Серия пенальти №{number} - конец",
  game_extra_event_penalty: "Пенальти",
  game_event_end_postfix: " - конец",
  add_game: "Добавить новую игру",
  hardware: "Оборудование",
  main_dc_ac: "Сеть",
  main_dc_battery: "Батарея",
  state_on: "ВКЛ.",
  state_off: "ВЫКЛ.",
  minutes: "Мин.",
  minute_number: "{number} минута",
  date: "Дата",
  date_from: "С",
  date_to: "До",
  game_date: "Дата игры",
  hardware_buttons: "Аппаратные кнопки",
  recording_to_storage: "Запись в хранилище",
  operator_stream: "Видеопоток оператора",
  configure: "Конфигурация",
  analytics: "Аналитика",
  configure_chart: "Настроить график",
  stop_recording: "Остановить запись и трансляцию",
  start_recording: "Начать запись",
  stop_storage_sync: "Остановить загрузку для аналитики",
  email_address: "Электронная почта",
  password: "Пароль",
  login: "Вход",
  register: "Регистрация",
  copyright: "Авторские права",
  cc_uri: "https://ccamera.io",
  error_wrong_signin_credentials: "Пользователь не найден, или введен неверный пароль.",
  menu_logout: "Выйти",
  not_implemented: "Будет реализовано в следующих этапах",
  camera_count: "Всего камер",
  teamref_count: "Всего команд",
  online: "Онлайн",
  offline: "Офлайн",
  critical: "Критично",
  on: "ВКЛ",
  off: "ВЫКЛ",
  shutting_down: "Выключается",
  auto: "Автоматически",
  streaming: "Вещание",
  active_epg_event: "Активное событие",
  no_active_epg_event: "Нет активного события",
  stream: "Поток",
  custom: "Произвольный",
  cpu_name_j1: "CPU J#1",
  cpu: "CPU",
  gpu: "GPU",
  outdoor_temp: "Внешняя темп",
  internal_temp: "Внутр. темп",
  fan: "Охлаждение",
  shut_down_camera: "Выключить Киберкамеру",
  maintenance_mode: "Режим технического обслуживания",
  storage_left: "Свободного места",
  storage_sync: "Загрузка видео на сервер аналитики",
  panorama: "Панорама",
  game_video: "Видео игры",
  team_physical_report: "Отчет о физических характеристиках команды",
  team_tactical_report: "Отчет по тактике команды",
  details: "Подробности",
  video_analytics: "Видеоаналитика",
  scoreboard: "Табло",
  storage: "Хранилище",
  statistics: "Статистика",
  get: "Получить",
  post: "Отправить",
  reload: "Перезагрузить",
  error_app_failed: "Невозможно запустить приложение.",
  ssh_console: "SSH консоль",
  shut_down: "Выключить",
  reboot: "Перезагрузить",
  turn_on: "Включить",
  start_firmware_update: "Начать обновление прошивки",
  error_general: "Произошла ошибка.",
  error_wrong_parameters: "Wrong request parameters.",
  power: "Питание",
  power_hw_btn: "Аппаратное питание (Принудительно > 10 сек)",
  reset_hw_btn: "Аппаратный сброс (Reset)",
  factory_reset_hw_btn: "Сброс до заводских настроек",
  start_when_cpu_more: "Включить, если t˚ CPU >, ˚C",
  stop_when_cpu_less: "Выключить, если t˚ CPU <, ˚C",
  critical_cpu_temp: "Критическая t˚ CPU, ˚C",
  state: "Состояние",
  mode: "Режим",
  case_heater: "Подогрев корпуса",
  source: "Источник",
  source_operator: "Оператор",
  source_panorama: "Панорама",
  stream_fps: "Кадров в секунду, fps",
  start_when_source_less: "Включить, если t˚ <, ˚C",
  stop_when_source_more: "Выключить, если t˚ >, ˚C",
  critical_source_temp: "Критическая t˚, ˚C",
  tilt_angle: "Угол наклона",
  camera_direction: "Положение камеры",
  empty_metrics_data: "Нет данных для отображения. Попробуйте использовать другие настройки.",
  action_reload: "Обновить",
  chart_voltage_name: "Напряжение питания",
  chart_cpu_load_name: "Загрузка CPU",
  chart_gpu_load_name: "Загрузка GPU",
  chart_outdoor_temperature_name: "Снаружи",
  chart_internal_temperature_name: "Внутри",
  chart_dew_point_temperature_name: "Точка росы",
  chart_internal_humidity_name: "Влажность внутри",
  chart_dc_temperature_name: "DC-адаптер",
  chart_heater_temperature_name: "Нагреватель",
  connection_error: "Ошибка соединения",
  session_missing_error: "Авторизационные данные не найдены",
  session_failed: "Ошибка авторизации. Необходимо осуществить вход в учетную запись.",
  logout_in_progress: "Ошибка выхода. Процесс выхода уже запущен, пожалуйста, дождитесь окончания",
  default_pi_name: "PI",
  board: "Плата",
  camera_main_view: "Camera Main View",
  recording: "Запись",
  error_latest_metrics_empty: "Нет необходимых измерений",
  chart_cpu_temp_name: "CPU",
  chart_board_temp_name: "Плата",
  chart_gpu_temp_name: "GPU",
  chart_case_heater_temp_name: "Нагреватель корпуса",
  chart_case_heater1_temp_name: "Нагреватель корпуса №1",
  chart_case_heater2_temp_name: "Нагреватель корпуса №2",
  chart_case_heater3_temp_name: "Нагреватель корпуса №3",
  chart_case_heater4_temp_name: "Нагреватель корпуса №4",
  chart_case_heater_cam1_temp_name: "Нагреватель объектива №1",
  chart_case_heater_cam2_temp_name: "Нагреватель объектива №2",
  chart_case_heater_cam3_temp_name: "Нагреватель объектива №3",
  chart_cpu_power_name: "Потребление CPU",
  chart_cpu_voltage_name: "Напряжение CPU",
  chart_soc_power_name: "Потребление SOC",
  chart_gpu_power_name: "Потребление GPU",
  chart_sys5v_power_name: "Потребление Sys5V",
  chart_sys5v_voltage_name: "Напряжение Sys5V",
  chart_vddrq_power_name: "Потребление VDDRQ",
  chart_cv_power_name: "Потребление CV",
  cv: "CV",
  vddrq: "VDDRQ",
  sys5v: "Sys5V",
  soc: "SOC",
  j1name: "J#1",
  j2name: "J#2",
  ok: "OK",
  problems: "Проблемы",
  internal: "Внутри",
  dew_point_short: "Росы",
  outdoor: "Снаружи",
  sensor_place_name_outdoor: "Снаружи",
  sensor_place_name_cameraCase: "Корпус камеры",
  sensor_place_name_DC: "Блок питания",
  sensor_place_name_heater: "Нагреватель корпуса",
  sensor_place_name_heater1: "Нагреватель корпуса №1",
  sensor_place_name_heater2: "Нагреватель корпуса №2",
  sensor_place_name_heater3: "Нагреватель корпуса №3",
  sensor_place_name_heater4: "Нагреватель корпуса №4",
  sensor_place_name_heater_cam1: "Нагреватель объектива №1",
  sensor_place_name_heater_cam2: "Нагреватель объектива №2",
  sensor_place_name_heater_cam3: "Нагреватель объектива №3",
  itself: "Своя",
  sensor_place_name_jetson1: "Jetson 1",
  sensor_place_name_jetson2: "Jetson 2",
  sensor_place_name_pi: "PI",
  resource_is_unreachable: "Запрашиваемый ресурс недоступен",
  wrong_resource_name: "Неизвестное имя ресурса",
  confirm_shut_down: "Выключить камеру и всё ее оборудование?",
  confirm_shut_down_jetson: "Выключить Jetson?",
  confirm_reboot_jetson: "Перезагрузить Jetson?",
  confirm_turn_on_jetson: "Включить Jetson?",
  confirm_shut_down_pi: "Выключить PI?",
  confirm_reboot_pi: "Перезагрузить PI?",
  confirm_reset_jetson: "Сбросить Jetson?",
  confirm_power_jetson: "Выключить (включить) Jetson?",
  confirm_start_firmware_update_jetson: "Начать обновление прошивки Jetson?",
  confirm_recovery_jetson: "Восстановить Jetson?",
  post_camera_api_confirm: "Отправить измененный конфиг на Jetson? Операция может быть небезопасной.",
  network_request_not_found: "Запрос не найден",
  undefined_camera_api_response_status: "Неизвестный статус ответа",
  empty_camera_api_response: "Пустое тело ответа",
  apply: "Применить",
  cancel: "Отменить",
  save: "Сохранить",
  abr_milliseconds: "мс.",
  duration: "Длительность",
  confirm_actions: "Подтвердить и применить действия?",
  confirm_remove: "Подтвердить удаление?",
  confirm_sync_selected_video: "Загрузить выбранное видео на сервер аналитики?",
  success_alert_sync_selected_video: "Выбранное видео помечены для загрузки на сервер аналитики. Ход отправки отображается на странице Хранилище.",
  used_volume: "Занято места",
  ssd: "SSD",
  updating: "Обновление...",
  no_connection_to_device_off: "Устройство выключено. Некоторые данные могут быть недоступны.",
  no_connection_to_device: "Устройство выключено, или недоступно. Некоторые данные могут быть недоступны.",
  azymuth: "Азимут",
  reboot_disclaimer: "Запрос на перезагрузку успешно отправлен. Пожалуйста, ожидайте.",
  shut_down_disclaimer: "Запрос на выключение успешно отправлен. Пожалуйста, ожидайте.",
  turn_on_disclaimer: "Запрос на включение успешно отправлен. Пожалуйста, ожидайте.",
  power_source_battery: "Аккумулятор",
  power_source_adapter: "Адаптер питания",
  firmware_update_disclaimer: "Последовательность успешно отправлена. Устройство скоро перейдет в режим обновления прошивки.",
  version: "Версия",
  chart_power_consumption: "Потребляемая мощность",
  cameras: "Камеры",
  jetson_status_firmware_update: "Режим обновления прошивки",
  camera_off: "Камера выключена",
  turn_odd_to_start_firmware_update: "Для начала обновления прошивки необходимо выключить камеру",
  camera_not_available: "Камера не доступна",
  voltage: "Напряжение",
  supply: "Питание",
  volume: "Место",
  total: "Всего",
  total_power: "Общее потребление",
  dc: "DC",
  heater: "Нагреватель корпуса",
  heater1: "Нагреватель корпуса №1",
  heater2: "Нагреватель корпуса №2",
  heater3: "Нагреватель корпуса №3",
  heater4: "Нагреватель корпуса №4",
  heater_cam1: "Нагреватель объектива №1",
  heater_cam2: "Нагреватель объектива №2",
  heater_cam3: "Нагреватель объектива №3",
  tilt: "Наклон",
  empty_metrics_warning: "Для отображения секции с графиками выберите метрики в настройках.",
  loading_metrics: "Данные загружаются...",
  hour: "Час",
  twoHours: "2 часа",
  fourHours: "4 часа",
  eightHours: "8 часов",
  halfDay: "Полдня",
  day: "День",
  output_period: "Период вывода",
  output_time: "Время вывода",
  now: "Сейчас",
  today: "Сегодня",
  tomorrow: "Завтра",
  ended: "Закончилось",
  every_day: "Ежедневно",
  yesterday: "Вчера",
  unit_w: "Вт",
  unit_v: "В",
  events: "События",
  events_with_numbers: "События, №",
  event: "Событие",
  archive: "Архив",
  history: "Расписание",
  teams: "Команды",
  team: "Команда",
  add_event: "Добавить",
  copy: "Копировать",
  copy_item: "Копия",
  remove: "Удалить",
  recover_remove_btn: "Восстановить",
  import_epg_btn: "Импорт расписания",
  disable_btn: "Выключить",
  enable_btn: "Включить",
  close_btn: "Закрыть",
  remove_selected_items: "Удалить выбранные элементы ?",
  recover_selected_items: "Восстановить выбранные элементы?",
  disable_selected_items: "Выключить выбранные элементы?",
  enable_selected_items: "Включить выбранные элементы?",
  show_deleted_events: "Показать удаленные события, чтобы восстановить их?",
  send_to_sync_server: "Загрузить на сервер аналитики",
  refresh: "Обновить",
  event_name: "Название",
  event_name_help: "Название события, например: «Футбол», «Баскетбол СКА-НБА», «Урок физкультуры»",
  event_sub_title: "Подзаголовок",
  event_sub_title_help: "Необязательный подзаголовок, например номер класса для урока: «Мальчики 2012»",
  event_description: "Описание | Используйте [Маркер] для анонсов ВКВидео",
  event_description_help: "Необязательное описание события, например: «Финальная игра года в Кубке ФИФА». Чтобы связать это событие с видеоанонсом на ВКВидео, используйте в описании произвольный маркер в квадратных скобках. Пример [Моя игра 102782]. Время анонса в ВКВидео иметь допуск до 1 часа для соответствия данному событию.",
  event_key_override_header: "Укажите новые ключи вещания для потоков, которые вы хотите изменить для этого события.",
  event_key_override_item: "{name}{number} ключ",
  event_profile_nothing_override_error: "В этом профиле нет потоков, которые можно было бы переопределить.",
  event_warning: "Состояние",
  event_priority: "Приоритет события",
  event_priority_help: "События с более высоким приоритетом могут перекрывать события с более низким приоритетом по времени.",
  event_priority_low: "Низкий",
  event_priority_imported: "Импортированный",
  event_priority_base: "Базовый",
  event_priority_high: "Высокий",
  event_priority_critical: "Критический",
  event_game_type: "Тип события",
  event_game_type_help: "Табло, если оно существует, отображается только для типа «Игра».",
  event_game_type_game: "Игра",
  event_game_type_train: "Тренировка (без табло)",
  event_game_type_free_time: "Свободное время (без табло)",
  event_default_sport_type: "Вид спорта по умолчанию",
  event_default_sport_type_hint: "Вид спорта по умолчанию, используемый при создании новых событий в расписании и отчетов Аналитики.",
  event_sport_type: "Вид спорта",
  event_sport_type_football: "Футбол",
  event_sport_type_hockey: "Хоккей",
  event_sport_type_basketball: "Баскетбол",
  event_sport_type_hockey_with_ball: "Хоккей с мячом",
  event_sport_type_multiple_sports: "Несколько видов спорта",
  event_sport_type_handball: "Гандбол",
  event_sport_type_mini_football: "Мини-футбол",
  event_sport_type_unknown: "Неизвестен",
  event_start_date: "Дата начала",
  event_time_period: "Период времени события",
  event_end_date: "Дата завершения",
  event_no_event_date: "Без даты",
  event_home_team: "Домашняя команда",
  event_guest_team: "Гостевая команда",
  event_teams_help: "Выберите команду, чтобы показать ее название на табло. Вы можете добавить команду во вкладке «Команды».",
  event_stream_type: "Профиль для вещания события",
  event_stream_type_help: "Если профиль не выбран, камера не будет записывать или транслировать это событие. Вы можете добавить профиль на странице «Стриминг».",
  event_analytics_promocode: "Купон для Аналитики",
  event_analytics_promocode_hint: "Вы можете ввести купон, чтобы ускорить формирование аналитических отчетов по данному событию. Купон действует только в том случае, если вы введете его до отправки видео на сервер аналитики. Чтобы получить купон, обратитесь к менеджеру службы поддержки Cybercamera.",
  import_epg_notes: "Предыдущие события расписания с приоритетом «Импортированный» будут заменены новыми событиями из импортированных данных.\nСобытия с другими приоритетами не изменятся.",
  import_epg_def_stream_type: "Профиль вещания для всех импортируемых событий",
  import_epg_def_stream_type_help: "Если профиль не выбран, камера не будет записывать или транслировать это событие. Вы можете добавить профиль на странице «Стриминг».",
  import_epg_file_type: "Формат данных EPG",
  import_epg_default_game_type: "Тип события по умолчанию",
  import_epg_default_game_type_help: "События используют этот тип, если формат данных EPG не предоставляет информацию о типе события. Табло, если оно существует, отображается только для типа «Игра».",
  import_epg_default_sport_type: "Вид спорта по умолчанию",
  import_epg_default_sport_type_help: "События используют этот вид спорта, если формат данных EPG не предоставляет информацию о виде спорта.",
  import_epg_upload_file: "Ручной импорт расписания из файла",
  import_epg_upload_file_help: "Импорт расписания из файла. Предыдущие события расписания с приоритетом «Импортированный» будут заменены новыми событиями из импортированных данных.",
  import_epg_upload_file_btn: "Загрузить расписание из файла",
  import_epg_auto_import: "Автоматический импорт",
  import_epg_auto_import_active: "Активен",
  import_epg_auto_import_disabled: "Не активен",
  import_epg_url: "URL для автоматического импорта расписания",
  import_epg_url_help: "Работающий веб-URL для загрузки событий EPG в указанном формате.",
  import_epg_update_period: "Интервал автообновления расписания, Час",
  import_epg_update_period_help: "Для автоматического обновления EPG используйте интервал от 0,1 часа до 168 часов (одна неделя). Чтобы отключить автоматические обновления, используйте 0.",
  import_epg_last_import_result: "Последний результат импорта",
  import_epg_last_import_success: "Успешный импорт.",
  import_epg_last_import_error: "Ошибка импорта.",
  stream_profile: "Профиль",
  stream_profile_apply_progress_header: "Связь с сервером",
  stream_profile_apply_progress_text: "Применение изменений",
  event_disabled: "Выключить событие",
  event_deleted: "Событие удалено",
  disabled: "Выключено",
  deleted: "Удалено",
  analytics_games_added_number: "Добавлено игр: {count}",
  analytics_uploaded: "Загружено",
  analytics_uploading: "Загружено: {percent}%",
  analytics_analytics_progress: "Прогресс {percent}%",
  analytics_ready_analysis: "Готова к анализу",
  analytics_waiting_team_mapping: "Ожидаем сопоставления команд",
  analytics_uploading_stoped: "Загрузка остановлена",
  analytics_waiting_markup: "Ожидаем разметки видео для аналитики",
  analytics_done: "Готово",
  analytics_state_analytics: "Аналитика",
  analytics_error: "Ошибка",
  analytics_canceled: "Отменено",
  analytics_filter_by_done: "Аналитика - Готово",
  analytics_filter_by_canceled: "Аналитика - Отменено",
  analytics_filter_by_failed: "Аналитика - Ошибка",
  analytics_filter_by_progress: "Аналитика - В процессе",
  analytics_filter_by_ready_analysis: "Аналитика - Готова к анализу",
  analytics_filter_by_waiting_team_mapping: "Аналитика - Ожидаем сопоставления команд",
  event_type: "Тип",
  event_teams: "Команды",
  event_time: "Время",
  event_repeat: "Повтор",
  event_repeat_no: "Без повтора",
  event_repeat_every_week: "Каждую неделю",
  event_invalid_start_date: "Некорректная дата начала события",
  event_invalid_start_date_description: "Дата начала события не должна быть в прошлом времени",
  event_invalid_max_duration: "Максимальная продолжительность события {max} часов",
  event_curr_duration: "Длительность {currHours}:{currMinutes}",
  event_end_before_start_date: "Дата окончания не может быть раньше даты начала",
  event_status_start_in_past: "Время начала события уже прошло",
  event_status_ended_event: "Завершенное событие",
  event_status_recurring_end_in_past: "Завершенное повторяющееся событие",
  event_status_disabled_event: "Выключенное событие",
  weekday_short_sunday: "Вс",
  weekday_short_monday: "Пн",
  weekday_short_tuesday: "Вт",
  weekday_short_wednesday: "Ср",
  weekday_short_thursday: "Чт",
  weekday_short_friday: "Пт",
  weekday_short_saturday: "Сб",
  button_ok: "OK",
  button_accept: "Применить",
  button_cancel: "Отмена",
  table_rows_per_page: "Элементов на странице",
  table_of: "из",
  history_event_streaming: "Поток",
  history_event_records: "Записи",
  team_id: "Идентификатор команды",
  team_id_help: "Выберите идентификатор команды для использования. Только если существующий идентификатор не найден, создайте новый идентификатор с заглавной латиницей, например: MSK_SPARTAK_DETI_2.",
  team_id_add_help: "Добавляйте новый идентификатор команды только в том случае, если вы не можете найти существующий идентификатор! Вы не сможете удалить этот идентификатор после его создания, поэтому проверьте его еще раз. В идентификаторе используйте только латинские буквы, цифры, нижнее подчеркивание, тире, например: MSK_SPARTAK_DETI_BASKETBALL_2, SPB-ZENIT-FOOTBALL-2015, NNov-Dinamo-Hockey-4",
  add_team_id: "Добавить идентификатор команды",
  team_name: "Название команды",
  team_short_name: "Короткое название",
  team_logo: "Логотип команды",
  team_notes: "Заметки",
  team_color: "Цвет команды",
  team_upload_new_logo: "Загрузить новое лого",
  page_streaming_configuration_title: "Стриминг",
  page_streaming_api_heading: "Стриминг",
  page_video_archive_heading: "Видео архив",
  page_video_archive_tab_operator: "Операторский поток",
  page_video_archive_tab_panorama: "Панорама",
  page_video_archive_tab_scoreboard: "Табло",
  page_video_archive_tab_select_all_option: "Выбрать всё",
  page_video_archive_empty_list: "Видеофайлы не найдены",
  add: "Добавить",
  edit: "Редактировать",
  rename: "Переименовать",
  failed_load_data: "Не удалось загрузить данные",
  stream_audio_title: "Звук",
  stream_audio_channels: "Каналы",
  stream_audio_bitrate: "Битрейт, кБит",
  stream_audio_enable: "Включено",
  stream_audio_sample_rate: "Частота, Гц",
  stream_audio_volume: "Громкость, %",
  stream_audio_control_title: "Компрессор звука",
  stream_audio_control_rms_peak: "RMS/peak",
  stream_audio_control_attack_time: "Attack time, ms",
  stream_audio_control_release_time: "Release time, ms",
  stream_audio_control_threshold_level: "Threshold level, dB",
  stream_audio_control_ratio: "Ratio, 1:n",
  stream_audio_control_knee_radius: "Knee radius, dB",
  stream_audio_control_knee_makeup_gain: "Makeup gain, dB",
  stream_video_title: "Основной поток",
  stream_video_secondary_title: "Дополнительный поток панорамы",
  stream_video_resolution: "Разрешение видео",
  stream_video_resolution_width: "Ширина, пикс.",
  stream_video_resolution_height: "Высота, пикс.",
  stream_video_bitrate_mbps: "Битрейт видео, МБит",
  stream_video_add_destination: "Добавить получаеля",
  stream_video_store_local_file: "Сохранять в видео архив на Киберкамеру",
  stream_video_sync_to_server: "Загружать видео на сервер аналитики",
  stream_video_panorama_source: "Источник панорамы",
  stream_video_rtmp_help: "Вы можете получить RTMP URL-адрес сервера и Ключ (KEY) на странице администрирования службы потоковой передачи, которую вы хотите использовать.",
  stream_video_rtmp_url: "RTMP URL",
  stream_video_rtmp_key: "Ключ (KEY)",
  stream_video_hint_cybercamera: "Киберкамера может принимать потоки в формате 4k при 60 кадрах в секунду и выводить 4k при 60 кадрах в секунду.",
  stream_video_hint_youtube: "Youtube может принимать потоки RTMP в формате 4k со скоростью 60 кадров в секунду, и в настройках будет отображаться предупреждение: «Текущее разрешение (3840 x 2160) не поддерживается на YouTube. Рекомендуем использовать формат 2560 x 1440.». но будет работать и выводить видео в 1440p со скоростью 60 кадров в секунду.",
  stream_video_hint_rutube: "RUTUBE может принимать потоки RTMP в формате 4k со скоростью 60 кадров в секунду, но будет выводить в формате 1080p со скоростью 30 кадров в секунду.",
  stream_video_hint_vkvideo: "VK Видео может принимать RTMP-потоки в формате 4k при 60 кадрах в секунду и выводить 4k при 60 кадрах в секунду.",
  stream_video_hint_twitch: "twitch может принимать потоки RTMP в формате 4k со скоростью 60 кадров в секунду и максимум 6 Мбит, но будет выводить в формате 1080p со скоростью 60 кадров в секунду.",
  stream_video_title_hint: "Cybercamera будет автоматически транслировать настроенные события из Расписания.",
  stream_video_youtube_login: "Войти на YouTube",
  stream_video_vkvideo_login: "Войти в VK Видео",
  stream_video_cybercamera_login: "Войти в Киберкамеру",
  stream_video_youtube_logout: "Выйти из Youtube",
  stream_video_vkvideo_logout: "Выйти из VK Видео",
  stream_video_cybercamera_logout: "Выйти из Киберкамеры",
  stream_video_stream_target: "Размещать на",
  stream_video_stream_target_hint: "Место для размещения видео. Это может быть собственная страница пользователя или группа, где у пользователя есть на это разрешение. Возможно, вам придется выйти и войти снова, чтобы изменить место размещения.",
  stream_video_stream_target_user_page: "Собственная страница пользователя",
  stream_video_playlist_name: "Название плейлиста",
  stream_video_playlist_name_hint: "Вы можете указать название для плейлиста, чтобы сгруппировать все видео с этой камеры.",
  stream_video_name_prefix: "Префикс названия потока",
  stream_video_name_prefix_hint: "По умолчанию названия потока выглядит как «11:15 Название события». Вы можете добавить необязательный префикс перед временем. Например, если префикс «Баскетбол – », то название будет выглядеть как «Баскетбол – 11:15 Название события».",
  stream_video_access_permision: "Права на доступ",
  stream_video_access_permision_hint: "Ограничьте круг лиц, которые могут просматривать этот видеопоток.",
  stream_access_permision_all: "Все",
  stream_access_permision_members: "Только участники",
  stream_access_permision_payed: "Участники, которые платят",
  stream_access_permision_admin: "Только администраторы",
  stream_access_permision_by_link: "Скрыто (Только по ссылке)",
  sort_by: "Сортировать по",
  sort_by_time: "Время",
  sort_asc: "Возр.",
  sort_desc: "Убыв.",
  filter_by_team: 'Фильтр по команде',
  filter_by_analytics_status: 'Фильтр по статусу',
  filter_no_filter: 'Без фильтрации',
  filter_by_date: "Фильтр по дате",
  filter_by_date_off: 'Без фильтрации',
  filter_by_camera: "Фильтр по камере",
  filter_by_all_camera: "Все камеры",
  filter_by_all_teams: "Все команды",
  add_account: "Добавить Аккаунт",
  users_filter_camera_label: "Фильтр по камере",
  users_filter_teamref_label: "Фильтр по команде",
  user_popup_title: "Аккаунт",
  user_name: "Имя",
  user_group: "Группа",
  user_visible_cameras: "Доступные камеры",
  user_visible_teamrefs: "Доступные команды",
  user_last_login: "Последняя авторизация",
  page_user_title_web_admin: "Локальные аккаунты",
  page_user_title_server_web_admin: "Облачные аккаунты",
  user_login: "Логин",
  user_server_login: "Логин (email)",
  user_password: "Пароль",
  user_confirm_password: "Подтверждение пароля",
  user_confirm_incorrect_password: "Некорректный пароль",
  enter_confirm_password: "Введите пароль повторно",
  user_old_password: "Старый пароль",
  passwords_do_not_match: "Пароли не совпадают",
  password_restrictions: "Пароль должен быть не короче {min} и не длиннее {max} символов, включать в себя маленькие и большие буквы и минимум 2 цифры",
  cant_delete_your_account: "Нельзя удалить свой аккаунт",
  invalid_current_password: "Указан не верный текущий пароль",
  password_will_be_generated_and_send_to_login_email_address: "Пароль будет сгенерирован и отправлен на адрес электронной почты для входа.",
  cameras_option_title_all: "Все мои камеры",
  cameras_option_title_root_access: "Root доступ",
  teamref_option_title_all: "Все мои команды",
  teamref_option_title_root_access: "Root доступ",
  cameras_option_title_selected_list: "Выбранные значения",
  cameras_option_title_none: "Ничего",
  cameras_option_title_guest: "Гостевой доступ",
  password_reset: "Сбросить пароль",
  password_reset_successful: "Пароль успешно сброшен. Новый пароль отправлен на email.",
  invalid_server_login: "Логин должен быть корректным адресом email.",
  value_incorrect: "Некорректное значение",
  filtered_data_empty: "По выбранным параметрам данных нет",
  cameraError: 'Ошибка',
  cameraOnlineWarning: "Онлайн, предупреждение",
  cameraOnlineCritical: "Онлайн, критично",
  unknown: "Неизвестный",
  unknown_short: "Неиз.",
  saveToStorage: "Запись в хранилище",
  camerasWarningCount: "С предупреждениями",
  camerasErrorCount: "С ошибками",
  storage_sync_short: "Загрузка для аналитики",
  active_camera: "Активная камера",
  no_active_camera: "АКТИВНАЯ КАМЕРА НЕ ВЫБРАНА",
  active_teamref: "Активная команда",
  no_active_teamref: "АКТИВНАЯ КОМАНДА НЕ ВЫБРАНА",
  select_camera_as_active: "Выбрать",
  camera_name: "Название",
  camera_address_pi: "Адрес основного бэкенда в камере (IP или DNS)",
  camera_address_j1: "Адрес J#1 (IP или DNS)",
  camera_address_j2: "Адрес J#2 (IP или DNS)",
  camera_type: "Тип камеры",
  camera_type_hint: "Установите правильный тип камеры. Используйте Proxy для конфигурации прокси-камеры.",
  camera_technical_info: "Техническая информация",
  camera_notes: "Заметки",
  home_address: "Домашний адресс",
  optional: "Необязательный",
  storage_general: "Размер хранилища",
  storage_free: "Свободно",
  storage_remove_old_video_when_storage_less_then_gb: 'Удалять старые видео, если свободно менее, ГБайт',
  current_sync_speed_in_b_sec: "Скорость загрузки на сервер",
  wait: 'Ожидание…',
  files_in_sync_queue_in_gb: 'Файлов в очереди на загрузку',
  clear_sync_queue: "Очистить очередь",
  default_sync_speed_mbit: 'Скорость загрузки (0 - Не ограничено), МБит',
  sync_speed_when_streaming_active_mbit: 'Скорость загрузки при активном стриминге (0 - Не ограничено), МБит',
  sync_speed_when_low_speed_network_mbit: "Скорость загрузки, когда скорости сети недостаточно для стриминга (0 - Не ограничено), МБит",
  maximum_sync_speed_mbit: "Максимальная скорость загрузки (0 - Не ограничено), МБит",
  maximum_sync_speed_in: "Максимальная скорость загрузки в период",
  select_streaming_settings: 'Профили вещания',
  no_stream: 'Нет вещания',
  stop_streaming: 'Остановить вещание',
  'rtmp:connecting': 'RTMP: Подключение',
  'rtmp:reconnecting': 'RTMP: Переподключение',
  'rtmp:disconnected': 'RTMP: Нет соединения',
  'rtmp:failed': 'RTMP: Ошибка',
  'rtmp:lastDataDrop': 'RTMP: Низкая скорость интернет',
  'mp4:connecting': 'MP4: Подключение',
  'mp4:reconnecting': 'MP4: Переподключение',
  'mp4:disconnected': 'MP4: Отключили',
  'mp4:failed': 'MP4: Ошибка',
  'mp4:lastDataDrop': 'MP4: Низкая скорость хранилища',
  oldest_first: "Сначала старые",
  newest_first: "Сначала новые",
  any: "Любое",
  cameras_list_statuses_critical: "В критическом состоянии",
  cameras_list_statuses_warning: "С предупреждением",
  cameras_list_statuses_error: "С ошибкой",
  cameras_list_statuses_off: "Не в сети",
};


export default ru;
