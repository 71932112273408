// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import YAxisTick from './YAxisTick';
import { Colors } from '../../../Configuration/Styles/Colors';
import { useMetricChartData } from '../../../Data/Camera/Metrics/Hooks';
import { stringFromUnixDate } from '../../../Tools/UnixDate';
import { formattedMetricUnit, formattedMetricValue } from '../../../Tools/Tools';
import { PlatformConfiguration } from '../../../Configuration/PlatformConfiguration';
import PlaceholderStyled, { ChartTooltipLabelStyle, ChartTooltipItemStyle, ChartLabelStyle, ChartLegendStyle } from '../Styled';
import { MetricsChartSourceInterface, dataKeyX, toMetricDataUnitIfPossible } from '../../../Data/Camera/Metrics/Types';
import LOCALIZATION from '../../../Localization';


type Props = Readonly<{
  source: MetricsChartSourceInterface;
} & WrappedComponentProps>;


const MetricsChartView: React.FC<Props> = (props: Props) => {
  const chartData = useMetricChartData(props.source);
  const hasEnoughtData = chartData.items.length >= 2;
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();
  const isLegendVisible = !useMediaQuery(theme.breakpoints.down('xs'));
  const margin = { top: parseInt(theme.spacing(4), 10), right: 0, left: -30, bottom: parseInt(theme.spacing(2), 10) };

  React.useEffect(() => {
    setIsLoading(true);

    // Если в течение И секунд данные не появились, считаем, что их нет.
    setTimeout(() => {
      setIsLoading(false);
    }, PlatformConfiguration.metricsDataTimeoutInterval);
  }, []);

  if (hasEnoughtData) {
    const tickFormatter0 = (date: any) => stringFromUnixDate(date, 'shortTime');
    const tick = (props: any) => (
      <YAxisTick
        x={ props.x }
        y={ props.y }
        value={ props.payload.value.toFixed(chartData.valuesRange?.decimalPartLength ?? 0) }
      />
    );
    const tickFormatter1 = (value: number) => value.toFixed(chartData.valuesRange?.decimalPartLength ?? 0);

    const tooltipFormatter = (valueAny: any, name: any, info: any, index: any, payload: any) => {
      const value = parseFloat(valueAny.toString())
      const unit = toMetricDataUnitIfPossible(info.unit);

      if (unit) {
        return formattedMetricValue(value, unit, { displayUnit: false, multiplyUnit: false });
      }

      return value.toFixed(1);
    };

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={ chartData.items }
          margin={ margin }
        >
          <CartesianGrid
            strokeDasharray="3 3"
            color={ Colors.lightGrey }
          />
          <XAxis
            style={ ChartLabelStyle }
            tick={ { fill: Colors.headingMain } }
            dataKey={ dataKeyX }
            axisLine={ false }
            tickLine={ false }
            // padding = { { left: 20 } }
            reversed = { true }
            ticks={ chartData.timeRange?.ticks }
            tickFormatter={ tickFormatter0 }
          />
          <YAxis
            width={ 30 }
            axisLine={ false }
            tickLine={ false }
            tick={ tick }
            ticks={ chartData.valuesRange?.ticks }
            type="number"
            domain={ [chartData.valuesRange?.minumum ?? 'auto', chartData.valuesRange?.maximum ?? 'auto'] }
            tickFormatter={ tickFormatter1 }
          />
          <Tooltip
            formatter={ tooltipFormatter }
            labelFormatter={ date => stringFromUnixDate(date, 'smart') }
            labelStyle={ ChartTooltipLabelStyle }
            itemStyle={ ChartTooltipItemStyle }
            wrapperStyle={ { background: Colors.mainBackground, zIndex: 2 } }
          />
          { props.source.metrics.map(metric => {
            return (
              <Line
                unit={ formattedMetricUnit(metric.unit) }
                name={ metric.name }
                isAnimationActive={ false }
                key={ metric.dataKey }
                type="monotone"
                strokeDasharray={ metric.dash }
                dataKey={ metric.dataKey }
                stroke={ metric.color }
                strokeWidth={ 2 }
                dot={ false }/>
            )
          }) }

          {
            (isLegendVisible) &&
              <Legend
                  verticalAlign="bottom"
                  iconSize={ 12 }
                  wrapperStyle={ ChartLegendStyle }
                  align="left"
              />
          }

        </LineChart>
      </ResponsiveContainer>
    );
  }

  return (
    <PlaceholderStyled>
      { isLoading ? props.intl.formatMessage({ id: LOCALIZATION.loading_metrics }) : props.intl.formatMessage({ id: LOCALIZATION.empty_metrics_data }) }
    </PlaceholderStyled>
  );
};

export default injectIntl(MetricsChartView);
