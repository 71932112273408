// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import MainButton from '../../_BaseUI/MainButton'
import { Alert } from '@mui/lab';
import { Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


export enum AlertType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Info = 'info',
}

type Props = Readonly<{
  type?: AlertType;
  header?: string;
  text?: string;
  onClose?: () => void;
}>;


const AlertPopup: React.FC<Props> = (props: Props) => {
  return (
    <Dialog
      open
      onClose={ props.onClose }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {
          (props.type) ?
            <Alert
              severity={ props.type }
            >
              { props.header }
            </Alert>
            :
            props.header
        }
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
        >
          { props.text }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          (props.onClose) &&
            <Grid item>
              <MainButton
                title={ 'OK' }
                onClicked={ props.onClose }
              />
            </Grid>
        }
      </DialogActions>
    </Dialog>
  );
};


export default AlertPopup;
