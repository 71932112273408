// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useMutation } from 'react-query';

import { deleteArchives, patchArchive, PREFIX_OF_A_COMPOSITE_KEY } from '.';
import { queryCache, queryClient } from '../../../../AppProviders';
import AnalyticsVideoArchive from '../AnalyticsVideoArchive';


export enum ActionType {
  Delete = 'delete',
  Patch = 'patch',
}

export type ArchiveForActionProps = Readonly<{
  id: string;
  archive?: AnalyticsVideoArchive; // used by ActionType.Patch
}>;

export type Action = Readonly<{
  archives?: ArchiveForActionProps[];
  type: ActionType;
}>;


const useMutationArchive = () => {
  return useMutation(
    (action: Action) => {
      switch (action.type) {
        case ActionType.Delete: {
          if (Array.isArray(action.archives) && action.archives.length > 0) {//{ archive={type: "operator", startAt: "2022-01-02T12:30", event?: EPGEvent} }
            return deleteArchives(action.archives[0]);
          }

          return Promise.reject(new Error('Not all required fields are initialized'));
        }
        case ActionType.Patch: {
          if (Array.isArray(action.archives) && action.archives.length > 0) {//{ archive={type: "operator", startAt: "2022-01-02T12:30", event?: EPGEvent} }
            return patchArchive(action.archives[0]);
          }

          return Promise.reject(new Error('Not all required fields are initialized'));
        }
        default:
          return Promise.reject(new Error('Invalid Account Mutation Action'));
      }
    },
    {
      onSuccess: () => {
        const queries = queryCache.findAll([[PREFIX_OF_A_COMPOSITE_KEY]]);

        if (queries && queries.length) {
          queries.forEach(({ queryKey }) => {
            queryClient.removeQueries(queryKey);
          })
        }
      },
    },
  );
};


export default useMutationArchive;
