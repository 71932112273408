// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Link, Typography } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Colors } from '../../../../Configuration/Styles/Colors'
import { PlatformConfiguration } from '../../../../Configuration/PlatformConfiguration'
import { Sizes } from '../../../../Configuration/Styles/Sizes';
import LOCALIZATION from '../../../../Localization';


type Props = Readonly<{} & WrappedComponentProps>;


const Copyright: React.FC<Props> = (props: Props) => (
  <Typography
    sx={ {
      color: Colors.textBlue,
      position: 'absolute',
      bottom: 25,
      right: 25,
      fontSize: Sizes.subtitle
    } }
  >
    { props.intl.formatMessage({ id: LOCALIZATION.copyright }) }{ ' ' }
    <Link color="inherit" href={ PlatformConfiguration.siteUrl() }>
      { `${ props.intl.formatMessage({ id: LOCALIZATION.app_title }) }` }
    </Link>{ ' ' }
    { ` © ${ new Date().getFullYear() } ${ props.intl.formatMessage({ id: LOCALIZATION.version }) }: (${ PlatformConfiguration.appVersion })` }
  </Typography>
);


export default injectIntl(Copyright);
