// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Checkbox, Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { ItemStreamProps } from '../index';
import BoxComponent from '../../../Box';
import FieldSetWrapper from '../FieldSetWrapper';
import { useCameraState } from '../../../../../Data/NapiCameraState/hook';
import FieldSet, { FieldSetInputType } from '../../../../../Components/_BaseUI/FieldSet';
import LOCALIZATION from '../../../../../Localization';
import { useCameraSettings } from '../../../../../Data/Camera/HWCameraSettings/hook/Hooks';


type Props = Readonly<{
  item: ItemStreamProps;
  onChange: (item: ItemStreamProps) => void;
} & WrappedComponentProps>;


const SecondaryStream: React.FC<Props> = (props: Props) => {
  const cameraSettings = useCameraSettings(true);
  
  const handleChangeStoreVideoToSsd = (event: any, storePanoramaVideoToSsd: boolean) => {
    const changed = {
      ...props.item,
      custom: {
        ...props.item?.custom,
        storePanoramaVideoToSsd,
      },
    };

    props.onChange(changed);
  };
  const handleChangeSyncPanoramaToServer = (event: any, syncPanorama: boolean) => {
    const changed = {
      ...props.item,
      custom: {
        ...props.item?.custom,
        syncPanorama,
      },
    };

    props.onChange(changed);
  };
  const handleChangeBitRate = (value: number | null) => {
    let bitrate = (value !== null && !isNaN(value)) ? value : 0;
    if (bitrate <= 0) {
      bitrate = 0.1
    }
    else if (bitrate > 200) {
      bitrate = 200
    }

    props.onChange({
      ...props.item,
      original: {
        ...props.item?.original,
        bitrate: Math.ceil(bitrate * 1000000),// mbit -> bit,
      },
    });
  };
  const cameraState = useCameraState();

  let panoramaSourceArr = [];
  const resolution = cameraState?.['cropped-frame-resolution']
  const width = resolution?.width;
  const height = resolution?.height;
  if (width && !isNaN(width) && height && !isNaN(height)) {
    panoramaSourceArr.push(`${ width }x${ height }`);
  }
  panoramaSourceArr.push(`(${ (props.item?.original?.h264) ? 'h264' : 'h265' })`);

  const fps = cameraSettings?.streamingState?.mainConfig?.['frame-rate'] || 60
  panoramaSourceArr.push(`${ fps } fps`);

  const panoramaSource = panoramaSourceArr.join(' ');

  return (
    <BoxComponent
      title={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_secondary_title }) }
    >
      <Grid
        container
        spacing={ 4 }
      >
        <FieldSetWrapper>
          <FieldSet
            label={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_store_local_file }) }
            input={ {
              type: FieldSetInputType.Other,
              children: (
                <Checkbox
                  sx = {{ marginLeft : -1 }}
                  defaultChecked={ !!props.item.custom?.storePanoramaVideoToSsd }
                  value={ !!props.item.custom?.storePanoramaVideoToSsd }
                  color="primary"
                  onChange={ handleChangeStoreVideoToSsd }
                />
              ),
            } }
          />
        </FieldSetWrapper>
        <FieldSetWrapper>
          <FieldSet
            label={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_sync_to_server }) }
            input={ {
              type: FieldSetInputType.Other,
              children: (
                <Checkbox
                  sx = {{ marginLeft : -1 }}
                  defaultChecked={ !!props.item.custom?.syncPanorama }
                  value={ !!props.item.custom?.syncPanorama }
                  color="primary"
                  onChange={ handleChangeSyncPanoramaToServer }
                />
              ),
            } }
          />
        </FieldSetWrapper>
        <FieldSetWrapper>
            <FieldSet
                label={ `${ props.intl.formatMessage({ id: LOCALIZATION.stream_video_panorama_source }) }:` }
                input={ {
                  type: FieldSetInputType.StaticLabel,
                  value: panoramaSource,
                } }
            />
        </FieldSetWrapper>
        <FieldSetWrapper>
          <FieldSet
            label={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_bitrate_mbps }) }
            input={ {
              type: FieldSetInputType.Float,
              value: (props.item?.original?.bitrate) ? props.item?.original?.bitrate / 1000000 : 30, // bit -> mbit 
              onChange: handleChangeBitRate,
            } }
          />
        </FieldSetWrapper>
      </Grid>
    </BoxComponent>
  );
};


export default injectIntl(SecondaryStream);
