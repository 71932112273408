import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Grid, } from '@mui/material';
import { injectIntl, IntlShape, WrappedComponentProps } from 'react-intl';
import Pagination from '../../Components/PaginationControl';

import Button from '../../Components/_BaseUI/Button';
import LOCALIZATION from '../../Localization';
import CameraPopup from './CameraPopup';
import isSuperAdmin from '../../Data/AccountUsers/tools/isSuperAdmin';
import AuthorisationManager from '../../Data/Auth/AuthorisationManager';
import RemoteCameraState, { RemoteCameraStateValue } from '../../Data/Camera/RemoteCameraState';
import { removeAllQueryCache } from '../../AppProviders';
import FieldSetSelectComponent from '../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import { FieldSetInputType } from '../../Components/_BaseUI/FieldSet';
import { PaginationContext } from './Pagination.context';
import { useCamerasStatistic } from '../../Data/Camera/AvaibleCamerasStatistic/hook';
import { RemoteCamerasFilter } from './RemoteCamerasFilter.context';
import Order from '../../Data/_Networking/ReactQuery/Order';
import CameraListStatusMessage from './Components/CameraListStatusMessage';
import { useIsAvaibleCameras } from '../../Data/Camera/ActiveCamera/hook';


type Props = Readonly<{
  camerasStatesArr: RemoteCameraState[];
} & WrappedComponentProps>;


type RemoteCameraStateFilterOption = {
  id: RemoteCameraStateValue | "0",
  name: string
}

/**
 * Logic for the camera status filter
 * extracted separately to avoid clutter in the component, not to deal with legacy code
 * and to maintain readability
 * if necessary, it can be moved to another entity
 * @param props.intl  I haven't searched for a way to inject into a hook, there must be one
 * @todo find a hook for {IntlShape}
 * @returns
 */
const useCameraStateFilter = (props: { intl: IntlShape }) => {
  const stateFilterOptions: RemoteCameraStateFilterOption[] = [
    {
      id: "0",
      name: props.intl.formatMessage({ id: LOCALIZATION.any })
    }, {
      id: RemoteCameraStateValue.Unknown,
      name: props.intl.formatMessage({ id: LOCALIZATION.unknown })
    }, {
      id: RemoteCameraStateValue.OnlineOK,
      name: props.intl.formatMessage({ id: LOCALIZATION.online })
    }, {
      id: RemoteCameraStateValue.OnlineWarning,
      name: props.intl.formatMessage({ id: LOCALIZATION.cameraOnlineWarning })
    }, {
      id: RemoteCameraStateValue.OnlineCritical,
      name: props.intl.formatMessage({ id: LOCALIZATION.cameraOnlineCritical })

    }, {
      id: RemoteCameraStateValue.Offline,
      name: props.intl.formatMessage({ id: LOCALIZATION.offline })
    }, {
      id: RemoteCameraStateValue.Error,
      name: props.intl.formatMessage({ id: LOCALIZATION.cameraError })
    }]

  /**
     * Фильтр по статусу камеры
     * @todo при необходимости вынести в отдельный контекст
     */
  const [cameraStateFilter, setCameraStateFilter] = useState<string>("0")

  const { setState, state: stateCamerasFilter } = useContext(RemoteCamerasFilter)

  useEffect(() => {
    if (cameraStateFilter === "0") {
      setState?.(undefined)
      return;
    }
    setState?.(cameraStateFilter as RemoteCameraStateValue)
  }, [cameraStateFilter, setState])

  return {
    stateFilterOptions,
    setCameraStateFilter,
    stateCamerasFilter,
  }
}

const LocalPagination = () => {
  const { limit, setPage, offset } = useContext(PaginationContext);

  const camerasStatisticQuery = useCamerasStatistic()

  const camerasOverroundInfo = camerasStatisticQuery.data;

  const totalPages = useMemo(() => {
    if (!camerasOverroundInfo) {
      return 0;
    }
    return Math.ceil(camerasOverroundInfo?.totalCameras / limit)
    // eslint-disable-next-line
  }, [camerasStatisticQuery.dataUpdatedAt, limit, camerasOverroundInfo]);

  const currentPage = useMemo(() => {
    return offset / limit
  }, [offset, limit])

  return <Pagination
    totalPages={totalPages}
    currentPage={currentPage}
    onClick={setPage}
  />
}

const CamerasPageHeader: React.FC<Props> = (props: Props) => {
  const [isAddPopupOpened, setIsAddPopupOpened] = React.useState<boolean>(false);
  const { orderByDate, setOrder, } = useContext(PaginationContext);
  const setOrderCallback = useCallback((value: string) => {
    setOrder?.(value as Order)
  }, [setOrder])




  const { user } = AuthorisationManager.shared;
  const isSA = isSuperAdmin(user);

  const isAvaibleCameras = useIsAvaibleCameras()

  const { stateFilterOptions, setCameraStateFilter, stateCamerasFilter } = useCameraStateFilter(props)

  const sortBy = [{
    id: Order.ASC,
    name: props.intl.formatMessage({ id: LOCALIZATION.oldest_first })
  }, {
    id: Order.DESC,
    name: props.intl.formatMessage({ id: LOCALIZATION.newest_first })
  }]

  const handleToggleAddPopup = () => {
    setIsAddPopupOpened(!isAddPopupOpened);
  };
  const handleClosePopup = () => {
    setIsAddPopupOpened(false);
  };
  const handlePostedSuccess = () => {
    handleClosePopup();

    removeAllQueryCache();

    alert(props.intl.formatMessage({ id: LOCALIZATION.add_cam_success_alert }));
  };

  return (
    <>
      {!!(isSA || isAvaibleCameras.data) && <Grid container style={{
        borderRadius: "8px",
        background: "#FFF",
        padding: "20px",
        margin: "3px",
        width: "100%",
        marginTop: "12px",
        paddingBottom: 0,
      }}
        spacing={2}
      >
        {(isSA) && <Grid item xs={"auto"} style={{ padding: 0, marginRight: "12px" }}  >


          <Button
            onClick={handleToggleAddPopup}
          >
            {props.intl.formatMessage({ id: LOCALIZATION.add })}
          </Button>

        </Grid>}
        {isAvaibleCameras.data && (
          <>
            <Grid item xs={"auto"} style={{ padding: 0 }}  >

              <FieldSetSelectComponent
                input={{
                  type: FieldSetInputType.Select,
                  label: props.intl.formatMessage({ id: LOCALIZATION.sort_by }),
                  value: orderByDate ?? Order.ASC,
                  options: sortBy,
                  onChange: setOrderCallback,
                }}
              ></FieldSetSelectComponent>
            </Grid>
            <Grid item xs={"auto"} style={{ padding: 0, marginLeft: "12px" }}   >

              <FieldSetSelectComponent
                input={{
                  type: FieldSetInputType.Select,
                  label: props.intl.formatMessage({ id: LOCALIZATION.state }),
                  value: stateCamerasFilter ?? "0",
                  options: stateFilterOptions,
                  onChange: setCameraStateFilter,
                }}
              ></FieldSetSelectComponent>
            </Grid>

            <Grid container style={{ padding: 0, height: "54px" }} alignItems={"center"}>
              <CameraListStatusMessage />
              <Grid item marginLeft={"auto"}>

                <LocalPagination></LocalPagination>
              </Grid>

            </Grid>
          </>)}
      </Grid>}
      {
        (isAddPopupOpened) &&
        <CameraPopup
          isServer={true}
          type="attach"
          onClose={handleClosePopup}
          onPostedSuccess={handlePostedSuccess}
        />
      }
    </>
  )
};

export default injectIntl(CamerasPageHeader);
