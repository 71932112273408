// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useLayoutEffect } from 'react'
import { Grid, Box, Typography } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import ErrorMessage from '../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage'
import GameStatBartLineChartCard from './GameStatPhysicalReportCard'
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import AnalyticsGamesPageHeader from '../../Components/_Layout/AnalyticsGamesPageHeader';
import { useParams } from 'react-router-dom';
import useGamesPagedData from '../../Data/Analytics/AnalyticsGameArchive/hook';
import AnalyticsGameArchive from '../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import { getResponseManyDataOne } from '../../Data/_Networking/ReactQuery/ResponseMany';
import FieldSetSelectComponent from '../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import { FieldSetInputType } from '../../Components/_BaseUI/FieldSet';
import LOCALIZATION from '../../Localization';
import { ANALYTICS_GRANULARITY_ARR, AnalyticsStatAction, AnalyticsStatGranularity, analyticsGranularityName } from '../../Data/Analytics/AnalyticsTypes';
import HorizGrid from '../../Components/_Layout/HorizGrid';
import Tabs, { TabItemInterface } from '../../Components/_Layout/Tabs';
import VertGrid from '../../Components/_Layout/VertGrid';
import useFetchAllPagesTeamRefs from '../../Data/Analytics/AnalyticsTeamRef/hook';

export type TabItemDetails = Readonly<{
  name: string;
  fetchAction: AnalyticsStatAction | undefined;
} & TabItemInterface>;

const AnalyticsGameReportMainDetails: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const [granularity, setGranularity] = React.useState<AnalyticsStatGranularity>(AnalyticsStatGranularity.Granularity4);
  
  const { AnalyticsGameReport: gameId = "" } = useParams();
  
  const { status: fetchStatus, data: gameRecordsData } = useGamesPagedData({ itemId: gameId });
  const gameResultInfo: AnalyticsGameArchive | undefined = getResponseManyDataOne(gameRecordsData?.data)

  const {
    getTeamRefByExternalId,
  } = useFetchAllPagesTeamRefs();

  useLayoutEffect(() => {
      window.scrollTo(0, 0)
  });

  const TABS: TabItemDetails[] = [
    {
      id: 0,
      title: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_overall }),
      name: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_overall_name }),
      fetchAction: undefined,
      Content: GameStatBartLineChartCard,
    },
    {
      id: 1,
      title: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_attacking }),
      name: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_attacking_name }),
      fetchAction: AnalyticsStatAction.Attack,
      Content: GameStatBartLineChartCard,
    },
    // {
    //   id: 2,
    //   title: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_attacking_defence }),
    //   name: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_attacking_defence_name }),
    // fetchAction: undefined,
    //   Content: GameStatBartLineChartCard,
    // },
    {
      id: 2,
      title: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_defending }),
      name: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_defending_name }),
      fetchAction: AnalyticsStatAction.Defense,
      Content: GameStatBartLineChartCard,
    },
    // {
    //   id: 4,
    //   title: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_defese_attack }),
    //   name: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_defese_attack_name }),
    // fetchAction: undefined,
    //   Content: GameStatBartLineChartCard,
    // },
  ];
  const [selectedTabID, setSelectedTabID] = React.useState<number>(TABS[0].id);


  if (fetchStatus === 'loading') {
    return (
      <>
      <br /><br /><Spinner /><br /><br />
      </>
    )
  }


  const renderContent = () => (
    TABS.map((tab: TabItemDetails) => {
      const hidden = (selectedTabID !== tab.id);

      if (hidden) { return null; }

      return (
        <Typography
          key={ tab.id }
          component="div"
          role="tabpanel"
          // hidden={ hidden }
          id={ `simple-tab-${tab.id}` }
          aria-labelledby={ `simple-tabpanel-${tab.id}` }
        >
          <GameStatBartLineChartCard 
            gameId={ gameId }
            granularity={ granularity } 
            action={ tab.fetchAction }
            gameResultInfo={gameResultInfo} 
            title={ tab.name } 
            getTeamRefByExternalId= { getTeamRefByExternalId }
          />
        </Typography>
      );
    })
  );

  const granularityOptions = ANALYTICS_GRANULARITY_ARR.map((granularity) => {
    return ({
      id: granularity,
      name: analyticsGranularityName(granularity),
    });
  });

  const handleGranularityChange = (value: string) => {
    setGranularity(value as AnalyticsStatGranularity);
  };

  return (
    <Box> 
      <Grid 
      container
      spacing={ 2 }
      >
        <Grid item xs={ 12 }>
          <AnalyticsGamesPageHeader/>
          <ErrorMessage/>
        </Grid>
        <Grid item xs={ 12 }>
          <VertGrid>
            <HorizGrid>
              <FieldSetSelectComponent
                input={ {
                  type: FieldSetInputType.Select,
                  label: props.intl.formatMessage({ id: LOCALIZATION.analytics_ganularity }),
                  value: granularity || "none",
                  sx: { minWidth: 200 },
                  options: granularityOptions,
                  onChange: handleGranularityChange,
                } }
              />
            </HorizGrid>

            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
              <Tabs
                items={ TABS }
                selectedTabID={ selectedTabID }
                onChangeTab={ setSelectedTabID }
              />
            </Box>
          </VertGrid>
        </Grid>
        <Grid item xs={ 12 }>
          { renderContent() }
        </Grid>
      </Grid>
    </Box>
  )
};

export default injectIntl(AnalyticsGameReportMainDetails);
