// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from "react";
import videojs from "video.js";

import "video.js/dist/video-js.css";
import "./VideoJS.css";
import { Box } from "@mui/material";

type Player = ReturnType<typeof videojs>;

type Props = Readonly<{
    videoUrl?: string;
    options?: any;
    onReady?: (player: Player) => void;
    onPlaybackTimeChange?: (player: Player, playbackPosSec: number | undefined) => void;
    onPlaybackTimeChangeRoundedToSec?: (player: Player, playbackPosSec: number | undefined) => void;
  }>;

export const VideoJS = (props: Props) => {
  const videoRef = React.useRef<HTMLDivElement | null>(null);
  const playerRef = React.useRef<Player | null>(null);
  const lastPlaybackPosRoundedToSec = React.useRef<number>(0);
  const { options: baseOptions, onReady, onPlaybackTimeChange, onPlaybackTimeChangeRoundedToSec } = props;

  
  let options = baseOptions;

  if (props.videoUrl) {
    const sources: Object[] = []
    // remove from url part for integrated player to allow use this player
    const videoUrl = props.videoUrl.replace("/player/iframe.html?url=..", "")

    if (videoUrl.getUrlExtension()) { // url already have extension
      sources.push({
        src: videoUrl,
        type: "application/vnd.apple.mpegurl"
      })
      options = {
        ...options,
        sources,
      }
    }
    else { // url not have extension. add it
      const hlsSource = { // HLS
        src: videoUrl + ".m3u8",
        type: "application/vnd.apple.mpegurl"
      }
      const dashSource = { // DASH
        src: videoUrl + ".mpd",
        type: "application/dash+xml"
      }
      
      if (videojs.browser.IS_ANY_SAFARI) {
        sources.push(hlsSource);
        sources.push(dashSource);
      }
      else {
        sources.push(dashSource);
        sources.push(hlsSource);
      }

      options = {
        ...options,
        sources,
      }
    }
  }

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current?.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("VideoPlayer:player is ready");
        onReady && onReady(player);

        lastPlaybackPosRoundedToSec.current = 0;
        if (onPlaybackTimeChange || onPlaybackTimeChangeRoundedToSec) {
          onPlaybackTimeChange && onPlaybackTimeChange(player, 0);
          onPlaybackTimeChangeRoundedToSec && onPlaybackTimeChangeRoundedToSec(player, 0);

          player.on('timeupdate', function () {
            const time = player.currentTime() || 0;
            onPlaybackTimeChange && onPlaybackTimeChange(player, time);

            if (onPlaybackTimeChangeRoundedToSec) {
              const newTimeSec = Math.round(time);
              if (lastPlaybackPosRoundedToSec.current !== newTimeSec) {// round plaback to
                lastPlaybackPosRoundedToSec.current = newTimeSec;
                onPlaybackTimeChangeRoundedToSec(player, newTimeSec);
              }
            }
          })
        }

      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:

    } 
    // else {
    //   const player = playerRef.current;
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        
        onPlaybackTimeChange && onPlaybackTimeChange(player, undefined);
        onPlaybackTimeChangeRoundedToSec && onPlaybackTimeChangeRoundedToSec(player, undefined);

        player.dispose();
        playerRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef]);

  return (
    <Box data-vjs-player 
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box 
        ref={videoRef} 
        sx={{
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  );
};

export {
    videojs as vjs
};
export type { Player as VjsPlayer };

export default VideoJS;
