// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Colors } from '../../../../Configuration/Styles/Colors';
import { RemoteCameraStateValue } from '../../../../Data/Camera/RemoteCameraState';


const getBarHeaderColor = (cameraHardwareState: RemoteCameraStateValue): string => {
  if (cameraHardwareState === RemoteCameraStateValue.Offline) {
    return Colors.mainRed;
  }

  if ((cameraHardwareState === RemoteCameraStateValue.OnlineWarning) || (cameraHardwareState === RemoteCameraStateValue.Error)) {
    return Colors.onlineWarning;
  }

  return Colors.mainGreen;
};


export {
  getBarHeaderColor,
};
