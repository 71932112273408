// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { ItemStreamProps } from '../index';
import FieldSet, { FieldSetInputType } from '../../../../../Components/_BaseUI/FieldSet';
import { VideoResolution } from '../../../../../Data/NapiStreamingProfileOptions';
import { EMPTY_ARRAY } from '../../../../../constants';
import FieldSetWrapper from '../FieldSetWrapper';
import LOCALIZATION from '../../../../../Localization';


type Props = Readonly<{
  item: ItemStreamProps;
  videoResolutions: VideoResolution[];
  onChange: (item: ItemStreamProps) => void;
} & WrappedComponentProps>;


const MainStreamVideoResolution: React.FC<Props> = (props: Props) => {

  const customVideoResolution = (
    props.videoResolutions
    .find((item) => (item.title === 'Custom'))
  );

  // find current match resolution
  const currentVideoResolution = (
    props.videoResolutions
      .find((item) => (item.width === props.item.encoder?.width && item.height === props.item.encoder?.height))
    || customVideoResolution
  );

  const handleChangeVideoResolution = (value: string) => {
    const foundVideoResolution = props.videoResolutions
      .find((item) => (item.title === value));

    if (foundVideoResolution) {
      let newItem = { ...props.item }
      if (!newItem.encoder) { newItem.encoder = {} }

      newItem.encoder.width = foundVideoResolution.width || 320
      newItem.encoder.height = foundVideoResolution.height || 240
  
      props.onChange(newItem);
    }
  };

  const handleChangeCustomVideoResolutionWidth = (value: number | null) => {
    let number = (value !== null && !isNaN(value)) ? value : 320;
    if (number < 320) { number = 320; }
    else if (number > 3840) { number = 3840; }

    let newItem = { ...props.item }
    if (!newItem.encoder) { newItem.encoder = {} }

    newItem.encoder.width = number

    props.onChange(newItem);
  };
  const handleChangeCustomVideoResolutionHeight = (value: number | null) => {
    let number = (value !== null && !isNaN(value)) ? value : 240;
    if (number < 240) { number = 240; }
    else if (number > 2160) { number = 2160; }

    let newItem = { ...props.item }
    if (!newItem.encoder) { newItem.encoder = {} }

    newItem.encoder.height = number

    props.onChange(newItem);
  };

  const renderCustomResolution = () => (
    <>
      <FieldSetWrapper>
        <FieldSet
          key={ 'VideoResolutionWidth' }
          label={ `${ props.intl.formatMessage({ id: LOCALIZATION.stream_video_resolution_width }) }:` }
          input={ {
            type: FieldSetInputType.Number,
            value: props.item.encoder?.width || 0,
            onChange: handleChangeCustomVideoResolutionWidth,
          } }
        />
      </FieldSetWrapper>
      <FieldSetWrapper>
        <FieldSet
          key={ 'VideoResolutionHeight' }
          label={ `${ props.intl.formatMessage({ id: LOCALIZATION.stream_video_resolution_height }) }:` }
          input={ {
            type: FieldSetInputType.Number,
            value: props.item.encoder?.height || 0,
            onChange: handleChangeCustomVideoResolutionHeight,
          } }
        />
      </FieldSetWrapper>
    </>
  );

  return (
    <>
      <FieldSetWrapper>
        <FieldSet
          key={ 'VideoResolution' }
          label={ `${ props.intl.formatMessage({ id: LOCALIZATION.stream_video_resolution }) }:` }
          input={ {
            type: FieldSetInputType.Select,
            value: currentVideoResolution?.title || '',
            options: props.videoResolutions
              .map((item: VideoResolution) => ({
                id: item.title,
                name: (item.title === 'Custom') ? props.intl.formatMessage({ id: LOCALIZATION.custom }) : item.title,
              })) || EMPTY_ARRAY,
            onChange: handleChangeVideoResolution,
          } }
        />
      </FieldSetWrapper>
      { (currentVideoResolution === customVideoResolution) && renderCustomResolution() }
    </>
  );
};


export default injectIntl(MainStreamVideoResolution);
