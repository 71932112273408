// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { EventPopupErrors } from './helpers';
import Alert, { AlertType } from '../../../Components/_PopupControls/Alert';
import LOCALIZATION from '../../../Localization';


type Props = Readonly<{
  errorType: EventPopupErrors;
  onClose: () => void;
} & WrappedComponentProps>;


const EventPopupErrorComponent: React.FC<Props> = (props: Props) => {
  const ERROR_TEXTS: { [key in EventPopupErrors]: { header: string; text: string } } = {
    [EventPopupErrors.EventInvalidStartDate]: {
      header: props.intl.formatMessage({ id: LOCALIZATION.event_invalid_start_date }),
      text: props.intl.formatMessage({ id: LOCALIZATION.event_invalid_start_date_description }),
    },
  };
  
  return (
    <Alert
      type={ AlertType.Error }
      header={ ERROR_TEXTS[props.errorType].header }
      text={ ERROR_TEXTS[props.errorType].text }
      onClose={ props.onClose }
    />
  );
};


export default injectIntl(EventPopupErrorComponent);
