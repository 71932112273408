// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Colors } from "../../../../Configuration/Styles/Colors";

export const MAIN_CARD_SX = {
    borderRadius: '12px',
    background: Colors.mainBackground,
    boxShadow: '0px 4px 16px #00000014',
  };
  
 export const MAIN_CARD_ACTIVE_SX = {
    border: '4px solid #18A0FB',
  };