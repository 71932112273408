// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import UserWithoutID, { UserCameraAccess, UserTeamRefAccess, UserType } from './UserWithoutID';


type User = { id: string; lastLogin: string; } & UserWithoutID;

export const isServerUser = (user?: User | UserWithoutID) : boolean => {
    if (!user) {
        return false;
    }
    if (user.type === UserType.Server) {
        return true;
    }
    return false;
};


export const isUserHaveServerCameraAccess = (user?: User | UserWithoutID) : boolean => {
    if (!user || !isServerUser(user)) {
        return false;
    }
    if (user.cameraAccess === UserCameraAccess.All) {
        return true;
    }
    if (user.cameraAccess === UserCameraAccess.List && user.cameraIdList?.length) {
        return true;
    }
    return false;
};

export const isUserHaveCameraAccess = (user?: User | UserWithoutID) : boolean => {
    if (!user) {
        return false;
    }
    if (user.cameraAccess === UserCameraAccess.All) {
        return true;
    }
    if (user.cameraAccess === UserCameraAccess.Local && !isServerUser(user)) {
        return true
    }
    if (user.cameraAccess === UserCameraAccess.List && user.cameraIdList?.length) {
        return true;
    }
    return false;
};

export const isUserHaveServerAnalyticsAccess = (user?: User | UserWithoutID) : boolean => {
    if (!user || !isServerUser(user)) {
        return false;
    }
    if (user.teamRefAccess === UserTeamRefAccess.All || user.teamRefAccess === UserTeamRefAccess.Guest) {
        return true;
    }
    if (user.teamRefAccess === UserTeamRefAccess.List && user.teamRefIdList?.length) {
        return true;
    }
    return false;
};

export default User;
