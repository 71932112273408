// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { Box } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {  SxProps, Theme } from '@mui/material';

import VertGrid from '../../../../Components/_Layout/VertGrid';
import AnalyticsGameArchive, { AnalyticsGameShared } from '../../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import AnalyticsVertOppositeBarView from '../../../../Components/_ChartsAndBars/AnalyticsVertOppositeBarView';
import LeftRightCardHeader from '../../../../Components/_Layout/CardHeader/LeftRight';
import { useAnalyticsStatDistance } from '../../../../Data/Analytics/AnalyticsStatDistance/hook';
import { useAnalyticsStatSpeed } from '../../../../Data/Analytics/AnalyticsStatSpeed/hook';
import { useAnalyticsStatAttackDefenseTime } from '../../../../Data/Analytics/AnalyticsStatAttackDefenseTime/hook';
import { useAnalyticsStatPossession } from '../../../../Data/Analytics/AnalyticsStatPossession/hook';
import { createTeamMainReportBarChartConfig } from '../../../../Components/_ChartsAndBars/AnalyticsVertOppositeBarView/createTeamMainReportBarChartConfig';
import useFetchAllPagesTeamRefs from '../../../../Data/Analytics/AnalyticsTeamRef/hook';
import { ResultStatus } from '../../../../Data/_Networking/ReactQuery/template';
import Spinner from '../../../../Components/_BaseUI/Spinner/Spinner';

type Props = Readonly<{
  gameInfo: AnalyticsGameArchive | undefined
  sx?: SxProps<Theme>;
} & WrappedComponentProps>;

const AnalyticsVideoOverallTab: React.FC<Props> = (props: Props) => {
  const gameId = props.gameInfo?.id || ""

  const { data: distanceStat } = useAnalyticsStatDistance({ gameId });
  const { data: speedStat } = useAnalyticsStatSpeed({ gameId });
  const { data: possessionStat } = useAnalyticsStatPossession({ gameId });
  const { data: attackDefenseTimeStat } = useAnalyticsStatAttackDefenseTime({ gameId });
  
  const {
    getTeamRefByExternalId,
    status: teamRefStatus,
  } = useFetchAllPagesTeamRefs();
  
  if (!gameId) {
    return null;
  }

  if (teamRefStatus === ResultStatus.Loading) {
    return (
      <>
      <br /><br /><Spinner /><br /><br />
      </>
    )
  }

  const barChartData = createTeamMainReportBarChartConfig(
    props.gameInfo, 
    distanceStat?.game_stat, 
    speedStat?.game_stat, 
    possessionStat?.game_stat, 
    attackDefenseTimeStat?.game_stat,
    getTeamRefByExternalId,
  );

  return (
    <VertGrid
    spacing={ 1 }
    sx={{
      ...props.sx
    }}
    >
      <Box
        sx={ {
          marginLeft: -2,
          marginRight: -2,
      } }
      >
        <LeftRightCardHeader titleLeft={ getTeamRefByExternalId?.(props.gameInfo?.team1)?.name }  titleRight={ getTeamRefByExternalId?.(props.gameInfo?.team2)?.name }/>
      </Box>
      <Box
        sx={ {
            marginTop: -2,
            height: 380,
            minHeight: 380,
        } }
        >
        <AnalyticsVertOppositeBarView 
            data={ barChartData }
            team1Color={ AnalyticsGameShared.team1Color }
            team2Color={ AnalyticsGameShared.team2Color }
        />
      </Box>
    </VertGrid>
  );
};

export default injectIntl(AnalyticsVideoOverallTab);
