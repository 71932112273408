// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { formatBytes } from "../../../Tools/b2Mb";
import { CameraSettingsInterface } from "./CameraSettings";
import { totalSaveToSsdSpeedMBperSec } from "./CameraSettingsStreamingState";

export interface CameraSettingsMetrics {
    DCVoltage?: number;
  
    j1OnVoltage?: number;
    j1CpuUsePercent?: number;
    j1GpuUsePercent?: number;
    j1MainDiskUsePercent?: number;
    j1MainDiskSizeMB?: number;
  
    j2OnVoltage?: number;
    j2CpuUsePercent?: number;
    j2GpuUsePercent?: number;
    j2MainDiskUsePercent?: number;
    j2MainDiskSizeMB?: number;
  
    mainSsdDiskUsePercent?: number;
    mainSsdDiskSizeMB?: number;
  
    piCpuUsePercent?: number;
    piMainDiskUsePercent?: number;
    piMainDiskSizeMB?: number;
  };
  
  export function freeSpaceMB(diskSizeMB?: number, diskUsePercent?: number): number {
    if (diskSizeMB && diskUsePercent) {
      const diskFreeRatio = (100 - diskUsePercent) / 100.0
      return diskSizeMB * diskFreeRatio
    }
    return 0
  }
  
  export function ssdFreeSpaceString(cameraSettings?: CameraSettingsInterface): string {
    const ssdFreeSpaceMB = freeSpaceMB(cameraSettings?.lastMetricsData?.mainSsdDiskSizeMB, cameraSettings?.lastMetricsData?.mainSsdDiskUsePercent)
  
    let freeSpace = formatBytes( ssdFreeSpaceMB * 1048576)
  
    const totalSaveSpeedMBperSec = totalSaveToSsdSpeedMBperSec(cameraSettings?.streamingState)
    if (ssdFreeSpaceMB !== 0 && totalSaveSpeedMBperSec !== 0) {
      const timeLeftSec = ssdFreeSpaceMB / totalSaveSpeedMBperSec
  
      var date = new Date(0);
      date.setSeconds(timeLeftSec); // specify value for SECONDS here
      var timeString = `${date.getHours()}h : ${date.getMinutes()}min`
  
      freeSpace += ` (${timeString})`
    }
  
    // 200 GB (2h 50min)
    return freeSpace
  }