// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { Grid } from '@mui/material';

import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import PowerChartView from './Chart'
import { usePowerValue } from './Hooks'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content'
import { useIntl } from 'react-intl';
import LOCALIZATION from '../../../../Localization';
import { useCameraData } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks';
import { CameraSettingsInterface } from '../../../../Data/Camera/HWCameraSettings/CameraSettings';
import { isCameraSettingsOptionActive } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsActiveOptions';


const CardHeader: React.FC = () => {
  const intl = useIntl();
  let title = intl.formatMessage({ id: LOCALIZATION.chart_voltage_name })
  const voltage = usePowerValue()
  const deviceStates = useCameraData()?.settings?.deviceStates

  if (voltage) {
    title += ': ' + voltage.toFixed(2) + 'V'
  }
  if (deviceStates) {
    const powerType = deviceStates.mainDC !== "AC" ? intl.formatMessage({ id: LOCALIZATION.power_source_battery }) : intl.formatMessage({ id: LOCALIZATION.power_source_adapter })
    title += ' (' + powerType + ')'
  }
  return (
    <RegularCardHeader title={ title }/>
  )
};

type Props = Readonly<{
  settings?: CameraSettingsInterface
}>;

const PowerCard: React.FC<Props> = (props: Props) => {
  if (props.settings && !isCameraSettingsOptionActive(props.settings?.activeOptions?.haveInputVoltmeter)) {
    return null;
  }

  return (
    <MainCard>
      <CardHeader/>
      <MainCardContent>
        <Grid
          sx={ { height: 300 } }
        >
          <PowerChartView/>
        </Grid>
      </MainCardContent>
    </MainCard>
  )
}


export default PowerCard;
