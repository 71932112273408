// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Autocomplete } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';

import StreamingProfile from '../../../Data/NapiStreamingProfile';
import { useFetchStreamingProfiles } from '../../../Data/NapiStreamingProfile/hook';


type Props = Readonly<{
  selectedStreamProfileID?: string | null;
  autoselectFirstIfNoId?: boolean;
  onSelect: (streamProfileID: string | null) => void;
}>;

type SelectItem = {
    id: string;
    name: string;
};

const useStyles = makeStyles(() => ({
  inputRoot: {
    height: '38px',
    lineHeight: '38px',
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // paddingTop: 0,
      // paddingBottom: 0,
      marginTop: '-8px', // hack to make edit 38px position correct on safari and chrome. on chrome also work padding 0
    },
    '& .MuiAutocomplete-endAdornment': {
      marginTop: '-5px',
    },
  }
}));

const StreamAutocompleteSelect: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  useFetchStreamingProfiles();
  const streamingProfilesData = useFetchStreamingProfiles();

  const options: SelectItem[] = (streamingProfilesData?.data?.data || [])
    .map(({ id, name }) => ({
      id,
      name: name,
    }));

  const selectedStream = (props.selectedStreamProfileID) ?
    options.find(({ id }) => (id === props.selectedStreamProfileID))
    :
    null;

  const handleSelect = (_event: any, newStream: SelectItem | null) => {
    if (!newStream) {
        props.onSelect(null);
    } else {
      const stream: StreamingProfile | undefined = (streamingProfilesData?.data?.data || []).find(({ id }) => (id === newStream.id));

      if (stream && stream?.id) {
        props.onSelect(stream.id);
      }
    }
  };


  React.useEffect(() => {
    try {
        if (props.autoselectFirstIfNoId && (props.selectedStreamProfileID === undefined) && streamingProfilesData?.data?.data.length) {
          const defaultId = streamingProfilesData?.data?.data[0]?.id;
          if (defaultId) {
            props.onSelect(defaultId);
          }
        }
    } catch (ignore) {
    }
    // eslint-disable-next-line
  }, [streamingProfilesData]);

  return (
    <Autocomplete
      options={ options }
      renderInput={params => (
        <TextField
          { ...params }
          variant="outlined"
        />
      )}
      classes={ classes }
      getOptionLabel={ option => option.name }
      style={ { height: '38px' } }
      value={ selectedStream }
      onChange={ handleSelect }
    />
  );
};


export default StreamAutocompleteSelect;
