// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import AuthorisationManager from '../Data/Auth/AuthorisationManager';

const getAccessToken = (): string | null => {
    try {
        const accessToken = AuthorisationManager.shared.accessToken;

        if (!accessToken)
            return null

        return accessToken
    } catch (ignore) {
        return null
    }
};

const setAccessToken = (newAccessToken: string) => {
    try {
        AuthorisationManager.shared.accessToken = newAccessToken;
    } catch (ignore) {
    }
};


export {
    getAccessToken,
    setAccessToken
};
