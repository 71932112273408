// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useMutation } from 'react-query';

import { postData, getKey } from '../fetch/index';
import { queryClient } from '../../../AppProviders';
import { StreamingSettings } from '..';


export enum ActionType {
  Post = 'post',
}

export type Action = Readonly<{
  settings?: Partial<StreamingSettings>;
  type: ActionType;
}>;


const useMutationNapiSettings = () => {
  return useMutation(
    (action: Action) => {
      switch (action.type) {
        case ActionType.Post: {
          return postData(action.settings as Partial<StreamingSettings>);
        }
        default:
          return Promise.reject(new Error('Invalid Settings Mutation Action'));
      }
    },
    {
      onSuccess: (data) => {
        if (data.error) {
          throw new Error(data.error);
        } else {
          queryClient.setQueryData(
              getKey(),
              data,
          );
        }
      },
    },
  );
};


export default useMutationNapiSettings;
