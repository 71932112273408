// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';


type Props = Readonly<{
  className?: string;
  text: string;
  statusColor?: string;
}>;


const CardHeaderStatus: React.FC<Props> = (props: Props) => (
  <Grid
    item
  >
    <div
      className={ props.className }
      style={ { color: props.statusColor } }
    >
      { props.text }
    </div>
  </Grid>
);


export default CardHeaderStatus;
