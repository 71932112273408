// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

export function useEffectAfterMount(effect: React.EffectCallback, deps: React.DependencyList) {
    const isMounted = React.useRef(false);
  
    React.useEffect(() => {
      if (isMounted.current) return effect();
      else isMounted.current = true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
  
    // reset on unmount; in React 18, components can mount again
    React.useEffect(() => () => {
      isMounted.current = false;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  }