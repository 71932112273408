// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import StorageRender from './StorageRender';
import { useMutationStorageInfo, ActionType } from '../../Data/NapiStorageInfo/hook/useMutation';


type Props = Readonly<{
  sizeInGB: number;
  freeInGB: number;
  removeOldVideoWhenStorageLessThenGB: number;
}>;


const Storage: React.FC<Props> = (props: Props) => {
  const { mutateAsync: mutateStorage } = useMutationStorageInfo();

  const handleRemoveOldVideoWhenStorageLessThenGBChanged = async (removeOldVideoWhenStorageLessThenGB: number) => {
    try {
      await mutateStorage({
        type: ActionType.Post,
        settings: { removeOldVideoWhenStorageLessThenGB },
      });
    } catch (error) {
      alert(error);
    }
  }

  return (
    <StorageRender
      sizeInGB={ props.sizeInGB || 0 }
      freeInGB={ props.freeInGB || 0 }
      removeOldVideoWhenStorageLessThenGB={ props.removeOldVideoWhenStorageLessThenGB || 0 }
      onChange={ handleRemoveOldVideoWhenStorageLessThenGBChanged }
    />
  );
};


export default Storage;
