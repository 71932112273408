// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useState, useEffect } from 'react';

import User from '../User';
import AuthorisationManager from '../../Auth/AuthorisationManager';


const useCurrentUser = (): User | undefined => {
    const [user, setUser] = useState<User | undefined>(AuthorisationManager.shared.user)

    useEffect(() => {
        function handleDataChange(user?: User) {
            setUser(user)
        }

        AuthorisationManager.shared.subscribeOnUserChanges(handleDataChange)
        return () => {
            AuthorisationManager.shared.unsubscribeFromUserChanges(handleDataChange)
        }
    })

    return user
};


export default useCurrentUser;
