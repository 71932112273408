// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: { position: "relative" },
  wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    "& > *": { height: "100%", width: "100%" }
  }
}));

type Props = Readonly<{
    children?: any;
    ratio?: number;
    maxWidth?: number;
  }>;

const AspectRatioBox: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const ratio = props.ratio || 16 / 9;
  return (
    <Box sx={{
      width: '100%',
      maxWidth: props.maxWidth,
      marginLeft: 'auto',
      marginRight: 'auto',
    }}>
      <Box className={classes.root}>
        <Box className={classes.wrapper}>{props.children}</Box>
        <Box style={{ paddingBottom: (1 / ratio) * 100 + "%" }} />
      </Box>
    </Box>
  );
};

export default AspectRatioBox;