// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

const uuid = (index?: number) => {
  let seed = Date.now() + (index || 0);

  if (window.performance && typeof window.performance.now === "function") {
      seed += performance.now();
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = ((seed || 0) + Math.random() * 16) % 16 | 0;
    seed = Math.floor(seed/16);

    return (c === 'x' ? r : r & (0x3|0x8)).toString(16);
  })
};


export default uuid;
