// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { Key } from 'react';
import moment from 'moment';
import { WrappedComponentProps } from 'react-intl';

import Archive, { getGameTitle } from '../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';

import { Box, Grid, ButtonBase, alpha } from '@mui/material';
import { useAppConfig } from '../../Localization/AppContextProvider/helpers';
import CameraStreamPreview from '../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import { intl } from '../../Localization/LocalizationProvider';
import LOCALIZATION from '../../Localization';
import VertGrid from '../../Components/_Layout/VertGrid';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';

import dayjs from 'dayjs';
import { Fonts } from '../../Configuration/Styles/Fonts';
import { Sizes } from '../../Configuration/Styles/Sizes';
import { Colors } from '../../Configuration/Styles/Colors';
import MenuDropdownButton from '../../Components/_BaseUI/MenuDropdownButton';
import { FileType } from '../../Data/VideoArchive';
import { AnalyticsAnalyticsStatus, analyticsStatusName } from '../../Data/Analytics/AnalyticsStatuses';
import { getMenuLink } from '../../Menu/Data/MenuStructure';
import { MenuID } from '../../Menu/Data/MenuElement';
import { AnalyticsConfig, makeAnalyticsVideoImageUrl } from '../../Data/Analytics/AnalyticsConfig/AnalyticsConfig';
import { localizedAnalyticsSportNameById } from '../../Data/Analytics/AnalyticsTypes';
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
require('dayjs/locale/ru')

export type Props = Readonly<{
  id: Key;
  hideDate: boolean;
  item: Archive;
  analyticsConfig: AnalyticsConfig | undefined;
  onEdit: (itemId: string, item?: Archive) => void;
  onRename: (itemId: string, item?: Archive) => void;
  onDelete: (itemId: string, item?: Archive) => void;
  onStartTeamMarkup: (itemId: string, item?: Archive) => void;
  onStartAnalytics: (itemId: string, item?: Archive) => void;
} & WrappedComponentProps>;


const GameArchiveItem: React.FC<Props> = (props: Props) => {
  const dateStart = moment(props.item.start);
  const dateEnd = moment(props.item.end);
  const { localization: { locale } } = useAppConfig();

  dateStart.locale(locale);
  dateEnd.locale(locale);
  dayjs.locale(locale);
  const handleEdit = () => {
    props.onEdit(props.item.id, props.item);
  };
  const handleRename = () => {
    props.onRename(props.item.id, props.item);
  };
  const handleDelete = () => {
    props.onDelete(props.item.id, props.item);
  };
  const imageUrl = () => (
    makeAnalyticsVideoImageUrl(props.analyticsConfig, props.item.preview)
  )
  const handleTeamMarkup = () => {
    props.onStartTeamMarkup(props.item.id, props.item);
  };
  const handleStartAnalytics = () => {
    props.onStartAnalytics(props.item.id, props.item);
  };

  // const videoUrl = () => (
  //   cameraSettings?.urls?.archiveVideoPlayerTemplate?.replace("{video_dir}", props.item.video || "")
  // )

  // const downloadVideoSaveFileName: string = (() => {
  //   const start = moment(props.item.start);
  //   let name = `${ start.format('YYYY-MM-DD_hh-mm') }_${ props.item.title }`
  //   let name2 = name.replace(/[^\p{L}0-9.-]/giu, '_')
  //   return name2
  // })()

  const startAt = dayjs(props.item.start);
  const endAt = dayjs(props.item.end);

  const videoDuration: string = (() => {
    const diffMinutes = endAt.diff(startAt, 'minutes');
    return `${diffMinutes} ${intl().formatMessage({ id: LOCALIZATION.minutes })}`
  })()

  const itemScore: string = (() => {
    return `${props.item?.score?.team1 || 0} - ${props.item?.score?.team2 || 0}`
  })()

  const itemState: string = (() => {
    return props.intl.formatMessage({ id: LOCALIZATION.analytics_state_analytics }) + ": "
  })()

  const itemStateValue = analyticsStatusName(props.item?.analytics_status, props.item?.analytics_progress);

  const itemStateColor: string = (() => {
    switch (props.item?.analytics_status) {
      case AnalyticsAnalyticsStatus.Done: {
        return Colors.mainGreen
      }
      case AnalyticsAnalyticsStatus.Cancelled: {
        return Colors.mainRed
      }
      case AnalyticsAnalyticsStatus.Progress: {
        return Colors.mainRed
      }
      case AnalyticsAnalyticsStatus.NotStarted: {
        return Colors.textBlue
      }
      case AnalyticsAnalyticsStatus.WaitingTeamMapping: {
        return Colors.mainRed
      }
    }
    return Colors.mainRed
  })()

  const navigate = useNavigate();

  const handleItemClickBtn = () => {
    const menuLink = getMenuLink(MenuID.AnalyticsGameReport, [props.item.id]);
    if (menuLink) {
      navigate(menuLink);
    }
  };

  return (
    <Grid item key={ props.id } {...{ xs: 12, sm: 6, md: 6, lg: 4 }}>
      <ButtonBase 
        component="div" 
        onClick={ handleItemClickBtn }
        sx={{
          '&:hover': {
            backgroundColor: alpha(Colors.menuDefaultHover,0.15),
            border: 'none',
          },
        }}
      >
        <VertGrid>
            <Box
              sx={ {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              } }
            >
              <MenuDropdownButton<Archive>
                item={ props.item as Archive }
                menuButtonIcon= { MenuIcon }

                customActions={[
                  {
                    title: props.intl.formatMessage({ id: LOCALIZATION.rename }),
                    icon: DriveFileRenameOutlineIcon,
                    onMenuClick: handleRename
                  },
                  {
                    title: props.intl.formatMessage({ id: LOCALIZATION.game_video }),
                    icon: VideoSettingsIcon,
                    onMenuClick: handleEdit
                  },
                  {
                    title: props.intl.formatMessage({ id: (props.item.analytics_status === AnalyticsAnalyticsStatus.NotStarted) ? LOCALIZATION.analytics_game_state_not_started : LOCALIZATION.analytics_team_mapping }),
                    icon: (props.item.analytics_status === AnalyticsAnalyticsStatus.NotStarted) ? QueryStatsIcon : CallSplitIcon,
                    onMenuClick: (props.item.analytics_status === AnalyticsAnalyticsStatus.Done || props.item.analytics_status === AnalyticsAnalyticsStatus.WaitingTeamMapping) ? handleTeamMarkup : (props.item.analytics_status === AnalyticsAnalyticsStatus.NotStarted) ? handleStartAnalytics : undefined
                  },
                ]}

                onDelete={ handleDelete }
              />
              <Box
                sx={ {
                  fontFamily: Fonts.main,
                  fontSize: Sizes.title,
                  fontWeight: Sizes.boldWeight,
                  color: Colors.headingMain,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                } }
              >
                { getGameTitle(props.item) }
              </Box>
            </Box>
            <Box
              sx={ {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              } }
            >
              <CameraStreamPreview
                            fileType={ FileType.Panorama }
                            previewImageUrl={ imageUrl() }
                            // video={ videoUrl() } // we did not play video but open details on click
                          />
            </Box>
            <Box
              sx={ {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: 1,
              } }
            >
              <Box
                key={ 1 }
                sx={ {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  fontFamily: Fonts.main,
                  fontSize: Sizes.title,
                  fontWeight: Sizes.regularWeight,
                  color: Colors.black,
                } }
              >
                { ((props.hideDate) ? "" : `${ dateStart.format('dd l').toUpperCase() }, `) + `${ dateStart.format('LT') }, ${ videoDuration }` }
              </Box>
              <Box
                  key={ 2 }
                  sx={{ 
                    minWidth: 5,
                    flexGrow: 1 
                  }}/>
              <Box
                key={ 3 }
                sx={ {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  fontFamily: Fonts.main,
                  fontSize: Sizes.heading,
                  fontWeight: Sizes.mediumWeight,
                  color: Colors.black,
                } }
              >
                { itemScore }
              </Box>
            </Box>

            <Box
              sx={ {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: '1pt',
                fontFamily: Fonts.main,
                fontSize: Sizes.title,
                fontWeight: Sizes.regularWeight,
                color: Colors.black,
              } }
            >
              { (!!props.item.subtitle?.length) ? `${localizedAnalyticsSportNameById(props.item.sport_type)}: ${(props.item.subtitle === 'Unknown') ? props.intl.formatMessage({ id: LOCALIZATION.unknown }) : props.item.subtitle}` : localizedAnalyticsSportNameById(props.item.sport_type) }
            </Box>

            <Box
              sx={ {
                fontFamily: Fonts.main,
                fontSize: Sizes.title,
                fontWeight: Sizes.boldWeight,
                paddingTop: '1pt',
            } }
            >
              <Box
                component="span"
                key={ 1 }
                sx={{
                  color: Colors.black,
                }}
              >
                { itemState }
              </Box>
              <Box
                component="span"
                key={ 3 }
                sx={{
                  color: itemStateColor,
                }}
              >
                { itemStateValue }
              </Box>
            </Box>
        </VertGrid>
      </ButtonBase>
    </Grid>
  );
};


export default GameArchiveItem;
