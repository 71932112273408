// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Tooltip } from '@mui/material';
import { Help } from '@mui/icons-material';


type Props = Readonly<{
  toolTip: string;
}>;


const QuestionTooltip: React.FC<Props> = (props: Props) => (
  <Tooltip
    title={ props.toolTip }
  >
    <Help
      style={ {
        display: 'inline',
        width: '15px',
        height: '15px',
        cursor: 'pointer',
        fill: '#18A0FB',
      } }
    />
  </Tooltip>
);


export default QuestionTooltip;
