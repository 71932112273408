// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import { ResultStatus } from '../../../_Networking/ReactQuery/template';
import { AnalyticsGameEvent } from '../AnalyticsGameEvent';
import {
  useFetch,
  flushCache,
  getCached,
} from '../fetch';

type Props = Readonly<{
  parentGameId?: string;// use to filter all games connected to same video
}>;

type Result = Readonly<{
  status: ResultStatus;
  data: ResponseMany<AnalyticsGameEvent[]> | undefined;
  handleFlushData: () => void;
}>;


const useGameEventsPagedData = (props: Props): Result => {
  const fetchedArchive = useFetch({
    parentGameId: props.parentGameId,
    enableToFetch: true,
  });
  const data = getCached({
    parentGameId: props.parentGameId,
  });

  return ({
    status: fetchedArchive.status,
    data,
    handleFlushData: flushCache,
  });
};


export default useGameEventsPagedData;
