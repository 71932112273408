// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { ObservableDataProvider } from '../../../Common/ObservableDataProvider'
import MetricsApiService from '../Networking'
import { MetricDouble3DInterface, MetricRequestSortParametersInterface, MetricRequestParametersInterface } from '../Types'
import { MagnetometerAxis, MagnetometerCalibrationInterface, MagnetometerCalibrationXPair, MagnetometerCalibrationYPair, MagnetometerCalibrationZPair } from './Types'

export interface CalibrationDataProviderInterface {
    readonly calibration: MagnetometerCalibrationInterface
    startCalibrating(axis: MagnetometerAxis): void
    stopCalibrating(): void
}

export class CalibrationDataProvider extends ObservableDataProvider<MagnetometerCalibrationInterface> implements CalibrationDataProviderInterface {
    static shared = new CalibrationDataProvider()

    get name(): string {
        return 'Calibration'
    }

    private zPoints: MagnetometerCalibrationZPair[] = []
    private yPoints: MagnetometerCalibrationYPair[] = []
    private xPoints: MagnetometerCalibrationXPair[] = []

    private calibratingAxis: MagnetometerAxis | undefined

    private apiService = new MetricsApiService()
    data?: MagnetometerCalibrationInterface = { x: 0, y: 0, z: 0, zPoints: [], yPoints: [], xPoints: [] }
    get calibration(): MagnetometerCalibrationInterface {
        return this.data!
    }

    get updateInterval(): number {
        return 500
    }

    startCalibrating(axis: MagnetometerAxis) {
        this.calibratingAxis = axis
        this.zPoints = []
        this.yPoints = []
        this.xPoints = []
    }

    stopCalibrating() {
        this.calibratingAxis = undefined
    }

    async update() {
        // For debug issues
        // let accel: MetricDouble3DInterface | undefined
        let mag: MetricDouble3DInterface | undefined
        let accel: MetricDouble3DInterface | undefined

        // console.log('MetricsChartDataProvider > will update accel values')
        // try {
        //     const sortParameters: MetricRequestSortParametersInterface = {
        //         sort: 'whenCreated',
        //         direction: 'desc'
        //     }

        //     const parameters: MetricRequestParametersInterface = {
        //         ...sortParameters,
        //         filter_name: 'Accelerometer',
        //         max: 1
        //     }
        //     const metrics = await this.apiService.get3DMetric(parameters)
        //     if (metrics.length === 1) {
        //         accel = metrics[0]
        //         console.log(`MetricsChartDataProvider > did update accel values: x = ${accel.x}, y = ${accel.y}, z = ${accel.z}`)
        //     } else {
        //         console.log('MetricsChartDataProvider > too many items')
        //     }
        // } catch (error) {
        //     console.log('MetricsChartDataProvider > failed to fetch metrics')
        // }

        // console.log('MetricsChartDataProvider > will update mag values')
        try {
            const sortParameters: MetricRequestSortParametersInterface = {
                sort: 'whenCreated',
                direction: 'desc'
            }

            const parameters: MetricRequestParametersInterface = {
                ...sortParameters,
                filter_name: 'Magnetometer',
                max: 1
            }
            const accelParameters: MetricRequestParametersInterface = {
                ...sortParameters,
                filter_name: 'Accelerometer',
                max: 1
            }
            const metrics = await this.apiService.get3DMetric(parameters)
            const accelMetrics = await this.apiService.get3DMetric(accelParameters)

            if (accelMetrics.length === 1) {
                accel = accelMetrics[0]
                // console.log(`MetricsChartDataProvider > did update accel values: x = ${accel.x}, y = ${accel.y}, z = ${accel.z}`)
            }

            if (metrics.length === 1) {
                mag = metrics[0]
                if (this.calibratingAxis === 'Z') {
                    this.zPoints.push({
                        x: mag.x,
                        y: mag.y
                    })
                }

                if (this.calibratingAxis === 'X') {
                    this.xPoints.push({
                        y: mag.y,
                        z: mag.z
                    })
                }

                if (this.calibratingAxis === 'Y') {
                    this.yPoints.push({
                        x: mag.x,
                        z: mag.z
                    })
                }
                // if (mag.x > this.max.x) {
                //     this.max.x = mag.x
                // } else if (mag.x < this.min.x) {
                //     this.min.x = mag.x
                // }

                // if (mag.y > this.max.y) {
                //     this.max.y = mag.y
                // } else if (mag.y < this.min.y) {
                //     this.min.y = mag.y
                // }

                // if (mag.z > this.max.z) {
                //     this.max.z = mag.z
                // } else if (mag.z < this.min.z) {
                //     this.min.z = mag.z
                // }

                this.data = {
                    ...this.calibration,
                    zPoints: this.zPoints.slice(),
                    yPoints: this.yPoints.slice(),
                    xPoints: this.xPoints.slice()
                }
                // console.log(`MetricsChartDataProvider > did update mag values: x = ${mag.x}, y = ${mag.y}, z = ${mag.z}`)
            } else {
                // console.log('MetricsChartDataProvider > too many items')
            }
        } catch (error) {
            // console.log('MetricsChartDataProvider > failed to fetch metrics')
        }

        if (mag !== undefined && accel !== undefined) {
            // console.log('MetricsChartDataProvider > mag and accel arrived')
            const MPU9250Declination = -8

            // ACCEL: Z -> Y * точно
            // ACCEL: Y -> -X *
            // ACCEL: X -> -Z *

            // MAG: Z -> -X * точно
            // MAG: Y -> Z
            // MAX: X -> -Y

            let accelX = -accel.z
            let accelY = -accel.x
            let accelZ = accel.y

            let magX = -mag.y
            let magY = mag.z
            let magZ = -mag.x

            const accelLength = Math.sqrt(accelX * accelX + accelY * accelY + accelZ * accelZ)

            if (accelLength > 0) {
                accelX = accelX / accelLength
                accelY = accelY / accelLength
                accelZ = accelZ / accelLength
            }

            const magLength = Math.sqrt(magX * magX + magY * magY + magZ * magZ)

            if (magLength > 0) {
                magX = magX / magLength
                magY = magY / magLength
                magZ = magZ / magLength
            }

            const pitch = Math.asin(accelY)
            const roll = -Math.asin(accelX / Math.cos(pitch))

            const Yh = magZ * Math.sin(roll) - magY * Math.cos(roll)
            const Xh = magX * Math.cos(pitch) + magY * Math.sin(pitch) * Math.sin(roll) + magZ * Math.sin(pitch) * Math.cos(roll)

            let az = Math.atan2(Yh, Xh) * 180 / Math.PI
            az = az >= 0 ? az : 360 + az
            az = az + MPU9250Declination
            if (az > 360) {
                az = az - 360
            } else if (az < 0) {
                az = az + 360
            }

            // const yaw = az
            // console.log(`MetricsChartDataProvider > values: roll = ${roll * 180 / Math.PI}, pitch = ${pitch * 180 / Math.PI}`)

            // console.log(`MetricsChartDataProvider > values: Y = ${magY}, X = ${magX}`)

            // console.log(`MetricsChartDataProvider > values: Yh = ${Yh}, Xh = ${Xh}`)
            // console.log(`MetricsChartDataProvider > values: yaw = ${yaw}`)

            // console.log(`MetricsChartDataProvider > values: x = [${this.min.x}...${this.max.x}]`)
            // console.log(`MetricsChartDataProvider > values: y = [${this.min.y}...${this.max.y}]`)
            // console.log(`MetricsChartDataProvider > values: z = [${this.min.z}...${this.max.z}]`)
        }
    }
}
