// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import {  SxProps, Theme } from '@mui/material';

import { injectIntl, WrappedComponentProps } from 'react-intl';
import SortedTable from '../../../../Components/SortedTable';

import dayjs from 'dayjs';
import { EMPTY_ARRAY } from '../../../../constants';
import Order from '../../../../Data/_Networking/ReactQuery/Order';
import { headCells, getRenderCellContent, useRowStyles } from './renderCellContent';
import { AnalyticsGameEvent, AnalyticsGameEventWithId } from '../../../../Data/Analytics/AnalyticsGameEvent/AnalyticsGameEvent';
import ResponseMany from '../../../../Data/_Networking/ReactQuery/ResponseMany';
import { AnalyticsSportType } from '../../../../Data/Analytics/AnalyticsTypes';
import { timeToSeconds } from '../../../../Components/_BaseUI/AnalyticsTimeField';
import { VideoPlayerCommands } from '../../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
require('dayjs/locale/ru')

type Props = Readonly<{
  sx?: SxProps<Theme>;
  sportType?: AnalyticsSportType;
  events: AnalyticsGameEvent[] | undefined;
  stickyHeader?: boolean;
  videoPlayerCommands: VideoPlayerCommands | undefined
  team1Name: string  | undefined;
  team2Name: string  | undefined;
} & WrappedComponentProps>;

const AnalyticsVideoEventsTableTab: React.FC<Props> = (props: Props) => {
  // const classes = useStyles();
  const rowClasses = useRowStyles();
  
  const [page] = React.useState(0);
  const [limit, setLimit] = React.useState(1000);
  const [orderBy, setOrderBy] = React.useState<keyof AnalyticsGameEventWithId>('timestamp');
  const [order, setOrder] = React.useState<Order>(Order.ASC);
  const [selected, setSelected] = React.useState<AnalyticsGameEventWithId[]>(EMPTY_ARRAY);

  // console.log('status:', status);

  const handlePeerPageChange = (newLimit: number) => {
    setSelected(EMPTY_ARRAY);
    setLimit(newLimit);
  };

  const handleRequestSort = (newOrderField: keyof AnalyticsGameEventWithId) => {
    if (orderBy === newOrderField) {
      setOrder((order === Order.ASC) ? Order.DESC : Order.ASC);
    }

    setOrderBy(newOrderField);
  };
  const handleRowItemClicked = (item: AnalyticsGameEventWithId): void => {
  };


  const reindexEventsWithId = (events?: (AnalyticsGameEvent | AnalyticsGameEventWithId)[]): AnalyticsGameEventWithId[] => {
    return events?.map( (event , index) => {
      return {
        ...event,
        id: index + 1,
      }
    }) || []
  };

  const eventsWithIndexes: ResponseMany<AnalyticsGameEventWithId[]> = {
    data: reindexEventsWithId(props.events),
    meta: {
      status: 200
    }
  }

  const renderCellContent = getRenderCellContent({
    sportType: props.sportType,
    rowClasses: rowClasses,
    events: eventsWithIndexes.data,
    onVideoStart(item: AnalyticsGameEventWithId) {
      const timeSec = timeToSeconds(item.timestamp)      
      props.videoPlayerCommands?.play(timeSec)
    },
    team1Name: props.team1Name,
    team2Name: props.team2Name,
  })


  return (
    <SortedTable<AnalyticsGameEventWithId>
      sx={{
        ...props.sx,
        // maxHeight: 220,
        // height: 220,
        // border: `solid 1px ${ Colors.mainGrey }`
      }}
      noTopPaddingWhenOnlyOnePage={true}
      stickyHeader={ props.stickyHeader }
      page={ page }
      rowsPerPage={ limit }
      // rowsPerPageOptions={ [5, 10, 25, 50, 100] }
      orderBy={ orderBy }
      order={ order }
      headCells={ headCells() }
      selected={ selected }
      manyItems={ eventsWithIndexes }
      renderCellContent={ renderCellContent }
      onPageChange={ () => {} }
      onPeerPageChange={ handlePeerPageChange }
      onRowClick={ handleRowItemClicked }
      onRequestSort={ handleRequestSort }
    />
  )
};

export default injectIntl(AnalyticsVideoEventsTableTab);
