// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useState, useEffect } from 'react'
import { MetricsDataHelper } from './Helper'
import { MetricsChartDataProvider } from './Charts/DataProvider'
import { MetricsDataProviderInterface, MetricsOwnerInterface } from './DataProvider'
import { maximumValueForMetricKeysCollection, minimumValueForMetricKeysCollection } from './Tools'
import { MetricsChartSourceInterface, MetricsDataItem, MetricsSourceInterface, MetricsValuesDataProviderUniqueKey } from './Types'
import { useMetricValuesData } from './MetricsValuesHook'
import { FormattedMetricValueOptions, formattedMetricValue } from '../../../Tools/Tools'

interface MetricsChartRangeInterface {
    ticks?: number[]
    minumum: number
    maximum: number
}

export interface MetricsChartValuesRangeInterface extends MetricsChartRangeInterface {
    decimalPartLength: number
}

export interface MetricsChartTimeRangeInterface extends MetricsChartRangeInterface {

}

export interface MetricsChartData {
    readonly valuesRange?: MetricsChartValuesRangeInterface
    readonly timeRange?: MetricsChartTimeRangeInterface
    readonly items: MetricsDataItem[]
}

class MetricsChardDataProviderOwner implements MetricsOwnerInterface {
    createDataProvider(source: MetricsSourceInterface): MetricsDataProviderInterface {
        // тут мы имеем дело с экземпляром MetricsChartSourceInterface
        return new MetricsChartDataProvider(source as MetricsChartSourceInterface)
    }
}

function calculateMetricsChartTimeRange(items: MetricsDataItem[]): MetricsChartTimeRangeInterface | undefined {
    const count = items.length

    if (items.length === 0) { return undefined }
    const min = items[0].date
    const max = items[count - 1].date
    let ticks: number[] = []

    if (count === 1) {
        ticks = [min]
    } else {
        const ticksCount = 5
        const everyTickIndex = Math.round(count / ticksCount)
        ticks = items.map(item => item.date).filter((_value, index, _array) => {
            return index % everyTickIndex === 0
        })
        ticks.push(max)
    }

    const range: MetricsChartTimeRangeInterface = {
        minumum: min,
        maximum: max,
        ticks: ticks
    }

    return range
}

function calculateMetricsChartValuesRange(items: MetricsDataItem[]): MetricsChartValuesRangeInterface | undefined {
    function nearestSignificantHighValue(value: number, significantValue: number): number {
        return Math.trunc((value + significantValue) / significantValue) * significantValue
    }

    function nearestSignificantLowValue(value: number, significantValue: number): number {
        return Math.ceil((value - significantValue) / significantValue) * significantValue
    }

    function calculateSignificantChange(min: number, max: number): number {
        const diff = max - min
        if (diff < 0.1) {
            return 0.1
        } else if (diff < 0.5) {
            return 0.5
        } else if (diff < 1) {
            return 1
        } else {
            return Math.max(Math.round(Math.floor(diff) / 4.0), 1)
        }
    }

    // // Подсчитаем в автоматическом режиме наиболее подходящие верхние и нижние границы, если они явно не заданы.
    if (items.length > 0) {
        const min = items.map(item => { return minimumValueForMetricKeysCollection(item) }).reduce((result, value) => value < result ? value : result)
        const max = items.map(item => { return maximumValueForMetricKeysCollection(item) }).reduce((result, value) => value > result ? value : result)

        const significantChangeValue = calculateSignificantChange(min, max)
        let decimalPartLength: number = 0

        // Подберем количество знаков после запятой
        if (significantChangeValue < 0.5) {
            decimalPartLength = 2
        } else if (significantChangeValue < 1) {
            decimalPartLength = 1
        }

        const minimumValue = nearestSignificantLowValue(min, significantChangeValue)
        const maximumValue = nearestSignificantHighValue(max, significantChangeValue)

        // на данном этапе max & min кратны significantValue.

        const diff = maximumValue - minimumValue

        if (diff <= significantChangeValue) {
            return {
                decimalPartLength: decimalPartLength,
                maximum: maximumValue,
                minumum: minimumValue
            }
        } else {
            const ticks: number[] = []
            let value = minimumValue
            while (value <= maximumValue) {
                ticks.push(value)
                value += significantChangeValue
            }
            return {
                decimalPartLength: decimalPartLength,
                ticks: ticks,
                maximum: maximumValue,
                minumum: minimumValue
            }
        }
    }
    return undefined
}

export function useMetricChartData(source: MetricsChartSourceInterface): MetricsChartData {
    const owner = new MetricsChardDataProviderOwner()
    const items = MetricsDataHelper.items(source, owner)

    const [data, setData] = useState<MetricsChartData>({
        items: items,
        valuesRange: calculateMetricsChartValuesRange(items),
        timeRange: calculateMetricsChartTimeRange(items)
    })

    useEffect(() => {
        function handleDataChange(items: MetricsDataItem[]) {
            setData({
                items: items,
                valuesRange: calculateMetricsChartValuesRange(items),
                timeRange: calculateMetricsChartTimeRange(items)
            })
        }

        MetricsDataHelper.subscribeToMetricsChange(source, handleDataChange, owner)
        return () => {
            MetricsDataHelper.unsubscribeFromMetricsChange(source, handleDataChange)
        }
    })

    return data
}

// Получаем данные на основе фильтров графиков для вывода текстом, например в хидерах
export function useMetricsChartTextValue(source: MetricsChartSourceInterface, uniqueKey: MetricsValuesDataProviderUniqueKey): string | undefined {
    // Подготовим метрики
    const metrics = source.metrics
    const data = useMetricValuesData({
        metrics: source.metrics,
        uniqueKey: uniqueKey,
        defaultSelected: [] // при получении значений мы не выбираем значения. Пока. Поэтому []
    })

    if (data.items.length === 1) {
        const item = data.items[0]
        const texts = metrics.map(metric => {
            const value = item[metric.dataKey]

            const opts: FormattedMetricValueOptions = {
                multiplyUnit: metric.identificationParameters.filter_name === 'Humidity' ? false : undefined,
                name: metric.name
            }

            return formattedMetricValue(value, metric.unit, opts)
        }).filter(text => !!text)

        if (texts.length > 0) {
            return texts.join(' | ')
        } else {
            return undefined
        }
    }

    return undefined
}
