// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import {  Box, SxProps, Theme } from '@mui/material';

import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import { injectIntl, WrappedComponentProps } from 'react-intl';
import LOCALIZATION from '../../../../Localization';
import { createStyles, makeStyles } from '@mui/styles';
import VertGrid from '../../../../Components/_Layout/VertGrid';
import AnalyticsVideoArchive from '../../../../Data/Analytics/AnalyticsVideoArchive/AnalyticsVideoArchive';
import { Colors } from '../../../../Configuration/Styles/Colors';

import { Sizes } from '../../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../../Configuration/Styles/Fonts';
import RefreshIcon from '@mui/icons-material/Refresh';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import SortedTable from '../../../../Components/SortedTable';

import { EMPTY_ARRAY, PAGE_LIMIT } from '../../../../constants';
import AnalyticsGameArchive from '../../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import Order from '../../../../Data/_Networking/ReactQuery/Order';
import useMutationGame, { ActionType } from '../../../../Data/Analytics/AnalyticsGameArchive/fetch/useMutation';
import useGamesPagedData from '../../../../Data/Analytics/AnalyticsGameArchive/hook';
import ButtonsInRowElement, { ButtonsInRowArray } from '../../../../Components/_BaseUI/ButtonsInRowElement';
import { headCells, getRenderCellContent, useRowStyles } from './renderCellContent';
import AddAnalyticsGamePopup from '../../AddAnalyticsGamePopup';
import useFetchAllPagesTeamRefs from '../../../../Data/Analytics/AnalyticsTeamRef/hook';
import { getMenuLink } from '../../../../Menu/Data/MenuStructure';
import { MenuID } from '../../../../Menu/Data/MenuElement';
import { useNavigate } from 'react-router-dom';
import AnalyticsGameMarkupIdPopup from '../../../AnalyticsGameMarkupIdPopup';
import AnalyticsGameStartAnalyticsPopup from '../../../AnalyticsGameStartAnalyticsPopup';
import { timeToSeconds } from '../../../../Components/_BaseUI/AnalyticsTimeField';
import { VideoPlayerCommands } from '../../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import Spinner from '../../../../Components/_BaseUI/Spinner/Spinner';
import { ResultStatus } from '../../../../Data/_Networking/ReactQuery/template';
import ErrorMessage from '../../../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage';
import uuid from '../../../../Tools/uuid';
import { AnalyticsAnalyticsStatus } from '../../../../Data/Analytics/AnalyticsStatuses';
import EditTitleSubtitlePopup, { EditTitleSubtitleFields } from '../../../../Components/_PopupControls/EditTitleSubtitlePopup';
import { AnalyticsType } from '../../../../Data/Analytics/AnalyticsTypes';


const useStyles = makeStyles(() => createStyles({
  titleText: {
    fontFamily: Fonts.main,
    fontSize: Sizes.caption,
    fontWeight: Sizes.boldWeight,
    color: Colors.mainTitle,
  },
  rowText: {
    fontFamily: Fonts.main,
    fontSize: Sizes.caption,
    fontWeight: Sizes.boldWeight,
    color: Colors.mainTitle,
  },
}));

type Props = Readonly<{
  videoRecordInfo?: AnalyticsVideoArchive
  videoPlayerCommands: VideoPlayerCommands | undefined
  sx?: SxProps<Theme>;
} & WrappedComponentProps>;

const GameResultCard: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const rowClasses = useRowStyles();
  const navigate = useNavigate();

  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);
  const [isEditPopupOpened, setIsEditPopupOpened] = React.useState<boolean>(false);
  const [showMarkupIdPopupForGame, setShowMarkupIdPopupForGame] = React.useState<AnalyticsGameArchive | undefined>(undefined);
  const [showStartAnalyticsPopupForGame, setShowStartAnalyticsPopupForGame] = React.useState<AnalyticsGameArchive | undefined>(undefined);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(PAGE_LIMIT);
  const [renameItem, setRenameItem] = React.useState<AnalyticsGameArchive | undefined>(undefined);
  const [editItem, setEditItem] = React.useState<AnalyticsGameArchive | undefined>(undefined);
  const [orderBy, setOrderBy] = React.useState<keyof AnalyticsGameArchive>('video_start');
  const [order, setOrder] = React.useState<Order>(Order.ASC);
  const [selected, setSelected] = React.useState<AnalyticsGameArchive[]>(EMPTY_ARRAY);
  const { mutateAsync: mutateGameConfig } = useMutationGame();

  const {
    status: fetchStatus,
    data: archive,
    handleFlushData,
  } = useGamesPagedData({
    parentVideoId: props.videoRecordInfo?.id,
    page,
    limit,
    order,
    orderBy: 'date',
  });

  const {
    getTeamRefByExternalId
  } = useFetchAllPagesTeamRefs();
  // console.log('status:', status);

  // const handleTeamRefSuccessUpdate = async () => {
  //   handleFlushData();
  //   handleCloseEditPopup();
  // };

  React.useEffect(() => {
    if (fetchStatus === ResultStatus.Loading) {
      setIsLoaderShowing(true);
    }
    else if (fetchStatus === ResultStatus.Error) {
      setIsLoaderShowing(false);
    }
    else { //  (fetchStatus === ResultStatus.Success)
      setIsLoaderShowing(false);
    }
    // eslint-disable-next-line
  }, [fetchStatus, archive?.data]);

  const handleDeleteGame = async (game?: AnalyticsGameArchive) => {
    try {
      if (game) {
        await mutateGameConfig(
          {
            archive: game,
            type: ActionType.Delete,
          }, {
            onSuccess: () => {
              setSelected(EMPTY_ARRAY);
              handleFlushData();
            },
          },
        );
        }
    } catch (error) {
      alert(error);
    }
  };
  const handleAddClicked = () => {
    setIsEditPopupOpened(true);
  };
  const handlePeerPageChange = (newLimit: number) => {
    setSelected(EMPTY_ARRAY);
    setLimit(newLimit);
  };
  const handleCloseEditPopup = () => {
    setIsEditPopupOpened(false);
    setEditItem(undefined);
  };
  // const handleSelectIDs = (selectedTeams: TeamRef[]) => {
  //   setSelected(selectedTeams);
  // };
  const handleRequestSort = (newOrderField: keyof AnalyticsGameArchive) => {
    if (orderBy === newOrderField) {
      setOrder((order === Order.ASC) ? Order.DESC : Order.ASC);
    }

    setOrderBy(newOrderField);
  };
  const handleRowItemClicked = (item: AnalyticsGameArchive): void => {
    setEditItem(item);
  };
  const handleMenuRename = (item?: AnalyticsGameArchive) => {
    setRenameItem(item);
  };
  const handleViewAnalyticsReport = (item?: AnalyticsGameArchive) => {
    const menuLink = getMenuLink(MenuID.AnalyticsGameReport, [item?.id ?? ""]);
    if (menuLink) {
      navigate(menuLink);
    }
  };
  const handleMenuDelete = (game?: AnalyticsGameArchive) => {
    if (game) {
      handleDeleteGame(game);
    }
  };
  const handleMenuCopy = (copyGame?: AnalyticsGameArchive) => {
    if (copyGame) {
      setEditItem({
        ...copyGame,
        copyId: copyGame.id,
        id: uuid(),
        title: `${copyGame.title} <${props.intl.formatMessage({ id: LOCALIZATION.copy_item })}>`,
        analytics_status: AnalyticsAnalyticsStatus.NotStarted,
        analytics_progress: undefined,
      });
    }
  };
  const handleGamePostedSuccess = async () => {
      handleFlushData();
      handleCloseEditPopup();
  };

  const handleMarkupIdSetSuccess = async (game: AnalyticsGameArchive) => {
    try {
      setShowMarkupIdPopupForGame(undefined);
      handleFlushData();
    } catch (error) {
      alert(error);

      handleFlushData();
      handleCloseMarkupIdPopup();
    }
  };
  const handleCloseMarkupIdPopup = () => {
    setShowMarkupIdPopupForGame(undefined);
  };


  const handleArchiveRename = async (editedItem: EditTitleSubtitleFields) => {
    try {
      await mutateGameConfig(
        {
          archive:{
            id: editedItem.id || "",
            game: { 
              id: editedItem.id,
              type: renameItem?.type || AnalyticsType.Game,
              title: editedItem.title,
              subtitle: editedItem.subtitle,
             },
          },
          type: ActionType.Patch,
        }, {
          onSuccess: () => {
            handleFlushData();
            handleCloseRenamePopup();
          },
        },
      );
    } catch (error) {
      alert(error);
      handleFlushData();
      handleCloseRenamePopup();
    }    
  };

  const handleCloseRenamePopup = () => {
    setRenameItem(undefined);
  };
  
  const handleStartAnalyticsSuccess = async (game: AnalyticsGameArchive | undefined) => {
    try {
      setShowStartAnalyticsPopupForGame(undefined);
      handleFlushData();
    } catch (error) {
      alert(error);

      handleFlushData();
      handleCloseMarkupIdPopup();
    }
  };
  const handleStartAnalyticsPopup = () => {
    setShowStartAnalyticsPopupForGame(undefined);
  };
  
  const buttons: ButtonsInRowArray = [
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.add_game }),
      onClick: handleAddClicked,
    },
    {
      space :-1 ,
    }, 
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.refresh }),
      iconButton: <RefreshIcon /> ,
      onClick: handleFlushData,
    },
  ];

  const renderCellContent = getRenderCellContent({
    rowClasses: rowClasses,
    getTeamRefByExternalId,
    onVideoStart: (item: AnalyticsGameArchive) => {
      const timeSec = timeToSeconds(item.video_start)      
      props.videoPlayerCommands?.play(timeSec)
    },
    onStartAnalytics: (item: AnalyticsGameArchive) => {
      setShowStartAnalyticsPopupForGame(item);
    },
    onStartMappingTeamId(item: AnalyticsGameArchive) {
      setShowMarkupIdPopupForGame(item)
    },
    onViewCompletedAnalytics: handleViewAnalyticsReport,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => handleFlushData,[]);

  return (
    <MainCard>
      <VertGrid sx={{ margin: 3 }} >
        <Box className={ classes.titleText } sx={{ paddingBottom: 2 }}>
          { props.intl.formatMessage({ id: LOCALIZATION.games_for_analytics }) }
        </Box>
        <ButtonsInRowElement
            id = "buttonsRow1"
            buttons={ buttons }
          />
        <ErrorMessage/>
        {
          (isLoaderShowing) ?
          <>
          <br /><br /><Spinner /><br /><br />
          </>
          : 
          (!!archive?.data?.length) &&
            <SortedTable<AnalyticsGameArchive>
              page={ page }
              rowsPerPage={ limit }
              // rowsPerPageOptions={ [5, 10, 25, 50, 100] }
              orderBy={ orderBy }
              order={ order }
              headCells={ headCells }
              selected={ selected }
              manyItems={ archive }
              renderCellContent={ renderCellContent }
              onPageChange={ setPage }
              onPeerPageChange={ handlePeerPageChange }
              onRowClick={ handleRowItemClicked }
              // onSelect={  (!mobileView) ? handleSelectIDs : undefined }
              // onSelect={  handleSelectIDs }
              onRequestSort={ handleRequestSort }
              onMenuEdit={ handleRowItemClicked }
              onMenuCopy={ handleMenuCopy }

              customActions={[
                {
                  title: props.intl.formatMessage({ id: LOCALIZATION.rename }),
                  icon: DriveFileRenameOutlineIcon,
                  onMenuClick: handleMenuRename
                },
                {
                  title: props.intl.formatMessage({ id: LOCALIZATION.analytics_game_state_done }),
                  icon: QueryStatsIcon,
                  onMenuClick: handleViewAnalyticsReport
                },
              ]}

              onMenuDelete={ handleMenuDelete }
            />
        }
        {
          (showMarkupIdPopupForGame) &&
            <AnalyticsGameMarkupIdPopup
              game={ showMarkupIdPopupForGame }
              onSubmit={ handleMarkupIdSetSuccess }
              onClose={ handleCloseMarkupIdPopup }
            />
        }
        {
        (renameItem) &&
          <EditTitleSubtitlePopup
            popupTitle={ props.intl.formatMessage({ id: LOCALIZATION.rename }) }
            item={ renameItem }
            onSubmit={ handleArchiveRename }
            onClose={ handleCloseRenamePopup }
          />
        }
        {
          (isEditPopupOpened || editItem) &&
            <AddAnalyticsGamePopup
              game={ editItem }
              gameVideoInfo={ props.videoRecordInfo }
              onPostedSuccess={ handleGamePostedSuccess }
              onClose={ handleCloseEditPopup }
            />
        }
        {
          (showStartAnalyticsPopupForGame) &&
            <AnalyticsGameStartAnalyticsPopup
              game={ showStartAnalyticsPopupForGame }
              onSubmit={ handleStartAnalyticsSuccess }
              onClose={ handleStartAnalyticsPopup }
            />
        }
      </VertGrid>
    </MainCard>
  )
};

export default injectIntl(GameResultCard);
