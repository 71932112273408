// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { styled } from '@mui/material/styles';

import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../Configuration/Styles/Fonts';


const ConfigurableMetricsChartViewRootStyled = styled('div')(() => ({
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  position: 'relative',
}));

const ConfigurableMetricsChartViewRPlaceholderStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  fontSize: Sizes.title,
  fontFamily: Fonts.main,
  fontWeight: Sizes.mediumWeight,
}));


export {
  ConfigurableMetricsChartViewRootStyled,
  ConfigurableMetricsChartViewRPlaceholderStyled,
};
