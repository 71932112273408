// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';
import { clone, remove } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import DestTypeBlockItem from './Item';
import uuid from '../../../../../../Tools/uuid';
import { DestStreamBlock } from '../../../../../../Data/NapiStreamingProfile';
import { StreamBlock, StreamType } from '../../../../../../Data/NapiStreamingProfileOptions';
import LOCALIZATION from '../../../../../../Localization';
import MainButton from '../../../../../../Components/_BaseUI/MainButton';


type Props = Readonly<{
  destStreamBlocksArr: DestStreamBlock[];
  streamBlocksArr: StreamBlock[];
  onChange: (destArr: DestStreamBlock[]) => void;
} & WrappedComponentProps>;

export type LocalDestStreamBlock = DestStreamBlock & { srcStreamInfo: StreamBlock | undefined  }


const DestTypeBlock: React.FC<Props> = (props: Props) => {
  const [destStreamBlocksArr, setDestStreamBlock] = React.useState<LocalDestStreamBlock[]>(
    props.destStreamBlocksArr.map((item: DestStreamBlock, index: number) => ({
      ...item,
      key: item.key || uuid(index),
      srcStreamInfo: props.streamBlocksArr.find((info) => info.id === item.id && info.type === item.type )
    }))
  );
  const allowedStreamBlocks = clone(props.streamBlocksArr);

  // local file added by other system
  // if (destStreamBlock.find((item) => (item.id === StreamType.LocalFile))) {
    remove(allowedStreamBlocks, { id: StreamType.LocalFile });
  // }

  const handleAddDestination = () => {

    const addItem = allowedStreamBlocks[0]
    const origConfig = props.streamBlocksArr.find((item) => item.id === addItem.id)

    setDestStreamBlock([
      ...destStreamBlocksArr,
      {
        key: uuid(),
        type: addItem.type,
        id: addItem.id,
        url: origConfig?.defaultUrl,
        enabled: true,        
        srcStreamInfo: origConfig,
      },
    ]);
  };
  const handleChanged = (changed: LocalDestStreamBlock) => {
    const clonedDestStreamBlock = clone(destStreamBlocksArr);
    const dest = clonedDestStreamBlock.find((item: LocalDestStreamBlock) => (item.key === changed.key));

    const origConfig = props.streamBlocksArr.find((item) => item.id === changed.id)

    if (dest) {
      dest.type = changed.type;
      dest.id = changed.id;
      dest.enabled = changed.enabled;
      dest.url = (changed.url === undefined) ? origConfig?.defaultUrl : changed.url;
      dest.urlKey = changed.urlKey;
      dest.file = changed.file;
      dest.oAuthToken = (dest.id !== changed.id) ? undefined : changed.oAuthToken; // reset token if StreamType changed
      dest.groupId = (dest.id !== changed.id) ? undefined : changed.groupId; // reset groupId if StreamType changed
      dest.groupName = (dest.id !== changed.id) ? undefined : changed.groupName; // reset groupName if StreamType changed
      dest.playlistName = (dest.id !== changed.id) ? undefined : changed.playlistName; // reset playlistName if StreamType changed
      dest.srcStreamInfo = origConfig

      dest.timeLocale = changed.timeLocale;
      dest.timezoneMinutes = changed.timezoneMinutes;
      dest.videoNamePrefix = changed.videoNamePrefix;
      dest.videoNameFormatOnStart = changed.videoNameFormatOnStart;
      dest.videoNameFormatOnStop = changed.videoNameFormatOnStop;
      dest.videoDescFormatOnStart = changed.videoDescFormatOnStart;
      dest.videoDescFormatOnStop = changed.videoDescFormatOnStop;
      dest.videoAccessPermission = changed.videoAccessPermission;

      setDestStreamBlock(clonedDestStreamBlock);
    }
  };
  const handleDelete = (key: string | undefined) => {
    if (key === undefined) {
      return;
    }
    const clonedDestStreamBlock = clone(destStreamBlocksArr);

    remove(clonedDestStreamBlock, { key });

    setDestStreamBlock(clonedDestStreamBlock);
  };

  React.useEffect(() => {
    props.onChange(destStreamBlocksArr.map((item: LocalDestStreamBlock) => ({
      key: item.key,
      type: item.type,
      id: item.id,
      enabled: item.enabled,
      url: item.url,
      urlKey: item.urlKey,
      file: item.file,
      oAuthToken: item.oAuthToken,
      groupId: item.groupId,
      groupName: item.groupName,
      playlistName: item.playlistName,

      timeLocale: item.timeLocale,
      timezoneMinutes: item.timezoneMinutes,
      videoNamePrefix: item.videoNamePrefix,
      videoNameFormatOnStart: item.videoNameFormatOnStart,
      videoNameFormatOnStop: item.videoNameFormatOnStop,
      videoDescFormatOnStart: item.videoDescFormatOnStart,
      videoDescFormatOnStop: item.videoDescFormatOnStop,
      videoAccessPermission: item.videoAccessPermission,
    })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destStreamBlocksArr]);

  const itemsToShow = () => {
    let itemsArr = [];
    let index = 1
    for (const item of destStreamBlocksArr) {
      if (item.id !== StreamType.LocalFile) {
        itemsArr.push(<DestTypeBlockItem
          index={ index }
          key={ item.key }
          streamBlock={ props.streamBlocksArr }
          allowedStreamBlocks={ allowedStreamBlocks }
          item={ item }
          onChange={ handleChanged }
          onDelete={ handleDelete }
        />)
        index += 1
      }
    }

    return itemsArr;
  }

  return (
    <Grid
      item
      xs={ 12 }
    >
      <Grid
        sx={ {
          padding: '0 20px',
          backgroundColor: '#F5F6F8',
          borderRadius: '8px',
        } }
      >
        {
          itemsToShow()
        }
      </Grid>
      <div
        style={ {
          marginTop: 20,
          display: 'inline-block',
        } }
      >
        <MainButton
          title={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_add_destination }) }
          onClicked={ handleAddDestination }
        />
      </div>
    </Grid>
  );
};


export default injectIntl(DestTypeBlock);
