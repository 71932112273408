// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { stateByKey } from './helpers';
import CircledLabel, { LabelType } from '../../../../Components/_BaseUI/CircledLabel/CircledLabel';
import { CameraSettingsInterface } from '../../../../Data/Camera/HWCameraSettings/CameraSettings';
import { CameraSystemStates } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsStuff';
import { useAppConfig } from '../../../../Localization/AppContextProvider/helpers';
import VertGrid from '../../../../Components/_Layout/VertGrid';


type Props = Readonly<{
  compact?: boolean;
  // camera: Camera;
  lastSettingsResponse?: CameraSettingsInterface
  criticalErrors?: Partial<CameraSystemStates>;
} & WrappedComponentProps>;

export enum InfoField {
  Critical = 'critical',
  Streaming = 'streaming',
  StreamingProfile = 'streamingProfile',
  ActiveEpgEvent = 'activeEpgEvent',

  SaveToStorage = 'saveToStorage',
  StorageLeft = 'storageLeft',
  StorageSync = 'storageSync',

  Outdoor = 'outdoor',
  Internal = 'internal',
  FAN = 'FAN',
  DC = 'DC',
}

const CameraPreviewInfo: React.FC<Props> = (props: Props) => {
  const { localization: { locale } } = useAppConfig();
  const { compact } = props;
  const renderStateItemExt = (key: keyof typeof InfoField, hideIfNoError?: boolean) => {
    const state = stateByKey({ key, lastSettingsResponse: props.lastSettingsResponse, intl: props.intl, criticalErrors: props.criticalErrors, locale });

    if ((!state) || (hideIfNoError && (state.label === LabelType.Green)))  { return null; }

    return (
      <CircledLabel
        labelType={ state.label }
        title={ state.title }
      />
    );
  };

  const renderLabelItem = (key: keyof typeof InfoField) => (renderStateItemExt(key, false))
  const renderLabelIfErrorItem = (key: keyof typeof InfoField) => (renderStateItemExt(key, true))

  const renderStateItem = (key: keyof typeof InfoField) => {
    const state = stateByKey({ key, lastSettingsResponse: props.lastSettingsResponse, intl: props.intl, criticalErrors: props.criticalErrors, locale });

    if (!state) { return null; }

    return (
      <Grid
        item
        key={ key }
        xs={ 12 }
      >
        <CircledLabel
          labelType={ state.label }
          title={ state.title }
        />
      </Grid>
    );
  };

  if (compact) {
    return (
      <VertGrid>
        { (['Critical', 'StreamingProfile', 'Streaming', 'ActiveEpgEvent', 'DC'] as Array<keyof typeof InfoField>).map(renderLabelItem) }
        { (['SaveToStorage', 'StorageLeft', 'StorageSync', 'Outdoor', 'Internal', 'FAN'] as Array<keyof typeof InfoField>).map(renderLabelIfErrorItem) }
      </VertGrid>
    )
  }

  return (
    <Grid
      container
      spacing={ 1 }
    >
      <Grid
        item
        xs={ 4 }
      >
        { (['Critical', 'StreamingProfile', 'Streaming', 'ActiveEpgEvent', 'DC'] as Array<keyof typeof InfoField>).map(renderStateItem) }
      </Grid>
      <Grid
        item
        xs={ 4 }
      >
        { (['SaveToStorage', 'StorageLeft', 'StorageSync'] as Array<keyof typeof InfoField>).map(renderStateItem) }
      </Grid>
      <Grid
        item
        xs={ 4 }
      >
        { (['Outdoor', 'Internal', 'FAN'] as Array<keyof typeof InfoField>).map(renderStateItem) }
      </Grid>
    </Grid>
  )
};

export default injectIntl(CameraPreviewInfo);
