// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {  SxProps, Theme } from '@mui/material';

import MainButton from '../../../../Components/_BaseUI/MainButton'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content';
import LOCALIZATION from '../../../../Localization';
import VertGrid from '../../../../Components/_Layout/VertGrid';
import { getMenuLink } from '../../../../Menu/Data/MenuStructure';
import { MenuID } from '../../../../Menu/Data/MenuElement';
import { useNavigate } from 'react-router-dom';
import AnalyticsGameArchive, { AnalyticsGameShared } from '../../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import AnalyticsVertOppositeBarView from '../../../../Components/_ChartsAndBars/AnalyticsVertOppositeBarView';
import LeftRightCardHeader from '../../../../Components/_Layout/CardHeader/LeftRight';
import { useAnalyticsStatDistance } from '../../../../Data/Analytics/AnalyticsStatDistance/hook';
import { useAnalyticsStatSpeed } from '../../../../Data/Analytics/AnalyticsStatSpeed/hook';
import { useAnalyticsStatAttackDefenseTime } from '../../../../Data/Analytics/AnalyticsStatAttackDefenseTime/hook';
import { useAnalyticsStatPossession } from '../../../../Data/Analytics/AnalyticsStatPossession/hook';
import { createTeamMainReportBarChartConfig } from '../../../../Components/_ChartsAndBars/AnalyticsVertOppositeBarView/createTeamMainReportBarChartConfig';
import { GetTeamRefById } from '../../../../Data/Analytics/AnalyticsTeamRef/hook';

type Props = Readonly<{
  gameResultInfo: AnalyticsGameArchive | undefined
  sx?: SxProps<Theme>;
  getTeamRefByExternalId: GetTeamRefById | undefined;
} & WrappedComponentProps>;

const GameTeamMainReportCard: React.FC<Props> = (props: Props) => {
  const gameId = props.gameResultInfo?.id || ""

  const { data: distanceStat } = useAnalyticsStatDistance({ gameId });
  const { data: speedStat } = useAnalyticsStatSpeed({ gameId });
  const { data: possessionStat } = useAnalyticsStatPossession({ gameId });
  const { data: attackDefenseTimeStat } = useAnalyticsStatAttackDefenseTime({ gameId });
  
  const navigate = useNavigate();

  const handleConfigure = () => {
    const menuLink = getMenuLink(MenuID.AnalyticsGameReportDetails, [gameId]);
    if (menuLink) {
      navigate(menuLink);
    }
  }

  if (!gameId) {
    return null;
  }

  const barChartData = createTeamMainReportBarChartConfig(
    props.gameResultInfo, 
    distanceStat?.game_stat, 
    speedStat?.game_stat, 
    possessionStat?.game_stat, 
    attackDefenseTimeStat?.game_stat,
    props.getTeamRefByExternalId,
  );

  return (
    <MainCard>
      <LeftRightCardHeader titleLeft={ props.getTeamRefByExternalId?.(props.gameResultInfo?.team1)?.name }  titleRight={ props.getTeamRefByExternalId?.(props.gameResultInfo?.team2)?.name }/>
      <MainCardContent>
        <VertGrid>
          <Grid
            sx={ {
              marginTop: -2,
              height: 525,
            } }
          >
            <AnalyticsVertOppositeBarView 
              data={ barChartData }
              team1Color={ AnalyticsGameShared.team1Color }
              team2Color={ AnalyticsGameShared.team2Color }
            />
          </Grid>

          <Grid container spacing={ 1 } 
              direction="row"
              justifyContent="center" 
              sx={ {
                marginTop: '15px',
              } }
            >
              <Grid item>
                <MainButton 
                  onClicked={ handleConfigure } 
                  title={ props.intl.formatMessage({ id: LOCALIZATION.details }) } 
                  sx={{ paddingLeft: 3, paddingRight: 3 }} 
                />
              </Grid>
            </Grid>
        </VertGrid>
      </MainCardContent>
    </MainCard>
  );
};

export default injectIntl(GameTeamMainReportCard);
