// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import TeamRef from '../index';
import Order from '../../../_Networking/ReactQuery/Order';
import { fetchJavaManyJSONData } from '../../../_Networking/ReactQuery/fetch';
import { PAGE_LIMIT } from '../../../../constants';
import { queryCache, queryClient } from '../../../../AppProviders';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import { getCachedDataAllPagedItems, Result, usePagedItems } from '../../../_Networking/ReactQuery/template';

type FetchProps = Readonly<{
  page: number;
  limit: number;
  order?: Order;
  orderBy?: keyof TeamRef;
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'TeamRefs';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.page,
  props.limit,
  props.orderBy,
  props.order,
]);

const getAllCached = () => (
  getCachedDataAllPagedItems<TeamRef>(getKeyWithoutPage())
);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<ResponseMany<TeamRef[]>>(getKey(props))
);

const clearTeamRefsCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};


const fetchData = async (props: FetchProps): Promise<ResponseMany<TeamRef[]>> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  const offset = (props.page * props.limit);
  let params = `?max=${props.limit}&first=${offset}`;
  let sort: string | undefined = undefined;

  switch (props.orderBy) {
    case 'name': {
      sort = 'name';
      break;
    }
    case 'shortName': {
      sort = 'shortName';
      break;
    }
    case 'color': {
      sort = 'color';
      break;
    }
  }

  if (sort) {
    params += `&direction=${props.order}&sort=${sort}`;
  }
  return await fetchJavaManyJSONData<TeamRef>(
    `/teamref${params}`,
    'teamref',
    {
      limit: props.limit,
      offset,
    },
    {
      method: 'GET',
    },
  );
};


type UseFetchProps = Readonly<{
  enableToFetch?: boolean;
  page?: number;
  limit?: number;
  order?: Order;
  orderBy?: keyof TeamRef;
}>;


const useTeamRefsFetch = (props: UseFetchProps): Result<TeamRef> => {
  const { enableToFetch = true, page: pg = 0, limit = PAGE_LIMIT } = props;
  const fetchTemplate = async (queryKey: QueryKey, page: number = 0) => fetchData({
    page,
    limit,
    order: props.order,
    orderBy: props.orderBy,
  });

  return usePagedItems<TeamRef>({
    page: pg,
    fetch: fetchTemplate,
    keepPreviousData: false,
    queryKey: getKey({
      page: pg,
      limit,
      order: props.order,
      orderBy: props.orderBy,
    }),
    config: {
      enabled: !!enableToFetch,
    },
  });
};



export {
  getKey,
  useTeamRefsFetch,
  getCached,
  clearTeamRefsCache,
  getAllCached,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
