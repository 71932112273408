// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useIntl } from 'react-intl';

import { JetsonTools } from '../../../Tools/Tools'
import { useCameraData } from '../HWCameraSettings/hook/Hooks'
import { TemperatureControllerDataInterface } from '../HWCameraSettings/CameraSettingsTemperature'
import { Jetson } from '../Metrics/Collection'
import { useMetricValuesData } from '../Metrics/MetricsValuesHook'
import { MetricsDataProviderUniqueKey } from '../Metrics/Types'
import { JetsonStatusReviewBuilder } from './Tools'
import { JetsonId, JetsonIdAll, JetsonInterface, JetsonNames, JetsonStatusReviewInterface, JetsonStorageInterface } from './Types'

export function useJetsonModelValue(id: JetsonId): JetsonInterface | undefined {
    const camera = useCameraData()

    if (!camera?.settings) { return undefined }// on no camera return here because we heen to make useMetricValuesData run every time

    const name = JetsonTools.getName(id)
    const state = JetsonTools.getState(camera, id)

    let temperatureControllerData: TemperatureControllerDataInterface | undefined
    let cpuLoad: number | undefined
    let gpuLoad: number | undefined
    let cpuTemperature: number | undefined
    let gpuTemperature: number | undefined
    let boardTemperature: number | undefined
    // let totalPowerConsumption: number | undefined // Не используется :(
    let voltage: number | undefined

    if (id === JetsonNames.Jetson1) {
        temperatureControllerData = camera?.settings?.temperatureControllerDataJ1
        cpuLoad = camera?.settings?.lastMetricsData?.j1CpuUsePercent
        cpuTemperature = camera?.settings?.lastThermalData?.j1CpuTemp
        gpuLoad = camera?.settings?.lastMetricsData?.j1GpuUsePercent
        gpuTemperature = camera?.settings?.lastThermalData?.j1GpuTemp
        voltage = camera?.settings?.lastMetricsData?.j1OnVoltage
    } else if (id === JetsonNames.Jetson2) {
        temperatureControllerData = camera?.settings?.temperatureControllerDataJ2
        cpuLoad = camera?.settings?.lastMetricsData?.j2CpuUsePercent
        cpuTemperature = camera?.settings?.lastThermalData?.j2CpuTemp
        gpuLoad = camera?.settings?.lastMetricsData?.j2GpuUsePercent
        gpuTemperature = camera?.settings?.lastThermalData?.j2GpuTemp
        voltage = camera?.settings?.lastMetricsData?.j2OnVoltage
    } else { throw new Error('Unexpected jetson id ' + id) }

    const model: JetsonInterface = {
        jetsonId: id,
        temperatureControllerData: temperatureControllerData,
        name: name,
        cpuLoad: cpuLoad,
        gpuLoad: gpuLoad,
        cpuTemp: cpuTemperature,
        gpuTemp: gpuTemperature,
        boardTemp: boardTemperature,
        powerVoltage: voltage,
        state: state
    }

    return model
}

export function useJetsonStatusReview(id: JetsonId): JetsonStatusReviewInterface | undefined {
    const intl = useIntl();
    const jetson = useJetsonModelValue(id)
    if (!jetson) { return undefined }
    return new JetsonStatusReviewBuilder(jetson, intl.formatMessage).build()
}

export function useJetsonsCriticals(): string[] {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const reviews = JetsonIdAll.map(id => useJetsonStatusReview(id))
    return reviews.filter(review => review && review.isCritical).map(review => `${review!.name}: ${review!.statuses.join(' ')}`)
}

export function useJetsonStorage(id: JetsonId): JetsonStorageInterface | undefined {
    const rootVolumeMetric = Jetson.VolumeMetric(id, true)
    const ssdVolumeMetric = Jetson.VolumeMetric(id, false)

    let uniqueKey: MetricsDataProviderUniqueKey

    if (id === JetsonNames.Jetson1) {
        uniqueKey = 'ValuesStorageJetson1'
    } else if (id === JetsonNames.Jetson2) {
        uniqueKey = 'ValuesStorageJetson2'
    } else { throw new Error('Unexpected jetson id ' + id) }

    const data = useMetricValuesData({
        metrics: [rootVolumeMetric, ssdVolumeMetric],
        uniqueKey: uniqueKey,
        defaultSelected: [] // при получении значений мы не выбираем значения. Пока. Поэтому []
    })

    let rootVolume: number | undefined
    let ssdVolume: number | undefined

    if (data.items.length === 1) {
        const values = data.items[0]
        rootVolume = values[rootVolumeMetric.dataKey]
        ssdVolume = values[ssdVolumeMetric.dataKey]
    }

    const model: JetsonStorageInterface = {
        rootVolume: rootVolume,
        ssdVolume: ssdVolume
    }

    return model
}
