// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { SyntheticEvent } from 'react';

import Item, { ItemType } from './item';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useIntl } from 'react-intl';
import LOCALIZATION from '../../../Localization';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Colors } from '../../../Configuration/Styles/Colors';

export type MenuDropdownAction<T> = Readonly<{
  title?: string;
  icon?: OverridableComponent<any>
  onMenuClick?: (item: T) => void;
}>;

type Props<T> = Readonly<{
  menuButtonIcon?: OverridableComponent<any>
  /**
   * If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right
   * side of the icon with content above or below, without ruining the border size and shape).
   * @default false
   */
  menuButtonEdge?: 'start' | 'end' | false;

  item: T;
  onEdit?: (item: T) => void;
  onCopy?: (item: T) => void;
  onDelete?: (item: T) => void;

  customActions?: MenuDropdownAction<T>[]
}>;


const MenuDropdownButton = <T, >(props: Props<T>): JSX.Element => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event?: {}, reason?: "backdropClick" | "escapeKeyDown"): void => {
    if (event) {
      const e = event as SyntheticEvent
      if (e.stopPropagation) {
        e.stopPropagation();
      }
    }
    setAnchorEl(null);
  };
  const handleEdit = (): void => {
    if (props.onEdit) {
      props.onEdit(props.item);
    }

    setAnchorEl(null);
  };
  const handleCopy = (): void => {
    if (props.onCopy) {
      props.onCopy(props.item);
    }

    setAnchorEl(null);
  };
  const handleDelete = (): void => {
    if (props.onDelete && window.confirm(intl.formatMessage({ id: LOCALIZATION.confirm_remove }))) {
      props.onDelete(props.item);
    }
    setAnchorEl(null);
  };
  const MenuIcon = props.menuButtonIcon || MoreVertIcon

  return (
    <>
      <IconButton
        id="basic-button"
        edge={ props.menuButtonEdge }
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={ open ? 'true' : undefined }
        onClick={ handleClick }
        sx={ {
          color: Colors.buttonBlue,
          minWidth: 'auto',
          cursor: 'pointer',
        } }
      >
        <MenuIcon/>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
        MenuListProps={ {
          'aria-labelledby': 'basic-button',
        } }
      >
        {
          (props.customActions?.length) &&
            props.customActions.map((action) => (
              <Item
              type={ ItemType.Custom }
              customName={ action.title }
              customIcon= { action.icon }
              onClick={() => {
                if (action.onMenuClick) {
                  action.onMenuClick(props.item);
                }
                setAnchorEl(null);
              }}
              />
            ))
        }
        {
          (props.onEdit) &&
            <Item
                type={ ItemType.Edit }
                onClick={ handleEdit }
            />
        }
        {
          (props.onCopy) &&
            <Item
                type={ ItemType.Copy }
                onClick={ handleCopy }
            />
        }
        {
          (props.onDelete) &&
            <Item
                type={ ItemType.Delete }
                onClick={ handleDelete }
            />
        }
      </Menu>
    </>
  );
};


export default MenuDropdownButton;
