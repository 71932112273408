// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { StorageService } from '../../Common/StorageService'
import { AppInterface } from '../Types'

interface AppStorageServiceInterface {
    app?: AppInterface
    reset(): void
}

export default class AppStorageService extends StorageService<AppInterface> implements AppStorageServiceInterface {
    key: string = 'AppStorageService.app'

    get app(): AppInterface | undefined {
        return this.data
    }

    set app(newApp: AppInterface | undefined) {
        this.data = newApp
    }
}
