// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';

import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Colors } from '../../../Configuration/Styles/Colors';


type Props = Readonly<{
  heading: string;
}>;


const useStyles = makeStyles(() => createStyles({
  page_heading: {
    fontWeight: Sizes.boldWeight,
    fontFamily: Fonts.main,
    fontSize: Sizes.caption,
    color: Colors.headingMain
  }
}));

const PageHeader: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <div
      className={ classes.page_heading }
    >
      { props.heading }
    </div>
  )
};


export default PageHeader;
