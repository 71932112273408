// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

export abstract class StorageService<T> {
    abstract key: string

    get data(): T | undefined {
        const data = localStorage.getItem(this.key)
        if (data) {
            try {
                return JSON.parse(data)
            } catch (error) {
                // console.log('StorageService > parse Data failed: ' + error)
                return undefined
            }
        } else {
            return undefined
        }
    }

    set data(newData: T | undefined) {
        if (newData !== undefined) {
            localStorage.setItem(this.key, JSON.stringify(newData))
        } else {
            localStorage.removeItem(this.key)
        }
    }

    reset(): void {
        this.data = undefined
    }
}
