// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { DestType } from '../NapiCameraConfig/Dest';


export type VideoResolution = Readonly<{
    title: string | 'Custom';
    width: number;
    height: number;
}>;

export enum StreamType {
    LocalFile = 'Local File',
    Cybercamera = 'Cybercamera',
    Youtube = 'Youtube',
    YoutubeRTMP = 'Youtube RTMP',
    RUTUBE = 'RUTUBE',
    VKVideo = 'VK Video',
    VKVideoRTMP = 'VK Video RTMP',
    Twitch = 'twitch',
    Facebook = 'Facebook',
    Other = 'Other',
}

export const STREAM_TYPES_SUPPORTING_OVERRIDE_ARR = [
    StreamType.YoutubeRTMP,
    StreamType.RUTUBE,
    StreamType.VKVideoRTMP,
    StreamType.Twitch,
    StreamType.Facebook,
    StreamType.Other,
]

export type StreamBlockOAuth = {
    clientId: string;
    scope: string;
    state?: string;
    authorizeUri: string;
    redirectUri?: string; // url MUST be on same host name as authorizeUri. else becase of CORS cant detect success response from server
    redirectUriScheme?: string | "http" | "https"; // some oauth like google requere https. else used scheme of current host
    responseType: string | "code" | "token" | "access_token";
    resposeSuccessKeyName: string | "code" | "token" | "access_token"; // if oauth reponse have this key treat as success. like "code", "token"
    params?: { [key: string]: string };
}

export type StreamBlock = {
    type: DestType;
    id: StreamType;
    defaultUrl?: string;
    separatedRtmpKey?: boolean;
    oAuthParams?: StreamBlockOAuth; // if have oAuthParams then use oauth
}

type StreamingProfileOptions = Readonly<{
    options: {
        videoResolution: VideoResolution[];
        streamBlock: StreamBlock[];
    };
}>;


export default StreamingProfileOptions;
