// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';


const NotFound: React.FC = () => (
  <div>
    404
  </div>
);


export default NotFound;
