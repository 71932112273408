// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { RenderCellContentValue } from "../../../../Components/SortedTable/SortedTableBodyRow";
import { AnalyticsSmallPlayBtn } from "../../../../Icons/Icons";
import LOCALIZATION from "../../../../Localization";
import { intl } from "../../../../Localization/LocalizationProvider";
import IconButton from '@mui/material/IconButton';
import { AnalyticsGameEventType, AnalyticsGameEventWithId, AnalyticsTeamSide, analyticsFindEventWithScoreForEvent, analyticsFindEventWithTeamSideForEvent, analyticsTeamValueBySide, localizedAnalyticsGameEventType } from "../../../../Data/Analytics/AnalyticsGameEvent/AnalyticsGameEvent";
import { createStyles, makeStyles } from '@mui/styles';
import { Sizes } from '../../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../../Configuration/Styles/Fonts';
import { Colors } from "../../../../Configuration/Styles/Colors";
import { Box } from "@mui/material";
import HorizGrid from "../../../../Components/_Layout/HorizGrid";
import { markerForAnalyticsGameEventType } from "../../../../Data/Analytics/AnalyticsGameEvent/helper";
import { AnalyticsSportType } from "../../../../Data/Analytics/AnalyticsTypes";
import { HeadCell } from "../../../../Components/SortedTable/SortedTableHead";

export const useRowStyles = makeStyles(() => createStyles({
    rowText: {
      fontFamily: Fonts.main,
      fontSize: Sizes.title,
      fontWeight: Sizes.regularWeight,
      color: Colors.mainTitle,
    },
  }));

export const headCells = (): HeadCell<AnalyticsGameEventWithId>[] => (
[
    {
        id: 'id' as keyof AnalyticsGameEventWithId,
        numeric: false,
        disablePadding: true,
        label: intl().formatMessage({ id: LOCALIZATION.analytics_events_number }),
    },
    {
        id: 'timestamp' as keyof AnalyticsGameEventWithId,
        numeric: false,
        disablePadding: true,
        label: intl().formatMessage({ id: LOCALIZATION.analytics_events_time }),
    },
    {
        id: 'event_type' as keyof AnalyticsGameEventWithId,
        numeric: false,
        disablePadding: true,
        label: intl().formatMessage({ id: LOCALIZATION.analytics_events_event }),
    },
    {
        id: 'team1_score' as keyof AnalyticsGameEventWithId,
        numeric: false,
        disablePadding: true,
        label: intl().formatMessage({ id: LOCALIZATION.analytics_events_details }),
    },
  ]);

type Props = Readonly<{
    sportType: AnalyticsSportType | undefined;
    rowClasses: ReturnType<typeof useRowStyles>;
    events: AnalyticsGameEventWithId[] | undefined;
    onVideoStart: (item: AnalyticsGameEventWithId) => void
    team1Name: string  | undefined;
    team2Name: string  | undefined;
}>;

export const getRenderCellContent = (props: Props) => {
    const countEventNumberForEvent = (event: AnalyticsGameEventWithId | undefined): number => {
        if (!event || !props.events) {
            return 0;
        }
        let count = 1;
        for(const item of props.events) {
            if (item.id === event.id) {
                return count;
            }
            if (item.event_type === event.event_type) {
                count++;
            }
        }
        return count;
    }

    const detectGoalTeamValueForEvent = (event: AnalyticsGameEventWithId | undefined, team1Value: string | undefined, team2Value: string | undefined): string | undefined => {
        if (!event || !props.events || event.event_type !== AnalyticsGameEventType.Goal) {
            return undefined;
        }

        const lastEventWithScore = analyticsFindEventWithScoreForEvent(event, props.events);
        const team1_score = lastEventWithScore?.team1_score ?? 0
        const team2_score = lastEventWithScore?.team2_score ?? 0

        if (event.team1_score !== undefined && team1_score < event.team1_score) { // team1 score become bigger
            return team1Value;
        }
        if (event.team2_score !== undefined && team2_score < event.team2_score) { // team2 score become bigger
            return team2Value;
        }

        return undefined;
    }

    return (item: AnalyticsGameEventWithId, cellName: keyof AnalyticsGameEventWithId, index: number): RenderCellContentValue => {
        switch (cellName) {
            case 'id': {
                return ({
                    content: (
                        <Box className={props.rowClasses.rowText}>
                            { item.id || "-" }
                        </Box>
                    ),
                });
            }
            case 'event_type': {
                const count = countEventNumberForEvent(item)
                let extraInfo = ""
                if (item.event_type === AnalyticsGameEventType.Goal) {
                    const goalTeamName = detectGoalTeamValueForEvent(item, props.team1Name, props.team2Name)
                    if (goalTeamName !== undefined) {
                        extraInfo = ` (${goalTeamName})`;
                    }
                }

                return ({
                    content: (
                        <HorizGrid>
                            { markerForAnalyticsGameEventType(item.event_type, props.sportType) }
                            <Box className={props.rowClasses.rowText}>
                                { `${localizedAnalyticsGameEventType(item.event_type, count)}${extraInfo}`  }
                            </Box>
                        </HorizGrid>
                    ),
                });
            }
            case 'timestamp': {
                if (!!item.timestamp) {
                    // use regex to remove starting 00 for time like 00:00:00  ;  00:03:00 ;  00:14:21  ; 01:20:00 
                    const timestamp = item.timestamp?.replace(/^(00:0|00:|0)/, "");

                    return ({
                        content: (
                            <HorizGrid>
                            <IconButton  aria-label="play" sx={{ marginLeft: -1 }} onClick={ (ev) => { 
                                ev.stopPropagation();
                                props.onVideoStart(item);
                                } }>
                                <AnalyticsSmallPlayBtn />
                            </IconButton>
                            <Box className={props.rowClasses.rowText}>
                                { timestamp }
                            </Box>
                            </HorizGrid>
                        ),
                    });
                }
                return ({
                    content: "-",
                });
            }  
            case 'team1_score': { // Description
                if (!!item.event_type) {
                    let detailsStr = ""
                    switch (item.event_type) {
                        case AnalyticsGameEventType.GameStart:
                            detailsStr="";
                            break;
                        case AnalyticsGameEventType.TimeStart:
                        case AnalyticsGameEventType.OvertimeStart:
                        case AnalyticsGameEventType.PenaltyStart:
                        case AnalyticsGameEventType.GameEnd:
                        case AnalyticsGameEventType.TimeEnd:
                        case AnalyticsGameEventType.OvertimeEnd:
                        case AnalyticsGameEventType.PenaltyEnd: {
                            const lastEventWithTeamSide = analyticsFindEventWithTeamSideForEvent(item, props.events);

                            const { leftTeamValue: leftTeamName, rightTeamValue: rightTeamName } = analyticsTeamValueBySide(
                                lastEventWithTeamSide?.team1_side ?? AnalyticsTeamSide.Left,
                                lastEventWithTeamSide?.team2_side ?? AnalyticsTeamSide.Right,
                                props.team1Name,
                                props.team2Name
                            )
    
                            const lastEventWithScore = analyticsFindEventWithScoreForEvent(item, props.events);
                            const team1_score = lastEventWithScore?.team1_score ?? 0
                            const team2_score = lastEventWithScore?.team2_score ?? 0

                            const { leftTeamValue, rightTeamValue } = analyticsTeamValueBySide(
                                lastEventWithTeamSide?.team1_side ?? AnalyticsTeamSide.Left,
                                lastEventWithTeamSide?.team2_side ?? AnalyticsTeamSide.Right,                                    
                                team1_score+"",
                                team2_score+""
                            )

                            detailsStr=`${leftTeamName}: ${leftTeamValue} | ${rightTeamName}: ${rightTeamValue}`;
                            break;
                        }
                        case AnalyticsGameEventType.Goal: {
                            if (item.team1_score !== undefined && item.team2_score !== undefined) {
                                const lastEventWithTeamSide = analyticsFindEventWithTeamSideForEvent(item, props.events);
                                const { leftTeamValue, rightTeamValue } = analyticsTeamValueBySide(
                                    lastEventWithTeamSide?.team1_side ?? AnalyticsTeamSide.Left,
                                    lastEventWithTeamSide?.team2_side ?? AnalyticsTeamSide.Right,
                                    item.team1_score+"",
                                    item.team2_score+""
                                )

                                detailsStr=`${leftTeamValue} : ${rightTeamValue}`;
                            }
                            break;
                        }
                    }

                    return ({
                        content: (
                            <Box className={props.rowClasses.rowText}>
                                { detailsStr }
                            </Box>
                        ),
                    });
                }
                return ({
                    content: "",
                });
            }              
        }

        return ({
        content: (item?.[cellName] + "" || ''),
        });
    };
}