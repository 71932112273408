// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import StreamingConfigurationCol from '../StreamingConfigurationCol';
import LOCALIZATION from '../../../Localization';
import CameraStreamPreview from '../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import { useCameraSettings } from '../../../Data/Camera/HWCameraSettings/hook/Hooks';
import { FileType } from '../../../Data/VideoArchive';
import ButtonsInRowElement, { ButtonsInRowArray } from '../../../Components/_BaseUI/ButtonsInRowElement';
import StreamingProfile from '../../../Data/NapiStreamingProfile';
import { NO_STREAM_ID } from '../../../Data/NapiStreamingProfile/constants';
import { activeEpgEventStatesInfo, steamingStatesInfo } from '../../../Data/Camera/HWCameraSettings/CameraSettingsStreamingState';
import VertGrid from '../../../Components/_Layout/VertGrid';
import CircledLabel from '../../../Components/_BaseUI/CircledLabel/CircledLabel';
import { getLabelFromState } from '../../CamerasPage/CameraCardBlock/CameraPreviewInfo/helpers';
import { useAppConfig } from '../../../Localization/AppContextProvider/helpers';
// import { useCameraState } from './../../../hooks/fetch/CameraState';


type Props = Readonly<{
  streamingProfile?: StreamingProfile;
  handleStopStreaming?: () => void;
} & WrappedComponentProps>;

const useStyles = makeStyles(() => createStyles({
  contentList: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    minHeight: '300pt',
    // overflowY: 'scroll',
  },
}));

const StreamingInfo: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const isLowWidth = useMediaQuery(theme.breakpoints.down('lg'));
  const classes = useStyles();
  const { localization: { locale } } = useAppConfig();

  const cameraSettings = useCameraSettings();
  const isNotProxyMode = (cameraSettings?.activeOptions?.isProxyMode !== "true")

  const handleStopStreaming = () => {
    if (props.handleStopStreaming) {
      props.handleStopStreaming()
    }
  };

  const [activeEventStateString, activeEventState] = activeEpgEventStatesInfo(cameraSettings?.activeEpgEvent, locale);

  const [storeStateString, storeState] = steamingStatesInfo(cameraSettings?.streamingState, 'mp4File', true)

  const [streamStateString, streamState] = steamingStatesInfo(cameraSettings?.streamingState, 'rtmpStream', true)
  
  const buttons: ButtonsInRowArray = [
    {
      space :-1 ,
    }, 
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.stop_streaming }),
      onClick: handleStopStreaming,
      transparent: (props.streamingProfile?.id === NO_STREAM_ID)
    },
  ];

  return (
    <StreamingConfigurationCol
      onOptionBar={
        <ButtonsInRowElement
          id = "buttonsRow1"
          buttons={ (isNotProxyMode) ? buttons : [] }
        />
      }
    >
      <Box
        className={ (isLowWidth) ? undefined : classes.contentList }
      >
        <CameraStreamPreview
          key="preview"
          previewImageUrl={ cameraSettings?.urls?.liveOperatorImage }
          videoUrl={ (isNotProxyMode) ? cameraSettings?.urls?.liveOperatorVideoPlayer : undefined }
          fileType={ FileType.Operator }
        />

        <br/><br/>

        <VertGrid spacing={ 0 }>
          {(isNotProxyMode) &&
            <>
              <CircledLabel
                labelType={ getLabelFromState(storeState) }
                title={ props.intl.formatMessage({ id: LOCALIZATION.recording_to_storage }) }
                stateText={ storeStateString }
              />
              <CircledLabel
                labelType={ getLabelFromState(streamState) }
                title={ props.intl.formatMessage({ id: LOCALIZATION.streaming }) }
                stateText={ streamStateString }
              />
            </>
          }
          <CircledLabel
            labelType={ getLabelFromState(activeEventState) }
            title={ props.intl.formatMessage({ id: LOCALIZATION.active_epg_event }) }
            stateText={ activeEventStateString }
          />
        </VertGrid>
      </Box>
    </StreamingConfigurationCol>
  );
};


export default injectIntl(StreamingInfo);
