// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import LogoStyled, { classes } from './Styled';
import ccLogoMainImage from '../../../Icons/logo_cybercamera.svg';


export enum CCLogoStyle {
  Main = 'main',
  Toolbar = 'toolbar',
}

type Props = Readonly<{
  logoStyle: CCLogoStyle;
}>;


const CCLogo: React.FC<Props> = (props: Props) => (
  <LogoStyled>
    <img
      src={ (props.logoStyle === CCLogoStyle.Main) ? ccLogoMainImage : ccLogoMainImage }
      className={ (props.logoStyle === CCLogoStyle.Main) ? classes.main : classes.toolbar }
      alt="Logo"
    />
  </LogoStyled>
);


export default CCLogo;
