// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

export namespace Colors {
    export const clear = 'transparent'

    // menu
    export const menuBackground = 'white'
    export const menuMainHeaderText = '#FFFFFF'
    export const menuMainHeaderIcon = '#FFFFFF'
    export const menuMainHeaderBackground = '#97B1D3'
    export const menuMainHeaderSelectedBackground = '#61C0FF'
    export const menuSectonText = '#334D5E'
    export const menuSectionBackground = '#E8EFF8'
    export const menuSectionExpandCollpseIcon = '#90A0B5'
    export const menuSectionIcon = '#334D5E'
    export const menuText = '#334D5E'
    export const menuTextSelected = '#18A0FB'
    export const menuIconDefault = '#90A0B5'
    export const menuIconSelected = '#18A0FB'

    // Analytics
    export const analyticsCircleBorder = '#707682'
    export const analyticsTeam1 = '#EB5757'
    export const analyticsTeam2 = '#5DCA8A'
    export const analyticsGreyHeader = '#6C7378'
    export const analyticsBarBackgroundFill = ['#F5F5F5', '#FCFCFC']
    export const analyticsBarStroke = '#707682'
    export const analyticsBarStrokeWidth = '1px'
    export const analyticsHeatmapText = '#FFFFFF'
    export const analyticsTime = '#707682'
    export const analyticsDisabledTeamColor = '#757F7F'

    // main app toolbar
    export const mainAppBarBackground = 'white'

    export const separator = '#C2CFE0'
    export const greyBackground = '#C5CFDF'
    export const blueBackground = '#79BEFA'
    export const blackBackground = 'black'
    export const mainGrey = '#C2CFE0'
    export const mainGreenWarning = '#51825a'
    export const darkGrey = '#C2CFE0'
    export const menuSelectedItemHover = '#42a5f5'
    export const mainBackground = 'white'
    export const menuDefaultHover = '#E1E1E1'
    export const secondaryBackground = '#F5F6F8'
    export const darkBlue = '#183E70'
    export const accentBlue = '#18A0FB'
    export const lightBlue = '#79BEF9'
    export const accentGrey = '#90A0B5'
    export const mainRed = '#FF6B6B'
    export const mainGreen = '#5DCA8A'
    export const onlineWarning = '#ff882d'
    export const white = 'white'
    export const mainTitle = 'black'
    export const textBlue = '#18A0FB'
    export const buttonBlue = '#18A0FB'
    export const buttonDisabled = '#A5AFBF'
    export const buttonOutlinedDisabled = '#C4C4C4'
    export const cicrcleGreen = '#5ECA8A'
    export const cicrcleRed = '#EB5757'
    export const cicrcleYellow = '#F29949'
    export const cicrcleOlive = '#969918'
    export const cicrcleDarkGreen = '#0E8836'
    export const boxBlueGrayLight = '#CEDAD3'
    export const boxGreenOlive = '#70CF97'
    export const black = '#000000'
    export const lightGrey = '#E0E0E0'
    export const boxRed = '#EB5757'
    export const backgroundGrey = '#E5E5E5'
    export const headingMain = '#334D5E' // text Color
}

export namespace ChartingColors {
    export const humidity = '#947ffa'

    export const temperature1 = '#f51b1b'
    export const temperature2 = '#d41747'
    export const temperature3 = '#e82382'
    export const temperature4 = '#d12411'

    export const dewPointTemperature = '#2003ad'

    export const load1 = '#969918'
    export const load2 = '#ad8b10'
    export const load3 = '#8c5308'
    export const load4 = '#d68f13'

    export const power1 = '#097538'
    export const power2 = '#069645'
    export const power3 = '#14b85c'
    export const power4 = '#08c95d'
    export const power5 = '#075222'
    export const power6 = '#023d17'
    export const power7 = '#006b34'

    export const voltage1 = '#306df0'
    export const voltage2 = '#1f53c2'
    export const voltage3 = '#1f45cf'

    export const dontmatter = '434c6b'
}
