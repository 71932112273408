import { QueryKey, useQuery, useQueryClient, UseQueryOptions } from "react-query"
import { CamerasStatisticResponse } from "../types"
import { getCamerasStatistics, getCameraStatisticKey } from "../fetch"
import { QueryHookReturnType } from "../../../types"


interface IProps<TData = unknown> {
    /**
     * Please note that the default settings are already set in the hook, including the polling time. If necessary, you can make an override.
     */
    options: Omit<UseQueryOptions<TData, unknown, TData, QueryKey>, 'queryKey' | 'queryFn'>
}

export const useCamerasStatistic = (props?: IProps<CamerasStatisticResponse | undefined>): QueryHookReturnType<CamerasStatisticResponse | undefined> => {
    const { options } = props ?? {};
    const key = getCameraStatisticKey()
    const queryClient = useQueryClient();
    const query = useQuery<CamerasStatisticResponse | undefined>(key, async () => {
        const res = await getCamerasStatistics()
        return res;
    }, {
        staleTime: Infinity,
        cacheTime: 60_000,
        refetchInterval: (15_000),       // 15 sec
        keepPreviousData: true,
        ...options,
    })
    const resetCache = () => {
        queryClient.invalidateQueries(key)
    }
    return { ...query, resetCache }
}

// export const useCameraStatisticCache = () => {
//     const queryClient = useQueryClient();
//     const cache = queryClient.getQueryData(getCameraStatisticKey())
// }