// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import {
  UseFetchProps,
  useFetchStatPossessionPerMinute,
  flushCache,
  getCached,
} from '../fetch';
import { AnalyticsStatPossessionPerMinute } from '../AnalyticsStatPossessionPerMinute';


type Result = Readonly<{
  status: string;
  data: AnalyticsStatPossessionPerMinute | undefined;
  handleFlushData: () => void;
}>;


export const useAnalyticsStatPossessionPerMinute = (props: UseFetchProps): Result => {
  const fetchedArchive = useFetchStatPossessionPerMinute(props);
  const data = getCached(props);

  return ({
    status: fetchedArchive.status,
    data: data?.data,
    handleFlushData: flushCache,
  });
};

