// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useQuery, UseQueryResult } from 'react-query';

import { getKey, fetchData } from '../fetch';
import ResponseSingle from '../../_Networking/ReactQuery/ResponseSingle';
import StreamingProfileOptions from '..';


export const useFetchStreamingSettingsProfileOptions = (enableToFetch: boolean = true): UseQueryResult<ResponseSingle<StreamingProfileOptions>> => {
  const queryKey = getKey();

  return useQuery<ResponseSingle<StreamingProfileOptions>>({
    queryKey,
    queryFn: () => fetchData(),
    enabled: !!enableToFetch,
  });
};

