// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey, QueryObserverOptions, useQuery } from 'react-query';

import { PAGE_LIMIT } from '../../../constants';
import { queryClient } from '../../../AppProviders';
import { getAllFetchedItems } from './utils';
import ResponseMany from './ResponseMany';
import ResponseSingle from './ResponseSingle';

export enum ResultStatus {
  Success = 'success',
  Error = 'error',
  Loading = 'loading',
  Idle = 'idle',
}

export type Result<Item> = Readonly<{
  data: ResponseMany<Array<Item>> | undefined;
  error: any | undefined;
  status: ResultStatus;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
}>;

export type ResultSingle<Item> = Readonly<{
  data: ResponseSingle<Item> | undefined;
  error: any | undefined;
  status: ResultStatus;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
}>;

type Parameters<Item> = Readonly<{
  queryKey: QueryKey;
  page: number;
  fetch: (queryKey: QueryKey, page: number) => Promise<ResponseMany<Array<Item>>>;
  config?: QueryObserverOptions<ResponseMany<Array<Item>>>;
  keepPreviousData?: boolean;
}>;


function getCachedDataPagedItems<Item>(queryKey: QueryKey): ResponseMany<Array<Item>> | undefined {
  return queryClient.getQueryData(queryKey);
}

function getCachedDataAllPagedItems<Item>(queryKey: QueryKey): ResponseMany<Array<Item>> | undefined {
  return getAllFetchedItems<Item>([queryKey]);
}

function getLastFetchedPagePagedItems<Item>(queryKeyWithoutPage: QueryKey): number {
  const cachedData = getCachedDataAllPagedItems<Item>([queryKeyWithoutPage]);

  if (!cachedData) { return 0; }

  return (Math.ceil(cachedData.data.length / PAGE_LIMIT) - 1);
}

/**
 * @deprecated Overengineering, it is better to describe each pagination separately, each may have its own characteristics. In addition, react-query provides sufficient sugar
 */
function usePagedItems<Item>(parameters: Parameters<Item>): Result<Item> {
  const fetchFunc = () => {
    return parameters.fetch([parameters.queryKey, parameters.page], parameters.page)
  };

  const data = useQuery<ResponseMany<Array<Item>>, any>(parameters.queryKey, fetchFunc, {
    ...parameters.config,
    keepPreviousData: (parameters.keepPreviousData !== undefined) ? parameters.keepPreviousData : true,
  });

  return ({
    data: data.data,
    error: data.error,
    status: data.status as ResultStatus,
    isSuccess: data.isSuccess,
    isLoading: data.isLoading,
    isError: data.isError,
  });
}

/**
 * @deprecated It is better to pass all util functions together with a hook
 * @see {src/Data/types.ts} - можно расширить тип и добавить дополнительный методы
 */
export {
  getCachedDataAllPagedItems,
  getCachedDataPagedItems,
  getLastFetchedPagePagedItems,
  usePagedItems,
};
