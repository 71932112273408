// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import CameraContext from '../HWCameraSettings/fetch/Context'
import { GpioPressAction, GpioPressActionsDataChangeSubscriber } from './Types'

export class GpioPressActionsDataHelper {
    static get pressActions(): GpioPressAction[] {
        return CameraContext.current?.gpioPressActionsDataProvider.pressActions ?? []
    }

    static subscribeToGpioPressActionsChange(subscriber: GpioPressActionsDataChangeSubscriber) {
        const dataProvider = CameraContext.current?.gpioPressActionsDataProvider
        dataProvider?.subscribeOnChanges(subscriber)
    }

    static unsubscribeFromGpioPressActionsChange(subscriber: GpioPressActionsDataChangeSubscriber) {
        const dataProvider = CameraContext.current?.gpioPressActionsDataProvider
        if (dataProvider) {
            dataProvider.unsubscribeFromChanges(subscriber)
        }
    }
}
