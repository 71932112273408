// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Theme } from '@mui/material';
import { Toolbar } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import AnyChildren from '../../Tools/AnyChildren';
import { MainAppBarHeight } from '.';

const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbar: {
    minHeight: MainAppBarHeight,
    maxHeight: MainAppBarHeight,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(3)
    }
  },
}));

type Props = Readonly<{
  className?: string;
  children?: AnyChildren;
}>;

const AppToolbar: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Toolbar
      className={ props.className || classes.toolbar }
      sx={{
        height: MainAppBarHeight,
        minHeight: MainAppBarHeight,
        maxHeight: MainAppBarHeight,
        backgroundColor: 'none',
      }}
    >
      { props.children }
    </Toolbar>
  )
};


export default AppToolbar;
