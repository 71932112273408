// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useState } from 'react'
import { MetricOutputPeriodInterface, MetricOutputTimeInterface, MetricsChartSourceInterface, MetricTypeInterface } from '../../../../Data/Camera/Metrics/Types'
import { MetricsChartConfigurationHelper } from './Storage'
import { MetricsChartConfigurationDataInterface } from './Types'

export function sourceWithMetricsChartConfiguration(source: MetricsChartSourceInterface, configuration: MetricsChartConfigurationDataInterface): MetricsChartSourceInterface {
    const metrics = source.metrics.filter(metric => configuration.selected.includes(metric.dataKey))
    return { ...source, metrics: metrics, outputPeriod: configuration.outputPeriod, outputTime: configuration.outputTime }
}

export const useMetricsChartConfigurationForm = (submitSource: (source: MetricsChartSourceInterface) => void, source: MetricsChartSourceInterface) => {
    const initialForm = MetricsChartConfigurationHelper.getInitialConfigurableForm(source)
    const [form, setForm] = useState(initialForm)

    const onChangeMetricTypeEnabled: (metric: MetricTypeInterface, value: boolean) => void = (metric, value) => {
        let selected = form.selected

        if (value) {
            if (!selected.includes(metric.dataKey)) {
                selected.push(metric.dataKey)
            }
        } else {
            selected = selected.filter(key => key !== metric.dataKey)
        }

        setForm({ ...form, selected: selected })
    }

    const onChangeMetricOutputPeriod: (outputPeriod: MetricOutputPeriodInterface) => void = (outputPeriod) => {
        setForm({ ...form, outputPeriod: outputPeriod })
    }

    const onChangeMetricOutputTime: (outputTime: MetricOutputTimeInterface) => void = (outputTime) => {
        setForm({ ...form, outputTime: outputTime })
    }

    const onSubmit: () => void = async () => {
        // const metrics = initialForm.metrics.filter(metric => form.selected.includes(metric.dataKey))
        // const source: MetricsChartSourceInterface = { ...form, metrics: metrics }
        const source: MetricsChartSourceInterface = sourceWithMetricsChartConfiguration(initialForm, form)
        // ну нжужно сохранять всю форму. Там есть параметры, которые предназначены только для графиков. Например имена и юниты.
        // Вместо этого сохраним только то, что относится к MetricsChartConfigurationDataInterface

        const configuration: MetricsChartConfigurationDataInterface = {
            selected: form.selected,
            outputTime: form.outputTime,
            outputPeriod: form.outputPeriod
        }

        MetricsChartConfigurationHelper.setMetricsChartConfiguration(source.uniqueKey, configuration)

        submitSource(source)
    }

    return {
        onChangeMetricTypeEnabled,
        onChangeMetricOutputPeriod,
        onChangeMetricOutputTime,
        onSubmit,
        form
    }
}
