// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Card, CardContent, SxProps, Theme } from '@mui/material';
import { Colors } from '../../../Configuration/Styles/Colors';
// import { Colors } from './../../styles/Colors';
// import MainCardHeader from './../../components/CardHeader/MainCardHeader'


type Props = Readonly<{
  children: any;
  sx?: SxProps<Theme>;
}>;


const CardBox = (props: Props) => (
  <Card
    sx={ { 
      flexGrow: 1,
      overflow: 'hidden',
      borderRadius: '12px',
      background: Colors.mainBackground,
      boxShadow: '0px 4px 16px #00000014',
      ...props.sx,
    } }
    elevation={ Sizes.elevation }
  >
    {/*<MainCardHeader*/ }
    {/*  model={ {*/ }
    {/*    name: 'Name',*/ }
    {/*    barColor: Colors.cicrcleDarkGreen,*/ }
    {/*  } }*/ }
    {/*/>*/ }
    <CardContent>
      { props.children }
    </CardContent>
  </Card>
);


export default CardBox;
