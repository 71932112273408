// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useState } from 'react';
import { Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { useCalibrationValues } from '../../../../../../Data/Camera/Metrics/Calibration/Hooks'
import { ResponsiveContainer, ScatterChart, CartesianGrid, XAxis, YAxis, Scatter, Tooltip } from 'recharts'
import { CalibrationDataProvider } from '../../../../../../Data/Camera/Metrics/Calibration/DataProvider'
import { MagnetometerAxis } from '../../../../../../Data/Camera/Metrics/Calibration/Types'
import { Colors } from '../../../../../../Configuration/Styles/Colors'


type Props = Readonly<{}>;


const useStyles = makeStyles(() => createStyles({
  root: {
    flexGrow: 1,
    background: Colors.mainBackground
  },
  chart: {
    height: 800,
    width: 800,
    background: Colors.white
  },
  button: {
    minWidth: 32,
    minHeight: 32
  }
}));

const Calibration: React.FC<Props> = React.forwardRef((props: Props, ref: any) => {
  const classes = useStyles()
  const calibration = useCalibrationValues()
  const [axis, setAxis] = useState<MagnetometerAxis | undefined>(undefined)

  let data: any
  if (axis === 'Y') {
    data = calibration.yPoints
  } else if (axis === 'Z') {
    data = calibration.zPoints
  } else if (axis === 'X') {
    data = calibration.xPoints
  }

  const handleCalibration = () => {
    if (axis === undefined) {
      setAxis('X')
      CalibrationDataProvider.shared.startCalibrating('X')
    } else {
      CalibrationDataProvider.shared.stopCalibrating()
      setAxis(undefined)
    }
  }

  return (
    <div
      ref={ ref }
      className={ classes.root }
    >
      <div className={ classes.chart }>
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart>
            <CartesianGrid/>
            { axis === 'Y' && (
              <XAxis type="number" dataKey="x" name="x" domain={ [-40, 40] } />
            ) }
            { axis === 'Y' && (
              <YAxis type="number" dataKey="z" name="z" domain={ [-40, 40] } />
            ) }

            { axis === 'X' && (
              <XAxis type="number" dataKey="z" name="z" domain={ [-40, 40] } />
            ) }
            { axis === 'X' && (
              <YAxis type="number" dataKey="y" name="y" domain={ [-40, 40] } />
            ) }

            { axis === 'Z' && (
              <XAxis type="number" dataKey="x" name="x" domain={ [-40, 40] } />
            ) }
            { axis === 'Z' && (
              <YAxis type="number" dataKey="y" name="y" domain={ [-40, 40] } />
            ) }
            <Tooltip cursor={ { strokeDasharray: '3 3' } }/>
            <Scatter name={ axis } data={ data } fill="#8884d8"/>)
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <Button className={ classes.button } onClick={ handleCalibration }>
        { axis ?? 'GO' }
      </Button>
    </div>
  );
});


export default Calibration;
