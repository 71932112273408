// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import MainCardHeader from '../../../../Components/_Layout/CardHeader/Main'
import HeaterTemperatureController from './Components/TemperatureController/View'
import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import { useCaseHeaterReview } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks'
import { TemperatureControllerHeadingModelBuilder } from '../../../../Components/_Layout/CardHeader/Main/Builders'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content'
import { HeaterId } from '../../../../Data/Camera/Metrics/Types'
import LOCALIZATION from '../../../../Localization';


type Props = Readonly<{
  id: HeaterId;
} & WrappedComponentProps>;


const CardHeader: React.FC<Props> = (props: Props) => {
  const id = props.id;
  const heaterReview = useCaseHeaterReview(id, props.intl.formatMessage({ id: LOCALIZATION.itself }));
  const heaterTitle = props.intl.formatMessage({ id: (LOCALIZATION?.[id] || LOCALIZATION.NONE) });
  // const heaterTitle = getString(id);

  if (!heaterReview) {
    return (<RegularCardHeader title={ heaterTitle }/>);
  }

  return (
    <MainCardHeader model={ new TemperatureControllerHeadingModelBuilder(heaterReview).build(heaterTitle) }/>
  )
};

const CaseHeaterCard: React.FC<Props> = (props: Props) => (
  <MainCard>
    <CardHeader id={ props.id } intl={ props.intl } />
    <MainCardContent>
      <Grid sx={ { minHeight: 320 } }>
        <HeaterTemperatureController id={ props.id }/>
      </Grid>
    </MainCardContent>
  </MainCard>
);


export default injectIntl(CaseHeaterCard);
