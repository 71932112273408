// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Container, Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import CCLogo, { CCLogoStyle } from '../../Components/_BaseUI/CClogo/CCLogo';
import useStyles from './useStyles';

type Props = Readonly<{
} & WrappedComponentProps>;

const EmptyPage: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Grid className={ classes.root }>
      <Container className={ classes.container } maxWidth="xs">
        <Grid container spacing={ 5 }>
          <Grid item xs={ 12 }>
            <CCLogo logoStyle={ CCLogoStyle.Main }/>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
};


export default injectIntl(EmptyPage);
