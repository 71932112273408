// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useMutation } from 'react-query';

import { postDataStorageInfo, getKeyStorageInfo } from '../fetch/index';
import { queryClient } from '../../../AppProviders';
import StorageInfo from '..';


export enum ActionType {
  Post = 'post',
}

export type Action = Readonly<{
  settings?: Partial<StorageInfo>;
  type: ActionType;
}>;


export const useMutationStorageInfo = () => {
  return useMutation(
    (action: Action) => {
      switch (action.type) {
        case ActionType.Post: {
          return postDataStorageInfo(action.settings as Partial<StorageInfo>);
        }
        default:
          return Promise.reject(new Error('Invalid Settings Mutation Action'));
      }
    },
    {
      onSuccess: (data) => {
        if (data.error) {
          throw new Error(data.error);
        } else {
          queryClient.setQueryData(
              getKeyStorageInfo(),
              data,
          );
        }
      },
    },
  );
};


