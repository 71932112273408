// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { TemperatureControllerDataInterface } from '../Data/Camera/HWCameraSettings/CameraSettingsTemperature'
import { CameraInterface, CameraStatus } from '../Data/Camera/HWCameraSettings/Camera'
import { GpioPressActionsDataInterface } from '../Data/Camera/Gpio/Hooks'
import { GpioPressAction } from '../Data/Camera/Gpio/Types'
import { FanInterface, JetsonHardwareButtonsCollectionInterface, JetsonId, JetsonIdAll, JetsonNames } from '../Data/Camera/Jetson/Types'
import { MetricDataUnit, MetricTypeInterface } from '../Data/Camera/Metrics/Types'
import { intl } from '../Localization/LocalizationProvider';
import LOCALIZATION from '../Localization';
import RegularCardHeader from '../Components/_Layout/CardHeader/Regular'

export function delay(ms: number) {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, ms)
    })
}

export function abortableDelay(ms: number, abortSignal: AbortSignal) {
    return new Promise((resolve, reject) => {
        const error = Error('Aborted')

        if (abortSignal.aborted) {
            return reject(error)
        }
        const timeout = setTimeout(resolve, ms)
        abortSignal.addEventListener('abort', () => {
            clearTimeout(timeout)
            reject(error)
        })
    })
}
export const debounce = (n: number, fn: (...params: any[]) => any, immed: boolean = false) => {
    let timer: NodeJS.Timeout
    return function (this: any, ...args: any[]) {
        if (timer === undefined && immed) {
            fn.apply(this, args)
        }
        clearTimeout(timer)
        timer = setTimeout(() => fn.apply(this, args), n)
        return timer
    }
}

export function timeIntervalHours(hours: number): number {
    return hours * 60 * 60 * 1000
}

export function latestStartDate(millisecond: number): number {
    return Date.now() - millisecond
}

export function metricUnitMultiplier(unit: MetricDataUnit): number {
    if (unit === '%') {
        return 100
    } else {
        return 1
    }
}

export function metricMultiplier(metric: MetricTypeInterface): number {
    // Влажность приходит уже в процентах
    if (metric.identificationParameters.filter_name === 'Humidity') return 1
    return metricUnitMultiplier(metric.unit)
}

export interface FormattedMetricValueOptions {
    readonly name?: string
    readonly decimals?: number
    readonly displayUnit?: boolean
    readonly multiplyUnit?: boolean
}
export function formattedMetricUnit(unit: MetricDataUnit): string {
    const translate = intl().formatMessage;

    switch (unit) {
        case 'V':
            return translate({ id: LOCALIZATION.unit_v })
        case 'W':
            return translate({ id: LOCALIZATION.unit_w })

        case '%':
        case '˚C':
        case '˚':
            return unit
    }
}
export function formattedMetricValue<T extends number | undefined>(value: T, unit: MetricDataUnit, opts?: FormattedMetricValueOptions): Exclude<T, number> | string {
    if (value === undefined) { return undefined as Exclude<T, number> | string }
    const multiplyUnit = opts?.multiplyUnit === undefined ? true : opts?.multiplyUnit
    const val: number = (value as number) * (multiplyUnit ? metricUnitMultiplier(unit) : 1)
    let decimals = opts?.decimals
    if (decimals === undefined) {
        decimals = 1
    }
    const name = opts?.name
    const displayUnit = opts?.displayUnit === undefined ? true : opts?.displayUnit
    const formattedValue: string = (() => {
        switch (unit) {
            case '%':
                return `${val.toFixed(decimals)}`
            case 'V':
                return `${val.toFixed(decimals)}`
            case 'W':
                return `${val.toFixed(decimals)}`
            case '˚C':
                return `${val.toFixed(decimals)}`
            case '˚':
                return `${val.toFixed(decimals)}`
        }
    })()
    return [name, `${[formattedValue, displayUnit ? (formattedMetricUnit(unit)) : undefined].filter(text => text !== undefined).join('')}`].filter(text => text !== undefined).join(': ')
}

export interface FormattedStateOptions {
    readonly name?: string,
    readonly symbolize?: boolean
}

export function formattedState<T extends boolean | undefined>(state: T, opts?: FormattedStateOptions): Exclude<T, boolean> | string {
    if (state === undefined) { return undefined as Exclude<T, boolean> | string }
    const symbol: string | undefined = (opts?.symbolize === true) ? (state ? '🔴 ‎ ' : '🟢 ‎ ') : undefined
    const translate = intl().formatMessage;
    const stateString = [`${state ? translate({ id: LOCALIZATION.on }) : translate({ id: LOCALIZATION.off })}`, symbol].join(' ')
    return [opts?.name, stateString].filter(text => text !== undefined).join(': ')
}

export namespace JetsonTools {

    export function chooseObject<T>(id: JetsonId, objects: [T, T]): T {
        switch (id) {
            case JetsonNames.Jetson1:
                return objects[0]
            case JetsonNames.Jetson2:
                return objects[1]
        }
    }

    export function getTemperatureControllerData(camera: CameraInterface, id: JetsonId): TemperatureControllerDataInterface | undefined {
        if (id === JetsonNames.Jetson1) {
            return camera.settings?.temperatureControllerDataJ1
        } else if (id === JetsonNames.Jetson2) {
            return camera.settings?.temperatureControllerDataJ2
        } else { throw new Error('Unexpected jetson id ' + id) }
    }

    export function getLastCpuTemperature(camera: CameraInterface, id: JetsonId): number | undefined {
        if (id === JetsonNames.Jetson1) {
            return camera.settings?.temperatureControllerDataHeater.lastData.jetson1
        } else if (id === JetsonNames.Jetson2) {
            return camera.settings?.temperatureControllerDataHeater.lastData.jetson2
        } else { throw new Error('Unexpected jetson id ' + id) }
    }

    export function getState(camera: CameraInterface | undefined, id: JetsonId): boolean {
        if (id === JetsonNames.Jetson1) {
            return (camera?.settings?.deviceStates?.jetson1 ?? "off") === "on"
        } else if (id === JetsonNames.Jetson2) {
            return (camera?.settings?.deviceStates?.jetson2 ?? "off") === "on"
        } else { throw new Error('Unexpected jetson id ' + id) }
    }

    export function getName(id: JetsonId): string {
        const translate = intl().formatMessage;

        if (id === JetsonNames.Jetson1) {
            return translate({ id: LOCALIZATION.j1name })
        } else if (id === JetsonNames.Jetson2) {
            return translate({ id: LOCALIZATION.j2name })
        } else { throw new Error('Unexpected jetson id ' + id) }
    }

    export function getIsAvailablePower(gpioPressActionsData: GpioPressActionsDataInterface, id: JetsonId): boolean {
        if (id === JetsonNames.Jetson1) {
            return gpioPressActionsData.isAvailablePowerJetson1
        } else if (id === JetsonNames.Jetson2) {
            return gpioPressActionsData.isAvailablePowerJetson2
        } else { throw new Error('Unexpected jetson id ' + id) }
    }

    export function getIsAvailableFactoryReset(gpioPressActionsData: GpioPressActionsDataInterface, id: JetsonId): boolean {
        if (id === JetsonNames.Jetson1) {
            return gpioPressActionsData.isAvailableFactoryResetJetson1
        } else if (id === JetsonNames.Jetson2) {
            return gpioPressActionsData.isAvailableFactoryResetJetson2
        } else { throw new Error('Unexpected jetson id ' + id) }
    }

    export function getIsAvailableReset(gpioPressActionsData: GpioPressActionsDataInterface, id: JetsonId): boolean {
        if (id === JetsonNames.Jetson1) {
            return gpioPressActionsData.isAvailableResetJetson1
        } else if (id === JetsonNames.Jetson2) {
            return gpioPressActionsData.isAvailableResetJetson2
        } else { throw new Error('Unexpected jetson id ' + id) }
    }

    export function getIsAvailableSoftShutdown(gpioPressActionsData: GpioPressActionsDataInterface, id: JetsonId): boolean {
        if (id === JetsonNames.Jetson1) {
            return gpioPressActionsData.isAvailableSoftShutdownJetson1
        } else if (id === JetsonNames.Jetson2) {
            return gpioPressActionsData.isAvailableSoftShutdownJetson2
        } else { throw new Error('Unexpected jetson id ' + id) }
    }

    export function getIsAvailableFirmwareUpdate(gpioPressActionsData: GpioPressActionsDataInterface, id: JetsonId): boolean {
        if (id === JetsonNames.Jetson1) {
            return gpioPressActionsData.isAvailableFirmwareUpdateJetson1
        } else if (id === JetsonNames.Jetson2) {
            return gpioPressActionsData.isAvailableFirmwareUpdateJetson2
        } else { throw new Error('Unexpected jetson id ' + id) }
    }

    export function getGpioPressActionsFromHardwareButtonsCollection(collection: JetsonHardwareButtonsCollectionInterface, id: JetsonId) {
        const names: GpioPressAction[] = []
        const states = collection.states

        if (id === JetsonNames.Jetson1) {
            if (states.power) {
                names.push('j1power')
            }
            if (states.reset) {
                names.push('j1reset')
            }
            if (states.recovery) {
                names.push('j1recovery')
            }
        } else if (id === JetsonNames.Jetson2) {
            if (states.power) {
                names.push('j2power')
            }
            if (states.reset) {
                names.push('j2reset')
            }
            if (states.recovery) {
                names.push('j2recovery')
            }
        } else { throw new Error('Unexpected jetson id ' + id) }

        return names
    }

    export function getSoftShutdownGpioPressAction(id: JetsonId): GpioPressAction {
        if (id === JetsonNames.Jetson1) {
            return 'j1shutdown'
        } else if (id === JetsonNames.Jetson2) {
            return 'j2shutdown'
        } else { throw new Error('Unexpected jetson id ' + id) }
    }

    export function getSoftRebootGpioPressAction(id: JetsonId): GpioPressAction {
        if (id === JetsonNames.Jetson1) {
            return 'j1reboot'
        } else if (id === JetsonNames.Jetson2) {
            return 'j2reboot'
        } else { throw new Error('Unexpected jetson id ' + id) }
    }

    export function criticalStates(camera: CameraInterface): string[] {
        const deviceStates = camera.settings?.deviceStates
        const criticals: string[] = []

        if (deviceStates) {
            const translate = intl().formatMessage;

            if (deviceStates.jetson1 !== "on") {
                criticals.push(`${translate({ id: LOCALIZATION.j1name })}: ${translate({ id: LOCALIZATION.off })}`)
            }
            if (deviceStates.jetson2 !== "on") {
                criticals.push(`${translate({ id: LOCALIZATION.j2name })}: ${translate({ id: LOCALIZATION.off })}`)
            }
        }
        return criticals
    }
}

export function CameraNotAvailable() {
    return (
        <RegularCardHeader title={ intl().formatMessage({ id: LOCALIZATION.camera_not_available }) }/>
    )
}

export type Modify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R

export function safeNumber(text: string | undefined): number | undefined {
    if (!text) return undefined

    const n = parseFloat(text)
    if (isNaN(n)) { return undefined }
    return n
}

export function checkArraysEquality<T>(array1: T[], array2: T[]): boolean {
    const sortedArray2 = array2.slice().sort()
    return array1.length === array2.length && array1.slice().sort().every(function(value, index) {
        return value === sortedArray2[index]
    })
}

export namespace CameraTools {
    export function fans(camera: CameraInterface): FanInterface[] {
        const translate = intl().formatMessage;
        const fans: FanInterface[] = [
            {
                name: translate({ id: LOCALIZATION.j1name }),
                isOn: camera.settings?.temperatureControllerDataJ1.state === 'on'
            },
            {
                name: translate({ id: LOCALIZATION.j2name }),
                isOn: camera.settings?.temperatureControllerDataJ2.state === 'on'
            }
        ]

        return fans
    }

    export function criticals(camera: CameraInterface): string[] {
        const criticals: string[] = []

        JetsonIdAll.forEach(id => {
            const temperatureControllerData = JetsonTools.getTemperatureControllerData(camera, id)
            const temperature = JetsonTools.getLastCpuTemperature(camera, id)
            const name = JetsonTools.getName(id)

            if (temperatureControllerData !== undefined && temperature !== undefined && temperatureControllerData.critical !== undefined) {
                if (temperature >= temperatureControllerData.critical) {
                    const cpuTempString = formattedMetricValue(temperature, '˚C')
                    criticals.push([name, cpuTempString].join(': ') + ' ⚠️')
                }
            }
        })

        return criticals
    }

    export function statusString(cameraStatus: CameraStatus): string {
        const translate = intl().formatMessage;

        switch (cameraStatus) {
            case CameraStatus.On:
                return translate({ id: LOCALIZATION.online })
            case CameraStatus.Off:
                return translate({ id: LOCALIZATION.offline })
            case CameraStatus.ShuttingDown:
                return translate({ id: LOCALIZATION.shutting_down })
        }
    }
}


export function pad(num: Number, size:number): string {
    const s = "000000000" + num;
    return s.substr(s.length-size);
}
declare global {
    interface Number {
        pad: (size:number) => string;
    }
    interface String {
        capitalizeFirstLetter: () => string;
        getUrlExtension: () => string | null;
    }
}

// eslint-disable-next-line
Number.prototype.pad = function(size:number) : string {
    return pad(this, size)
}

export function capitalizeFirstLetter(str: String): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
// eslint-disable-next-line
String.prototype.capitalizeFirstLetter = function() : string {
    return capitalizeFirstLetter(this)
}

function getUrlExtension( urlStr: string | undefined | null ): string | null {
    try {
        if (!urlStr) {
            return null;
        }
        const url = new URL(urlStr);
        // the .pathname method returns the path
        const pathname = url.pathname;
        if (!pathname || pathname.length === 0) {
            return null;
        }
        // now get the file name
        let filename = pathname.split('/').reverse()[0]
        // returns "design.swf"
        let ext = filename.split('.')[1];
        return ext?.trim() || null;
    } catch (ignore) {
        // console.log(`getUrlExtension(${urlStr}) - Error: ${error}`)
    }
    return null;
}
// eslint-disable-next-line
String.prototype.getUrlExtension = function() : string | null {
    return getUrlExtension(this.toString())
}