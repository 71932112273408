// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import cn from 'classnames';
import { Card } from '@mui/material';

import AnyChildren from '../../../../Tools/AnyChildren';
import { createStyles, makeStyles } from '@mui/styles';


type Props = Readonly<{
    elevation?: number;
    className?: string;
    children: AnyChildren;
}>;


const useStyles = makeStyles(() => createStyles({
    card: {
        flexGrow: 1,
        borderRadius: 23,
        boxShadow: '0px 4px 16px #00000014',
    },
}));


const ModalCard: React.FC<Props> = React.forwardRef((props: Props, ref: any) => {
    const classes = useStyles();

    return (
      <Card
        ref={ ref }
        elevation={ props.elevation }
        className={ cn(classes.card, props.className) }
      >
          { props.children }
      </Card>
    );
});


export default ModalCard;
