// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import Camera from '../../../Data/Camera/ActiveCamera/Camera';


const getCameraName = (camera: Camera | undefined, isSuperAdmin: boolean, isServer: boolean): string => (
  (!camera) ? "NA" : (isSuperAdmin && isServer) ? `${ camera.cameraId } (${ camera.name })` : camera.name
);


export {
  getCameraName,
};
