// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>


import { Box } from "@mui/material";
import { AnalyticsGameEventType } from "./AnalyticsGameEvent";
import { AnalyticsSportType } from "../AnalyticsTypes";

export const markerForAnalyticsGameEventType = (type: AnalyticsGameEventType | undefined, sportType: AnalyticsSportType | undefined) => {
    const sortIcon = () => {
        switch (sportType) {
            case AnalyticsSportType.Football:
            case AnalyticsSportType.MiniFootball:
                return '⚽️';
            case AnalyticsSportType.Basketball:
                return '🏀';
            case AnalyticsSportType.Hockey:
                return '🏒';
            case AnalyticsSportType.HockeyWithBall:
                return '🏑';
            case AnalyticsSportType.Handball:
                return '🏐';
        }
        return ''
    }

    switch (type) {
        case AnalyticsGameEventType.GameStart:
            return null;
        case AnalyticsGameEventType.GameEnd:
            return null;
        case AnalyticsGameEventType.TimeStart:
        case AnalyticsGameEventType.OvertimeStart:
        case AnalyticsGameEventType.PenaltyStart:
            return null;
            // return (
            //     <Box sx={{
            //         marginTop: 0.5,
            //     }}>
            //         <IconArrowRight />
            //     </Box>
            // );
        case AnalyticsGameEventType.TimeEnd:
        case AnalyticsGameEventType.OvertimeEnd:
        case AnalyticsGameEventType.PenaltyEnd:
            return null;
            // return (
            //     <Box sx={{
            //         marginTop: 0.5,
            //     }}>
            //         <IconArrowLeft />
            //     </Box>
            // );
        case AnalyticsGameEventType.TimeOutStart:
            return (
                <Box sx={{
                    paddingLeft: 1,
                    paddingRight: 1,
                }}>
                    { '⏸' }
                </Box>
            );
        case AnalyticsGameEventType.TimeOutEnd:
            return (
                <Box sx={{
                    paddingLeft: 1,
                    paddingRight: 1,
                }}>
                    { '⏯' }
                </Box>
            );
        case AnalyticsGameEventType.Goal:
            return (
                <Box sx={{
                    paddingLeft: 1,
                    paddingRight: 1,
                }}>
                    { sortIcon() }
                </Box>
            );
    }
    return null
}