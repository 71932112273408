// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import { fetchJavaManyJSONData } from '../../../_Networking/ReactQuery/fetch';
import { queryCache, queryClient } from '../../../../AppProviders';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import RemoteCameraState, { RemoteCameraStateValue } from '..';


type FetchProps = Readonly<{
  page: number;
  limit: number;
  filterByCameraID?: string;
  sort?: string,
  stateFilter?: RemoteCameraStateValue,
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'remote-cameras-states';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

export const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.page,
  props.limit,
  props.filterByCameraID,
  props.sort,
  props.stateFilter,
]);


// const getAllCached = () => (
//   queryClient.getQueryData<RemoteCameraState[]>(getKeyWithoutPage())
// );

export const getCached = (props: FetchProps) => (
  queryClient.getQueryData<ResponseMany<RemoteCameraState[]>>(getKey(props))
);

export const flushCamerasStatesCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};


export const fetchData = async (props: FetchProps): Promise<ResponseMany<RemoteCameraState[]>> => {
  // const cacheData = getCached(props);

  // if (cacheData) { return cacheData; }

  const offset = (props.page * props.limit);
  let params = `?max=${props.limit}&first=${offset}`;

  if (props.filterByCameraID) {
    params += `&filter_cameraList.cameraId=${props.filterByCameraID}`;
  }
  return await fetchJavaManyJSONData<RemoteCameraState>(
    `/cameras/states${params}`,
    'cameras-states',
    {
      limit: props.limit,
      offset,
    },
    {
      method: 'GET',
    },
  );
};
