// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {  SxProps, Theme } from '@mui/material';

import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import MainButton from '../../../../Components/_BaseUI/MainButton'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content';
import LOCALIZATION from '../../../../Localization';
import VertGrid from '../../../../Components/_Layout/VertGrid';
import { getMenuLink } from '../../../../Menu/Data/MenuStructure';
import { MenuID } from '../../../../Menu/Data/MenuElement';
import { useNavigate } from 'react-router-dom';
import AnalyticsGameArchive from '../../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import { useAnalyticsStatPossessionHeatmap } from '../../../../Data/Analytics/AnalyticsStatPossessionHeatmap/hook';
import { useAnalyticsStatScore } from '../../../../Data/Analytics/AnalyticsStatScore/hook';
import AnalyticsHeatmapReportView from './AnalyticsHeatmapReportView';
import { GetTeamRefById } from '../../../../Data/Analytics/AnalyticsTeamRef/hook';

type Props = Readonly<{
  gameResultInfo: AnalyticsGameArchive | undefined
  sx?: SxProps<Theme>;
  getTeamRefByExternalId?: GetTeamRefById;
} & WrappedComponentProps>;


const GameTeamTacticalReportCard: React.FC<Props> = (props: Props) => {
  const gameId = props.gameResultInfo?.id || ""

  const { data: scoreStat } = useAnalyticsStatScore({ gameId });
  const { data: heatmapStat } = useAnalyticsStatPossessionHeatmap({ gameId });
  
  const navigate = useNavigate();

  const handleConfigure = () => {
    const menuLink = getMenuLink(MenuID.AnalyticsGameReportTactical, [gameId]);
    if (menuLink) {
      navigate(menuLink);
    }
  }

  if (!gameId) {
    return null;
  }

  return (
    <MainCard>
      <RegularCardHeader title={ props.intl.formatMessage({ id: LOCALIZATION.team_tactical_report }) }/>
      <MainCardContent>
        <VertGrid spacing={ 4 } >

          { 
            heatmapStat?.time_intervals?.map((item, index) => {
              const scoreInfo = (scoreStat?.time_intervals && index < scoreStat.time_intervals.length) ? scoreStat?.time_intervals[index] : undefined
              return (
                <AnalyticsHeatmapReportView 
                  gameResultInfo = { props.gameResultInfo }
                  heatmapInfo = { item }
                  scoreInfo = { scoreInfo }
                  getTeamRefByExternalId = { props.getTeamRefByExternalId }
                />
              )
            })
          }

          <Grid container spacing={ 1 } 
              direction="row"
              justifyContent="center" 
            >
              <Grid item>
                <MainButton 
                  onClicked={ handleConfigure } 
                  title={ props.intl.formatMessage({ id: LOCALIZATION.details }) } 
                  sx={{ paddingLeft: 3, paddingRight: 3 }} 
                />
              </Grid>
            </Grid>
        </VertGrid>
      </MainCardContent>
    </MainCard>
  );
};

export default injectIntl(GameTeamTacticalReportCard);
