// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

function getMinMaxValue(min: number, max: number) {
    let value = 0;

    return {
        get current() {
            return value;
        },
        set current(val: number) {
            if (val > max) {
                value = max;
            } else if (val < min) {
                value = min;
            } else {
                value = val;
            }
        }
    }
}

export {
    getMinMaxValue,
};
