import { createContext } from "react";
import Order from "../../Data/_Networking/ReactQuery/Order";

export interface IPaginationContextValues {
    /**
     * offset to set the initial position of the list
     */
    offset: number,
    /**
     * count of elements to take
     */
    limit: number,
    /**
     * to change page to be shown
     * @param value номер страницы
     */
    setPage?: (value: number) => void,
    /**
     * Order by created date
     */
    orderByDate?: Order,

    setOrder?: (order: Order) => void
}
/**
 * Context for keeping/changing the state of pagintation
 */
export const PaginationContext = createContext<IPaginationContextValues>({
    offset: 0,
    limit: 0,
});