// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { Box, Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { createStyles, makeStyles } from '@mui/styles';

import VertGrid from '../../../../Components/_Layout/VertGrid';
import AnalyticsGameArchive, { AnalyticsGameShared } from '../../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import AnalyticsHeatmapView from '../../../../Components/_ChartsAndBars/AnalyticsHeatmapView';
import { AnalyticsStatPossessionHeatmapForTime } from '../../../../Data/Analytics/AnalyticsStatPossessionHeatmap/AnalyticsStatPossessionHeatmap';
import { AnalyticsStatScoresForTime } from '../../../../Data/Analytics/AnalyticsStatScore/AnalyticsStatScore';
import { Sizes } from '../../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../../Configuration/Styles/Fonts';
import { Colors } from '../../../../Configuration/Styles/Colors';
import { analyticsSplitLeftRightTeam } from '../../../../Data/Analytics/AnalyticsSplitLeftRightTeam';
import { analyticsTimeIntervalName } from '../../../../Data/Analytics/AnalyticsTypes';
import { GetTeamRefById } from '../../../../Data/Analytics/AnalyticsTeamRef/hook';

const useStyles = makeStyles(() => createStyles({
    teamTextLeft: {
        fontFamily: Fonts.main,
        fontSize: Sizes.heading,
        fontWeight: Sizes.regularWeight,
        color: Colors.mainTitle,
        textAlign: 'left',
    },
    teamTextRight: {
        fontFamily: Fonts.main,
        fontSize: Sizes.heading,
        fontWeight: Sizes.regularWeight,
        color: Colors.mainTitle,
        textAlign: 'right',
    },
    timeText: {
      fontFamily: Fonts.main,
      fontSize: Sizes.heading,
      fontWeight: Sizes.regularWeight,
      color: Colors.analyticsTime,
      textAlign: 'center',
    },

    scoreTextLeft: {
        fontFamily: Fonts.main,
        fontSize: Sizes.analyticsScore,
        fontWeight: Sizes.mediumWeight,
        color: Colors.mainTitle,
        textAlign: 'left',
    },
    scoreTextRight: {
        fontFamily: Fonts.main,
        fontSize: Sizes.analyticsScore,
        fontWeight: Sizes.mediumWeight,
        color: Colors.mainTitle,
        textAlign: 'right',
    },
  }));

type Props = Readonly<{
  gameResultInfo: AnalyticsGameArchive | undefined
  heatmapInfo: AnalyticsStatPossessionHeatmapForTime | undefined
  scoreInfo: AnalyticsStatScoresForTime | undefined
  getTeamRefByExternalId?: GetTeamRefById;
} & WrappedComponentProps>;


const AnalyticsHeatmapReportView: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const teamsInfo = analyticsSplitLeftRightTeam(props.gameResultInfo, props.scoreInfo, props.heatmapInfo, props.getTeamRefByExternalId)

  const teamName = (teamId: string | undefined) => {
    return props.getTeamRefByExternalId?.(teamId)?.shortName || teamId || ""
  }

  return (
    <VertGrid spacing={ 1 } >
        <Grid container direction="row" alignItems="baseline" >

            <Grid item xs={ 4 }>
                <Box className={ classes.teamTextLeft }>
                    { teamsInfo.teamLeft?.title }
                </Box>
            </Grid>

            <Grid item xs={ 4 }>
                <Box className={ classes.timeText }>
                    { analyticsTimeIntervalName(props.scoreInfo?.type, props.scoreInfo?.type_order_num) }
                </Box>
            </Grid>

            <Grid item xs={ 4 }>
                <Box className={ classes.teamTextRight }>
                    { teamsInfo.teamRight?.title }
                </Box>
            </Grid>        
        </Grid>

        <Grid container direction="row" alignItems="center" >

            <Grid item xs={ 1 }>
                <Box className={ classes.scoreTextLeft }>
                    { teamsInfo.teamLeft?.score }
                </Box>
            </Grid>

            <Grid item xs={ 10 }>
                <AnalyticsHeatmapView 
                    maxWidth={ 550 }
                    data={ props.heatmapInfo?.interval_stat }
                    sportType={ props.gameResultInfo?.sport_type }
                    team1Name={ teamName(props.gameResultInfo?.team1) }
                    team2Name={ teamName(props.gameResultInfo?.team2) }
                    team1Color={AnalyticsGameShared.team1Color}
                    team2Color={AnalyticsGameShared.team2Color}
                />
            </Grid>

            <Grid item xs={ 1 }>
                <Box className={ classes.scoreTextRight }>
                    { teamsInfo.teamRight?.score }
                </Box>
            </Grid>
        </Grid>
    </VertGrid>
  );
};

export default injectIntl(AnalyticsHeatmapReportView);
