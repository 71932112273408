// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { JetsonNames } from "../Jetson/Types"
import { HeaterId, SensorPlace, TemperatureControllerID } from "../Metrics/Types"
import { HeaterTemperatureControllerDataInterface, TemperatureControllerFormInterface } from "./CameraSettingsTemperature"
import { CameraSettingsInterface } from "./CameraSettings"

export interface CameraReferenceInterface {
    name: string
}

export enum CameraStatus {
    On = 'on',
    Off = 'off',
    ShuttingDown = 'shutting_down',
}

export interface CameraInterface extends CameraReferenceInterface {
    status: CameraStatus
    settings?: CameraSettingsInterface

    getTemperatureControllerData(id: SensorPlace): TemperatureControllerFormInterface|undefined
    getHeaterTemperatureControllerData(id: HeaterId): HeaterTemperatureControllerDataInterface|undefined
}

export class CameraInstance implements CameraInterface {
    status: CameraStatus
    settings?: CameraSettingsInterface
    name: string

    constructor(name: string, status: CameraStatus, settings?: CameraSettingsInterface) {
        this.status = status
        this.settings = settings
        this.name = name
    }

    static newCamera(name: string) {
        return new this(name, CameraStatus.On, undefined);
    }
    static fromInterface(camera: CameraInterface) {
        return new this(camera.name, camera.status, camera.settings);
    }

    getTemperatureControllerData(id: SensorPlace): TemperatureControllerFormInterface|undefined {
        switch (id) {
            case JetsonNames.Jetson1:
                return this.settings?.temperatureControllerDataJ1
            case JetsonNames.Jetson2:
                return this.settings?.temperatureControllerDataJ2
            case TemperatureControllerID.Heater:
                return this.settings?.temperatureControllerDataHeater
            case TemperatureControllerID.Heater1:
                return this.settings?.temperatureControllerDataHeater1
            case TemperatureControllerID.Heater2:
                return this.settings?.temperatureControllerDataHeater2
            case TemperatureControllerID.Heater3:
                return this.settings?.temperatureControllerDataHeater3
            case TemperatureControllerID.Heater4:
                return this.settings?.temperatureControllerDataHeater4
            case TemperatureControllerID.HeaterCAM1:
                return this.settings?.temperatureControllerDataHeaterCam1
            case TemperatureControllerID.HeaterCAM2:
                return this.settings?.temperatureControllerDataHeaterCam2
            case TemperatureControllerID.HeaterCAM3:
                return this.settings?.temperatureControllerDataHeaterCam3
        }
    }

    getHeaterTemperatureControllerData(id: HeaterId): HeaterTemperatureControllerDataInterface|undefined {
        const data = this.getTemperatureControllerData(id)
        if (data !== undefined && "lastData" in data) {
            return data as HeaterTemperatureControllerDataInterface
        }
    }
}

export type CameraDataChangeSubscriber = (data: CameraInterface) => void
export type CameraReferenceChangeSubscriber = (reference: CameraReferenceInterface) => void
