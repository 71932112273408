// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { isArray } from 'lodash';
import { Grid } from '@mui/material';

import Slider from '../../../../../Components/_BaseUI/Slider';
import FieldSet, { FieldSetInputType } from '../../../../../Components/_BaseUI/FieldSet';
import { getMinMaxValue } from './helpers';


type Props = Readonly<{
  label: string;
  value: number;
  min: number;
  max: number;
  step?: number;
  onChange: (value: number) => void;
}>;


const SX = {
  paddingLeft: 4,
  paddingRight: 0,
};

const SliderWrapper: React.FC<Props> = (props: Props) => {
  const limiterValue = getMinMaxValue(props.min, props.max);

  const handleChangeInInput = React.useCallback((value: number | null) => {
    if (value !== null) {
      limiterValue.current = value;

      props.onChange(limiterValue.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeInSlider = React.useCallback((value: number | number[]) => {
    if (!isArray(value)) {
      limiterValue.current = value;

      props.onChange(limiterValue.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      justifyContent="flex-end"
      sx={ SX }
    >
      <Grid
        item
        xs={ 12 }
      >
        <FieldSet
          key={ 'Volume' }
          label={ props.label }
          input={ {
            type: FieldSetInputType.Float,
            value: props.value,
            min: props.min,
            max: props.max,
            step: props.step,
            onChange: handleChangeInInput,
          } }
        />
      </Grid>
      <Grid
        item
        xs={ 6 }
        sx = {{
          marginTop: -1

        }}
      >
        <Slider
          value={ props.value }
          min={ props.min }
          max={ props.max }
          step={ props.step }
          onChange={ handleChangeInSlider }
        />
      </Grid>
    </Grid>
  );
};


export default SliderWrapper;
