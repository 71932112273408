// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { List, ListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ExpandMoreRounded } from '@mui/icons-material';

import MenuUIElementContent from './MenuUIElementContent';
import MenuUISecton from './MenuUISection/MenuUISecton';
import { Sizes } from '../../Configuration/Styles/Sizes';
import { Colors } from '../../Configuration/Styles/Colors';
import MenuUISectonHeader from './MenuUISection/MenuUISectonHeader';
import MenuUISectonContent from './MenuUISection/MenuUISectonContent';
import { MenuElement, MenuID } from '../Data/MenuElement';


type Props = Readonly<{
  item?: MenuElement;
  breadCrumbs: string[];
  closeMenuIfNeed: () => void;
  onPageSelected: (page: MenuElement) => void;
  onLogout: () => void;
}>;


const SX = {
  expandIcon: {
    width: 30,
    height: 30,
    color: Colors.menuSectionExpandCollpseIcon,
  },
  menu_items_container: {
    margin: 0,
    padding: 0,
    width: '100%',
    minHeight: 45,
  },
  menu_regular_item: {
    backgroundColor: Colors.menuBackground,
    '&:hover': {
      backgroundColor: Colors.menuDefaultHover
    },
    margin: 0,
    paddingLeft: '16px',
    minHeight: 45,
    color: Colors.menuText,
    fontWeight: Sizes.mediumWeight,
    '&.Mui-selected': {
      backgroundColor: Colors.mainBackground,
      color: Colors.menuTextSelected,
      fontWeight: Sizes.boldWeight,
      borderWidth: 0
    }
  },
  menu_secton_item: {
    margin: 0,
    paddingLeft: '16px',
    minHeight: 42,
  },
  menu_main_header_item: {
    backgroundColor: Colors.menuMainHeaderBackground,
    '&:hover': {
      background: Colors.menuSelectedItemHover + " !important"
    },
    margin: 0,
    paddingLeft: '16px',
    minHeight: 64,
    color: Colors.menuMainHeaderText,
    fontWeight: Sizes.boldWeight,
    '&.Mui-selected': {
      backgroundColor: Colors.menuMainHeaderSelectedBackground,
      borderWidth: 0
    }
  },
};

const MenuUIElement = (props: Props) => {
  const navigate = useNavigate();

  const isSelected = (item: MenuElement): boolean => (props.breadCrumbs.includes(item.id));

  const renderItem = (item: MenuElement, parentID?: MenuID) => {
    const isSectionItem = item.isMenuSection();
    const isMenuMainHeader = item.isMenuMainHeader();
    const isItemSelected = isSelected(item);
    const isMenuLogout = item.isMenuLogout();

    const handleClick = () => {
      if (isMenuLogout) {
        props.onLogout()
        props.closeMenuIfNeed()
      }
      else if (isSectionItem) {
        props.onPageSelected(item);
      } else {
        const link = (parentID) ?
          `/${ parentID }/${ item.id }`
          :
          `/${ item.id }`;

        navigate(link);
        props.closeMenuIfNeed()
      }
    };

    return (
      <ListItem
        button
        key={ item.id }
        sx={ (isSectionItem) ? SX.menu_secton_item : ((isMenuMainHeader) ? SX.menu_main_header_item : SX.menu_regular_item) }
        onClick={ handleClick }
        selected={ isItemSelected }
      >
        <MenuUIElementContent
          parentID={ parentID }
          item={ item }
          isSelected={ isItemSelected }
          iconColor={ (isMenuMainHeader) ? Colors.menuMainHeaderIcon : ((isItemSelected) ? Colors.menuIconSelected : Colors.menuIconDefault) }
        />
      </ListItem>
    );
  };

  const renderContent = (items: MenuElement[], parentID?: MenuID) => (
    <List
      sx={ SX.menu_items_container }
    >
      { items.map((item: MenuElement) => renderItem(item, parentID)) }
    </List>
  );

  if (props.item?.isMenuSection()) {
    return (
      <MenuUISecton
        sx={ { margin: '0 !important' } }
        defaultExpanded={ true }
        // expanded={ isSelected(props.section) }
      >
        <MenuUISectonContent
          expandIcon={ <ExpandMoreRounded sx={ SX.expandIcon } /> }
        >
          <MenuUIElementContent
            item={ props.item }
            isSelected={ false /*isSelected(props.section)*/ }
            iconColor={ Colors.menuSectionIcon }
          />
        </MenuUISectonContent>
        <MenuUISectonHeader>
          { renderContent(props.item?.items || [], props.item?.id) }
        </MenuUISectonHeader>
      </MenuUISecton>
    );
  }

  return renderContent( (props.item) ? [props.item] : []);
};


export default MenuUIElement;
