// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import {
  UseFetchProps,
  useFetchStatPossessionHeatmap,
  flushCache,
  getCached,
} from '../fetch';
import { AnalyticsStatPossessionHeatmap } from '../AnalyticsStatPossessionHeatmap';
import { ResultStatus } from '../../../_Networking/ReactQuery/template';


type Result = Readonly<{
  status: ResultStatus;
  data: AnalyticsStatPossessionHeatmap | undefined;
  handleFlushData: () => void;
}>;


export const useAnalyticsStatPossessionHeatmap = (props: UseFetchProps): Result => {
  const fetchedArchive = useFetchStatPossessionHeatmap(props);
  const data = getCached(props);

  return ({
    status: fetchedArchive.status as ResultStatus,
    data: data?.data,
    handleFlushData: flushCache,
  });
};

