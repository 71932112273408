// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import User, { isUserHaveServerAnalyticsAccess, isUserHaveServerCameraAccess } from '../../Data/AccountUsers/User';
import PageContainer from '../../Components/_Layout/PageContainer';
import LOCALIZATION from '../../Localization';
import { PAGE_LIMIT } from '../../constants';
import Order from '../../Data/_Networking/ReactQuery/Order';
import { isUserRoleMinimum, UserRole, UserType } from '../../Data/AccountUsers/UserWithoutID';
import useFetchAllPagesUsers from '../../Data/AccountUsers/hook';
import SortedTable from '../../Components/SortedTable';
import Card from '../../Components/_Layout/Card';
import useMutationUser, { ActionType } from '../../Data/AccountUsers/fetch/useMutation';
import AuthorisationManager from '../../Data/Auth/AuthorisationManager';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import UserPopup from './UserPopup';
import ButtonsInRowElement, { ButtonsInRowArray } from '../../Components/_BaseUI/ButtonsInRowElement';
import renderCellContent from './Cell';
import { useAppConfig } from '../../Localization/AppContextProvider/helpers';
import FieldSetSelectComponent, { FieldSetSelectOption } from '../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import { FieldSetInputType } from '../../Components/_BaseUI/FieldSet';
import { getCachedCameras, clearCamerasCache } from '../../Data/Camera/ActiveCamera/fetch';
import { useCameras } from '../../Data/Camera/ActiveCamera/hook';
import { clearTeamRefsCache  } from '../../Data/Analytics/AnalyticsTeamRef/fetch';
import RefreshIcon from '@mui/icons-material/Refresh';
import { RowClassName } from '../../Components/SortedTable/SortedTableBodyRow';
import useFetchAllPagesTeamRefs from '../../Data/Analytics/AnalyticsTeamRef/hook';
import { HeadCell } from '../../Components/SortedTable/SortedTableHead';
import { CameraType } from '../../Data/Camera/ActiveCamera/Camera';

type Props = Readonly<{} & WrappedComponentProps>;


const FILTER_ALL_CAMERAS = {
  id: 'ALL_CAMERAS',
  name: 'All Cameras'
};
const FILTER_ALL_TEAMS = {
  id: 'ALL_TEAMS',
  name: 'All Teams'
};

const UsersPage: React.FC<Props> = (props: Props) => {
  const { localization: { locale } } = useAppConfig();

  const [isEditPopupOpened, setIsEditPopupOpened] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(PAGE_LIMIT);
  const [editItem, setEditItem] = React.useState<User | undefined>(undefined);
  const [orderBy, setOrderBy] = React.useState<keyof User>('name');
  const [order, setOrder] = React.useState<Order>(Order.ASC);
  const [filterCameraSelected, setFilterCameraSelected] = React.useState<FieldSetSelectOption>(FILTER_ALL_CAMERAS);
  const [filterTeamSelected, setFilterTeamSelected] = React.useState<FieldSetSelectOption>(FILTER_ALL_TEAMS);
  const { user } = AuthorisationManager.shared;
  const isServer = (user?.type === UserType.Server);
  const cameraFetch = useCameras(isServer);
  const cameras = getCachedCameras()?.filter( (camera) => ( camera.type === CameraType.Remote ));
  const curUserHaveServerCameraAccess = isUserHaveServerCameraAccess(user);
  const curUserHaveServerAnalyticsAccess = isUserHaveServerAnalyticsAccess(user);
  const {
    status: teamRefFetchStatus,
    // status,
    allCached: teamRefs,
  } = useFetchAllPagesTeamRefs();
  const isValidRoleForAddDelUser = isUserRoleMinimum(user?.role, UserRole.Admin);

  React.useEffect(() => {
    clearCamerasCache();
    clearTeamRefsCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    status,
    data,
    handleFlushData,
  } = useFetchAllPagesUsers({
    page,
    limit,
    order,
    orderBy,
    filterByCameraID: (filterCameraSelected.id !== FILTER_ALL_CAMERAS.id) ? filterCameraSelected.id : undefined,
    filterByTeamRefID: (filterTeamSelected.id !== FILTER_ALL_TEAMS.id) ? filterTeamSelected.id : undefined,
  });
  const { mutateAsync: mutateUsers } = useMutationUser();

  const handleChangeFilterCamera = (selectedCameraID: string) => {
    const selectedCamera = (cameras || []).find(({ cameraId }) => (cameraId === selectedCameraID));

    if (selectedCamera) {
      setFilterCameraSelected({
        id: selectedCamera.cameraId,
        name: selectedCamera.name,
      });
    } else {
      setFilterCameraSelected(FILTER_ALL_CAMERAS);
    }
    setPage(0);
  };
  
  const handleChangeFilterTeamRef = (selectedTeamRefID: string) => {
    const selectedTeamRef = (teamRefs?.data || []).find(({ id }) => (id === selectedTeamRefID));

    if (selectedTeamRef) {
      setFilterTeamSelected({
        id: selectedTeamRef.id,
        name: selectedTeamRef.name,
      });
    } else {
      setFilterTeamSelected(FILTER_ALL_TEAMS);
    }
    setPage(0);
  };
  const handleRequestSort = (newOrderField: keyof User) => {
    if (orderBy === newOrderField) {
      setOrder((order === Order.ASC) ? Order.DESC : Order.ASC);
    }

    setOrderBy(newOrderField);
  };
  const handleDelete = async (user: User) => {
    try {
      await mutateUsers({
        user,
        type: ActionType.Delete,
      }, {
        onSuccess: () => {
          handleFlushData();
        },
      });
    } catch (error) {
      alert(error);
      handleFlushData();
    }
  };
  const handlePeerPageChange = (newLimit: number) => {
    setLimit(newLimit);
  };
  const handleRemoveIconButtonClicked = (item: User) => {
    if (user?.id === item.id) {
      alert(props.intl.formatMessage({ id: LOCALIZATION.cant_delete_your_account }));
    } else {
      if (window && window.confirm(props.intl.formatMessage({ id: LOCALIZATION.confirm_actions }))) {
        handleDelete(item);
      }
    }
  };
  const handleAddClicked = () => {
    setIsEditPopupOpened(true);
  };
  const handleRowItemClicked = (item: User): void => {
    setEditItem(item);
  };
  const handleCloseEditPopup = () => {
    setIsEditPopupOpened(false);
    setEditItem(undefined);
  };
  const handlePostedSuccess = async (newUser?: User) => {
    handleFlushData();
    handleCloseEditPopup();
    if (newUser?.id && newUser?.id === user?.id) {// curretn user changed
      AuthorisationManager.shared.user = newUser
    }
  };
  const handleShowRemoveIconButton = (rowUser?: User): boolean => {
    return ((isValidRoleForAddDelUser) && (!user || !rowUser || rowUser?.id !== user?.id))
  };

  const rowClassName = (item: User, allItem: User[]) => {
    const isCurrentUser = (user?.id === item.id);

    if (isCurrentUser) {
      return RowClassName.Blue;
    }

    return null;
  }

  var headCells: HeadCell<User>[] = [
    {
      id: 'name' as keyof User,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.user_name }),
    },
    {
      id: 'login' as keyof User,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.user_login }),
    },
    {
      id: 'role' as keyof User,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.user_group }),
    },
  ];
  if (isServer && isUserHaveServerCameraAccess(user)) {
    headCells.push({
      id: 'userAvailableCameraIdList' as keyof User,
      numeric: false,
      disablePadding: true,
      disableSort: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.user_visible_cameras }),
      hidden: (!isServer),
    });
  }
  if (isServer && isUserHaveServerAnalyticsAccess(user)) {
    headCells.push({
      id: 'userAvailableTeamRefIdList' as keyof User,
      numeric: false,
      disablePadding: true,
      disableSort: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.user_visible_teamrefs }),
      hidden: (!isServer),
    });
  }

  headCells.push({
      id: 'lastLogin' as keyof User,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.user_last_login }),
  });

  let buttons: ButtonsInRowArray ;
  if (isValidRoleForAddDelUser) {
    buttons = [
      {
        text: props.intl.formatMessage({ id: LOCALIZATION.add_account }),
        onClick: handleAddClicked,
      },
      {
        space : -1 ,
      }
    ];
  }
  else {
    buttons = [
      {
        space : -1 ,
      }
    ]
  }

  if (curUserHaveServerCameraAccess) {
    buttons.push(
      <FieldSetSelectComponent
          key="FieldSetSelectCamera"
          sx={{
            maxWidth: '200pt'
          }}
          input={ {
            // label: 'Filter by Camera',
            type: FieldSetInputType.Select,
            label: props.intl.formatMessage({ id: LOCALIZATION.users_filter_camera_label }),
            value: filterCameraSelected.id,
            options: [
              {
                id: FILTER_ALL_CAMERAS.id,
                name: props.intl.formatMessage({ id: LOCALIZATION.filter_by_all_camera }),
              },
              ...(cameras || []).map(({ cameraId: id, name }) => ({ id, name })),
            ],
            onChange: handleChangeFilterCamera,
          } }
        />
    );
    buttons.push({
      space : 10 ,
    })
  }

  if (curUserHaveServerAnalyticsAccess) {
    buttons.push(
      <FieldSetSelectComponent
          key="FieldSetSelectTeamRef"
          sx={{
            maxWidth: '200pt'
          }}
          input={ {
            // label: 'Filter by TeamRef',
            type: FieldSetInputType.Select,
            label: props.intl.formatMessage({ id: LOCALIZATION.users_filter_teamref_label }),
            value: filterTeamSelected.id,
            options: [
              {
                id: FILTER_ALL_TEAMS.id,
                name: props.intl.formatMessage({ id: LOCALIZATION.filter_by_all_teams }),
              },
              ...(teamRefs?.data || []).map(({ id, name }) => ({ id, name })),
            ],
            onChange: handleChangeFilterTeamRef,
          } }
        />
    );
    buttons.push({
      space : 10 ,
    })
  }

  buttons.push( {
    text: props.intl.formatMessage({ id: LOCALIZATION.refresh }),
    iconButton: <RefreshIcon /> ,
    onClick: handleFlushData,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => handleFlushData,[]);

  return (
    <PageContainer
      header={
      props.intl.formatMessage({
        id: (isServer) ? LOCALIZATION.page_user_title_server_web_admin : LOCALIZATION.page_user_title_web_admin
      })
    }
    >
      <Grid
        item
        xs={ 12 }
      >
        <Card>
          <ButtonsInRowElement
            id = "buttonsRow1"
            buttons={ buttons }
          />
          <br />
          {
            (status === 'loading' || cameraFetch.status === 'loading' || teamRefFetchStatus === 'loading') ?
              <>
                <br /><br /><Spinner /><br /><br />
              </>
              :
              (data?.meta?.pagination?.count !== undefined && data.meta.pagination.count !== 0) ?
                <SortedTable<User>
                  page={ page }
                  rowsPerPage={ limit }
                  orderBy={ orderBy }
                  order={ order }
                  headCells={ headCells }
                  manyItems={ data }
                  renderCellContent={ renderCellContent(locale) }
                  onRowClick={ handleRowItemClicked }
                  onPageChange={ setPage }
                  rowClassName={ rowClassName }
                  onPeerPageChange={ handlePeerPageChange }
                  showRemoveIconButton={ handleShowRemoveIconButton }
                  onRemoveIconButtonClicked={ handleRemoveIconButtonClicked }
                  onRequestSort={ handleRequestSort }
                />
                :
                <div><br />{ props.intl.formatMessage({ id: LOCALIZATION.filtered_data_empty }) }</div>
          }
          {
            (user?.role && (isEditPopupOpened || editItem)) &&
              <UserPopup
                cameras={ (cameras || []) }
                editorCameraIdList={ user.cameraIdList }
                teamRefs={ (teamRefs?.data || []) }
                editorTeamRefIdList={ user.teamRefIdList }
                editorAccessLevel={ user.accessLevel }
                cameraAccess={ user.cameraAccess }
                teamRefAccess={ user.teamRefAccess }
                isCurrentUser={ !!(editItem && editItem?.id && editItem?.id === user.id) }
                type={ user.type }
                user={ editItem }
                onClose={ handleCloseEditPopup }
                onPostedSuccess={ handlePostedSuccess }
              />
          }
        </Card>
      </Grid>
    </PageContainer>
  );
};


export default injectIntl(UsersPage);
