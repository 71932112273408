// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { useCoolerTemperatureControllerForm } from './Hooks'
import { CameraNotAvailable, JetsonTools, safeNumber } from '../../../../../Tools/Tools'
import { useCameraData } from '../../../../../Data/Camera/HWCameraSettings/hook/Hooks'
import CameraContext from '../../../../../Data/Camera/HWCameraSettings/fetch/Context'
import InputTemperatureControllerModeForm from '../../../Components/InputTemperatureControllerModeForm/View'
import InputTextForm from '../../../Components/InputTextForm/View'
import { CoolerTemperatureControllerFormInterface, TemperatureControllerMode} from '../../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature'
import { CameraInterface } from '../../../../../Data/Camera/HWCameraSettings/Camera'
import { JetsonId } from '../../../../../Data/Camera/Jetson/Types'
import { InputFieldType } from '../../../../../Components/_BaseUI/InputField/InputField';
import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import LOCALIZATION from '../../../../../Localization';


type Props = Readonly<{
    id: JetsonId;
    camera: CameraInterface;
}>;

const Render: React.FC<Props> = (props: Props) => {
    const intl = useIntl();
    const { id, camera } = props;

    const originalData = JetsonTools.getTemperatureControllerData(camera, id)

    // Нам не нужно ггонять лишние данные, только те, которые предусмотрены формой
    const initialForm: CoolerTemperatureControllerFormInterface | undefined = (originalData === undefined) ? undefined : {
        start: originalData.start,
        stop: originalData.stop,
        critical: originalData.critical,
        mode: originalData.mode
    }

    async function submitForm(form?: CoolerTemperatureControllerFormInterface): Promise<CoolerTemperatureControllerFormInterface> {
        const data = await CameraContext.current!.cameraDataProvider?.setJetsonTemperatureControllerData(id, form)
        return data
    }

    const { onChangeMode, onChangeStart, onChangeStop, onChangeCritical, form, validation } = useCoolerTemperatureControllerForm(submitForm, initialForm)

    let isStartOk = validation.start
    let isStopOk = validation.stop
    let isCriticalOk = validation.critical
    const isModeOk = validation.mode

    const handleStartChange = (text: string) => {
        const value = safeNumber(text)
        value !== undefined ? onChangeStart(value) : isStartOk = false
    }

    const handleStopChange = (text: string) => {
        const value = safeNumber(text)
        value !== undefined ? onChangeStop(value) : isStopOk = false
    }

    const handleCriticalChange = (text: string) => {
        const value = safeNumber(text)
        value !== undefined ? onChangeCritical(value) : isCriticalOk = false
    }

    const modeInput = (
        <InputTemperatureControllerModeForm name={intl.formatMessage({ id: LOCALIZATION.mode })} mode={form?.mode ?? TemperatureControllerMode.Auto} isCorrect={isModeOk} onModeChanged={onChangeMode}/>
    )
    const startInput = (
        <InputTextForm name={intl.formatMessage({ id: LOCALIZATION.start_when_cpu_more })} inputFieldType={ InputFieldType.Temperature } defaultValue={form?.start.toFixed(1)} isCorrect={isStartOk} onTextChanged={handleStartChange}/>
    )
    const stopInput = (
        <InputTextForm name={intl.formatMessage({ id: LOCALIZATION.stop_when_cpu_less })} inputFieldType={ InputFieldType.Temperature } defaultValue={form?.stop.toFixed(1)} isCorrect={isStopOk} onTextChanged={handleStopChange}/>
    )
    const criticalInput = (
        <InputTextForm name={intl.formatMessage({ id: LOCALIZATION.critical_cpu_temp })} inputFieldType={ InputFieldType.Temperature } defaultValue={form?.critical.toFixed(1)} isCorrect={isCriticalOk} onTextChanged={handleCriticalChange}/>
    )

    return form ? (
        <Grid container spacing={1}>
            {[modeInput, startInput, stopInput, criticalInput].map((element, index) => (
                <Grid item key={index} xs={12}>{element}</Grid>
            ))}
        </Grid>
    ) : null
};

export default function CoolerTemperatureController(props: {id: JetsonId}) {
    const camera = useCameraData()
    if (!camera) { return <CameraNotAvailable/> }

    return (
        <Render
            id={ props.id }
            camera={ camera }
        />
    );
}
