// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import Order from '../../Data/_Networking/ReactQuery/Order';
import { TableHead, TableRow, Checkbox, TableCell, TableSortLabel, Box } from '@mui/material';


export type HeadCell<T> = Readonly<{
  disablePadding: boolean;
  disableSort?: boolean;
  id: keyof T;
  label: string;
  numeric: boolean;
  hidden?: boolean;
}>;

type Props<T> = Readonly<{
  order: Order;
  orderBy: keyof T;
  headCells: HeadCell<T>[];
  numSelected?: number;
  rowCount: number;
  onSelectAll?: () => void;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  addRemoveButton?: boolean;
  addMenuButton?: boolean;
  addAddButton?: boolean;
}>;


const SortedTableHead = <T, >(props: Props<T>): JSX.Element => {
  const createSortHandler = (
    property: keyof T
  ) => (event: React.MouseEvent<unknown>) => {
    if (props.onRequestSort) {
      props.onRequestSort(event, property);
    }
  };

  const renderCell = (headCell: HeadCell<T>) => (
    <TableCell
      key={ headCell.id as string | number }
      align={ headCell.numeric ? 'right' : 'left' }
      padding={ headCell.disablePadding ? 'none' : 'normal' }
      sortDirection={ props.orderBy === headCell.id ? props.order : false }
    >
      {
        (props.onRequestSort && headCell.disableSort !== true) ?
          <TableSortLabel
            active={ props.orderBy === headCell.id }
            direction={ props.orderBy === headCell.id ? props.order : Order.ASC }
            onClick={ createSortHandler(headCell.id) }
          >
            {/* <Typography sx = {{
              wordBreak: 'break-all',
            }}> */}
              { headCell.label }
            {/* </Typography> */}
            {
              props.orderBy === headCell.id ?
                <Box
                  component="span"
                />
                :
                null
            }
          </TableSortLabel>
          :
          headCell.label
      }
    </TableCell>
  );
  const renderHeader = props.headCells.filter((item) => ( !item.hidden )).map(renderCell);

  return (
    <TableHead>
      <TableRow>
        {
          (props.addAddButton) &&
            <TableCell
              padding="none"
            />
        }
        {
          (props.numSelected !== undefined && props.onSelectAll) &&
            <TableCell
              padding="checkbox"
            >
              <Checkbox
                color="primary"
                indeterminate={ props.numSelected > 0 && props.numSelected < props.rowCount }
                checked={ props.rowCount > 0 && props.numSelected === props.rowCount }
                onChange={ props.onSelectAll }
                inputProps={ {
                  'aria-label': 'select all desserts',
                } }
              />
            </TableCell>
        }
        { renderHeader }
        {
          (props.addMenuButton) &&
            <TableCell
              padding="none"
            />
        }
        {
          (props.addRemoveButton) &&
            <TableCell
                padding="none"
            />
        }
      </TableRow>
    </TableHead>
  );
};


export default SortedTableHead;
