// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import LOCALIZATION from "../../Localization";
import { intl } from "../../Localization/LocalizationProvider";
import { AnalyticsConvertedDataUnit, AnalyticsConvertedPreferedUnits, analyticsDistanceConvertTo, analyticsSpeedConvertTo, analyticsTimeConvertTo } from "../../Tools/analyticsUnitsConvert";
import { analyticsRatioConvertToPercent100 } from "../../Tools/analyticsUnitsConvert";
import { roundTo1 } from "../../Tools/roundTo";
import { AnalyticsStatAttackDefenseTeam } from "./AnalyticsStatAttackDefenseTime/AnalyticsStatAttackDefenseTime";
import { AnalyticsStatDistanceGame, AnalyticsStatDistanceTeam } from "./AnalyticsStatDistance/AnalyticsStatDistance";
import { AnalyticsStatPossessionTeam } from "./AnalyticsStatPossession/AnalyticsStatPossession";
import { AnalyticsStatScoreTeam } from "./AnalyticsStatScore/AnalyticsStatScore";
import { AnalyticsStatSpeedTeam, AnalyticsStatSpeedTeamDetails } from "./AnalyticsStatSpeed/AnalyticsStatSpeed";


export enum AnalyticsConvertedDataType {
    score = 'score',
    possession = 'possession',
    avgTimeInPossession = 'avgTimeInPossession',
    distanceCovered = 'distanceCovered',
    avgSpeed = 'avgSpeed',
    avgSpeedInAttack = 'avgSpeedInAttack',
    avgSpeedInDefense = 'avgSpeedInDefense',
    attackTime = 'attackTime',
    defenseTime = 'defenseTime',
    intensity = 'intensity',

    partWalk = 'partWalk',
    partRun = 'partRun',
    partSprint = 'partSprint',
}


export type AnalyticsConvertedValue = Readonly<{
    type?: AnalyticsConvertedDataType;
    name?: string;
    value: number;
    units?: AnalyticsConvertedDataUnit;
    ratio?: number; // percent 0..100 to measure this value between other values
    parts?: AnalyticsConvertedValue[]; // split this value to several intervals. like: walk, run, splint for speed
}>;

export type AnalyticsConvertedItem = Readonly<{
    type: AnalyticsConvertedDataType;
    team1: AnalyticsConvertedValue;
    team2: AnalyticsConvertedValue;
    normBase?: number;
    units?: AnalyticsConvertedDataUnit;
}>;


export type AnalyticsConvertedData = {
    [key in AnalyticsConvertedDataType]?: AnalyticsConvertedItem;
};

export const analyticsConvertScore = ( scoreInfo?: AnalyticsStatScoreTeam | null, team1Name?: string, team2Name?: string ): AnalyticsConvertedItem | undefined => {
    if (scoreInfo === undefined) {
        return undefined;
    }
    return {
        type: AnalyticsConvertedDataType.score,
        team1: {
            type: AnalyticsConvertedDataType.score,
            name: team1Name,
            value: roundTo1(scoreInfo?.team1),
            units: AnalyticsConvertedDataUnit.Number,
        },
        team2: {
            type: AnalyticsConvertedDataType.score,
            name: team2Name,
            value: roundTo1(scoreInfo?.team2),
            units: AnalyticsConvertedDataUnit.Number,
        },
        units: AnalyticsConvertedDataUnit.Number,
    }
}

export const analyticsConvertPossession = ( possessionInfo?: AnalyticsStatPossessionTeam | null, team1Name?: string, team2Name?: string ): AnalyticsConvertedItem | undefined => {
    if (possessionInfo === undefined) {
        return undefined;
    }
    return {
        type: AnalyticsConvertedDataType.possession,
        team1: {
            type: AnalyticsConvertedDataType.possession,
            name: team1Name,
            value: analyticsRatioConvertToPercent100(possessionInfo?.team1?.percent), //  api percent return as 0..1 value
            units: AnalyticsConvertedDataUnit.Percent,
        },
        team2: {
            type: AnalyticsConvertedDataType.possession,
            name: team2Name,
            value: analyticsRatioConvertToPercent100(possessionInfo?.team2?.percent), //  api percent return as 0..1 value
            units: AnalyticsConvertedDataUnit.Percent,
        },
        normBase: 100,
        units: AnalyticsConvertedDataUnit.Percent,
    }
}

export const analyticsConvertAvgTimeInPossession = ( possessionInfo?: AnalyticsStatPossessionTeam | null, team1Name?: string, team2Name?: string, preferedUnits?: AnalyticsConvertedPreferedUnits ): AnalyticsConvertedItem | undefined => {
    if (possessionInfo === undefined) {
        return undefined;
    }
    const units = preferedUnits?.time || AnalyticsConvertedDataUnit.Seconds;
    return {
        type: AnalyticsConvertedDataType.avgTimeInPossession,
        team1: {
            type: AnalyticsConvertedDataType.avgTimeInPossession,
            name: team1Name,
            value: analyticsTimeConvertTo(possessionInfo?.team1?.avg_time, units),
            units,
        },
        team2: {
            type: AnalyticsConvertedDataType.avgTimeInPossession,
            name: team2Name,
            value: analyticsTimeConvertTo(possessionInfo?.team2?.avg_time, units),
            units,
        },
        units,
    }
}

const analyticsConvertWalkRunSprintPartsDistance = (stat: AnalyticsStatDistanceTeam | undefined, preferedUnits?: AnalyticsConvertedPreferedUnits) : AnalyticsConvertedValue[] => {
    const units = preferedUnits?.distance || AnalyticsConvertedDataUnit.KM;
    return [
      {
        type: AnalyticsConvertedDataType.partWalk,
        name: intl().formatMessage({ id: LOCALIZATION.analytics_walk }),
        value: analyticsDistanceConvertTo((stat?.walking_ratio && stat?.overall) ? stat.walking_ratio * stat.overall : 0, units),
        ratio: analyticsRatioConvertToPercent100(stat?.walking_ratio),
        units,
      },
      {
        type: AnalyticsConvertedDataType.partRun,
        name: intl().formatMessage({ id: LOCALIZATION.analytics_run }),
        value: analyticsDistanceConvertTo((stat?.running_ratio && stat?.overall) ? stat.running_ratio * stat.overall : 0, units),
        ratio: analyticsRatioConvertToPercent100(stat?.running_ratio),
        units,
      },
      {
        type: AnalyticsConvertedDataType.partSprint,
        name: intl().formatMessage({ id: LOCALIZATION.analytics_sprint }),
        value: analyticsDistanceConvertTo((stat?.sprinting_ratio && stat?.overall) ? stat.sprinting_ratio * stat.overall : 0, units),
        ratio: analyticsRatioConvertToPercent100(stat?.sprinting_ratio),
        units,
      }
    ]
}

export const analyticsConvertDistanceCovered = ( distanceInfo?: AnalyticsStatDistanceGame | null, team1Name?: string, team2Name?: string, preferedUnits?: AnalyticsConvertedPreferedUnits ): AnalyticsConvertedItem | undefined => {
    if (distanceInfo === undefined) {
        return undefined;
    }
    const units = preferedUnits?.distance || AnalyticsConvertedDataUnit.KM;
    return {
        type: AnalyticsConvertedDataType.distanceCovered,
        team1: {
            type: AnalyticsConvertedDataType.distanceCovered,
            name: team1Name,
            value: analyticsDistanceConvertTo(distanceInfo?.team1?.overall, units),
            units,
            parts: analyticsConvertWalkRunSprintPartsDistance(distanceInfo?.team1, preferedUnits),
        },
        team2: {
            type: AnalyticsConvertedDataType.distanceCovered,
            name: team2Name,
            value: analyticsDistanceConvertTo(distanceInfo?.team2?.overall, units),
            units,
            parts: analyticsConvertWalkRunSprintPartsDistance(distanceInfo?.team2, preferedUnits),
        },
        units,
    }
}

export const analyticsConvertAvgSpeed = ( speedInfo?: AnalyticsStatSpeedTeam | null, team1Name?: string, team2Name?: string, preferedUnits?: AnalyticsConvertedPreferedUnits ): AnalyticsConvertedItem | undefined => {
    if (speedInfo === undefined) {
        return undefined;
    }
    const units = preferedUnits?.avgSpeed || AnalyticsConvertedDataUnit.KmPerHour;
    return {
        type: AnalyticsConvertedDataType.avgSpeed,
        team1: {
            type: AnalyticsConvertedDataType.avgSpeed,
            name: team1Name,
            value: analyticsSpeedConvertTo(speedInfo?.team1?.overall, units),
            units,
            parts: analyticsConvertWalkRunSprintPartsSpeed(speedInfo?.team1, preferedUnits),
        },
        team2: {
            type: AnalyticsConvertedDataType.avgSpeed,
            name: team2Name,
            value: analyticsSpeedConvertTo(speedInfo?.team2?.overall, units),
            units,
            parts: analyticsConvertWalkRunSprintPartsSpeed(speedInfo?.team2, preferedUnits),
        },
        units,
    }
}

const analyticsConvertWalkRunSprintPartsSpeed = (stat: AnalyticsStatSpeedTeamDetails | undefined, preferedUnits?: AnalyticsConvertedPreferedUnits) : AnalyticsConvertedValue[] => {
    const units = preferedUnits?.avgSpeed || AnalyticsConvertedDataUnit.KmPerHour;
    return [
      {
        type: AnalyticsConvertedDataType.partWalk,
        name: intl().formatMessage({ id: LOCALIZATION.analytics_walk }),
        value: analyticsSpeedConvertTo(stat?.walking?.speed, units),
        ratio: analyticsRatioConvertToPercent100(stat?.walking?.ratio),
        units,
      },
      {
        type: AnalyticsConvertedDataType.partRun,
        name: intl().formatMessage({ id: LOCALIZATION.analytics_run }),
        value: analyticsSpeedConvertTo(stat?.running?.speed, units),
        ratio: analyticsRatioConvertToPercent100(stat?.running?.ratio),
        units,
      },
      {
        type: AnalyticsConvertedDataType.partSprint,
        name: intl().formatMessage({ id: LOCALIZATION.analytics_sprint }),
        value: analyticsSpeedConvertTo(stat?.sprinting?.speed, units),
        ratio: analyticsRatioConvertToPercent100(stat?.sprinting?.ratio),
        units,
      }
    ]
}
  
export const analyticsConvertAvgSpeedInAttack = ( speedInAttackInfo?: AnalyticsStatSpeedTeam | null, team1Name?: string, team2Name?: string, preferedUnits?: AnalyticsConvertedPreferedUnits ): AnalyticsConvertedItem | undefined => {
    if (speedInAttackInfo === undefined) {
        return undefined;
    }
    const units = preferedUnits?.avgSpeed || AnalyticsConvertedDataUnit.KmPerHour;
    return {
        type: AnalyticsConvertedDataType.avgSpeedInAttack,
        team1: {
            type: AnalyticsConvertedDataType.avgSpeedInAttack,
            name: team1Name,
            value: analyticsSpeedConvertTo(speedInAttackInfo?.team1?.overall, units),
            units,
            parts: analyticsConvertWalkRunSprintPartsSpeed(speedInAttackInfo?.team1, preferedUnits),
        },
        team2: {
            type: AnalyticsConvertedDataType.avgSpeedInAttack,
            name: team2Name,
            value: analyticsSpeedConvertTo(speedInAttackInfo?.team2?.overall, units),
            units,
            parts: analyticsConvertWalkRunSprintPartsSpeed(speedInAttackInfo?.team2, preferedUnits),
        },
        units,
    }
}
  
export const analyticsConvertAvgSpeedInDefense = ( speedInDefenseInfo?: AnalyticsStatSpeedTeam | null, team1Name?: string, team2Name?: string, preferedUnits?: AnalyticsConvertedPreferedUnits ): AnalyticsConvertedItem | undefined => {
    if (speedInDefenseInfo === undefined) {
        return undefined;
    }
    const units = preferedUnits?.avgSpeed || AnalyticsConvertedDataUnit.KmPerHour;
    return {
        type: AnalyticsConvertedDataType.avgSpeedInDefense,
        team1: {
            type: AnalyticsConvertedDataType.avgSpeedInDefense,
            name: team1Name,
            value: analyticsSpeedConvertTo(speedInDefenseInfo?.team1?.overall, units),
            units,
            parts: analyticsConvertWalkRunSprintPartsSpeed(speedInDefenseInfo?.team1, preferedUnits),
        },
        team2: {
            type: AnalyticsConvertedDataType.avgSpeedInDefense,
            name: team2Name,
            value: analyticsSpeedConvertTo(speedInDefenseInfo?.team2?.overall, units),
            units,
            parts: analyticsConvertWalkRunSprintPartsSpeed(speedInDefenseInfo?.team2, preferedUnits),
        },
        units,
    }
}

export const analyticsConvertIntensity = ( speedInfo?: AnalyticsStatSpeedTeam | null, team1Name?: string, team2Name?: string ): AnalyticsConvertedItem | undefined => {
    if (speedInfo === undefined) {
        return undefined;
    }
    return {
        type: AnalyticsConvertedDataType.intensity,
        team1: {
            type: AnalyticsConvertedDataType.intensity,
            name: team1Name,
            value: analyticsRatioConvertToPercent100(speedInfo?.team1?.intensity),
            units: AnalyticsConvertedDataUnit.Percent,
        },
        team2: {
            type: AnalyticsConvertedDataType.intensity,
            name: team2Name,
            value: analyticsRatioConvertToPercent100(speedInfo?.team2?.intensity),
            units: AnalyticsConvertedDataUnit.Percent,
        },
        units: AnalyticsConvertedDataUnit.Percent,
        normBase: 100,
    }
}

export const analyticsConvertAttackTime = ( attackDefenseTimeInfo?: AnalyticsStatAttackDefenseTeam | null, team1Name?: string, team2Name?: string, preferedUnits?: AnalyticsConvertedPreferedUnits ): AnalyticsConvertedItem | undefined => {
    if (attackDefenseTimeInfo === undefined) {
        return undefined;
    }
    const units = preferedUnits?.time || AnalyticsConvertedDataUnit.Minutes;
    return {
        type: AnalyticsConvertedDataType.attackTime,
        team1: {
            type: AnalyticsConvertedDataType.attackTime,
            name: team1Name,
            value: analyticsTimeConvertTo(attackDefenseTimeInfo?.team1.attack_time, units),
            units,
        },
        team2: {
            type: AnalyticsConvertedDataType.attackTime,
            name: team2Name,
            value: analyticsTimeConvertTo(attackDefenseTimeInfo?.team2.attack_time, units),
            units,
        },
        units,
    }
}

export const analyticsConvertDefenseTime = ( attackDefenseTimeInfo?: AnalyticsStatAttackDefenseTeam | null, team1Name?: string, team2Name?: string, preferedUnits?: AnalyticsConvertedPreferedUnits ): AnalyticsConvertedItem | undefined => {
    if (attackDefenseTimeInfo === undefined) {
        return undefined;
    }
    const units = preferedUnits?.time || AnalyticsConvertedDataUnit.Minutes;
    return {
        type: AnalyticsConvertedDataType.defenseTime,
        team1: {
            type: AnalyticsConvertedDataType.defenseTime,
            name: team1Name,
            value: analyticsTimeConvertTo(attackDefenseTimeInfo?.team1.defense_time, units),
            units,
        },
        team2: {
            type: AnalyticsConvertedDataType.defenseTime,
            name: team2Name,
            value: analyticsTimeConvertTo(attackDefenseTimeInfo?.team2.defense_time, units),
            units,
        },
        units,
    }
}

export const analyticsConvertAllData = 
(
    team1Name?: string,
    team2Name?: string,
    scoreInfo?: AnalyticsStatScoreTeam | null,
    possessionInfo?: AnalyticsStatPossessionTeam | null,
    distanceInfo?: AnalyticsStatDistanceGame | null, 
    speedInfo?: AnalyticsStatSpeedTeam | null, 
    attackDefenseTimeInfo?: AnalyticsStatAttackDefenseTeam | null,
    speedInAttackInfo?: AnalyticsStatSpeedTeam | null, 
    speedInDefenseInfo?: AnalyticsStatSpeedTeam | null, 
    preferedUnits?: AnalyticsConvertedPreferedUnits,
  ): AnalyticsConvertedData => {

  let out: AnalyticsConvertedData = {}

  out.score = analyticsConvertScore(scoreInfo, team1Name, team2Name);
  out.possession = analyticsConvertPossession(possessionInfo, team1Name, team2Name);
  out.avgTimeInPossession = analyticsConvertAvgTimeInPossession(possessionInfo, team1Name, team2Name, preferedUnits);
  out.distanceCovered = analyticsConvertDistanceCovered(distanceInfo, team1Name, team2Name, preferedUnits);
  out.avgSpeed = analyticsConvertAvgSpeed(speedInfo, team1Name, team2Name, preferedUnits);
  out.avgSpeedInAttack = analyticsConvertAvgSpeedInAttack(speedInAttackInfo, team1Name, team2Name, preferedUnits);
  out.avgSpeedInDefense = analyticsConvertAvgSpeedInDefense(speedInDefenseInfo, team1Name, team2Name, preferedUnits);
  out.intensity = analyticsConvertIntensity(speedInfo, team1Name, team2Name);
  out.attackTime = analyticsConvertAttackTime(attackDefenseTimeInfo, team1Name, team2Name, preferedUnits);
  out.defenseTime = analyticsConvertDefenseTime(attackDefenseTimeInfo, team1Name, team2Name, preferedUnits);

  return out;
}


export const analyticsAnalyticsConvertedDataTypeName = (type: AnalyticsConvertedDataType | undefined, item: AnalyticsConvertedItem | AnalyticsConvertedValue | AnalyticsConvertedDataUnit | undefined, short?: boolean): string => {
    switch (type) {
        case AnalyticsConvertedDataType.score: 
            return analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_score) 
        case AnalyticsConvertedDataType.possession: 
            return analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_possession, item) 
        case AnalyticsConvertedDataType.avgTimeInPossession: 
            return analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_avg_time_in_possession, item) 
        case AnalyticsConvertedDataType.distanceCovered: 
            return analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_distance_covered, item) 
        case AnalyticsConvertedDataType.avgSpeed: 
            return analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_avg_speed, item) 
        case AnalyticsConvertedDataType.intensity: 
            return analyticsConvertedLocalizedWithUnits((short) ? LOCALIZATION.analytics_intensity_short : LOCALIZATION.analytics_intensity, item) 
        case AnalyticsConvertedDataType.attackTime: 
            return analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_attack_time, item) 
        case AnalyticsConvertedDataType.defenseTime: 
            return analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_defense_time, item) 
    }
    return intl().formatMessage({ id: LOCALIZATION.unknown }) 
}

export const analyticsConvertedDataUnitName = (unit?: AnalyticsConvertedDataUnit, longFormat?: boolean): string => {
    switch (unit) {
        case AnalyticsConvertedDataUnit.Number: 
            return "" 
        case AnalyticsConvertedDataUnit.Percent: 
            return intl().formatMessage({ id: (longFormat) ? LOCALIZATION.analytics_unit_percent : LOCALIZATION.analytics_unit_percent_short }) 
        case AnalyticsConvertedDataUnit.Seconds: 
            return intl().formatMessage({ id: (longFormat) ? LOCALIZATION.analytics_unit_seconds : LOCALIZATION.analytics_unit_seconds_short }) 
        case AnalyticsConvertedDataUnit.Minutes: 
            return intl().formatMessage({ id: (longFormat) ? LOCALIZATION.analytics_unit_minutes : LOCALIZATION.analytics_unit_minutes_short }) 
        case AnalyticsConvertedDataUnit.Hours: 
            return intl().formatMessage({ id: (longFormat) ? LOCALIZATION.analytics_unit_hours : LOCALIZATION.analytics_unit_hours_short }) 
        case AnalyticsConvertedDataUnit.KM: 
            return intl().formatMessage({ id: (longFormat) ? LOCALIZATION.analytics_unit_km : LOCALIZATION.analytics_unit_km_short }) 
        case AnalyticsConvertedDataUnit.Meters: 
            return intl().formatMessage({ id: (longFormat) ? LOCALIZATION.analytics_unit_meters : LOCALIZATION.analytics_unit_meters_short }) 
        case AnalyticsConvertedDataUnit.KmPerHour: 
            return intl().formatMessage({ id: (longFormat) ? LOCALIZATION.analytics_unit_km_per_hour : LOCALIZATION.analytics_unit_km_per_hour_short }) 
        case AnalyticsConvertedDataUnit.MeterPerSecond: 
            return intl().formatMessage({ id: (longFormat) ? LOCALIZATION.analytics_unit_meter_per_second : LOCALIZATION.analytics_unit_meter_per_second_short }) 
        case AnalyticsConvertedDataUnit.MeterPerMinute: 
            return intl().formatMessage({ id: (longFormat) ? LOCALIZATION.analytics_unit_meter_per_minute : LOCALIZATION.analytics_unit_meter_per_minute_short }) 
   }
    return intl().formatMessage({ id: LOCALIZATION.unknown }) 
}

export const analyticsConvertedValueFormated = (value?: AnalyticsConvertedValue): string => {
    return analyticsConvertedAddUnits(`${value?.value || 0}`, value?.units)
}

export const analyticsConvertedValueFormatedFromParts = (
    value: AnalyticsConvertedValue | undefined, 
    partType: AnalyticsConvertedDataType.partWalk | AnalyticsConvertedDataType.partRun | AnalyticsConvertedDataType.partSprint | undefined,
): string => {
    if (!!value?.parts?.length) {
        const matchPart = value?.parts.find((item) => item.type === partType)
        if (matchPart) {
            return analyticsConvertedAddUnits(`${matchPart.value}`, matchPart?.units)
        }
    }
    return "0"
}

export const analyticsConvertedLocalizedWithUnits = (id: string, item?: AnalyticsConvertedItem | AnalyticsConvertedValue | AnalyticsConvertedDataUnit): string => {
    const subItem = item as any;
    const units: AnalyticsConvertedDataUnit = (subItem && subItem["units"]) ? subItem["units"] : item
    return analyticsConvertedAddUnits(intl().formatMessage({ id }), units, (units) ? "," : undefined)
}

export const analyticsConvertedAddUnits = (src?: string, unit?: AnalyticsConvertedDataUnit, separator?: string): string => {
    if (!unit) {
        unit = AnalyticsConvertedDataUnit.Number;
    }
    if (!src) {
        src = ""
    }
    if (separator && separator.length > 0) {
        src += separator;
    }
    switch (unit) {
        case AnalyticsConvertedDataUnit.Number: 
            return src
        case AnalyticsConvertedDataUnit.Percent: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_unit_percent_format }, { value: src});
        case AnalyticsConvertedDataUnit.Seconds: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_unit_seconds_format }, { value: src });
        case AnalyticsConvertedDataUnit.Minutes: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_unit_minutes_format }, { value: src });
        case AnalyticsConvertedDataUnit.Hours: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_unit_hours_format }, { value: src });
        case AnalyticsConvertedDataUnit.KM: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_unit_km_format }, { value: src });
        case AnalyticsConvertedDataUnit.Meters: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_unit_meters_format }, { value: src });
        case AnalyticsConvertedDataUnit.KmPerHour: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_unit_km_per_hour_format }, { value: src });
        case AnalyticsConvertedDataUnit.MeterPerSecond: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_unit_meter_per_second_format }, { value: src });
        case AnalyticsConvertedDataUnit.MeterPerMinute: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_unit_meter_per_minute_format }, { value: src });
    }
    return `${src} ${unit}` 
}

