// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import dayjs, { Dayjs } from 'dayjs';
import EPGEvent from '../EPGEvents/EPGEvent';
import Event from './Event';
import { GameRepeatType, WeekDay } from './EventWithoutID';
import { dayjsFrom, dayjsFromDateStr } from '../../../Pages/EventsPage/AddEvent/helpers';

import isBetween from 'dayjs/plugin/isBetween';
// var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export const isTodayEvent = (item: Event): boolean => {
  return isEventAtDay(item, dayjs())
};

export const isTomorrowEvent = (item: Event): boolean => {
  return isEventAtDay(item, dayjs().add(1, 'day'))
};

export const isCurrentEPGEvent = (epgEvent: EPGEvent): boolean => {
  if (!epgEvent) {
    return false;
  }
  const now = dayjs();
  const startAt = dayjsFromDateStr(epgEvent.startAt);
  const endAt = dayjsFromDateStr(epgEvent.endAt);

  return (startAt?.isBefore(now) && endAt?.isAfter(now));
};

export const isCurrentEvent = (item: Event): boolean => {
  if (!item || item.disabled) {
    return false;
  }

  const now = dayjs()
  const start = dayjsFrom("start", item.startDate, item.startTime, item.endDate, item.endTime)

  if (item.repeatType === GameRepeatType.NoRepeat) {
    // non repepeat use simple match
    const end = dayjsFrom("end", item.startDate, item.startTime, item.endDate, item.endTime)
    if (start?.isBefore(now) && end?.isAfter(now)) {
      return true;
    }
    return false;
  }
  else if (item.repeatType === GameRepeatType.EveryWeek) {// repeat

    if (!item.repeatDays?.length) {// if repeat week did not define days then not match
      return false;
    }

    if (start?.isAfter(now)) {// start in future
      return false;
    }

    if (item.endDate) { // if have end date in past then not match
      const end = dayjsFrom("endDate", item.startDate, item.startTime, item.endDate, item.endTime)
      if (end?.isBefore(now)) {
        return false
      }
    }

    const weekDay = now.locale('en').format('dd').toLowerCase() as WeekDay;
    if (item.repeatDays.includes(weekDay)) {
      // now day of week match -> check time match
      const nowDateStr = now.locale('en').format('YYYY.MM.DD')
      const start = dayjsFrom("start", nowDateStr, item.startTime, null, item.endTime)
      const end = dayjsFrom("end", nowDateStr, item.startTime, null, item.endTime)
      if (start?.isBefore(now) && end?.isAfter(now)) {
        return true;
      }
      return false;
    }
  }

  return false;
};

export const isEventAtDay = (item: Event, testDayStart: Dayjs): boolean => {
  if (!item || item.disabled) {
    return false;
  }

  if (!testDayStart) {
    return false;
  }
  testDayStart = testDayStart.startOf('day')

  const start = dayjsFrom("start", item.startDate, item.startTime, item.endDate, item.endTime)

  if (item.repeatType === GameRepeatType.NoRepeat) {
    // non repepeat use simple match
    const end = dayjsFrom("end", item.startDate, item.startTime, item.endDate, item.endTime)
    if (start?.isSame(testDayStart, 'day') || end?.isSame(testDayStart)) {
      return true;
    }
    return false;
  }
  else if (item.repeatType === GameRepeatType.EveryWeek) {// repeat

    if (!item.repeatDays?.length) {// if repeat week did not define days then not match
      return false;
    }

    const testDayEnd = testDayStart.endOf('day')

    if (start?.isAfter(testDayEnd)) {// start in future
      return false;
    }

    if (item.endDate) { // if have end date in past then not match
      const end = dayjsFrom("endDate", item.startDate, item.startTime, item.endDate, item.endTime)
      if (end?.isBefore(testDayStart)) {
        return false
      }
    }

    const weekDay = testDayStart.locale('en').format('dd').toLowerCase() as WeekDay;
    if (item.repeatDays.includes(weekDay)) {
      // now day of week match -> check time match
      const now = dayjs()
      const todayEnd = now.endOf('day')
      if (testDayStart.isBefore(todayEnd)) {// today test
        const nowDateStr = testDayStart.locale('en').format('YYYY.MM.DD')
        const end = dayjsFrom("end", nowDateStr, item.startTime, null, item.endTime)
        if (end?.isAfter(now)) {
          return true;
        }
        return false;
      }
      else { // not today test
        return true;
      }
    }
  }

  return false;
};

export const isEventIntersectEvent = (item1: Event, item2: Event): boolean => {
  if (!item1 || item1.disabled || !item2 || item2.disabled) {
    return false;
  }
  if (item1.priority !== item2.priority) {
    return false;
  }
  if (item1.repeatType !== GameRepeatType.NoRepeat && item2.repeatType === GameRepeatType.NoRepeat) {
    // reverce items if item2 is NoRepeat and item1 EveryWeek
    const tempItem = item1;
    item1 = item2;
    item2 = tempItem;
  }

  const start1 = dayjsFrom("start", item1.startDate, item1.startTime, item1.endDate, item1.endTime)
  const start2 = dayjsFrom("start", item2.startDate, item2.startTime, item2.endDate, item2.endTime)
  const now = dayjs()

  if (item1.repeatType === GameRepeatType.NoRepeat) {// non repeat events
    const end1 = dayjsFrom("end", item1.startDate, item1.startTime, item1.endDate, item1.endTime)
    if (end1?.isBefore(now)) {// event in past
      return false;
    }

    // non repepeat use simple match
    if (item2.repeatType === GameRepeatType.NoRepeat) {
      const end2 = dayjsFrom("end", item2.startDate, item2.startTime, item2.endDate, item2.endTime)
      if (end2?.isBefore(now)) {// event in past
        return false;
      }

      if (start1?.isBetween(start2, end2) || end1?.isBetween(start2, end2) || start2?.isBetween(start1, end1) || end2?.isBetween(start1, end1)) {
        return true;
      }
      return false;
    }

    // item2 repeat
    else if (item2.repeatType === GameRepeatType.EveryWeek) {
      if (!item2.repeatDays?.length) {// if repeat week did not define days then not match
        return false;
      }

      const end2 = dayjsFrom("endDate", item2.startDate, item2.startTime, item2.endDate, item2.endTime)
      if (end2?.isBefore(now)) {// event in past
        return false;
      }
      if (end1?.isBefore(start2) || end2?.isBefore(start1)) {// events not intersect by end date
        return false;
      }

      const weekDay = start1?.locale('en').format('dd').toLowerCase() as WeekDay;
      if (item2.repeatDays.includes(weekDay)) {
        // now day of week match -> check time match
        const newDateStr = item1.startDate
        const start2 = dayjsFrom("start", newDateStr, item2.startTime, null, item2.endTime)
        const end2 = dayjsFrom("end", newDateStr, item2.startTime, null, item2.endTime)
        if (start1?.isBetween(start2, end2) || end1?.isBetween(start2, end2) || start2?.isBetween(start1, end1) || end2?.isBetween(start1, end1)) {
          return true;
        }
        return false;
      }
  
    }
    return false;
  }

  // item1 & item2 repeat
  else if (item1.repeatType === GameRepeatType.EveryWeek && item2.repeatType === GameRepeatType.EveryWeek) {

    if (!item1.repeatDays?.length || !item2.repeatDays?.length) {// if repeat week did not define days then not match
      return false;
    }

    const end1 = dayjsFrom("endDate", item1.startDate, item1.startTime, item1.endDate, item1.endTime)
    const end2 = dayjsFrom("endDate", item2.startDate, item2.startTime, item2.endDate, item2.endTime)
    if (end1?.isBefore(now) || end2?.isBefore(now)) {// event in past
      return false;
    }
    if (end1 && end2) { // check end dates not intersect
      if (!(start1?.isBetween(start2, end2) || end1?.isBetween(start2, end2) || start2?.isBetween(start1, end1) || end2?.isBetween(start1, end1))) {
        //events not intresect by end dates
        return false;
      }
    }

    const intersectDay = item1.repeatDays.find((weekDay) => item2.repeatDays.includes(weekDay) )
    if (intersectDay) { 
      // have intersect weekDay

      const nowDateStr = now.locale('en').format('YYYY.MM.DD')
      const start1 = dayjsFrom("start", nowDateStr, item1.startTime, null, item1.endTime)
      const end1 = dayjsFrom("end", nowDateStr, item1.startTime, null, item1.endTime)
      const start2 = dayjsFrom("start", nowDateStr, item2.startTime, null, item2.endTime)
      const end2 = dayjsFrom("end", nowDateStr, item2.startTime, null, item2.endTime)
      
      if (start1?.isSame(start2) || start1?.isBetween(start2, end2) || end1?.isBetween(start2, end2) || start2?.isBetween(start1, end1) || end2?.isBetween(start1, end1)) {
        return true;
      }
    }
    return false;
  }

  return false;
};


export const isEventEnded = (item: Event): boolean => {
  if (!item || item.disabled) {
    return true;
  }

  const now = dayjs()

  if (item.repeatType === GameRepeatType.NoRepeat) {
    // non repepeat use simple match
    const end = dayjsFrom("end", item.startDate, item.startTime, item.endDate, item.endTime)
    if (end?.isBefore(now)) {// passed event
      return true;
    }
    return false;
  }
  else if (item.repeatType === GameRepeatType.EveryWeek) {// repeat

    if (!item.repeatDays?.length) {// if repeat week did not define days then not match
      return false;
    }

    if (item.endDate) { // if have end date in past then not match
      const end = dayjsFrom("endDate", item.startDate, item.startTime, item.endDate, item.endTime)
      if (end?.isBefore(now)) {
        return true
      }
    }
    return false;
  }

  return false;
};