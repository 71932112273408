// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { IntlShape } from "react-intl";
import { cloneDeep } from 'lodash';
import { AnalyticsTimeIntervalType, AnalyticsTimeIntervalTypeHeader, analyticsTimeIntervalName } from "../../../../Data/Analytics/AnalyticsTypes";
import LOCALIZATION from "../../../../Localization";
import { intl } from "../../../../Localization/LocalizationProvider";
import { AnalyticsConvertedDataType, AnalyticsConvertedItem, analyticsAnalyticsConvertedDataTypeName, analyticsConvertAvgSpeed, analyticsConvertDistanceCovered,  analyticsConvertPossession } from "../../../../Data/Analytics/AnalyticsConverters";
import { AnalyticsStatPossessionMinute, AnalyticsStatPossessionPerMinuteForTime } from "../../../../Data/Analytics/AnalyticsStatPossessionPerMinute/AnalyticsStatPossessionPerMinute";
import { AnalyticsStatDistanceMinute, AnalyticsStatDistancePerMinuteForTime } from "../../../../Data/Analytics/AnalyticsStatDistancePerMinute/AnalyticsStatDistancePerMinute";
import { AnalyticsStatSpeedMinute, AnalyticsStatSpeedPerMinuteForTime } from "../../../../Data/Analytics/AnalyticsStatSpeedPerMinute/AnalyticsStatSpeedPerMinute";
import { secondsToHHmmss, timeToSeconds } from "../../../../Components/_BaseUI/AnalyticsTimeField";

const AnalyticsWithMniutesArr = [AnalyticsConvertedDataType.possession, AnalyticsConvertedDataType.distanceCovered, AnalyticsConvertedDataType.avgSpeed];

export type BarLineChartItemData = Readonly<{
    name: string;
    value1: number;
    value2: number;
}>;

export type BarLineChartData = {
    [key in AnalyticsConvertedDataType]?: BarLineChartItemData
}

const analyticsConvertedDataToBarLineChartItemData = (name: string, convertedData?: AnalyticsConvertedItem): BarLineChartItemData => {
    return {
        name: name,
        value1: convertedData?.team1.value || 0,
        value2: convertedData?.team2.value || 0,
    }
}

export enum AnalyticsHorizBarVisualiseMode {
    Bar = 'bar',
    Line = 'line',
}
export const ANALYTICS_HOR_BAR_VISUALISE_MODE_ARR = [
    AnalyticsHorizBarVisualiseMode.Bar, AnalyticsHorizBarVisualiseMode.Line
]

export const analyticsHorizBarVisualiseModeName = (type?: AnalyticsHorizBarVisualiseMode): string => {
    switch (type) {
        case AnalyticsHorizBarVisualiseMode.Bar: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_visualize_mode_bar }) 
        case AnalyticsHorizBarVisualiseMode.Line: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_visualize_mode_line }) 
    }
    return intl().formatMessage({ id: LOCALIZATION.unknown }) 
}

export const isBarLineChartDataNotEmpty = (data?: BarLineChartData): boolean => {
    if (!data) {
        return false;
    }

    for(const val of AnalyticsWithMniutesArr) {
        if (data[val]) {
            return true;
        }
    }
    return false;
}

export type BarLineChartItem = {
    id: number; // index to group together same time interval for all data
    startSec: number;
    endSec: number;
    timeName: string;// like: Half 1, Half 2,  Overtime
    itemName: string;// like: 1/2, 1/8, 2/8
    titleForTooltip: string;// like: "1-5 minutes statistics"
    data: BarLineChartData;
    child?: BarLineChartItem[];
    hiddenData?: BarLineChartData;// used when make fake items
};

export type BarLineChartStatType = {
    index: AnalyticsConvertedDataType;
    name: string;
};

export type BarLineChartConfig = {
    availableStats: BarLineChartStatType[];// if stat exist in data then is is presented in this list
    data: BarLineChartItem[];
};

function getGranulaNamesPerMinuteArr (
    minutesCount: number,
): string[] {
    let granulaNamesArr: string[] = []
    for(let index = 0; index < minutesCount; index++) {
        granulaNamesArr.push(`${index+1}`);
    }

    return granulaNamesArr;
}

export function createTemplatePerMinuteBarLineChartItemsArr(
    possessionIntervals?: AnalyticsStatPossessionPerMinuteForTime[],
    distanceIntervals?: AnalyticsStatDistancePerMinuteForTime[],
    speedIntervals?: AnalyticsStatSpeedPerMinuteForTime[],
): BarLineChartItem[] {
    let tempItems: BarLineChartItem[] = [];
    const allInputArr = [possessionIntervals, distanceIntervals, speedIntervals]

    let intervalCount: number =
        allInputArr.sort((a, b) => (a?.length || 0) - (b?.length || 0))?.[0]?.length || 0;
    
    let intervalLengthArr: number[] = []
    let intervalObjectArr: (AnalyticsTimeIntervalTypeHeader | undefined)[] = []
    for(let index = 0; index < intervalCount; index++) {
        let maxLen = undefined;
        let intervalObj = undefined;
        for(const arr of allInputArr) {
            if (arr && index < arr.length) {
                const dataObj = arr[index];
                const newLan = dataObj?.interval_stat?.length || 0;
                if ((maxLen || 0) < newLan) {
                    maxLen = newLan;
                    intervalObj = dataObj;
                }
            }
        }
        if (maxLen !== undefined) {
            intervalLengthArr.push(maxLen);
            intervalObjectArr.push(intervalObj);
        }
    }

    // fill root items with longest interval
    if (intervalLengthArr.length) {
        const emptyData: BarLineChartData = {};
        const emptyValue = {
            name: "",
            value1: 0,
            value2: 0,
        }
        for(const key of AnalyticsWithMniutesArr) {
            emptyData[key] = emptyValue;
        }


        for(let index = 0; index < intervalLengthArr.length; index++) {
            const minutesCount = intervalLengthArr[index];
            const intervalObj = intervalObjectArr[index];
            const startSec = timeToSeconds(intervalObj?.start_at) || 0
            const endSec = timeToSeconds(intervalObj?.end_at) || 0

            const timeName = analyticsTimeIntervalName(intervalObj?.type || AnalyticsTimeIntervalType.time, intervalObj?.type_order_num || 0);

            let child: BarLineChartItem[] = [];
            if (minutesCount) {
                const granulaNamesArr = getGranulaNamesPerMinuteArr(minutesCount);
                for(let cIndex = 0; cIndex < granulaNamesArr.length; cIndex++) {
                    const startAtSec = startSec + cIndex * 60;
                    const endAtSec = startAtSec + 60;
                    child.push({
                        id: index * 100 + cIndex,
                        startSec: startAtSec,
                        endSec: endAtSec,
                        timeName,
                        itemName: granulaNamesArr[cIndex],
                        titleForTooltip: `${secondsToHHmmss(startAtSec, true)} - ${secondsToHHmmss(endAtSec, true)}\n${timeName}, ${intl().formatMessage({ id: LOCALIZATION.minute_number }, {"number": granulaNamesArr[cIndex]})}`,
                        data: cloneDeep(emptyData),
                    })
                }
            }

            tempItems.push({
                id: index,
                startSec,
                endSec,
                timeName,
                itemName: "",
                titleForTooltip: timeName,
                data: cloneDeep(emptyData),
                child,
            })
        }
    }

    return tempItems;
}


export function createBarLineChartConfigForPerMinuteReportDetails(
    intl: IntlShape,
    possessionIntervals?: AnalyticsStatPossessionPerMinuteForTime[],
    distanceIntervals?: AnalyticsStatDistancePerMinuteForTime[],
    speedIntervals?: AnalyticsStatSpeedPerMinuteForTime[],
): BarLineChartConfig {
    // create filled template array with all names
    let outItems: BarLineChartItem[] = createTemplatePerMinuteBarLineChartItemsArr(
        possessionIntervals, distanceIntervals, speedIntervals);

    let availableStats: BarLineChartStatType[] =[];


    function fillDataWithIntervals<S, T extends {
        interval_stat?: S[];
    } & AnalyticsTimeIntervalTypeHeader>
        (type: AnalyticsConvertedDataType, 
        intervalsArr: Array<T> | undefined, 
        converter:((item: S | undefined) => AnalyticsConvertedItem | undefined) ) 
    {
        const getNameForDataIndex = (index: AnalyticsConvertedDataType, firstConverted: AnalyticsConvertedItem | undefined): string => {
            const name = analyticsAnalyticsConvertedDataTypeName(index, firstConverted);
            availableStats.push({
                index, name
            })
            return name;
        }

        if (intervalsArr && intervalsArr.length) {
            const name = getNameForDataIndex(type, converter(intervalsArr[0].interval_stat?.[0]));
    
            let index = 0;
            for(const stat of intervalsArr) {
                const item = outItems[index];
                if (!("data" in item)) {
                    continue
                } 
    
                // score
                item.data[type] = analyticsConvertedDataToBarLineChartItemData(name, converter(stat.interval_stat?.[0]));
    
                // have additonal granulas
                if (stat.interval_stat?.length) {
                    const granulaCount = Math.min(item.child?.length || 0, stat.interval_stat?.length ||  0);
                    for(let cIndex = 0; cIndex < granulaCount; cIndex++) {
                        const subItem = item.child?.[cIndex];
                        const subStat = stat.interval_stat[cIndex];
                        if (subItem && subStat) {
                            subItem.data[type] = analyticsConvertedDataToBarLineChartItemData(name, converter(subStat));
                        }
                    }                   
                }
    
                index ++;
            }
        }
    }
 
    // fill possession
    fillDataWithIntervals<AnalyticsStatPossessionMinute, AnalyticsStatPossessionPerMinuteForTime>(
        AnalyticsConvertedDataType.possession,
        possessionIntervals,
        (item) => analyticsConvertPossession(item?.stat) );
   
    // fill distance
    fillDataWithIntervals<AnalyticsStatDistanceMinute, AnalyticsStatDistancePerMinuteForTime>(
        AnalyticsConvertedDataType.distanceCovered,
        distanceIntervals,
        (item) => analyticsConvertDistanceCovered(item?.stat) );
       
    // fill avg speed
    fillDataWithIntervals<AnalyticsStatSpeedMinute, AnalyticsStatSpeedPerMinuteForTime>(
        AnalyticsConvertedDataType.avgSpeed,
        speedIntervals,
        (item) => analyticsConvertAvgSpeed(item?.stat) );
 

    // flat all data if need
    {
        let flatOutItems: BarLineChartItem[] = []
        let index = 0;

        const emptyValue: BarLineChartItemData = {
            name: "",
            value1: 0,
            value2: 0,
        }

        for(const item of outItems) {
            if (item.child) {
                for (const subItem of item.child) {
                    if (subItem) { /*&& isBarLineChartDataNotEmpty(item.data)*/
                        flatOutItems.push({...subItem, id: index});
                        index ++;
                    }
                }
                // add fake divider for parent element
                const fakeEndItem = {
                    ...item, 
                    id: index,
                    hiddenData: cloneDeep(item.data)
                }
                for(const key of AnalyticsWithMniutesArr) {
                    fakeEndItem.data[key] = emptyValue;
                }
                flatOutItems.push(fakeEndItem);
                index ++;
            }
            else {
                flatOutItems.push({...item, id: index});
                index ++;
            }
        }

        outItems = flatOutItems;
    }

    return {
        availableStats,
        data: outItems,
    }
}