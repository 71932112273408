// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import AnalyticsGameArchive, { AnalyticsGameShared } from "./AnalyticsGameArchive/AnalyticsGameArchive";
import { AnalyticsStatPossessionHeatmapForTime } from "./AnalyticsStatPossessionHeatmap/AnalyticsStatPossessionHeatmap";
import { AnalyticsStatScoresForTime } from "./AnalyticsStatScore/AnalyticsStatScore";
import { GetTeamRefById } from "./AnalyticsTeamRef/hook";
import { AnalyticsStatTeamSide } from "./AnalyticsTypes";

export type AnalyticsTeamDetails = {
    title?: string;
    color?: string
    score?: number;
};


export type AnalyticsVideoArchive = Readonly<{
    teamLeft?: AnalyticsTeamDetails;
    teamRight?: AnalyticsTeamDetails;
    isTeam1And2Swapped: boolean;
}>;

export const analyticsSplitLeftRightTeam = (
    gameResultInfo: AnalyticsGameArchive | undefined,
    scoreInfo: AnalyticsStatScoresForTime | undefined,
    heatmapInfo: AnalyticsStatPossessionHeatmapForTime | undefined,
    getTeamRefByExternalId: GetTeamRefById | undefined
): AnalyticsVideoArchive => {
    const teamLeft: AnalyticsTeamDetails = {
    }
    const teamRight: AnalyticsTeamDetails = {
    }

    const team1_side = heatmapInfo?.team1_side || scoreInfo?.team1_side || AnalyticsStatTeamSide.left
    const team2_side = heatmapInfo?.team2_side || scoreInfo?.team2_side || AnalyticsStatTeamSide.right
    const team1 = (team1_side ===  AnalyticsStatTeamSide.left) ? teamLeft : teamRight;
    const team2 = (team2_side ===  AnalyticsStatTeamSide.left) ? teamLeft : teamRight;
    const isTeam1And2Swapped = (team1_side !==  AnalyticsStatTeamSide.left)

    team1.title = getTeamRefByExternalId?.(gameResultInfo?.team1)?.name || gameResultInfo?.team1;
    team2.title = getTeamRefByExternalId?.(gameResultInfo?.team2)?.name || gameResultInfo?.team2;

    team1.color = AnalyticsGameShared.team1Color
    team2.color = AnalyticsGameShared.team2Color

    team1.score = scoreInfo?.interval_stat?.team1
    team2.score = scoreInfo?.interval_stat?.team2

    return ({
        isTeam1And2Swapped,
        teamLeft,
        teamRight,
    })
}