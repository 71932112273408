// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { analyticsConvertAllData, analyticsConvertedDataUnitName, analyticsConvertedLocalizedWithUnits } from "../../../Data/Analytics/AnalyticsConverters";
import AnalyticsGameArchive from "../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive";
import { AnalyticsStatDistanceGame } from "../../../Data/Analytics/AnalyticsStatDistance/AnalyticsStatDistance";
import { AnalyticsStatSpeedTeam } from "../../../Data/Analytics/AnalyticsStatSpeed/AnalyticsStatSpeed";
import { GetTeamRefById } from "../../../Data/Analytics/AnalyticsTeamRef/hook";
import LOCALIZATION from "../../../Localization";
import { BarChartItem } from "./helper";

export const createPhysicalReportVertBarChartConfig = 
(
  gameResultInfo: AnalyticsGameArchive | undefined,
  distanceStat: AnalyticsStatDistanceGame | undefined, 
  speedStat: AnalyticsStatSpeedTeam | undefined, 
  speedInAttackStat: AnalyticsStatSpeedTeam | undefined, 
  speedInDefenseStat: AnalyticsStatSpeedTeam | undefined, 
  getTeamRefByExternalId: GetTeamRefById | undefined,
  ): BarChartItem[] => 
{

  const convertedData = analyticsConvertAllData(
    getTeamRefByExternalId?.(gameResultInfo?.team1)?.shortName || gameResultInfo?.team1,
    getTeamRefByExternalId?.(gameResultInfo?.team2)?.shortName || gameResultInfo?.team2,

    gameResultInfo?.score,
    undefined,
    distanceStat,
    speedStat,
    undefined,
    speedInAttackStat,
    speedInDefenseStat,
  );

  let outArr: BarChartItem[] = []

  if (!gameResultInfo) {
    return outArr;
  }

  if (convertedData.distanceCovered) {
    outArr.push({
      label: analyticsConvertedLocalizedWithUnits( LOCALIZATION.analytics_distance_covered, convertedData.distanceCovered),
      value1: convertedData.distanceCovered.team1,
      value2: convertedData.distanceCovered.team2,
    })
  }

  if (convertedData.avgSpeed) {
    outArr.push({
      label: analyticsConvertedLocalizedWithUnits( LOCALIZATION.analytics_avg_speed, convertedData.avgSpeed),
      value1: convertedData.avgSpeed.team1,
      value2: convertedData.avgSpeed.team2,
    })
  }

  if (convertedData.avgSpeedInAttack) {
    outArr.push({
      label: analyticsConvertedLocalizedWithUnits( LOCALIZATION.analytics_attacking, convertedData.avgSpeedInAttack),
      value1: convertedData.avgSpeedInAttack.team1,
      value2: convertedData.avgSpeedInAttack.team2,
    })
  }

  if (convertedData.avgSpeedInDefense) {
    outArr.push({
      label: analyticsConvertedLocalizedWithUnits( LOCALIZATION.analytics_defending, convertedData.avgSpeedInDefense),
      value1: convertedData.avgSpeedInDefense.team1,
      value2: convertedData.avgSpeedInDefense.team2,
    })
  }
 
  if (convertedData.intensity) {
    outArr.push({
      label: analyticsConvertedLocalizedWithUnits( LOCALIZATION.analytics_intensity, convertedData.intensity),
      value1: convertedData.intensity.team1,
      value2: convertedData.intensity.team2,
      normBase: convertedData.intensity.normBase,
      units: analyticsConvertedDataUnitName(convertedData.intensity.units),
    })
  }

  return outArr;
}