// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

import { AnalyticsTeamSide, localizedAnalyticsTeamSide } from '../../../Data/Analytics/AnalyticsGameEvent/AnalyticsGameEvent';


type Props = Readonly<{
  selectedSide: AnalyticsTeamSide | undefined;
  onChange: (newSide: AnalyticsTeamSide | undefined) => void;
}>;


const AnalyticsTeamSideSelector = (props: Props) => {

  const handleChange = (event: any) => {
    const newSide = event.target.value as AnalyticsTeamSide;
    props.onChange(newSide);
  };

  const renderMenuOptions = () => (
    Object.values(AnalyticsTeamSide).map((side: string) => (
      <MenuItem
        key={ side }
        value={ side }
      >
        { localizedAnalyticsTeamSide(side as AnalyticsTeamSide) }
      </MenuItem>
    ))
  );

  return (
    <FormControl>
      <Select
        id="select-team-side-select"
        value={ props.selectedSide || AnalyticsTeamSide.Left }
        onChange={handleChange}
        sx = {{ 
          height: '38px',
          width: '90pt'
      }}
      >
      { renderMenuOptions() }
      </Select>
    </FormControl>
  );
};


export default AnalyticsTeamSideSelector;
