// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useQuery } from 'react-query';

import { ResultSingle } from '../../_Networking/ReactQuery/template';
import { StreamingSettings } from '..';
import ResponseSingle from '../../_Networking/ReactQuery/ResponseSingle';
import { getKey, fetchData } from '../fetch';


export const useNapiStreamingSettingsFetch = (): ResultSingle<StreamingSettings> => {
  const queryKey = getKey();

  return useQuery<ResponseSingle<StreamingSettings>>({
    queryKey,
    queryFn: () => fetchData(),
    // enabled: enableToFetch,
  }) as ResultSingle<StreamingSettings>;
};
