// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import Popup from '../../../Components/_Layout/Popup';
import Button from '../../../Components/_BaseUI/Button';
import { NODE_JS_BACKEND_URL, JAVA_BACKEND_URL } from '../../../constants';
import FieldSet, { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';
import { resetToDefaultState, setJavaBackend, setNodeJSBackend, DEFAULT_NAPI, DEFAULT_JAPI, DEFAULT_NAPI_FIN } from '../../../Configuration/ENV';
import AuthorisationManager from '../../../Data/Auth/AuthorisationManager';
import { Card, Grid } from '@mui/material';


type Props = Readonly<{
  onClose: () => void;
}>;


const NAPI_LIST = [
  {
    id: DEFAULT_NAPI_FIN,
    name: `DEFAULT ${DEFAULT_NAPI}`,
  },
  {
    id: 'https://home.cybercamera.ru/api/napi',
    name: 'PROD home.cybercamera.ru/api/napi',
  },
  {
    id: 'http://192.168.10.94/api/napi',
    name: 'CCAM5 192.168.10.94/api/napi',
  },
  {
    id: 'http://192.168.10.105/api/napi',
    name: 'CCAM30 192.168.10.105/api/napi',
  },
  {
    id: 'http://192.168.10.182/api/napi',
    name: 'SERVER 192.168.10.182/api/napi',
  },
  {
    id: 'http://localhost:9900/napi',
    name: 'LOCAL_DEV localhost:9900/napi',
  },
  {
    id: 'http://localhost:1337',
    name: 'LOCAL_DEV localhost:1337',
  },
];
const JAPI_LIST = [
  {
    id: DEFAULT_JAPI,
    name: `DEFAULT ${DEFAULT_JAPI}`,
  },
  {
    id: 'https://home.cybercamera.ru/api',
    name: 'PROD home.cybercamera.ru/api',
  },
  {
    id: 'http://192.168.10.94/api',
    name: 'CCAM5 192.168.10.94/api',
  },
  {
    id: 'http://192.168.10.105/api',
    name: 'CCAM30 192.168.10.105/api',
  },
  {
    id: 'http://192.168.10.182/api',
    name: 'SERVER 192.168.10.182/api',
  },
  {
    id: 'http://localhost:9900',
    name: 'LOCAL_DEV localhost:9900',
  },
];

const ServicePopup: React.FC<Props> = (props: Props) => {
  const [napi, setNapi] = React.useState<string>(NODE_JS_BACKEND_URL);
  const [japi, setJapi] = React.useState<string>(JAVA_BACKEND_URL);

  const handleReload = (): void => {
    AuthorisationManager.shared.logout(() => {
      document.location.replace('/');
    });
  };
  const handleResetToDefault = () => {
    resetToDefaultState();

    handleReload();
  };
  const handleSubmit = () => {
    setNodeJSBackend(napi);
    setJavaBackend(japi);

    handleReload();
  };

  const SX = {
    padding: '40px',
  };

  return (
    <Popup
      isWide
      headerTitle={ 'Service' }
      isApplyDisabled={ false }
      isCloseDisabled={ false }
      onApply={ handleSubmit }
      onClose={ props.onClose }
    >
      <Grid
        sx={ SX }
      >
        <Card>
          <FieldSet
            label={ 'NAPI:' }
            input={ {
              type: FieldSetInputType.Select,
              value: napi,
              options: NAPI_LIST,
              onChange: setNapi,
            } }
          />
          <FieldSet
            label={ 'JAPI:' }
            input={ {
              type: FieldSetInputType.Select,
              value: japi,
              options: JAPI_LIST,
              onChange: setJapi,
            } }
          />
          <Button
            onClick={ handleResetToDefault }
          >
            Reset
          </Button>
      </Card>
      </Grid>
    </Popup>
  );
};


export default ServicePopup;
