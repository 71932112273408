// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import fetchJSONData from '../../_Networking/ReactQuery/fetch';
import { NODE_JS_BACKEND_URL } from '../../../constants';
import { queryCache, queryClient } from '../../../AppProviders';
import ResponseSingle from '../../_Networking/ReactQuery/ResponseSingle';
import StreamingProfileOptions from '..';


const PREFIX_OF_A_COMPOSITE_KEY = 'streaming-settings-profile-options';

export const getKey = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

export const flushStreamingSettingsProfileOptionsCache = () => {
  const queries = queryCache.findAll(getKey());

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};

export const getStreamingSettingsProfileOptionsCached = () => (
  queryClient.getQueryData<ResponseSingle<StreamingProfileOptions>>(getKey())
);

export const fetchData = async (): Promise<ResponseSingle<StreamingProfileOptions>> => {
  const cacheData = getStreamingSettingsProfileOptionsCached();

  if (cacheData) { return cacheData; }

  return await fetchJSONData<ResponseSingle<StreamingProfileOptions>>(
    `${NODE_JS_BACKEND_URL}/streaming/profiles/options.json`,
    {
      method: 'GET',
    }
  );
};


