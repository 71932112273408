// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { uniq } from 'lodash';
import { Grid, List } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import CameraItem from './CameraItem';
import H2 from '../../../../Components/_Layout/H2';
import Camera from '../../../../Data/Camera/ActiveCamera/Camera';
import { EMPTY_ARRAY } from '../../../../constants';
import CamerasPreSet, { SelectPreSet } from './CamerasPreSet';
import { UserCameraAccess, UserRole } from '../../../../Data/AccountUsers/UserWithoutID';
import {
  calculateCameraAccessByPreSet,
  calculateCameraPreSetBySelectedIDs, calculateInitialValueCamerasPreSet, calculateInitialValueCheckedIDs,
  calculateSelectedCamerasByPreSet
} from './helpers';
import LOCALIZATION from '../../../../Localization';


type Props = Readonly<{
  isEditMode: boolean;
  userCameraAccess: UserCameraAccess;
  userRole: UserRole;
  editorCameraIdList?: string[];
  withRootAccess: boolean;
  userCameras: string[];
  cameras: Camera[];
  onChange: (cameraAccess: UserCameraAccess, selectedCamerasIDs: string[]) => void;
} & WrappedComponentProps>;

const CamerasList: React.FC<Props> = (props: Props) => {
  const { userCameras = EMPTY_ARRAY } = props;
  const [cameraAccess, setCameraAccess] = React.useState<UserCameraAccess>(UserCameraAccess.All);
  const [checkedIDs, setCheckedIDs] = React.useState<string[]>(calculateInitialValueCheckedIDs(userCameras));
  const [camerasPreSet, setCamerasPreSet] = React.useState<SelectPreSet>(calculateInitialValueCamerasPreSet(props.withRootAccess, props.cameras.length, calculateInitialValueCheckedIDs(userCameras).length, props.userCameraAccess, props.isEditMode));

  const handleToggleCamera = (id: string) => {
    const index = checkedIDs.indexOf(id);
    const checkedIDsNewValue = (index === -1) ? [ ...checkedIDs, id ] : checkedIDs.filter((i) => (i !== id));

    setCheckedIDs(checkedIDsNewValue);

    setCamerasPreSet(calculateCameraPreSetBySelectedIDs(props.cameras.length, checkedIDsNewValue.length));
  };
  const handleChangeCamerasPreSet = (value: SelectPreSet) => {
    setCamerasPreSet(value);
  };

  const renderItem = (camera: Camera) => (
    <CameraItem
      key={ camera.id }
      isChecked={ checkedIDs.includes(camera.cameraId) }
      camera={ camera }
      onClick={ handleToggleCamera }
    />
  );

  React.useEffect(() => {
    setCheckedIDs(uniq([ ...checkedIDs, ...userCameras ]));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCameras]);

  React.useEffect(() => {
    props.onChange(cameraAccess, checkedIDs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraAccess, checkedIDs]);

  React.useEffect(() => {
    const calculatedSelectedCamerasByPreSet = calculateSelectedCamerasByPreSet(props.cameras, (props.editorCameraIdList || EMPTY_ARRAY), camerasPreSet, props.withRootAccess);

    if (calculatedSelectedCamerasByPreSet !== undefined) {
      setCheckedIDs(calculatedSelectedCamerasByPreSet);
    }

    setCameraAccess(calculateCameraAccessByPreSet(camerasPreSet));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camerasPreSet, props.cameras.length]);

  // React.useEffect(() => {
  //   setCamerasPreSet(calculateCameraPreSetBySelectedIDs(props.cameras.length, checkedIDs.length, props.withRootAccess, props.userRole));
  // }, [props.userRole]);

  return (
    <Grid>
      <Grid
        container
      >
        <Grid
          item
          xs={ 7 }
        >
          <H2>{ props.intl.formatMessage({ id: LOCALIZATION.user_visible_cameras }) }</H2>
        </Grid>
        <Grid
          item
          xs={ 5 }
        >
          <CamerasPreSet
            withRootAccess={ props.withRootAccess }
            value={ camerasPreSet }
            onChange={ handleChangeCamerasPreSet }
          />
        </Grid>
      </Grid>
      <Grid
        sx={ {
          maxHeight: '20vh',
          overflowY: 'scroll',
          backgroundColor: '#F5F6F8',
          borderRadius: '8px',
        } }
      >
        <List>
          { 
          props.cameras
          .filter((camera) => ( (props.withRootAccess) || ((props.editorCameraIdList) && (props.editorCameraIdList?.indexOf(camera.cameraId) >= 0))))
          .map(renderItem) 
          }
        </List>
      </Grid>
    </Grid>
  );
};


export default injectIntl(CamerasList);
