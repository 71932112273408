// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import cn from 'classnames';
import { createStyles, makeStyles } from '@mui/styles';
import { Grid, Slider } from '@mui/material';


type Props = Readonly<{
  value: number;
  min: number;
  max: number;
  step?: number;
  onChange: (value: number | number[]) => void;
}>;


const useStyles = makeStyles(() => createStyles({
  root: {},
  limValueLabel: {
    minWidth: 20,
    marginTop: 3,
    marginRight: 12,
    marginLeft: 12,
  },
  limValueLabelLeft: {
    textAlign: 'right',
  },
}));

const SliderComponent: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const handleChange = (event: Event, value: number | number[]) => {
    props.onChange(value);
  };

  return (
    <Grid
      container
    >
      <Grid
        item
        className={ cn(classes.limValueLabel, classes.limValueLabelLeft) }
      >
        { props.min }
      </Grid>
      <Grid
        item
        xs
      >
        <Slider
          value={ props.value }
          min={ props.min }
          max={ props.max }
          step={ props.step }
          onChange={ handleChange }
        />
      </Grid>
      <Grid
        item
        className={ classes.limValueLabel }
      >
        { props.max }
      </Grid>
    </Grid>
  );
};


export default SliderComponent;
