// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import Order from '../../../_Networking/ReactQuery/Order';
import fetchJSONData from '../../../_Networking/ReactQuery/fetch';
import EPGEvent from '../EPGEvent';
import { getTimezoneInMinutes } from '../../../../Tools/timezone';
import { PAGE_LIMIT, NODE_JS_BACKEND_URL } from '../../../../constants';
import { queryCache, queryClient } from '../../../../AppProviders';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import { getCachedDataAllPagedItems, Result, usePagedItems } from '../../../_Networking/ReactQuery/template';


type FetchProps = Readonly<{
  date?: string;
  page: number;
  limit: number;
  order?: Order;
  orderBy?: keyof EPGEvent;
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'epg-events';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.date,
  props.page,
  props.limit,
  props.orderBy,
  props.order,
]);

const getAllCached = () => (
  getCachedDataAllPagedItems<EPGEvent>(getKeyWithoutPage())
);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<ResponseMany<EPGEvent[]>>(getKey(props))
);

const flushCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};

const fetchData = async (props: FetchProps): Promise<ResponseMany<EPGEvent[]>> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  const offset = (props.page * props.limit);
  let params = `?timezone=${getTimezoneInMinutes()}&limit=${ props.limit }&offset=${ offset }`;
  if (props.orderBy) {
    params += `&order=${ props.order }&orderBy=${ props.orderBy }`;
  }
  if (props.date) {
    params += `&date=${ props.date }`;
  }
  return await fetchJSONData<ResponseMany<EPGEvent[]>>(
    `${NODE_JS_BACKEND_URL}/events/epg${ params }`,
    {
      method: 'GET',
    }
  );
};


type UseFetchProps = Readonly<{
  date?: string;
  enableToFetch: boolean;
  page: number;
  limit?: number;
  order?: Order;
  orderBy?: keyof EPGEvent;
}>;


const useFetch = (props: UseFetchProps): Result<EPGEvent> => {
  const { enableToFetch = true, page: pg = 0, limit = PAGE_LIMIT } = props;
  const fetchTemplate = async (queryKey: QueryKey, page: number = 0) => fetchData({
    date: props?.date,
    page,
    limit,
    order: props.order,
    orderBy: props.orderBy,
  });

  return usePagedItems<EPGEvent>({
    page: pg,
    fetch: fetchTemplate,
    queryKey: getKey({
      date: props.date,
      page: pg,
      limit,
      order: props.order,
      orderBy: props.orderBy,
    }),
    config: {
      enabled: !!enableToFetch,
    },
  });
};


export {
  getKey,
  useFetch,
  getCached,
  flushCache,
  getAllCached,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
