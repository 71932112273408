// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { ReactComponent as GameFieldBasketballSvg } from './gameFieldNoBackgroundBasketball.svg';
import { ReactComponent as GameFieldFootballSvg } from './gameFieldNoBackgroundFootball.svg';
import { ReactComponent as GameFieldHandballSvg } from './gameFieldNoBackgroundHandball.svg';
import { ReactComponent as GameFieldHockeySvg } from './gameFieldNoBackgroundHockey.svg';
import { ReactComponent as GameFieldMiniFootballSvg } from './gameFieldNoBackgroundMiniFootball.svg';

const gameFieldBasketballAspectRatio = 450 / 242;
const gameFieldFootballAspectRatio = 373 / 241;
const gameFieldHandballAspectRatio = 450 / 225;
const gameFieldHockeyAspectRatio = 450 / 225;
const gameFieldMiniFootballAspectRatio = 450 / 236;

export {
    GameFieldBasketballSvg,
    GameFieldFootballSvg,
    GameFieldHandballSvg,
    GameFieldHockeySvg,
    GameFieldMiniFootballSvg,
    gameFieldBasketballAspectRatio,
    gameFieldFootballAspectRatio,
    gameFieldHandballAspectRatio,
    gameFieldHockeyAspectRatio,
    gameFieldMiniFootballAspectRatio,
}