// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import AuthorisationManager from '../../Auth/AuthorisationManager'
import { PlatformConfiguration } from '../../../Configuration/PlatformConfiguration'
import { ApiAxiosErrorBuilder } from './Errors/Builders'
import ApiErrorProcessor from './Errors/Prosessors'
import ApiError from './Errors/Types'
import { BaseCommand } from './Types'

export interface BaseCommandExecutorInterface<Result> {
  execute(succeeded: (result: Result) => void, failed: (error: ApiError) => void): void
}

export const api = axios.create({
  baseURL: PlatformConfiguration.baseApiUrl,
  responseType: 'json',
  headers: {
    'X-API-VERSION': PlatformConfiguration.apiVersion,
    'X-CC-APP-KEY': PlatformConfiguration.apiKey,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0'
  }
})

export default class BaseCommandExecutor<Result> implements BaseCommandExecutorInterface<Result> {
  private apiErrorProcessor = new ApiErrorProcessor()

  constructor(private command: BaseCommand) {
  }

  execute(succeeded: (result: Result, headers: any) => void, failed: (error: ApiError) => void): void {
    const config: AxiosRequestConfig = {
      method: this.command.method,
      url: this.command.path,
      headers: this.headers,
      data: this.command.data,
      params: this.command.params,
      timeout: 5000
    }
    api.request<Result>(config).then((response: AxiosResponse) => {
      succeeded(response.data, response.headers);
    }).catch((error: AxiosError) => {
      const apiError = new ApiAxiosErrorBuilder(error, this.command.scope)
      this.apiErrorProcessor.process(apiError, () => {
        // console.log('Session > refreshed. Will retry command execution')
        // retry command
        this.execute(succeeded, failed)
      }, failed)
    })
  }

  private get headers(): any {
    const headers: Record<string, string> = {}

    if (this.command.isAuthorisationRequired) {
      const session = AuthorisationManager.shared.session
      if (session) {
        headers.Authorization = session.accessToken
      }
    }

    const app = AuthorisationManager.shared.app?.id
    if (app) {
      headers['X-CC-UAPP-KEY'] = app
    }
    return headers
  }
}
