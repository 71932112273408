// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>


export type VKUserGoup = {
    id?: number;
    name?: string;
    type?: string;
    is_closed?: number;
    photo_50?: string;
    photo_100?: string;
    photo_200?: string;
};

export type VKUserGoupItems = Array<VKUserGoup>

export enum VKUserGroupFilter {
    admin = 'admin',
    moder = 'moder',
}

export type VKResponseItems<Data> = Readonly<{
    response: {
        count: number;
        items: Data;
    }
  }>;