// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl';

import CameraTilt from './Components/CameraTilt/View'
import Compass from './Components/Compass/View'
import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content'
import { Grid } from '@mui/material';
import LOCALIZATION from '../../../../Localization';
import { CameraSettingsInterface } from '../../../../Data/Camera/HWCameraSettings/CameraSettings';
import { isCameraSettingsOptionActive } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsActiveOptions';


type Props = Readonly<{
  settings?: CameraSettingsInterface
} & WrappedComponentProps>;

const CameraDirectionCard: React.FC<Props> = (props: Props) => {
  if (props.settings && !isCameraSettingsOptionActive(props.settings?.activeOptions?.haveGyroscope)) {
    return null;
  }

  return (
    <MainCard>
      <RegularCardHeader title={ props.intl.formatMessage({ id: LOCALIZATION.camera_direction }) } />
      <MainCardContent>
        <Grid
          container
          spacing={ 2 }
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          sx={ {
            marginBottom: 0,
            minHeight: 320
          } }
        >
          <Grid item>
            <Compass />
          </Grid>
          <Grid item>
            <CameraTilt />
          </Grid>
        </Grid>
      </MainCardContent>
    </MainCard>
  );
}


export default injectIntl(CameraDirectionCard);
