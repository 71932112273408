// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { StorageService } from '../../../../../../Data/Common/StorageService'
import { TiltSource } from './Types'

interface CameraTiltStorageServiceInterface {
    tiltSource: TiltSource
    reset(): void
}

export default class CameraTiltStorageService extends StorageService<TiltSource> implements CameraTiltStorageServiceInterface {
    key: string = 'CameraTiltStorageService.tiltSource'

    get tiltSource(): TiltSource {
        return this.data ?? 'pitch'
    }

    set tiltSource(newTiltSource: TiltSource) {
        this.data = newTiltSource
    }
}
