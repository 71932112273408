// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';


import RegularCardHeader from '../../../Components/_Layout/CardHeader/Regular';
import { CardContent, Grid } from '@mui/material';
import Card from '../../../Components/_Layout/Card';

type Props = Readonly<{
  title: string | JSX.Element;
  contentHeight?: string;
  children: any;
}>;


const BoxCol2: React.FC<Props> = (props: Props) => (
  <Grid
    item
    xs={ 12 }
    lg={ 6 }
  >
    <Card>
      {
        (typeof props.title === 'string') ?
          <RegularCardHeader
            title={ props.title }
          />
          :
          <Grid
            sx={ {
              marginLeft: '20px',
            } }
          >
            { props.title }
          </Grid>
      }
      <CardContent>
        <div
          style={ { height: props.contentHeight } }
        >
          { props.children }
        </div>
      </CardContent>
    </Card>
  </Grid>
);

export default BoxCol2;
