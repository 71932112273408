// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { checkArraysEquality, metricMultiplier } from '../../../../Tools/Tools'
import { PlatformConfiguration } from '../../../../Configuration/PlatformConfiguration'
import { MetricsDataProviderBase } from '../DataProvider'
import { compareMetricsDataItemDate } from '../Tools'
import MetricsApiService from '../Networking'
import { MetricsChartSourceInterface, MetricsDataItem, MetricTypeInterface, MetricRequestParametersInterface, MetricRequestSortParametersInterface, MetricRequestRangeParametersInterface } from '../Types'
// import { stringFromUnixDate } from '../../UnixDate/Types'

export class MetricsChartDataProvider extends MetricsDataProviderBase {
    private sourceItems: MetricsDataItem[] = []
    private fetchIndex: number = 0
    private someFetchesFailed: boolean = false
    private hasHoles: boolean = false

    constructor(private source: MetricsChartSourceInterface) {
        super()
        // console.log(`MetricsChartDataProvider > created with uniqueKey: ${source.uniqueKey}`)
    }

    get updateInterval(): number {
        return PlatformConfiguration.chartsUpdateTimeInterval
    }

    get name(): string {
        return this.source.uniqueKey
    }

    updateSource(source: MetricsChartSourceInterface): void {
        const existingUniqueKey = this.source.uniqueKey

        if (existingUniqueKey !== source.uniqueKey) {
            // console.log(`MetricsChartDataProvider > wrong source. Existing key = ${existingUniqueKey}, new key = ${source.uniqueKey}`)
        } else {
            const currentKeys = this.source.metrics.map(metric => metric.dataKey)
            const newKeys = source.metrics.map(metric => metric.dataKey)
            const changed: boolean = !checkArraysEquality(currentKeys, newKeys) ||
            this.source.outputPeriod !== source.outputPeriod ||
            this.source.outputTime !== source.outputTime

            this.source = source
            if (changed) {
                // Из данных нужно убрать лишние величины
                // Лучше дождемся ответа от сервера, чтобы наверняка получить данные, которые удовлетворяют новой фильрации
                // this.Data = this.Data.map(item => {
                //     const newItem: MetricsDataItem = {
                //         date: item.date
                //     }
                //     source.metrics.forEach(metric => {
                //         newItem[metric.dataKey] = item[metric.dataKey]
                //     })
                //     return newItem
                // })
                // console.log(`MetricsChartDataProvider > source has been changed. Needs to update data remotely. Key = ${existingUniqueKey}`)
                this.sourceItems = []
                this.someFetchesFailed = false
                this.hasHoles = false
                this.fetchIndex = 0
                this.setNeedsUpdate()
            }
        }
    }

    private apiService = new MetricsApiService()

    private max = {
        x: -100,
        y: -100,
        z: -100
    }

    private min = {
        x: 100,
        y: 100,
        z: -100
    }

    async update() {
        const source = this.source
        const data: { [key: number]: MetricsDataItem } = { }

        if ((this.fetchIndex % 2 === 0 && this.someFetchesFailed) || (this.fetchIndex % 5 === 0 && this.hasHoles)) {
            // Если ранее были замечаны проблемы при получении данных, то заберем весь список целиком
            // Кроме того, если есть 'дырки', то дополнительно заберем весь список.
            this.sourceItems = []
            this.hasHoles = false
            this.someFetchesFailed = false
            // console.log('MetricsChartDataProvider > needs all items fetching')
        }
        this.fetchIndex += 1

        for (const metric of source.metrics) {
            const requestParameters = this.buildParameters(metric, source)

            try {
                const items = await this.apiService.get1DMetric(requestParameters)

                items.forEach(item => {
                    const result: MetricsDataItem = data[item.sampleTime] ?? {
                        date: item.sampleTime * PlatformConfiguration.metricsSample
                    }
                    result[metric.dataKey] = item.value * metricMultiplier(metric)
                    data[item.sampleTime] = result
                })
            } catch (error) {
                // console.log(`MetricsChartDataProvider > failed to fetch values: ${error}`)
                // Ошибка при получении новых данных. Сохраним ключ, чтобы при возможности запбрать все местрики для графика
                this.someFetchesFailed = true
            }
        }

        let items = Object.values(data).sort(compareMetricsDataItemDate)
        // console.log(`MetricsChartDataProvider > fetched ${items.length} items`)

        this.collectSourceItems(items)

        items = this.sourceItems.slice()
        // console.log(`MetricsChartDataProvider > will use ${items.length} items`)

        // Насколько перегружен данными массив точек.

        let expectedPointsCount = PlatformConfiguration.expectedMetricsPerGraphic
        const metricsCount = source.metrics.length

        if (metricsCount > 0) {
            expectedPointsCount = Math.trunc(expectedPointsCount / metricsCount)
            expectedPointsCount = Math.max(PlatformConfiguration.minimumMetricsPerChart, expectedPointsCount)

            // console.log(`MetricsChartDataProvider: resample > ${metricsCount} metric found. Expected points count for chat: ${expectedPointsCount}`)
        }

        // Нужно прорядить, если точек слишком много
        const overload = Math.trunc(items.length / expectedPointsCount)
        if (overload >= 2) {
            // console.log(`MetricsChartDataProvider: resample > overload is ${overload}. Length before resample: ${items.length}`)
            items = items.filter((_value, index, _array) => {
                return index % overload === 0
            })
            // console.log(`MetricsChartDataProvider: resample > Length after resample: ${items.length}`)
        }
        this.data = items

        this.hasHoles = this.calculateHasHoles(items)
        if (this.hasHoles) {
            // console.log('MetricsChartDataProvider: holes detected')
        }
    }

    private calculateHasHoles(items: MetricsDataItem[]): boolean {
        if (items.length > 0) {
            for (const metric of this.source.metrics) {
                for (const item of items) {
                    if (item[metric.dataKey] === undefined) {
                        return true
                    }
                }
            }
        }

        return false
    }

    private collectSourceItems(items: MetricsDataItem[]) {
        const count = this.sourceItems.length
        const lastDate = count > 0 ? this.sourceItems[count - 1].date : undefined
        if (lastDate) {
            // "Подгружаем" новые значения
            const newItems = items.filter(item => item.date > lastDate)
            // console.log(`MetricsChartDataProvider > already stored ${count} items. New: ${newItems.length}`)
            newItems.forEach(item => this.sourceItems.push(item))
        } else {
            // Грузим начально
            // console.log('MetricsChartDataProvider > No previous items found. Will store everything fetched.')
            this.sourceItems = items.slice()
        }

        const outputPeriodTime = this.source.outputPeriod.timeInterval // ЗА сколько. За час
        if (outputPeriodTime !== undefined) {
            // const count = this.sourceItems.length
            // console.log('MetricsChartDataProvider > will filter cached points if needed')
            let until = this.source.outputTime.timeInterval // ДО какого момента. 0 == сейчсс
            if (until !== 0) {
                until = Date.now() - until
            }
            const from = (() => {
                if (until) {
                    return until - outputPeriodTime
                } else {
                    return Date.now() - outputPeriodTime
                }
            })()
            // console.log(`MetricsChartDataProvider > from: ${from ? stringFromUnixDate(from) : 'null'}, until: ${until ? stringFromUnixDate(until) : 'null'}`)

            // Отфильтруем те точки, которые не укладываются в range
            this.sourceItems = this.sourceItems.filter(item => {
                if (until) {
                    if (from !== undefined) {
                        return item.date <= until && item.date >= from
                    } else {
                        return item.date <= until
                    }
                } else {
                    if (from) {
                        return item.date >= from
                    } else {
                        return true
                    }
                }
            })
            // console.log(`MetricsChartDataProvider > filtered ${count - this.sourceItems.length} items`)
        }
    }

    private buildParameters(metric: MetricTypeInterface, source: MetricsChartSourceInterface): MetricRequestParametersInterface {
        const identificationParameters = metric.identificationParameters

        const sortParameters: MetricRequestSortParametersInterface = {
            sort: 'whenCreated',
            direction: 'desc'
        }

        // До какого времени
        const until: number | undefined = (() => {
            const time = source.outputTime.timeInterval
            if (time === 0) {
                return undefined
            }
            return Date.now() - time
        })()

        let from: number | undefined = (() => {
            const time = source.outputPeriod.timeInterval
            return (until ?? Date.now()) - time
        })()

        const sourceItemsCount = this.sourceItems.length
        if (sourceItemsCount > 0) {
            // console.log('MetricsChartDataProvider > Getting actual run-time values. No need to fetch everything, just new')
            // console.log(`MetricsChartDataProvider > from before: ${from}`)
            from = this.sourceItems[sourceItemsCount - 1].date
            // console.log(`MetricsChartDataProvider > from after: ${from}`)
        }

        const max: number = source.outputPeriod.timeInterval / PlatformConfiguration.metricsSample

        const rangeParameters: MetricRequestRangeParametersInterface = {
            gt_whenCreated: from,
            max: max
        }

        if (until) {
            rangeParameters.le_whenCreated = until
        }

        const requestParameters: MetricRequestParametersInterface = { ...identificationParameters, ...sortParameters, ...rangeParameters }

        return requestParameters
    }
}
