// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import User from '../User';
import { UserType } from '../UserWithoutID';


const isServer = (user?: User): boolean => (user?.type === UserType.Server);


export default isServer;
