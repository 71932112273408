// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useLayoutEffect } from 'react'
import { Grid, Box } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import ErrorMessage from '../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage'
import TacticalReportCard from './TacticalReportCard'
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import AnalyticsGamesPageHeader from '../../Components/_Layout/AnalyticsGamesPageHeader';
import { useParams } from 'react-router-dom';
import useGamesPagedData from '../../Data/Analytics/AnalyticsGameArchive/hook';
import AnalyticsGameArchive from '../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import { getResponseManyDataOne } from '../../Data/_Networking/ReactQuery/ResponseMany';
import { AnalyticsStatAction, AnalyticsStatGranularity } from '../../Data/Analytics/AnalyticsTypes';
import { useAnalyticsStatScore } from '../../Data/Analytics/AnalyticsStatScore/hook';
import { useAnalyticsStatDistance } from '../../Data/Analytics/AnalyticsStatDistance/hook';
import { useAnalyticsStatSpeed } from '../../Data/Analytics/AnalyticsStatSpeed/hook';
import { useAnalyticsStatPossession } from '../../Data/Analytics/AnalyticsStatPossession/hook';
import { useAnalyticsStatPossessionHeatmap } from '../../Data/Analytics/AnalyticsStatPossessionHeatmap/hook';
import useFetchAllPagesTeamRefs from '../../Data/Analytics/AnalyticsTeamRef/hook';
import { ResultStatus } from '../../Data/_Networking/ReactQuery/template';


const AnalyticsGameReportTacticalDetails: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const [granularity/*, setGranularity*/] = React.useState<AnalyticsStatGranularity>(AnalyticsStatGranularity.Granularity0);
  
  const { AnalyticsGameReport: gameId = "" } = useParams();
  
  const { status: fetchStatus, data: gameRecordsData } = useGamesPagedData({ itemId: gameId });
  const gameResultInfo: AnalyticsGameArchive | undefined = getResponseManyDataOne(gameRecordsData?.data)

  const { data: scoreStat } = useAnalyticsStatScore({ gameId, granularity });
  const { data: distanceStat } = useAnalyticsStatDistance({ gameId, granularity });
  const { data: speedStat } = useAnalyticsStatSpeed({ gameId, granularity });
  const { data: attackSpeedStat } = useAnalyticsStatSpeed({ gameId, granularity, action: AnalyticsStatAction.Attack });
  const { data: defenseSpeedStat } = useAnalyticsStatSpeed({ gameId, granularity, action: AnalyticsStatAction.Defense });
  const { data: possessionStat } = useAnalyticsStatPossession({ gameId, granularity });
  const { data: heatmapStat } = useAnalyticsStatPossessionHeatmap({ gameId, granularity });
  const {
    getTeamRefByExternalId,
    status: teamRefStatus,
  } = useFetchAllPagesTeamRefs();
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  if (fetchStatus === 'loading' || teamRefStatus === ResultStatus.Loading) {
    return (
      <>
      <br /><br /><Spinner /><br /><br />
      </>
    )
  }

  // const granularityOptions = ANALYTICS_GRANULARITY_ARR.map((granularity) => {
  //   return ({
  //     id: granularity,
  //     name: analyticsGranularityName(granularity),
  //   });
  // });

  // const handleGranularityChange = (value: string) => {
  //   setGranularity(value as AnalyticsStatGranularity);
  // };

  return (
    <Box> 
      <Grid 
      container
      spacing={ 4 }
      >
        <Grid item xs={ 12 }>
          <AnalyticsGamesPageHeader/>
          <ErrorMessage/>
        </Grid>
        {/* <Grid item xs={ 12 }>
          <HorizGrid>
            <FieldSetSelectComponent
              input={ {
                type: FieldSetInputType.Select,
                label: props.intl.formatMessage({ id: LOCALIZATION.analytics_ganularity }),
                value: granularity || "none",
                sx: { minWidth: 200 },
                options: granularityOptions,
                onChange: handleGranularityChange,
              } }
            />
          </HorizGrid>
        </Grid> */}
        <Grid item xs={ 12 }>
          <Grid container spacing={ 3 }>
            { 
              heatmapStat?.time_intervals?.map((item, index) => {
                const scoreInfo = (scoreStat?.time_intervals && index < scoreStat.time_intervals.length) ? scoreStat?.time_intervals[index] : undefined
                const distanceInfo = (distanceStat?.time_intervals && index < distanceStat.time_intervals.length) ? distanceStat?.time_intervals[index] : undefined
                const speedInfo = (speedStat?.time_intervals && index < speedStat.time_intervals.length) ? speedStat?.time_intervals[index] : undefined
                const attackSpeedInfo = (attackSpeedStat?.time_intervals && index < attackSpeedStat.time_intervals.length) ? attackSpeedStat?.time_intervals[index] : undefined
                const defenseSpeedInfo = (defenseSpeedStat?.time_intervals && index < defenseSpeedStat.time_intervals.length) ? defenseSpeedStat?.time_intervals[index] : undefined

                const possessionInfo = (possessionStat?.time_intervals && index < possessionStat.time_intervals.length) ? possessionStat?.time_intervals[index] : undefined

                return (
                  <Grid item xs={ 12 }>
                    <TacticalReportCard 
                      gameResultInfo={gameResultInfo}
                      heatmapInfo={item}
                      scoreInfo={scoreInfo}
                      distanceInfo={distanceInfo}
                      avgSpeedInfo={speedInfo}
                      possessionInfo={possessionInfo} 
                      avgSpeedInAttackInfo={ attackSpeedInfo } 
                      avgSpeedInDefenseInfo={ defenseSpeedInfo }     
                      getTeamRefByExternalId={ getTeamRefByExternalId }              
                    />
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};


export default injectIntl(AnalyticsGameReportTacticalDetails);
