// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';


type Props = Readonly<{
  color: string;
}>;


const TeamColor: React.FC<Props> = (props: Props) => (
  <span
    style={ {
      display: 'inline-block',
      objectFit: 'contain',
      maxWidth: '100%',
      maxHeight: '100%',
      height: '28px',
      width: '28px',
      padding: '4px',
      marginTop: '20px',
      marginBottom: '20px',
      borderRadius: '28px',
      border: '2px solid #fff',
      boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.23), inset 0 0 0 1px rgba(0, 0, 0, 0.23)',
      backgroundColor: props.color,
    } }
  />
);


export default TeamColor;
