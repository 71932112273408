// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { AccordionDetails as MUIAccordionDetails } from '@mui/material';


type Props = Readonly<{
  children?: React.ReactNode;
}>;


const MenuUISectonHeader: React.FC<Props> = (props: Props) => (
  <MUIAccordionDetails
    sx={ {
      padding: 0,
      margin: 0,
    } }
  >
    { props.children }
  </MUIAccordionDetails>
);


export default MenuUISectonHeader;
