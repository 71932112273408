// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>


import React from 'react'
import ConfigurableMetricsChartView from '../../../../Components/_ChartsAndBars/ConfigurableMetricsChartView'
import { HardwarePowerCardDefaultMetricsSource } from '../../../../Data/Camera/Metrics/Sources/Collection'

export default function PowerChartView() {
    const defaultSource = HardwarePowerCardDefaultMetricsSource
    return <ConfigurableMetricsChartView defaultSource = { defaultSource }/>
}
