// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import Order from '../../../_Networking/ReactQuery/Order';
import AnalyticsGameArchive from '../AnalyticsGameArchive';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import {
  useFetch,
  flushCache,
  getCached,
} from '../fetch';
import { ResultStatus } from '../../../_Networking/ReactQuery/template';
import { ErrorMessageHelper } from '../../../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessageHelper';

type Props = Readonly<{
  parentVideoId?: string;// use to filter all games connected to same video
  itemId?: string;
  page?: number;
  limit?: number;
  order?: Order;
  orderBy?: 'date';
  filterByDate?: string;
  showShared?: boolean;
  filterByAnalyticsStatus?: string;
  filterByTeamExternalId?: string;
}>;

type Result = Readonly<{
  status: ResultStatus;
  data: ResponseMany<AnalyticsGameArchive[]> | undefined;
  handleFlushData: () => void;
}>;


const useGamesPagedData = (props: Props): Result => {
  const fetchedArchive = useFetch({
    parentVideoId: props.parentVideoId,
    itemId: props.itemId,
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    filterByDate: props.filterByDate,
    filterByAnalyticsStatus: props.filterByAnalyticsStatus,
    filterByTeamExternalId: props.filterByTeamExternalId,
    enableToFetch: true,
  });
  const data = getCached({
    parentVideoId: props.parentVideoId,
    itemId: props.itemId,
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    filterByDate: props.filterByDate,
    filterByAnalyticsStatus: props.filterByAnalyticsStatus,
    filterByTeamExternalId: props.filterByTeamExternalId,
  });

  ErrorMessageHelper.handleError(fetchedArchive?.error)

  return ({
    status: fetchedArchive.status,
    data,
    handleFlushData: flushCache,
  });
};


export default useGamesPagedData;
