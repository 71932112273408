// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { CircularProgress, Grid } from '@mui/material';

import { Colors } from '../../../Configuration/Styles/Colors';


export enum SpinnerType {
  MainPlain = 'mainPlain',
}
type Props = Readonly<{
  spinnerType?: SpinnerType;
  isHidden?: boolean;
}>;


const SX = {
  mainPlain: {
    color: Colors.accentBlue,
  },
  none: {
    display: 'none',
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
};

const Spinner: React.FC<Props> = (props: Props) => {
  let sx;
  const spinnerType = props.spinnerType ?? 'mainPlain';

  switch (spinnerType) {
    case SpinnerType.MainPlain:
      if (!props.isHidden) {
        sx = SX.root;
      } else {
        sx = SX.none;
      }

      break;
  }

  return (
    <Grid
      sx={ sx }
    >
      <CircularProgress
        sx={ SX.mainPlain }
      />
    </Grid>
  )
};


export default Spinner;
