// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useState } from 'react'
import { CoolerTemperatureControllerFormInterface, TemperatureControllerMode } from '../../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature'
import { Modify } from '../../../../../Tools/Tools'

export const useCoolerTemperatureControllerForm = (submitForm: (form?: CoolerTemperatureControllerFormInterface) => Promise<CoolerTemperatureControllerFormInterface>, initialForm: CoolerTemperatureControllerFormInterface | undefined) => {
    const [form, setForm] = useState(initialForm)


    type CoolerTemperatureControllerFormValidation = Modify<CoolerTemperatureControllerFormInterface, {
        start: boolean,
        stop: boolean,
        critical: boolean
        mode: boolean
    }>

    const allValid: CoolerTemperatureControllerFormValidation = {
        start: true,
        stop: true,
        critical: true,
        mode: true
    }

    const [validation, setValidation] = useState<CoolerTemperatureControllerFormValidation>(allValid)

    const updateForm = async (data?: CoolerTemperatureControllerFormInterface) => {
        setForm(data)
        await submitForm(data)
    }

    if (form === undefined && initialForm !== undefined) {
        setForm(initialForm)
    }

    const onChangeMode: (value: TemperatureControllerMode) => void = async (value) => {
        try {
            const data = (form !== undefined) ? { ...form, mode: value } : undefined
            await updateForm(data)
            setValidation(allValid)
        } catch {
            setValidation({ ...validation, mode: false })
        }
    }

    const onChangeStart: (value: number) => void = async (value) => {
        try {
            const data = (form !== undefined) ? { ...form, start: value } : undefined
            await updateForm(data)
            setValidation(allValid)
        } catch {
            setValidation({ ...validation, start: false })
        }
    }

    const onChangeStop: (value: number) => void = async (value) => {
        try {
            const data = (form !== undefined) ? { ...form, stop: value } : undefined
            await updateForm(data)
            setValidation(allValid)
        } catch {
            setValidation({ ...validation, stop: false })
        }
    }

    const onChangeCritical: (value: number) => void = async (value) => {
        try {
            const data = (form !== undefined) ? { ...form, critical: value } : undefined
            await updateForm(data)
            setValidation(allValid)
        } catch {
            setValidation({ ...validation, critical: false })
        }
    }

    return {
        onChangeMode,
        onChangeStart,
        onChangeStop,
        onChangeCritical,
        form,
        validation
    }
}
