// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

export enum DestType {
  MP4 = 'mp4',
  File = 'file',
  RTMP = 'rtmp',
}

export interface DestFile {
  // Format to store full-frame panorama video.
  // This can be: mp4 = mp4 file; file = h264, h265 stream file.
  type: DestType.MP4 | DestType.File;
  // File path to store full-frame panorama video.
  // Placeholder {timestamp} will be replaced with date-time like 2021-04-21_13-22-07_836781.
  // If create file in several parts then will add part number in the end of the name
  // like original_2021-04-21_13-22-07_836781_part1.mp4.
  file?: string;           // default="/mnt/ssd/panorama/panorama_{timestamp}_part.mp4"
  // is this file used for local playback in player
  streaming?: boolean; // default=false
}

export interface DestRTMP {
  // Format for storing/streaming operator video.
  // This can be: mp4 = mp4 file; file = h264, h265 stream file;rtmp =  rtmp video stream.
  type: DestType.RTMP;
  // rtmp url for streaming operator video.
  url?: string; 
}

//Define to configure output streams.
interface Dest {
  // Path to the folder to store all jpeg,png images saved by the camera.
  // Take a look at this document to find all links to dest->jpeg-dir parameter.
  'jpeg-dir'?: string;                   // default "/var/run/camera-handler"
  // Define array to store full-frame panorama video.
  original?: DestFile[];
  // Define array to stream/store operator video.
  operator?: Array<DestFile | DestRTMP>;
  // Split and rename stored video files by timer.
  // If true then stored files (mp4,h264,h265) will be split by time defined in rename-interval.
  'rename-files'?: boolean; // default=true
  // Interval in seconds for split and rename stored video files (mp4,h264,h265).
  // Define 0 to disable option.
  'rename-interval'?: number;            // 0 .. 86400 default=600
}


export default Dest;
