// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import Event from '../../../Data/EPG/EpgSchedule/Event';
import ResponseMany from '../../../Data/_Networking/ReactQuery/ResponseMany';
import { getStreamingProfilesCached } from '../../../Data/NapiStreamingProfile/fetch';
import dayjs from 'dayjs';
import EventWithoutID, { GameRepeatType } from '../../../Data/EPG/EpgSchedule/EventWithoutID';
import { dayjsFrom } from '../AddEvent/helpers';

const getEventByID = (id: Event['id'], data?: ResponseMany<Event[]>): Event | undefined => (
  (data?.data || []).find((event: Event) => (event.id === id))
);

const getStreamingName = (streamProfileID: string, defaultName: string): string => {
  const streamingProfiles = getStreamingProfilesCached();
  const streamingProfile = streamingProfiles?.data.find(({ id }) => (id === streamProfileID));

  if (streamingProfile && streamingProfile.name) {
    return streamingProfile.name;
  }

  return defaultName;
};


const isEventEnded = (item?: Event | EventWithoutID) : boolean => {
  try {
    if (!item) {
      return false;
    }
    const now = dayjs()
    if (item.repeatType === GameRepeatType.NoRepeat) { // non repeat event
      const end = dayjsFrom("end", item.startDate, item.startTime, item.endDate, item.endTime)

      // end erlier then now and not recurent
      if (end?.isBefore(now)) {
        return true;
      }
    }
    else {// repeat event
      const endD = dayjsFrom("endDate", item.startDate, item.startTime, item.endDate, item.endTime)

      // repeat event with end in past
      if (endD?.isBefore(now)) {
        return true;
      }
    }
  } catch (ignore) {
  }
  return false
}

export {
  getEventByID,
  getStreamingName,
  isEventEnded,
};
