// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Container, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import AuthorisationManager from '../../Data/Auth/AuthorisationManager';
import CCLogo, { CCLogoStyle } from '../../Components/_BaseUI/CClogo/CCLogo';
import InputField, { InputFieldType } from '../../Components/_BaseUI/InputField/InputField';
import MainButton from '../../Components/_BaseUI/MainButton';
import Copyright from './Components/Copyright/View';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import ErrorMessage from '../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage';
import LanguageSelector from '../../Menu/AppBar/LanguageSelector';
import AppStorageService from '../../Data/Auth/App/Storage';
import useStyles from './useStyles';
import { useAppConfig } from '../../Localization/AppContextProvider/helpers';
import LOCALIZATION from '../../Localization';


type Props = Readonly<{
  error?: Error;
  onSignedIn: () => void;
} & WrappedComponentProps>;
type LocationStateFrom = Readonly<{
  from?: {
    pathname?: string;
  };
}>;


const SX = {
  root: {
    position: 'relative',
    minHeight: 400,
    height: '100vh',
  },
  languageSelector: {
    position: 'absolute',
    top: 16,
    right: 16
  },
  copyright: {
    flexGrow: 1,
    position: 'absolute',
    width: '100%',
    bottom: 10,
    right: 10
  },
};

const LoginPage: React.FC<Props> = (props: Props) => {
  const { localization: { locale } } = useAppConfig();
  const l = useLocation();
  const from = (l.state as LocationStateFrom)?.from?.pathname || '/';
  const navigate = useNavigate();
  const [userName, setUserName] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [error, setError] = React.useState<Error | undefined>(props.error)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [shouldReload, setShouldReload] = React.useState<boolean>(false)

  const handleUsernameChange = (text: string): void => {
    setError(undefined);
    setUserName(text);
  };
  const handlePasswordChange = (text: string): void => {
    setError(undefined);
    setPassword(text);
  };

  const handleUsernameEnterKey = (text: string): void => {
    setError(undefined);
    setUserName(text);
    document.getElementById('passwordEdit')?.focus();
  };
  const handlePasswordEnterKey = (text: string): void => {
    setError(undefined);
    setPassword(text);
    signIn();
  };

  const signIn = (): void => {
    if (userName && password) {
      setIsLoading(true)
      AuthorisationManager.shared.signIn(userName, password, user => {
        setError(undefined)
        setIsLoading(false)
        setShouldReload(false)
        props.onSignedIn()
        setTimeout(() => {
          navigate(from, { replace: true });
        }, 500);
      }, (error, apiError) => {
        setError(error)
        setIsLoading(false)
        if (apiError.statusCode === 400) {
          // console.log('Get 400 on signin. Remove stored auth info')
          new AppStorageService().reset()
          setShouldReload(true)
        } else {
          setShouldReload(false)
        }
        // console.log('User failed to sign in: ' + error)
      })
    } else {
      // console.log('Empty username or password')
    }
  };

  const prepare = (): void => {
    setIsLoading(true);
    AuthorisationManager.shared.createAppIfNeeded(locale, app => {
      setError(props.error);
      setIsLoading(false);
      setShouldReload(false);
    }, error => {
      setError(Error(props.intl.formatMessage({ id: LOCALIZATION.error_app_failed }) + '\n' + error.message))
      setIsLoading(false);
      setShouldReload(true);
    })
  };

  const reload = (): void => {
    prepare()
  };

  const signUp = () => {
    // TODO: Временная возможность сброса App. В проде такого быть не должно, когда будет норм регистрация
    new AppStorageService().reset();
    setError(Error(props.intl.formatMessage({ id: LOCALIZATION.not_implemented }) + '\nПриложение сброшено'));

    reload();
  };

  const classes = useStyles();

  React.useEffect(() => {
    prepare();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid sx={ SX.root }>
      <Grid sx={ SX.languageSelector }>
        <LanguageSelector />
      </Grid>
      <Container className={ classes.container } maxWidth="xs">
        <Grid container spacing={ 5 }>
          <Grid item xs={ 12 }>
            <CCLogo logoStyle={ CCLogoStyle.Main }/>
          </Grid>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 }>
                <InputField
                  autoFocus
                  inputType={ InputFieldType.Email }
                  onTextChanged={ handleUsernameChange }
                  onEnterPress={ handleUsernameEnterKey }
                />
              </Grid>
              <Grid item xs={ 12 }>
                <InputField
                  inputType={ InputFieldType.Password }
                  onTextChanged={ handlePasswordChange }
                  onEnterPress={ handlePasswordEnterKey }
                  id={ 'passwordEdit' }
                />
              </Grid>
              <Grid item xs={ 12 } className={ error ? classes.error : classes.none }>
                <ErrorMessage error={ error } local />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={ 12 }>
            { isLoading
              ? (<Spinner/>)
              : shouldReload
                ? (
                  <Grid container spacing={ 1 } justifyContent="center">
                    <Grid item>
                      <MainButton title={ props.intl.formatMessage({ id: LOCALIZATION.reload }) } onClicked={ signUp }/>
                    </Grid>
                  </Grid>)
                : (
                  <Grid container spacing={ 1 } justifyContent="center">
                    <Grid item>
                      <MainButton title={ props.intl.formatMessage({ id: LOCALIZATION.login }) } onClicked={ signIn }/>
                    </Grid>
                    {/* <Grid item>
                                            <MainButton title={props.intl.formatMessage({ id: LOCALIZATION.events })register} onClicked={signUp}/>
                                        </Grid> */ }
                  </Grid>
                ) }
          </Grid>
        </Grid>
      </Container>
      <Grid sx={ SX.copyright }>
        <Copyright />
      </Grid>
    </Grid>
  )
};


export default injectIntl(LoginPage);
