// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import EPGEvent from '../EPGEvent';
import Order from '../../../_Networking/ReactQuery/Order';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import {
  useFetch,
  flushCache,
  getCached,
} from '../fetch';


type Props = Readonly<{
  date?: string;
  page: number;
  limit: number;
  order: Order;
  orderBy: keyof EPGEvent;
}>;

type Result = Readonly<{
  status: string;
  data: ResponseMany<EPGEvent[]> | undefined;
  handleFlushData: () => void;
}>;


const usePagedData = (props: Props): Result => {
  const fetchedEPGEvents = useFetch({
    date: props?.date,
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    enableToFetch: true,
  });
  const data = getCached({
    date: props?.date,
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
  });

  return ({
    status: fetchedEPGEvents.status,
    data,
    handleFlushData: flushCache,
  });
};


export default usePagedData;
