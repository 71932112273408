// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useState } from 'react'
import { HeaterTemperatureControllerFormInterface, TemperatureControllerMode } from '../../../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature'
import { SensorPlace } from '../../../../../../Data/Camera/Metrics/Types'
import { Modify } from '../../../../../../Tools/Tools'

export const useHeaterTemperatureControllerForm = (submitForm: (form: HeaterTemperatureControllerFormInterface) => Promise<HeaterTemperatureControllerFormInterface>, initialForm: HeaterTemperatureControllerFormInterface) => {
    const [form, setForm] = useState(initialForm)

    type HeaterTemperatureControllerFormValidation = Modify<HeaterTemperatureControllerFormInterface, {
        start: boolean,
        stop: boolean,
        critical: boolean
        currentSource: boolean,
        mode: boolean
    }>

    const allValid: HeaterTemperatureControllerFormValidation = {
        start: true,
        stop: true,
        critical: true,
        currentSource: true,
        mode: true
    }

    const [validation, setValidation] = useState<HeaterTemperatureControllerFormValidation>(allValid)

    const updateForm = async (data: HeaterTemperatureControllerFormInterface) => {
        setForm(data)
        await submitForm(data)
    }

    const onChangeMode: (value: TemperatureControllerMode) => void = async (value) => {
        try {
            await updateForm({ ...form, mode: value })
            setValidation(allValid)
        } catch {
            setValidation({ ...validation, mode: false })
        }
    }

    const onChangeStart: (value: number) => void = async (value) => {
        try {
            await updateForm({ ...form, start: value })
            setValidation(allValid)
        } catch {
            setValidation({ ...validation, start: false })
        }
    }

    const onChangeStop: (value: number) => void = async (value) => {
        try {
            await updateForm({ ...form, stop: value })
            setValidation(allValid)
        } catch {
            setValidation({ ...validation, stop: false })
        }
    }

    const onChangeCritical: (value: number) => void = async (value) => {
        try {
            await updateForm({ ...form, critical: value })
            setValidation(allValid)
        } catch {
            setValidation({ ...validation, critical: false })
        }
    }

    const onChangeSource: (value: SensorPlace) => void = async (value) => {
        try {
            await updateForm({ ...form, currentSource: value })
            setValidation(allValid)
        } catch {
            setValidation({ ...validation, currentSource: false })
        }
    }

    return {
        onChangeMode,
        onChangeSource,
        onChangeStart,
        onChangeStop,
        onChangeCritical,
        form,
        validation
    }
}
