// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import clsx from 'clsx'
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { FormControl, MenuItem, Select } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import {
  TemperatureControllerMode,
  TemperatureControllerModeAll
} from '../../../../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature'
import { Fonts } from '../../../../../../../Configuration/Styles/Fonts'
import { Colors } from '../../../../../../../Configuration/Styles/Colors'
import { Sizes } from '../../../../../../../Configuration/Styles/Sizes';
import LOCALIZATION from '../../../../../../../Localization';


type Props = Readonly<{
  mode: TemperatureControllerMode;
  isCorrect?: boolean;
  onChanged: (mode: TemperatureControllerMode) => void;
} & WrappedComponentProps>;


const useStyles = makeStyles(() => createStyles({
  root: {
    flexGrow: 1
  },
  formControl: {
    minWidth: 90,
    width: '100%',
    minHeight: 40,
    background: Colors.mainBackground,
    fontFamily: Fonts.main
  },
  select: {
    height: 38,
    borderRadius: 6
  },
  text: {
    fontFamily: Fonts.main,
    fontSize: Sizes.subtitle
  },
  correct: {
    color: Colors.mainTitle
  },
  wrong: {
    color: Colors.mainRed
  }
}));

const TemperatureControllerModeSelector: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const isCorrect = (props.isCorrect !== undefined) ? props.isCorrect : true;
  const temperatureControllerModeName = {
    'on': props.intl.formatMessage({ id: LOCALIZATION.on }),
    'off': props.intl.formatMessage({ id: LOCALIZATION.off }),
    'auto': props.intl.formatMessage({ id: LOCALIZATION.auto }),
  }

  const currentValue = props.mode

  const handleCurrentValueChange = (mode: TemperatureControllerMode) => {
    props.onChanged(mode)
  }

  function createDropDownItems() {
    return TemperatureControllerModeAll.map(mode => {
      return (
        <MenuItem
          key={ mode }
          value={ mode }
          onClick={ () => {
            handleCurrentValueChange(mode)
          } }>
          <div
            className={ clsx(classes.text, isCorrect ? classes.correct : classes.wrong) }>{ temperatureControllerModeName[mode] }</div>
        </MenuItem>
      )
    })
  }

  return (
    <div className={ classes.root }>
      <FormControl variant="outlined" className={ classes.formControl } size="small">
        <Select
          className={ classes.select }
          displayEmpty
          value={ currentValue }
        >
          { createDropDownItems() }
        </Select>
      </FormControl>
    </div>
  );
};


export default injectIntl(TemperatureControllerModeSelector);
