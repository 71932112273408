// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import FieldSetText, { FieldSetInputText } from './FieldSetText';
import FieldSetColor, { FieldSetInputColor } from './FieldSetColor';
import FieldSetDateComponent, { FieldSetDate } from './FieldSetDateComponent';
import FieldSetTimeComponent, { FieldSetTime } from './FieldSetTimeComponent';
import FieldSetFileComponent, { FieldSetFile } from './FieldSetFileComponent';
import FieldSetNumber, { FieldSetInputNumber } from './FieldSetNumber';
import FieldSetOtherComponent, { FieldSetOther } from './FieldSetOtherComponent';
import FieldSetSelectComponent, { FieldSetSelect } from './FieldSetSelectComponent';
import FieldSetDateAndTimeComponent, { FieldSetDateAndTime } from './FieldSetDateAndTimeComponent';
import FieldSetTimeTillTimeComponent, { FieldSetTimeTillTime } from './FieldSetTimeTillTimeComponent';
import FieldSetBoolean, { FieldSetInputBoolean } from './FieldSetBoolean';
import FieldSetStaticLabel, { FieldSetInputStaticLabel } from './FieldSetStaticLabel';
import FieldSetWrapper from "./FieldSetWrapper";
import { SxProps, Theme } from '@mui/system';


export enum FieldSetInputType {
  Number = 'number',
  Float = 'float',
  Text = 'text',
  TextArea = 'textarea',
  Password = 'password',
  Color = 'color',
  Select = 'select',
  Date = 'date',
  Time = 'time',
  DateAndTime = 'dateAndTime',
  TimeTillTime = 'timeTillTime',
  Other = 'other',
  File = 'file',
  Radio = 'radio',
  Boolean = 'boolean',
  StaticLabel = 'staticLabel',
}

type ChangeRadio = (value: boolean) => void;

type FieldSetInputRadio = Readonly<{
  type: FieldSetInputType.Radio;
  value: boolean;
  onChange: ChangeRadio;
}>;

type Input = Readonly<
  FieldSetInputText | FieldSetInputNumber | FieldSetInputBoolean |
  FieldSetInputStaticLabel | FieldSetInputColor | FieldSetSelect | FieldSetInputRadio | 
  FieldSetDate | FieldSetTime | FieldSetDateAndTime | FieldSetTimeTillTime |   
  FieldSetFile | FieldSetOther
>;

type Props = Readonly<{
  label: string;
  labelSx?: SxProps<Theme>;
  tooltipQuestionText?: string;
  onErrorTooltipText?: string;// show if input.errorCheckPattern return true or if forceShowonErrorTooltipText==true
  forceShowonErrorTooltipText?: boolean;
  input?: Input;
  hideSeparator?: boolean
  disableAutocomplete?: boolean
  isError?: boolean;
}>;


const FieldSet: React.FC<Props> = (props: Props) => {
  const [isError, setIsError] = React.useState<boolean>(false);
  let control = null;

  switch (props.input?.type) {
    case FieldSetInputType.Password:
    case FieldSetInputType.Text: {
      control = (
        <FieldSetText
          input={ props.input }
          isError={ props.isError }
          onIsError={ setIsError }
          disableAutocomplete={ true }
        />
      );

      break;
    }
    case FieldSetInputType.StaticLabel: {
      control = (
        <FieldSetStaticLabel
          input={ props.input }
        />
      );
      break;
    }
    case FieldSetInputType.Boolean: {
      control = (
        <FieldSetBoolean
          input={ props.input }
        />
      );
      break;
    }
    case FieldSetInputType.Float:
    case FieldSetInputType.Number: {
      control = (
        <FieldSetNumber
          input={ props.input }
        />
      );

      break;
    }
    case FieldSetInputType.Color: {
      control = (
        <FieldSetColor
          input={ props.input }
        />
      );

      break;
    }
    case FieldSetInputType.Select: {
      control = (
        <FieldSetSelectComponent
          input={ props.input }
        />
      );

      break;
    }
    case FieldSetInputType.Date: {
      control = (
        <FieldSetDateComponent
          input={ props.input }
          isError={ isError }
          onIsError={ setIsError }
        />
      );

      break;
    }
    case FieldSetInputType.Time: {
      control = (
        <FieldSetTimeComponent
          input={ props.input }
          isError={ isError }
          onIsError={ setIsError }
        />
      );

      break;
    }
    case FieldSetInputType.DateAndTime: {
      control = (
        <FieldSetDateAndTimeComponent
          input={ props.input }
          isError={ isError }
          onIsError={ setIsError }
        />
      );

      break;
    }
    case FieldSetInputType.TimeTillTime: {
      control = (
        <FieldSetTimeTillTimeComponent
          input={ props.input }
          isError={ isError }
          onIsError={ setIsError }
        />
      );

      break;
    }
    case FieldSetInputType.Other: {
      control = (
        <FieldSetOtherComponent
          input={ props.input }
        />
      );

      break;
    }
    case FieldSetInputType.File: {
      control = (
        <FieldSetFileComponent
          input={ props.input }
        />
      );

      break;
    }
    default: control = null;
  }

  if (control) {
    return (
      <FieldSetWrapper
        label={ props.label }
        labelSx={ props.labelSx }
        tooltipQuestionText={ props.tooltipQuestionText }
        multiLine={ (props.input?.type === FieldSetInputType.Text && (props.input.rows || 1) > 1) }
        helperText={ (isError || props.forceShowonErrorTooltipText) ? props.onErrorTooltipText : undefined }
        hideSeparator = {props.hideSeparator }
      >
        { control }
      </FieldSetWrapper>
    );
  }
  else {
    return (
      <FieldSetWrapper
        label={ props.label }
        labelSx={ props.labelSx }
        tooltipQuestionText={ props.tooltipQuestionText }
        helperText={ (isError || props.forceShowonErrorTooltipText) ? props.onErrorTooltipText : undefined }
        hideSeparator = {props.hideSeparator }
      />
    );
  }
};


export default FieldSet;
