// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useState, useEffect } from 'react'
import { GpioPressActionsDataHelper } from './Helper'
import { GpioPressAction } from './Types'

export interface GpioPressActionsDataInterface {
    readonly isAvaialbleSoftShutdownPi: boolean

    readonly isAvailableSoftShutdownJetson1: boolean
    readonly isAvailableSoftShutdownJetson2: boolean

    readonly isAvailablePowerJetson1: boolean
    readonly isAvailablePowerJetson2: boolean

    readonly isAvailableFactoryResetJetson1: boolean
    readonly isAvailableFactoryResetJetson2: boolean

    readonly isAvailableResetJetson1: boolean
    readonly isAvailableResetJetson2: boolean

    readonly isAvailableFirmwareUpdateJetson1: boolean
    readonly isAvailableFirmwareUpdateJetson2: boolean

}

export function useGpioPressActionData(): GpioPressActionsDataInterface {
    const [pressActions, setPressActions] = useState<GpioPressAction[]>(GpioPressActionsDataHelper.pressActions)

    useEffect(() => {
        function handleDataChange(pressActions: GpioPressAction[]) {
            setPressActions(pressActions)
        }

        GpioPressActionsDataHelper.subscribeToGpioPressActionsChange(handleDataChange)
        return () => {
            GpioPressActionsDataHelper.unsubscribeFromGpioPressActionsChange(handleDataChange)
        }
    })

    return {
        isAvaialbleSoftShutdownPi: pressActions.includes('pipower'),

        isAvailableSoftShutdownJetson1: pressActions.includes('j1shutdown'),
        isAvailableSoftShutdownJetson2: pressActions.includes('j2shutdown'),

        isAvailablePowerJetson1: pressActions.includes('j1power'),
        isAvailablePowerJetson2: pressActions.includes('j2power'),

        isAvailableFactoryResetJetson1: pressActions.includes('j1recovery'),
        isAvailableFactoryResetJetson2: pressActions.includes('j2recovery'),

        isAvailableResetJetson1: pressActions.includes('j1reset'),
        isAvailableResetJetson2: pressActions.includes('j2reset'),

        isAvailableFirmwareUpdateJetson1: pressActions.includes('j1power') && pressActions.includes('j1recovery') && pressActions.includes('j1reset'),
        isAvailableFirmwareUpdateJetson2: pressActions.includes('j2power') && pressActions.includes('j2recovery') && pressActions.includes('j2reset')
    }
}
