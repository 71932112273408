// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { MenuElPathArr, getMenuArrayFromUrlPath } from '../../../Menu/Data/MenuStructure';
import { MenuID } from '../../../Menu/Data/MenuElement';
import { useLocation, useParams } from 'react-router-dom';
import PageHeader2Row from '../PageHeader2Row';
import useGamesPagedData from '../../../Data/Analytics/AnalyticsGameArchive/hook';
import AnalyticsGameArchive from '../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import { getResponseManyDataOne } from '../../../Data/_Networking/ReactQuery/ResponseMany';
import { MenuDropdownAction } from '../../_BaseUI/MenuDropdownButton';

type Props = Readonly<{
  onEditSecondLineClick?: () => void;

  secondLineMenuActions?: MenuDropdownAction<AnalyticsGameArchive>[];
}>;

const AnalyticsGamesPageHeader: React.FC<Props> = (props: Props) => {
  let { AnalyticsGameReport: gameId } = useParams();
  
  const { status: fetchStatus, data: gameRecordsData } = useGamesPagedData({ itemId: gameId });
  const gameRecord: AnalyticsGameArchive | undefined = getResponseManyDataOne(gameRecordsData?.data)

  const location = useLocation();
  const dymanicIdTitlesDict = (gameId && gameRecord?.title) ? {[gameId] : gameRecord?.title} : undefined;
  const pathMenuArr: MenuElPathArr = getMenuArrayFromUrlPath(location.pathname, dymanicIdTitlesDict);

  if (fetchStatus === 'loading') {
    return null;
  }
  
  return (
    <PageHeader2Row<AnalyticsGameArchive>
    id = "AnalyticsPageHeader"
    pathMenuArr={ pathMenuArr }  
    removeMenuIdsFromMenu={ [MenuID.Analytics] }
    onEditSecondLineClick={ props.onEditSecondLineClick }
    secondLineMenuItem={ gameRecord }
    secondLineMenuActions={ props.secondLineMenuActions }
    />
  )
};


export default AnalyticsGamesPageHeader;
