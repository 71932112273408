// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { PlatformConfiguration } from '../../Configuration/PlatformConfiguration'
import { abortableDelay } from '../../Tools/Tools'

export type DataChangeSubscriber<DataType> = (data: DataType) => void

export abstract class ObservableDataProvider<DataType> {
    private subscribers: DataChangeSubscriber<DataType>[] = []
    private isStarted = false
    data?: DataType
    private stopDelay = PlatformConfiguration.stopServicesDelay
    abstract get updateInterval(): number
    abstract update(): Promise<void>
    abstract get name(): string

    subscribeOnChanges(subscriber: DataChangeSubscriber<DataType>): void {
        this.subscribers.push(subscriber)

        if (!this.isStarted) {
            this.startUpdating()
        }
    }

    unsubscribeFromChanges(subscriber: DataChangeSubscriber<DataType>) {
        this.subscribers = this.subscribers.filter(s => s !== subscriber)
    }

    setNeedsUpdate() {
        this.breakWaitingForNextUpdate()
    }

    informSubscribers() {
        const data = this.data
        if (data) {
            this.subscribers.forEach(subsriber => subsriber(data))
        }
    }

    reset() {
        // console.log(`ObservableDataProvider: ${this.constructor.name} - ${this.name} > reset() called. Current subscribers: ${this.subscribers.length}`)
        this.subscribers = []
        this.breakWaitingForNextUpdate()
    }

    private async startUpdating() {
        if (this.isStarted) {
            // console.log(`ObservableDataProvider: ${this.constructor.name} - ${this.name} > already started`)
            return
        }
        // console.log(`ObservableDataProvider: ${this.constructor.name} - ${this.name} > will start updating`)

        this.isStarted = true

        while (this.isStarted) {
            // const date = Date.now()
            try {
                await this.update()
                // console.log(`ObservableDataProvider: ${this.constructor.name} - ${this.name} > update succeeded. Took ${(Date.now() - date) / 1000} s.`)
                this.informSubscribers()
            } catch (error) {
                // console.log(`ObservableDataProvider: ${this.constructor.name} - ${this.name} > update failed: ${error}. Took ${(Date.now() - date) / 1000} s.`)
            }

            await this.waitForNextUpdate()
            const count = this.subscribers.length
            // Если не осталось подписчиков, то нужно подождать еще немного, и если их по-прежнему не будет, то завершаем цикл до появления нового подписчика
            if (count === 0) {
                this.isStarted = false
            } else {
                // console.log(`ObservableDataProvider: ${this.constructor.name} - ${this.name} > current subscribers count: ${count}`)
            }
        }

        // console.log(`ObservableDataProvider: ${this.constructor.name} - ${this.name} > finished updating`)
    }

    private updatesAbortController = new AbortController()

    private breakWaitingForNextUpdate() {
        this.updatesAbortController.abort()
    }

    private async waitForNextUpdate() {
        try {
            this.updatesAbortController = new AbortController()
            await abortableDelay(this.updateInterval, this.updatesAbortController.signal)
        } catch (error) {
            // console.log(`ObservableDataProvider: ${this.constructor.name} - ${this.name} > waiting for next update has been canceled`)
        }
    }
}
