// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import MainCardHeader from '../Main';
import { Colors } from '../../../../Configuration/Styles/Colors';
import { MainCardHeaderActionInterface } from '../Types';


type Props = Readonly<{
  title: string;
  subtitle?: string;
  rightActions?: MainCardHeaderActionInterface[];
}>;


const RegularCardHeader: React.FC<Props> = (props: Props) => {
  return (
    <MainCardHeader model={{
      name: [props.title, props.subtitle].filter(text => !!text).join(', '),
      rightActions: props.rightActions,
      barColor: Colors.white,
      statuses: [],
     }}  />
  );
};


export default RegularCardHeader;
