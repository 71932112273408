// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Card from '../../Components/_Layout/Card';
import TeamsList from './TeamsList';
import EventsTabList from './EventsList';
import PageContainer from '../../Components/_Layout/PageContainer';
import EventsHistoryList from './HistoryList';
import Tabs, { TabItemInterface } from '../../Components/_Layout/Tabs';
import LOCALIZATION from '../../Localization';


type Props = Readonly<{} & WrappedComponentProps>;


const EventsPage: React.FC<Props> = (props: Props) => {
  const TABS: TabItemInterface[] = [
    {
      id: 0,
      title: props.intl.formatMessage({ id: LOCALIZATION.events }),
      Content: EventsTabList,
    },
    {
      id: 1,
      title: props.intl.formatMessage({ id: LOCALIZATION.history }),
      Content: EventsHistoryList,
    },
    {
      id: 2,
      title: props.intl.formatMessage({ id: LOCALIZATION.teams }),
      Content: TeamsList,
    },
  ];
  const [selectedTabID, setSelectedTabID] = React.useState<number>(TABS[0].id);

  const renderContent = () => (
    TABS.map((tab: TabItemInterface) => {
      const hidden = (selectedTabID !== tab.id);

      if (hidden) { return null; }

      const Content = TABS[tab.id].Content;

      return (
        <Typography
          key={ tab.id }
          component="div"
          role="tabpanel"
          // hidden={ hidden }
          id={ `simple-tab-${tab.id}` }
          aria-labelledby={ `simple-tabpanel-${tab.id}` }
        >
          <Content />
        </Typography>
      );
    })
  );

  return (
    <PageContainer>
      <div
        style={ { margin: '0 20px' } }
      >
        <Tabs
          items={ TABS }
          selectedTabID={ selectedTabID }
          onChangeTab={ setSelectedTabID }
        />
      </div>
      <Grid
        item
        xs={ 12 }
      >
        <Card>
          { renderContent() }
        </Card>
      </Grid>
    </PageContainer>
  );
};


export default injectIntl(EventsPage);
