// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Card from '../../../../Components/_Layout/Card';
import Popup from '../../../../Components/_Layout/Popup';
import Team from '../../../../Data/EPG/Team/Team';
import Spinner from '../../../../Components/_BaseUI/Spinner/Spinner';
import TeamWithoutID from '../../../../Data/EPG/Team/TeamWithoutID';
import FieldSet, { FieldSetInputType } from '../../../../Components/_BaseUI/FieldSet';
import getTemplateURLToImage from '../../../../Tools/getTemplateURLToImage';
import LOCALIZATION from '../../../../Localization';
import { useCameraSettings } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks';


type Props = Readonly<{
  team?: Team | TeamWithoutID;
  // page: number;
  onSubmit: (team: Team | TeamWithoutID) => void;
  onClose: () => void;
} & WrappedComponentProps>;


const DEFAULT_VALUE = (): TeamWithoutID => {
  return {
    name: '',
    shortName: '',
    color: `#${ Math.floor(Math.random()*16777215).toString(16) }`, // random color for new team
    logo: null,
  }
};

const TeamsListPopup: React.FC<Props> = (props: Props) => {    
  const cameraSettings = useCameraSettings(true);
  const [team, setTeam] = React.useState<Team | TeamWithoutID>(props.team || DEFAULT_VALUE());
  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);
  const isApplyDisabled = !(team && team.name.length && team.shortName.length && team.color.length);
  const handleSubmit = () => {
    if (team && team.name.length && team.shortName.length && team.color.length) {
      setIsLoaderShowing(true);
      props.onSubmit(team);
    }
  };
  const handleChangeName = (name: string) => {
    setTeam({
      ...team,
      name,
    });
  };
  const handleChangeShortName = (shortName: string) => {
    setTeam({
      ...team,
      shortName,
    });
  };
  const handleChangeColor = (color: string) => {
    setTeam({
      ...team,
      color,
    });
  };
  const handleSetLogoBase64 = (logoInBase64?: string) => {
    setTeam({
      ...team,
      logo: (logoInBase64 || null),
    });
  };

  return (
    <Popup
      isWide
      headerTitle={ props.intl.formatMessage({ id: LOCALIZATION.team }) }
      isApplyDisabled={ (isApplyDisabled || isLoaderShowing) }
      isCloseDisabled={ isLoaderShowing }
      onApply={ !isLoaderShowing ? handleSubmit : undefined }
      onClose={ props.onClose }
    >
      <Grid
        item
        xs={ 12 }
      >
        <Card>
          {
            (isLoaderShowing) ?
            <>
            <br /><br /><Spinner /><br /><br />
            </>
              :
              <form
                action={ window.location.href }
                encType={ 'multipart/form-Data' }
                onSubmit={ handleSubmit }
              >
                <FieldSet
                  key={ 'Name' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.team_name }) }
                  input={ {
                    type: FieldSetInputType.Text,
                    value: team.name,
                    onChange: handleChangeName,
                  } }
                />
                <FieldSet
                  key={ 'Short Name' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.team_short_name }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.tooltip_team_short_name })}
                  input={ {
                    type: FieldSetInputType.Text,
                    value: team.shortName,
                    onChange: handleChangeShortName,
                  } }
                />
                <FieldSet
                  key={ 'Team Color' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.team_color }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.tooltip_team_color_use })}
                  input={ {
                    type: FieldSetInputType.Color,
                    value: team.color,
                    onChange: handleChangeColor,
                  } }
                />
                <FieldSet
                  key={ 'Team Logo' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.team_logo }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.alert_only_png_images })}
                  input={ {
                    type: FieldSetInputType.File,
                    value: (
                      getTemplateURLToImage(cameraSettings?.urls?.eventsImageTemplate, "events_image_path", team.logo)
                    ),
                    buttonText: props.intl.formatMessage({ id: LOCALIZATION.team_upload_new_logo }),
                    onChange: handleSetLogoBase64,
                  } }
                />
              </form>
          }
        </Card>
      </Grid>
    </Popup>
  );
};


export default injectIntl(TeamsListPopup);
