// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { styled } from '@mui/material/styles';


const PREFIX = 'Logo';
const classes = {
  main: `${ PREFIX }-main`,
  toolbar: `${ PREFIX }-toolbar`,
};
const LogoStyled = styled('div')(({ theme }) => ({
  [`& .${ classes.main }`]: {
    width: '90%',
    paddingLeft: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 440,
    display: 'block',
  },
  [`& .${ classes.toolbar }`]: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw - 200px)',
    }
  }
}));


export {
  classes,
};

export default LogoStyled;
