// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
// import CloseIcon from '@mui/icons-material/Close';
import CloseIcon from '@mui/icons-material/Cancel';

import { PlayButton } from './Styled';
import { FileType } from '../../Data/VideoArchive';
import ButtonPlay from './button_stream_play.svg';
import OperatorPlaceholder from './operator.png';
import PanoramaPlaceholder from './panorama.png';
// import ButtonFullScreen from './button_stream_fullscreen.svg';
import { MAIN_BACKEND_HOST_URL } from '../../constants';
import { Box, SxProps, Theme } from '@mui/material';
import { Colors } from '../../Configuration/Styles/Colors';
import { Sizes } from '../../Configuration/Styles/Sizes';
import { Fonts } from '../../Configuration/Styles/Fonts';
import VideoPlayer, { VjsPlayer } from '../VideoPlayer';
import { timeToSeconds } from '../_BaseUI/AnalyticsTimeField';
import { isString } from 'lodash';
// import { Colors } from '../../Styles/Colors';

export type VideoPlayerCommands = Readonly<{
  getVjsPlayer: () => VjsPlayer | undefined
  play: (startPositionSec?: number | string) => void
  pause: () => void
  stop: () => void
  currentTimeSec: (seekToTimeSec?: number | string)=> number | undefined
  isPlayerActive: () => boolean
}>;

export type { VjsPlayer };

type Props = Readonly<{
  title?: string;
  videoUrl?: string;
  previewImageUrl?: string;
  fileType: FileType;
  bigPreview?: boolean;
  preventControlsHide?: boolean;
  noUseAspectRatio?: boolean;
  sx?: SxProps<Theme>;
  initialStartPositionSec?: number | string
  onPlay?: () => void;
  onFullScreen?: () => void;
  onPlayerCommandsUpdate?: (commands: VideoPlayerCommands | undefined) => void
  onPlaybackTimeChangeRoundedToSec?: (player: VjsPlayer, playbackPosSec: number | undefined) => void;
}>;


const CameraStreamPreview: React.FC<Props> = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);
  const [showPlayer, setShowPlayer] = React.useState<boolean>(false);
  // const [isFullScreenMode, setIsFullScreenMode] = React.useState<boolean>(false);
  const playerRef = React.useRef<VjsPlayer | undefined>(undefined);
  const playerInitStartPositionSec = React.useRef<number | string | undefined>(undefined);

  const defaultImageSRC = (props.fileType === FileType.Operator) ? OperatorPlaceholder : PanoramaPlaceholder;
  const previewSize = (props.bigPreview) ? "w=1280&h=720" : "w=640&h=480"
  const imagePreview = (props.previewImageUrl) ? `${ MAIN_BACKEND_HOST_URL }${ props.previewImageUrl }?${previewSize}` : defaultImageSRC;
  const defaultPreferedAspectRatio = (props.fileType === FileType.Operator) ? '16/9' : '16/5';

  const playerCommands: VideoPlayerCommands = {
    getVjsPlayer() {
      return playerRef.current
    },
    play(startPositionSec?: number | string) {
      // test if input time is in "1:55" format convert to seconds
      if (startPositionSec && isString(startPositionSec) && startPositionSec.length > 1 && startPositionSec.indexOf(":") >= 0) {
        startPositionSec = timeToSeconds(startPositionSec as string)
      }

      if (!playerRef.current) {
        playerInitStartPositionSec.current = startPositionSec;
        setShowPlayer(true);
      }
      else {
        if (startPositionSec !== undefined) {
          playerRef.current?.currentTime(startPositionSec);
        }
        playerRef.current?.play();
      }
    },
    pause() {
      playerRef.current?.pause()
    },
    stop() {
      handleClosePlayer();
    },
    currentTimeSec(seekToTimeSec?: number | string) {
      if (seekToTimeSec === undefined) {
        return playerRef.current?.currentTime();
      }
      return playerRef.current?.currentTime(seekToTimeSec);
    },
    isPlayerActive() {
      return (!!playerRef.current) && (!playerRef.current.isDisposed())
    },
  }
  React.useEffect(() => {
    props.onPlayerCommandsUpdate?.(playerCommands)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlay = () => {
    playerCommands.play(props.initialStartPositionSec)
  };

  const handleClosePlayer = () => {
    setShowPlayer(false);
    playerRef.current = undefined
    props.onPlayerCommandsUpdate?.(playerCommands)
  };

  React.useEffect(() => {
    if (imgRef.current) {
      imgRef.current.onerror = () => {
        if (imgRef.current) {
          imgRef.current.src = (props.fileType === FileType.Operator) ? OperatorPlaceholder : PanoramaPlaceholder;
        }
      };
    }
    // eslint-disable-next-line
  }, [imgRef, props.fileType]);

  const renderClosePlayer = () => {
    if (!showPlayer) { return null; }

    return (
      <div
        style={ {
          position: 'absolute',
          zIndex: 10,
          top: '11px',
          left: '10px',
          width: '32px',
          height: '32px',
          cursor: 'pointer',
          color: '#fff',
          filter: 'drop-shadow( 0px 0px 3px rgba(0, 0, 0, .7))',
          // backgroundColor: '#fff',
        } }
        onClick={ handleClosePlayer }
      >
        <CloseIcon sx = {{ width: '32px', height: '32px' }} />
      </div>
    );
  };

  const renderPlayerOrImage = () => {
    return (
      <>
        {(showPlayer) ? 
          <VideoPlayer 
            videoUrl={`${ MAIN_BACKEND_HOST_URL }${ props.videoUrl }`}
            // aspectRatio={ props.noUseAspectRatio ? undefined : defaultPreferedAspectRatio }
            posterUrl={ imagePreview }
            preventControlsHide={ props.preventControlsHide }
            onPlayerReady={(player: VjsPlayer) => {
              playerRef.current = player
              props.onPlayerCommandsUpdate?.(playerCommands)

              if (playerInitStartPositionSec.current) {
                const startPositionSec = playerInitStartPositionSec.current;
                playerInitStartPositionSec.current = undefined;
                playerRef.current?.currentTime(startPositionSec);
                playerRef.current?.play()
              }
            }}
            onPlaybackTimeChangeRoundedToSec={ props.onPlaybackTimeChangeRoundedToSec }
          />
          :
          <Box
            component="img"
            sx={{
              // aspectRatio: props.noUseAspectRatio ? undefined : defaultPreferedAspectRatio,
              visibility: (showPlayer) ? 'hidden' : 'visible',
              minWidth: '100%',
              width: '100%',
              maxWidth: '100%',
              overflow: 'hidden',
              height: "100%",
              backgroundColor: Colors.backgroundGrey,
              objectFit: "cover",
            }}
            ref={ imgRef }
            src={ imagePreview }
            alt={ props.title }
          />
        }
        { renderClosePlayer() }
        {(props.videoUrl && !showPlayer) &&
          <PlayButton
            style={ { backgroundImage: `url("${ ButtonPlay }")` } }
            onClick={ handlePlay }
          />
        }
      </>
    )
  }

  if (props.noUseAspectRatio) {
    return (
      <Box
      sx={ {
        position: 'relative',
        width: '100%',
        height: '100%',
        ...props.sx,
      } }
      >
        {(props.title) &&
          <Box
            sx={ {
              paddingBottom: '13px',
              fontSize: Sizes.heading,
              fontFamily: Fonts.main,
              fontWeight: Sizes.mediumWeight,
            } }
          >
            { props.title }
          </Box>
        }
        <Box
          sx={ {
            position: "absolute",
            // display: 'block',
            // width: 'auto',
            // height: 'auto',
            backgroundColor: Colors.backgroundGrey,
            overflow: 'hidden',
            borderRadius: '10px',
            border: 'solid 1px #0000004C',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // filter: 'drop-shadow( 0px 0px 3px rgba(0, 0, 0, .7))',
          } }
          ref={ ref }
        >
          { renderPlayerOrImage() }
        </Box>
      </Box>
    )
  }

  return (
    <Box
    sx={ {
      position: 'relative',
      display: 'inline-block',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      ...props.sx,
    } }
    >
      {(props.title) &&
        <Box
          sx={ {
            paddingBottom: '13px',
            fontSize: Sizes.heading,
            fontFamily: Fonts.main,
            fontWeight: Sizes.mediumWeight,
          } }
        >
          { props.title }
        </Box>
      }
      <Box
        sx={ {
          position: 'relative',
          aspectRatio: defaultPreferedAspectRatio,
          // display: 'block',
          // width: 'auto',
          // height: 'auto',
          backgroundColor: Colors.backgroundGrey,
          overflow: 'hidden',
          borderRadius: '10px',
          border: 'solid 1px #0000004C',
          // filter: 'drop-shadow( 0px 0px 3px rgba(0, 0, 0, .7))',
        } }
        ref={ ref }
      >
        { renderPlayerOrImage() }
      </Box>
    </Box>
  );
}


export default CameraStreamPreview;
