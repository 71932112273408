// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { MAIN_BACKEND_HOST_URL } from '../constants';

const getTemplateURLToImage = (templateUrl?: string, templateName?: string,  imagePath?: string | null): string => {
  if (templateUrl && templateName && imagePath) {
    const url = MAIN_BACKEND_HOST_URL + templateUrl.replace(`{${templateName}}`, imagePath)
    return url
  }
  return ""
};


export default getTemplateURLToImage;
