// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { ListItem, ListItemText, ListItemButton, ListItemIcon, Checkbox } from '@mui/material';

import Camera from '../../../../Data/Camera/ActiveCamera/Camera';
import { getCameraName } from '../../../CamerasPage/CameraCardBlock/helper';
import AuthorisationManager from '../../../../Data/Auth/AuthorisationManager';
import isSuperAdmin from '../../../../Data/AccountUsers/tools/isSuperAdmin';


type Props = Readonly<{
  isChecked: boolean;
  camera: Camera;
  onClick: (id: string) => void;
}>;


const CameraItem: React.FC<Props> = (props: Props) => {
  const user = AuthorisationManager.shared.user
  
  const labelId = `checkbox-list-label-${ props.camera.cameraId }`;

  const handleClick = () => {
    props.onClick(props.camera.cameraId);
  };

  return (
    <ListItem
      key={ props.camera.id }
      component="div"
      disablePadding
    >
      <ListItemButton
        onClick={ handleClick }
      >
        <ListItemIcon>
          <Checkbox
            disableRipple
            edge="start"
            checked={ props.isChecked }
            tabIndex={ -1 }
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText
          primary={ getCameraName (props.camera, isSuperAdmin(user) , true) }
        />
      </ListItemButton>
    </ListItem>
  );
};


export default CameraItem;
