// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { TemperatureControllerLastDataInterface } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature'
import { SensorPlace } from '../../../../Data/Camera/Metrics/Types'
import HeaterTemperatureControllerSourceSelector from '../../Cards/CaseHeater/Components/TemperatureController/SourceSelector'
import InputAnyForm from '../../../../Components/_Layout/InputAnyForm/View'

export default function InputHeaterTemperatureControllerSource(props: {
    sources: SensorPlace[],
    lastData: TemperatureControllerLastDataInterface,
    currentSource?: SensorPlace,
    isCorrect?: boolean,
    name: string,
    onSourceChanged: (source: SensorPlace) => void}) {
    return (
        <InputAnyForm name={props.name} form={(
            <HeaterTemperatureControllerSourceSelector
                sources = { props.sources }
                lastData = { props.lastData }
                currentSource = {props.currentSource }
                isCorrect = { props.isCorrect }
                onChanged = { props.onSourceChanged }/>
        )}/>
    )
}
