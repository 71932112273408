// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';

import Header from '../Header';


type Props = Readonly<{
  header?: string;
  error?: Error;
  children: any;
}>;


const PageContainer: React.FC<Props> = (props: Props) => (
  <Grid
    sx={ { flexGrow: 1 } }
  >
    <Grid
      container
      spacing={ 2 }
    >
      {
        (props.header || props.error) &&
          <Header
              text={ props.header }
              error={ props.error }
          />
      }
      { props.children }
    </Grid>
  </Grid>
);


export default PageContainer;
