// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import moment from "moment"
import LOCALIZATION from "../../../Localization"
import { intl } from "../../../Localization/LocalizationProvider"
import { formatBitrate } from "../../../Tools/b2Mb"
import Camera from "../../NapiCameraConfig/Camera"
import EPGEvent from "../../EPG/EPGEvents/EPGEvent"
import { AppLocale } from '../../../Localization/AppContextProvider/helpers';

export enum CameraSystemStateValue {
    Unknown = 'unknown',
    Ok = 'ok',
    Critial = 'critical',
    Warning = 'warning',
}

export function CameraSystemStateValueToNumber(val?: CameraSystemStateValue) : number {
switch (val) {
    case CameraSystemStateValue.Unknown:
    return 1
    case CameraSystemStateValue.Ok:
    return 2
    case CameraSystemStateValue.Warning:
    return 3
    case CameraSystemStateValue.Critial:
    return 4        
}
return 0
}

export type StreamingStateResolution = Readonly<{
    width: number;
    height: number;
  }>;
  
export type CameraSettingsStreamingTragetType = 'unknown' | 'rtmpStream' | 'mp4File' | 'mp4FileForLocalStream' | 'h264RawFile'
export type CameraSettingsStreamingOutputState = 'writing' | 'unknown' | 'connecting' | 'stopped' | 'reconnecting' | 'disconnected' | 'connected' | 'prepared' | 'failed'

export type CameraSettingsStreamingProblemReason = 'rtmp:connecting' | 'rtmp:reconnecting' | 'rtmp:disconnected' | 'rtmp:failed' | 'mp4:connecting' | 'mp4:reconnecting' | 'mp4:disconnected' | 'mp4:failed'

export type CameraSettingsStreamingSourceType = 'operator' | 'panorama'

export enum StreamType {
  LocalFile = 'Local File',
  CBS = 'ЦБС',
  Youtube = 'Youtube',
  RUTUBE = 'RUTUBE',
  VKVideo = 'VK Video',
  Twitch = 'twitch',
  Facebook = 'Facebook',
  Other = 'Other',
}

export const isStreamingOutputStateOk = (state?: CameraSettingsStreamingOutputState) => {
  switch (state) {
    case 'connected':
    case 'connecting':
    case 'prepared':
    case 'writing':
      return true;
  }
  return false;
};
  
export type CameraSettingsStreamingStateOutput = Readonly<{
    // Timestamp in milliseconds from 1970 when last time drop data on save.
    lastDataDropTimestamp?: number;
    // If now output network speed is low and cant send data then set to true.
    lowNetworkSpeedError?: boolean;
    // Output bitrate in bit/sec
    outputBitrate?: number;
    // unknown - when cant get data from Camera_handler
    // when output is rtmp stream: connecting,stopped,reconnecting,disconnected,connected
    // when output is mp4 file: prepared,stopped,failed,writing
    outputState?: CameraSettingsStreamingOutputState;
    // unknown - when cant get data from Camera_handler
    // ok - output work
    // warning,critical - when something when wrong
    state?: CameraSystemStateValue;
    // warning or ctritical state reason.
    problemReason?: CameraSettingsStreamingProblemReason;
    // Format of output data:
    // unknown - when cant get data from Camera_handler
    // rtmpStream - stream via RTMP
    // mp4File - store regular MP4 file
    // mp4FileForLocalStream - sore MP$ special for local camera streaming. Internal stream.
    // h264RawFile - file in RAW h264. Used for internal tests only.
    targetType?: CameraSettingsStreamingTragetType;
    // name of stream. like: RUTUBE, VK Video, Youtube, etc
    streamType?: StreamType;
    // optional url for stream. not used for security
    targetUrl?: string;
    // runtime value operator, panorama
    sourceType?: CameraSettingsStreamingSourceType;
}>;
  
export type CameraSettingsStreamingStateValue = 'unknown' | 'configApplying' | 'running' | 'error' | 'stopped' | 'runningWithOutputWarnings' | 'runningWithOutputCritical'

export type CameraSettingsStreamingState = Readonly<{
    // active profile id defined in camera_handler config custom
    // if not profile used 'No-Streaming'
    activeProfileId?: string;
    // active profile name defined in camera_handler config custom
    activeProfileName?: string;
    // Camera_handler main config section.
    mainConfig?: Camera;
    // Average video fps for last app start.
    averageFps?: number;
    // unknown - when cant get data from Camera_handler
    // configApplying - app process new config
    // running - app active and work
    // error - some error. check errorMessage
    // stopped - app is stopped and did not stream
    // runningWithOutputWarnings - app active and some output in warning state
    // runningWithOutputCritical - app active and some output in critical state
    state?: CameraSettingsStreamingStateValue
    jpegForAiOperatorResolution?: StreamingStateResolution;
    operatorVideoResolution?: StreamingStateResolution;
    panoramaSrcFullResolution?: StreamingStateResolution;
    panoramaVideoResolution?: StreamingStateResolution;
    srcResolutionForAiOperatorJpeg?: StreamingStateResolution;
    operatorOutputs?: CameraSettingsStreamingStateOutput[];
    panoramaOutputs?: CameraSettingsStreamingStateOutput[];
}>;
  
export function steamingStatesInfo(checkOutputs: CameraSettingsStreamingStateOutput[] | CameraSettingsStreamingState | undefined, targetType: CameraSettingsStreamingTragetType, outWithSpeed: boolean, hideStreamTypeFromName?: boolean): [stateString: string, state:CameraSystemStateValue] {
  let stateStringArr: string[] = [] // string value of all outputs. like "OK, Warning"
  let state = CameraSystemStateValue.Unknown //worst state of all outputs

  try {
    let outputs: CameraSettingsStreamingStateOutput[] | undefined = []
    if ((checkOutputs) && (('operatorOutputs' in checkOutputs) || ('panoramaOutputs' in checkOutputs))) {
      const streamingState = checkOutputs as CameraSettingsStreamingState

      streamingState.operatorOutputs?.forEach((state) => {
        outputs?.push({
          ...state,
          sourceType: "operator"
        })
      })
      streamingState.panoramaOutputs?.forEach((state) => {
        outputs?.push({
          ...state,
          sourceType: "panorama"
        })
      })
    }
    else if (Array.isArray(checkOutputs)) {
      outputs = checkOutputs as CameraSettingsStreamingStateOutput[]
    }

    const states = outputs?.filter(output => (output?.targetType === targetType))
    
    states?.forEach(stream => {
      if (stream.state) {
        let stateName = ""
        if (!hideStreamTypeFromName) {
          if (targetType === 'rtmpStream' && stream.streamType) { // show target name for rtmp
            stateName = `${ stream.streamType } - `;
          }
          else if (targetType === 'mp4File' && stream.sourceType) {
            let sourceType: string = stream.sourceType;
            if (stream.sourceType === "operator") {
              sourceType = intl().formatMessage({ id: LOCALIZATION.source_operator })
            }
            else if (stream.sourceType === "panorama") {
              sourceType = intl().formatMessage({ id: LOCALIZATION.source_panorama })
            }

            stateName = `${ sourceType } - `;
          }
        }

        if (stream.state) {
          if (stream.problemReason && (stream.state === CameraSystemStateValue.Warning || stream.state === CameraSystemStateValue.Critial)) {
            stateName += intl().formatMessage({ id: stream.problemReason }) ?? stream.problemReason
          }
          else {
            stateName += intl().formatMessage({ id: stream.state }) ?? intl().formatMessage({ id: LOCALIZATION.off })
          }
        }
        else {
          stateName += intl().formatMessage({ id: LOCALIZATION.off })
        }
        if (outWithSpeed && stream.outputBitrate) {
          stateName += ` (${ formatBitrate(stream.outputBitrate) })`
        }
        stateStringArr.push(stateName)
  
        //worst state of all outputs
        if (CameraSystemStateValueToNumber(state) < CameraSystemStateValueToNumber(stream.state)) {
          state = stream.state
        }
      }
    });
  }
  catch (error) {
    console.log(`steamingStatesInfo error: ${error}`)
  }

  let stateString = (stateStringArr.length !== 0) ? stateStringArr.join(', ') : intl().formatMessage({ id: LOCALIZATION.off })

  return [stateString, state]
}
  
export function totalSaveToSsdSpeedMBperSec(streamingState: CameraSettingsStreamingState | undefined): number {
  try {
    if (! ((streamingState) && ('operatorOutputs' in streamingState) && ('panoramaOutputs' in streamingState))) {
      return 0
    }
    const outputs = (streamingState.operatorOutputs || []).concat(streamingState.panoramaOutputs || [])?.filter(output => (output?.targetType === 'mp4File'))
    if (!outputs || outputs.length === 0) {
      return 0
    }
    
    let totalBitrate = 0
    outputs?.forEach(stream => {
      if (stream.outputBitrate && (stream.state === CameraSystemStateValue.Ok || stream.state === CameraSystemStateValue.Warning)) {
        totalBitrate += stream.outputBitrate
      }
    });
  
    return totalBitrate / 8388608.0 // bit to Mega Bytes
  }
  catch (error) {
    console.log(`steamingStatesInfo error: ${error}`)
  }
  return -1
}


export function activeEpgEventStatesInfo(activeEpgEvent: EPGEvent | null | undefined, locale?: AppLocale): [stateString: string, state:CameraSystemStateValue] {
  var defaultStateString = "";
  const defaultState = CameraSystemStateValue.Unknown; //worst state of all outputs

  try {
    defaultStateString = intl().formatMessage({ id: LOCALIZATION.no_active_epg_event }); // string value of all outputs. like "OK, Warning"
    if (!activeEpgEvent || (activeEpgEvent?.name === undefined && activeEpgEvent?.startAt === undefined)) {
      return [defaultStateString, defaultState];
    }

    // process event
    const startAt = (activeEpgEvent.startAt) ? moment(activeEpgEvent.startAt) : undefined;
    const endAt = (activeEpgEvent.endAt) ? moment(activeEpgEvent.endAt) : undefined;
    var timeStr = "";
    if (startAt && endAt) {
      if (locale) {
        startAt.locale(locale);
        endAt.locale(locale);
      }
      timeStr = `[${startAt.format("LT")} - ${endAt.format("LT")}] `;
    }
    const stateString = `${timeStr}"${activeEpgEvent.name}"`;
    const state = CameraSystemStateValue.Ok;
  
    return [stateString, state]
  }
  catch (error) {
    console.log(`activeEpgEventStatesInfo error: ${error}`)
  }
  return [defaultStateString, defaultState]
}
