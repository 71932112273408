// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import BaseCommandExecutor from '../../../Data/_Networking/AxiosFetch/Executor'
import { PiRestApi } from '../../../Data/_Networking/AxiosFetch/RestApi'
import { NetworkRequestInterface, ApiRequestConfigInterface } from '../Types'
import { generateUserError } from '../../../Data/_Networking/AxiosFetch/Errors/Tools'
import ApiError from '../../../Data/_Networking/AxiosFetch/Errors/Types'
import { intl } from '../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../Localization';

interface CameraApiRequestsApiServiceInterface {
    fetchApiRequests(succeeded: (configs: ApiRequestConfigInterface[]) => void, failed: (error: ApiError) => void): void
    getRequest(name: string, succeeded: (value: any) => void, failed: (error: ApiError) => void): void
    fetchNetworkRequest(id: string, succeeded: (networkRequest: NetworkRequestInterface) => void, failed: (error: Error) => void): void
}

export default class CameraApiRequestsApiService implements CameraApiRequestsApiServiceInterface {
    fetchApiRequests(succeeded: (configs: ApiRequestConfigInterface[]) => void, failed: (error: ApiError) => void): void {
        const command = PiRestApi.fetchApiRequestsCommand()
        const executor = new BaseCommandExecutor<ApiRequestConfigInterface[]>(command)

        executor.execute(succeeded, failed)
    }

    getRequest(name: string, succeeded: (value: any) => void, failed: (error: ApiError) => void): void {
        const command = PiRestApi.getApiRequestCommand(name)
        const executor = new BaseCommandExecutor(command)

        executor.execute(succeeded, failed)
    }

    postRequest(name: string, json: any, succeeded: (networkRequest: NetworkRequestInterface) => void, failed: (error: ApiError) => void): void {
        const command = PiRestApi.postApiRequestCommand(name, json)
        const executor = new BaseCommandExecutor<NetworkRequestInterface>(command)

        executor.execute(succeeded, failed)
    }

    fetchNetworkRequest(id: string, succeeded: (networkRequest: NetworkRequestInterface) => void, failed: (error: Error) => void): void {
        const filter = {
            filter_id: id
        }
        const command = PiRestApi.getNetworkRequestsCommand(filter)
        const executor = new BaseCommandExecutor<NetworkRequestInterface[]>(command)

        executor.execute(requests => {
            if (requests.length === 1) {
                succeeded(requests[0])
            } else {
                failed(Error(intl().formatMessage({ id: LOCALIZATION.network_request_not_found })))
            }
        }, error => failed(generateUserError(error)))
    }
}
