// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { allMetricDataKeys, MetricDouble3DInterface, MetricsDataItem, SensorPlace, SensorPlaceID, TemperatureControllerID } from './Types'
import { intl } from '../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../Localization';
import { JetsonNames } from '../Jetson/Types';

function existingValuesFromMetricKeysCollection(collection: any): number[] {
    const allValues = Object.entries(collection).map(entry => {
        return allMetricDataKeys.some(element => element === entry[0]) ? entry[1] : undefined
    })

    const values: number[] = allValues.filter(value => value !== undefined) as number[]
    return values
}

export function maximumValueForMetricKeysCollection(collection: any): number {
    const values = existingValuesFromMetricKeysCollection(collection)
    const max = values.reduce((result, value) => value > result ? value : result)
    return max
}

export function minimumValueForMetricKeysCollection(collection: any): number {
    const values = existingValuesFromMetricKeysCollection(collection)
    const min = values.reduce((result, value) => value < result ? value : result)
    return min
}

export function compareMetricsDataItemDate(m1: MetricsDataItem, m2: MetricsDataItem): number {
    if (m1.date < m2.date) {
        return -1
    } else if (m1.date > m2.date) {
        return 1
    } else {
        return 0
    }
}

export function metricDouble3DLength(metric: MetricDouble3DInterface): number {
    return Math.sqrt(Math.pow(metric.x, 2) + Math.pow(metric.y, 2) + Math.pow(metric.z, 2))
}

export function getSensorPlaceName(sensorPlace: SensorPlace): string {
    const translate = intl().formatMessage;

    switch (sensorPlace) {
        case SensorPlaceID.Outdoor:
            return translate({ id: LOCALIZATION.sensor_place_name_outdoor })
        case SensorPlaceID.CameraCase:
            return translate({ id: LOCALIZATION.sensor_place_name_cameraCase })
        case SensorPlaceID.DC:
            return translate({ id: LOCALIZATION.sensor_place_name_DC })
        case TemperatureControllerID.Heater:
            return translate({ id: LOCALIZATION.sensor_place_name_heater })
        case TemperatureControllerID.Heater1:
            return translate({ id: LOCALIZATION.sensor_place_name_heater1 })
        case TemperatureControllerID.Heater2:
            return translate({ id: LOCALIZATION.sensor_place_name_heater2 })
        case TemperatureControllerID.Heater3:
            return translate({ id: LOCALIZATION.sensor_place_name_heater3 })
        case TemperatureControllerID.Heater4:
            return translate({ id: LOCALIZATION.sensor_place_name_heater4 })
        case TemperatureControllerID.HeaterCAM1:
            return translate({ id: LOCALIZATION.sensor_place_name_heater_cam1 })
        case TemperatureControllerID.HeaterCAM2:
            return translate({ id: LOCALIZATION.sensor_place_name_heater_cam2 })
        case TemperatureControllerID.HeaterCAM3:
            return translate({ id: LOCALIZATION.sensor_place_name_heater_cam3 })
        case JetsonNames.Jetson1:
            return translate({ id: LOCALIZATION.sensor_place_name_jetson1 })
        case JetsonNames.Jetson2:
            return translate({ id: LOCALIZATION.sensor_place_name_jetson2 })
        case SensorPlaceID.Pi:
            return translate({ id: LOCALIZATION.sensor_place_name_pi })
    }
}

export function getSensorPlaceOrder(sensorPlace: SensorPlace): number {
    switch (sensorPlace) {
        case SensorPlaceID.Outdoor:
            return 0
        case SensorPlaceID.CameraCase:
            return 1
        case SensorPlaceID.DC:
            return 2
        case TemperatureControllerID.Heater:
            return 3
        case TemperatureControllerID.Heater1:
            return 4
        case TemperatureControllerID.Heater2:
            return 5
        case TemperatureControllerID.Heater3:
            return 6
        case TemperatureControllerID.Heater4:
            return 7
        case TemperatureControllerID.HeaterCAM1:
            return 8
        case TemperatureControllerID.HeaterCAM2:
            return 9
        case TemperatureControllerID.HeaterCAM3:
            return 10
        case JetsonNames.Jetson1:
            return 11
        case JetsonNames.Jetson2:
            return 12
        case SensorPlaceID.Pi:
            return 13
    }
}

export function compareSensorPlaces(sp1: SensorPlace, sp2: SensorPlace): number {
    const sp1Index = getSensorPlaceOrder(sp1)
    const sp2Index = getSensorPlaceOrder(sp2)

    if (sp1Index < sp2Index) {
        return -1
    } else if (sp1Index > sp2Index) {
        return 1
    } else {
        return 0
    }
}
