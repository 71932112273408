// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import Order from '../../../Data/_Networking/ReactQuery/Order';
import { intl } from '../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../Localization';


type Props<T> = Readonly<{
  /** if defined then constrol state is stored in browser session and recovered on control present.  */
  sessionStoreKey?: string;
  orderBy: Array<keyof T>;
  orderByTitle: Array<string>;
  selectedOrderBy: keyof T;
  selectedOrder: Order;
  onChange: (orderBy: keyof T, order: Order) => void;
}>;

export function SortOrderSelector_StoredValue(sessionStoreKey: string): Order | undefined {
  try {
    if (sessionStoreKey?.length) {// if sessionStoreKey enabled then recover stored value from session storage only if no active value
      const storedValue = window.sessionStorage.getItem("SortOrderSelector-"+sessionStoreKey);
      if (storedValue !== null) {
        return (storedValue === 'asc') ? Order.ASC : Order.DESC;
      }
    }
  } catch(ignore) {}
  return undefined;
}

const SortOrderSelector = <T, >(props: Props<T>) => {
  const getOptionID = (orderBy: keyof T, order: Order) => `${ orderBy.toString() }-${ order.toString().toLowerCase() }`;

  const handleChange = (event: any) => {
    const [selectedOrderBy, selectedOrder] = event.target.value.split('-');
    const order = (selectedOrder === 'asc') ? Order.ASC : Order.DESC;

    if (props.sessionStoreKey?.length) {// if enabled then recover stored value from session storage
      window.sessionStorage.setItem("SortOrderSelector-"+props.sessionStoreKey, order || "");
    }

    props.onChange(selectedOrderBy as keyof T, order);
  };

  //{ `${ item.toString() } ${ order }` }
  const renderMenuOption = (item: keyof T, index: number) => (
    Object.keys(Order).map((order: string) => (
      <MenuItem
        value={ getOptionID(item, order as Order) }
      >
        { `${ props.orderByTitle[index] } ${ intl().formatMessage({ id: ((order === 'ASC') ? LOCALIZATION.sort_asc :  LOCALIZATION.sort_desc) }) }` }
      </MenuItem>
    ))
  );

  return (
    <Box sx = {{ minWidth: 80 }}>
      <FormControl fullWidth>
        <InputLabel id="select-sort-label">{ intl().formatMessage({ id: LOCALIZATION.sort_by }) }</InputLabel>
        <Select
          labelId="select-sort-label"
          id="select-sort-select"
          value={ getOptionID(props.selectedOrderBy, props.selectedOrder) }
          label={ intl().formatMessage({ id: LOCALIZATION.sort_by }) }
          onChange={handleChange}
          sx = {{ height: '38px' }}
        >
        {
          props.orderBy.map(renderMenuOption)
        }
        </Select>
      </FormControl>
    </Box>
  );
};


export default SortOrderSelector;
