// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';

import Button, { Props as ButtonProps } from '../../../Components/_BaseUI/Button';


type Props = Readonly<{
  items: ButtonProps[];
}>;


const ButtonOptions: React.FC<Props> = (props: Props) => {
  if (props.items.length === 0) { return null; }

  const renderButton = (buttonProps: ButtonProps) => (
    <Grid
      key={ buttonProps.children }
      item
    >
      <Button
        sx={ {
          padding: '0 10px',
          ...buttonProps.sx 
        }}
        onClick={ buttonProps.onClick }
        disabled={ buttonProps.disabled }
      >
        { buttonProps.children }
      </Button>
    </Grid>
  );

  return (
    <Grid
      container
      spacing={ 1 }
      justifyContent="flex-end" 
      direction="row"
      sx={ {
        paddingTop: '10px',
      }}
    >
      { props.items.map(renderButton) }
    </Grid>
  );
};


export default ButtonOptions;
