// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import fetchJSONData from '../../_Networking/ReactQuery/fetch';
import { NODE_JS_BACKEND_URL } from '../../../constants';
import CameraState, { CameraStateValue } from '..';



export const fetchCameraState = async (): Promise<CameraState> => {
  try {
    const data = await fetchJSONData<CameraState>(
      `${ NODE_JS_BACKEND_URL }/camera-state`,
      {
        method: 'GET',
      }
    );

    if (data.state === CameraStateValue.Error) {
      throw new Error(data?.message || '');
    } else {
      return data;
    }
  } catch (error) {
    throw error;
  }
};
