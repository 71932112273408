// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { makeStyles } from '@mui/styles';

import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import AnyChildren from '../../../Tools/AnyChildren';


type Props = Readonly<{
  textAnchor: string;
  x: number;
  y: number;
  fill: string;
  children: AnyChildren;
}>;


const useStyles = makeStyles(() => ({
  label: {
    fontSize: Sizes.title,
    fontFamily: Fonts.main,
  },
}));


const YAxisLabel: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <text
      className={ classes.label }
      textAnchor={ props.textAnchor }
    >
      { props.children }
    </text>
  );
};


export default YAxisLabel;
