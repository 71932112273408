// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { StorageService } from '../Common/StorageService'
import User from '../AccountUsers/User';

export type UserDataChangeSubscriber = (data: User | undefined) => void

interface UserStorageServiceInterface {
    user?: User
    reset(): void

    subscribeOnChanges(subscriber: UserDataChangeSubscriber): void
    unsubscribeFromChanges(subscriber: UserDataChangeSubscriber): void
}

export default class UserStorageService extends StorageService<User> implements UserStorageServiceInterface {
    key: string = 'UserStorageService.user'

    private subscribers: UserDataChangeSubscriber[] = []

    get user(): User | undefined {
        return this.data
    }

    set user(newUser: User | undefined) {
        this.data = newUser
        this.informSubscribers()
    }

    subscribeOnChanges(subscriber: UserDataChangeSubscriber): void {
        if (!this.subscribers.includes(subscriber)) {
            this.subscribers.push(subscriber)
        }
    }

    unsubscribeFromChanges(subscriber: UserDataChangeSubscriber): void {
        this.subscribers = this.subscribers.filter(s => s !== subscriber)
    }

    private informSubscribers() {
        this.subscribers.forEach(s => s(this.data))
    }
}
