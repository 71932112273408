// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import VideoJS, { VjsPlayer, vjs } from './VideoJS'

export type { VjsPlayer }

type Props = Readonly<{
  videoUrl?: string;
  aspectRatio?: string;
  posterUrl?: string;
  preventControlsHide?: boolean;
  onPlayerReady?: (player: VjsPlayer) => void
  onPlaybackTimeChange?: (player: VjsPlayer, playbackPosSec: number | undefined) => void;
  onPlaybackTimeChangeRoundedToSec?: (player: VjsPlayer, playbackPosSec: number | undefined) => void;
}>;

const VideoPlayer = (props: Props) => {
  const playerRef = React.useRef<VjsPlayer | null>(null);

  const aspectRatio = props.aspectRatio?.replace("/", ":");// because here need 16:9 not 16/9

  // ControlBar.prototype.options_ = {
  //   loadEvent: 'play',
  //   children: ['playToggle', 'volumeMenuButton', 'currentTimeDisplay', 'progressControl', 'liveDisplay', 'durationDisplay', 'customControlSpacer', 'playbackRateMenuButton', 'chaptersButton', 'subtitlesButton', 'captionsButton', 'fullscreenToggle']
  // };

  const videoJsOptions = {
    poster: props.posterUrl,
    controls: true,
    autoplay: 'muted',
    // responsive: true,
    fluid: !!aspectRatio,
    aspectRatio: aspectRatio,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    fill: !aspectRatio,
    inactivityTimeout: (props.preventControlsHide) ? 0 : 10000, // in  milliseconds

    controlBar: {
      playToggle: true,
      volumePanel: {inline: false},
      pictureInPictureToggle: true,
      progressControl: {
        seekBar: true
      },
      skipButtons: {
        forward: 15,
        backward: 15
      },
      currentTimeDisplay: true,
      timeDivider: false,
      durationDisplay: false,
      remainingTimeDisplay: true,
      playbackRateMenuButton: true
    },
    userActions: {
      hotkeys: function(this: VjsPlayer, event: KeyboardEvent) {
        if (!this) {
          return;
        }
        const seekTimeSeconds = 5
        const currentTime = this.currentTime() || 0;

        if (event.key === "ArrowRight") {
          let duration = this.duration() || 0;
    
          if (this.player_.liveTracker && this.player_.liveTracker.isLive()) {
            const seekDuration = this.player_.liveTracker.seekableEnd();
            if (seekDuration !== undefined) {
              duration = seekDuration;
            }
          }
          const newTime = Math.min(currentTime + seekTimeSeconds, duration);
          this.currentTime(newTime);
        } 
        else if (event.key === "ArrowLeft") {
          const newTime = Math.max(0, currentTime - seekTimeSeconds);
          this.currentTime(newTime);
        }
        else if (event.code === "Space") {
          if (this.paused()) {
            this.play()
          }
          else {
            this.pause()
          }
        }
      },
    },

    liveui: true,
  };
  /*
  const EVENTS = [
    // HTMLMediaElement events
    'abort',
    'canplay',
    'canplaythrough',
    'durationchange',
    'emptied',
    'ended',
    'error',
    'loadeddata',
    'loadedmetadata',
    'loadstart',
    'pause',
    'play',
    'playing',
    'progress',
    'ratechange',
    'seeked',
    'seeking',
    'stalled',
    'suspend',
    'timeupdate',
    'volumechange',
    'waiting',

    // HTMLVideoElement events
    'enterpictureinpicture',
    'leavepictureinpicture',

    // Element events
    'fullscreenchange',
    'resize',

    // video.js events
    'audioonlymodechange',
    'audiopostermodechange',
    'controlsdisabled',
    'controlsenabled',
    'debugon',
    'debugoff',
    'disablepictureinpicturechanged',
    'dispose',
    'enterFullWindow',
    'error',
    'exitFullWindow',
    'firstplay',
    'fullscreenerror',
    'languagechange',
    'loadedmetadata',
    'loadstart',
    'playerreset',
    'playerresize',
    'posterchange',
    'ready',
    'textdata',
    'useractive',
    'userinactive',
    'usingcustomcontrols',
    'usingnativecontrols',
];
*/

  const handlePlayerReady = (player: VjsPlayer) => {
    playerRef.current = player;
    props.onPlayerReady?.(player)
    setTimeout(() => {
      vjs.log('VideoPlayer: show controls');
      player.play();
      player.userActive(true)
    }, 500)

    // You can handle player events here, for example:
    player.on('waiting', () => {
      vjs.log('VideoPlayer: is waiting');
    });

    player.on('dispose', () => {
      vjs.log('VideoPlayer: will dispose');
    });
    // player.on(EVENTS, (event: any) => {
    //   vjs.log('VideoPlayer: ' + event?.['type']);
    // });
  };

  return (
    <VideoJS 
      videoUrl={ props.videoUrl }
      options={videoJsOptions} 
      onReady={handlePlayerReady}
      onPlaybackTimeChange={ props.onPlaybackTimeChange }
      onPlaybackTimeChangeRoundedToSec={ props.onPlaybackTimeChangeRoundedToSec }
     />
  );
}

export default VideoPlayer;