// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import ResponseManyMeta from './ResponseMeta';


type ResponseMany<Data> = {
  data: Data;
  meta: ResponseManyMeta;
};

export function getResponseManyDataOne<T>(item: T | T[] | undefined, defaultVal?:T | undefined): T | undefined {
  if (item) {
    if (Array.isArray(item) && item.length > 0) {
      return item[0];
    }
    return item as T;
  }
  return defaultVal;
}

export function getResponseManyDataArray<T>(item: T | T[] | undefined): T[] | undefined {
  if (item) {
    if (Array.isArray(item)) {
      return item;
    }
    return [item];
  }
  return undefined;
}

export function getResponseManyDataArrayDef<T>(item: T | T[] | undefined, defaultVal:T[]): T[] {
  if (item) {
    if (Array.isArray(item)) {
      return item;
    }
    return [item];
  }
  return defaultVal;
}

export default ResponseMany;
