// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import { Colors } from '../../../Configuration/Styles/Colors';

type Props = Readonly<{
  separatorColor?: string
  separatorHeight?: number // default 28px
  separatorLineHeight?: string // default 1px
  sx?: SxProps<Theme>;
}>;

const LineSeparator: React.FC<Props> = (props: Props) => {
  const separatorColor = (props.separatorColor === undefined) ? Colors.separator : props.separatorColor
  const separatorLineHeight = (props.separatorLineHeight === undefined) ? '1px' : props.separatorLineHeight
  const marginVert = (props.separatorHeight === undefined) ? '10px' : `${props.separatorHeight/2}px`

  // const titleToValueWidthRatio = (props.titleToValueWidthRatio === undefined) ? 0.3 : props.titleToValueWidthRatio

  return (
    <Box sx={{
      marginTop: marginVert,
      marginBottom: marginVert,
      marginLeft: '-10px',
      marginRight: '-10px',
      height: separatorLineHeight,
      borderBottom: `solid ${separatorColor} ${separatorLineHeight}`,
    }}/>
  )
};


export default LineSeparator;
