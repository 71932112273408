// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import BaseCommandExecutor from '../../_Networking/AxiosFetch/Executor'
import { PiRestApi } from '../../_Networking/AxiosFetch/RestApi'
import { GpioPressAction } from './Types'

interface GpioPressActionsApiServiceInterface {
    getPressActions(): Promise<GpioPressAction[]>
    sendPressAction(action: GpioPressAction): Promise<void>
    sendPressActions(actions: GpioPressAction[], duration: number): Promise<void>
}

export default class GpioPressActionsApiService implements GpioPressActionsApiServiceInterface {
    getPressActions(): Promise<GpioPressAction[]> {
        return new Promise<GpioPressAction[]>((resolve, reject) => {
            const command = PiRestApi.fetcGpioPressActionsCommand()
            const executor = new BaseCommandExecutor<GpioPressAction[]>(command)
            executor.execute(data => resolve(data), error => reject(error))
        })
    }

    sendPressAction(action: GpioPressAction): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const command = PiRestApi.pressActionCommand(action)
            const executor = new BaseCommandExecutor<void>(command)
            executor.execute(resolve, error => reject(error))
        })
    }

    sendPressActions(actions: GpioPressAction[], duration: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const command = PiRestApi.pressActionsCommand(actions, duration)
            const executor = new BaseCommandExecutor<void>(command)
            executor.execute(resolve, error => reject(error))
        })
    }
}
