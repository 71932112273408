// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import Team from '../../../Data/EPG/Team/Team';
import useFetchAllPagesTeams from '../../../Data/EPG/Team/hook';


type Props = Readonly<{
  teamID: Team['id'];
}>;


const TeamCell: React.FC<Props> = (props: Props) => {
  const { allCached: teams } = useFetchAllPagesTeams();
  const team = (teams?.data || []).find(({ id }) => (id === props.teamID));

  if (team) {
    return (
      <span>
        • { team.shortName || team.name }
      </span>
    );
  }

  return null;
};


export default TeamCell;
