// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Box, TextField } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';


const useStylesInputBox = makeStyles(() => createStyles({
    input: {
      height: '38px',
      paddingTop: '0px',
      paddingBottom: '0px',
      lineHeight: '38px',
    },
}));

type Props = Readonly<{
    value: number | undefined;
    onChange: (newScore: number | undefined) => void;
}>;


const AnalyticsScoreNumber = (props: Props) => {
    const classes = useStylesInputBox();

    const handleTextValueChanged = (event: React.SyntheticEvent) => {
      const textValue = (event.target as HTMLInputElement).value
      let value = parseInt((textValue || ''), 10);
  
      if (!isNaN(value)) {
        if ((value < 0)) value = 0;
  
        props.onChange(value);
      }
      else if (textValue === '') {
        props.onChange( 0);
        
      }
      else {
        props.onChange(undefined);
      }
    };
  
    return (
      <Box sx = {{ maxWidth: 80 }}>
        <TextField
          fullWidth
          type={ "number" }
          variant="outlined"
          value={ props.value || 0 }
          InputProps={ { className: classes.input } }
          inputProps={ { step: 1, min: 0 } }
          onChange={ handleTextValueChanged }
        />
      </Box>
    );
};


export default AnalyticsScoreNumber;
