// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl';

import CameraStreamPreview from '../../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview'
import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content';
import LOCALIZATION from '../../../../Localization';
import { FileType } from '../../../../Data/VideoArchive';
import { useCameraData } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks';
import VertGrid from '../../../../Components/_Layout/VertGrid';


const ScoreboardCard: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const cameraSettings = useCameraData();

  if (!cameraSettings?.settings?.urls?.scoreboardSrcImage) {
    return (
        <></>
    )
  }

  return (
    <MainCard>
      <RegularCardHeader title={ props.intl.formatMessage({ id: LOCALIZATION.scoreboard }) }/>
      <MainCardContent>
        <VertGrid>
          <CameraStreamPreview
            previewImageUrl={ cameraSettings?.settings?.urls?.scoreboardSrcImage }
            fileType={ FileType.Operator }
          />
        </VertGrid>
      </MainCardContent>
    </MainCard>
  );
};


export default injectIntl(ScoreboardCard);
