// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';

import Team from '../../../Data/EPG/Team/Team';
import useFetchAllPagesTeams from '../../../Data/EPG/Team/hook';


type Props = Readonly<{
  selectedTeamID?: number;
  onSelect: (teamID: Team['id'] | null) => void;
}>;


const useStyles = makeStyles(() => ({
  inputRoot: {
    height: '38px',
    lineHeight: '38px',
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // paddingTop: 0,
      // paddingBottom: 0,
      marginTop: '-8px', // hack to make edit 38px position correct on safari and chrome. on chrome also work padding 0
    },
    '& .MuiAutocomplete-endAdornment': {
      marginTop: '-5px',
    },
  }
}));

const TeamAutocompleteSelect: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    // status,
    allCached: teams,
  } = useFetchAllPagesTeams();
  const options = (teams?.data || [])
    .map(({ id, shortName }) => ({
      id,
      name: shortName,
    }));
  const selectedTeam = (props.selectedTeamID) ?
    options.find(({ id }) => (id === props.selectedTeamID))
    :
    null;

  const handleSelect = (_event: any, newTeam: any | null) => {
    if (!newTeam) {
      props.onSelect(null);
    } else {
      const team: Team | undefined = (teams?.data || []).find(({ id }) => (id === newTeam.id));

      if (team && team?.id) {
        props.onSelect(team.id);
      }
    }
  };

  return (
    <Autocomplete
      options={ options }
      renderInput={params => (
        <TextField
          { ...params }
          variant="outlined"
        />
      )}
      classes={ classes }
      getOptionLabel={ option => option.name }
      style={ { height: '38px' } }
      value={ selectedTeam }
      onChange={ handleSelect }
    />
  );
};


export default TeamAutocompleteSelect;
