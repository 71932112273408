// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { clone } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { ItemStreamProps } from '../index';
import FieldSet, { FieldSetInputType } from '../../../../../Components/_BaseUI/FieldSet';
import { useFetchStreamingSettingsProfileOptions } from '../../../../../Data/NapiStreamingProfileOptions/hook';
import FieldSetWrapper from '../FieldSetWrapper';
import LOCALIZATION from '../../../../../Localization';
import CameraConfig from '../../../../../Data/NapiCameraConfig';


type Props = Readonly<{
  item: ItemStreamProps;
  defaultStreamingProfileConfig: CameraConfig;
  onChange: (item: ItemStreamProps) => void;
} & WrappedComponentProps>;

enum SourceType {
  Operator = 'operator',
  Panorama = 'panorama',
}


const MainStreamSource: React.FC<Props> = (props: Props) => {
  useFetchStreamingSettingsProfileOptions();

  const handleChangeSource = (value: string) => {
    const operator = clone(props.item.operator) || {};

    operator['full-frame'] = (value === SourceType.Panorama);

    /*
    if panorama selected then
    "operator": {
       "full-frame": true,
       and remove  "external":
    */
    if (operator['full-frame']) {
      delete operator.external;
    } else {
      operator['external'] = props.defaultStreamingProfileConfig.operator?.external;
    }

    props.onChange({
      ...props.item,
      operator,
    });
  };

  const source = (props.item.operator?.['full-frame'] === true) ?
    SourceType.Panorama
    :
    SourceType.Operator;

  return (
    <FieldSetWrapper>
      <FieldSet
        key={ 'Source' }
        label={ `${ props.intl.formatMessage({ id: LOCALIZATION.source }) }:` }
        input={ {
          type: FieldSetInputType.Select,
          value: source,
          options: [
            {
              id: SourceType.Operator,
              name: props.intl.formatMessage({ id: LOCALIZATION.source_operator }),
            },
            {
              id: SourceType.Panorama,
              name: props.intl.formatMessage({ id: LOCALIZATION.source_panorama }),
            },
          ],
          onChange: handleChangeSource,
        } }
      />
    </FieldSetWrapper>
  );
};


export default injectIntl(MainStreamSource);
