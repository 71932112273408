// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Card from '../../Components/_Layout/Card';
import PageContainer from '../../Components/_Layout/PageContainer';
import VideoArchiveContent, { sessionStoreKey } from './VideoArchiveContent';
import LOCALIZATION from '../../Localization';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get'
import { FieldSetDateComponent_StoredValue } from '../../Components/_BaseUI/FieldSet/FieldSetDateComponent';

type Props = Readonly<{} & WrappedComponentProps>;

const AnalyticsVideoArchivePage: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const filterDate = get(location, 'state.filterDate');// get filter date from events/history

  const [date, setDate] = React.useState<string | null>(filterDate || FieldSetDateComponent_StoredValue(sessionStoreKey));

  const handleChangeDate = (date: string | null) => {
    setDate(date);
  };

  return (
    <PageContainer
      header={
      props.intl.formatMessage({
        id: LOCALIZATION.page_analytics_video_archive_title
      })
    }
    >
      <Grid
        item
        xs={ 12 }
      >
        <Card>
          <VideoArchiveContent
            date={ date }
            onChangeDate={ handleChangeDate }
          />
        </Card>
      </Grid>
    </PageContainer>
  );
};


export default injectIntl(AnalyticsVideoArchivePage);
