// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import Team from '../Team';
import Order from '../../../_Networking/ReactQuery/Order';
import { PAGE_LIMIT, NODE_JS_BACKEND_URL } from '../../../../constants';
import { queryCache, queryClient } from '../../../../AppProviders';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import { getCachedDataAllPagedItems, Result, usePagedItems } from '../../../_Networking/ReactQuery/template';
import fetchJSONData from "../../../_Networking/ReactQuery/fetch";
import ResponseSingle from '../../../_Networking/ReactQuery/ResponseSingle';
import { Action, ActionType } from './useMutation';

type FetchProps = Readonly<{
  page: number;
  limit: number;
  order?: Order;
  orderBy?: keyof Team;
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'teams';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.page,
  props.limit,
  props.orderBy,
  props.order,
]);

const getAllCached = () => (
  getCachedDataAllPagedItems<Team>(getKeyWithoutPage())
);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<ResponseMany<Team[]>>(getKey(props))
);

const flushCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};



const postTeam = async (team: Team): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<Team>>(
      `${NODE_JS_BACKEND_URL}/team`,
      {
        method: 'POST',
        body: JSON.stringify(team),
      }
    );

    if (data.error) {
      throw new Error(data.error);
    } else {
      return ({
        type: ActionType.Post,
        team: data.data,
      });
    }
  } catch (error) {
    throw error;
  }
};

const deleteTeam = async (IDs: number[]): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<Team>>(
      `${NODE_JS_BACKEND_URL}/team`,
      {
        method: 'DELETE',
        body: JSON.stringify({ IDs }),
      }
    );

    if (!data?.error) {
      return ({
        IDs,
        type: ActionType.Delete,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Delete Team');
    }
  } catch (error) {
    throw error;
  }
};

const fetchData = async (props: FetchProps): Promise<ResponseMany<Team[]>> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  const offset = (props.page * props.limit);
  let params = `?limit=${props.limit}&offset=${offset}`;
  if (props.orderBy) {
    params += `&order=${props.order}&orderBy=${props.orderBy}`;
  }
  return await fetchJSONData<ResponseMany<Team[]>>(
    `${NODE_JS_BACKEND_URL}/teams${params}`,
    {
      method: 'GET',
    }
  );
};


type UseFetchProps = Readonly<{
  enableToFetch: boolean;
  page: number;
  limit?: number;
  order?: Order;
  orderBy?: keyof Team;
}>;


const useFetch = (props: UseFetchProps): Result<Team> => {
  const { enableToFetch = true, page: pg = 0, limit = PAGE_LIMIT } = props;
  const fetchTemplate = async (queryKey: QueryKey, page: number = 0) => fetchData({
    page,
    limit,
    order: props.order,
    orderBy: props.orderBy,
  });

  return usePagedItems<Team>({
    page: pg,
    fetch: fetchTemplate,
    queryKey: getKey({
      page: pg,
      limit,
      order: props.order,
      orderBy: props.orderBy,
    }),
    config: {
      enabled: !!enableToFetch,
    },
  });
};


export {
  getKey,
  postTeam,
  deleteTeam,
  useFetch,
  getCached,
  flushCache,
  getAllCached,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
