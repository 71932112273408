// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { dayjsFromDateStr } from '../../../Pages/EventsPage/AddEvent/helpers';
import EPGEvent from '../EPGEvents/EPGEvent';
import EventWithoutID, { GameRepeatType } from './EventWithoutID';


type Event = Readonly<EventWithoutID & { id: number; }>;


export const eventFromEPGEvent = (event?: EPGEvent): Event | undefined => {
    if (!event) {
        return undefined;
    }

    const startAt = dayjsFromDateStr(event.startAt);
    const endAt = dayjsFromDateStr(event.endAt);
    const startDate = startAt.locale('en').format('YYYY.MM.DD');
    const startTime = startAt.locale('en').format('HH:mm');
    const endDate = endAt.locale('en').format('YYYY.MM.DD');
    const endTime = endAt.locale('en').format('HH:mm');

    const out: Event = {
      id: event.id,
      name: event.name,
      subTitle: event.subTitle,
      description: event.description,
      priority: event.priority,
      gameType: event.gameType,
      sportType: event.sportType,
      homeTeamID: event.homeTeamID,
      guestTeamID: event.guestTeamID,
      streamProfileID: event.streamingID,
      streamOverride: event.streamOverride,
      immediateMarkupPromocode: event.immediateMarkupPromocode,
      extraJson: event.extraJson,
      disabled: false,
      repeatType: GameRepeatType.NoRepeat,
      repeatDays: [],
      startDate: startDate,
      startTime: startTime,
      endDate: endDate,
      endTime: endTime,
    }
    return out;
}

export default Event;
