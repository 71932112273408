// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import { NODE_JS_BACKEND_URL } from '../../../constants';
import fetchJSONData from '../../_Networking/ReactQuery/fetch';
import { StreamingSettings } from '..';
import { queryCache, queryClient } from '../../../AppProviders';
import ResponseSingle from '../../_Networking/ReactQuery/ResponseSingle';


export const PREFIX_OF_A_COMPOSITE_KEY = 'napi-streaming-settings';

export const getKey = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

export const getNapiStreamingSettingsCached = (): ResponseSingle<StreamingSettings> | undefined => (
  queryClient.getQueryData<ResponseSingle<StreamingSettings>>(getKey())
);

export const flushNapiStreamingSettingsCache = () => {
  const queries = queryCache.findAll([getKey()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};


export const fetchData = async (): Promise<ResponseSingle<StreamingSettings>> => {
  const cacheData = getNapiStreamingSettingsCached();

  if (cacheData) { return cacheData; }

  return await fetchJSONData<ResponseSingle<StreamingSettings>>(
    `${NODE_JS_BACKEND_URL}/streaming/settings.json`,
    {
      method: 'GET',
    }
  );
};

export const postData = async (settings: Partial<StreamingSettings>): Promise<ResponseSingle<StreamingSettings>> => {
  try {
    return await fetchJSONData<ResponseSingle<StreamingSettings>>(
        `${NODE_JS_BACKEND_URL}/streaming/settings.json`,
        {
          method: 'POST',
          body: JSON.stringify(settings),
        }
    );
  } catch (error) {
    throw error;
  }
};

