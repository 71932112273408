// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useLayoutEffect } from 'react'
import { Grid, Box } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Delete } from '@mui/icons-material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';

import ErrorMessage from '../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage'
import GameResultCard from './Cards/GameResultCard'
import GameVideoCard from './Cards/GameVideoCard'
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import VertGrid from '../../Components/_Layout/VertGrid';
import AnalyticsGamesPageHeader from '../../Components/_Layout/AnalyticsGamesPageHeader';
import { useParams } from 'react-router-dom';
import useGamesPagedData from '../../Data/Analytics/AnalyticsGameArchive/hook';
import AnalyticsGameArchive, { AnalyticsGameShared, getGameTitle, updateAnalyticsGameSharedColors } from '../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import { getResponseManyDataOne } from '../../Data/_Networking/ReactQuery/ResponseMany';
import GameTeamPysicalReportCard from './Cards/GameTeamPhysicalReportCard';
import GameTeamMainReportCard from './Cards/GameTeamMainReportCard';
import GameTeamTacticalReportCard from './Cards/GameTeamTacticalReportCard';
import useFetchAllPagesTeamRefs from '../../Data/Analytics/AnalyticsTeamRef/hook';
import { ResultStatus } from '../../Data/_Networking/ReactQuery/template';
import { AnalyticsAnalyticsStatus } from '../../Data/Analytics/AnalyticsStatuses';
import useMutationGame, { ActionType } from '../../Data/Analytics/AnalyticsGameArchive/fetch/useMutation';
import EditTitleSubtitlePopup, { EditTitleSubtitleFields } from '../../Components/_PopupControls/EditTitleSubtitlePopup';
import LOCALIZATION from '../../Localization';
import { AnalyticsType } from '../../Data/Analytics/AnalyticsTypes';

import { getMenuLink } from '../../Menu/Data/MenuStructure';
import { useNavigate } from 'react-router-dom';
import { MenuID } from '../../Menu/Data/MenuElement';
import AnalyticsGameMarkupIdPopup from '../AnalyticsGameMarkupIdPopup';
import AnalyticsGameStartAnalyticsPopup from '../AnalyticsGameStartAnalyticsPopup';
import { removeAllQueryCache } from '../../AppProviders';

const AnalyticsGameReport: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  let { AnalyticsGameReport: gameId } = useParams();

  const { mutateAsync: mutateConfig } = useMutationGame();
  const [renameItem, setRenameItem] = React.useState<AnalyticsGameArchive | undefined>(undefined);
  
  const { status: fetchStatus, data: gameRecordsData, handleFlushData: handleFlushGameData } = useGamesPagedData({ itemId: gameId });
  const gameResultInfo: AnalyticsGameArchive | undefined = getResponseManyDataOne(gameRecordsData?.data)
  const gameReportReady = gameResultInfo?.analytics_status === AnalyticsAnalyticsStatus.Done
  const [showMarkupIdPopupForGame, setShowMarkupIdPopupForGame] = React.useState<AnalyticsGameArchive | undefined>(undefined);
  const [showStartAnalyticsPopupForGame, setShowStartAnalyticsPopupForGame] = React.useState<AnalyticsGameArchive | undefined>(undefined);

  const [team1Color, setTeam1Color] = React.useState<string | undefined>(AnalyticsGameShared.team1Color);
  const [team2Color, setTeam2Color] = React.useState<string | undefined>(AnalyticsGameShared.team2Color);

  const navigate = useNavigate();

  const {
    getTeamRefByExternalId,
    status: teamRefStatus,
  } = useFetchAllPagesTeamRefs();

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  React.useEffect(() => {
    if (fetchStatus === ResultStatus.Success) {
      const team1Color = gameResultInfo?.team1_color || getTeamRefByExternalId(gameResultInfo?.team1)?.color
      const team2Color = gameResultInfo?.team2_color || getTeamRefByExternalId(gameResultInfo?.team2)?.color

      if (updateAnalyticsGameSharedColors(team1Color, team2Color, gameResultInfo?.id, false)) {
        // do not update gameResultInfo?.team1_color .. because it is not affect on any UI
        // make reload all UI
        setTeam1Color(AnalyticsGameShared.team1Color);
        setTeam2Color(AnalyticsGameShared.team2Color);        
      }
    }
    // eslint-disable-next-line
  }, [fetchStatus, gameResultInfo, getTeamRefByExternalId]);

  if (fetchStatus === ResultStatus.Loading || teamRefStatus === ResultStatus.Loading) {
    return (
      <>
      <br /><br /><Spinner /><br /><br />
      </>
    )
  }

  const handleArchiveEdit = async (editedItem: EditTitleSubtitleFields) => {
    try {
      await mutateConfig(
        {
          archive:{
            id: editedItem.id || "",
            game: { 
              id: editedItem.id,
              type: renameItem?.type || AnalyticsType.Game,
              title: editedItem.title,
              subtitle: editedItem.subtitle,
             },
          },
          type: ActionType.Patch,
        }, {
          onSuccess: () => {
            handleFlushGameData();
            handleCloseRenamePopup();
          },
        },
      );
    } catch (error) {
      alert(error);
      handleFlushGameData();
      handleCloseRenamePopup();
    }
  };

  const handleCloseRenamePopup = () => {
    setRenameItem(undefined);
  };
  

  const handleDelete = async () => {
    if (!gameResultInfo || !window.confirm(props.intl.formatMessage({ id: LOCALIZATION.confirm_remove }))) {
      return
    }
    try {
      await mutateConfig(
        {
          archive: {
            id: gameResultInfo.id,
            game: gameResultInfo,
          },
          type: ActionType.Delete,
        },
        {
          onSuccess: () => {
            const menuLink = getMenuLink(MenuID.AnalyticsVideoArchive);
            if (menuLink) {
              navigate(menuLink);
            }
          },
        }
      );
    } catch (error) {
      alert(error);
    }
  };

  const handleTeamMarkup = () => {
    setShowMarkupIdPopupForGame(gameResultInfo);
  };
  const handleStartAnalytics = () => {
    setShowStartAnalyticsPopupForGame(gameResultInfo);
  };

  const handleMarkupIdSetSuccess = async (game: AnalyticsGameArchive) => {
    try {
      setShowMarkupIdPopupForGame(undefined);
      removeAllQueryCache();
    } catch (error) {
      alert(error);

      handleFlushGameData();
      handleCloseMarkupIdPopup();
    }
  };
  const handleCloseMarkupIdPopup = () => {
    setShowMarkupIdPopupForGame(undefined);
  };

  const handleStartAnalyticsSuccess = async (game: AnalyticsGameArchive | undefined) => {
    try {
      setShowStartAnalyticsPopupForGame(undefined);
      handleFlushGameData();
    } catch (error) {
      alert(error);

      handleFlushGameData();
      handleCloseMarkupIdPopup();
    }
  };
  const handleStartAnalyticsPopup = () => {
    setShowStartAnalyticsPopupForGame(undefined);
  };
  const handleItemEditClicked = (item?: AnalyticsGameArchive) => {
    const menuLink = getMenuLink(MenuID.AnalyticsVideoArchiveDetails, [item?.record_id ?? ""]);
    if (menuLink) {
      navigate(menuLink);
    }
  };

  return (
    <Box> 
      <Grid 
      container
      spacing={ 2 }
      key={`${team1Color}-${team2Color}`}
      >
        <Grid item xs={ 12 }>
          <AnalyticsGamesPageHeader
            secondLineMenuActions={[
              {
                title: props.intl.formatMessage({ id: LOCALIZATION.rename }),
                icon: DriveFileRenameOutlineIcon,
                onMenuClick: () => {
                  setRenameItem(gameResultInfo);
                }
              },
              {
                title: props.intl.formatMessage({ id: LOCALIZATION.game_video }),
                icon: VideoSettingsIcon,
                onMenuClick: () => {
                  handleItemEditClicked(gameResultInfo);
                }
              },
              {
                title: props.intl.formatMessage({ id: (gameResultInfo?.analytics_status === AnalyticsAnalyticsStatus.NotStarted) ? LOCALIZATION.analytics_game_state_not_started : LOCALIZATION.analytics_team_mapping }),
                icon: (gameResultInfo?.analytics_status === AnalyticsAnalyticsStatus.NotStarted) ? QueryStatsIcon : CallSplitIcon,
                onMenuClick: (gameResultInfo?.analytics_status === AnalyticsAnalyticsStatus.Done || gameResultInfo?.analytics_status === AnalyticsAnalyticsStatus.WaitingTeamMapping) ? handleTeamMarkup : (gameResultInfo?.analytics_status === AnalyticsAnalyticsStatus.NotStarted) ? handleStartAnalytics : undefined
              },
              {
                title: props.intl.formatMessage({ id: LOCALIZATION.remove }),
                icon: Delete,
                onMenuClick: handleDelete
              },
            ]}
          />
          <ErrorMessage/>
        </Grid>
        <Grid item xs={ 12 }>
          <Grid container spacing={ 2 } direction="row">
            <Grid item xs={ 12 } md={ 6 }>
              <VertGrid spacing='19px'>
                <GameResultCard gameResultInfo={gameResultInfo} getTeamRefByExternalId={getTeamRefByExternalId} onRequestUpdateGameData={() => {
                  handleFlushGameData()
                }} />
                <GameVideoCard gameInfo={gameResultInfo} getTeamRefByExternalId={getTeamRefByExternalId} />
                {(gameReportReady) &&
                  <GameTeamPysicalReportCard gameResultInfo={gameResultInfo} getTeamRefByExternalId={getTeamRefByExternalId} />
                }
              </VertGrid>
            </Grid>
            {(gameReportReady) &&
              <Grid item xs={ 12 } md={ 6 }>
                <VertGrid spacing='19px'>
                  <GameTeamMainReportCard gameResultInfo={gameResultInfo} getTeamRefByExternalId={getTeamRefByExternalId} />
                  <GameTeamTacticalReportCard gameResultInfo={gameResultInfo} getTeamRefByExternalId={getTeamRefByExternalId} />
                </VertGrid>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      {
        (renameItem) &&
          <EditTitleSubtitlePopup
            popupTitle={ props.intl.formatMessage({ id: LOCALIZATION.rename }) }
            item={ {
              id: renameItem.id,
              title: getGameTitle(renameItem),
              subtitle: renameItem.subtitle,
            } }
            onSubmit={ handleArchiveEdit }
            onClose={ handleCloseRenamePopup }
          />
      }
      {
        (showMarkupIdPopupForGame) &&
          <AnalyticsGameMarkupIdPopup
            game={ showMarkupIdPopupForGame }
            onSubmit={ handleMarkupIdSetSuccess }
            onClose={ handleCloseMarkupIdPopup }
          />
      }
      {
        (showStartAnalyticsPopupForGame) &&
          <AnalyticsGameStartAnalyticsPopup
            game={ showStartAnalyticsPopupForGame }
            onSubmit={ handleStartAnalyticsSuccess }
            onClose={ handleStartAnalyticsPopup }
          />
      }
    </Box>
  )
};


export default injectIntl(AnalyticsGameReport);
