import { QueryKey, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { ResultSingle } from '../../../_Networking/ReactQuery/template';
import Camera from '../Camera';
import { getAccessToken } from '../../../../Tools/token';
import { getCamerasKey, getKey, getCameras, fetchActiveCamera, getCamerasList, CameraListOrderByVariant } from '../fetch';
import { ErrorResponse } from '../../../_Networking/ReactQuery/fetch';
import { QueryHookReturnType } from '../../../types';
import { RemoteCameraStateValue } from '../../RemoteCameraState';
import Order from '../../../_Networking/ReactQuery/Order';
import { getCameraStatisticKey } from '../../AvaibleCamerasStatistic/fetch';
import { CamerasStatisticResponse } from '../../AvaibleCamerasStatistic/types';

interface IGetCameraListProps<TData> {
  limit: number,
  offset: number,
  stateFilter?: RemoteCameraStateValue,
  filterByCameraID?: string,
  sort?: {
    order: Order,
    by: CameraListOrderByVariant,
  },
  /**
   * Please note that the default settings are already set in the hook, including the polling time. If necessary, you can make an override.
   */
  options?: Omit<UseQueryOptions<TData, unknown, TData, QueryKey>, 'queryKey' | 'queryFn'>
}

export const useCamerasList = (props: IGetCameraListProps<Camera[]>): QueryHookReturnType<Camera[]> => {
  const { limit, offset, filterByCameraID, stateFilter, sort, options } = props;
  const queryClient = useQueryClient();
  const queryKey = getCamerasKey();

  const resetCache = () => {
    queryClient.invalidateQueries(queryKey);
  };
  const query = useQuery(
    [queryKey, limit, offset, filterByCameraID, stateFilter, sort?.order, sort?.by] as QueryKey,
    async () => {
      return getCamerasList({
        limit,
        offset,
        filterByCameraID,
        stateFilter,
        sort,
      })
    }, {
      staleTime: Infinity,
      cacheTime: 60_000,
      refetchInterval: (15_000),       // 15 sec
      keepPreviousData: true,
      ...options,
    });

  return {
    ...query,
    resetCache,
  }
}
interface IGetAvaibleProps<TData> {
  /**
   * Please note that the default settings are already set in the hook, including the polling time. If necessary, you can make an override.
   */
  options?: Omit<UseQueryOptions<TData, unknown, TData, QueryKey>, 'queryKey' | 'queryFn'>
}
/**
 * hook for define is there any camera in the sys
 */
export const useIsAvaibleCameras = (props?: IGetAvaibleProps<boolean>) => {
  const queryClient = useQueryClient();

  /**
   * Checks cameras in cache. If it so then we have avaible cammeras
   * @returns
   */
  const hasCameraInList = async () => (queryClient.getQueriesData<Camera[]>(getCamerasKey()).map(v => !!v?.[1]?.length)).includes(true);

  /**
   * checks cameras stat present.  If it so then we have avaible cammeras
   * @returns
   */
  const hasCamerasInStatistic = async () => (queryClient.getQueriesData<CamerasStatisticResponse>(getCameraStatisticKey()).map(v => !!v?.[1]?.totalCameras)).includes(true);

  /**
   * if we have not any cache then we have to check by self
   * @returns
   */
  const getIsAvaibleCamerasForce = async () => {
    const res = await getCamerasList({
      limit: 1,
      offset: 0,
    })
    return !!res.length
  }

  const queryKey = getCamerasKey();
  const { options } = props ?? {};

  const resetCache = () => {
    queryClient.invalidateQueries(queryKey);
  };
  const query = useQuery(
    ['useIsAvaibleCameras'] as QueryKey,
    async () => {
      const res = (await hasCameraInList()) || (await hasCamerasInStatistic()) || (await getIsAvaibleCamerasForce())
      return res
    }, {
    staleTime: Infinity,
    refetchInterval: (15_000),       // 15 sec
    keepPreviousData: true,
    ...options,
  });

  return {
    ...query,
    resetCache,
  }
}

/**
 * @deprecated
 * @see {useCamerasList}
 * @param enableToFetch
 * @returns
 */
export const useCameras = (enableToFetch: boolean): ResultSingle<Camera[]> => {
  const queryKey = getCamerasKey();

  // @ts-ignore
  return useQuery<Camera[]>({
    queryKey,
    enableToFetch,
    queryFn: getCameras,
    staleTime: Infinity,
    keepPreviousData: true,
  });
};


export const useFetchActiveCamera = (): ResultSingle<Camera | ErrorResponse | undefined> => {
  const queryKey = getKey();
  const accessToken = getAccessToken();
  // @ts-ignore
  return useQuery<Camera | ErrorResponse | undefined>({
    queryKey,
    queryFn: fetchActiveCamera,
    enabled: !!accessToken,
    // keepPreviousData: true,
  });
};
