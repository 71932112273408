// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useMutation } from 'react-query';

import { queryClient } from '../../../../AppProviders';
import { deleteCamera, addCamera, patchCamera, getCamerasKey, getCachedCameras, clearCamerasCache } from '../fetch';
import Camera, { NewCamera } from '../Camera';


export enum ActionType {
  Add = 'add',
  Patch = 'patch',
  Delete = 'delete',
}

export type Action = Readonly<{
  camera?: Camera | NewCamera;
  type: ActionType;
  password?: string;
  login?: string;
}>;


const useMutationUser = () => {
  return useMutation(
    (action: Action) => {
      switch (action.type) {
        case ActionType.Add: {
          if (action.camera) {
            return addCamera(action.camera, action.login, action.password);
          }

          return Promise.reject(new Error('Invalid Camera Mutation Action Add'));
        }
        case ActionType.Patch: {
          if (action.camera) {
            return patchCamera(action.camera as Camera);
          }

          return Promise.reject(new Error('Invalid Camera Mutation Action Patch'));
        }
        case ActionType.Delete: {
          if (action.camera) {
            return deleteCamera(action.camera as Camera);
          }

          return Promise.reject(new Error('Invalid Camera Mutation Action Delete'));
        }
        default:
          return Promise.reject(new Error('Invalid Camera Mutation Action'));
      }
    },
    {
      onSuccess: (data) => {
        if ((data as Action).type === ActionType.Delete) {
          const deletedCameraID = (data as Action).camera?.cameraId
          const cameras = getCachedCameras()?.filter(({ cameraId }) => (cameraId !== deletedCameraID));

          queryClient.setQueryData(
            getCamerasKey(),
            cameras,
          );
        } else {
          clearCamerasCache();
        }
      },
    },
  );
};


export default useMutationUser;
