// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { compact } from 'lodash';

import User from '../../../Data/AccountUsers/User';
import enumToObject from '../../../Tools/enumToObject';
import UserWithoutID, {
  UserAccessLevel,
  UserCameraAccess,
  UserRole,
  userRoleAccessLevel,
  UserState,
  UserTeamRefAccess,
  UserType
} from '../../../Data/AccountUsers/UserWithoutID';


const DEFAULT_VALUE: User | UserWithoutID = {
  name: '',
  info: '',
  type: UserType.Local,
  state: UserState.Invalid,
  role: UserRole.User,
  login: '',
  cameraAccess: UserCameraAccess.None,
  teamRefAccess: UserTeamRefAccess.None,
  accessLevel: UserAccessLevel.User,
  userAvailableTeamRefExternalIdList: [],
  userAvailableCameraIdList: [],
  password: '',
};

const isUserWithID = (user: User | UserWithoutID): user is User => (user as User).id !== undefined;

const rolesOptions = (editorAccessLevel: UserAccessLevel) => {
  const roles = enumToObject(UserRole);

  return compact(Object.keys(roles).map((key: string) => {
    const keyId = roles[key] as UserRole
    const access = userRoleAccessLevel(keyId);

    if (!access) { return null; }

    if (editorAccessLevel > access) { return null; }

    // only root can add minimal
    if ((keyId === UserRole.Minimal) && (editorAccessLevel !== UserAccessLevel.Root)) { return null; }

    return ({
      id: roles[key],
      name: key.toLowerCase(),
    });
  }));
};


export {
  rolesOptions,
  isUserWithID,
  DEFAULT_VALUE,
};
