// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { JetsonTools } from '../../../../Tools/Tools'
import { JetsonId } from '../../Jetson/Types'
import { CaseHeaterTemperatureMetric, CaseHeater1TemperatureMetric, CaseHeater2TemperatureMetric, CaseHeater3TemperatureMetric, CaseHeater4TemperatureMetric, CaseHeaterCam1TemperatureMetric, CaseHeaterCam2TemperatureMetric, CaseHeaterCam3TemperatureMetric, DCTemperatureMetric, InternalHumidityMetric, InternalTemperatureMetric, Jetson, OutdoorTemperatureMetric, PiCpuLoadMetric, PiCpuTemperatureMetric, PowerInputVoltageMetric, DewPointTemperatureMetric } from '../Collection'
import { MetricsChartSourceInterface, MetricPeriodDay, MetricOutputTimeNow } from '../Types'

export const HardwarePowerCardDefaultMetricsSource: MetricsChartSourceInterface = {
    metrics: [PowerInputVoltageMetric, DCTemperatureMetric],
    defaultSelected: [PowerInputVoltageMetric.dataKey],
    uniqueKey: 'ChartPowerInputVoltage',
    outputPeriod: MetricPeriodDay,
    outputTime: MetricOutputTimeNow
}

export const HardwarePiCardDefaultMetricsSource: MetricsChartSourceInterface = {
    metrics: [PiCpuLoadMetric, PiCpuTemperatureMetric],
    defaultSelected: [PiCpuLoadMetric.dataKey, PiCpuTemperatureMetric.dataKey],
    uniqueKey: 'ChartHardwarePi',
    outputPeriod: MetricPeriodDay,
    outputTime: MetricOutputTimeNow
}

export const StatusGeneralStatisticCardDefaultMetricsSource: MetricsChartSourceInterface = {
    metrics: [InternalTemperatureMetric, DewPointTemperatureMetric, OutdoorTemperatureMetric, DCTemperatureMetric, CaseHeaterTemperatureMetric, CaseHeater1TemperatureMetric, CaseHeater2TemperatureMetric, CaseHeater3TemperatureMetric, CaseHeater4TemperatureMetric, CaseHeaterCam1TemperatureMetric, CaseHeaterCam2TemperatureMetric, CaseHeaterCam3TemperatureMetric, InternalHumidityMetric],
    defaultSelected: [OutdoorTemperatureMetric.dataKey, InternalTemperatureMetric.dataKey, InternalHumidityMetric.dataKey, DewPointTemperatureMetric.dataKey],
    uniqueKey: 'ChartStatusGeneralStatistics',
    outputPeriod: MetricPeriodDay,
    outputTime: MetricOutputTimeNow
}

export const HardwareStatisticCardDefaultMetricsSource: MetricsChartSourceInterface = {
    metrics: [InternalTemperatureMetric, DewPointTemperatureMetric, OutdoorTemperatureMetric, DCTemperatureMetric, CaseHeaterTemperatureMetric, CaseHeater1TemperatureMetric, CaseHeater2TemperatureMetric, CaseHeater3TemperatureMetric, CaseHeater4TemperatureMetric, CaseHeaterCam1TemperatureMetric, CaseHeaterCam2TemperatureMetric, CaseHeaterCam3TemperatureMetric, InternalHumidityMetric],
    defaultSelected: [OutdoorTemperatureMetric.dataKey, InternalTemperatureMetric.dataKey, InternalHumidityMetric.dataKey, DewPointTemperatureMetric.dataKey],
    uniqueKey: 'ChartHardwareStatistics',
    outputPeriod: MetricPeriodDay,
    outputTime: MetricOutputTimeNow
}

export function HardwareJetsonCardDefaultMetricsSource(id: JetsonId): MetricsChartSourceInterface {
    const CpuLoadMetric = Jetson.CpuLoadMetric(id)
    const CpuTemperatureMetric = Jetson.CpuTemperatureMetric(id)
    const CpuPowerConsumptionMetric = Jetson.CpuPowerConsumptionMetric(id)
    const CpuVoltageMetric = Jetson.CpuVoltageMetric(id)
    const GpuLoadMetric = Jetson.GpuLoadMetric(id)
    const GpuTemperatureMetric = Jetson.GpuTemperatureMetric(id)
    const GpuPowerConsumptionMetric = Jetson.GpuPowerConsumptionMetric(id)
    const Sys5VVoltageMetric = Jetson.Sys5VVoltageMetric(id)
    const Sys5VPowerConsumptionMetric = Jetson.Sys5VPowerConsumptionMetric(id)
    const SocPowerConsumptionMetric = Jetson.SocPowerConsumptionMetric(id)
    const BoardTemperatureMetric = Jetson.BoardTemperatureMetric(id)
    const TotalPowerConsumptionMetric = Jetson.TotalPowerConsumptionMetric(id)

    return {
        metrics: [
            CpuLoadMetric,
            CpuTemperatureMetric,
            CpuPowerConsumptionMetric,
            CpuVoltageMetric,
            GpuLoadMetric,
            GpuTemperatureMetric,
            GpuPowerConsumptionMetric,
            Sys5VVoltageMetric,
            Sys5VPowerConsumptionMetric,
            SocPowerConsumptionMetric,
            BoardTemperatureMetric,
            TotalPowerConsumptionMetric
        ],
        defaultSelected: [CpuLoadMetric.dataKey, CpuTemperatureMetric.dataKey, GpuLoadMetric.dataKey, GpuTemperatureMetric.dataKey, BoardTemperatureMetric.dataKey],
        uniqueKey: JetsonTools.chooseObject(id, ['CharHardwareJetson1', 'CharHardwareJetson2']),
        outputPeriod: MetricPeriodDay,
        outputTime: MetricOutputTimeNow
    }
}
