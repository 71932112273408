// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { StorageService } from '../../Common/StorageService'
import { SessionInterface } from '../Types'

interface SessionStorageServiceInterface {
    session?: SessionInterface
    reset(): void
}


export const KEY = 'SessionStorageService.session';

export default class SessionStorageService extends StorageService<SessionInterface> implements SessionStorageServiceInterface {
    key: string = KEY;

    get session(): SessionInterface | undefined {
        return this.data
    }

    set session(newSession: SessionInterface | undefined) {
        this.data = newSession
    }
}
