// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { compact } from 'lodash';
import { useLocation } from 'react-router-dom';
import { MenuID, MenuIDArr } from './Data/MenuElement';

// convert current browser url to menu element now active
const useBreadCrumbs = (): Array<MenuID> => {
  const location = useLocation();
  const breadCrumbs = compact((location.pathname.split('/'))
    .map((item: string) => {
      if (item.length !== 0) {
        const menuId = item as MenuID
        const index = MenuIDArr.indexOf(menuId);

        if (index !== -1) {
          return menuId;
        }

        return null;
      }

      return null;
    }));

  return breadCrumbs;
};


export default useBreadCrumbs;
