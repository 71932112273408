// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { useIntl } from 'react-intl';
import { useCameraData, useJetsonFanReview } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks'
import { useGpioPressActionData } from '../../../../Data/Camera/Gpio/Hooks'
import { useJetsonModelValue } from '../../../../Data/Camera/Jetson/Hooks'
import {
  JetsonHardwareButtonsAvailability,
  JetsonHardwareButtonsFormInterface,
  JetsonHardwareButtonType,
  JetsonId,
  JetsonNames
} from '../../../../Data/Camera/Jetson/Types'
import { PlatformConfiguration } from '../../../../Configuration/PlatformConfiguration'
import MainCardHeader from '../../../../Components/_Layout/CardHeader/Main'
import { ErrorMessageHelper } from '../../../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessageHelper'
import MainButton, { ButtonType } from '../../../../Components/_BaseUI/MainButton'
import MainCheckbox, { MainCheckboxType } from '../../../../Components/_BaseUI/MainCheckbox/MainCheckbox'
import { Colors } from '../../../../Configuration/Styles/Colors'
import { Fonts } from '../../../../Configuration/Styles/Fonts'
import { Sizes } from '../../../../Configuration/Styles/Sizes'
import { CameraNotAvailable, JetsonTools } from '../../../../Tools/Tools'
import JetsonChartView from './Chart/View'
import { useHardwareButtonsForm, useButtonsAvailability } from './Hooks'
import CoolerTemperatureController from './TemperatureController/View'
import {
  JetsonCardHeadingModelBuilder,
  TemperatureControllerHeadingModelBuilder
} from '../../../../Components/_Layout/CardHeader/Main/Builders'
import JetsonHardwareButtonDurationSelector from './Components/DurationSelector/View'
import { generateUserError } from '../../../../Data/_Networking/AxiosFetch/Errors/Tools'
import CameraContext from '../../../../Data/Camera/HWCameraSettings/fetch/Context'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content'
import { createStyles, makeStyles } from '@mui/styles';
import { Grid, Theme } from '@mui/material';
import LOCALIZATION from '../../../../Localization';
import { isUserRoleMinimum, UserRole } from '../../../../Data/AccountUsers/UserWithoutID';
import AuthorisationManager from '../../../../Data/Auth/AuthorisationManager';
import { CameraSettingsInterface } from '../../../../Data/Camera/HWCameraSettings/CameraSettings';
import { isCameraSettingsOptionActive } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsActiveOptions';


const useStyles = makeStyles((theme: Theme) => createStyles({
  heading: {
    fontSize: Sizes.heading,
    fontFamily: Fonts.main,
    fontWeight: Sizes.mediumWeight
  },
  controllerContainer: {
    paddingRight: 0,
    [theme.breakpoints.up('md')]: {
      paddingRight: 20,
      borderRight: `1px solid ${ Colors.mainGrey }`
    }
  },
  buttonsContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20
    }
  },
  chart: {
    height: 240
  }
}));

function CardHeader(props: { id: JetsonId }) {
  const intl = useIntl();
  const model = useJetsonModelValue(props.id)
  if (!model) return (<CameraNotAvailable/>)

  return (
    <MainCardHeader model={ new JetsonCardHeadingModelBuilder(model, intl.formatMessage).build() }/>
  )
}

function FanSection(props: { id: JetsonId }) {
  const intl = useIntl();
  const classes = useStyles()
  const id = props.id

  let isDisabled: boolean = false

  const fanReview = useJetsonFanReview(id)
  if (!fanReview) {
    isDisabled = true
  } else {
    isDisabled = fanReview.isDisabled !== undefined ? fanReview.isDisabled : false
  }

  const heading = fanReview ? new TemperatureControllerHeadingModelBuilder(fanReview).build(intl.formatMessage({ id: LOCALIZATION.fan })).name : undefined

  return isDisabled
    ? (<></>)
    : (
      <Grid container spacing={ 2 } className={ classes.controllerContainer }>
        <Grid item xs={ 12 }>
          <div className={ classes.heading }>
            { heading }
          </div>
        </Grid>
        <Grid item xs={ 12 }>
          <CoolerTemperatureController id={ id }/>
        </Grid>
      </Grid>
    )
}

function HardwareButtonsSection(props: { id: JetsonId }) {
  const intl = useIntl();
  const classes = useStyles()
  const id = props.id
  const dataProvider = CameraContext.current?.gpioPressActionsDataProvider
  const user = AuthorisationManager.shared.user

  const initialForm: JetsonHardwareButtonsFormInterface = {
    duration: PlatformConfiguration.defaultHardwareButtonsTime,
    states: {
      power: false,
      recovery: false,
      reset: false
    }
  }

  const btnNames = (id: string) => {
    switch (id) {
      case 'power': return intl.formatMessage({ id: LOCALIZATION.power_hw_btn })
      case 'recovery': return intl.formatMessage({ id: LOCALIZATION.factory_reset_hw_btn })
      case 'reset': return intl.formatMessage({ id: LOCALIZATION.reset_hw_btn })
    }
  }

  async function submitForm(form: JetsonHardwareButtonsFormInterface) {
    await dataProvider?.pressJetsonHardwareButtons(id, form)
  }

  const { onChangeButtonState, onChangeDuration, onSubmit, form } = useHardwareButtonsForm(submitForm, initialForm)

  const availability: JetsonHardwareButtonsAvailability = useButtonsAvailability(id)

  const buttonsCollection: JetsonHardwareButtonType[] = []
  if (availability.power) {
    buttonsCollection.push('power')
  }
  if (availability.reset) {
    buttonsCollection.push('reset')
  }
  if (availability.recovery && isUserRoleMinimum(user?.role, UserRole.SuperAdmin)) {
    buttonsCollection.push('recovery')
  }

  const isSubmitAvailable = buttonsCollection.map(buttonType => {
    return form.states[buttonType]
  }).reduce((result, value) => value || result, false)

  return (
    <Grid container spacing={ 2 } className={ classes.buttonsContainer }>
      <Grid item xs={ 12 }>
        <div className={ classes.heading }>
          { intl.formatMessage({ id: LOCALIZATION.hardware_buttons }) }
        </div>
      </Grid>
      <Grid item xs={ 12 }>
        { buttonsCollection.map(buttonType => {
          return (<Grid item key={ buttonType } xs={ 12 }>
            <MainCheckbox
              buttonType={ MainCheckboxType.CircleShaped }
              isChecked={ form.states[buttonType] }
              title={ btnNames(buttonType) }
              onClicked={ () => onChangeButtonState(buttonType, !form.states[buttonType]) }/>
          </Grid>)
        }) }
      </Grid>
      { isSubmitAvailable && (
        <Grid item key="duration">
          <Grid container spacing={ 2 }>
            <Grid item xs={ 6 }>
              <JetsonHardwareButtonDurationSelector currentDuration={ form.duration } onChanged={ onChangeDuration }/>
            </Grid>
            <Grid item xs={ 6 }>
              <MainButton title={ intl.formatMessage({ id: LOCALIZATION.apply }) } onClicked={ onSubmit }/>
            </Grid>
          </Grid>
        </Grid>
      ) }
    </Grid>
  )
}

function ButtonsCollection(props: { id: JetsonId }) {
  const intl = useIntl();
  const camera = useCameraData()

  const gpioPressActions = useGpioPressActionData()

  if (!camera) {
    return (<CameraNotAvailable/>)
  }

  const id = props.id
  const isOn = JetsonTools.getState(camera, id)
  const isCameraOn = camera.status === 'on'
  const dataProvider = CameraContext.current?.gpioPressActionsDataProvider

  const isAvailableSoftShutdown = JetsonTools.getIsAvailableSoftShutdown(gpioPressActions, id) && isOn
  const isAvailableTurnOn = JetsonTools.getIsAvailablePower(gpioPressActions, id) && !isOn

  const isAvailableFirmwareUpdate = JetsonTools.getIsAvailableFirmwareUpdate(gpioPressActions, id) && (isCameraSettingsOptionActive(camera.settings?.activeOptions?.haveFanHeaterPwm))
  // const isAvailableSSHConsole = JetsonTools.getState(camera, id)
  const user = AuthorisationManager.shared.user

  const handleReboot = () => {
    if (window.confirm(intl.formatMessage({ id: LOCALIZATION.confirm_reboot_jetson }))) {
      dataProvider?.softRebootJetson(id).then(() => {
        alert(intl.formatMessage({ id: LOCALIZATION.reboot_disclaimer }))
      }).catch(error => {
        ErrorMessageHelper.handleError(generateUserError(error))
      })
    }
  }

  const handleShutdown = () => {
    if (window.confirm(intl.formatMessage({ id: LOCALIZATION.confirm_shut_down_jetson }))) {
      dataProvider?.softShutDownJetson(id).then(() => {
        alert(intl.formatMessage({ id: LOCALIZATION.shut_down_disclaimer }))
      }).catch(error => {
        ErrorMessageHelper.handleError(generateUserError(error))
      })
    }
  }

  const handleTurnOn = () => {
    if (window.confirm(intl.formatMessage({ id: LOCALIZATION.confirm_turn_on_jetson }))) {
      dataProvider?.turnOnJetson(id).then(() => {
        alert(intl.formatMessage({ id: LOCALIZATION.turn_on_disclaimer }))
      }).catch(error => {
        ErrorMessageHelper.handleError(generateUserError(error))
      })
    }
  }

  const handleFirmwareUpdate = () => {
    if (isOn) {
      alert(intl.formatMessage({ id: LOCALIZATION.turn_odd_to_start_firmware_update }))
    } else {
      if (window.confirm(intl.formatMessage({ id: LOCALIZATION.confirm_start_firmware_update_jetson }))) {
        dataProvider?.startFirmwareUpdate(id).then(() => {
          alert(intl.formatMessage({ id: LOCALIZATION.firmware_update_disclaimer }))
        }).catch(error => {
          ErrorMessageHelper.handleError(generateUserError(error))
        })
      }
    }
  }

  // const handleSSHConsole = () => {
  //   ErrorMessageHelper.handleError(Error(intl.formatMessage({ id: LOCALIZATION.not_implemented })))
  // }

  return !isCameraOn
    ? (<></>)
    : (
      <Grid container spacing={ 1 } justifyContent="flex-end">
        { isAvailableSoftShutdown && (
          <Grid item key="shutdown">
            <MainButton onClicked={ handleShutdown } title={ intl.formatMessage({ id: LOCALIZATION.shut_down }) }/>
          </Grid>
        ) }
        { isAvailableTurnOn && (
          <Grid item key="turnon">
            <MainButton onClicked={ handleTurnOn } title={ intl.formatMessage({ id: LOCALIZATION.turn_on }) }/>
          </Grid>
        ) }
        { isAvailableSoftShutdown && (
          <Grid item key="reboot">
            <MainButton buttonType={ ButtonType.Outline } onClicked={ handleReboot } title={ intl.formatMessage({ id: LOCALIZATION.reboot }) }/>
          </Grid>
        ) }
        { isAvailableFirmwareUpdate && isUserRoleMinimum(user?.role, UserRole.SuperAdmin) && (
          <Grid item key="startupdatefirmware">
            <MainButton buttonType={ ButtonType.Outline } onClicked={ handleFirmwareUpdate }
                   title={ intl.formatMessage({ id: LOCALIZATION.start_firmware_update }) }/>
          </Grid>
        ) }
        {/* { isAvailableSSHConsole && (
          <Grid item key="sshconsole">
            <MainButton buttonType={ ButtonType.Outline } onClicked={ handleSSHConsole } title={ intl.formatMessage({ id: LOCALIZATION.ssh_console }) }/>
          </Grid>
        ) } */}
      </Grid>
    )
}

type Props = Readonly<{
  id: JetsonId
  settings?: CameraSettingsInterface
}>;

export default function JetsonCard(props: Props) {
  const id = props.id
  const classes = useStyles()

  if (props.settings) {
    if ((id === JetsonNames.Jetson1) && !isCameraSettingsOptionActive(props.settings?.activeOptions?.haveJ1)) {
      return null;
    }
    if ((id === JetsonNames.Jetson2) && !isCameraSettingsOptionActive(props.settings?.activeOptions?.haveJ2)) {
      return null;
    }
  }

  const havePwm = !((props.settings) && !isCameraSettingsOptionActive(props.settings?.activeOptions?.haveFanHeaterPwm));

  return (
    <MainCard>
      <CardHeader id={ id }/>
      <MainCardContent>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <div className={ classes.chart }>
              <JetsonChartView id={ id }/>
            </div>
          </Grid>
          { (havePwm) &&
            <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
              <FanSection id={ id }/>
            </Grid>
          }
          { (havePwm) &&
            <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
              <HardwareButtonsSection id={ id }/>
            </Grid>
          }
          <Grid item xs={ 12 }>
            <ButtonsCollection id={ id }/>
          </Grid>
        </Grid>
      </MainCardContent>
    </MainCard>
  )
}
