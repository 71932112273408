// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';

export default function JsonEditor(props: {value: any, onChanged: (value: any) => void, onWrong?: () => void }) {
    const stringify = (json: any): string => {
        if (typeof json === 'string' || json instanceof String) {
            return json.toString()
        }
        else {
            return JSON.stringify(json, null, ' ')
        }
    }

    const input = stringify(props.value)

    const handleJsonChange = (json: any) => {
        props.onChanged(json)
    }

    return (
        <CodeMirror
        value={input}
        // height="200px"
        extensions={[javascript({ jsx: true })]}
        onChange={(value, viewUpdate) => {
            handleJsonChange(value)
            // try {
            //     const json = JSON.parse(value)
            //     handleJsonChange(json)
            // } catch (error) {
            //     if (props.onWrong) {
            //         props.onWrong()
            //     }
            //     // console.log('JsonEditor json parsing error: ' + error)
            // }
        }}
        />
    )
}
