// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import UserStorageService from './UserStorageService'
import CameraContext from '../Camera/HWCameraSettings/fetch/Context'
import AppStorageService from './App/Storage'
import AuthorisationApiService from './Networking'
import SessionStorageService from './Session/Storage'
import { SessionInterface, AppInterface } from './Types'
import { generateUserError } from '../_Networking/AxiosFetch/Errors/Tools'
import { MetricsChartConfigurationHelper } from '../../Components/_ChartsAndBars/Components/Configurator/Storage'
import { UserDataChangeSubscriber } from './UserStorageService'
import ApiError from '../_Networking/AxiosFetch/Errors/Types'
import User from '../AccountUsers/User';
import { AppLocale } from '../../Localization/AppContextProvider/helpers';


export default class AuthorisationManager {
    private sessionStorageService: SessionStorageService = new SessionStorageService()
    private appStorageService: AppStorageService = new AppStorageService()
    private authorisationApiSerivce = new AuthorisationApiService()
    private userStorageService = new UserStorageService()

    constructor() {
        if (this.isAuthorised()) {
            // update current user after restart and some delay
            setTimeout(() => {
                this.fetchCurrentUser(user => {
                }, (error, apiError) => {
                })
            }, 1000)
        }
    }

    static shared = new AuthorisationManager()

    isAuthorised(): boolean {
        return this.sessionStorageService.session !== undefined
    }

    get session(): SessionInterface | undefined {
        return this.sessionStorageService.session
    }

    get accessToken(): string | undefined {
        return this.session?.accessToken
    }

    set accessToken(newAccessToken: string | undefined) {
        const session = this.session
        if (session && newAccessToken) {
            session.accessToken = newAccessToken
            this.sessionStorageService.session = session
        }
    }

    get app(): AppInterface | undefined {
        return this.appStorageService.app
    }

    reset(): void {
        // console.log('AuthorisationManager > reset')
        this.sessionStorageService.reset()
        this.userStorageService.reset()
    }

    logoutLocally(): void {
        this.reset()
        // При логауте сотрем текущий контекст для доступа данным к камере
        CameraContext.reset()

        // Нужно очистить конфигурации графикоф
        MetricsChartConfigurationHelper.reset()
    }

    logout(completion: () => void): void {
        const complete = () => {
            this.logoutLocally()
            completion()
        }

        this.authorisationApiSerivce.logout(complete, error => {
            // console.log('Authorisation Manager > logout failed: ' + error)
            // Даже если пришла ошибка, удалим сессию. Пользователь не ожидает, что можно не выйти.
            complete()
        })
    }

    fetchCurrentUser(succeeded: (user: User) => void,
        failed: (error: Error, apiError: ApiError) => void) {
        new AuthorisationApiService().fetchCurrentUser(user => {
            this.userStorageService.user = user
            succeeded(user)
        }, error => failed(generateUserError(error), error))
    }

    signIn(username: string, password: string,
        succeeded: (user: User) => void,
        failed: (error: Error, apiError: ApiError) => void) {
        new AuthorisationApiService().signIn(username, password, authorisation => {
            this.sessionStorageService.session = authorisation
            const user = authorisation.user
            this.userStorageService.user = user
            succeeded(user)
        }, error => failed(generateUserError(error), error))
    }

    createAppIfNeeded(locale: AppLocale, succeeded: (user: AppInterface) => void,
                      failed: (error: Error) => void) {
        const app = this.app

        if (!app) {
            new AuthorisationApiService().createApp(locale, app => {
                this.appStorageService.app = app
                succeeded(app)
            }, error => failed(generateUserError(error)))
        } else {
            succeeded(app)
        }
    }


    // MARK: User
    get user(): User | undefined {
        return this.userStorageService.user
    }
    set user(newUser: User | undefined) {
        this.userStorageService.user = newUser
    }

    subscribeOnUserChanges(subscriber: UserDataChangeSubscriber): void {
        this.userStorageService.subscribeOnChanges(subscriber)
    }

    unsubscribeFromUserChanges(subscriber: UserDataChangeSubscriber): void {
        this.userStorageService.unsubscribeFromChanges(subscriber)
    }
}
