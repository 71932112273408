// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { DestType } from '../../NapiCameraConfig/Dest';
import { StreamType } from '../../NapiStreamingProfileOptions';
import Team from '../Team/Team';

export enum SportType {
  Football = 'Football', 
  Hockey = 'Hockey',
  Basketball = 'Basketball',
  HockeyWithBall = 'Hockey With Ball', 
  Handball = 'Handball', 
  MiniFootball = 'Mini Football', 
  MultipleSports = 'Multiple Sports', 
  Unknown = 'Unknown',
}

export enum GameType {
  // Disable = 'disable',
  // Camera = 'camera',
  Game = 'game',
  Train = 'train',
  FreeTime = 'freeTime',
}

export enum GameRepeatType {
  NoRepeat = 'noRepeat',
  EveryWeek = 'everyWeek',
}

export enum EventPriority {
  Low = 'low',
  Imported = 'imported',
  Base = 'base',
  High = 'high',
  Critical = 'critical',
}

export const minPriorityForNonAdmin = EventPriority.Imported
export const priorityFilterForNonAdmin = `>=${minPriorityForNonAdmin}` as EventPriority // >= used to define minimal priority to show for user

export enum WeekDay {
  Mo = 'mo',
  Tu = 'tu',
  We = 'we',
  Th = 'th',
  Fr = 'fr',
  Sa = 'sa',
  Su = 'su',
}

export const weekDaysMondayToFidayArray = [WeekDay.Mo, WeekDay.Tu, WeekDay.We, WeekDay.Th, WeekDay.Fr]
const weekDaysMondayToFidayString = weekDaysMondayToFidayArray.join(",")

export const isWeekDaysMondayToFiday = (repeatDays?: WeekDay[]) : boolean => {
  if (!repeatDays) {
    return false;
  }
  return repeatDays.join(",") === weekDaysMondayToFidayString
}

export type EventDestStreamOverrideItem = {
  orderNumber: number; // order number in original dest arr. used to name 
  uiOrderNumber?: number; // order number in original dest arr. used to name 
  type: DestType;
  id: StreamType;
  key?: string; // unique uuid for this block
  url?: string;// only for type = RTMP. rtmp URL to store stream. can have key or not. urlKey is automatically add to this URL if defined
  urlKey?: string;// only for type = RTMP. key for RTMP url.  urlKey is automatically add to url if defined
};

export type EventDestStreamOverride = {
  profileId?: string; // profile Id override
  overrideItems?: EventDestStreamOverrideItem[]
};
export type EventExtraJson = {
};

type EventWithoutID = Readonly<{
  name: string;
  subTitle: string | null;
  description: string | null;
  priority: EventPriority;
  gameType: GameType;
  sportType: string;
  homeTeamID: Team['id'] | null;
  guestTeamID: Team['id'] | null;
  startDate: string;//2022.12.08
  startTime: string;//12:10
  endDate: string | null;
  endTime: string;//15:00
  repeatType: GameRepeatType;
  repeatDays: WeekDay[];
  streamProfileID: string | null;
  streamOverride: EventDestStreamOverride | undefined | null;
  immediateMarkupPromocode: string | null;
  extraJson: EventExtraJson | undefined | null;
  disabled: boolean;
  deleted?: number | null;
}>;


export default EventWithoutID;
