/** RGB colors in 0..255 */
type RGBInt = {
    r: number;
    g: number;
    b: number;
}

/** Convert string "#FF00FF" to RGB{255,0,255} */
export function hexToRgb(hex: string): RGBInt | null {
    if (!hex) {
        return null;
    }
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2}).*$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

export function colorHexDeltaE(rgbA: string, rgbB: string): number {
    return colorRgbDeltaE(hexToRgb(rgbA), hexToRgb(rgbB));
}

/** 
 * Delta E - Color difference as number. http://zschuessler.github.io/DeltaE/  
 * <= 1.0  : Not perceptible by human eyes. 
 * 1-2     : Perceptible through close observation.
 * 2-10    : Perceptible at a glance.
 * 11-49   : Colors are more similar than opposite
 * 100     : Colors are exact opposite
*/
export function colorRgbDeltaE(rgbA: RGBInt | null, rgbB: RGBInt | null): number {
    if (!rgbA || !rgbB) {
        return 100;
    }
    let labA = rgb2lab(rgbA);
    let labB = rgb2lab(rgbB);
    let deltaL = labA[0] - labB[0];
    let deltaA = labA[1] - labB[1];
    let deltaB = labA[2] - labB[2];
    let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
    let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
    let deltaC = c1 - c2;
    let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
    let sc = 1.0 + 0.045 * c1;
    let sh = 1.0 + 0.015 * c1;
    let deltaLKlsl = deltaL / (1.0);
    let deltaCkcsc = deltaC / (sc);
    let deltaHkhsh = deltaH / (sh);
    let i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
    return i < 0 ? 0 : Math.sqrt(i);
}
  
function rgb2lab(rgb: RGBInt): number[]{
    let r = rgb.r / 255, g = rgb.g / 255, b = rgb.b / 255, x, y, z;
    r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
    x = (x > 0.008856) ? Math.pow(x, 1/3) : (7.787 * x) + 16/116;
    y = (y > 0.008856) ? Math.pow(y, 1/3) : (7.787 * y) + 16/116;
    z = (z > 0.008856) ? Math.pow(z, 1/3) : (7.787 * z) + 16/116;
    return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
}


export function findNearestColorInPalette(paletteHex: string[] | undefined, colorHex: string | undefined, butNotColorHex?: string): string | undefined {
    if (!paletteHex?.length || !colorHex?.length) {
      return undefined;
    }
      let bestColor: string | undefined = undefined;
      let bestColorDeltaE: number | undefined = undefined;
  
      paletteHex.forEach((paletteColor, index) => {
  
        const deltaE = colorHexDeltaE(paletteColor, colorHex);
  
        if ((bestColorDeltaE === undefined || (bestColorDeltaE > deltaE)) && (paletteColor !== butNotColorHex)) {
          bestColor = paletteColor;
          bestColorDeltaE = deltaE;
        }
      })
      return bestColor;
}