// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import fetchJSONData from '../../_Networking/ReactQuery/fetch';
import { NODE_JS_BACKEND_URL } from '../../../constants';
import { queryCache, queryClient } from '../../../AppProviders';
import ResponseSingle from '../../_Networking/ReactQuery/ResponseSingle';
import CameraConfig from '..';


const PREFIX_OF_A_COMPOSITE_KEY = 'streaming-settings-default-profile-config';

const getKey = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const flushStreamingSettingsDefaultProfileConfigCache = () => {
  const queries = queryCache.findAll(getKey());

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};

const getStreamingSettingsDefaultProfileConfigCached = () => (
  queryClient.getQueryData<ResponseSingle<CameraConfig>>(getKey())
);

const fetchData = async (): Promise<ResponseSingle<CameraConfig>> => {
  const cacheData = getStreamingSettingsDefaultProfileConfigCached();

  if (cacheData) { return cacheData; }

  return await fetchJSONData<ResponseSingle<CameraConfig>>(
    `${NODE_JS_BACKEND_URL}/streaming/settings/default-profile-config.json`,
    {
      method: 'GET',
    }
  );
};

export {
  getKey,
  getStreamingSettingsDefaultProfileConfigCached,
  flushStreamingSettingsDefaultProfileConfigCache,
  PREFIX_OF_A_COMPOSITE_KEY,
  fetchData,
};
