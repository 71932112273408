// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

export namespace Sizes {
    export const elevation: number = 3

    export const analyticsScore: number = 30

    export const caption: number = 24
    export const heading2: number = 18
    export const heading: number = 16
    export const title: number = 14
    export const titleFiled: number = 15
    // export const input: number = 16 // using this won't scale page on iPhone when focus
    export const input: number = 14
    export const subtitle: number = 13
    export const small: number = 12
    export const action: number = 13
    export const extraSmall: number = 11

    export const mediumWeight: number = 600
    export const boldWeight: number = 900
    export const regularWeight = 400
}
