// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import { FieldSetInputType } from '.';


export type FieldSetOther = Readonly<{
  type: FieldSetInputType.Other;
  children: any;
}>;

type Props = Readonly<{
  input: FieldSetOther;
}>;


const FieldSetOtherComponent: React.FC<Props> = (props: Props) => (
  props.input.children
);


export default FieldSetOtherComponent;
