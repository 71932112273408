// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import LOCALIZATION from "../../../Localization";
import { intl } from "../../../Localization/LocalizationProvider";
import { AnalyticsTimeIntervalType, AnalyticsType, analyticsTimeIntervalName } from "../AnalyticsTypes";


export enum AnalyticsGameEventType {
    GameStart = 'game_start',
    GameEnd = 'game_end',
    TimeStart = 'time_start',
    TimeEnd = 'time_end',
    TimeOutStart = 'timeout_start',
    TimeOutEnd = 'timeout_end',
    Goal = 'goal',
    OvertimeStart = 'overtime_start',
    OvertimeEnd = 'overtime_end',
    PenaltyStart = 'penalty_start',
    PenaltyEnd = 'penalty_end',
}

export enum AnalyticsGameEventExtraType {
    Penalty = 'penalty',
}

export enum AnalyticsTeamSide {
    Left = 'left',
    Right = 'right',
}

export type AnalyticsGameEvent = Readonly<{
    id?: number | string; // may be added by client if need
    type?: AnalyticsType;
    event_type?: AnalyticsGameEventType;
    timestamp?: string; //"00:00:00" - when event attear in video recod. video record contain several games
    title?: string;// optional name for debug

    team1_side?: AnalyticsTeamSide;// used for start events: time_start,  game_start, overtime_start
    team2_side?: AnalyticsTeamSide;// used for start events: time_start,  game_start, overtime_start

    team1_score?: number;// used for end events: time_end,  game_end, overtime_end,  penalty_end
    team2_score?: number;// used for end events:time_end,  game_end, overtime_end,  penalty_end

    extra_type?: AnalyticsGameEventExtraType;// if event_type==Goal has extra_type
}>;


export type AnalyticsGameEventWithId = Readonly<{
    id: number;// used inside cliend code
} & AnalyticsGameEvent>;

export const analyticsEventTypeHasTeamSide = (eventType: AnalyticsGameEventType | undefined, events: AnalyticsGameEvent[] | undefined): boolean => {
    switch (eventType) {
        case AnalyticsGameEventType.GameStart :{
            const otherEventsWithSideCount = events?.filter((event) => (
                event.event_type === AnalyticsGameEventType.TimeStart ||
                event.event_type === AnalyticsGameEventType.OvertimeStart ||
                event.event_type === AnalyticsGameEventType.PenaltyStart
            ))?.length || 0
            return !otherEventsWithSideCount
        }
        case AnalyticsGameEventType.TimeStart :
        case AnalyticsGameEventType.OvertimeStart :
        {
            return true;
        }
    }
    return false;
}

export const analyticsEventTypeGetEndType = (eventType: AnalyticsGameEventType | undefined): AnalyticsGameEventType | undefined => {
    switch (eventType) {
        case AnalyticsGameEventType.GameStart :{
            return AnalyticsGameEventType.GameEnd
        }
        case AnalyticsGameEventType.TimeStart :{
            return AnalyticsGameEventType.TimeEnd
        }
        case AnalyticsGameEventType.TimeOutStart :{
            return AnalyticsGameEventType.TimeOutEnd
        }
        case AnalyticsGameEventType.OvertimeStart : {
            return AnalyticsGameEventType.OvertimeEnd
        }
        case AnalyticsGameEventType.PenaltyStart : {
            return AnalyticsGameEventType.PenaltyEnd
        }
    }
    return undefined;
}

export const analyticsCanAddEventInsideEvent = (parentEventType: AnalyticsGameEventType | undefined, insertedEventType: AnalyticsGameEventType | undefined): boolean => {
    if (parentEventType === undefined || insertedEventType === undefined) {
        return false
    }

    switch (parentEventType) {
        case AnalyticsGameEventType.GameStart :{
            return true
        }
        case AnalyticsGameEventType.TimeOutEnd :
        case AnalyticsGameEventType.OvertimeEnd :
        case AnalyticsGameEventType.PenaltyEnd :
        case AnalyticsGameEventType.TimeEnd : {
            return true
        }

        case AnalyticsGameEventType.PenaltyStart :
        case AnalyticsGameEventType.OvertimeStart :
        case AnalyticsGameEventType.TimeStart :{
            return [AnalyticsGameEventType.TimeOutStart, AnalyticsGameEventType.TimeOutEnd, 
                    AnalyticsGameEventType.Goal, 
                    ].includes(insertedEventType)
        }
    }
    return false;
}

export const analyticsEventTypeHasScore = (eventType: AnalyticsGameEventType | undefined): boolean => {
    switch (eventType) {
        case AnalyticsGameEventType.GameEnd :
        case AnalyticsGameEventType.TimeEnd :
        case AnalyticsGameEventType.OvertimeEnd :
        case AnalyticsGameEventType.PenaltyEnd :
        case AnalyticsGameEventType.Goal :
        {
            return true;
        }
    }
    return false;
}

export const localizedAnalyticsTeamSide = (side: AnalyticsTeamSide | undefined): string => {
    switch (side) {
      case AnalyticsTeamSide.Left:
        return intl().formatMessage({ id: LOCALIZATION.analytics_team_side_left }) || '';
      case AnalyticsTeamSide.Right:
        return intl().formatMessage({ id: LOCALIZATION.analytics_team_side_right }) || '';
    }
    return side || 'Unknown'
}

export const analyticsTeamSideOpposit = (side: AnalyticsTeamSide | undefined): AnalyticsTeamSide => {
    switch (side) {
      case AnalyticsTeamSide.Left:
        return AnalyticsTeamSide.Right;
      case AnalyticsTeamSide.Right:
        return AnalyticsTeamSide.Left;
    }
    return AnalyticsTeamSide.Left
}

export type AnalyticsTeamBySide = Readonly<{
    leftTeamValue?: string;
    rightTeamValue?: string;
}>;

export const analyticsTeamValueBySide = (team1_side: AnalyticsTeamSide | undefined,team2_side: AnalyticsTeamSide | undefined,team1Value: string | undefined,team2Value: string | undefined): AnalyticsTeamBySide => {
    let leftTeamValue, rightTeamValue;
    switch (team1_side) {
        case AnalyticsTeamSide.Left:
            leftTeamValue = team1Value; break;
        case AnalyticsTeamSide.Right:
            rightTeamValue = team1Value; break;
    }
    switch (team2_side) {
        case AnalyticsTeamSide.Left:
            leftTeamValue = team2Value; break;
        case AnalyticsTeamSide.Right:
            rightTeamValue = team2Value; break;
    }
    return {
        leftTeamValue: leftTeamValue,
        rightTeamValue: rightTeamValue,
    }
}

export const analyticsFindEventWithScoreForEvent = (event: AnalyticsGameEventWithId | undefined, events: AnalyticsGameEventWithId[] | undefined, canReturnSelfEvent = true): AnalyticsGameEventWithId | undefined => {
    if (!event || !events) {
        return undefined;
    }
    if (canReturnSelfEvent && event.team1_score !== undefined && event.team2_score !== undefined) {
        return event;
    }

    let lastFoundEvent: AnalyticsGameEventWithId | undefined = undefined;

    for(const item of events) {
        if (item.id === event.id) {
            break;
        }
        if (item.team1_score !== undefined && item.team2_score !== undefined) {
            lastFoundEvent = item;
        }
    }
    return lastFoundEvent;
}

export const analyticsFindEventWithTeamSideForEvent = (event: AnalyticsGameEventWithId | undefined, events: AnalyticsGameEventWithId[] | undefined): AnalyticsGameEventWithId | undefined => {
    if (!event || !events) {
        return undefined;
    }
    if (event.team1_side !== undefined && event.team1_side !== undefined) {
        return event;
    }

    let lastFoundEvent: AnalyticsGameEventWithId | undefined = undefined;

    for(const item of events) {
        if (item.id === event.id) {
            break;
        }
        if (item.team1_side !== undefined && item.team1_side !== undefined) {
            lastFoundEvent = item;
        }
    }
    return lastFoundEvent;
}

export const localizedAnalyticsGameEventType = (type: AnalyticsGameEventType | undefined, number?: number): string => {
    const orderNum = (number === undefined) ? undefined : number - 1;// bacause orderNum start from 0
    switch (type) {
        case AnalyticsGameEventType.GameStart:
            return intl().formatMessage({ id: LOCALIZATION.game_event_game_start }) || '';
        case AnalyticsGameEventType.GameEnd:
            return intl().formatMessage({ id: LOCALIZATION.game_event_game_end }) || '';
        case AnalyticsGameEventType.TimeStart:
            return analyticsTimeIntervalName(AnalyticsTimeIntervalType.time, orderNum);
        case AnalyticsGameEventType.TimeEnd:
            return `${analyticsTimeIntervalName(AnalyticsTimeIntervalType.time, orderNum)}${intl().formatMessage({ id: LOCALIZATION.game_event_end_postfix })}` 
        case AnalyticsGameEventType.TimeOutStart:
            return intl().formatMessage({ id: LOCALIZATION.game_event_timeout_start }) || '';
        case AnalyticsGameEventType.TimeOutEnd:
            return intl().formatMessage({ id: LOCALIZATION.game_event_timeout_end }) || '';
        case AnalyticsGameEventType.Goal:
            return intl().formatMessage({ id: LOCALIZATION.game_event_goal }) || '';
        case AnalyticsGameEventType.OvertimeStart:
            return analyticsTimeIntervalName(AnalyticsTimeIntervalType.overtime, orderNum);
        case AnalyticsGameEventType.OvertimeEnd:
            return `${analyticsTimeIntervalName(AnalyticsTimeIntervalType.overtime, orderNum)}${intl().formatMessage({ id: LOCALIZATION.game_event_end_postfix })}` 
        case AnalyticsGameEventType.PenaltyStart:
            return analyticsTimeIntervalName(AnalyticsTimeIntervalType.penalty, orderNum);
        case AnalyticsGameEventType.PenaltyEnd:
            return `${analyticsTimeIntervalName(AnalyticsTimeIntervalType.penalty, orderNum)}${intl().formatMessage({ id: LOCALIZATION.game_event_end_postfix })}` 
    }
    return type || 'Unknown'
}
