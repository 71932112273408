// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Box, Grid } from '@mui/material';
import { injectIntl } from 'react-intl';
import { WrappedComponentProps } from 'react-intl';

import Card from '../../_Layout/Card';
import Popup from '../../_Layout/Popup';
import Spinner from '../../_BaseUI/Spinner/Spinner';
import FieldSet, { FieldSetInputType } from '../../_BaseUI/FieldSet';
import LOCALIZATION from '../../../Localization';
import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Colors } from '../../../Configuration/Styles/Colors';

export type EditTitleFields = Readonly<{
  title?: string;
}>;

const DEFAULT_VALUE: EditTitleFields = {
  title: '',
};

type Props = Readonly<{
  popupTitle?: string;
  titleLabel?: string;
  titleHint?: string;
  item?: EditTitleFields;
  // page: number;
  onSubmit: (editedItem: EditTitleFields) => void;
  onClose: () => void;
} & WrappedComponentProps>;

const EditTitlePopup: React.FC<Props> = (props: Props) => {
  const [item, setItem] = React.useState<EditTitleFields>(props.item || DEFAULT_VALUE);
  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);

  const isApplyDisabled = !item || !item!.title?.length || (/\s+/.test(item!.title));
  const handleSubmit = () => {
    if (!isApplyDisabled) {
      setIsLoaderShowing(true);
      props.onSubmit(item);
    }
  };

  return (
    <>
      <Popup
        isWide
        headerTitle={ props.popupTitle || props.intl.formatMessage({ id: LOCALIZATION.edit }) }
        applyTitle={ props.intl.formatMessage({ id: LOCALIZATION.save }) }
        isApplyDisabled={ (isApplyDisabled || isLoaderShowing) }
        isCloseDisabled={ isLoaderShowing }
        onApply={ handleSubmit }
        onClose={ props.onClose }
      >
        <Grid
          item
          xs={ 12 }
        >
          <Card>
            {
              (isLoaderShowing) ?
              <>
              <br /><br /><Spinner /><br /><br />
              </>
                :
                <form
                  action={ window.location.href }
                  onSubmit={ handleSubmit }
                >
                { 
                  (!!props.titleHint?.length) &&
                    <Box
                      sx={ {
                        fontFamily: Fonts.main,
                        fontSize: Sizes.title,
                        fontWeight: Sizes.regularWeight,
                        color: Colors.black,
                      } }
                    >
                      { props.titleHint }
                    </Box>
                  }
                  <FieldSet
                    key={ 'title' }
                    label={ props.titleLabel || props.intl.formatMessage({ id: LOCALIZATION.event_name }) }
                    input={ {
                      type: FieldSetInputType.Text,
                      value: item.title || "",
                      onChange: (title: string) => ( setItem({ ...item, title, }) ),
                    } }
                  />
                </form>
            }
          </Card>
        </Grid>
      </Popup>
    </>
  );
};


export default injectIntl(EditTitlePopup);
