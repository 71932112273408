// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useEffect, useState } from 'react'
import { ErrorMessageHelper } from './ErrorMessageHelper'

export function useRecentError(): Error | undefined {
    const [error, setError] = useState<Error | undefined >(undefined)

    useEffect(() => {
        function handleErrorChange(error?: Error) {
            setError(error)
        }

        ErrorMessageHelper.subscribeToErrorChange(handleErrorChange)
        return () => {
            ErrorMessageHelper.unsubscribeFromErrorChange(handleErrorChange)
        }
    })

    return error
}
