// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

const enumToObject = (enumeration: any): { [key in string]: string } => {
  try {
    const result = {};

    for (let enumMember in enumeration) {
      // @ts-ignore
      result[enumMember] = enumeration[enumMember];
    }

    return result;
  } catch (ignore) {
    // console.log('ignore:', ignore);
    // ignore
  }

  return {};
};


export default enumToObject;
