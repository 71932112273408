// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

/* eslint-disable camelcase */
import { JetsonId } from '../Jetson/Types'
import { UnixDate } from '../../../Tools/UnixDate'
import { intl } from '../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../Localization';

export type MetricName =
'Temperature' |
'DewPoint' |
'Pressure' |
'Altitude' |
'Humidity' |
'Voltage' |
'Accelerometer' |
'Gyroscope' |
'Magnetometer' |
'Volume' |
'CpuUtilization' |
'GpuUtilization' |
'Power' |
'Heading' |
'Pitch' |
'Roll' |
'Yaw'

export type SensorName =
'BME280' |
'DS18B20' |
'MCP3008' |
'INA219' |
'MPU9250' |
'linuxCPUTemp' |
'linuxGPUTemp' |
'linuxBoardTemp' |
'linuxCPUStat' |
'jetsonPowerTotal' |
'jetsonCV' |
'jetsonVDDRQ' |
'jetsonSYS5V' |
'jetsonGPU' |
'jetsonCPU' |
'jetsonSOC' |
'/mnt/ssd' |
'/'


export enum TemperatureControllerID {
    Jetson1FAN = "jetson1_fan",
    Jetson2FAN = "jetson2_fan",
    Heater = "heater",
    Heater1 = "heater1",
    Heater2 = "heater2",
    Heater3 = "heater3",
    Heater4 = "heater4",
    HeaterCAM1 = "heater_cam1",
    HeaterCAM2 = "heater_cam2",
    HeaterCAM3 = "heater_cam3",
}

export enum SensorPlaceID {
    Outdoor = "outdoor",
    CameraCase = "cameraCase",
    DC = "DC",
    Pi = "pi",
}

export type TemperatureControllerIDType = keyof typeof TemperatureControllerID;

export const HeaterIdAll = [
    TemperatureControllerID.Heater, 
    TemperatureControllerID.Heater1, 
    TemperatureControllerID.Heater2, 
    TemperatureControllerID.Heater3, 
    TemperatureControllerID.Heater4, 
    TemperatureControllerID.HeaterCAM1, 
    TemperatureControllerID.HeaterCAM2, 
    TemperatureControllerID.HeaterCAM3,
] as const

export type HeaterId = typeof HeaterIdAll[number]

export const HeaterIdAllSelf = HeaterIdAll as unknown as HeaterId[]

export type SensorPlace =
SensorPlaceID.Outdoor |
SensorPlaceID.CameraCase |
SensorPlaceID.DC |
HeaterId |
JetsonId |
SensorPlaceID.Pi

export interface MetricInterface {
    name: MetricName
    sensorPlace: SensorPlace
    sensorName: SensorName
    whenCreated: UnixDate
    sampleTime: number // i.e whenCreated / 6000 calculate on backend
}

export interface MetricDoubleInterface extends MetricInterface {
    value: number
}

export interface MetricDouble3DInterface extends MetricInterface {
    x: number
    y: number
    z: number
}

export interface MetricOutputPeriodInterface {
    readonly displayName: string
    readonly timeInterval: number
}

export const MetricPeriodHour: MetricOutputPeriodInterface = {
    get displayName(): string { return intl().formatMessage({ id: LOCALIZATION.hour }) },
    timeInterval: 60 * 60 * 1 * 1000
}

export const MetricPeriod2Hours: MetricOutputPeriodInterface = {
    get displayName(): string { return intl().formatMessage({ id: LOCALIZATION.twoHours }) },
    timeInterval: 60 * 60 * 2 * 1000
}

export const MetricPeriod4Hours: MetricOutputPeriodInterface = {
    get displayName(): string { return intl().formatMessage({ id: LOCALIZATION.fourHours }) },
    timeInterval: 60 * 60 * 4 * 1000
}

export const MetricPeriod8Hours: MetricOutputPeriodInterface = {
    get displayName(): string { return intl().formatMessage({ id: LOCALIZATION.eightHours }) },
    timeInterval: 60 * 60 * 8 * 1000
}

export const MetricPeriodHalfDay: MetricOutputPeriodInterface = {
    get displayName(): string { return intl().formatMessage({ id: LOCALIZATION.halfDay }) },
    timeInterval: MetricPeriodHour.timeInterval * 12
}

export const MetricPeriodDay: MetricOutputPeriodInterface = {
    get displayName(): string { return intl().formatMessage({ id: LOCALIZATION.day }) },
    timeInterval: MetricPeriodHour.timeInterval * 24
}

export const MetricOutputPeriodAll: MetricOutputPeriodInterface[] = [MetricPeriodHour, MetricPeriod2Hours, MetricPeriod4Hours, MetricPeriod8Hours, MetricPeriodHalfDay, MetricPeriodDay]

export interface MetricOutputTimeInterface {
    readonly displayName: string
    readonly timeInterval: number
}

export const MetricOutputTimeNow: MetricOutputTimeInterface = {
    timeInterval: 0,
    get displayName(): string { return intl().formatMessage({ id: LOCALIZATION.now }) }
}

export const MetricOutputTimeBack1Hour: MetricOutputTimeInterface = {
    displayName: `-${MetricPeriodHour.displayName}`,
    timeInterval: MetricPeriodHour.timeInterval
}

export const MetricOutputTimeBack2Hours: MetricOutputTimeInterface = {
    displayName: `-${MetricPeriod2Hours.displayName}`,
    timeInterval: MetricPeriod2Hours.timeInterval
}

export const MetricOutputTimeBack4Hours: MetricOutputTimeInterface = {
    displayName: `-${MetricPeriod4Hours.displayName}`,
    timeInterval: MetricPeriod4Hours.timeInterval
}

export const MetricOutputTimeBack8Hours: MetricOutputTimeInterface = {
    displayName: `-${MetricPeriod8Hours.displayName}`,
    timeInterval: MetricPeriod8Hours.timeInterval
}

export const MetricOutputTimeBackHalfDay: MetricOutputTimeInterface = {
    displayName: `-${MetricPeriodHalfDay.displayName}`,
    timeInterval: MetricPeriodHalfDay.timeInterval
}

// Мы храним историю не более суток.

// export const MetricOutputTimeBackDay: MetricOutputTimeInterface = {
//     displayName: `-${MetricPeriodDay.displayName}`,
//     endDate: Date.now() - MetricPeriodDay.timeInterval
// }

export const MetricOutputTimeAll: MetricOutputTimeInterface[] = [MetricOutputTimeNow, MetricOutputTimeBack1Hour, MetricOutputTimeBack2Hours, MetricOutputTimeBack4Hours, MetricOutputTimeBack8Hours, MetricOutputTimeBackHalfDay]

export interface MetricsSourceInterface {
    readonly uniqueKey: MetricsDataProviderUniqueKey // уникальный ключ для каждого источника, который имлементирует данный интерфейс.
    readonly metrics: MetricTypeInterface[]
    readonly defaultSelected: MetricDataKey[]
}

export interface MetricsChartConfigurationInterface {
    outputPeriod: MetricOutputPeriodInterface
    outputTime: MetricOutputTimeInterface
}

// Для вывода
export interface MetricsChartSourceInterface extends MetricsSourceInterface, Readonly<MetricsChartConfigurationInterface> {

}

export interface MetricsValuesSourceInterface extends MetricsSourceInterface {

}

export interface MetricRequestSortParametersInterface {
    readonly sort?: MetricRequestSortField,
    readonly direction?: MetricRequestSortDirection
}

export interface MetricRequestIdentificationParametersInterface {
    readonly filter_name: MetricName,
    readonly filter_sensorName?: SensorName,
    readonly filter_sensorPlace?: SensorPlace
}

export interface MetricRequestRangeParametersInterface {
    readonly gt_whenCreated?: number,
    le_whenCreated?: number,
    readonly max: number
}

export interface MetricRequestParametersInterface extends MetricRequestSortParametersInterface, MetricRequestIdentificationParametersInterface, MetricRequestRangeParametersInterface {

}

export const allMetricDataKeys = [
    'piv', // Power Input Voltage
    'pcl', // Pi Cpu Load
    'pct', // Pi Cpu Temperature
    'j1ct', // Jetson 1 Cpu Temperature
    'j2ct', // Jetson 2 Cpu Temperature
    'j1cl', // Jetson 1 Cpu Load
    'j2cl', // Jetson 2 Cpu Load
    'j1gl', // Jetson 1 Gpu Load
    'j2gl', // Jetson 2 Gpu Load
    'j1bt', // Jetson 1 Board Temperature
    'j2bt', // Jetson 2 Board Temperature
    'j1gt', // Jetson 1 Gpu Temperature
    'j2gt', // Jetson 2 Gpu Temperature
    'j1rv', // Jetson 1 Root Volume
    'j2rv', // Jetson 2 Root Volume
    'j1sv', // Jetson 1 SSD Volume
    'j2sv', // Jetson 2 SSD Volume
    'j1tpc', // Jetson 1 Total Power Consumption
    'j2tpc', // Jetson 2Total Power Consumption
    'j1v', // Jetson 1 Voltage
    'j2v', // Jetson 2 Voltage
    'j1cpupc', // Jetson 1 CPU Power Consumption
    'j2cpupc', // Jetson 2 CPU Power Consumption
    'j1socpc', // Jetson 1 SOC Power Consumption
    'j2socpc', // Jetson 2 SOC Power Consumption
    'j1gpupc', // Jetson 1 GPU Power Consumption
    'j2gpupc', // Jetson 2 GPU Power Consumption
    'j1s5vpc', // Jetson 1 Sys5V Power Consumption
    'j2s5vpc', // Jetson 2 Sys5V Power Consumption
    'j1vddpc', // Jetson 1 VDDRQ Power Consumption
    'j2vddpc', // Jetson 2 VDDRQ Power Consumption
    'j1cvpc', // Jetson 1 CV Power Consumption
    'j2cvpc', // Jetson 2 CV Power Consumption
    'j1cpuv', // Jetson 1 Voltage
    'j2cpuv', // Jetson 2 Voltage
    'j1s5vv', // Jetson 1 Sys5V Voltage
    'j2s5vv', // Jetson 2 Sys5V Voltage
    'it', // Internal Temperature,
    'dp', // DewPoint Temperature,
    'ot', // Outdoor Temperature,
    'ih', // Internal Humidity
    'dct', // DC Temperature
    'cht', // Case Heater Temperature
    'cht1', // Case Heater 1 Temperature
    'cht2', // Case Heater 2 Temperature
    'cht3', // Case Heater 3 Temperature
    'cht4', // Case Heater 4 Temperature
    'chtc1', // Case Heater Cam1 Temperature
    'chtc2', // Case Heater Cam2 Temperature
    'chtc3', // Case Heater Cam3 Temperature
    'cta', // Camera tilt angle
    'caz' // Camera azymuth

] as const

export type MetricDataKey = typeof allMetricDataKeys[number]
export const MetricDataUnitAll = ['V', '%', '˚C', 'W', '˚'] as const
export type MetricDataUnit = typeof MetricDataUnitAll[number]

export function isMetricDataUnit(value?: any): boolean {
    if (value === undefined) return false
    return MetricDataUnitAll.includes(value)
}

export function toMetricDataUnitIfPossible(value?: any): MetricDataUnit | undefined {
    if (!isMetricDataUnit(value)) return undefined
    return value as MetricDataUnit
}

export type MetricRequestSortDirection = 'asc' | 'desc'

export type MetricRequestSortField = keyof MetricInterface

export interface MetricTypeInterface {
    readonly name: string
    readonly shortName: string
    readonly unit: MetricDataUnit
    readonly dataKey: MetricDataKey
    readonly color: string
    readonly dash?: string
    readonly identificationParameters: MetricRequestIdentificationParametersInterface
}

export interface MetricsDataItem {
    [key: string]: number // key динамически предаполагается быть MetricDataKey.
    date: UnixDate
}

export const dataKeyX: keyof MetricsDataItem = 'date'
export type MetricsValuesDataProviderUniqueKey = 'ValuesPowerInputVoltage' | 'ValuesHardwarePi' | 'ValuesHardwareJetson1' | 'ValuesHardwareJetson2' | 'ValuesHardwareStatus' | 'ValuesStorageJetson1' | 'ValuesStorageJetson2' | 'ValuesDirectionsTilt' | 'ValuesDirectionsAzymuth' | 'ValuesStatusStatistic' | 'ValuesHardwareStatistic'
export type MetricsChartDataProviderUniqueKey = 'ChartPowerInputVoltage' | 'ChartHardwarePi' | 'CharHardwareJetson1' | 'CharHardwareJetson2' | 'ChartStatusGeneralStatistics' | 'ChartHardwareStatistics'

export type MetricsDataProviderUniqueKey = MetricsChartDataProviderUniqueKey | MetricsValuesDataProviderUniqueKey
export type MetricDataChangeSubscriber = (data: MetricsDataItem[]) => void
