// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useLayoutEffect } from 'react'
import { Grid, Box } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import ErrorMessage from '../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage'
import GameStatBartLineChartCard from './GameStatBartLineChartCard'
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import AnalyticsGamesPageHeader from '../../Components/_Layout/AnalyticsGamesPageHeader';
import { useParams } from 'react-router-dom';
import useGamesPagedData from '../../Data/Analytics/AnalyticsGameArchive/hook';
import AnalyticsGameArchive from '../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import { getResponseManyDataOne } from '../../Data/_Networking/ReactQuery/ResponseMany';
import FieldSetSelectComponent from '../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import { FieldSetInputType } from '../../Components/_BaseUI/FieldSet';
import LOCALIZATION from '../../Localization';
import { ANALYTICS_GRANULARITY_ARR, AnalyticsStatGranularity, analyticsGranularityName } from '../../Data/Analytics/AnalyticsTypes';
import HorizGrid from '../../Components/_Layout/HorizGrid';
import { useAnalyticsStatScore } from '../../Data/Analytics/AnalyticsStatScore/hook';
import { useAnalyticsStatDistance } from '../../Data/Analytics/AnalyticsStatDistance/hook';
import { useAnalyticsStatSpeed } from '../../Data/Analytics/AnalyticsStatSpeed/hook';
import { useAnalyticsStatPossession } from '../../Data/Analytics/AnalyticsStatPossession/hook';
import { useAnalyticsStatAttackDefenseTime } from '../../Data/Analytics/AnalyticsStatAttackDefenseTime/hook';
import { createBarLineChartConfigForMainReportDetails } from '../../Components/_ChartsAndBars/AnalyticsHorizBarLineChartView/helper';
import { AnalyticsConvertedDataType, analyticsConvertAllData } from '../../Data/Analytics/AnalyticsConverters';
import useFetchAllPagesTeamRefs from '../../Data/Analytics/AnalyticsTeamRef/hook';
import { ResultStatus } from '../../Data/_Networking/ReactQuery/template';


const AnalyticsGameReportMainDetails: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const [granularity, setGranularity] = React.useState<AnalyticsStatGranularity>(AnalyticsStatGranularity.Granularity4);
  
  const { AnalyticsGameReport: gameId = "" } = useParams();
  
  const { status: fetchStatus, data: gameRecordsData } = useGamesPagedData({ itemId: gameId });
  const gameResultInfo: AnalyticsGameArchive | undefined = getResponseManyDataOne(gameRecordsData?.data)

  const { data: scoreStat } = useAnalyticsStatScore({ gameId, granularity });
  const { data: distanceStat } = useAnalyticsStatDistance({ gameId, granularity });
  const { data: speedStat } = useAnalyticsStatSpeed({ gameId, granularity });
  const { data: possessionStat } = useAnalyticsStatPossession({ gameId, granularity });
  const { data: attackDefenseTimeStat } = useAnalyticsStatAttackDefenseTime({ gameId, granularity });

  const {
    getTeamRefByExternalId,
    status: teamRefStatus,
  } = useFetchAllPagesTeamRefs();

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  if (fetchStatus === 'loading' || teamRefStatus === ResultStatus.Loading) {
    return (
      <>
      <br /><br /><Spinner /><br /><br />
      </>
    )
  }

  const teamName = (teamId: string | undefined) => {
    return getTeamRefByExternalId?.(teamId)?.name || teamId || ""
  }

  const team1Name = teamName(gameResultInfo?.team1);
  const team2Name = teamName(gameResultInfo?.team2);
  
  const {availableStats, data} = createBarLineChartConfigForMainReportDetails(
    props.intl,
    granularity,
    scoreStat?.time_intervals,
    possessionStat?.time_intervals,
    distanceStat?.time_intervals,
    speedStat?.time_intervals,
    attackDefenseTimeStat?.time_intervals,
  )

  const convertedData = analyticsConvertAllData(
    team1Name,
    team2Name,
    scoreStat?.game_stat,
    possessionStat?.game_stat,
    distanceStat?.game_stat,
    speedStat?.game_stat,
    attackDefenseTimeStat?.game_stat,
  )

  const granularityOptions = ANALYTICS_GRANULARITY_ARR.map((granularity) => {
    return ({
      id: granularity,
      name: analyticsGranularityName(granularity),
    });
  });

  const handleGranularityChange = (value: string) => {
    setGranularity(value as AnalyticsStatGranularity);
  };

  return (
    <Box> 
      <Grid 
      container
      spacing={ 2 }
      >
        <Grid item xs={ 12 }>
          <AnalyticsGamesPageHeader/>
          <ErrorMessage/>
        </Grid>
        <Grid item xs={ 12 }>
          <HorizGrid>
            <FieldSetSelectComponent
              input={ {
                type: FieldSetInputType.Select,
                label: props.intl.formatMessage({ id: LOCALIZATION.analytics_ganularity }),
                value: granularity || "none",
                sx: { minWidth: 200 },
                options: granularityOptions,
                onChange: handleGranularityChange,
              } }
            />
          </HorizGrid>
        </Grid>
        <Grid item xs={ 12 }>
          <Grid container spacing={ 2 }>
            {
              availableStats.map((item) => {
                // do not show defence chart but combine it with attach
                if (item.index === AnalyticsConvertedDataType.defenseTime) {
                  return null;
                }
                const secondDataIndex = (item.index ===  AnalyticsConvertedDataType.attackTime) ? AnalyticsConvertedDataType.defenseTime : undefined;
                const availableStatsFiltered = availableStats.filter((otherItem) => otherItem.index !== item.index)
                return (
                  <Grid item xs={ 12 }>
                    <GameStatBartLineChartCard 
                      granularity={ granularity } 
                      allConvertedData={ convertedData }
                      gameResultInfo={gameResultInfo} 
                      availableStats={ availableStatsFiltered }
                      mainDataIndex={ item.index }
                      secondDataIndex={ secondDataIndex }
                      getTeamRefByExternalId={ getTeamRefByExternalId }
                      title={item.name} 
                      data={ data} 
                    />
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};


export default injectIntl(AnalyticsGameReportMainDetails);
