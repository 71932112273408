// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import { FieldSetInputType } from '.';
import { Checkbox } from '@mui/material';


export type ChangeBoolean = (value: boolean) => void;

export type FieldSetInputBoolean = Readonly<{
  type: FieldSetInputType.Boolean;
  value: boolean;
  onChange: ChangeBoolean;
}>;

type Props = Readonly<{
  input: FieldSetInputBoolean;
}>;

const FieldSetBoolean: React.FC<Props> = (props: Props) => {

  const handleBoolValueChanged = (event: any) => {
    const value = event.target.checked
    props.input.onChange(value)
  };

  return (
    <Checkbox
        sx = {{ marginLeft : -1 }}
        defaultChecked={ props.input.value }
        value={ props.input.value }
        color="primary"
        onChange={ handleBoolValueChanged }
    />
  );
};


export default FieldSetBoolean;
