// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { intl } from '../Localization/LocalizationProvider';
import LOCALIZATION from '../Localization';


export type UnixDate = number

export type UnixDateFormat = 'defaultDate' | 'defaultTime' | 'defaultDateTime' | 'shortTime' | 'smart'

export function dateFromUnixDate(unixDate: UnixDate): Date {
    return new Date(unixDate)
}

export type RelativeDateType = 'today' | 'yesterday'

export function relativeDate(date: Date, today: Date = new Date()): RelativeDateType | undefined {
    const days = today.getDate() - date.getDate()
    const months = today.getMonth() - date.getMonth()
    const years = today.getFullYear() - date.getFullYear()

    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    if (years === 0 && months === 0 && days === 0) {
        return 'today'
    } else if (years === 0 && months === 0 && days === 1) {
        return 'yesterday'
    } else if (years === 0 && months === 1 && date.getDate() === lastDayOfMonth.getDate() && today.getDate() === 1) {
        return 'yesterday'
    } else if (years === 1 && months === -11 && date.getDate() === lastDayOfMonth.getDate() && today.getDate() === 1) {
        return 'yesterday'
    }
    // console.log(`years: ${years}`)
    // console.log(`months: ${months}`)
    // console.log(`days: ${days}`)

    return undefined
}

const isToday = (date: Date) => {
    return relativeDate(date) === 'today'
}

const isYesterday = (date: Date) => {
    return relativeDate(date) === 'yesterday'
}

export function stringFromUnixDate(unixDate: UnixDate, format: UnixDateFormat = 'defaultDateTime'): string {
    const date = dateFromUnixDate(unixDate)

    switch (format) {
        case 'defaultDate':
            return date.toLocaleDateString()
        case 'shortTime':
            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        case 'defaultTime':
            return date.toLocaleTimeString()
        case 'defaultDateTime':
            return [date.toLocaleDateString(), date.toLocaleTimeString()].join(', ')
        case 'smart':
            if (isToday(date)) {
                return [intl().formatMessage({ id: LOCALIZATION.today }), stringFromUnixDate(unixDate, 'shortTime')].join(', ')
            } if (isYesterday(date)) {
                return [intl().formatMessage({ id: LOCALIZATION.yesterday }), stringFromUnixDate(unixDate, 'shortTime')].join(', ')
            } else {
                return stringFromUnixDate(unixDate, 'defaultDateTime')
            }
    }
}
