// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

const en = {
  app_title: "CyberCamera",
  add_cam_login_pass_help: "Use the login and password of a local camera user with SuperAdmin rights to authorize adding a camera.",
  add_cam_name_help: "Any name for camera you like that identify it for all users. Example: 'MSK Basketball 800 years', 'SPB Hockey Shans Arena '",
  add_cam_ip_address: "For security reasons, it is better to use the VPN IP (DNS) address of the Pi, but you can also use the external IP (DNS) of the camera itself. Default used 80 port. Example: pi.ccam3.mskl.local, 192.168.10.105, 192.168.10.94:9900",
  add_cam_login: "Login For Existing on Camera SuperAdmin Account",
  add_cam_success_alert: "The added camera will appear in the list after about 15 seconds.",
  add_teamref_success_alert: "The added team will appear in the list after about 15 seconds.",
  alert_only_png_images: "You must use image in PNG format.\nThe maximum image size is 500x500 pixels.",
  tooltip_team_short_name: "The short name of the team used on the scoreboard in the video stream.",
  tooltip_team_color_use: "The color used to indicate the team on the scoreboard in the video stream.",
  page_status_title: "Status",
  page_status_heading: "Camera status",
  page_hardware_title: "Hardware",
  page_hardware_heading: "Camera hardware",
  page_camera_api_title: "Debug",
  page_camera_configuration_title: "Camera",
  page_camera_api_heading: "Camera API",
  page_events_title: "EPG Events",
  page_events_heading: "Events",
  page_panorama_title: "Panorama",
  page_panorama_heading: "Panorama",
  page_sync_settings_title: "Storage",
  page_sync_settings_heading: "Storage",
  page_users_administration_title: "Administration",
  page_analytics_teams_title: "Analytics Teams",
  page_analytics_video_archive_title: "Video for Analysis",
  page_analytics_games_archive_title: "Analytics Reports",
  analytics_possession: "Possession",
  analytics_zone_possession: "Zone Possession",
  analytics_zone_possession_draw: "Draw in Zone Possession",
  analytics_tab_overall: "Overall",
  analytics_tab_events: "Events",
  analytics_tab_heatmap: "Heatmap",
  analytics_tab_attacking: "Attacking",
  analytics_tab_attacking_defence: "Attack ➞ Defense",
  analytics_tab_defending: "Defending",
  analytics_tab_defese_attack: "Defense ➞ Attack",
  analytics_tab_overall_name: "Team distance covered and intensity",
  analytics_tab_attacking_name: "Distance covered and intensity when team in attack",
  analytics_tab_attacking_defence_name: "Distance covered and intensity when team move from attack to defence",
  analytics_tab_defending_name: "Distance covered and intensity when team in defence",
  analytics_tab_defese_attack_name: "Distance covered and intensity when team move from defence to attack",
  analytics_score: "Score",
  analytics_no_players: "No Players",
  analytics_seconds_short: "sec",
  analytics_distance_covered: "Distance Covered",
  analytics_avg_speed: "Avg. Speed",
  analytics_avg_time_in_possession: "Avg. Time in Possession",
  analytics_attack_time: "Attack time",
  analytics_defense_time: "Defense time",
  analytics_walk: "Walk",
  analytics_run: "Run",
  analytics_sprint: "Sprint",
  analytics_attacking: "Attacking",
  analytics_defending: "Defending",
  analytics_intensity: "Intensity (Running)",
  analytics_intensity_short: "Intensity",
  analytics_type_time1: "First half",
  analytics_type_time2: "Second half",
  analytics_type_time3: "Third half",
  analytics_type_time4: "Fourth half",
  analytics_type_overtime1: "First overtime",
  analytics_type_overtime2: "Second overtime",
  analytics_type_overtime3: "Third overtime",
  analytics_type_penalty1: "Penalty series",
  analytics_type_penalty2: "Second penalty series",
  analytics_ganularity: "Level of Detail",
  analytics_ganularity_0: "One Half",
  analytics_ganularity_2: "1/2 Half",
  analytics_ganularity_4: "1/4 Half",
  analytics_ganularity_8: "1/8 Half",
  analytics_visualize_mode: "View",
  analytics_visualize_mode_bar: "Bar Chart",
  analytics_visualize_mode_line: "Line Chart",
  analytics_compare: "Compare to",
  analytics_unit_percent: "Percent",
  analytics_unit_percent_short: "%",
  analytics_unit_seconds: "Seconds",
  analytics_unit_seconds_short: "Sec.",
  analytics_unit_minutes: "Minutes",
  analytics_unit_minutes_short: "Min.",
  analytics_unit_hours: "Hours",
  analytics_unit_hours_short: "h",
  analytics_unit_km: "Kilometer",
  analytics_unit_km_short: "KM",
  analytics_unit_meters: "Meters",
  analytics_unit_meters_short: "m",
  analytics_unit_km_per_hour: "Kilometers per Hour",
  analytics_unit_km_per_hour_short: "KM/H",
  analytics_unit_meter_per_second: "Meters per Second",
  analytics_unit_meter_per_second_short: "M/Sec.",
  analytics_unit_meter_per_minute: "Meter per Minute",
  analytics_unit_meter_per_minute_short: "M/Min.",
  analytics_unit_percent_format: "{value} %",
  analytics_unit_seconds_format: "{value} sec",
  analytics_unit_minutes_format: "{value} min",
  analytics_unit_hours_format: "{value} h",
  analytics_unit_km_format: "{value} km",
  analytics_unit_meters_format: "{value} m",
  analytics_unit_km_per_hour_format: "{value} km/h",
  analytics_unit_meter_per_second_format: "{value} m/sec",
  analytics_unit_meter_per_minute_format: "{value} m/min",
  analytics_stat_team_distance: "Team Distance",
  analytics_stat_avg_players_speed: "Average Players Speed",
  analytics_stat_distance_walked_by_players: "Distance walked by players",
  analytics_stat_avg_walking_speed: "Average walking speed",
  analytics_stat_distance_run_by_players: "Distance run by players",
  analytics_stat_avg_running_speed: "Average running speed",
  analytics_stat_distance_sprinted_by_players: "Distance sprinted by players",
  analytics_stat_avg_sprinting_speed: "Average sprinting speed",
  analytics_stat_avg_intensity: "Average intensity (Running + sprinting over distance)",
  analytics_help_walking: "Walking (0-7 km/h)",
  analytics_help_running: "Running (7-20 km/h)",
  analytics_help_sprinting: "Sprinting (20+ km/h)",
  analytics_left_side: "Left side",
  analytics_right_side: "Right side",
  analytics_game_teams: "Teams",
  analytics_game_score: "Score",
  analytics_game_start: "Start",
  analytics_game_end: "End",
  analytics_game_time_periods: "Time Periods",
  analytics_game_state: "State",
  analytics_game_state_in_progress: "Analysis for analytics in progress {percent}",
  analytics_game_state_failed: "Analytics Failed",
  analytics_game_state_not_started: "Start Analytics",
  analytics_game_start_mapping_team_id: "Start Team Mapping",
  analytics_team_mapping: "Team Mapping",
  games_for_analytics: "Games for Analytics",
  game_for_analytics: "Game for Analytics",
  game_edit_scissors_help: "Start video and use time slider and \"Left\", \"Right\", \"Space\" keyboard buttons to find event time.\nPress ✄ button near event to store time.",
  analytics_game_state_done: "View Report",
  analytics_game_in_readonly_state: "Analysis state: {state}",
  analytics_game_in_readonly: "This game cannot be edited. If you need to edit it, make a copy.",
  analytics_select_player_match_team: "Select players that better match team",
  analytics_events_number: "#",
  analytics_events_event: "Event",
  analytics_events_time: "Time",
  analytics_events_details: "Details",
  analytics_team_side_left: "Left Side",
  analytics_team_side_right: "Right Side",
  analytics_game_edit_time_invalid: "The invalid time is entered",
  game_event_game: "Game",
  game_event_training: "Training",
  game_event_free_time: "Free Time",
  game_event_game_start: "Game Start",
  game_event_game_end: "Game End",
  game_event_time: "Game Half",
  game_event_time_start: "Half #{number}",
  game_event_time_short: "H{number}",
  game_event_time_end: "Half #{number} - end",
  game_event_timeout: "Time-out",
  game_event_timeout_start: "Time-out Start",
  game_event_timeout_end: "Time-out End",
  game_event_goal: "Goal",
  game_event_overtime: "Overtime",
  game_event_overtime_start: "Overtime #{number}",
  game_event_overtime_short: "O{number}",
  game_event_overtime_end: "Overtime #{number} - end",
  game_event_penalty_series: "Penalty series",
  game_event_penalty_start: "Penalty series #{number}",
  game_event_penalty_short: "P{number}",
  game_event_penalty_end: "Penalty series #{number} - end",
  game_extra_event_penalty: "Penalty",
  game_event_end_postfix: " - end",
  add_game: "Add New Game",
  hardware: "Hardware",
  main_dc_ac: "AC Power",
  main_dc_battery: "Battery",
  state_on: "ON",
  state_off: "OFF",
  minutes: "Min",
  minute_number: "{number} minute",
  date: "Date",
  date_from: "From",
  date_to: "To",
  game_date: "Game Date",
  hardware_buttons: "Hardware Buttons",
  configure: "Configure",
  analytics: "Analytics",
  configure_chart: "Configure Graphic",
  stop_recording: "Stop Recording & Streaming",
  start_recording: "Start Recording",
  stop_storage_sync: "Stop Upload for Analytics",
  recording_to_storage: "Recording to Storage",
  operator_stream: "Operator Stream",
  email_address: "Email address",
  password: "Password",
  login: "Log in",
  register: "Register",
  copyright: "Copyright",
  cc_uri: "https://ccamera.io",
  error_wrong_signin_credentials: "User not found or password is incorrect.",
  menu_logout: "Log out",
  not_implemented: "Gonna be implemented in future",
  camera_count: "Camera count",
  teamref_count: "Teams count",
  online: "Online",
  offline: "Offline",
  critical: "Critical",
  on: "ON",
  off: "OFF",
  shutting_down: "Shutting down",
  auto: "Auto",
  streaming: "Streaming",
  active_epg_event: "Active EPG Event",
  no_active_epg_event: "No Active Event",
  stream: "Stream",
  custom: "Custom",
  cpu_name_j1: "CPU J#1",
  cpu: "CPU",
  gpu: "GPU",
  outdoor_temp: "Outdoor temp",
  internal_temp: "Internal temp",
  fan: "FAN",
  shut_down_camera: "Shut Down Cybercamera",
  maintenance_mode: "Maintenance Mode",
  storage_left: "Storage Left",
  storage_sync: "Analytics Server Video Upload",
  panorama: "Panorama",
  game_video: "Game Video",
  team_physical_report: "Team Physical Report",
  team_tactical_report: "Team Tactical Report",
  details: "Details",
  video_analytics: "Video Analytics",
  scoreboard: "Scoreboard",
  storage: "Storage",
  statistics: "Statistics",
  get: "GET",
  post: "POST",
  reload: "Reload",
  error_app_failed: "Failed to start the app.",
  ssh_console: "SSH Console",
  shut_down: "Shut down",
  reboot: "Reboot",
  turn_on: "Turn on",
  start_firmware_update: "Start firmware update",
  error_general: "Failed with error.",
  error_wrong_parameters: "Wrong request parameters.",
  power: "Power",
  power_hw_btn: "HW Power (Force Off > 10 sec)",
  reset_hw_btn: "HW Reset",
  factory_reset_hw_btn: "Factory Reset",
  start_when_cpu_more: "Start when t˚ CPU > than, ˚C",
  stop_when_cpu_less: "Stop when t˚ CPU < than, ˚C",
  critical_cpu_temp: "Critical t˚ CPU, C˚",
  state: "State",
  mode: "Mode",
  case_heater: "Case Heater",
  source: "Source",
  source_operator: "Operator",
  source_panorama: "Panorama",
  stream_fps: "Frames per second, fps",
  start_when_source_less: "Start when t˚ source < than, ˚C",
  stop_when_source_more: "Stop when t˚ source > than, ˚C",
  critical_source_temp: "Critical t˚ source, ˚C",
  tilt_angle: "Tilt Angle",
  camera_direction: "Camera Direction",
  empty_metrics_data: "No Data to display. Try to use another settings.",
  loading_metrics: "Data is loading...",
  action_reload: "Reload",
  chart_voltage_name: "Power input voltage",
  chart_cpu_load_name: "CPU load",
  chart_gpu_load_name: "GPU load",
  chart_outdoor_temperature_name: "Outdoor",
  chart_internal_temperature_name: "Internal",
  chart_dew_point_temperature_name: "Dew Point",
  chart_dc_temperature_name: "DC adapter",
  chart_heater_temperature_name: "Heater",
  chart_internal_humidity_name: "Internal humidity",
  chart_cpu_power_name: "CPU power",
  chart_cpu_voltage_name: "CPU voltage",
  chart_soc_power_name: "SOC power",
  chart_gpu_power_name: "GPU power",
  chart_sys5v_voltage_name: "Sys5V voltage",
  chart_sys5v_power_name: "Sys5V power",
  chart_vddrq_power_name: "VDDRQ power",
  chart_cv_power_name: "CV power",
  cv: "CV",
  vddrq: "VDDRQ",
  sys5v: "Sys5V",
  soc: "SOC",
  connection_error: "Connection error",
  session_missing_error: "Authorisation Data is missing",
  session_failed: "Authorisation error. Please sign in again.",
  logout_in_progress: "Logout error. Operation is in progress, please wait.",
  default_pi_name: "PI",
  board: "Board",
  voltage: "Voltage",
  camera_main_view: "Camera Main View",
  recording: "Recording",
  error_latest_metrics_empty: "No metrics found",
  chart_cpu_temp_name: "CPU",
  chart_board_temp_name: "Board",
  chart_gpu_temp_name: "GPU",
  chart_case_heater_temp_name: "Case Heater",
  chart_case_heater1_temp_name: "Case Heater #1",
  chart_case_heater2_temp_name: "Case Heater #2",
  chart_case_heater3_temp_name: "Case Heater #3",
  chart_case_heater4_temp_name: "Case Heater #4",
  chart_case_heater_cam1_temp_name: "Lens Heater #1",
  chart_case_heater_cam2_temp_name: "Lens Heater #2",
  chart_case_heater_cam3_temp_name: "Lens Heater #3",
  j1name: "J#1",
  j2name: "J#2",
  ok: "OK",
  problems: "Problems",
  internal: "Internal",
  dew_point_short: "DWPT",
  outdoor: "Outdoor",
  sensor_place_name_outdoor: "Outdoor",
  sensor_place_name_cameraCase: "Camera case",
  sensor_place_name_DC: "DC Adapter",
  sensor_place_name_heater: "Case Heater",
  sensor_place_name_heater1: "Case Heater #1",
  sensor_place_name_heater2: "Case Heater #2",
  sensor_place_name_heater3: "Case Heater #3",
  sensor_place_name_heater4: "Case Heater #4",
  sensor_place_name_heater_cam1: "Lens Heater #1",
  sensor_place_name_heater_cam2: "Lens Heater #2",
  sensor_place_name_heater_cam3: "Lens Heater #3",
  itself: "Itself",
  sensor_place_name_jetson1: "Jetson 1",
  sensor_place_name_jetson2: "Jetson 2",
  sensor_place_name_pi: "PI",
  resource_is_unreachable: "Requested resource is unreachable",
  wrong_resource_name: "Unknown resource name",
  confirm_shut_down: "Shut down the Camera and its all hardware?",
  confirm_shut_down_jetson: "Shut down the Jetson?",
  confirm_reboot_jetson: "Reboot the Jetson?",
  confirm_turn_on_jetson: "Turn on the Jetson?",
  confirm_start_firmware_update_jetson: "Start firmware update for the Jetson?",
  confirm_shut_down_pi: "Shut down PI?",
  confirm_reboot_pi: "Reboot PI?",
  confirm_reset_jetson: "Reset the Jetson?",
  confirm_power_jetson: "Power the Jetson?",
  confirm_recovery_jetson: "Recover the Jetson?",
  post_camera_api_confirm: "Post the changed config to Jetson? The operation may be unsafe.",
  network_request_not_found: "Request is not found",
  undefined_camera_api_response_status: "Undefined response status",
  empty_camera_api_response: "Empty response",
  apply: "Apply",
  cancel: "Cancel",
  save: "Save",
  abr_milliseconds: "ms.",
  duration: "Duration",
  confirm_actions: "Confirm and apply the actions?",
  confirm_remove: "Confirm remove?",
  confirm_sync_selected_video: "Upload selected video to analytics server?",
  success_alert_sync_selected_video: "The selected video is marked for upload to the analytics server. Upload progress is displayed on the Storage page.",
  used_volume: "Volume used",
  ssd: "SSD",
  updating: "Updating...",
  no_connection_to_device_off: "The device is off. Some Data may be missing.",
  no_connection_to_device: "The device is off or unavailable. Some Data may be missing.",
  azymuth: "Azymuth",
  reboot_disclaimer: "The reboot request has been sent successfully. Please wait.",
  shut_down_disclaimer: "The power off request has been sent successfully. Please wait.",
  turn_on_disclaimer: "The turn on request has been sent successfully. Please wait.",
  firmware_update_disclaimer: "The sequence sent successfully. Software update mode should start shortly.",
  power_source_battery: "Battery",
  power_source_adapter: "Power adapter",
  version: "Version",
  chart_power_consumption: "Power consumption",
  cameras: "Cameras",
  jetson_status_firmware_update: "Factory update mode",
  camera_off: "Camera is off",
  turn_odd_to_start_firmware_update: "You should turn Jetson off to start firmware update",
  camera_not_available: "Camera is not available",
  supply: "Supply",
  volume: "Volume",
  total: "Total",
  total_power: "Total power",
  dc: "DC",
  heater: "Case Heater",
  heater1: "Case Heater #1",
  heater2: "Case Heater #2",
  heater3: "Case Heater #3",
  heater4: "Case Heater #4",
  heater_cam1: "Lens Heater #1",
  heater_cam2: "Lens Heater #2",
  heater_cam3: "Lens Heater #3",
  tilt: "Tilt",
  empty_metrics_warning: "Please enable metrics usingg settings to display graphics.",
  hour: "Hour",
  twoHours: "2 hours",
  fourHours: "4 hours",
  eightHours: "8 hours",
  halfDay: "Half day",
  day: "Day",
  output_period: "Output period",
  output_time: "Output time",
  now: "Now",
  today: "Today",
  ended: "Ended",
  tomorrow: "Tomorrow",
  every_day: "Every Day",
  yesterday: "Yesterday",
  unit_w: "W",
  unit_v: "V",
  events: "Events",
  events_with_numbers: "Events, #",
  event: "Event",
  archive: "Archive",
  history: "EPG",
  teams: "Teams",
  team: "Team",
  add_event: "Add event",
  copy: "Copy",
  copy_item: "Copy",
  remove: "Remove",
  recover_remove_btn: "Restore",
  import_epg_btn: "Import EPG",
  disable_btn: "Disabe",
  enable_btn: "Enable",
  close_btn: "Close",
  remove_selected_items: "Remove Selected Items ?",
  recover_selected_items: "Restore selected items?",
  disable_selected_items: "Disable selected items?",
  enable_selected_items: "Enable selected items?",
  show_deleted_events: "Show deleted events to restore them?",
  send_to_sync_server: "Upload to Analytics Server",
  refresh: "Refresh",
  event_name: "Title",
  event_name_help: "Event name, like: 'Football', 'Basketball SKA-NBA', 'Physical education lesson'",
  event_sub_title: "Subtitle",
  event_sub_title_help: "An optional subtitle, such as the class number for the lesson: 'Boys 2012'",
  event_description: "Description | Use [Unique-Marker] for VKVideo announcements",
  event_description_help: "An optional description of the event. Like: 'FIFA CUP final game of the year'. To link this event with a video announcement on VKVideo, use an arbitrary marker in sqaure brackets in the description. Example [My sport 102782]. The announcement time in VKVideo may have a tolerance of up to 1 hour for this event to correspond.",
  event_key_override_header: "Specify new streaming keys for the streams you want to change for this event.",
  event_key_override_item: "{name}{number} Key",
  event_profile_nothing_override_error: "There are no streams in this profile that can be overridden.",
  event_warning: "State",
  event_priority: "Event Priority",
  event_priority_help: "Higher priority events can overlap lower priority events by time.",
  event_priority_low: "Low",
  event_priority_imported: "Imported",
  event_priority_base: "Base",
  event_priority_high: "High",
  event_priority_critical: "Critical",
  event_game_type: "Event Type",
  event_game_type_help: "The scoreboard, if it exists, is only displayed for the type 'Game'.",
  event_game_type_game: "Game",
  event_game_type_train: "Training (no scoreboard)",
  event_game_type_free_time: "Free Time (no scoreboard)",
  event_default_sport_type: "Default Sport Type",
  event_default_sport_type_hint: "The default sport type used when creating new EPG Events and Analytics reports.",
  event_sport_type: "Sport Type",
  event_sport_type_football: "Football",
  event_sport_type_hockey: "Hockey",
  event_sport_type_basketball: "Basketball",
  event_sport_type_hockey_with_ball: "Hockey With Ball",
  event_sport_type_multiple_sports: "Multiple Sports",
  event_sport_type_handball: "Handball",
  event_sport_type_mini_football: "Mini Football",
  event_sport_type_unknown: "Unknown",
  event_start_date: "Start Date",
  event_time_period: "Event Time Period",
  event_end_date: "End date",
  event_no_event_date: "No Date",
  event_home_team: "Home Team",
  event_guest_team: "Guest Team",
  event_teams_help: "Select a team to show its name on the scoreboard. You can add a team in the 'Teams' tab.",
  event_stream_type: "Event Stream Profile",
  event_stream_type_help: "If no profile is selected, the camera will not record or broadcast this event. You can add a profile on the 'Streaming' page.",
  event_analytics_promocode: "Analytics Coupon",
  event_analytics_promocode_hint: "You can enter a coupon to speed up the generation of analytical reports for this event. The coupon works only if you enter it before sending the video to the analytics server. To receive Analytics coupon, contact your Cybercamera Support Manager.",
  import_epg_notes: "Previous EPG events with 'Imported' priority will be replaced by new events from imported data.\nEvents with a different priority will not change.",
  import_epg_def_stream_type: "Event Stream Profile for All Imported Events",
  import_epg_def_stream_type_help: "If no profile is selected, the camera will not record or broadcast this event. You can add a profile on the 'Streaming' page.",
  import_epg_file_type: "EPG data format",
  import_epg_default_game_type: "Default Event Type",
  import_epg_default_game_type_help: "Events uses this type if the EPG data format does not provide information about the event type. The scoreboard, if it exists, is only displayed for the type 'Game'.",
  import_epg_default_sport_type: "Default Sport Type",
  import_epg_default_sport_type_help: "Events uses this sport if the EPG data format does not provide information about the sport.",
  import_epg_upload_file: "Manual Import EPG from File",
  import_epg_upload_file_help: "Import EPG from file. Previous EPG events with 'Imported' priority will be replaced by new events from imported data.",
  import_epg_upload_file_btn: "Upload EPG from File",
  import_epg_auto_import: "Automatic Import",
  import_epg_auto_import_active: "Active",
  import_epg_auto_import_disabled: "Inactive",
  import_epg_url: "URL for Automatic Import of EPG",
  import_epg_url_help: "A valid web URL to download the EPG events in selected format.",
  import_epg_update_period: "EPG Auto Update Interval, Hours",
  import_epg_update_period_help: "To update the EPG automatically, use the interval from 0.1 hour to 168 hours (one week). To disable automatic updates, use 0.",
  import_epg_last_import_result: "Last Import Result",
  import_epg_last_import_success: "Successful import.",
  import_epg_last_import_error: "Import error.",
  stream_profile: "Profile",
  stream_profile_apply_progress_header: "Communicating with Server",
  stream_profile_apply_progress_text: "Applying Changes",
  event_disabled: "Disable Event",
  event_deleted: "Deleted Event",
  disabled: "Disabled",
  deleted: "Deleted",
  analytics_games_added_number: "Added games: {count}",
  analytics_uploaded: "Uploaded",
  analytics_uploading: "Uploading: {percent}%",
  analytics_analytics_progress: "Progress {percent}%",
  analytics_ready_analysis: "Ready for Analysis",
  analytics_start_analysis_help: "The game is ready for analysis. The analysis process will take from several minutes to hours, depending on the duration of the game. Once you start the analysis, you will not be able to edit the settings for this game. To edit the game, you must first cancel the analysis you have started.",
  analytics_waiting_team_mapping: "Waiting Team Mapping",
  analytics_uploading_stoped: "Uploading stoped",
  analytics_waiting_markup: "Waiting Video Markup for Analytics",
  analytics_done: "Done",
  analytics_state_analytics: "Analytics",
  analytics_error: "Error",
  analytics_canceled: "Canceled",
  analytics_filter_by_done: "Analytics - Done",
  analytics_filter_by_canceled: "Analytics - Canceled",
  analytics_filter_by_failed: "Analytics - Error",
  analytics_filter_by_progress: "Analytics - In Progress",
  analytics_filter_by_ready_analysis: "Analytics - Ready for Analysis",
  analytics_filter_by_waiting_team_mapping: "Analytics - Waiting Team Mapping",
  event_type: "Type",
  event_teams: "Teams",
  event_time: "Time",
  event_repeat: "Repeat",
  event_repeat_no: "No Repeat",
  event_repeat_every_week: "Every Week",
  event_invalid_start_date: "Invalid event start date",
  event_invalid_start_date_description: "The start date of the event must not be in the past",
  event_invalid_max_duration: "Maximum event duration {max} hours",
  event_curr_duration: "Duration {currHours}:{currMinutes}",
  event_end_before_start_date: "The end date cannot be earlier than the start date",
  event_status_start_in_past: "The start time of the event has already passed",
  event_status_ended_event: "Completed event",
  event_status_recurring_end_in_past: "Completed recurring event",
  event_status_disabled_event: "Disabled event",
  weekday_short_sunday: "Su",
  weekday_short_monday: "Mo",
  weekday_short_tuesday: "Tu",
  weekday_short_wednesday: "We",
  weekday_short_thursday: "Th",
  weekday_short_friday: "Fr",
  weekday_short_saturday: "Sa",
  button_ok: "OK",
  button_accept: "Accept",
  button_cancel: "Cancel",
  table_rows_per_page: "Rows per page",
  table_of: "of",
  history_event_streaming: "Streaming",
  history_event_records: "Records",
  team_id: "Team Id",
  team_id_help: "Select the Team ID to use. Only if an existing identifier is not found, create a new identifier with a capital latin name, for example: MSK_SPARTAK_DETI_2.",
  team_id_add_help: "Only add a new Team ID if you can't find match an existing ID! You won't be able to delete this ID once created, so double check. In Id, use only Latin letters, numbers, underscores, dashes, for example: MSK_SPARTAK_DETI_BASKETBALL_2, SPB-ZENIT-FOOTBALL-2015, NNov-Dinamo-Hockey-4",
  add_team_id: "Add Team Id",
  team_name: "Name",
  team_short_name: "Short Name",
  team_logo: "Team Logo",
  team_notes: "Notes",
  team_color: "Team Color",
  team_upload_new_logo: "Upload New Logo",
  page_streaming_configuration_title: "Streaming",
  page_streaming_api_heading: "Streaming",
  page_video_archive_heading: "Video Archive",
  page_video_archive_tab_operator: "Operator Stream",
  page_video_archive_tab_panorama: "Panorama",
  page_video_archive_tab_scoreboard: "Scoreboard",
  page_video_archive_tab_select_all_option: "Select All",
  page_video_archive_empty_list: "Video files not found",
  add: "Add",
  edit: "Edit",
  rename: "Rename",
  failed_load_data: "Failed to load data",
  stream_audio_title: "Audio",
  stream_audio_channels: "Channels",
  stream_audio_bitrate: "Bitrate, kbit",
  stream_audio_enable: "Enable",
  stream_audio_sample_rate: "Sample Rate, Hz",
  stream_audio_volume: "Volume Rate, %",
  stream_audio_control_title: "Audio Compressor",
  stream_audio_control_rms_peak: "RMS/peak",
  stream_audio_control_attack_time: "Attack time, ms",
  stream_audio_control_release_time: "Release time, ms",
  stream_audio_control_threshold_level: "Threshold level, dB",
  stream_audio_control_ratio: "Ratio, 1:n",
  stream_audio_control_knee_radius: "Knee radius, dB",
  stream_audio_control_knee_makeup_gain: "Makeup gain, dB",
  stream_video_title: "Main Stream",
  stream_video_secondary_title: "Secondary Panorama Stream",
  stream_video_resolution: "Video resolution",
  stream_video_resolution_width: "Width",
  stream_video_resolution_height: "Height",
  stream_video_bitrate_mbps: "Video bitrate, mbps",
  stream_video_add_destination: "Add Destination",
  stream_video_store_local_file: "Store to video archive on Cybercamera",
  stream_video_sync_to_server: "Upload Video to Analytics Server",
  stream_video_panorama_source: "Panorama source",
  stream_video_rtmp_help: "You can get the RTMP URL and KEY from the administration page of the streaming service you want to use.",
  stream_video_rtmp_url: "RTMP URL",
  stream_video_rtmp_key: "KEY",
  stream_video_hint_cybercamera: "Cybercamera can receive streams in 4k at 60 fps and will output 4k at 60 fps.",
  stream_video_hint_youtube: "Youtube can receive RTMP streams in 4k at 60 fps and it will show warning in settings: 'The current resolution (3840 x 2160) is not supported by YouTube. We recommend using the 2560 x 1440 format.' but will work and output video in 1440p at 60 fps.",
  stream_video_hint_rutube: "RUTUBE can receive 4k at 60 fps but will output in 1080p at 30 fps.",
  stream_video_hint_vkvideo: "VKVideo can receive RTMP streams in 4k at 60 fps and will output 4k at 60 fps.",
  stream_video_hint_twitch: "twitch can receive RTMP streams in 4k at 60 fps and maximum 6 mbit but will output in 1080p at 60 fps.",
  stream_video_title_hint: "Cybercamera will automatically broadcast the configured EPG Events. You can define a group name to group all videos from this camera.",
  stream_video_youtube_login: "Login to YouTube",
  stream_video_vkvideo_login: "Login to VK Video",
  stream_video_cybercamera_login: "Login to Cybercamera",
  stream_video_youtube_logout: "Log out of Youtube",
  stream_video_vkvideo_logout: "Log out of VK Video",
  stream_video_cybercamera_logout: "Log out of Cybercamera",
  stream_video_stream_target: "Place on",
  stream_video_stream_target_hint: "Destination for video streaming. This can be user own page or group, where the user has permission to do so. You may need to logout and login again to change the place.",
  stream_video_stream_target_user_page: "User's own page",
  stream_video_playlist_name: "Playlist Name",
  stream_video_playlist_name_hint: "You can define a playlist name to group all videos from this camera.",
  stream_video_name_prefix: "Stream Name Prefix",
  stream_video_name_prefix_hint: "By default, the stream name looks like \"11:15 Event Name\". You can add an optional prefix before the time. For example, if the prefix is \"Basketball - \", then the name would become \"Basketball - 11:15 Event Name\".",
  stream_video_access_permision: "Access Permissions",
  stream_video_access_permision_hint: "Restrict who can view this video stream.",
  stream_access_permision_all: "All",
  stream_access_permision_members: "Only Members",
  stream_access_permision_payed: "Members who pay",
  stream_access_permision_admin: "Only Admins",
  stream_access_permision_by_link: "Hidden (Only by link)",
  sort_by: "Sort By",
  sort_by_time: "Time",
  sort_asc: "Asc",
  sort_desc: "Desc",
  filter_by_team: 'Filter By Team',
  filter_by_analytics_status: 'Filter By Status',
  filter_no_filter: 'No Filter',
  filter_by_date: "Filter By Date",
  filter_by_date_off: 'No Filter',
  filter_by_camera: "Filter By Camera",
  filter_by_all_camera: "All Cameras",
  filter_by_all_teams: "All Teams",
  add_account: "Add Account",
  users_filter_camera_label: "Filter by Camera",
  users_filter_teamref_label: "Filter by Team",
  user_popup_title: "Account",
  user_name: "Name",
  user_group: "Group",
  user_visible_cameras: "Visible Cameras",
  user_visible_teamrefs: "Visible Teams",
  user_last_login: "Last Login",
  page_user_title_web_admin: "Local Accounts",
  page_user_title_server_web_admin: "Cloud Accounts",
  user_login: "Login",
  user_server_login: "Login (email)",
  user_password: "Password",
  user_confirm_password: "Confirm Password",
  user_confirm_incorrect_password: "Incorrect Password",
  enter_confirm_password: "Enter confirm password",
  user_old_password: "Old password",
  passwords_do_not_match: "Passwords do not match",
  password_restrictions: "The password must be no shorter than {min} and no longer than {max} characters, include small and large letters and at least 2 numbers",
  cant_delete_your_account: "Can't delete your account",
  invalid_current_password: "Invalid current password",
  password_will_be_generated_and_send_to_login_email_address: "Password will be generated and send to login email address.",
  cameras_option_title_all: "All my cameras",
  cameras_option_title_root_access: "Root Access",
  teamref_option_title_all: "All my teams",
  teamref_option_title_root_access: "Root Access",
  cameras_option_title_selected_list: "Selected List",
  cameras_option_title_none: "None",
  cameras_option_title_guest: "Guest access",
  password_reset: "Reset Password",
  password_reset_successful: "Password reset successfully. The new password has been sent by email.",
  invalid_server_login: "Login must be a valid email address.",
  value_incorrect: "Incorrect Value",
  filtered_data_empty: "There are no data for the selected parameters",
  cameraError: 'Error',
  cameraOnlineWarning: "Online, warning",
  cameraOnlineCritical: "Online, сritical",
  unknown: "Unknown",
  unknown_short: "Unk",
  saveToStorage: "Save To Storage",
  camerasWarningCount: "Warning",
  camerasErrorCount: "Error",
  storage_sync_short: "Upload for Analytics",
  active_camera: "Active Camera",
  no_active_camera: "ACTIVE CAMERA NOT SELECTED",
  active_teamref: "Active Team",
  no_active_teamref: "ACTIVE TEAM NOT SELECTED",
  select_camera_as_active: "Select",
  camera_name: "Name",
  camera_address_pi: "Main Backend Address in Camera (ip or DNS)",
  camera_address_j1: "Address J#1 (ip or DNS)",
  camera_address_j2: "Address J#2 (ip or DNS)",
  camera_type: "Camera Type",
  camera_type_hint: "Set correct camera type. Use Proxy for Proxy camera config.",
  camera_technical_info: "Technical Info",
  camera_notes: "Notes",
  home_address: "Home Address",
  optional: "Optional",
  storage_general: "Storage Size",
  storage_free: "Storage Free",
  storage_remove_old_video_when_storage_less_then_gb: 'Remove old video when free less than, GB',
  current_sync_speed_in_b_sec: "Current upload speed",
  wait: 'Wait…',
  files_in_sync_queue_in_gb: 'Files in Upload Queue',
  clear_sync_queue: "Clear Queue",
  default_sync_speed_mbit: 'Default upload speed (0 - Unlimited), mbit',
  sync_speed_when_streaming_active_mbit: 'Upload speed when streaming active (0 - Unlimited), mbit',
  sync_speed_when_low_speed_network_mbit: "Upload speed when network speed is not enough for streaming (0 - Unlimited), mbit",
  maximum_sync_speed_mbit: "Maximum upload speed (0 - Unlimited), mbit",
  maximum_sync_speed_in: "Maximum upload speed in",
  select_streaming_settings: 'Streaming Profiles',
  no_stream: 'No Streaming',
  stop_streaming: 'Stop Streaming',
  'rtmp:connecting': 'RTMP: Connecting',
  'rtmp:reconnecting': 'RTMP: Reconnecting',
  'rtmp:disconnected': 'RTMP: Disconnected',
  'rtmp:failed': 'RTMP: Failed',
  'rtmp:lastDataDrop': 'RTMP: Low Network Speed',
  'mp4:connecting': 'MP4: Connecting',
  'mp4:reconnecting': 'MP4: Reconnecting',
  'mp4:disconnected': 'MP4: Disconnected',
  'mp4:failed': 'MP4: Failed',
  'mp4:lastDataDrop': 'MP4: Low Storage Speed',
  oldest_first: "Oldest first",
  newest_first: "Newest first",
  any: "Any",
  cameras_list_statuses_critical: "Critical",
  cameras_list_statuses_warning: "Warning",
  cameras_list_statuses_error: "Error",
  cameras_list_statuses_off: "Offline",

};


export default en;
