// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import MainCardHeader from '../Main';
import { Colors } from '../../../../Configuration/Styles/Colors';
import { MainCardHeaderModelInterface } from '../Types';


type Props = Readonly<{
  titleLeft?: string;
  titleRight?: string;
}>;


const LeftRightCardHeader: React.FC<Props> = (props: Props) => {
  const model :MainCardHeaderModelInterface = {
    name: props.titleLeft,
    nameRight: props.titleRight,
    barColor: Colors.white,
    statuses: [],
    hideDivider: true
  }

  return (
    <MainCardHeader model={ model } />
  );
};


export default LeftRightCardHeader;
