import { JAVA_BACKEND_URL } from "../../../../constants"
import fetchJSONData, { ErrorResponse } from "../../../_Networking/ReactQuery/fetch"
import { CamerasStatisticResponse } from "../types"

export const getCameraStatisticKey = () => "CamerasStatistic-d227015d-7e1e-41bc-bb45-01701f02586b"

/**
 * Getting information about the status of cameras available to the user
 */
export const getCamerasStatistics = async () => {

    try {
        /**
         * @param error
         */
        const onError = (error: ErrorResponse) => {
            throw error
        };
        const result = await fetchJSONData<CamerasStatisticResponse>(`${JAVA_BACKEND_URL}/cameras/statistics`, {
            method: "GET"
        },
            onError)
        return result;
    } catch (e) {
        return undefined;
    }
}