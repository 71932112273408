// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import LOCALIZATION from '../../../Localization';
import FieldSet, { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';


type Props = Readonly<{
  isCurrentUser: boolean;
  onChangePassword: (isPasswordCorrect: boolean, password: string, oldPassword?: string) => void;
} & WrappedComponentProps>;
enum IncorrectPasswordReason {
  EnterConfirmPassword = 'enter_confirm_password',
  PasswordsDoNotMatch = 'passwords_do_not_match',
  PasswordRestrictions = 'password_restrictions',
}


const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 250;
/*
(?=.*?[A-Z])                                                -- Есть заглавные
(?=.*?[a-z])                                                -- Есть прописные
(?=.*?[0-9].*?[0-9])                                        -- Минимум, есть 2 цифры
[A-Za-z0-9]{${MIN_PASSWORD_LENGTH},${MAX_PASSWORD_LENGTH}}  -- Общая длинна вышеперечисленного от MIN_PASSWORD_LENGTH и до MAX_PASSWORD_LENGTH
 */
const passwordPattern = new RegExp(`(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9].*?[0-9])[A-Za-z0-9]{${MIN_PASSWORD_LENGTH},${MAX_PASSWORD_LENGTH}}`);
const passwordChecker = (password: string): boolean => (passwordPattern.test(password));

const UserPopupPasswords: React.FC<Props> = (props: Props) => {
  const [oldPassword, setOldPassword] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [isPasswordCorrect, setIsPasswordCorrect] = React.useState<boolean>(true);
  const [incorrectPasswordReason, setIncorrectPasswordReason] = React.useState<IncorrectPasswordReason | undefined>(undefined);
  const handleChangeConfirmPassword = (password: string) => {
    setConfirmPassword(password);
  };

  React.useEffect(() => {
    if (password && password.length !== 0) {
      const isPasswordsMatch = (password === confirmPassword);
      const isCorrectPassword = passwordChecker(password);

      setIsPasswordCorrect(isPasswordsMatch && isCorrectPassword);
      setIncorrectPasswordReason(
        (confirmPassword.length === 0) ?
          IncorrectPasswordReason.EnterConfirmPassword
          :
          (isPasswordsMatch) ?
            (isCorrectPassword) ?
              undefined
              :
              IncorrectPasswordReason.PasswordRestrictions
            :
            IncorrectPasswordReason.PasswordsDoNotMatch
      );
    } else {
      setIsPasswordCorrect(true);
      setIncorrectPasswordReason(undefined);
    }
  }, [password, confirmPassword]);

  React.useEffect(() => {
    props.onChangePassword(isPasswordCorrect, password, oldPassword);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasswordCorrect, password, oldPassword]);

  return (
    <>
      {
        (props.isCurrentUser) &&
          <FieldSet
            key={ 'OldPassword' }
            label={ props.intl.formatMessage({ id: LOCALIZATION.user_old_password }) }
            input={ {
              type: FieldSetInputType.Password,
              value: oldPassword || '',
              onChange: setOldPassword,
            } }
          />
      }
      <FieldSet
        key={ 'Password' }
        label={ props.intl.formatMessage({ id: LOCALIZATION.user_password }) }
        onErrorTooltipText={
          (incorrectPasswordReason === IncorrectPasswordReason.PasswordsDoNotMatch) ?
            props.intl.formatMessage({ id: IncorrectPasswordReason.PasswordsDoNotMatch })
            :
            (incorrectPasswordReason === IncorrectPasswordReason.PasswordRestrictions) ?
              props.intl.formatMessage({ id: IncorrectPasswordReason.PasswordRestrictions }, { min: MIN_PASSWORD_LENGTH, max: MAX_PASSWORD_LENGTH })
              :
              undefined
        }
        forceShowonErrorTooltipText= {
          (incorrectPasswordReason === IncorrectPasswordReason.PasswordsDoNotMatch) || (incorrectPasswordReason === IncorrectPasswordReason.PasswordRestrictions)
        }
        input={ {
          type: FieldSetInputType.Password,
          value: password || '',
          onChange: setPassword,
        } }
      />
      <FieldSet
        key={ 'ConfirmPassword' }
        label={ props.intl.formatMessage({ id: LOCALIZATION.user_confirm_password }) }
        onErrorTooltipText={
          (incorrectPasswordReason === IncorrectPasswordReason.EnterConfirmPassword) ?
            props.intl.formatMessage({ id: IncorrectPasswordReason.EnterConfirmPassword })
            :
            undefined
        }
        forceShowonErrorTooltipText= {
          (incorrectPasswordReason === IncorrectPasswordReason.EnterConfirmPassword)
        }
        input={ {
          type: FieldSetInputType.Password,
          value: confirmPassword,
          onChange: handleChangeConfirmPassword,
        } }
      />
    </>
  );
};


export default injectIntl(UserPopupPasswords);
