// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import FieldSet, { FieldSetInputType } from '../../Components/_BaseUI/FieldSet';
import LOCALIZATION from '../../Localization';
import { formatBytes } from '../../Tools/b2Mb';


type Props = Readonly<{
  sizeInGB: number;
  freeInGB: number;
  removeOldVideoWhenStorageLessThenGB: number;
  onChange: (limit: number) => void;
} & WrappedComponentProps>;


const StorageRender: React.FC<Props> = (props: Props) => {

  const handleChangeLimit = (limit: number | null) => {
    if (limit !== null) {
      props.onChange(limit);
    }
  };

  if (!props.sizeInGB) {
    return (
      <>
        <FieldSet
          label={ props.intl.formatMessage({ id: LOCALIZATION.state }) } 
          input={ {
            type: FieldSetInputType.StaticLabel,
            value: props.intl.formatMessage({ id: LOCALIZATION.connection_error })
          } }
        />
      </>
    );
  }

  return (
    <>
      <FieldSet
        label={ props.intl.formatMessage({ id: LOCALIZATION.storage_general }) } 
        input={ {
          type: FieldSetInputType.StaticLabel,
          value: (props.sizeInGB) ? formatBytes(props.sizeInGB * 1000000000) : props.intl.formatMessage({ id: LOCALIZATION.connection_error })
        } }
      />
      <FieldSet
        label={ props.intl.formatMessage({ id: LOCALIZATION.storage_free }) }
        input={ {
          type: FieldSetInputType.StaticLabel,
          value: (props.sizeInGB) ? `${formatBytes(props.freeInGB * 1000000000)}   ${ (props.freeInGB * 100 / props.sizeInGB).toFixed(2) }%` : props.intl.formatMessage({ id: LOCALIZATION.connection_error })
        } }
      />
      <FieldSet
        label={ props.intl.formatMessage({ id: LOCALIZATION.storage_remove_old_video_when_storage_less_then_gb }) }
        input={ {
          type: FieldSetInputType.Number,
          value: props.removeOldVideoWhenStorageLessThenGB || 0,
          onChange: handleChangeLimit,
        } }
      />
    </>
  );
};


export default injectIntl(StorageRender);
