// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useState, useEffect } from 'react'
import { CalibrationDataProvider } from './DataProvider'
import { CalibrationHelper } from './Helpers'
import { MagnetometerCalibrationInterface } from './Types'

export function useCalibrationValues(): MagnetometerCalibrationInterface {
    const dataProvider = CalibrationDataProvider.shared

    const [data, setData] = useState(dataProvider.calibration)

    useEffect(() => {
        function handleDataChange(data: MagnetometerCalibrationInterface) {
            setData(data)
        }

        CalibrationHelper.subscribeToCalibrationChange(handleDataChange)
        return () => {
            CalibrationHelper.unsubscribeFromCalibrationChange(handleDataChange)
        }
    })

    return data
}
