// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { RenderCellContentValue } from "../../../../Components/SortedTable/SortedTableBodyRow";
import AnalyticsGameArchive from "../../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive";
import { AnalyticsAnalyticsStatus } from "../../../../Data/Analytics/AnalyticsStatuses";
import { AnalyticsSmallPlayBtn } from "../../../../Icons/Icons";
import LOCALIZATION from "../../../../Localization";
import { intl } from "../../../../Localization/LocalizationProvider";
import IconButton from '@mui/material/IconButton';
import MainButton, { ButtonType } from "../../../../Components/_BaseUI/MainButton";
import { HeadCell } from "../../../../Components/SortedTable/SortedTableHead";
import { analyticsTimeIntervalName, localizedAnalyticsSportNameById } from "../../../../Data/Analytics/AnalyticsTypes";
import VertGrid from "../../../../Components/_Layout/VertGrid";
import { Box } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { Sizes } from '../../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../../Configuration/Styles/Fonts';
import { Colors } from "../../../../Configuration/Styles/Colors";
import HorizGrid from "../../../../Components/_Layout/HorizGrid";
import { GetTeamRefById } from "../../../../Data/Analytics/AnalyticsTeamRef/hook";

export const useRowStyles = makeStyles(() => createStyles({
    rowText: {
      fontFamily: Fonts.main,
      fontSize: Sizes.title,
      fontWeight: Sizes.regularWeight,
      color: Colors.mainTitle,
    },
  }));

export const headCells: HeadCell<AnalyticsGameArchive>[] = [
    {
      id: 'title' as keyof AnalyticsGameArchive,
      numeric: false,
      disablePadding: true,
      disableSort: true,
      label: intl().formatMessage({ id: LOCALIZATION.analytics_game_teams }),
    },
    {
      id: 'score' as keyof AnalyticsGameArchive,
      numeric: false,
      disablePadding: true,
      disableSort: true,
      label: intl().formatMessage({ id: LOCALIZATION.analytics_game_score }),
    },
    {
      id: 'video_start' as keyof AnalyticsGameArchive,
      numeric: false,
      disablePadding: true,
      label: intl().formatMessage({ id: LOCALIZATION.analytics_game_start }),
    },
    {
      id: 'video_end' as keyof AnalyticsGameArchive,
      numeric: false,
      disablePadding: true,
      label: intl().formatMessage({ id: LOCALIZATION.analytics_game_end }),
    },
    {
      id: 'time_periods' as keyof AnalyticsGameArchive,
      numeric: false,
      disablePadding: true,
      disableSort: true,
      label: intl().formatMessage({ id: LOCALIZATION.analytics_game_time_periods }),
    },
    {
      id: 'analytics_status' as keyof AnalyticsGameArchive,
      numeric: false,
      disablePadding: true,
      label: intl().formatMessage({ id: LOCALIZATION.analytics_game_state }),
    },
  ];

type Props = Readonly<{
    rowClasses: ReturnType<typeof useRowStyles>
    onVideoStart: (item: AnalyticsGameArchive) => void
    onStartAnalytics: (item: AnalyticsGameArchive) => void
    onStartMappingTeamId: (item: AnalyticsGameArchive) => void
    onViewCompletedAnalytics: (item: AnalyticsGameArchive) => void
    getTeamRefByExternalId: GetTeamRefById | undefined
}>;

export const getRenderCellContent = (props: Props) => {
    return (item: AnalyticsGameArchive, cellName: keyof AnalyticsGameArchive, index: number): RenderCellContentValue => {
        switch (cellName) {
            case 'title': {
                if (!!item.team1?.length || !!item.team2?.length) {
                    return ({
                        content: (
                            <VertGrid>
                                <Box className={props.rowClasses.rowText}> • { props.getTeamRefByExternalId?.(item.team1)?.name || "-" }</Box>
                                <Box className={props.rowClasses.rowText}> • { props.getTeamRefByExternalId?.(item.team2)?.name || "-" }</Box>
                            </VertGrid>
                        ),
                    });
                }
                return ({
                    content: item.title || "-",
                });
            }
            case 'score': {
                if (!!item.score) {
                    return ({
                    content: (
                        <VertGrid>
                            <Box className={props.rowClasses.rowText}>{ item.score?.team1 || "-" }</Box>
                            <Box className={props.rowClasses.rowText}>{ item.score?.team2 || "-" }</Box>
                            </VertGrid>
                    ),
                    });
                }
                return ({
                    content: "-",
                });
            }
            case 'video_start': {
                if (!!item.video_start) {
                    return ({
                    content: (
                        <HorizGrid>
                                <IconButton aria-label="play" sx={{ marginLeft: -1 }} onClick={(ev) => {
                            ev.stopPropagation();
                            props.onVideoStart(item);
                        } }>
                            <AnalyticsSmallPlayBtn />
                        </IconButton>

                        <Box className={props.rowClasses.rowText}>
                        { item.video_start }
                        </Box>
                        </HorizGrid>
                    ),
                    });
                }
                return ({
                    content: "-",
                });
            }
            case 'video_end': {
                if (!!item.video_end) {
                    return ({
                    content: (
                        <Box className={props.rowClasses.rowText}>
                            { item.video_end }
                        </Box>
                    ),
                    });
                }
                return ({
                    content: "-",
                });
            }
            case 'time_periods': {
                if (!!item.time_periods?.length) {
                    const periods = item.time_periods.map((item) => {
                        const timeName = analyticsTimeIntervalName(item.type, item.type_order_num);
                        return (
                        <Box className={props.rowClasses.rowText}>
                            {` • ${timeName}: ${item.start_at} - ${item.end_at}`}
                        </Box>
                        )
                    })
                    return ({
                    content: (
                        <VertGrid>
                            <Box className={props.rowClasses.rowText}>
                                {localizedAnalyticsSportNameById(item.sport_type)}
                            </Box>
                            {periods}
                        </VertGrid>
                    ),
                    });
                }
                return ({
                    content: "-",
                });
            }
            case 'analytics_status': {
                if (!!item.analytics_status) {
                    switch(item.analytics_status) {
                        case AnalyticsAnalyticsStatus.NotStarted:
                        case AnalyticsAnalyticsStatus.Cancelled:
                        {
                            return ({
                                content: (
                                    <MainButton
                                        onClicked={(e) => { e?.stopPropagation?.(); props.onStartAnalytics(item) }}
                                        title={ `"${item.title}"\n${intl().formatMessage({ id: LOCALIZATION.analytics_game_state_not_started } )}` }
                                    />
                                ),
                            });
                        }
                        case AnalyticsAnalyticsStatus.WaitingTeamMapping:
                        {
                            return ({
                                content: (
                                    <MainButton
                                        onClicked={(e) => { e?.stopPropagation(); props.onStartMappingTeamId(item) }}
                                        title={ `"${item.title}"\n${intl().formatMessage({ id: LOCALIZATION.analytics_game_start_mapping_team_id } )}` }
                                    />
                                ),
                            });
                        }
                        case AnalyticsAnalyticsStatus.Progress: {
                            return ({
                                content: (
                                    <VertGrid>
                                        <Box className={props.rowClasses.rowText}>
                                            {`"${item.title}"`}
                                        </Box>
                                        <Box className={props.rowClasses.rowText}>
                                            <Box  sx={{ color: Colors.textBlue, fontWeight: Sizes.boldWeight }}>
                                                {`${intl().formatMessage({ id: LOCALIZATION.analytics_game_state_in_progress },
                                                                    {percent: `${item?.analytics_progress}%`} )}`}
                                            </Box>
                                        </Box>
                                    </VertGrid>
                                )
                            })
                        }
                        case AnalyticsAnalyticsStatus.Done: {
                            return ({
                                content: (
                                    <MainButton
                                        buttonType={ButtonType.Outline}
                                        onClicked={(e) => { e?.stopPropagation(); props.onViewCompletedAnalytics(item) }}
                                        title={ `"${item.title}"\n${intl().formatMessage({ id: LOCALIZATION.analytics_game_state_done } )}` }
                                    />
                                ),
                            });
                        }
                        case AnalyticsAnalyticsStatus.Failed: {
                            return ({
                                content: (
                                    <VertGrid>
                                        <Box className={props.rowClasses.rowText}>
                                            {`"${item.title}"`}
                                        </Box>
                                        <Box className={props.rowClasses.rowText} >
                                            <Box  sx={{ color: Colors.mainRed, fontWeight: Sizes.boldWeight }}>
                                                { intl().formatMessage({ id: LOCALIZATION.analytics_game_state_failed } ) }
                                            </Box>
                                        </Box>
                                    </VertGrid>
                                )
                            })
                        }
                    }
                }
                return ({
                    content: "-",
                });
            }
        }

        return ({
        content: (item?.[cellName] + "" || ''),
        });
    };
}