// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { isEqual } from 'lodash';
import { cloneDeep, merge } from 'lodash';

import uuid from '../../../../Tools/uuid';
import CameraConfig from '../../../../Data/NapiCameraConfig';
import StreamingProfile, {DestStreamBlock, isValidDestStreamBlock} from '../../../../Data/NapiStreamingProfile';
import { intl } from '../../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../../Localization';


export const updateProfileItemWithDefaults = (itemName: string | undefined, profile: StreamingProfile | undefined, defaultValues: CameraConfig | undefined): StreamingProfile => {
    if (profile) { // profile already exist. update it with defaults
        return {
            ...profile,
            config: (profile.config && defaultValues) ? merge(cloneDeep(defaultValues), profile.config) : cloneDeep(profile.config)
        }
    }
    return {
        id: uuid(),
        name: itemName || intl().formatMessage({ id: LOCALIZATION.stream }),
        config: cloneDeep(defaultValues),
        custom: {
            storePanoramaVideoToSsd: false,
        },
    }
};

const isValidDestStreamBlocks = (destStreamBlocks?: DestStreamBlock[]): boolean => {
    if (!destStreamBlocks || destStreamBlocks.length === 0) { 
        return true; 
    }

    for (let i = 0; i < destStreamBlocks.length; i += 1) {
        const item = destStreamBlocks[i];

        if (!isValidDestStreamBlock(item)) {
            return false;
        }
    }

    return true;
};

export const isAllowSubmit = (editedItem: StreamingProfile, sourceItem?: StreamingProfile) => {
    if (!editedItem.name) {
        return false;
    }

    const equal = isEqual(editedItem, sourceItem)
    if (!equal) {
        const destBlockValid = isValidDestStreamBlocks(editedItem.custom?.destStreamBlock)        
        if (!destBlockValid) {
            return false;
        }

        return true;
    }

    return false
};


