// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import LOCALIZATION from "../../Localization";
import { intl } from "../../Localization/LocalizationProvider";

export type NoStream = Readonly<{
  id: string;
  name: string;
}>;

export const NO_STREAM_ID = 'No_Stream'

export const NO_STREAM = () : NoStream => ({
  id: NO_STREAM_ID,
  name: intl().formatMessage({ id: LOCALIZATION.no_stream }),
})

