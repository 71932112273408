// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import Order from '../../../_Networking/ReactQuery/Order';
import fetchJSONData from '../../../_Networking/ReactQuery/fetch';
import { PAGE_LIMIT, ANALYTICS_BACKEND_URL } from '../../../../constants';
import { queryCache, queryClient } from '../../../../AppProviders';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import ResponseSingle from '../../../_Networking/ReactQuery/ResponseSingle';
import AnalyticsExternalTeam from '../AnalyticsExternalTeam';
import { TeamForActionProps, Action, ActionType } from './useMutation';
import { getCachedDataAllPagedItems, Result, usePagedItems } from '../../../_Networking/ReactQuery/template';

type FetchProps = Readonly<{
  page: number;
  limit?: number;
  order?: Order;
  orderBy?: 'id';
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'analytics-external-teams-list';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.page,
  props.limit || PAGE_LIMIT,
  props.orderBy,
  props.order,
]);

const getAllCached = () => (
  getCachedDataAllPagedItems<AnalyticsExternalTeam>(getKeyWithoutPage())
);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<ResponseMany<AnalyticsExternalTeam[]>>(getKey(props))
);

const flushCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};

const postTeam = async (team: TeamForActionProps): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<boolean>>(
        `${ANALYTICS_BACKEND_URL}/teams`,
        {
          method: 'POST',
          body: JSON.stringify({ ...team.team }),
        }
    );

    if (!data?.error) {
      return ({
        team: team,
        type: ActionType.Patch,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Edit Team');
    }
  } catch (error) {
    throw error;
  }
};

const patchTeam = async (team: TeamForActionProps): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<boolean>>(
        `${ANALYTICS_BACKEND_URL}/teams/${team.id}`,
        {
          method: 'PATCH',
          body: JSON.stringify({ ...team.team }),
        }
    );

    if (!data?.error) {
      return ({
        team: team,
        type: ActionType.Patch,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Edit Team');
    }
  } catch (error) {
    throw error;
  }
};

const deleteTeam = async (team: TeamForActionProps): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<boolean>>(
        `${ANALYTICS_BACKEND_URL}/teams/${team.id}`,
        {
          method: 'DELETE',
          body: JSON.stringify({ id: team.id }),
        }
    );

    if (!data?.error) {
      return ({
        team: team,
        type: ActionType.Delete,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Delete Team');
    }
  } catch (error) {
    throw error;
  }
};

const fetchData = async (props: FetchProps): Promise<ResponseMany<AnalyticsExternalTeam[]>> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  const { limit = PAGE_LIMIT } = props;
  const offset = (props.page * limit);
  let params = `?limit=${ limit }&offset=${ offset }`;

  switch (props.order) {
    case Order.ASC: {
      params += `&sort_by=time_asc`; break;
    }
    case Order.DESC: {
      params += `&sort_by=time_desc`; break;
    }
  }

  return await fetchJSONData<ResponseMany<AnalyticsExternalTeam[]>>(
    `${ANALYTICS_BACKEND_URL}/teams${ params }`,
    {
      method: 'GET',
    }
  );
};


type UseFetchProps = Readonly<{
  enableToFetch: boolean;
} & FetchProps>;


const useFetch = (props: UseFetchProps): Result<AnalyticsExternalTeam> => {
  const { enableToFetch = true, page: pg = 0, limit = PAGE_LIMIT } = props;
  const fetchTemplate = async (queryKey: QueryKey, page: number = 0) => fetchData({
    page,
    limit,
    order: props.order,
    orderBy: props.orderBy,
  });

  return usePagedItems<AnalyticsExternalTeam>({
    page: pg,
    fetch: fetchTemplate,
    queryKey: getKey({
      page: pg,
      limit,
      order: props.order,
      orderBy: props.orderBy,
    }),
    config: {
      enabled: !!enableToFetch,
    },
  });
};


export {
  getKey,
  useFetch,
  getCached,
  flushCache,
  getAllCached,
  postTeam,
  patchTeam,
  deleteTeam,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
