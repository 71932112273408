// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { List, RadioGroup } from '@mui/material';

import StreamSettingsProfileItem from './item';
// import FetchErrorNotice from './../../../UI/FetchError';
import Spinner from '../../../Components/_BaseUI/Spinner/Spinner';
import { NO_STREAM, NO_STREAM_ID,  NoStream } from '../../../Data/NapiStreamingProfile/constants';
import StreamingProfile from '../../../Data/NapiStreamingProfile';
import { EMPTY_ARRAY } from '../../../constants';


type Props = Readonly<{
  isMaintenanceModeOn: boolean;
  isSuccessLoadedData: boolean;
  isChangeConfigInProgress: boolean;
  selectedProfileID: string;
  profiles?: StreamingProfile[];
  onSelect: (item: StreamingProfile | NoStream) => void;
  onCopy: (item: StreamingProfile) => void;
  onDelete: (item: StreamingProfile) => void;
  onEdit: (item: StreamingProfile) => void;
}>;


const StreamSettingsProfilesList: React.FC<Props> = (props: Props) => {
  if (!(props.isSuccessLoadedData)) {
    return (<Spinner/>);
  }

  return (
    <List>
      <RadioGroup
        defaultValue={ NO_STREAM_ID }
        value={ props.selectedProfileID }
        name="radio-buttons-group"
      >
        <StreamSettingsProfileItem
          checkboxDisabled={ (props.isMaintenanceModeOn || props.isChangeConfigInProgress) }
          optionsDisabled={ props.isChangeConfigInProgress }
          selectedProfileID={ props.selectedProfileID }
          key={ NO_STREAM_ID }
          item={ NO_STREAM() }
          onSelect={ props.onSelect }
        />
        {
          (props.profiles || EMPTY_ARRAY)
            .map((item: StreamingProfile) => (
              <StreamSettingsProfileItem
                checkboxDisabled={ (props.isMaintenanceModeOn || props.isChangeConfigInProgress) }
                optionsDisabled={ props.isChangeConfigInProgress }
                key={ item.id }
                selectedProfileID={ props.selectedProfileID }
                item={ item }
                onSelect={ props.onSelect }
                onEdit={ props.onEdit }
                onCopy={ props.onCopy }
                onDelete={ props.onDelete }
              />
            ))
        }
      </RadioGroup>
    </List>
  );
};


export default StreamSettingsProfilesList;
