// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import SortOrderSelector, { SortOrderSelector_StoredValue } from '../../Components/_BaseUI/SortOrderSelector';
import VideoArchiveItem from './VideoArchiveItem';
import Order from '../../Data/_Networking/ReactQuery/Order';
import ButtonsInRowElement from '../../Components/_BaseUI/ButtonsInRowElement';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import AnalyticsVideoArchive from '../../Data/Analytics/AnalyticsVideoArchive/AnalyticsVideoArchive';
import usePagedData from '../../Data/Analytics/AnalyticsVideoArchive/hook';
import useMutationArchive, { ActionType, ArchiveForActionProps } from '../../Data/Analytics/AnalyticsVideoArchive/fetch/useMutation';
import FieldSetDateComponent from '../../Components/_BaseUI/FieldSet/FieldSetDateComponent';
import { FieldSetInputType } from '../../Components/_BaseUI/FieldSet';
import Pagination from '../../Components/PaginationControl';
import LOCALIZATION from '../../Localization';
import { EMPTY_ARRAY } from '../../constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Grid } from '@mui/material';
import EditTitleSubtitlePopup, { EditTitleSubtitleFields } from '../../Components/_PopupControls/EditTitleSubtitlePopup';
import { AnalyticsType } from '../../Data/Analytics/AnalyticsTypes';
import { useAnalyticsConfig } from '../../Data/Analytics/AnalyticsConfig/hook';
import CameraIdSelect, { CameraIdSelect_StoredValue } from '../../Components/_BaseUI/CameraIdSelect';
import { useNavigationType, NavigationType } from 'react-router-dom';
import { useEffectAfterMount } from '../../Tools/useEffectAfterMount';

export type VideoArchiveContentProps = Readonly<{
  date: string | null;
  onChangeDate: (date: string | null) => void;
} & WrappedComponentProps>;

export type SortBy = Readonly<{
  date: string;
}>;

export const sessionStoreKey = "VideoArchive"

function storedPageNumber(navigationType: NavigationType): number {
  try {
    if (sessionStoreKey?.length) {// if sessionStoreKey enabled then recover stored value from session storage only if no active value
      if (navigationType === NavigationType.Push) { // if direct open page = PUSH then always use page 0 and reset stored value
        window.sessionStorage.setItem(sessionStoreKey + "-pageNumber", "0");
        return 0;
      }
      else {
        const storedValue = window.sessionStorage.getItem(sessionStoreKey + "-pageNumber");
        if (storedValue !== null) {
          return parseInt(storedValue, 10);
        }
      }
    }
  } catch(ignore) {}
  return 0;
}

const VideoArchiveContent: React.FC<VideoArchiveContentProps> = (props: VideoArchiveContentProps) => {
  const itemsOnPageCount = () => ( 9 )

  const { date } = props;
  const navigateType = useNavigationType();

  const [page, setPage] = React.useState( () => (storedPageNumber(navigateType)) );
  const [limit] = React.useState( itemsOnPageCount() );
  const [total, setTotal] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>(() => (SortOrderSelector_StoredValue(sessionStoreKey) || Order.DESC));
  const [filterByCameraID, setFilterByCameraID] = React.useState<string|undefined>(() => (CameraIdSelect_StoredValue(sessionStoreKey)));
  const { mutateAsync: mutateConfig } = useMutationArchive();

  const [renameItem, setRenameItem] = React.useState<AnalyticsVideoArchive | undefined>(undefined);

  const {
    status,
    data: archive,
    handleFlushData,
  } = usePagedData({
    page,
    limit,
    order,
    filterByDate: date || undefined,
    filterByCameraId: (filterByCameraID && filterByCameraID !== "none") ? filterByCameraID : undefined,
    orderBy: 'date',
  });

  const {
    status: analyticsConfigStatus,
    data: analyticsConfig
  } = useAnalyticsConfig({});
  
  useEffectAfterMount(() => { // use AfterMount to prevent race when view mounted
    try {
      window.sessionStorage.setItem(sessionStoreKey + "-pageNumber", page+"");
    } catch(ignore) {}
  }, [page]);

  const handleChangeDate = (date: string|null) => {
    props.onChangeDate(date)
    setPage(0);
  };

  const handleDelete = async (IDs: string[]) => {
    try {
      const archives: ArchiveForActionProps[] = (archive?.data || [])
        .filter((item: AnalyticsVideoArchive) => (IDs.includes(item.id)))
        .map((item: AnalyticsVideoArchive) => ({
          id: item.id,
          archive: item,
        }));

      await mutateConfig(
        {
          archives,
          type: ActionType.Delete,
        },
        {
          onSuccess: () => {
            handleFlushData();
          },
        }
      );
    } catch (error) {
      alert(error);
    }
  };
  const handleItemRenameClicked = (clickedItemId: string, item?: AnalyticsVideoArchive) => {
    setRenameItem(item);
  };
  const handleItemDeleteClicked = (clickedItemStartAt: string) => {
    if (clickedItemStartAt && clickedItemStartAt.length !== 0) {
      handleDelete([clickedItemStartAt]);
    }
  };

  const handleOrderByChanged = (orderBy: keyof SortBy, order: Order) => {
    setOrder(order);
  };
  const renderPagination = () => {
    if (total) {
      const totalPages = Math.ceil(total / limit);

      if (totalPages > 0) {
        return (
          <Pagination
            totalPages={ totalPages }
            currentPage={ page }
            onClick={ setPage }
          />
        );
      }
    }

    return <></>;
  };

  const buttons1 = [
    <SortOrderSelector<SortBy>
      sessionStoreKey={ sessionStoreKey } 
      key="sort"
      orderBy={ [ 'date' ] }
      orderByTitle={ [ props.intl.formatMessage({ id: LOCALIZATION.sort_by_time }) ] }
      selectedOrderBy={ 'date' }
      selectedOrder={ order }
      onChange={ handleOrderByChanged }
    />,
    {
      space : 10 ,
    }, 
    <FieldSetDateComponent
      key="FieldSetDateComponent"
      labelText={ props.intl.formatMessage({ id: LOCALIZATION.filter_by_date }) }
      input={ {
        sessionStoreKey: sessionStoreKey,
        type: FieldSetInputType.Date,
        noWrapper: true,
        allowRemoveDate: true,
        noValueText: props.intl.formatMessage({ id: LOCALIZATION.filter_by_date_off }),
        dateFormat: 'YYYY-MM-DD',
        value: {
          date: (date || null),
        },
        onChangeDate: handleChangeDate,
      } }
    />,
    {
      space : 10 ,
    }, 
    <CameraIdSelect 
      sessionStoreKey={ sessionStoreKey }
      label={ props.intl.formatMessage({ id: LOCALIZATION.filter_by_camera }) }
      noneValueText= { props.intl.formatMessage({ id: LOCALIZATION.filter_no_filter }) }
      selectedCameraID={ filterByCameraID }
      onSelect={(teamId) => {
        setPage(0);
        setFilterByCameraID(teamId || undefined);
      }} 
    />,
    {
      space :-1 ,
    }, 
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.refresh }),
      iconButton: <RefreshIcon /> ,
      onClick: handleFlushData,
    },
  ];

  const buttons2 = [
    {
      space :-1 ,
    }, 
    renderPagination()
  ];

  React.useEffect(() => {
    return handleFlushData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (archive && archive?.meta?.pagination && !isNaN(archive?.meta?.pagination?.total)) {
      setTotal(archive.meta.pagination.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archive?.meta?.pagination?.total]);


  const handleArchiveRename = async (editedItem: EditTitleSubtitleFields) => {
    try {
      await mutateConfig(
        {
          archives:[{
            id: editedItem.id || "",
            archive: { 
              id: editedItem.id,
              type: renameItem?.type || AnalyticsType.VideoRecord,
              title: editedItem.title,
              subtitle: editedItem.subtitle,
             },
          }],
          type: ActionType.Patch,
        }, {
          onSuccess: () => {
            handleFlushData();
            handleCloseRenamePopup();
          },
        },
      );
    } catch (error) {
      alert(error);
      handleFlushData();
      handleCloseRenamePopup();
    }
  };

  const handleCloseRenamePopup = () => {
    setRenameItem(undefined);
  };

  const renderItem = (item: AnalyticsVideoArchive) => (
    <VideoArchiveItem
      id={ item.id }
      hideDate={ (!!props.date) }
      item={ item }
      analyticsConfig={ analyticsConfig }
      onRename={ handleItemRenameClicked }
      onDelete={ handleItemDeleteClicked }
      intl={ props.intl }
    />
  );

  const renderPanel = () => (
    <div>
      <Box sx= {{
        marginBottom : '15px'
      }}
      >
        <ButtonsInRowElement
          id = "buttonsRow1"
          buttons={ buttons1 }
        />
      </Box>
      <ButtonsInRowElement
        id = "buttonsRow2"
        buttons={ buttons2 }
      />
    </div>
  );

  return (
    <>
      { renderPanel() }
      {
        (status === 'loading' || analyticsConfigStatus === 'loading' || archive === undefined) ?
          <>
          <br /><br />         
          <Spinner
            isHidden={ false }
          /><br /><br />
          </>
          :
          (archive?.data?.length) ?
          <Box sx={{ padding: '30px 0' }}>
            <Grid
              container
              spacing={ '42px' }
              rowSpacing={ '48px' }
            >
              { (archive?.data || EMPTY_ARRAY).map(renderItem) }
            </Grid>
          </Box>
          :
          <>
          <br /><br />         
          <Grid
           sx={ {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          } }
          >
          { props.intl.formatMessage({ id: LOCALIZATION.page_video_archive_empty_list }) }
          </Grid>
          <br /><br />
          </>
      }
      {/* { (status !== 'loading' && archive !== undefined && (archive?.data || EMPTY_ARRAY).length !== 0) && renderPanel() } */}
      {
        (renameItem) &&
          <EditTitleSubtitlePopup
            popupTitle={ props.intl.formatMessage({ id: LOCALIZATION.rename }) }
            item={ renameItem }
            onSubmit={ handleArchiveRename }
            onClose={ handleCloseRenamePopup }
          />
      }
    </>
  );
};


export default injectIntl(VideoArchiveContent);
