// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import { FieldSetInputType } from '.';
import { createStyles, makeStyles } from '@mui/styles';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Colors } from '../../../Configuration/Styles/Colors';
import { Link } from '@mui/material';

export type StaticLabelTypes = 'default' | 'warning' | 'error'

export type FieldSetInputStaticLabel = Readonly<{
  type: FieldSetInputType.StaticLabel;
  valueType?: StaticLabelTypes
  value?: string;
  actionButtonLabel?: string;
  onActionButtonPress?: () => void;
}>;

type Props = Readonly<{
  input: FieldSetInputStaticLabel;
}>;


const useStyles = makeStyles(() => createStyles({
    labelDefault: {
      fontWeight: Sizes.mediumWeight,
      fontFamily: Fonts.main,
      fontSize: Sizes.heading,
      color: Colors.headingMain,
    },
    labelWaring: {
      fontWeight: Sizes.mediumWeight,
      fontFamily: Fonts.main,
      fontSize: Sizes.heading,
      color: Colors.mainGreenWarning,
    },
    labelError: {
      fontWeight: Sizes.mediumWeight,
      fontFamily: Fonts.main,
      fontSize: Sizes.heading,
      color: Colors.mainRed,
    },
  }));

const FieldSetStaticLabel: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <div
    className={ (props.input.valueType === 'error') ? classes.labelError : (props.input.valueType === 'warning') ? classes.labelWaring : classes.labelDefault }
    >
      { props.input.value }
      {(props.input.actionButtonLabel && props.input.onActionButtonPress) && 
        <Link
          component="button"
          variant="body2"
          style={{ paddingLeft: '10px' }}
          onClick={ props.input.onActionButtonPress }
        >
          { props.input.actionButtonLabel }
        </Link>
      }
    </div>
  );
};


export default FieldSetStaticLabel;
