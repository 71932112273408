// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { Attributes } from 'react';
import { Box, ButtonBase, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Team, { TeamRefShort } from '../../../Data/Analytics/AnalyticsTeamRef';
import useFetchAllPagesTeamRefs from '../../../Data/Analytics/AnalyticsTeamRef/hook';
import Popup from '../../_Layout/Popup';
import Card from '../../../Components/_Layout/Card';
import FieldSet, { FieldSetInputType } from '../FieldSet';
import { Colors } from '../../../Configuration/Styles/Colors';
import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import LOCALIZATION from '../../../Localization';
import { intl } from '../../../Localization/LocalizationProvider';
import { fetchImage } from '../../../Data/_Networking/ReactQuery/fetch';
import { useEffectAfterMount } from '../../../Tools/useEffectAfterMount';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type Props = Readonly<{
  /** if defined then constrol state is stored in browser session and recovered on control present.  */
  sessionStoreKey?: string;
  label?: string;
  selectedExternalTeamID?: string | null;
  noneValueText?: string;
  showRequiredMark?: boolean;
  team1ImagesUrl?: string;
  team2ImagesUrl?: string;
  selectedImgIndex?: number | null;
  onSelect: (selectedExternalTeamID: Team['id'] | undefined | null, selectedImg: number | undefined | null) => void;
}& Attributes>;

const useLabelStyles = makeStyles(() => ({
  outlined: {
    "&:not(.MuiInputLabel-shrink)": {
      marginTop: '-8px',
    }
  }
}));

const useStyles = makeStyles(() => ({
  paper: {    
    visibility: "hidden"
  },
}));
const useElementsStyles = makeStyles(() => ({
  teamImage: {
    overflow: 'hidden',
    minWidth: "100px",
    width: "97%",
    aspectRatio: "16/9",
    objectFit: "cover",
    backgroundColor: Colors.backgroundGrey,
    borderRadius: '8px',
    border: 'solid 1px #0000004C',
    marginLeft: "1%",
    marginRight: "1%",
    marginTop: "1%",
    marginBottom: "-1%",
  },
  titleText: {
    fontFamily: Fonts.main,
    fontSize: Sizes.titleFiled,
    fontWeight: Sizes.regularWeight,
    color: Colors.mainTitle,
},
}));

export function TeamRefWithImageSelect_StoredValue(sessionStoreKey: string){
  try {
    if (sessionStoreKey?.length) {// if sessionStoreKey enabled then recover stored value from session storage only if no active value
      const storedValue = window.sessionStorage.getItem("TeamRefWithImageSelect-"+sessionStoreKey);
      if (storedValue !== null) {
        return storedValue
      }
    }
  } catch(ignore) {}
  return undefined
}

const TeamRefWithImageSelect: React.FC<Props> = (props: Props) => {
  const [selectedExternalTeamID, setSelectedExternalTeamID] = React.useState<string>(props.selectedExternalTeamID || ((props.noneValueText?.length) ? "none" : ''));
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState<number | null>(props.selectedImgIndex || null);
  const [team1UrlImgRef, setTeam1UrlImgRef] = React.useState<HTMLImageElement | null>(null);
  const [team2UrlImgRef, setTeam2UrlImgRef] = React.useState<HTMLImageElement | null>(null);

  const classes = useStyles();
  const labelClasses = useLabelStyles();
  const elementsClasses =   useElementsStyles();
  const hasImageSelectDialog = (!!props.team1ImagesUrl?.length || !!props.team2ImagesUrl?.length)

  const {
    // status,
    allCached: teams,
  } = useFetchAllPagesTeamRefs();

  const downloadTeam1Image = async () => {
    try {
      if (team1UrlImgRef && !!props.team1ImagesUrl?.length) {
        const img = await fetchImage(props.team1ImagesUrl);
        team1UrlImgRef.setAttribute('src', img);

        team1UrlImgRef.onerror = (error) => {
          team1UrlImgRef?.setAttribute('src', `/placeholder-operator.jpg`);
        }
      }
    } catch (ignore) {
    }
  };
  const downloadTeam2Image = async () => {
    try {
      if (team2UrlImgRef && !!props.team2ImagesUrl?.length) {
        const img = await fetchImage(props.team2ImagesUrl);
        team2UrlImgRef.setAttribute('src', img);

        team2UrlImgRef.onerror = (error) => {
          team2UrlImgRef?.setAttribute('src', `/placeholder-operator.jpg`);
        }
      }
    } catch (ignore) {
    }
  };

  useEffectAfterMount(() => { // use AfterMount to prevent race when view mounted
    try {
      if (props.sessionStoreKey?.length) {// if enabled then recover stored value from session storage
        window.sessionStorage.setItem("TeamRefWithImageSelect-"+props.sessionStoreKey, selectedExternalTeamID);
      }
    } catch(ignore) {}
  }, [selectedExternalTeamID, props.sessionStoreKey]);


  React.useEffect(() => {
    try {
      if (props.sessionStoreKey?.length && !props.selectedExternalTeamID) {// if sessionStoreKey enabled then recover stored value from session storage only if no active value
        const storedValue = window.sessionStorage.getItem("TeamRefWithImageSelect-"+props.sessionStoreKey);
        if (storedValue !== null && storedValue !== selectedExternalTeamID) {
          setNewTeamId(storedValue);
        }
      }
    } catch(ignore) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  React.useEffect(() => {
    downloadTeam1Image();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team1UrlImgRef]);

  React.useEffect(() => {
    downloadTeam2Image();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team2UrlImgRef]);

  const teamsArr = () => {
    let arr: TeamRefShort[] = (teams?.data || [])
    if (props.noneValueText?.length) {
      arr.splice(0,0, {
        externalTeamId: "none", 
        name: props.noneValueText,
        shortName: props.noneValueText,
      });
    }
    return arr
  };

  const renderMenuOptions = () => (
    teamsArr()
    .map(({ externalTeamId, name }) => (      
      <MenuItem
        key={ externalTeamId }
        value={ externalTeamId }
      >
        { `${ name }` }
      </MenuItem>
    ))
  );

  const setNewTeamId = (newTeamId: string) => {
    setSelectedExternalTeamID(newTeamId);
    props.onSelect((newTeamId === "none") ? undefined : newTeamId, selectedImg)
  };
  const handleChange = (event: SelectChangeEvent<typeof selectedExternalTeamID>) => {
    setNewTeamId(event.target.value);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const renderTeamImage = (teamIndex: number, imageUrl: string | undefined) => {
    if (!imageUrl?.length) {
      return null;
    }

    return (
      <Grid item xs={ 6 }>
        <ButtonBase 
          component="div" 
          onClick={ () => {
            setSelectedImg(teamIndex);
          } }
          sx={{
            '&:hover': {
              backgroundColor: alpha(Colors.menuDefaultHover,0.15),
              border: 'none',
            },
          }}
        >
          <Box
            sx = {{
              margin: 1,
              borderRadius: '8px',
              outline: (teamIndex === (selectedImg || 0)) ? `3px solid ${Colors.accentBlue}` : "",
            }}
          >
            <img
              ref={ (teamIndex === 0) ? setTeam1UrlImgRef : setTeam2UrlImgRef }
              className={ elementsClasses.teamImage }
              alt={ `${teamIndex}` }
            />
          </Box>
        </ButtonBase>
      </Grid>
    )
  }

  const showResetValue = (props.noneValueText?.length && selectedExternalTeamID && selectedExternalTeamID !== "none");

  const handleResetValueClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    setNewTeamId("none")
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box>
      <Box key={ props.key || props.label } sx = {{ minWidth: 130 }}>
        <FormControl fullWidth>
          <InputLabel 
            id="select-sort-label" 
            classes={labelClasses}
            required={ props.showRequiredMark }
          >{ props.label }</InputLabel>
          <Select
            IconComponent={ (showResetValue) ? Box : ArrowDropDownIcon }
            MenuProps={ (hasImageSelectDialog) ? { classes } : undefined}
            labelId="select-sort-label"
            id="select-sort-select"
            value={ selectedExternalTeamID }
            label={ props.label }
            onChange={handleChange}
            sx = {{ height: '38px' }}
            open={isOpen}
            onClose={handleClose}
            onOpen={ () => { setIsOpen(true) }}
            endAdornment={(
              <InputAdornment 
              position="end"
              disablePointerEvents={ !showResetValue }
              >
                {
                  (showResetValue) ?
                    <IconButton 
                    edge="end"
                    color="warning" 
                    aria-label="reset" 
                    component="label"
                    onClick= { handleResetValueClick }
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  :
                    null
                }
              </InputAdornment>
            )}
          >
          { renderMenuOptions() }
          </Select>
        </FormControl>
      </Box>


      {
        (isOpen && hasImageSelectDialog) &&
        <Popup
          isWide
          headerTitle={ props.label || "" }
          closeTitle="OK"
          onClose={ handleClose }
        >
            <Card>
              <FieldSet
                key={ 'Team' }
                label={ intl().formatMessage({ id: LOCALIZATION.team }) }
                // helperText={ 'Short team name' }
                // tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_game_type_help }) }
                input={ {
                  type: FieldSetInputType.Select,
                  value: selectedExternalTeamID,
                  options: teamsArr().map(({ externalTeamId, shortName }) => ( {id: externalTeamId, name: shortName } )),
                  onChange: (externalTeamID: string) => ( setNewTeamId(externalTeamID) ),
                } }
              />

              <Box sx={{ paddingTop: 1}}>
                <Box className={ elementsClasses.titleText }>
                  { intl().formatMessage({ id: LOCALIZATION.analytics_select_player_match_team }) }
                </Box>
                <Grid container spacing={ 1 }> 
                  { renderTeamImage(0, props.team1ImagesUrl) }
                  { renderTeamImage(1, props.team1ImagesUrl) }
                </Grid>
              </Box>
            </Card>
        </Popup>
      }
    </Box>
  );
};


export default TeamRefWithImageSelect;
