// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import { Colors } from '../../../Configuration/Styles/Colors';
import YAxisLabel from './YAxisLabel';


type Props = Readonly<{
  x: number;
  y: number;
  value: string;
}>;


function YAxisTick(props: Props) {
  const value = props.value;
  const y = props.y + 4

  return (
    <YAxisLabel textAnchor="start" x={ 0 } y={ y } fill={ Colors.headingMain }>{ value }</YAxisLabel>
  )
}

export default YAxisTick;
