// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import VertGrid from '../../Components/_Layout/VertGrid';

type Props = Readonly<{
    children: any;
    onOptionBar: JSX.Element | JSX.Element[];
}>;

const StreamingConfigurationCol: React.FC<Props> = (props: Props) => {
    return (
        <VertGrid
        spacing={ 2 }
        >
            { props.children }
            { props.onOptionBar }
        </VertGrid>
    );
};


export default StreamingConfigurationCol;
