// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { cloneDeep } from 'lodash';
import { AnalyticsStatDistanceForTime, AnalyticsStatDistanceGame } from "../../../Data/Analytics/AnalyticsStatDistance/AnalyticsStatDistance";
import { AnalyticsStatPossessionForTime, AnalyticsStatPossessionTeam } from "../../../Data/Analytics/AnalyticsStatPossession/AnalyticsStatPossession";
import { AnalyticsStatSpeedForTime, AnalyticsStatSpeedTeam } from "../../../Data/Analytics/AnalyticsStatSpeed/AnalyticsStatSpeed";
import { AnalyticsStatGranularity, AnalyticsTimeIntervalTypeHeader, AnalyticsTimeIntervalTypeHeaderAny, analyticsTimeIntervalName } from "../../../Data/Analytics/AnalyticsTypes";
import LOCALIZATION from "../../../Localization";
import { intl } from "../../../Localization/LocalizationProvider";
import { AnalyticsConvertedDataType, AnalyticsConvertedItem, AnalyticsConvertedValue, analyticsAnalyticsConvertedDataTypeName, analyticsConvertAvgSpeed, analyticsConvertAvgTimeInPossession, analyticsConvertDistanceCovered, analyticsConvertIntensity, analyticsConvertPossession, analyticsConvertedValueFormated } from "../../../Data/Analytics/AnalyticsConverters";
import { IconFigureRun, IconFigureSprint, IconFigureWalk, SvgInHtml } from "../../../Icons/Icons";
import { AnalyticsConvertedDataUnit } from "../../../Tools/analyticsUnitsConvert";

export type BarChartWithPartsItemDataContent = Readonly<{
    valueLabel?: string | JSX.Element;
    valueName?: string | JSX.Element;
    value: number | undefined;
    valueFormated?: string | JSX.Element;
    ratio: number | undefined;
    units?: AnalyticsConvertedDataUnit;
}>;
const EMPTY_ITEM_CONTENT: BarChartWithPartsItemDataContent = {
    value: 0,
    ratio: 0
}

export type BarChartWithPartsItemData = Readonly<{
    name: string;
    label: string | undefined;
    units?: string;
    val1_data0?: BarChartWithPartsItemDataContent,// contain main data for val1
    val1_data1?: BarChartWithPartsItemDataContent,// contain part 1 for val1
    val1_data2?: BarChartWithPartsItemDataContent,// contain part 2 for val1
    val1_data3?: BarChartWithPartsItemDataContent,// contain part 3 for val1
    val2_data0?: BarChartWithPartsItemDataContent,// contain main data for val1
    val2_data1?: BarChartWithPartsItemDataContent,// contain part 1 for val2
    val2_data2?: BarChartWithPartsItemDataContent,// contain part 2 for val2
    val2_data3?: BarChartWithPartsItemDataContent,// contain part 3 for val2
}>;
const EMPTY_ITEM_VALUE: BarChartWithPartsItemData = {
    name: "",
    label: "",
    val1_data0: EMPTY_ITEM_CONTENT,// contain main data for val1
    val1_data1: EMPTY_ITEM_CONTENT,// contain part 1 for val1
    val1_data2: EMPTY_ITEM_CONTENT,// contain part 2 for val1
    val1_data3: EMPTY_ITEM_CONTENT,// contain part 3 for val1
    val2_data0: EMPTY_ITEM_CONTENT,// contain main data for val1
    val2_data1: EMPTY_ITEM_CONTENT,// contain part 1 for val2
    val2_data2: EMPTY_ITEM_CONTENT,// contain part 2 for val2
    val2_data3: EMPTY_ITEM_CONTENT,// contain part 3 for val2
}

export type BarChartWithPartsData = {
    [key in AnalyticsConvertedDataType]?: BarChartWithPartsItemData
}

function valueWithParts( 
    teamName: string | undefined, 
    part: AnalyticsConvertedValue, 
    valueLabel?: string | JSX.Element, 
    parentName?: string, 
    ratio?: number): BarChartWithPartsItemDataContent 
{
    return {
        valueLabel,
        valueName: (teamName && part.name) ? teamName + " - " + part.name : teamName,
        value: part.value,
        valueFormated: analyticsConvertedValueFormated(part),
        ratio: (ratio !== undefined) ? ratio : part.value,
        units: part.units,
    }
}
  
function updateDataWithValue( teamName: string | undefined, outData: BarChartWithPartsItemData, keyPrefix: string, item: AnalyticsConvertedItem, value?: AnalyticsConvertedValue) {
    if (value) {// add all existing parts
        type BarChartDataVal = {
            [key: string|number]: any
        }
        const dataMap = outData as BarChartDataVal;

        const valueLabel = analyticsConvertedValueFormated(value);

        if (!!value.parts?.length) {
            for (let i = 0; (i < value.parts.length) && (i < 3); i++) {
                dataMap[keyPrefix+(i+1)] = valueWithParts(teamName, value.parts[i], undefined, value.name);
            }
            // if have parts then add main value with 0 ratio to make it 0 height
            dataMap[keyPrefix+0] = valueWithParts(teamName, value, valueLabel, undefined, 0);
        }
        else {
            dataMap[keyPrefix+0] = valueWithParts(teamName, value, valueLabel);
        }

    }
}

const analyticsConvertedDataToBarChartWithPartsItemData = (statName: string, team1Name?: string, team2Name?: string, convertedData?: AnalyticsConvertedItem): BarChartWithPartsItemData => {
    if (!convertedData) {
        return EMPTY_ITEM_VALUE;
    }

    const outData: BarChartWithPartsItemData = {
        name: statName,
        label: statName,
    }
    updateDataWithValue(team1Name, outData, "val1_data", convertedData, convertedData.team1);
    updateDataWithValue(team2Name, outData, "val2_data", convertedData, convertedData.team2);

    return outData;
}

export type BarChartWithPartsItem = {
    id: number; // index to group together same time interval for all data
    timeName: string;// like: Half 1, Half 2,  Overtime
    itemName: string;// like: 1/2, 1/8, 2/8
    titleForTooltip: string;// like: "1-5 minutes statistics"
    data: BarChartWithPartsData;
    child?: BarChartWithPartsItem[]; // granula childs
    hiddenData?: BarChartWithPartsData;// used when make fake items
};

export type BarChartWithPartsStatType = {
    index: AnalyticsConvertedDataType;
    name: string;
};

export type BarChartWithPartsConfig = {
    availableStats: BarChartWithPartsStatType[];// if stat exist in data then is is presented in this list
    data: BarChartWithPartsItem[];
};

function getGranulaNamesArr (
    granularity: AnalyticsStatGranularity,
    timeName: string,
    durationSec: number,
): string[] {
    let granulaNamesArr: string[] = []
    let granulaCount = 0;

    switch (granularity) {
        case AnalyticsStatGranularity.Granularity0: {
            break;
        }
        case AnalyticsStatGranularity.Granularity2: {// 2 = 1/2 ; Half
            // return ["1/2", timeName];
            granulaCount = 2;
            break;
        }
        case AnalyticsStatGranularity.Granularity4: {// 4 = 1/4 ; 1/2; 3/4; Half
            // return ["1/4", "1/2", "3/4", timeName];
            granulaCount = 4;
            break;
        }
        case AnalyticsStatGranularity.Granularity8: {// 8 = 1/8 ; 1/4; 3/8; 1/2
            // return ["1/8", "1/4", "3/8", "1/2", "5/8", "3/4", "7/8", timeName];
            granulaCount = 8;
            break;
        }
    }
    
    const granulaDurationSec = durationSec / granulaCount;
    for(let index = 0; index < granulaCount; index++) {
        const startMinutes = Math.floor(granulaDurationSec * index / 60);
        const endMinutes = Math.floor(granulaDurationSec * (index + 1) / 60);

        granulaNamesArr.push(`${startMinutes+1}-${endMinutes}`);
    }
    // granulaNamesArr.push(timeName);

    return granulaNamesArr;
}

export function createTemplateBarChartWithPartsItemsArr(
    granularity: AnalyticsStatGranularity,
    possessionIntervals?: AnalyticsStatPossessionForTime[],
    distanceIntervals?: AnalyticsStatDistanceForTime[],
    speedIntervals?: AnalyticsStatSpeedForTime[],
): BarChartWithPartsItem[] {
    let tempItems: BarChartWithPartsItem[] = [];

    let longestInterval: AnalyticsTimeIntervalTypeHeader[] | undefined =
        [possessionIntervals, distanceIntervals, speedIntervals]
        .sort((a, b) => (a?.length || 0) - (b?.length || 0)).shift();

    // fill root items with longest interval
    if (longestInterval) {
        const emptyData: BarChartWithPartsData = {};
        for(const key of Object.values(AnalyticsConvertedDataType)) {
            emptyData[key] = EMPTY_ITEM_VALUE;
        }


        let intervalsArr = longestInterval as AnalyticsTimeIntervalTypeHeaderAny[];
        let index = 0; 
        for(const item of intervalsArr) {
            const timeName = analyticsTimeIntervalName(item.type, item.type_order_num);
            const durationSec = item.duration || 45 * 60;

            let child: BarChartWithPartsItem[] = [];
            if (granularity !== AnalyticsStatGranularity.Granularity0 && item.granules) {
                const granulaNamesArr = getGranulaNamesArr(granularity, timeName, durationSec);
                for(let cIndex = 0; cIndex < granulaNamesArr.length; cIndex++) {
                    child.push({
                        id: index * 100 + cIndex,
                        timeName,
                        itemName: granulaNamesArr[cIndex] + "'",
                        titleForTooltip: `${timeName}, ${granulaNamesArr[cIndex]} ${intl().formatMessage({ id: LOCALIZATION.minutes })}`,
                        data: cloneDeep(emptyData),
                    })
                }
            }

            tempItems.push({
                id: index,
                timeName,
                itemName: timeName,
                titleForTooltip: timeName,
                data: cloneDeep(emptyData),
                child,
            })
            index ++;
        }
    }

    return tempItems;
}


export function createBarChartWithPartsConfigForPhysicalReportDetails(
    team1Name: string | undefined,
    team2Name: string | undefined,
    granularity: AnalyticsStatGranularity,
    possessionIntervals?: AnalyticsStatPossessionForTime[],
    distanceIntervals?: AnalyticsStatDistanceForTime[],
    speedIntervals?: AnalyticsStatSpeedForTime[],
): BarChartWithPartsConfig {
    // create filled template array with all names
    let outItems: BarChartWithPartsItem[] = createTemplateBarChartWithPartsItemsArr(
        granularity, possessionIntervals, distanceIntervals, speedIntervals );

    let availableStats: BarChartWithPartsStatType[] =[];

    const getNameForDataIndex = (index: AnalyticsConvertedDataType, firstConverted: AnalyticsConvertedItem | undefined): string => {
        const name = analyticsAnalyticsConvertedDataTypeName(index, firstConverted);
        availableStats.push({
            index, name
        })
        return name;
    }

    function fillDataWithIntervals<E, T extends {
        interval_stat?: E;
        granules?: E[];
    }>
        (type: AnalyticsConvertedDataType, 
        intervalsArr: Array<T> | undefined, 
        converter:((item: E | undefined) => AnalyticsConvertedItem | undefined) ) 
    {
        if (intervalsArr && intervalsArr.length) {
            const statName = getNameForDataIndex(type, converter(intervalsArr[0].interval_stat));
    
            let index = 0;
            for(const stat of intervalsArr) {
                const item = outItems[index];
    
                // score
                item.data[type] = analyticsConvertedDataToBarChartWithPartsItemData(statName, team1Name, team2Name, converter(stat.interval_stat));
    
                // have additonal granulas
                if (granularity !== AnalyticsStatGranularity.Granularity0 && stat.granules) {
                    const granulaCount = Math.min(item.child?.length || 0, stat.granules.length);
                    for(let cIndex = 0; cIndex < granulaCount; cIndex++) {
                        const subItem = item.child?.[cIndex];
                        const subStat = stat.granules[cIndex];
                        if (subItem && subStat) {
                            subItem.data[type] = analyticsConvertedDataToBarChartWithPartsItemData(statName, team1Name, team2Name, converter(subStat));
                        }
                    }                   
                }
    
                index ++;
            }
        }
    }
 
    // fill possession
    fillDataWithIntervals<AnalyticsStatPossessionTeam, AnalyticsStatPossessionForTime>(
        AnalyticsConvertedDataType.possession,
        possessionIntervals,
        (item) => analyticsConvertPossession(item) );

    // fill avg time in possession
    fillDataWithIntervals<AnalyticsStatPossessionTeam, AnalyticsStatPossessionForTime>(
        AnalyticsConvertedDataType.avgTimeInPossession,
        possessionIntervals,
        (item) => analyticsConvertAvgTimeInPossession(item) );
   
    // fill distance
    fillDataWithIntervals<AnalyticsStatDistanceGame, AnalyticsStatDistanceForTime>(
        AnalyticsConvertedDataType.distanceCovered,
        distanceIntervals,
        (item) => analyticsConvertDistanceCovered(item) );
       
    // fill avg speed
    fillDataWithIntervals<AnalyticsStatSpeedTeam, AnalyticsStatSpeedForTime>(
        AnalyticsConvertedDataType.avgSpeed,
        speedIntervals,
        (item) => analyticsConvertAvgSpeed(item) );
              
    // fill intensity
    fillDataWithIntervals<AnalyticsStatSpeedTeam, AnalyticsStatSpeedForTime>(
        AnalyticsConvertedDataType.intensity,
        speedIntervals,
        (item) => analyticsConvertIntensity(item) );

    // flat all data if need
    if (granularity !== AnalyticsStatGranularity.Granularity0) {
        let flatOutItems: BarChartWithPartsItem[] = []
        let index = 0;

        for(const item of outItems) {
            if (item.child) {
                for (const subItem of item.child) {
                    if (subItem) { /*&& isBarLineChartDataNotEmpty(item.data)*/
                        flatOutItems.push({...subItem, id: index});
                        index ++;
                    }
                }
                // add fake divider for parent element
                const fakeEndItem = {
                    ...item, 
                    id: index,
                    hiddenData: cloneDeep(item.data)
                }
                for(const key of Object.values(AnalyticsConvertedDataType)) {
                    fakeEndItem.data[key] = EMPTY_ITEM_VALUE;
                }
                flatOutItems.push(fakeEndItem);
                index ++;
            }
            else {
                flatOutItems.push({...item, id: index});
                index ++;
            }
        }

        outItems = flatOutItems;
    }

    return {
        availableStats,
        data: outItems,
    }
}


export type BarChartWithPartsTeamConfig = Readonly<{
    dataKeyPrefix: string;// key to get value from data
    teamIndex: 1 | 2;
    fillOpacity?: number;
    stackId?: string;// used to group bars with same label in one row (stack)
    icon?: SvgInHtml;
  }>;

// skin config to bar chart with parts
export const barChartWithPartsTeamConfigArr: BarChartWithPartsTeamConfig[] = [
    {
      teamIndex: 1,
      fillOpacity: 0.6,
      dataKeyPrefix: "val1_data1",
      stackId: "val1",
      icon: IconFigureWalk,
    },
    {
      teamIndex: 1,
      fillOpacity: 0.8,
      dataKeyPrefix: "val1_data2",
      stackId: "val1",
      icon: IconFigureRun,
    },
    {
      teamIndex: 1,
      fillOpacity: 1,
      dataKeyPrefix: "val1_data3",
      stackId: "val1",
      icon: IconFigureSprint,
    },
    {
      teamIndex: 1,
      fillOpacity: 1,
      dataKeyPrefix: "val1_data0",
      stackId: "val1",
    },


    {
      teamIndex: 2,
      fillOpacity: 0.6,
      dataKeyPrefix: "val2_data1",
      stackId: "val2",
      icon: IconFigureWalk,
    },
    {
      teamIndex: 2,
      fillOpacity: 0.8,
      dataKeyPrefix: "val2_data2",
      stackId: "val2",
      icon: IconFigureRun,
    },
    {
      teamIndex: 2,
      fillOpacity: 1,
      dataKeyPrefix: "val2_data3",
      stackId: "val2",
      icon: IconFigureSprint,
    },
    {
      teamIndex: 2,
      fillOpacity: 1,
      dataKeyPrefix: "val2_data0",
      stackId: "val2"
    },
  ]

type LabelBarWithPartsCoontentIconProps = Readonly<{
    Icon: SvgInHtml | undefined
    padding?: number;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    style?: any;
    value?: number | string;
}>;
  
export const LabelBarWithPartsContentIconWraper: React.FC<LabelBarWithPartsCoontentIconProps> = (props: LabelBarWithPartsCoontentIconProps) => {
    const { x = 0, y = 0, width = 0, height = 0, style, Icon, padding = 0 } = props;
    if (!width || !height || !Icon || width > height) {
      return null;
    }
  
    return (
        <Icon 
          x={ x + padding } 
          y={ y + padding} 
          width={ width - padding*2 } 
          height={ height - padding*2 } 
          style={ style } 
        />
    );
  };