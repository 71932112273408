// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import Order from '../../Data/_Networking/ReactQuery/Order';
import EpgEvent from '../../Data/EPG/EpgSchedule/Event';
import { isEventEnded } from '../../Data/EPG/EpgSchedule/dates';

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
const stableSort = <T>(array: readonly T[], comparator: (a: T, b: T) => number): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) { return order; }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el: [T, number]) => el[0]);
}

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T): number => {

  //if EpgEvent object
  if (('startTime' in a) && ('startTime' in b) && ('disabled' in a) && ('disabled' in b) && ('streamProfileID' in a)) {
    const a1 = a as unknown as EpgEvent
    const b1 = b as unknown as EpgEvent

    if (orderBy === "startDate") {
      // this is Event. check in details
      if (a1.disabled && b1.disabled) {
        return 0;
      }
      else if (a1.disabled) {
        return -1;
      }
      else if (b1.disabled) {
        return 1;
      }

      const aEnded = isEventEnded(a1);
      const bEnded = isEventEnded(b1);
      if (aEnded && bEnded) {
        return 0;
      }
      else if (aEnded) {
        return -1;
      }
      else if (bEnded) {
        return 1;
      }

      if (b1.startTime < a1.startTime) { return -1; }
      if (b1.startTime > a1.startTime) { return 1; }
      return 0;
    }
    else if (orderBy === "gameType") {
      const aType = a1.gameType + a1.sportType
      const bType = b1.gameType + b1.sportType
      if (bType < aType) { return -1; }
      if (bType > aType) { return 1; }    
      return 0;
    }
    else if (orderBy === "repeatDays") {
      const aType = a1.repeatType + a1.repeatDays
      const bType = b1.repeatType + b1.repeatDays
      if (bType < aType) { return -1; }
      if (bType > aType) { return 1; }    
      return 0;
    }
    else if (orderBy === "homeTeamID") {
      const aType = `${a1?.homeTeamID}:${a1?.guestTeamID}`
      const bType = `${b1?.homeTeamID}:${b1?.guestTeamID}`
      if (bType < aType) { return -1; }
      if (bType > aType) { return 1; }    
      return 0;
    }
    
  }


  if (b[orderBy] < a[orderBy]) { return -1; }

  if (b[orderBy] > a[orderBy]) { return 1; }

  return 0;
}

function getComparator<T>(
  order: Order, orderBy: keyof T
) : (a: T, b: T) => number {
  return order === Order.DESC ?
    (a, b) => descendingComparator<T>(a, b, orderBy)
    :
    (a, b) => -descendingComparator<T>(a, b, orderBy);
}


export {
  stableSort,
  getComparator,
};
