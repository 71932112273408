// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { SyntheticEvent } from 'react';
import { Delete, Edit, FileCopy } from '@mui/icons-material';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import LOCALIZATION from '../../../Localization';


export enum ItemType {
    Edit = 'edit',
    Copy = 'copy',
    Custom = 'custom',
    Delete = 'delete',
}

type Props = Readonly<{
    type: ItemType;
    customName?: string;
    customIcon?: OverridableComponent<any>
    onClick?: () => void;
} & WrappedComponentProps>;


const ICONS: { [key in ItemType]: OverridableComponent<any> } = {
    [ItemType.Edit]: Edit,
    [ItemType.Copy]: FileCopy,
    [ItemType.Custom]: FileCopy,
    [ItemType.Delete]: Delete,
};

const TEXT: { [key in ItemType]: string } = {
    [ItemType.Edit]: LOCALIZATION.edit,
    [ItemType.Copy]: LOCALIZATION.copy,
    [ItemType.Custom]: LOCALIZATION.copy,
    [ItemType.Delete]: LOCALIZATION.remove,
};


const Item: React.FC<Props> = (props: Props) => {
    if (!props.onClick) { return null; }

    const Icon = props.customIcon || ICONS[props.type]

    const handleClick = (event: SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();
        if (props.onClick) {
          props.onClick();
        }
      };

    return (
        <MenuItem
            onClick={ handleClick }
        >
            <ListItemIcon>
                <Icon />
            </ListItemIcon>
            <ListItemText>
                { props.customName || props.intl.formatMessage({ id: TEXT[props.type] }) }
            </ListItemText>
        </MenuItem>
    );
};


export default injectIntl(Item);
