// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {  SxProps, Theme, Typography } from '@mui/material';

import AnalyticsGameArchive from '../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import Tabs, { TabItemInterface } from '../../../Components/_Layout/Tabs';
import LOCALIZATION from '../../../Localization';
import AnalyticsVideoEventsTableTab from './AnalyticsVideoEventsTableTab';
import { AnalyticsGameEvent } from '../../../Data/Analytics/AnalyticsGameEvent/AnalyticsGameEvent';
import VertGrid from '../../../Components/_Layout/VertGrid';
import { VideoPlayerCommands } from '../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import AnalyticsVideoOverallTab from './AnalyticsVideoOverallTab';

export type TabItemDetails = Readonly<{
  getTabContent: (() => JSX.Element | null) | undefined,
} & TabItemInterface>;

type Props = Readonly<{
  gameInfo: AnalyticsGameArchive | undefined
  sx?: SxProps<Theme>;
  team1Name: string  | undefined;
  team2Name: string  | undefined;
  videoPlayerCommands: VideoPlayerCommands | undefined
  events: AnalyticsGameEvent[] | undefined;
} & WrappedComponentProps>;

const AnalyticsEventsTabsCard: React.FC<Props> = (props: Props) => {
  
  const TABS: TabItemDetails[] = [
    {
      id: 0,
      title: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_events }),
      Content: AnalyticsVideoEventsTableTab,
      getTabContent() {
          return <AnalyticsVideoEventsTableTab
            sportType={props.gameInfo?.sport_type}
            events={props.events} 
            videoPlayerCommands={ props.videoPlayerCommands } 
            team1Name={ props.team1Name }
            team2Name={ props.team2Name }
            stickyHeader={ true }
            sx={{
              height: "100%",
              minHeight: 0,
            }}
            />
      },
    },
    // {
    //   id: 1,
    //   title: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_heatmap }),
    //   Content: GameEventsShortTable,
    //   getTabContent: undefined,
    // },
    {
      id: 1,
      title: props.intl.formatMessage({ id: LOCALIZATION.analytics_tab_overall }),
      Content: AnalyticsVideoOverallTab,
      getTabContent() {
        return <AnalyticsVideoOverallTab
          gameInfo={props.gameInfo}
          sx={{
            height: "100%",
            overflow: "scroll",
          }}
          />
    },
    },
  ];
  const [selectedTabID, setSelectedTabID] = React.useState<number>(TABS[0].id);

  const renderContent = () => (
    TABS.map((tab: TabItemDetails) => {
      const hidden = (selectedTabID !== tab.id);

      if (hidden) { return null; }
      
      const getTabContent = tab.getTabContent;
      if (!getTabContent) { return null; }

      return (
        <Typography
          key={ tab.id }
          component="div"
          role="tabpanel"
          // hidden={ hidden }
          id={ `simple-tab-${tab.id}` }
          aria-labelledby={ `simple-tabpanel-${tab.id}` }
          sx={{
            minHeight: 0,
            marginTop: 1,
          }}
        >
          { getTabContent() }
        </Typography>
      );
    })
  );

  return (
    <VertGrid
      sx={{
        height: "100%",
        ...props.sx,
      }}
    >
      <Tabs
        items={ TABS }
        selectedTabID={ selectedTabID }
        onChangeTab={ setSelectedTabID }
      />
      { renderContent() }
    </VertGrid>
  );
};


export default injectIntl(AnalyticsEventsTabsCard);
