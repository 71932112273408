// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { PlatformConfiguration } from '../../../Configuration/PlatformConfiguration'
import { BaseCommand } from './Types'
import { AppLocale } from '../../../Localization/AppContextProvider/helpers';


export namespace PiRestApi {

    export function signInCommand(username: string, password: string): BaseCommand {
        const bodyParams = {
            login: username,
            password: password
        }
        const command = new BaseCommand('/user/signin', 'signin', 'POST', null, bodyParams)

        return command
    }

    export function logout(): BaseCommand {
        const command = new BaseCommand('/user/logout', 'logout', 'POST', null, null)
        return command
    }

    export function currentUser(): BaseCommand {
        const command = new BaseCommand('/user', 'current_user')
        return command
    }

    export function refreshAccessToken(refreshToken: string): BaseCommand {
        const bodyParams = {
            refreshToken: refreshToken
        }
        const command = new BaseCommand('/user/token/refresh', 'refresh_session', 'POST', null, bodyParams)
        return command
    }

    export function monoMetrics(parameters: any): BaseCommand {
        parameters.date = Date.now().toFixed()
        const command = new BaseCommand('/metrics/1d', 'metrics', 'GET', parameters)
        return command
    }

    // For test cases
    // export function monoMetricsFailed(parameters: any): BaseCommand {
    //     parameters.date = Date.now().toFixed()
    //     const command = new BaseCommand('/metric/1d', 'metrics', 'GET', parameters)
    //     return command
    // }

    export function stereoMetrics(parameters: any): BaseCommand {
        parameters.date = Date.now().toFixed()
        const command = new BaseCommand('/metrics/3d', 'metrics', 'GET', parameters)
        return command
    }

    export function createAppCommand(locale: AppLocale, data: string): BaseCommand {
        const bodyParams = {
            locale,
            data,
            platform: PlatformConfiguration.platform,
        }

        const command = new BaseCommand('/user/app', 'create_app', 'POST', null, bodyParams, false)

        return command
    }

    export function fetchCameraSettingsCommand(): BaseCommand {
        const command = new BaseCommand('/settings', 'camera_settings')

        return command
    }

    export function patchCameraSettingsCommand(params: any): BaseCommand {
        const command = new BaseCommand('/settings', 'camera_settings', 'PATCH', null, params)

        return command
    }

    export function fetchApiRequestsCommand(): BaseCommand {
        const command = new BaseCommand('/jetson/api/requests', 'camera_api')

        return command
    }

    export function getApiRequestCommand(name: string): BaseCommand {
        const command = new BaseCommand('/jetson/api/' + name, 'camera_api')

        return command
    }

    export function postApiRequestCommand(name: string, json: any): BaseCommand {
        const command = new BaseCommand('/jetson/api/' + name, 'camera_api', 'POST', null, json)

        return command
    }

    export function fetcGpioPressActionsCommand(): BaseCommand {
        const command = new BaseCommand('/press/actions', 'press_actions')

        return command
    }

    export function getNetworkRequestsCommand(filter: any): BaseCommand {
        const command = new BaseCommand('/network/requests', 'camera_api', 'GET', filter)

        return command
    }

    export function pressActionCommand(actionName: string): BaseCommand {
        const command = new BaseCommand('/press/' + actionName, 'press_actions', 'POST')

        return command
    }

    export function pressActionsCommand(actionNames: string[], duration: number): BaseCommand {
        const bodyParams = {
            actions: actionNames,
            duration: duration
        }

        const command = new BaseCommand('/press/multiple', 'press_actions', 'POST', null, bodyParams)

        return command
    }
}
