// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useEffect, useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';

import { ChangeText } from './FieldSetText';
import { FieldSetInputType } from '.';
import { Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';

export type FieldSetInputColor = Readonly<{
  type: FieldSetInputType.Color;
  value: string;
  onChange: ChangeText;
}>;

type Props = Readonly<{
  input: FieldSetInputColor;
}>;

const useStyles = makeStyles(() => ({
  picker: {
    width: '100%',
    // position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.23)',
  },
  hexPicker: {
    margin: '15px',
  },
  hexTextPicker: {
    border: 0,
    marginLeft: '10px',
  },
  swatch: {
    width: '38px',
    height: '38px',
    borderRadius: '6px',
    border: '2px solid #fff',
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
  },
}));

const FieldSetColor: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const [color, setColor] = useState(props.input.value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  useEffect(() => {
    props.input.onChange(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[color])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={ classes.picker }>
      <div
        className={ classes.swatch }
        style={{ backgroundColor: color }}
        onClick={ handleClick }
      />
      <HexColorInput 
        className={ classes.hexTextPicker }
        prefixed={ true }
        color={ color } 
        onChange={ setColor }
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        >
          <div className={ classes.hexPicker }>
            <HexColorPicker color={color} onChange={ setColor } />
          </div>
      </Popover>
    </div>
  );
};


export default FieldSetColor;
