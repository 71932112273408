// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { isEqual } from 'lodash';

import { StreamingSettings } from '../../Data/NapiStreamingSettings';
import { NO_STREAM_ID } from '../../Data/NapiStreamingProfile/constants';
import CameraConfig from '../../Data/NapiCameraConfig';
import StreamingProfile from '../../Data/NapiStreamingProfile';


export const getActiveProfile = (streamingProfilesArr: StreamingProfile[], cameraConfig: CameraConfig): StreamingProfile | undefined => (
    streamingProfilesArr
        .find(
            (item: StreamingProfile) => (
                isEqual(item.config?.dest, cameraConfig?.dest || {}) && isEqual(item.config?.audio, cameraConfig?.audio || {})
            )
        )
);

export const getSelectedProfileID = (settings?: StreamingSettings, profilesArr?: StreamingProfile[]): string => {
    try {
        if (!settings || !settings?.streaming_settings_selected_profile_id) {
            return NO_STREAM_ID;
        }

        const profileId = settings.streaming_settings_selected_profile_id
        if (profilesArr && profilesArr.length > 0) {
            const foundProfile = profilesArr.find((profile: StreamingProfile) => (profile.id === profileId))
            if (foundProfile) {
                return profileId
            }
        } 
        return NO_STREAM_ID;
    } catch (ignore) {
        return NO_STREAM_ID;
    }
};
