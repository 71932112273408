// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { get as getDeep } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, ReferenceLine, BarChart, LabelList, Rectangle } from 'recharts';

import PlaceholderStyled, { ChartTooltipLabelStyle, ChartTooltipItemStyle } from '../Styled';
import LOCALIZATION from '../../../Localization';
import { BarChartWithPartsItem, BarChartWithPartsItemDataContent, BarChartWithPartsTeamConfig, barChartWithPartsTeamConfigArr, LabelBarWithPartsContentIconWraper } from './helper';
import AspectRatio from '../../_Layout/AspectRatio';
import { Colors } from '../../../Configuration/Styles/Colors';
import { AnalyticsStatGranularity } from '../../../Data/Analytics/AnalyticsTypes';
import { analyticsAnalyticsConvertedDataTypeName, AnalyticsConvertedDataType } from '../../../Data/Analytics/AnalyticsConverters';
import { useMediaQuery, useTheme } from '@mui/material';
import { AnalyticsGameShared } from '../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';

type Props = Readonly<{
    granularity: AnalyticsStatGranularity;
    value1Name: string | undefined;
    value1Color: string | undefined;
    value2Name: string | undefined;
    value2Color: string | undefined;
    data: BarChartWithPartsItem[] | undefined
    mainDataIndex?: AnalyticsConvertedDataType
    mainDataIndexName?: string
    secondDataIndex?: AnalyticsConvertedDataType // additional info when hover
    thirdDataIndex?: AnalyticsConvertedDataType // additional info when hover for main value only. like intencity, %
} & WrappedComponentProps>;

const AnalyticsHorizBarLineChartView: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const verySmallUI = useMediaQuery(theme.breakpoints.down('md'));

  if (!!props.data) {
    const { mainDataIndex = AnalyticsConvertedDataType.distanceCovered, secondDataIndex, thirdDataIndex } = props
    const mainDataKey = `data.${mainDataIndex}.`;
    const mainDataKeyForSearch = `.${mainDataIndex}.`;// key used to search this key after change other parts of key
    const secondDataIndexKey = `.${secondDataIndex}.`;
    const thirdDataIndexKey = `.${thirdDataIndex}.`;

    const CustomCursor = (props: any) => {
      const { x, y, width, height } = props;
      // const { x, y } = props;
      return (
        <Rectangle
          fill="black"
          fillOpacity={(width < 10) ? 1 : 0.1}
          stroke="black"
          strokeOpacity={(width < 10) ? 1 : 0.1}
          x={x-1}
          y={y-1}
          width={width + 1}
          height={height + 1}
          style={{
            zIndex: 10
          }}
        />
      );
    };
    
    const dataCount = props.data.length;
    return (
     <AspectRatio 
        ratio={ (verySmallUI) ?  1200/600 : 1200/340 }
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={550}
            height={500}
            data={ props.data }
            stackOffset="none"
            barCategoryGap="5%"
            barGap={2}
            barSize={(dataCount < 16) ? 25 : undefined}
            margin={{
                top: 10,
                right: 10,
                left: -20,
                bottom: 10
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="id" 
                scale={ (dataCount > 2) ? "point" : "auto" }
                padding={{ left: 60, right: 60 }}
                tickSize={ 8 }
                type="category"
                tickFormatter={(value: any, index: number): string => {
                  const newIndex = parseInt(value);
                  if (props.data && newIndex < props.data.length) {
                    const newValue = props.data[newIndex].itemName;
                    return newValue;
                  }
                  return value;
                }}
              />
              <YAxis type="number" domain={[0, ((dataMax: number) => (dataMax * 1.1))]} />
              <Tooltip 
              allowEscapeViewBox={{y: true}}
              cursor={<CustomCursor />}
              labelFormatter={(label: any, payload: any) => {
                const index = parseInt(label);
                return props.data?.[index]?.titleForTooltip || label;
              }}
              formatter={(value: any, name: string, props: any, index: number, palyload: any) => {
                let valueKey = name.replace('.ratio', '');
                const isMasterInfoItem = (value === 0 && !!(props.payload as BarChartWithPartsItem)?.hiddenData)
                if (isMasterInfoItem) {
                  valueKey = valueKey.replace('data.', 'hiddenData.');
                }
                const content = getDeep(props.payload, valueKey) as BarChartWithPartsItemDataContent;
                let newValue = content?.valueFormated as string || "";
                const newName = content?.valueName as string;

                let extraSecodValue = ""
                if (thirdDataIndex && name.includes("_data0")) {
                  const thirdValueKey = valueKey.replace(mainDataKeyForSearch, thirdDataIndexKey);
                  const thirdContent = getDeep(props.payload, thirdValueKey) as BarChartWithPartsItemDataContent;
                  const thirdValue = thirdContent?.valueFormated as string;
                  if (thirdValue) {
                    const thirdName = analyticsAnalyticsConvertedDataTypeName(thirdDataIndex, undefined, true)
                    extraSecodValue = `, ${thirdName}: ${thirdValue}`
                  }
                }

                // add additional info like: (2.5 km/h)
                if (secondDataIndex) {
                  const secondValueKey = valueKey.replace(mainDataKeyForSearch, secondDataIndexKey);
                  const secodContent = getDeep(props.payload, secondValueKey) as BarChartWithPartsItemDataContent;
                  const secondValue = secodContent?.valueFormated as string;
                  if (secondValue) {
                    newValue = `${newValue} (${secondValue}${extraSecodValue})`
                  }
                }


                return [newValue, newName];
              }}
              labelStyle={ ChartTooltipLabelStyle }
              itemStyle={ ChartTooltipItemStyle }
              wrapperStyle={ { background: Colors.mainBackground, zIndex: 2 } }
              />

              { barChartWithPartsTeamConfigArr.map((item: BarChartWithPartsTeamConfig, index) => (
                <Bar
                  key={ index }
                  dataKey={ mainDataKey + item.dataKeyPrefix + ".ratio" }
                  fill={ (item.teamIndex === 1) ? AnalyticsGameShared.team1Color : AnalyticsGameShared.team2Color }
                  isAnimationActive={false}
                  stroke={ Colors.analyticsBarStroke }
                  fillOpacity={ item.fillOpacity }
                  // strokeOpacity={0.5}
                  strokeWidth={ Colors.analyticsBarStrokeWidth }
                  stackId={ item.stackId }
                >

                  <LabelList
                    position="center"
                    style={{ fill: "#00FF00" }}
                    content={ <LabelBarWithPartsContentIconWraper Icon={ item.icon } padding={2} /> }
                  />

                  <LabelList
                    position="top"
                    style={{ fill: "#000000" }}
                    valueAccessor={(payload: any) => {
                      const content = getDeep(payload, mainDataKey + item.dataKeyPrefix);
                      return content?.valueLabel;
                    }}
                  />
                </Bar> ))
              }

              {
                props.data.map((item) => {
                  if (item.hiddenData) {
                    return <ReferenceLine 
                    stroke="red" 
                    ifOverflow = 'extendDomain'
                    x = { item.id }
                    />             
                  }
                  return null;
                })
              }

          </BarChart>
        </ResponsiveContainer>
      </AspectRatio>
    );
  }

  return (
    <PlaceholderStyled>
      { props.intl.formatMessage({ id: LOCALIZATION.loading_metrics }) }
    </PlaceholderStyled>
  );
};

export default injectIntl(AnalyticsHorizBarLineChartView);
