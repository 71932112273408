// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useQuery } from 'react-query';

import { ResultSingle } from '../../_Networking/ReactQuery/template';
import StorageInfo from '..';
import ResponseSingle from '../../_Networking/ReactQuery/ResponseSingle';
import { getKeyStorageInfo, fetchDataStorageInfo } from '../fetch';

export const useFetchStorageInfo = (): ResultSingle<StorageInfo> => (
    useQuery<ResponseSingle<StorageInfo>>({
      queryKey: getKeyStorageInfo(),
      queryFn: () => fetchDataStorageInfo(),
      // refetchInterval: 1000,
      // staleTime: 0,
      // cacheTime: 0,
      // notifyOnChangeProps: ['data', 'isLoading', 'error'],
    }) as ResultSingle<StorageInfo>
);
