// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { Grid, Box } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import ErrorMessage from '../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage'
import HardwareCard from './Cards/HardwareCard/HardwareCard'
import OperatorStreamCard from './Cards/OperatorStreamCard/OperatorStreamCard'
import PanoramaCard from './Cards/PanoramaCard/PanoramaCard'
import GeneralStatisticsCard from './Cards/GeneralStatisticsCard/GeneralStatisticsCard'
import StorageCard from './Cards/StorageCard/StorageCard'
import ScoreboardCard from './Cards/ScoreboardCard/ScoreboardCard'
import PageHeader from '../../Components/_Layout/PageHeader';
import LOCALIZATION from '../../Localization';
import { useCameraData } from '../../Data/Camera/HWCameraSettings/hook/Hooks';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import VertGrid from '../../Components/_Layout/VertGrid';


const StatusPage: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {

  const camera = useCameraData()
  const isLoaderShowing = (!camera)
  const isNotProxyMode = (camera?.settings?.activeOptions?.isProxyMode !== "true")

  if (isLoaderShowing) {
    return (
      <>
      <br /><br /><Spinner /><br /><br />
      </>
    )
  }

  return (
    // maggic -20px margin to fix internal margin of grid
    <Box sx={{ marginRight: '-20px' }}> 
      <Grid 
      container
      spacing={ 2 }
      >
        <Grid item xs={ 12 }>
          <PageHeader heading={ props.intl.formatMessage({ id: LOCALIZATION.page_status_heading }) }/>
          <ErrorMessage/>
        </Grid>
        <Grid item xs={ 12 }>
          <Grid container spacing={ 2 } direction="row">
            <Grid item xs={ 12 } md={ 6 }>
              <VertGrid spacing='19px'>
                <HardwareCard/>
                <OperatorStreamCard/>
              </VertGrid>
            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              <VertGrid spacing='19px'>
                <PanoramaCard/>
                {(isNotProxyMode) &&
                  <>
                    <StorageCard/>
                    <ScoreboardCard/>
                  </>
                }
              </VertGrid>
            </Grid>
          </Grid>
        </Grid>

        {(isNotProxyMode) &&
          <>
            <Grid item xs={ 12 }>
              <PageHeader heading={ props.intl.formatMessage({ id: LOCALIZATION.statistics }) } />
            </Grid>

            <Grid item xs={ 12 }>
              <GeneralStatisticsCard/>
            </Grid>
          </>
        }
      </Grid>
    </Box>
  )
};


export default injectIntl(StatusPage);
