// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import Event from '../Event';
import { EventPriority } from '../EventWithoutID';
import Order from '../../../_Networking/ReactQuery/Order';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import {
  useFetch,
  flushCache,
  getCached,
} from '../fetch';
import { ResultStatus } from '../../../_Networking/ReactQuery/template';


type Props = Readonly<{
  page: number;
  limit: number;
  order: Order;
  orderBy: keyof Event;
  deleted?: number; // -1 - show all; 0 - show non deleted (default); 1 - show deleted
  priority?: EventPriority; // if undefined then no filter. else filter priority
}>;

type Result = Readonly<{
  status: ResultStatus;
  data: ResponseMany<Event[]> | undefined;
  handleFlushData: () => void;
}>;


const usePagedData = (props: Props): Result => {
  const fetchedEvents = useFetch({
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    enableToFetch: true,
    deleted: props.deleted,
    priority: props.priority,
  });
  const data = getCached({
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    deleted: props.deleted,
    priority: props.priority,
  });

  return ({
    status: fetchedEvents.status,
    data,
    handleFlushData: flushCache,
  });
};


export default usePagedData;
