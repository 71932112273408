// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import CircledLabel, { LabelType } from '../../../../Components/_BaseUI/CircledLabel/CircledLabel'
import { useCameraCriticals, useCameraData } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks'
import { CameraNotAvailable, CameraTools, formattedMetricValue } from '../../../../Tools/Tools'
import { getInternalMeteoGrade, getOutdoorMeteoGrade } from './Tools'
import { MeteoGrade } from './Types'
import LOCALIZATION from '../../../../Localization';
import { DCDeviceStatus } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature';
import { getLabelFromState } from '../../../CamerasPage/CameraCardBlock/CameraPreviewInfo/helpers';


const Info: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const camera = useCameraData()
  if (!camera) return (<CameraNotAvailable/>)

  const isOnline = camera.status === 'on'
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const criticals = useCameraCriticals()

  const internalTemperature = camera.settings?.lastThermalData?.cameraCase
  const internalHumidity = camera.settings?.lastThermalData?.Humidity
  const outdoorTemperature = camera.settings?.lastThermalData?.outdoor

  const fans = CameraTools.fans(camera)

  const statusText: string = criticals.length > 0 ? props.intl.formatMessage({ id: LOCALIZATION.problems }) : props.intl.formatMessage({ id: LOCALIZATION.ok })
  const internalTemperatureString = formattedMetricValue(internalTemperature, '˚C')
  const internalHumidityString = formattedMetricValue(internalHumidity, '%', { multiplyUnit: false })
  const outdoorTemperatureString = formattedMetricValue(outdoorTemperature, '˚C')

  const internal: string = [internalTemperatureString, internalHumidityString].filter(text => !!text).join(', ')

  // Может потребуется докинуть еще параметров, чтобы сразу была фильтрация
  const outdoor: string = [outdoorTemperatureString].filter(text => !!text).join(', ')

  const internalMeteoGrade = getInternalMeteoGrade({
    temperature: internalTemperature,
    humidity: internalHumidity
  })

  const outdoorMeteoGrade = getOutdoorMeteoGrade({
    temperature: outdoorTemperature
  })

  const getLabelTypeFromMeteoGrade = (gr: MeteoGrade) => {
    switch (gr) {
      case 'awefull':
        return LabelType.Red;
      case 'bad':
        return LabelType.Yellow;
      case 'normal':
      case 'good':
        return LabelType.Green;
      default:
        return LabelType.Grey;
    }
  }

  const meteos = [{
    meteoGrade: internalMeteoGrade,
    info: internal,
    name: props.intl.formatMessage({ id: LOCALIZATION.internal })
  }, {
    meteoGrade: outdoorMeteoGrade,
    info: outdoor,
    name: props.intl.formatMessage({ id: LOCALIZATION.outdoor })
  }]

  let fansString = fans.map(fan => {
    return fan.name + ': ' + (fan.isOn ? props.intl.formatMessage({ id: LOCALIZATION.on }) : props.intl.formatMessage({ id: LOCALIZATION.off }))
  }).join(', ')
  if (fansString.length > 0) {
    fansString = props.intl.formatMessage({ id: LOCALIZATION.fan }) + ': ' + fansString
  }
  const isAnyFanOn = fans.map(fan => fan.isOn).reduce((result, value) => {
    return result || value
  })

  const mainDC = camera.settings?.deviceStates?.mainDC;
  const powerTypeName = props.intl.formatMessage({ id: (mainDC === DCDeviceStatus.AC) ? LOCALIZATION.main_dc_ac : (mainDC === DCDeviceStatus.Battery) ? LOCALIZATION.main_dc_battery : LOCALIZATION.unknown });
  const batteryVolt = (mainDC !== DCDeviceStatus.AC) ? camera.settings?.lastMetricsData?.DCVoltage : undefined
  const batteryVoltName = (batteryVolt) ? ` (${ batteryVolt.toFixed(1) } ${props.intl.formatMessage({ id: LOCALIZATION.unit_v }) })` : '';
  const batteryLabel = getLabelFromState(camera.settings?.systemStates?.DC)

  return (
    <Grid
      container
      spacing={ 1 }
      justifyContent="space-between"
      sx={ { flexGrow: 1 } }
      direction="column"
    >
      <Grid item xs={ 12 }>
        <CircledLabel labelType={ isOnline ? LabelType.Green : LabelType.Red } title={ props.intl.formatMessage({ id: LOCALIZATION.hardware }) } stateText={ statusText }/>
      </Grid>
      <Grid item xs={ 12 }>
        <CircledLabel labelType={ batteryLabel } title={ props.intl.formatMessage({ id: LOCALIZATION.power }) } stateText={ `${ powerTypeName }${ batteryVoltName }` }/>
      </Grid>
      { criticals.map((text, index) => {
        return (
          <Grid item xs={ 12 } key={ index }>
            <CircledLabel labelType={ LabelType.Red } title={ text }/>
          </Grid>
        )
      }) }
      { meteos.filter(meteo => {
        return meteo.info.length > 0
      }).map((meteo, index) => {
        return (
          <Grid item xs={ 12 } key={ index }>
            <CircledLabel labelType={ getLabelTypeFromMeteoGrade(meteo.meteoGrade) } title={ meteo.name }
                          stateText={ meteo.info }/>
          </Grid>
        )
      }) }

      <Grid item xs={ 12 }>
        <CircledLabel labelType={ isAnyFanOn ? LabelType.Red : LabelType.Green } title={ fansString }/>
      </Grid>
    </Grid>
  )
};


export default injectIntl(Info);
