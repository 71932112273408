// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';

import { useVKUserGroups } from '../../../Data/oAuth/VKUserGroups/hook';
import { VKUserGroupFilter } from '../../../Data/oAuth/VKUserGroups/VKUserGoup';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import LOCALIZATION from '../../../Localization';

type Props = Readonly<{
  accessToken: string | undefined;
  selectedGroupId: number | undefined;
  onSelect: (groupID: number | null | undefined, groupName: string | undefined) => void;
} & WrappedComponentProps>;


const useStyles = makeStyles(() => ({
  inputRoot: {
    height: '38px',
    lineHeight: '38px',
    background: "white",
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // paddingTop: 0,
      // paddingBottom: 0,
      marginTop: '-8px', // hack to make edit 38px position correct on safari and chrome. on chrome also work padding 0
    },
    '& .MuiAutocomplete-endAdornment': {
      marginTop: '-5px',
    },
  }
}));

const VKUserGroupAutocompleteSelect: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    // status,
    data: userGroupsArr,
    handleFlushData,
  } = useVKUserGroups({ accessToken: props.accessToken, filter: VKUserGroupFilter.moder });

  let options = (userGroupsArr || [])
    .map(({ id, name }) => ({
      id,
      name,
    }));
    options.unshift({
        id: 0,
        name: props.intl.formatMessage({ id: LOCALIZATION.stream_video_stream_target_user_page }),
    })
  const selectedTeam =  options.find(({ id }) => (id === props.selectedGroupId)) || options[0];

  const handleSelect = (_event: any, newGroup: any | null) => {
    if (!newGroup) {
      props.onSelect(null, undefined);
    } else {
      const group = (userGroupsArr || []).find(({ id }) => (id === newGroup.id)) || options[0];
      props.onSelect(group?.id, group?.name);
    }
  };

  React.useEffect(() => {
    handleFlushData();
    return () => {
        handleFlushData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      options={ options }
      renderInput={params => (
        <TextField
          { ...params }
          variant="outlined"
        />
      )}
      classes={ classes }
      getOptionLabel={ option => option.name || "" }
      style={ { height: '38px' } }
      value={ selectedTeam }
      onChange={ handleSelect }
    />
  );
};


export default injectIntl(VKUserGroupAutocompleteSelect);