// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import renderCellContent from './Cell';
import ButtonsInRowElement from '../../../Components/_BaseUI/ButtonsInRowElement';
import Order from '../../../Data/_Networking/ReactQuery/Order';
import SortedTable from '../../../Components/SortedTable';
import EPGEvent from '../../../Data/EPG/EPGEvents/EPGEvent';
import FieldSetDateComponent from '../../../Components/_BaseUI/FieldSet/FieldSetDateComponent';
import { PAGE_LIMIT, JAVA_BACKEND_URL } from '../../../constants';
import { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';
import { RowClassName } from '../../../Components/SortedTable/SortedTableBodyRow';
import { isCurrentEPGEvent } from '../../../Data/EPG/EpgSchedule/dates';
import usePagedData from '../../../Data/EPG/EPGEvents/hook/usePagedData';
import LOCALIZATION from '../../../Localization';
import RefreshIcon from '@mui/icons-material/Refresh';
import dayjs from 'dayjs';
import { useAppConfig } from '../../../Localization/AppContextProvider/helpers';
import AuthorisationManager from '../../../Data/Auth/AuthorisationManager';
import isSuperAdmin from '../../../Data/AccountUsers/tools/isSuperAdmin';
import { Link } from '@mui/material';
import isServer from '../../../Data/AccountUsers/tools/isServer';
import { getCachedActiveCamera, isNoActiveCamera } from '../../../Data/Camera/ActiveCamera/fetch';

type Props = Readonly<{} & WrappedComponentProps>;

const EventsHistoryList: React.FC<Props> = (props: Props) => {

  const { localization: { locale } } = useAppConfig();
  dayjs.locale(locale);

  const user = AuthorisationManager.shared.user
  
  // timer to update ui every 10 sec to reflect active event change
  const uiUpdateTimer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [uiUpdateCount, setUiUpdateCount] = React.useState(0);

  React.useEffect(() => {
    if (uiUpdateTimer.current) {
      clearTimeout(uiUpdateTimer.current);
    }
    uiUpdateTimer.current = setTimeout(() => {
      setUiUpdateCount(uiUpdateCount + 1);
    }, 10000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  // cancel timer on hide
  React.useEffect(() => {
    return (
      () => {
        if (uiUpdateTimer.current) {
          clearTimeout(uiUpdateTimer.current);
        }
      }
    );
  }, []);

  const headCells = [
    {
      id: 'priority' as keyof EPGEvent,
      numeric: false,
      disablePadding: true,
      label: "📊",
    },
    {
      id: 'name' as keyof EPGEvent,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.event_name }),
    },
    {
      id: 'gameType' as keyof EPGEvent,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.event_type }),
    },
    {
      id: 'homeTeamID' as keyof EPGEvent,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.event_teams }),
    },
    {
      id: 'startAt' as keyof EPGEvent,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.event_time }),
    },
    {
      id: 'streamingName' as keyof EPGEvent,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.history_event_streaming }),
    },
    {
      id: 'records' as keyof EPGEvent,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.history_event_records }),
    },
  ];
  const [date, setDate] = React.useState<string|null>(null);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(PAGE_LIMIT);
  const orderBy = 'startAt';
  const order = Order.ASC;

  const {
    // status,
    data: EPGEvents,
    handleFlushData,
  } = usePagedData({
    date: date || undefined,
    page,
    limit,
    order,
    orderBy,
  });

  const rowClassName = (item: EPGEvent, allItem: EPGEvent[]) => {
    const isCurrentEvent = isCurrentEPGEvent(item);

    if (isCurrentEvent) {
      return RowClassName.Blue;
    }

    if (item.completed) {
      return RowClassName.LightGray;
    }

    return null;
  }

  const handleChangeDate = (date: string | null) => {
    setDate(date);
  };

  const buttons = [
    <FieldSetDateComponent
      key="FieldSetDateComponent"
      labelText={ props.intl.formatMessage({ id: LOCALIZATION.filter_by_date }) }
      input={ {
        type: FieldSetInputType.Date,
        noWrapper: true,
        allowRemoveDate: true,
        noValueText: props.intl.formatMessage({ id: LOCALIZATION.filter_by_date_off }),
        dateFormat: 'YYYY-MM-DD',
        value: {
          date: (date || null),
        },
        onChangeDate: handleChangeDate,
      } }
    />,
    {
      space :-1 ,
    }, 
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.refresh }),
      iconButton: <RefreshIcon /> ,
      onClick: handleFlushData,
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => handleFlushData,[]);

  const epgXmlUrl: string | undefined = (() => {
    if (isSuperAdmin(user)) {
      if (isServer(user)) { // for server add target caeraId
        const activeCameraData = getCachedActiveCamera();
        if (!isNoActiveCamera(activeCameraData) && activeCameraData?.cameraId) {
          return `${ JAVA_BACKEND_URL }/events/epg.xml?cameraId=${ activeCameraData.cameraId }`
        }
      }
      return `${ JAVA_BACKEND_URL }/events/epg.xml`
    }
    return undefined
  })()

  return (
    <>
      <ButtonsInRowElement
        id = "buttonsRow1"
        buttons={ buttons }
      />
      {
        (EPGEvents && uiUpdateCount >= 0) &&
          <SortedTable<EPGEvent>
            page={ page }
            rowsPerPage={ limit }
            // rowsPerPageOptions={ [5, 10, 25, 50, 100] }
            orderBy={ orderBy }
            order={ order }
            headCells={ headCells }
            manyItems={ EPGEvents }
            renderCellContent={ renderCellContent }
            rowClassName={ rowClassName }
            onPageChange={ setPage }
            onPeerPageChange={ setLimit }
          />
      }
      {
        (epgXmlUrl) &&
          <>
           <br/><br/>
           <Link href={ epgXmlUrl }>Link to Epg.xml</Link>
          </>
      }
    </>
  );
};


export default injectIntl(EventsHistoryList);
