// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { CameraSettingsInterface } from "../HWCameraSettings/CameraSettings";

export enum RemoteCameraStateValue {
    Unknown = 'unknown',
    OnlineOK = 'online_ok',
    OnlineWarning = 'online_warning',
    OnlineCritical = 'online_critical',
    Offline = 'offline',
    Error = 'error',
}

export const RemoteCameraOnlineStates: RemoteCameraStateValue[] = [RemoteCameraStateValue.OnlineOK, RemoteCameraStateValue.OnlineWarning, RemoteCameraStateValue.OnlineCritical]
  
export type SettingsResponseError = Readonly<{
    errorType: string | 'Timeout';
    httpStatus: number;
    message: string | 'TimeoutException';
}>;
  
interface  RemoteCameraState {
    readonly id: string;
    readonly cameraId: string;

    // Unix time with milliseconds when camera last time was online in any state. Auto-update then lastSettingsResponse changed.
    readonly lastOnlineTime: number;

    // Last http response code from camera request to /settings.
    readonly lastSettingsHttpCode: number;

    readonly lastSettingsResponse: CameraSettingsInterface | SettingsResponseError;

    // Unix time with milliseconds of last http response data change. Auto-update then lastSettingsResponse changed.
    readonly lastSettingsResponseTimestamp: number;

    /*  Make sense only for server. State of camera represent this settings on server.
        Can be:
        • unknown - Value on system init.
        • online_ok - camera is online and all work correct.
        • online_warning - camera is online and some values need user attention.
        • online_critical - camera is online and some values are in critical state.
        • offline - cant get data from camera.
        • error - camera returned http error.
        */
    readonly state: RemoteCameraStateValue;
};
  
export default RemoteCameraState;