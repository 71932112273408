// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import { Colors } from '../../../../Configuration/Styles/Colors'
import { Fonts } from '../../../../Configuration/Styles/Fonts'
import { Sizes } from '../../../../Configuration/Styles/Sizes'
import { ApiRequestConfigInterface } from '../../Types'
import { EMPTY_ARRAY } from '../../../../constants';
import { createStyles, makeStyles } from '@mui/styles';
import { FormControl, MenuItem, Select } from '@mui/material';

const useStyles = makeStyles(() => createStyles({
  root: {
    flexGrow: 1
  },
  formControl: {
    minWidth: 90,
    width: '100%',
    minHeight: 40,
    background: Colors.mainBackground,
    fontFamily: Fonts.main,
    borderRadius: 6
  },
  select: {
    borderRadius: 6
  },
  text: {
    fontFamily: Fonts.main,
    fontSize: Sizes.subtitle,
    color: Colors.mainTitle
  }
}));

export default function CameraApiRequestSelector(props: { sources: ApiRequestConfigInterface[], currentSource?: ApiRequestConfigInterface, onChanged: (source: ApiRequestConfigInterface) => void }) {
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const sources = props.sources || EMPTY_ARRAY;

  const firstAvailableApiRequest = () => {
    if (props.currentSource) {
      return props.currentSource
    } else if (sources.length > 0) {
      return sources[0]
    } else {
      return undefined
    }
  }
  const currentValue = firstAvailableApiRequest()
  const handleCurrentValueChange = (source: ApiRequestConfigInterface) => {
    props.onChanged(source)
  }

  function createDropDownItems() {
    return sources.map(source => {
      return (
        <MenuItem
          key={ source.name }
          value={ source.name }
          onClick={ () => {
            handleCurrentValueChange(source)
          } }>
          <div className={ classes.text }>{ source.name }</div>
        </MenuItem>
      )
    })
  }

  return (
    <div className={ classes.root }>
      <FormControl variant="outlined" className={ classes.formControl } size="small">
        <Select
          className={ classes.select }
          displayEmpty
          value={ currentValue?.name ?? '' }
        >
          { createDropDownItems() }
        </Select>
      </FormControl>
    </div>
  )
}
