// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { SxProps, Theme } from '@mui/material';

import CameraStreamPreview, { VideoPlayerCommands } from '../../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview'
import { FileType } from '../../../../Data/VideoArchive';
import AnalyticsVideoArchive from '../../../../Data/Analytics/AnalyticsVideoArchive/AnalyticsVideoArchive';
import { useAnalyticsConfig } from '../../../../Data/Analytics/AnalyticsConfig/hook';
import { makeAnalyticsVideoImageUrl, makeAnalyticsVideoPlayerUrl } from '../../../../Data/Analytics/AnalyticsConfig/AnalyticsConfig';
import { MAIN_CARD_SX } from '../../../../Components/_Layout/Cards/Main/styles';

type Props = Readonly<{
  videoRecordInfo: AnalyticsVideoArchive | undefined
  onPlayerCommandsUpdate?: (commands: VideoPlayerCommands | undefined) => void
  sx?: SxProps<Theme>;
} & WrappedComponentProps>;

const GameVideoCard: React.FC<Props> = (props: Props) => {

  const {
    data: analyticsConfig
  } = useAnalyticsConfig({});

  return (
    <CameraStreamPreview
      sx={{
        ...MAIN_CARD_SX,
        width: "100%",
        height: "99%",
      }}
      previewImageUrl={ makeAnalyticsVideoImageUrl(analyticsConfig, props.videoRecordInfo?.preview) }
      videoUrl={ makeAnalyticsVideoPlayerUrl(analyticsConfig, props.videoRecordInfo?.video) }
      fileType={ FileType.Panorama }
      bigPreview={true}
      onPlayerCommandsUpdate={ props.onPlayerCommandsUpdate }
    />
  );
};


export default injectIntl(GameVideoCard);
