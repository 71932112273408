// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

type TeamRef = Readonly<{
  id: string;
  externalTeamId: string;
  name: string;
  shortName: string;
  color: string;
  logo: string | null;
  address?: string;
  notes?: string;
}>;

export type TeamRefShort = Readonly<{
  id?: string;
  externalTeamId: string;
  name: string;
  shortName: string;
}>;

export type NewTeamRef = Readonly<{
  externalTeamId: string;
  name: string;
  shortName: string;
  color: string;
  logo: string | null;
  address?: string;
  notes?: string;
}>;

export const getTeamRefName = (teamRef: TeamRef | undefined, isSuperAdmin: boolean): string => (
  (!teamRef) ? "NA" : (isSuperAdmin && teamRef.externalTeamId) ? `${ teamRef.name } (${ teamRef.externalTeamId })` : teamRef.name
);

export default TeamRef;
