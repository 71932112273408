// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useMutation } from 'react-query';

import { clearTeamRefsCache } from '.';
import TeamRef, { NewTeamRef } from '..';
import fetchJSONData from '../../../_Networking/ReactQuery/fetch';
import { JAVA_BACKEND_URL } from '../../../../constants';


export enum ActionType {
  Add = 'add',
  Patch = 'patch',
  Delete = 'delete',
}

export type Action = Readonly<{
  teamRef?: TeamRef | NewTeamRef;
  originalTeamRef?: TeamRef;
  type: ActionType;
}>;

const deleteTeamRef = async (teamRef: TeamRef): Promise<Action | Error> => {
  try {
    await fetchJSONData<TeamRef>(
      `${JAVA_BACKEND_URL}/teamref/${teamRef.id}`,
      {
        method: 'DELETE',
      }
    );

    return ({
      type: ActionType.Delete,
      teamRef,
    });
  } catch (error) {
    throw error;
  }
};

const addTeamRef = async (teamRef: TeamRef | NewTeamRef): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<TeamRef>(
      `${JAVA_BACKEND_URL}/teamref/add`,
      {
        method: 'POST',
        body: JSON.stringify({ ...teamRef }),
      }
    );

    return ({
      type: ActionType.Add,
      teamRef: data,
    });
  } catch (error) {
    throw error;
  }
};

const patchTeamRef = async (teamRef: TeamRef): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<TeamRef>(
      `${JAVA_BACKEND_URL}/teamref/${teamRef.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ ...teamRef }),
      }
    );

    return ({
      type: ActionType.Patch,
      teamRef: data,
    });
  } catch (error) {
    throw error;
  }
};

const useMutationTeamRef = () => {
  return useMutation(
    (action: Action) => {
            switch (action.type) {
        case ActionType.Add: {
          if (action.teamRef) {
            return addTeamRef(action.teamRef);
          }

          return Promise.reject(new Error('Invalid TeamRef Mutation Action Add'));
        }
        case ActionType.Patch: {
          if (action.teamRef) {
            return patchTeamRef(action.teamRef as TeamRef);
          }

          return Promise.reject(new Error('Invalid TeamRef Mutation Action Patch'));
        }
        case ActionType.Delete: {
          if (action.teamRef) {
            return deleteTeamRef(action.teamRef as TeamRef);
          }

          return Promise.reject(new Error('Invalid TeamRef Mutation Action Delete'));
        }
        default:
          return Promise.reject(new Error('Invalid TeamRef Mutation Action'));
      }
    },
    {
      onSuccess: clearTeamRefsCache,
    },
  );
};


export default useMutationTeamRef;
