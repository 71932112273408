// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { PureComponent } from 'react';
import { get as getDeep } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ComposedChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Line, Legend, ReferenceLine, Rectangle } from 'recharts';

import PlaceholderStyled, { ChartTooltipLabelStyle, ChartTooltipItemStyle } from '../../../../Components/_ChartsAndBars/Styled';
import LOCALIZATION from '../../../../Localization';
import { AnalyticsHorizBarVisualiseMode, BarLineChartItem } from './helper';
import AspectRatio from '../../../../Components/_Layout/AspectRatio';
import { Colors } from '../../../../Configuration/Styles/Colors';
import { Sizes } from '../../../../Configuration/Styles/Sizes';
import { Fonts } from '../../../../Configuration/Styles/Fonts';
import { AnalyticsConvertedDataType } from '../../../../Data/Analytics/AnalyticsConverters';
import { useMediaQuery, useTheme } from '@mui/material';
import { AnalyticsGameEvent } from '../../../../Data/Analytics/AnalyticsGameEvent/AnalyticsGameEvent';
import { timeToSeconds } from '../../../../Components/_BaseUI/AnalyticsTimeField';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { VideoPlayerCommands } from '../../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';

type EventMapping = Readonly<{
  text: string
  events: AnalyticsGameEvent[];
}>;

type Props = Readonly<{
    events: AnalyticsGameEvent[] | undefined;
    videoPlayerPosSec: number | undefined;
    videoPlayerCommands: VideoPlayerCommands | undefined
    value1Name: string | undefined;
    value1Color: string | undefined;
    value2Name: string | undefined;
    value2Color: string | undefined;
    data: BarLineChartItem[] | undefined
    visualiseMode?: AnalyticsHorizBarVisualiseMode
    mainDataIndex?: AnalyticsConvertedDataType
    mainDataIndexName?: string
    secondDataIndex?: AnalyticsConvertedDataType
    secondDataIndexName?: string
} & WrappedComponentProps>;

const AnalyticsMinutesLineChartView: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const verySmallUI = useMediaQuery(theme.breakpoints.down('md'));

  if (!!props.data) {
    const { mainDataIndex = AnalyticsConvertedDataType.score, visualiseMode=AnalyticsHorizBarVisualiseMode.Bar, secondDataIndex } = props
    const mainDataKey = `data.${mainDataIndex}`;
    const secondDataKey = `data.${secondDataIndex}`;

    let chartData: BarLineChartItem[] = props.data;
    if (visualiseMode === AnalyticsHorizBarVisualiseMode.Line) {// make NULL gap for time divider. else line will connect last dot in period with time divider
      chartData = chartData.map((item: BarLineChartItem) => {
        if (item.child?.length) {
          return {
            ...item,
            data: {},
          }
        }
        return item;
      })
    }
    const dataCount = chartData.length;

     // found item id matching current player pos
    let playerPosItemId: number | undefined = undefined
    if (props.videoPlayerPosSec !== undefined) {
      const posSec = props.videoPlayerPosSec;
      for(const item of chartData) {
        if (item.startSec <= posSec && posSec <= item.endSec) {
          playerPosItemId = item.id;
          break;
        }
      }
    }

    // fill array of 
    let eventsTickArr: (EventMapping | undefined)[] = Array(dataCount).fill(undefined)
    if (props.events) {
      const eventsCount = props.events.length
      for(let eindex = 0; eindex < eventsCount; eindex++) { 
        const event: AnalyticsGameEvent = props.events[eindex];
        const eventTimeSec = timeToSeconds(event.timestamp);

        if (eventTimeSec !== undefined) {
          for(let cindex = 0; cindex < dataCount; cindex++) { 
            const item = chartData[cindex];
            if (item.startSec <= eventTimeSec && eventTimeSec <= item.endSec) {

              const newValueText = `${eindex + 1}`;
              let newMapping:EventMapping | undefined = undefined
              const prevMapping = eventsTickArr[cindex];

              if (prevMapping !== undefined) {
                newMapping = {
                  text: `${prevMapping.text},${newValueText}`,
                  events: [...prevMapping.events, {
                    ...event,
                    id: eindex + 1, // +1 because events in table start from 1
                  }]
                }
              }
              else {
                newMapping = {
                  text: newValueText,
                  events: [{
                    ...event,
                    id: eindex + 1, // +1 because events in table start from 1
                  }]
                }
              }
              eventsTickArr[cindex] = newMapping;
              break;
            }
          }
        }
      } 
    }

    // console.log(JSON.stringify(chartData, undefined, 2))

    const barOrLineEl = (
      name: string, 
      mode: AnalyticsHorizBarVisualiseMode, 
      keyName: string, 
      legendColor?: string, 
      fillColor?: string, 
      strokeColor?: string, 
      strokeDasharray?: string
      ) => {
      if (mode === AnalyticsHorizBarVisualiseMode.Bar){
        return (
        <Bar 
          name={ name }
          dataKey={ keyName } 
          fill={ fillColor } 
          color={ legendColor }
          floodColor={ legendColor } // fake not used color to pass it to legend
          barSize={(dataCount < 10) ? 15 : undefined} 
          isAnimationActive={false}
          stroke={ Colors.analyticsBarStroke }
          strokeWidth={ Colors.analyticsBarStrokeWidth }
        />
        )
      }
      
      return <Line 
        connectNulls={false}
        name={ name }
        type="linear" 
        dataKey={ keyName } 
        color={ legendColor }
        floodColor={ legendColor } // fake not used color to pass it to legend
        stroke={ strokeColor || fillColor } 
        isAnimationActive={false}
        strokeWidth={2}
        strokeDasharray={ strokeDasharray }
      />
    }

    class CustomizedXAxisEventTick extends PureComponent {
      render() {
        const { x, y, fill, payload } = this.props as any;
    
        let value: string = payload.value;
        const newIndex = payload.index;
        if (chartData && newIndex < eventsTickArr.length) {
          const item = eventsTickArr[newIndex];
          if (item === undefined) {
            return (
              <g></g>
            )
          }
          value = item.text;
        }

        const textWidth = value.length;
        const width = 10 + textWidth * 5;

        const rect={arrowX: width / 2, arrowY: 0, boxTop: 10, width, height: 25}

        return (
          <g transform={`translate(${x - rect.arrowX},${y})`}>
            <path d={`M${rect.arrowX} 0L${rect.width} ${rect.boxTop}V${rect.height}H0V${rect.boxTop}L${rect.arrowX} 0Z`} fill="#C4C4C4" stroke="black" stroke-width="2"/>
            <text x={rect.arrowX} y={rect.boxTop} dy={10} textAnchor="middle" fill={fill} fontSize={12} font-weight="bold">
              {value}
            </text>
          </g>
        );
      }
    }
    
    const CustomCursor = (props: any) => {
      const { height, points } = props;
      const newX =  points?.[0]?.x
      const newY =  points?.[0]?.y
      // const { x, y, top, left, width, height, stroke, points } = props;
      // const { x, y } = props;
      return (
        <Rectangle
          fill="black"
          fillOpacity={1}
          stroke="black"
          x={newX-1}
          y={newY-1}
          width={1}
          height={height}
          style={{
            zIndex: 10
          }}
        />
      );
    };

    const playVideoForIndex = (clickIndex: number | undefined) => {
      if (clickIndex !== undefined) {
        const index: number = clickIndex || 0
        if (chartData && index < chartData.length) {
          const item = chartData[index];
          if (item) {
            props.videoPlayerCommands?.play(item.startSec)            
          }
        }  

        if (eventsTickArr && index < eventsTickArr.length) {
          const item = eventsTickArr[index];
          if (item !== undefined && item.events.length) {
            const event = item.events[0]
            const el = document.querySelector(`#TRow${event.id}`)
            if (el) {
              el.scrollIntoView({ behavior: 'smooth', block: 'center' })
              const animation = el.animate([{opacity:0},{opacity:1}],{duration:500,iterations:2, delay : 1000})
              setTimeout(()=>{
                if (animation) {
                  animation.cancel();
                }
              }, 2000)
            }
          }
        }     
      }
    }

    const bodyOnClick = (nextState: CategoricalChartState, event: any) => {
      if (nextState) {
        const index = nextState.activeTooltipIndex || 0
        playVideoForIndex(index);
      }
    }

    const xAxisOnClick = (event: any) => {
      const { index: clickIndex } = event;
      if (clickIndex !== undefined) {
        const index: number = clickIndex || 0
        playVideoForIndex(index);
      }
    }
    
    return (
     <AspectRatio 
        ratio={ (verySmallUI) ?  1200/600 : 1200/250 }
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
              onClick={ bodyOnClick }
              width={550}
              height={500}
              data={ chartData }
              margin={{
                  top: 10,
                  right: 10,
                  left: -20,
                  bottom: 10
                }}
              >
              <defs>
                  <pattern
                      id="value1ColorDiagonalHatch"
                      width="10"
                      height="10"
                      patternTransform="rotate(45 0 0)"
                      patternUnits="userSpaceOnUse"
                  >
                      <rect x="0" y="0" width="10" height="10" fill={ props.value1Color } />
                      <line x1="0" y1="0" x2="0" y2="10" stroke="black" strokeWidth="4" />
                  </pattern>
                  <pattern
                      id="value2ColorDiagonalHatch"
                      width="10"
                      height="10"
                      patternTransform="rotate(45 0 0)"
                      patternUnits="userSpaceOnUse"
                  >
                      <rect x="0" y="0" width="10" height="10" fill={ props.value2Color } />
                      <line x1="0" y1="0" x2="0" y2="10" stroke="black" strokeWidth="4" />
                  </pattern>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                orientation="top"
                xAxisId={ 0 }
                dataKey="id" 
                interval={ (verySmallUI) ? "preserveStart" : 0 }
                minTickGap={ (verySmallUI) ? 5 : 0 }
                scale={ (dataCount > 2) ? "point" : "auto" }
                padding={{ left: 10, right: 10 }}
                tickSize={ 8 }
                tickFormatter={(value: any, index: number): string => {
                  const newIndex = parseInt(value);
                  if (chartData && newIndex < chartData.length) {
                    const item = chartData[newIndex];
                    if (item.child?.length) { // time separator. hide tick for it
                      return "";
                    }
                    const newValue = item.itemName;
                    if (verySmallUI) {
                      return newValue;
                    }

                    const nextItem = ((newIndex + 1) < chartData.length) ? chartData[newIndex + 1] : undefined;
                    const isNextItemIsTimeSeparator = !!nextItem?.child?.length
                    if (isNextItemIsTimeSeparator) { // if next is separator need out current value 
                      return newValue;
                    }

                    const nextNextItem = ((newIndex + 2) < chartData.length) ? chartData[newIndex + 2] : undefined;
                    const isNextNextItemIsTimeSeparator = !!nextNextItem?.child?.length
                    if (isNextNextItemIsTimeSeparator) { // if +2 is separator need to hid ecurrent to make space for next
                      return "";
                    }

                    const newValueInt = parseInt(newValue);
                    if (newValueInt === 1 || (newValueInt % 5) === 0) {// out every 5 
                      return newValue;
                    }

                    return "";
                  }
                  return value;
                }}
              />
              <XAxis 
                xAxisId={ 1 }
                orientation="bottom"
                dataKey="id" 
                interval={ (verySmallUI) ? "preserveStart" : 0 }
                minTickGap={ (verySmallUI) ? 5 : 0 }
                scale={ (dataCount > 2) ? "point" : "auto" }
                padding={{ left: 10, right: 10 }}
                tickSize={ 0 }
                tickFormatter={(value: any, index: number): string => {
                  const newIndex = parseInt(value);
                  if (chartData && newIndex < eventsTickArr.length) {
                    const item = eventsTickArr[newIndex];
                    return item?.text || "";
                  }
                  return "";
                }}
                tick={<CustomizedXAxisEventTick />}
                onClick={ xAxisOnClick }
              />
              <YAxis />
              <Tooltip 
              cursor={<CustomCursor />}
              labelFormatter={(label: any, payload: any) => {
                const index = parseInt(label);
                let text: string = chartData?.[index]?.titleForTooltip || label;

                if (eventsTickArr && index < eventsTickArr.length) {
                  const item = eventsTickArr[index];
                  if (item !== undefined) {
                    text = `${text}\n${props.intl.formatMessage({ id: LOCALIZATION.events_with_numbers })}: ${item.text}`
                  }
                }

                return <div 
                  style={{
                    whiteSpace: "pre-line"
                  }}
                >
                  {text}
                </div>
              }}
              formatter={(value: any, name: string, props: any) => {
                if (value === 0 && (props.payload as BarLineChartItem)?.hiddenData) {
                  const valueKey = props.dataKey.replace('data.', 'hiddenData.');
                  const newValue = getDeep(props.payload, valueKey);
                  // console.log(`1--- :${newValue}:${name}`);
                  return [newValue, name];
                }
                // console.log(`2--- :${value}:${name}`);
                return [value, name];
              }}
              labelStyle={ ChartTooltipLabelStyle }
              itemStyle={ ChartTooltipItemStyle }
              wrapperStyle={ { background: Colors.mainBackground, zIndex: 2 } }
              />
              <Legend align="center" verticalAlign='bottom' 
                formatter={(value: any, entry, index) => {
                  const color = (entry.payload as any)?.["floodColor"] as string || entry?.color

                  return (
                    <span style={{ 
                      color: color, 
                      fontWeight: Sizes.regularWeight,
                      fontFamily: Fonts.main,
                      fontSize: Sizes.title,
                     }}>{value}</span>
                  )
                }}
              />

              { barOrLineEl(`${props.value1Name} ${props.mainDataIndexName}`, visualiseMode, mainDataKey + '.value1', Colors.black, props.value1Color) }
              { barOrLineEl(`${props.value2Name} ${props.mainDataIndexName}`, visualiseMode, mainDataKey + '.value2', Colors.black, props.value2Color) }

              {(secondDataIndex !== undefined) && (
                barOrLineEl(`${props.value1Name} ${props.secondDataIndexName}`, visualiseMode, secondDataKey + '.value1', Colors.black, "url(#value1ColorDiagonalHatch)", props.value1Color, "4")
              )}
              { (secondDataIndex !== undefined) && (
                barOrLineEl(`${props.value2Name} ${props.secondDataIndexName}`, visualiseMode, secondDataKey + '.value2', Colors.black, "url(#value2ColorDiagonalHatch)", props.value2Color, "4")
              )}    

              {
                chartData.map((item) => {
                  if (item.hiddenData) {
                    return <ReferenceLine 
                    stroke="red" 
                    ifOverflow = 'extendDomain'
                    x = { item.id }
                    label={(props: any) => {
                      const { viewBox } = props;

                      const transfom = `translate(${viewBox.x},${viewBox.y+viewBox.height / 2})`
                  
                      return (
                        <g transform={transfom}>
                          <text dy={ 3 } 
                            fill="red"
                            fontSize={12} 
                            textAnchor="middle"
                            style={{stroke: "white", strokeWidth: 3, paintOrder: "stroke"}}
                            transform="rotate(-90)"
                          >
                            {item.timeName}
                          </text>
                        </g>
                      );
                    }}
                    />             
                  }
                  return null;
                })
              }

              {
                (playerPosItemId !== undefined) &&
                  <ReferenceLine 
                    stroke="blue" 
                    strokeWidth={ 2 }
                    ifOverflow = 'extendDomain'
                    x = { playerPosItemId }
                    />
              }

          </ComposedChart>
        </ResponsiveContainer>
      </AspectRatio>
    );
  }

  return (
    <PlaceholderStyled>
      { props.intl.formatMessage({ id: LOCALIZATION.loading_metrics }) }
    </PlaceholderStyled>
  );
};

export default injectIntl(AnalyticsMinutesLineChartView);
