// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';

import { useCameraData } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks'
import { useGpioPressActionData } from '../../../../Data/Camera/Gpio/Hooks'
import { PiCpuLoadMetric, PiCpuTemperatureMetric } from '../../../../Data/Camera/Metrics/Collection'
import { useMetricValuesData } from '../../../../Data/Camera/Metrics/MetricsValuesHook'
import MainCardHeader from '../../../../Components/_Layout/CardHeader/Main'
import { ErrorMessageHelper } from '../../../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessageHelper'
import MainButton, { ButtonType } from '../../../../Components/_BaseUI/MainButton'
import { CameraNotAvailable } from '../../../../Tools/Tools'
import PiChartView from './PiChartView'
import { PICardHeadingModelBuilder } from '../../../../Components/_Layout/CardHeader/Main/Builders'
import { generateUserError } from '../../../../Data/_Networking/AxiosFetch/Errors/Tools'
import { PiInterface } from './Types'
import CameraContext from '../../../../Data/Camera/HWCameraSettings/fetch/Context'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content'
import { useIntl } from 'react-intl';
import LOCALIZATION from '../../../../Localization';
import { CameraSettingsInterface } from '../../../../Data/Camera/HWCameraSettings/CameraSettings';
import { isCameraSettingsOptionActive } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsActiveOptions';


const usePiModelValue = (): PiInterface | undefined => {
  const intl = useIntl();
  const camera = useCameraData()

  const cpuLoadMetric = PiCpuLoadMetric
  const cpuTemperatureMetric = PiCpuTemperatureMetric

  const data = useMetricValuesData({
    metrics: [cpuLoadMetric, cpuTemperatureMetric],
    uniqueKey: 'ValuesHardwarePi',
    defaultSelected: [] // при получении значений мы не выбираем значения. Пока. Поэтому []
  })

  if (!camera) {
    return undefined
  }

  const model: PiInterface = {
    status: camera.status,
    name: intl.formatMessage({ id: LOCALIZATION.default_pi_name })
  }

  if (data.items.length === 1) {
    const item = data.items[0]
    model.cpuLoad = item[cpuLoadMetric.dataKey]
    model.cpuTemp = item[cpuTemperatureMetric.dataKey]
    model.status = camera.status
  }

  return model
};

const CardHeader: React.FC = () => {
  const model = usePiModelValue()
  if (!model) {
    return (<CameraNotAvailable/>)
  }

  return (
    <MainCardHeader model={ new PICardHeadingModelBuilder(model).build() }/>
  )
};

const ButtonsCollection: React.FC = () => {
  const intl = useIntl();
  const camera = useCameraData()
  // const classes = useStyles()

  const gpioPressActions = useGpioPressActionData()

  if (!camera) {
    return (<CameraNotAvailable/>)
  }

  const isAvaialbleShutdown = gpioPressActions.isAvaialbleSoftShutdownPi
  const dataProvider = CameraContext.current?.gpioPressActionsDataProvider

  const handleReboot = () => {
    if (!window.confirm(intl.formatMessage({ id: LOCALIZATION.confirm_reboot_pi }))) {
      return
    }

    dataProvider?.softRebootPi().then(() => {
      // console.log('success')
    }).catch(error => {
      ErrorMessageHelper.handleError(generateUserError(error))
    })
  }

  const handleShutdown = () => {
    if (!window.confirm(intl.formatMessage({ id: LOCALIZATION.confirm_shut_down_pi }))) {
      return
    }

    dataProvider?.softShutDownPi().then(() => {
      // console.log('success')
    }).catch(error => {
      ErrorMessageHelper.handleError(generateUserError(error))
    })
  }

  // const handleOpenSSHConsole = () => {
  //   ErrorMessageHelper.handleError(Error(intl.formatMessage({ id: LOCALIZATION.not_implemented })))
  // }
  const isOnline = camera.status === 'on'

  return (
    <Grid container spacing={ 1 } direction="row" justifyContent="flex-end"
          style={ { display: isOnline ? undefined : 'none' } }>
      <Grid item key="shutdown" style={ {
        display: isAvaialbleShutdown ? undefined : 'none'
      } }>
        <MainButton onClicked={ handleShutdown } title={ intl.formatMessage({ id: LOCALIZATION.shut_down }) } />
      </Grid>
      <Grid item>
        <MainButton buttonType={ ButtonType.Outline } onClicked={ handleReboot } title={ intl.formatMessage({ id: LOCALIZATION.reboot }) } />
      </Grid>
      {/* <Grid item>
        <MainButton buttonType={ ButtonType.Outline } onClicked={ handleOpenSSHConsole } title={ intl.formatMessage({ id: LOCALIZATION.ssh_console }) } />
      </Grid> */}
    </Grid>
  )
};

type Props = Readonly<{
  settings?: CameraSettingsInterface
}>;

const PICard: React.FC<Props> = (props: Props) => {
  if (props.settings && !isCameraSettingsOptionActive(props.settings?.activeOptions?.havePi)) {
    return null;
  }

  return (
    <MainCard>
      <CardHeader/>
      <MainCardContent>
        <Grid container spacing={ 1 }>
          <Grid item xs={ 12 }>
            <Grid
              sx={ { height: 250 } }
            >
              <PiChartView />
            </Grid>
          </Grid>
          <Grid item xs={ 12 }>
            <ButtonsCollection />
          </Grid>
        </Grid>
      </MainCardContent>
    </MainCard>
  )
}


export default PICard;
