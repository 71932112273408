// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { formattedMetricValue, formattedState } from '../../../Tools/Tools'
import { JetsonInterface, JetsonStatusReviewInterface } from './Types'
import LOCALIZATION from '../../../Localization';

export class JetsonStatusReviewBuilder {
    private intl = (props: { id: string }) => '';
    constructor(private jetson: JetsonInterface, intl: any) {
        this.intl = intl;
    }

    build(): JetsonStatusReviewInterface {
        const cpuTemp = this.jetson.cpuTemp
        const cpuLoad = this.jetson.cpuLoad
        const gpuTemp = this.jetson.gpuTemp
        const gpuLoad = this.jetson.gpuLoad
        const boardTemp = this.jetson.boardTemp
        const voltage = this.jetson.powerVoltage

        const temperatureControllerData = this.jetson.temperatureControllerData

        let isCritical = false

        const critical = temperatureControllerData?.critical

        if (cpuTemp !== undefined && critical) {
            if (cpuTemp > critical) {
                isCritical = true
            }
        }

        let fan: boolean = false
        switch (temperatureControllerData?.state) {
            case 'on':
                fan = true
                break

            case 'off':
                fan = false
                break
        }

        const cpuLoadString = formattedMetricValue(cpuLoad, '%', { name: this.intl({ id: LOCALIZATION.cpu }), multiplyUnit: false })
        const cpuTempString = formattedMetricValue(cpuTemp, '˚C')
        const gpuLoadString = formattedMetricValue(gpuLoad, '%', { name: this.intl({ id: LOCALIZATION.gpu }), multiplyUnit: false })
        const gpuTempString = formattedMetricValue(gpuTemp, '˚C')

        // Критичное состоение считается относительно CPU.  Поэтому ! можем ставить прямо рядом с ним.
        let cpuStatusString: string | undefined = [cpuLoadString, cpuTempString].filter(text => !!text).join(', ')
        if (cpuStatusString.length === 0) {
            cpuStatusString = undefined
        }

        let gpuStatusString: string | undefined = [gpuLoadString, gpuTempString].filter(text => !!text).join(', ')
        if (gpuStatusString.length === 0) {
            gpuStatusString = undefined
        }

        const cpuString = cpuStatusString
        const boardTempString = formattedMetricValue(boardTemp, '˚C', { name: this.intl({ id: LOCALIZATION.board }) })
        const fanString = formattedState(fan, { name: this.intl({ id: LOCALIZATION.fan }), symbolize: false })
        const voltageString = formattedMetricValue(voltage, 'V', { name: this.intl({ id: LOCALIZATION.voltage }) })
        const statuses: string[] = []
        if (isCritical) {
            statuses.push('⚠️')
        }
        statuses.push([cpuString, gpuStatusString, boardTempString, fanString, voltageString].filter(text => !!text).join(' | '))

        return {
            id: this.jetson.jetsonId,
            name: this.jetson.name,
            isCritical: isCritical,
            statuses: statuses
        }
    }
}
