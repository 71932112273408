// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { delay, JetsonTools } from '../../../Tools/Tools'
import { ObservableDataProvider } from '../../Common/ObservableDataProvider'
import { JetsonHardwareButtonsCollectionInterface, JetsonId, JetsonNames } from '../Jetson/Types'
import { PlatformConfiguration } from '../../../Configuration/PlatformConfiguration'
import GpioPressActionsApiService from './Networking'
import { GpioPressAction } from './Types'

export interface GpioPressActionsDataProviderInterface {
    readonly pressActions: GpioPressAction[]
    readonly updateInterval: number

    softShutDownPi(): Promise<void>
    softShutDownJetson(id: JetsonId): Promise<void>
    turnOnJetson(id: JetsonId): Promise<void>

    pressJetsonHardwareButtons(id: JetsonId, collection: JetsonHardwareButtonsCollectionInterface): Promise<void>
    startFirmwareUpdate(jetsonId: JetsonId): Promise<void>
}

export class GpioPressActionsDataProvider extends ObservableDataProvider<GpioPressAction[]> implements GpioPressActionsDataProviderInterface {
    private apiService = new GpioPressActionsApiService()
    data?: GpioPressAction[]
    get pressActions(): GpioPressAction[] {
        return this.data ?? []
    }

    get name(): string {
        return 'GPIO'
    }

    get updateInterval(): number {
        return PlatformConfiguration.gpioActionsUpdatesTimeInterval
    }

    async update() {
        try {
            this.data = await this.apiService.getPressActions()
        } catch (error) {
            // console.log(`GpioPressActionsDataProvider > failed to fetch values: ${error}`)
        }
    }

    async softShutDownPi() {
        await this.apiService.sendPressAction('pipower')
    }
    async softRebootPi() {
        await this.apiService.sendPressAction('pireboot')
    }

    async softShutDownJetson(id: JetsonId) {
        const pressAction = JetsonTools.getSoftShutdownGpioPressAction(id)
        await this.apiService.sendPressAction(pressAction)
    }

    async softRebootJetson(id: JetsonId) {
        const pressAction = JetsonTools.getSoftRebootGpioPressAction(id)
        await this.apiService.sendPressAction(pressAction)
    }

    async pressJetsonHardwareButtons(id: JetsonId, collection: JetsonHardwareButtonsCollectionInterface) {
        const pressActions = JetsonTools.getGpioPressActionsFromHardwareButtonsCollection(collection, id)
        await this.apiService.sendPressActions(pressActions, collection.duration)
    }

    async turnOnJetson(id: JetsonId): Promise<void> {
        const power: () => GpioPressAction = () => {
            switch (id) {
                case JetsonNames.Jetson1:
                    return 'j1power'
                case JetsonNames.Jetson2:
                    return 'j2power'
            }
        }
        await this.apiService.sendPressAction(power())
    }

    // зажать “Factory Reset” -> нажать и отпустить Power -> подождать 2 сек -> нажать Reset и подержать 2 сек и отпустить -> отпустить “Factory Reset”
    async startFirmwareUpdate(jetsonId: JetsonId) {
        const recovery: () => GpioPressAction = () => {
            switch (jetsonId) {
                case JetsonNames.Jetson1:
                    return 'j1recovery'
                case JetsonNames.Jetson2:
                    return 'j2recovery'
            }
        }

        const power: () => GpioPressAction = () => {
            switch (jetsonId) {
                case JetsonNames.Jetson1:
                    return 'j1power'
                case JetsonNames.Jetson2:
                    return 'j2power'
            }
        }

        const reset: () => GpioPressAction = () => {
            switch (jetsonId) {
                case JetsonNames.Jetson1:
                    return 'j1reset'
                case JetsonNames.Jetson2:
                    return 'j2reset'
            }
        }

        // зажать “Factory Reset”
        await this.apiService.sendPressActions([recovery()], 6000)
        await delay(1000)

        // нажать и отпустить Power
        await this.apiService.sendPressActions([power()], 500)
        // подождать 2 сек (500 милисекунд будет нажат повер)
        await delay(3000)

        // нажать Reset и подержать 0.5-2 сек и отпустить
        await this.apiService.sendPressActions([reset()], 500)
    }
}
