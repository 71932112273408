// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

export enum CameraStateValue {
  ConfigApplying = 'config_applying',
  Running = 'running',
  Error = 'error',
  Stopped = 'stopped',
}


export enum CameraOperatorOutputValue {
  Connected = 'connected',
  Writing = 'writing',
}


export type FrameResolution = Readonly<{
  width: number;
  height: number;
}>;

export type PanoramaOutput = Readonly<{
  state: string;              // I seen Only 'writing' Value
  'dropped-packets': number;
  'dropped-size': number;
  'last-dropped-time': string;
  'prepared-bitrate': number;
  'sent-bitrate': number;
}>;


type CameraState = Readonly<{
  id: string;
  state: CameraStateValue;
  'full-frame-resolution': FrameResolution;
  'cropped-frame-resolution': FrameResolution;
  'panorama-output': PanoramaOutput[];
  'operator-resolution': FrameResolution;
  'operator-output': Array<{            // Несколько аутпутов на YouTube в виде файлика и пр.
    state: CameraOperatorOutputValue;
  }>;
  message?: string;
}>;


export default CameraState;
