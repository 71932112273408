// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import LOCALIZATION from "../../Localization"
import { intl } from "../../Localization/LocalizationProvider"
import { SportType } from "../EPG/EpgSchedule/EventWithoutID";

export enum AnalyticsType {
    VideoRecord = 'video_record',
    Game = 'game',
    StatScore = 'score',
    StatDistance = 'distance',
    StatAttackDefense = 'attack',
    StatHeatmap = 'heatmap',
    GameEvent = 'game_event',
}

export enum AnalyticsSportType {
    Football = 'football',
    MiniFootball = 'minifootball',
    Basketball = 'basketball',
    Handball = 'handball',
    Hockey = 'hockey',
    HockeyWithBall = 'hockey_with_ball',
    Unknown = 'unknown',
}
export const ANALYTICS_SPORT_ID_ARR = Object.values(AnalyticsSportType)
export const localizedAnalyticsSportNameById = (sportId: string | undefined): string => {
    switch (sportId) {
      case AnalyticsSportType.Football:
        return intl().formatMessage({ id: LOCALIZATION.event_sport_type_football }) || '';
      case AnalyticsSportType.Hockey:
        return intl().formatMessage({ id: LOCALIZATION.event_sport_type_hockey }) || '';
      case AnalyticsSportType.Basketball:
        return intl().formatMessage({ id: LOCALIZATION.event_sport_type_basketball }) || '';
      case AnalyticsSportType.HockeyWithBall:
        return intl().formatMessage({ id: LOCALIZATION.event_sport_type_hockey_with_ball }) || '';
      case AnalyticsSportType.Handball:
        return intl().formatMessage({ id: LOCALIZATION.event_sport_type_handball }) || '';
      case AnalyticsSportType.MiniFootball:
        return intl().formatMessage({ id: LOCALIZATION.event_sport_type_mini_football }) || '';
      case AnalyticsSportType.Unknown:
        return intl().formatMessage({ id: LOCALIZATION.event_sport_type_unknown }) || '';
    }
    return sportId || 'Unknown'
  }

export const analyticsSportTypeFromSportType = (sportType: SportType | undefined) : AnalyticsSportType | undefined => {
    if (sportType === undefined) {
        return undefined;
    }
    switch (sportType) {
        case SportType.Football:
          return AnalyticsSportType.Football;
        case SportType.Hockey:
          return AnalyticsSportType.Hockey;
        case SportType.Basketball:
          return AnalyticsSportType.Basketball;
        case SportType.HockeyWithBall:
          return AnalyticsSportType.HockeyWithBall;
        case SportType.Handball:
          return AnalyticsSportType.Handball;
        case SportType.MiniFootball:
          return AnalyticsSportType.MiniFootball;
        case SportType.MultipleSports:
          return AnalyticsSportType.Unknown;
        case SportType.Unknown:
          return AnalyticsSportType.Unknown;
    }
    return undefined
}

export enum AnalyticsTimeIntervalType {
    unknown = 'unknown',
    time = 'time',
    overtime = 'overtime',
    penalty = 'penalty',
}

export type AnalyticsTimeIntervalTypeHeader = {
    name?: string;
    type?: AnalyticsTimeIntervalType;
    order_num?: number; // order number among all time periods for this game. like 0 - time, 1-time, 2-overtime, 3-overtime
    type_order_num?: number; // order number in this type. like: 1 time = Time #2 (because 0 is first time)
    team1_side?: AnalyticsStatTeamSide;
    team2_side?: AnalyticsStatTeamSide;
    duration?: number;
    start_at?: string;
    end_at?: string;
};

export type AnalyticsTimeIntervalTypeHeaderAny = { // wrapper to use stat to get granula length
    interval_stat?: any;
    granules?: any[];
} & AnalyticsTimeIntervalTypeHeader;

export enum AnalyticsStatGranularity {
    Granularity0 = '0',
    Granularity2 = '2',
    Granularity4 = '4',
    Granularity8 = '8',
}
export const AnalyticsStatGranularityMax = 8; 

export const ANALYTICS_GRANULARITY_ARR = [
    AnalyticsStatGranularity.Granularity0, AnalyticsStatGranularity.Granularity2, AnalyticsStatGranularity.Granularity4
    //, AnalyticsStatGranularity.Granularity8
]

export enum AnalyticsStatAction {
    Attack = 'attack',
    Defense = 'defence',
}

export enum AnalyticsStatTeamSide {
    left = 'left',
    right = 'right',
}

export const analyticsTimeIntervalName = (type?: AnalyticsTimeIntervalType, orderNum?: number): string => {
    switch (type) {
        case AnalyticsTimeIntervalType.unknown: 
            return intl().formatMessage({ id: LOCALIZATION.unknown }) 

        case AnalyticsTimeIntervalType.time: {
            switch (orderNum) {
                case 0: 
                    return intl().formatMessage({ id: LOCALIZATION.analytics_type_time1 }) 
                case 1: 
                    return intl().formatMessage({ id: LOCALIZATION.analytics_type_time2 }) 
                case 2: 
                    return intl().formatMessage({ id: LOCALIZATION.analytics_type_time3 }) 
                case 3: 
                    return intl().formatMessage({ id: LOCALIZATION.analytics_type_time4 }) 
            }
            return intl().formatMessage({ id: LOCALIZATION.game_event_time_start }, {number: (orderNum === undefined) ? "" : orderNum + 1 }) 
        }

        case AnalyticsTimeIntervalType.overtime: {
            switch (orderNum) {
                case 0: 
                    return intl().formatMessage({ id: LOCALIZATION.analytics_type_overtime1 }) 
                case 1: 
                    return intl().formatMessage({ id: LOCALIZATION.analytics_type_overtime2 }) 
                case 2: 
                    return intl().formatMessage({ id: LOCALIZATION.analytics_type_overtime3 }) 
            }
            return intl().formatMessage({ id: LOCALIZATION.game_event_overtime_start }, {number: (orderNum === undefined) ? "" : orderNum + 1 }) 
        } 
        case AnalyticsTimeIntervalType.penalty: {
            switch (orderNum) {
                case 0: 
                    return intl().formatMessage({ id: LOCALIZATION.analytics_type_penalty1 }) 
                case 1: 
                    return intl().formatMessage({ id: LOCALIZATION.analytics_type_penalty2 }) 
            }
            return intl().formatMessage({ id: LOCALIZATION.game_event_penalty_start }, {number: (orderNum === undefined) ? "" : orderNum + 1 }) 
        } 
    }
    return intl().formatMessage({ id: LOCALIZATION.unknown }) 
}

export const analyticsTimeIntervalShortName = (type?: AnalyticsTimeIntervalType, orderNum?: number): string => {
    switch (type) {
        case AnalyticsTimeIntervalType.unknown: 
            return intl().formatMessage({ id: LOCALIZATION.unknown_short }) 

        case AnalyticsTimeIntervalType.time: {
            return intl().formatMessage({ id: LOCALIZATION.game_event_time_short }, {number: (orderNum === undefined) ? "" : orderNum + 1 }) 
        }

        case AnalyticsTimeIntervalType.overtime: {
            return intl().formatMessage({ id: LOCALIZATION.game_event_overtime_short }, {number: (orderNum === undefined) ? "" : orderNum + 1 }) 
        } 
        case AnalyticsTimeIntervalType.penalty: {
            return intl().formatMessage({ id: LOCALIZATION.game_event_penalty_short }, {number: (orderNum === undefined) ? "" : orderNum + 1 }) 
        } 
    }
    return intl().formatMessage({ id: LOCALIZATION.unknown_short }) 
}


export const analyticsGranularityName = (type?: AnalyticsStatGranularity): string => {
    switch (type) {
        case AnalyticsStatGranularity.Granularity0: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_ganularity_0 }) 
        case AnalyticsStatGranularity.Granularity2: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_ganularity_2 }) 
        case AnalyticsStatGranularity.Granularity4: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_ganularity_4 }) 
        case AnalyticsStatGranularity.Granularity8: 
            return intl().formatMessage({ id: LOCALIZATION.analytics_ganularity_8 }) 
    }
    return intl().formatMessage({ id: LOCALIZATION.unknown }) 
}