// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import Order from '../../_Networking/ReactQuery/Order';
import fetchJSONData from '../../_Networking/ReactQuery/fetch';
import { getTimezoneInMinutes } from '../../../Tools/timezone';
import { PAGE_LIMIT, NODE_JS_BACKEND_URL } from '../../../constants';
import { queryCache, queryClient } from '../../../AppProviders';
import ResponseMany from '../../_Networking/ReactQuery/ResponseMany';
import ResponseSingle from '../../_Networking/ReactQuery/ResponseSingle';
import Archive, { FileType } from '..';
import { ArchiveForActionProps, Action, ActionType } from './useMutation';
import { getCachedDataAllPagedItems, Result, usePagedItems } from '../../_Networking/ReactQuery/template';


type FetchProps = Readonly<{
  type: FileType;
  page: number;
  limit?: number;
  date?: string;
  order?: Order;
  orderBy?: 'date';
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'archive';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.type,
  props.date,
  props.page,
  props.limit || PAGE_LIMIT,
  props.orderBy,
  props.order,
]);

const getAllCached = () => (
  getCachedDataAllPagedItems<Archive>(getKeyWithoutPage())
);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<ResponseMany<Archive[]>>(getKey(props))
);

const flushCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};

const patchArchive = async (archive: ArchiveForActionProps): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<boolean>>(
        `${NODE_JS_BACKEND_URL}/archive`,
        {
          method: 'PATCH',
          body: JSON.stringify({ timezone: getTimezoneInMinutes(), archive }),
        }
    );

    if (!data?.error) {
      return ({
        archives: [archive],
        type: ActionType.Patch,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Edit Archive');
    }
  } catch (error) {
    throw error;
  }
};

const deleteArchives = async (archives: ArchiveForActionProps[]): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<boolean>>(
        `${NODE_JS_BACKEND_URL}/archive`,
        {
          method: 'DELETE',
          body: JSON.stringify({ timezone: getTimezoneInMinutes(), archives }),
        }
    );

    if (!data?.error) {
      return ({
        archives,
        type: ActionType.Delete,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Delete Archives');
    }
  } catch (error) {
    throw error;
  }
};

const sendArchivesToSyncServer = async (archives: ArchiveForActionProps[]): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<boolean>>(
        `${NODE_JS_BACKEND_URL}/archive/sendToSyncServer`,
        {
          method: 'POST',
          body: JSON.stringify({ timezone: getTimezoneInMinutes(), archives }),
        }
    );

    if (!data?.error) {
      return ({
        archives,
        type: ActionType.SendToSyncServer,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Send to Sync Server Archives');
    }
  } catch (error) {
    throw error;
  }
};

const fetchData = async (props: FetchProps): Promise<ResponseMany<Archive[]>> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  const { limit = PAGE_LIMIT } = props;
  const offset = (props.page * limit);
  let params = `?type=${ props.type }&timezone=${getTimezoneInMinutes()}&limit=${ limit }&offset=${ offset }`;

  if (props.orderBy) {
    params += `&order=${ props.order }&orderBy=${ props.orderBy }`;
  }

  if (props.date) {
    params += `&date=${ props.date }`;
  }

  return await fetchJSONData<ResponseMany<Archive[]>>(
    `${NODE_JS_BACKEND_URL}/archive${ params }`,
    {
      method: 'GET',
    }
  );
};


type UseFetchProps = Readonly<{
  enableToFetch: boolean;
} & FetchProps>;


const useFetch = (props: UseFetchProps): Result<Archive> => {
  const { enableToFetch = true, page: pg = 0, limit = PAGE_LIMIT } = props;
  const fetchTemplate = async (queryKey: QueryKey, page: number = 0) => fetchData({
    page,
    limit,
    type: props.type,
    order: props.order,
    orderBy: props.orderBy,
    date: props.date,
  });

  return usePagedItems<Archive>({
    page: pg,
    fetch: fetchTemplate,
    queryKey: getKey({
      page: pg,
      limit,
      type: props.type,
      order: props.order,
      orderBy: props.orderBy,
      date: props.date,
    }),
    config: {
      enabled: !!enableToFetch,
    },
  });
};


export {
  getKey,
  useFetch,
  getCached,
  flushCache,
  getAllCached,
  patchArchive,
  deleteArchives,
  sendArchivesToSyncServer,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
