// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { isArray } from 'lodash';
import { QueryKey } from 'react-query';

import { JAVA_BACKEND_URL, PAGE_LIMIT } from '../../../../constants';
import Camera, { CameraExtraJson, CameraModelType, NewCamera } from '../Camera';
import { Action, ActionType } from '../hook/useMutation';
import { queryClient } from '../../../../AppProviders';
import { setAccessToken } from '../../../../Tools/token';
import { isSuccessResponse, SuccessResponse } from '../../../AccountUsers/fetch';
import fetchJSONData, { ErrorResponse } from '../../../_Networking/ReactQuery/fetch';
import { RemoteCameraStateValue } from '../../RemoteCameraState';
import Order from '../../../_Networking/ReactQuery/Order';
import { isCameraSettings } from '../../../../Pages/CamerasPage/helpers';
import { CameraSettingsInterface } from '../../HWCameraSettings/CameraSettings';
import { SportType } from '../../../EPG/EpgSchedule/EventWithoutID';


const getKey = (): QueryKey => ('camera-active');

const getCamerasKey = (): QueryKey => ('cameras-list');

const isNoActiveCamera = (object: undefined | Camera | ErrorResponse): object is ErrorResponse => (
  object === undefined || (object as ErrorResponse).httpStatus === 404
);

const isCameraAProxy = (object: undefined | Camera | ErrorResponse): boolean => {
  if (isValidCamera(object)) {
    const camera = object as Camera
    if (camera?.extraJson) {
      const json = JSON.parse(camera.extraJson) as CameraExtraJson;
      if (json) {
        return json?.cameraModel === CameraModelType.Proxy;
      }
    }
    else if (isCameraSettings(camera?.state?.lastSettingsResponse)) {
      const settings = camera?.state?.lastSettingsResponse as CameraSettingsInterface;
      return settings?.activeOptions?.isProxyMode === "true"
    }
  }
  return false
};

export const DEFAULT_SPORT_TYPE = SportType.Football;
const getDefaultCameraSportType = (defaultSportType: SportType | undefined = DEFAULT_SPORT_TYPE): SportType | undefined => {
  const activeCameraData = getCachedActiveCamera();
  if (isValidCamera(activeCameraData)) {
    const camera = activeCameraData as Camera
    if (camera?.extraJson) {
      const json = JSON.parse(camera.extraJson) as CameraExtraJson;
      if (json) {
        return json?.defaultSportType || defaultSportType;
      }
    }
  }
  return defaultSportType
};

const isValidCamera = (object: undefined | Camera | ErrorResponse): object is Camera => (
  (object as Camera) !== undefined
);

const getCachedActiveCamera = (): undefined | Camera | ErrorResponse => (
  queryClient.getQueryData<Camera>(getKey())
);

const getCachedCameras = (): Camera[] | undefined => (
  queryClient.getQueryData<Camera[]>(getCamerasKey())
);

export const clearActiveCameraCache = () => {
  const queryKey = getKey();

  queryClient.resetQueries(queryKey);
};

export const clearCamerasCache = () => {
  const queryKey = getCamerasKey();

  queryClient.resetQueries(queryKey);
};


const fetchActiveCamera = async (): Promise<Camera | ErrorResponse | undefined> => {
  try {
    const cacheData = getCachedActiveCamera();

    if (cacheData) { return cacheData; }

    const onError = (error: ErrorResponse) => {
      // console.log('onError', error);
    };

    const result = await fetchJSONData<Camera>(
      `${JAVA_BACKEND_URL}/cameras/active`,
      {
        method: 'GET',
      },
      onError,
    );

    return result;
  } catch (error) {
    return undefined;
  }
};

const changeActiveCamera = async (cameraId: string): Promise<boolean> => {
  try {
    const onError = (error: ErrorResponse) => {
      // console.log('onError', error);
    };

    const result = await fetchJSONData<SuccessResponse | Error>(
      `${JAVA_BACKEND_URL}/cameras/active`,
      {
        method: 'POST',
        body: JSON.stringify({ cameraId }),
      },
      onError,
    );

    if (isSuccessResponse(result)) {
      setAccessToken(result.newAccessToken)
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

/**
 * Gets a list of all available cameras
 * @deprecated Better used with pagination to optimize memory usage
 * @see {getCamerasList}
 * @returns
 */
const getCameras = async (): Promise<Camera[] | undefined> => {
  try {
    const cacheData = getCachedCameras();

    if (cacheData) { return cacheData; }

    const cameras = await fetchJSONData<Camera[]>(
      `${JAVA_BACKEND_URL}/cameras`,
      {
        method: 'GET',
      }
    );

    return isArray(cameras) ? cameras : [];
  } catch (error) {
    return [];
  }
};


export enum CameraListOrderByVariant {
  id = "id",
  cameraId = "cameraId",
  name = "name",
  addressPi = "addressPi",
  addressJ1 = "addressJ1",
  addressJ2 = "addressJ2",
  teqInfo = "teqInfo",
  notes = "notes",
  extraJson = "extraJson",
  whenCreated = "whenCreated",
  whenUpdated = "whenUpdated",
}
interface IGetCameraListProps {
  offset: number;
  limit?: number;
  filterByCameraID?: string;
  stateFilter?: RemoteCameraStateValue,
  sort?: {
    order: Order,
    by: CameraListOrderByVariant,
  }
}

/**
 * Getting a list of cameras with pagination
 * @param props
 * @returns
 */
export const getCamerasList = async (props: IGetCameraListProps): Promise<Camera[]> => {
  const { offset, limit = PAGE_LIMIT, filterByCameraID, stateFilter, sort } = props;
  try {
    let params = `?max=${limit}&first=${offset}`;

    if (filterByCameraID) {
      params += `&filter_cameraList.cameraId=${filterByCameraID}`;
    }
    if (stateFilter) {
      params += `&filter_state.state=${stateFilter}`
    }
    if (sort) {
      params += `&direction=${sort.order}`
      params += `&sort=${sort.by}`
    }
    const res = await fetchJSONData<Camera[]>(
      `${JAVA_BACKEND_URL}/cameras${params}`,
      {
        method: 'GET',
      }
    );
    return res;
  } catch {
    return []
  }
}

const deleteCamera = async (camera: Camera): Promise<Action | Error> => {
  try {
    await fetchJSONData<Camera>(
      `${JAVA_BACKEND_URL}/cameras/${camera.cameraId}`,
      {
        method: 'DELETE',
      }
    );

    return ({
      type: ActionType.Delete,
      camera,
    });
  } catch (error) {
    throw error;
  }
};

const addCamera = async (camera: Camera | NewCamera, login?: string, password?: string): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<Camera>(
      `${JAVA_BACKEND_URL}/cloud/attach`,
      {
        method: 'POST',
        body: JSON.stringify({ ...camera, login, password }),
      }
    );

    return ({
      type: ActionType.Add,
      camera: data,
    });
  } catch (error) {
    throw error;
  }
};

const patchCamera = async (camera: Camera): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<Camera>(
      `${JAVA_BACKEND_URL}/cameras/${camera.cameraId}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ ...camera }),
      }
    );

    return ({
      type: ActionType.Patch,
      camera: data,
    });
  } catch (error) {
    throw error;
  }
};


export {
  getKey,
  fetchActiveCamera,
  getCameras,
  addCamera,
  patchCamera,
  deleteCamera,
  getCamerasKey,
  isNoActiveCamera,
  isCameraAProxy,
  getDefaultCameraSportType,
  isValidCamera,
  getCachedCameras,
  changeActiveCamera,
  getCachedActiveCamera,
};
