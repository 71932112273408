// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { analyticsConvertAllData, analyticsConvertedDataUnitName, analyticsConvertedLocalizedWithUnits } from "../../../Data/Analytics/AnalyticsConverters";
import AnalyticsGameArchive from "../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive";
import { AnalyticsStatAttackDefenseTeam } from "../../../Data/Analytics/AnalyticsStatAttackDefenseTime/AnalyticsStatAttackDefenseTime";
import { AnalyticsStatDistanceGame } from "../../../Data/Analytics/AnalyticsStatDistance/AnalyticsStatDistance";
import { AnalyticsStatPossessionTeam } from "../../../Data/Analytics/AnalyticsStatPossession/AnalyticsStatPossession";
import { AnalyticsStatSpeedTeam } from "../../../Data/Analytics/AnalyticsStatSpeed/AnalyticsStatSpeed";
import { GetTeamRefById } from "../../../Data/Analytics/AnalyticsTeamRef/hook";
import LOCALIZATION from "../../../Localization";
import { intl } from "../../../Localization/LocalizationProvider";
import { BarChartItem } from "./helper";

export const createTeamMainReportBarChartConfig = 
(
  gameResultInfo: AnalyticsGameArchive | undefined,
  distanceStat: AnalyticsStatDistanceGame | undefined, 
  speedStat: AnalyticsStatSpeedTeam | undefined, 
  possessionStat: AnalyticsStatPossessionTeam | undefined,
  attackDefenseTimeStat: AnalyticsStatAttackDefenseTeam | undefined,
  getTeamRefByExternalId: GetTeamRefById | undefined,
  ): BarChartItem[] => 
{

  const convertedData = analyticsConvertAllData(
    getTeamRefByExternalId?.(gameResultInfo?.team1)?.shortName,
    getTeamRefByExternalId?.(gameResultInfo?.team2)?.shortName,

    gameResultInfo?.score,
    possessionStat,
    distanceStat,
    speedStat,
    attackDefenseTimeStat,
  );

  let outArr: BarChartItem[] = []

  if (convertedData.score) {
    outArr.push({
      label: intl().formatMessage({ id: LOCALIZATION.analytics_score }),
      value1: convertedData.score.team1,
      value2: convertedData.score.team2
    })  
  }
  
  if (convertedData.possession) {
    outArr.push({
      label: analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_possession, convertedData.possession),
      value1: convertedData.possession.team1,
      value2: convertedData.possession.team2,
      normBase: convertedData.possession.normBase,
      units: analyticsConvertedDataUnitName(convertedData.possession.units),
    })
  }

  if (convertedData.avgTimeInPossession) {
    outArr.push({
      label: analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_avg_time_in_possession, convertedData.avgTimeInPossession),
      value1: convertedData.avgTimeInPossession.team1,
      value2: convertedData.avgTimeInPossession.team2,
    })
  }

  if (convertedData.distanceCovered) {
    outArr.push({
      label: analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_distance_covered, convertedData.distanceCovered),
      value1: convertedData.distanceCovered.team1,
      value2: convertedData.distanceCovered.team2,
    })
  }

  if (convertedData.avgSpeed) {
    outArr.push({
      label: analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_avg_speed, convertedData.avgSpeed),
      value1: convertedData.avgSpeed.team1,
      value2: convertedData.avgSpeed.team2,
    })
  }

  if (convertedData.attackTime) {

    outArr.push({
      label: analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_attack_time, convertedData.attackTime),
      value1: convertedData.attackTime.team1,
      value2: convertedData.attackTime.team2,
    })
  }

  if (convertedData.defenseTime) {
    outArr.push({
      label: analyticsConvertedLocalizedWithUnits(LOCALIZATION.analytics_defense_time, convertedData.defenseTime),
      value1: convertedData.defenseTime.team1,
      value2: convertedData.defenseTime.team2,
    })

  }

  return outArr;
}