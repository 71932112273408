// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import {
  UseFetchProps,
  useFetchStatScore,
  flushCache,
  getCached,
} from '../fetch';
import { AnalyticsStatScore } from '../AnalyticsStatScore';


type Result = Readonly<{
  status: string;
  data: AnalyticsStatScore | undefined;
  handleFlushData: () => void;
}>;


export const useAnalyticsStatScore = (props: UseFetchProps): Result => {
  const fetchedArchive = useFetchStatScore(props);
  const data = getCached(props);

  return ({
    status: fetchedArchive.status,
    data: data?.data,
    handleFlushData: flushCache,
  });
};

