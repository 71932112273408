// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';

import AppWrapper from './AppWrapper';
import AppContextProvider from './Localization/AppContextProvider';
import LocalizationProvider from './Localization/LocalizationProvider';
import { ReactQueryDevtools } from 'react-query/devtools';

// eslint-disable-next-line global-require
require('whatwg-fetch');


type Props = Readonly<{}>;


export const queryCache = new QueryCache();
export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ['data', 'error'],
      retry: 1,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});
const theme = createTheme({
  spacing: 10,
});

export const removeAllQueryCache = () => {
  queryClient.resetQueries()
};

const AppProviders: React.FC<Props> = () => (
  <BrowserRouter>
    <ThemeProvider
      theme={ theme }
    >
      <QueryClientProvider
        client={ queryClient }
      >
        <AppContextProvider>
          <LocalizationProvider>
            <StyledEngineProvider injectFirst>
              <AppWrapper />
              { (process.env.NODE_ENV === 'development') && <ReactQueryDevtools initialIsOpen={ false }/> }
            </StyledEngineProvider>
          </LocalizationProvider>
        </AppContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </BrowserRouter>
);


export default AppProviders;
