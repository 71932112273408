// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { CameraSettingsInterface } from '../../Data/Camera/HWCameraSettings/CameraSettings';
import { CameraSystemStateValue } from '../../Data/Camera/HWCameraSettings/CameraSettingsStreamingState';
import { CameraSystemStates, CameraSystemStateField } from '../../Data/Camera/HWCameraSettings/CameraSettingsStuff';
import { SettingsResponseError } from '../../Data/Camera/RemoteCameraState';

const isCameraSettings = (object: CameraSettingsInterface | SettingsResponseError): object is CameraSettingsInterface => (
  (object as CameraSettingsInterface)?.id !== undefined
);


const filterCriticalsStates = (systemStates?: CameraSystemStates): Partial<CameraSystemStates> | null | undefined => {
  if (!systemStates) {
    return undefined
  }
  const result: Partial<CameraSystemStates> = {};
  const keys = Object.keys(systemStates);

  keys.forEach((key) => {
    if (systemStates[key as CameraSystemStateField] !== CameraSystemStateValue.Ok) {
      result[key as CameraSystemStateField] = systemStates[key as CameraSystemStateField];
    }
  });

  if (Object.keys(result).length === 0) { return null; }

  return result;
}


export {
  filterCriticalsStates,
  isCameraSettings,
};
