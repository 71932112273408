// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import fetchJSONData from '../../../_Networking/ReactQuery/fetch';
import { ANALYTICS_BACKEND_URL } from '../../../../constants';
import { queryCache, queryClient } from '../../../../AppProviders';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import ResponseSingle from '../../../_Networking/ReactQuery/ResponseSingle';
import { ArchiveForActionProps, Action, ActionType } from './useMutation';
import { getCachedDataAllPagedItems, Result, usePagedItems } from '../../../_Networking/ReactQuery/template';
import { AnalyticsGameEvent } from '../AnalyticsGameEvent';

type FetchProps = Readonly<{
  parentGameId?: string;// use to filter all games connected to same video
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'analytics-games-events-archive';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.parentGameId,
]);

const getAllCached = () => (
  getCachedDataAllPagedItems<AnalyticsGameEvent>(getKeyWithoutPage())
);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<ResponseMany<AnalyticsGameEvent[]>>(getKey(props))
);

const flushCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};

const patchEvents = async (archive: ArchiveForActionProps): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<boolean>>(
        `${ANALYTICS_BACKEND_URL}/games/${archive.id}/events`,
        {
          method: 'PATCH',
          body: JSON.stringify(archive.events),
        }
    );

    if (!data?.error) {
      return ({
        archives: archive,
        type: ActionType.Patch,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Edit Archive');
    }
  } catch (error) {
    throw error;
  }
};


const fetchData = async (props: FetchProps): Promise<ResponseMany<AnalyticsGameEvent[]>> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  return await fetchJSONData<ResponseMany<AnalyticsGameEvent[]>>(
    `${ANALYTICS_BACKEND_URL}/games/${ props.parentGameId }/events`,
    {
      method: 'GET',
    }
  );
};


type UseFetchProps = Readonly<{
  enableToFetch: boolean;
} & FetchProps>;


const useFetch = (props: UseFetchProps): Result<AnalyticsGameEvent> => {
  const { enableToFetch = true, } = props;
  const fetchTemplate = async (queryKey: QueryKey, page: number = 0) => fetchData({
    parentGameId: props.parentGameId,
  });

  return usePagedItems<AnalyticsGameEvent>({
    page: 0,
    fetch: fetchTemplate,
    queryKey: getKey({
      parentGameId: props.parentGameId,
    }),
    config: {
      enabled: !!enableToFetch,
    },
  });
};


export {
  getKey,
  useFetch,
  getCached,
  flushCache,
  getAllCached,
  patchEvents,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
