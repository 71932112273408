// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { compact } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import LOCALIZATION from '../../../../Localization';
import enumToObject from '../../../../Tools/enumToObject';
import FieldSetSelectComponent from '../../../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import { FieldSetInputType } from '../../../../Components/_BaseUI/FieldSet';


export enum SelectPreSet {
  All = 'All',
  RootAccess = 'RootAccess',
  SelectedList = 'SelectedList',
  None = 'None',
}


type Props = Readonly<{
  withRootAccess: boolean;
  value: SelectPreSet;
  onChange: (value: SelectPreSet) => void;
} & WrappedComponentProps>;


const OPTIONS_TITLE: { [key in SelectPreSet]: string } = {
  [SelectPreSet.All]: LOCALIZATION.cameras_option_title_all,
  [SelectPreSet.RootAccess]: LOCALIZATION.cameras_option_title_root_access,
  [SelectPreSet.SelectedList]: LOCALIZATION.cameras_option_title_selected_list,
  [SelectPreSet.None]: LOCALIZATION.cameras_option_title_none,
}

const CamerasPreSet: React.FC<Props> = (props: Props) => {
  const options = compact(Object.keys(enumToObject(SelectPreSet)).map((key) => {
    if (!props.withRootAccess && (key as SelectPreSet) === SelectPreSet.RootAccess) { return null; }

    return ({
      id: key,
      name:props.intl.formatMessage({ id: OPTIONS_TITLE[key as SelectPreSet] }),
    });
  }));

  const handleChange = (value: string) => {
    props.onChange(value as SelectPreSet);
  };

  return (
    <FieldSetSelectComponent
      input={ {
        type: FieldSetInputType.Select,
        value: props.value,
        options,
        onChange: handleChange,
      } }
    />
  );
};


export default injectIntl(CamerasPreSet);
