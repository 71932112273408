// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid, Theme } from '@mui/material';
import AnyChildren from '../../../../Tools/AnyChildren';


type Props = Readonly<{
  children: AnyChildren;
  margin?: number
}>;


const MainCard: React.FC<Props> = (props: Props) => {
  const SX = (theme: Theme) => ({ margin: theme.spacing(props.margin !== undefined ? props.margin : 2), });

  return (
    <Grid
      sx={ SX }
    >
      { props.children }
    </Grid>
  )
};


export default MainCard;
