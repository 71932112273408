// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {  SxProps, Theme } from '@mui/material';

import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import MainButton from '../../../../Components/_BaseUI/MainButton'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content';
import LOCALIZATION from '../../../../Localization';
import VertGrid from '../../../../Components/_Layout/VertGrid';
import { getMenuLink } from '../../../../Menu/Data/MenuStructure';
import { MenuID } from '../../../../Menu/Data/MenuElement';
import { useNavigate } from 'react-router-dom';
import AnalyticsGameArchive, { AnalyticsGameShared } from '../../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import AnalyticsVertBarView from '../../../../Components/_ChartsAndBars/AnalyticsVertBarView';
import { createPhysicalReportVertBarChartConfig } from '../../../../Components/_ChartsAndBars/AnalyticsVertBarView/createPhysicalReportVertBarChartConfig';
import { useAnalyticsStatDistance } from '../../../../Data/Analytics/AnalyticsStatDistance/hook';
import { useAnalyticsStatSpeed } from '../../../../Data/Analytics/AnalyticsStatSpeed/hook';
import { AnalyticsStatAction } from '../../../../Data/Analytics/AnalyticsTypes';
import { GetTeamRefById } from '../../../../Data/Analytics/AnalyticsTeamRef/hook';

type Props = Readonly<{
  gameResultInfo: AnalyticsGameArchive | undefined
  sx?: SxProps<Theme>;
  getTeamRefByExternalId?: GetTeamRefById;
} & WrappedComponentProps>;

const GameTeamPysicalReportCard: React.FC<Props> = (props: Props) => {
  const gameId = props.gameResultInfo?.id || ""

  const { data: distanceStat } = useAnalyticsStatDistance({ gameId });
  const { data: speedStat } = useAnalyticsStatSpeed({ gameId });
  const { data: speedAttackStat } = useAnalyticsStatSpeed({ gameId, action: AnalyticsStatAction.Attack });
  const { data: speedDefenseStat } = useAnalyticsStatSpeed({ gameId, action: AnalyticsStatAction.Defense });

  const navigate = useNavigate();

  const handleConfigure = () => {
    const menuLink = getMenuLink(MenuID.AnalyticsGameReportPhysical, [gameId]);
    if (menuLink) {
      navigate(menuLink);
    }
  }

  if (!gameId) {
    return null;
  }

  const barChartData = createPhysicalReportVertBarChartConfig(props.gameResultInfo, distanceStat?.game_stat, speedStat?.game_stat, speedAttackStat?.game_stat, speedDefenseStat?.game_stat, props.getTeamRefByExternalId );

  return (
    <MainCard>
      <RegularCardHeader title={ props.intl.formatMessage({ id: LOCALIZATION.team_physical_report }) }/>
      <MainCardContent>
        <VertGrid>
          <Grid
            sx={ {
              height: 360,
            } }
          >
            <AnalyticsVertBarView 
              data={ barChartData } 
              team1Color={ AnalyticsGameShared.team1Color }
              team2Color={ AnalyticsGameShared.team2Color }
            />
          </Grid>

          <Grid container spacing={ 1 } 
              direction="row"
              justifyContent="center" 
              sx={ {
                marginTop: '15px',
              } }
            >
              <Grid item>
                <MainButton 
                  onClicked={ handleConfigure } 
                  title={ props.intl.formatMessage({ id: LOCALIZATION.details }) } 
                  sx={{ paddingLeft: 3, paddingRight: 3 }} 
                />
              </Grid>
            </Grid>
        </VertGrid>
      </MainCardContent>
    </MainCard>
  );
};

export default injectIntl(GameTeamPysicalReportCard);
