// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from '@mui/material';

import IconConfigure from '../Components/Configurator/Resources/icon_chart_configuration.svg';
import { MetricsChartSourceInterface } from '../../../Data/Camera/Metrics/Types';
import MetricsChartConfigurator from '../Components/Configurator/MetricsChartConfigurator';
import MetricsChartView from '../MetricsChartView';
import { sourceWithMetricsChartConfiguration } from '../Components/Configurator/Hooks';
import { MetricsChartConfigurationHelper } from '../Components/Configurator/Storage';
import { ConfigurableMetricsChartViewRootStyled, ConfigurableMetricsChartViewRPlaceholderStyled } from './Styled';
import LOCALIZATION from '../../../Localization';


type Props = Readonly<{
  defaultSource: MetricsChartSourceInterface;
  onSourceChanged?: (source: MetricsChartSourceInterface) => void;
}>;


const SX = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    position: 'absolute',
    right: -10,
    top: -10,
    padding: 0,
    minWidth: 50,
    minHeight: 50,
  },
};


const ConfigurableMetricsChartView: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const initialDefaultSource = props.defaultSource

  // Нужно модифицировать defaultSource согласно сохраненным настройкам
  const defaultSource = sourceWithMetricsChartConfiguration(props.defaultSource, MetricsChartConfigurationHelper.getInitialConfigurableForm(props.defaultSource))

  const [metricsSource, setMetricsSource] = React.useState<MetricsChartSourceInterface>(defaultSource)

  const proxySourceChange = (source: MetricsChartSourceInterface) => {
    const sourceChanged = props.onSourceChanged
    if (sourceChanged) {
      sourceChanged(source)
    }
  }

  const handleMetricSourceChange = (source: MetricsChartSourceInterface) => {
    setMetricsSource(source);
    proxySourceChange(source);
  }

  const chartingContent = () => {
    if (metricsSource.metrics.length > 0) {
      return <MetricsChartView source={ metricsSource } />
    } else {
      return (
        <ConfigurableMetricsChartViewRPlaceholderStyled>
          { intl.formatMessage({ id: LOCALIZATION.empty_metrics_warning }) }
        </ConfigurableMetricsChartViewRPlaceholderStyled>
      );
    }
  }

  return (
    <ConfigurableMetricsChartViewRootStyled>
      { chartingContent() }
      <Button sx={ SX.button } onClick={ handleOpen }>
        <img src={ IconConfigure } style={ { width: 30, height: 30 } } alt="IconConfigure" />
      </Button>
      <Modal
        sx={ SX.modal }
        open={ open }
        onClose={ handleClose }
      >
        <MetricsChartConfigurator
          source={ initialDefaultSource }
          onChanged={ handleMetricSourceChange }
          onClose={ handleClose }
        />
      </Modal>
    </ConfigurableMetricsChartViewRootStyled>
  )
};


export default ConfigurableMetricsChartView;
