// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';


type Props = Readonly<{
  className?: string;
  children: string;
}>;


const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
  },
  text: {
    display: 'block',
    minWidth: '0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const TextEllipsis = (props: Props) => {
  const classes = useStyles();

  return (
    <div
      className={ clsx(classes.root, props.className) }
    >
      <span
        className={ classes.text }
      >
          { props.children }
      </span>
    </div>
  );
};


export default TextEllipsis;
