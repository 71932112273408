// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import SortOrderSelector, { SortOrderSelector_StoredValue } from '../../Components/_BaseUI/SortOrderSelector';
import VideoArchiveItem from './VideoArchiveItem';
import Order from '../../Data/_Networking/ReactQuery/Order';
import ButtonsInRowElement from '../../Components/_BaseUI/ButtonsInRowElement';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import Archive, { FileType } from '../../Data/VideoArchive';
import usePagedData from '../../Data/VideoArchive/hook/usePagedData';
import useMutationArchive, { ActionType, ArchiveForActionProps } from '../../Data/VideoArchive/fetch/useMutation';
import FieldSetDateComponent from '../../Components/_BaseUI/FieldSet/FieldSetDateComponent';
import { FieldSetInputType } from '../../Components/_BaseUI/FieldSet';
import AddEventPopup from '../EventsPage/AddEvent';
import Pagination from '../../Components/PaginationControl';
import LOCALIZATION from '../../Localization';
import { EMPTY_ARRAY } from '../../constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Grid } from '@mui/material';
import EventWithoutID from '../../Data/EPG/EpgSchedule/EventWithoutID';
import Event, { eventFromEPGEvent } from '../../Data/EPG/EpgSchedule/Event';
import EPGEvent, { epgEventFromEvent } from '../../Data/EPG/EPGEvents/EPGEvent';

export type VideoArchiveContentProps = Readonly<{
  type: FileType;
  date: string;
  onChangeDate: (date: string | null) => void;
} & WrappedComponentProps>;

export type SortBy = Readonly<{
  date: string;
}>;


const LIMIT = {
  [FileType.Operator]: 9,
  [FileType.Panorama]: 9,
  [FileType.Scoreboard]: 9,
};

export const sessionStoreKey = "CameraVideoArchive"

const VideoArchiveContent: React.FC<VideoArchiveContentProps> = (props: VideoArchiveContentProps) => {
  const { date } = props;
  const [selectedIDs, setSelectedIDs] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(LIMIT[props.type]);
  const [total, setTotal] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>(SortOrderSelector_StoredValue(sessionStoreKey) || Order.DESC);
  const { mutateAsync: mutateConfig } = useMutationArchive();

  const [editItem, setEditItem] = React.useState<Event | EventWithoutID | undefined>(undefined);
  const [editEpgItem, setEditEpgItem] = React.useState<EPGEvent | undefined>(undefined);

  const {
    status,
    data: archive,
    handleFlushData,
  } = usePagedData({
    page,
    limit,
    order,
    date,
    type: props.type,
    orderBy: 'date',
  });

  const handleChangeDate = (date: string|null) => {
    props.onChangeDate(date)
    setPage(0);
  };

  const handleSyncVideo = async (IDs: string[]) => {
    try {
      const archives: ArchiveForActionProps[] = (archive?.data || [])
        .filter((item: Archive) => (IDs.includes(item.meta.event.startAt)))
        .map((item: Archive) => ({
          type: item.type,
          startAt: item.meta.event.startAt,
        }));

      await mutateConfig(
        {
          archives,
          type: ActionType.SendToSyncServer,
        },
        {
          onSuccess: () => {
            setSelectedIDs([]);
            handleFlushData();
            window.alert(props.intl.formatMessage({ id: LOCALIZATION.success_alert_sync_selected_video }))
          },
        }
      );
    } catch (error) {
      alert(error);
    }
  };

  const handleDelete = async (IDs: string[]) => {
    try {
      const archives: ArchiveForActionProps[] = (archive?.data || [])
        .filter((item: Archive) => (IDs.includes(item.meta.event.startAt)))
        .map((item: Archive) => ({
          type: item.type,
          startAt: item.meta.event.startAt,
        }));

      await mutateConfig(
        {
          archives,
          type: ActionType.Delete,
        },
        {
          onSuccess: () => {
            setSelectedIDs([]);
            handleFlushData();
          },
        }
      );
    } catch (error) {
      alert(error);
    }
  };
  const handleSelectAll = () => {
    if (selectedIDs.length) {
      setSelectedIDs([]);
    }
    else {
      setSelectedIDs((archive?.data || []).map((item: Archive) => (item.meta.event.startAt)));
    }
  };
  const handleRemoveClicked = () => {
    if (selectedIDs.length !== 0 && window.confirm(props.intl.formatMessage({ id: LOCALIZATION.confirm_remove }))) {
      handleDelete(selectedIDs);
    }
  };
  // const handleSyncVideoClicked = () => {
  //   if (selectedIDs.length !== 0 && window.confirm(props.intl.formatMessage({ id: LOCALIZATION.confirm_sync_selected_video }))) {
  //     handleSyncVideo(selectedIDs);
  //   }
  // };
  const handleItemClicked = (clickedItemStartAt: string) => {
    if (selectedIDs.findIndex((startAt: string) => (startAt === clickedItemStartAt)) === -1) {
      setSelectedIDs([...selectedIDs, clickedItemStartAt]);
    } else {
      setSelectedIDs(selectedIDs.filter((startAt: string) => (startAt !== clickedItemStartAt)));
    }
  };
  const handleItemEditClicked = (clickedItemStartAt: string, item?: Archive) => {
    const event = item?.meta?.event;
    setEditEpgItem(event)
    const epgEvent = eventFromEPGEvent(event);
    setEditItem(epgEvent);
  };
  const handleItemSendToServerClicked = (clickedItemStartAt: string) => {
    if (clickedItemStartAt && clickedItemStartAt.length !== 0 && window.confirm(props.intl.formatMessage({ id: LOCALIZATION.confirm_sync_selected_video }))) {
      handleSyncVideo([clickedItemStartAt]);
    }
  };
  const handleItemDeleteClicked = (clickedItemStartAt: string) => {
    if (clickedItemStartAt && clickedItemStartAt.length !== 0) {
      handleDelete([clickedItemStartAt]);
    }
  };

  const handleOrderByChanged = (orderBy: keyof SortBy, order: Order) => {
    setOrder(order);
  };
  const renderPagination = () => {
    if (total) {
      const totalPages = Math.ceil(total / limit);

      if (totalPages > 0) {
        return (
          <Pagination
            totalPages={ totalPages }
            currentPage={ page }
            onClick={ setPage }
          />
        );
      }
    }

    return <></>;
  };

  const buttons1 = [
    <SortOrderSelector<SortBy>
      sessionStoreKey={ sessionStoreKey } 
      key="sort"
      orderBy={ [ 'date' ] }
      orderByTitle={ [ props.intl.formatMessage({ id: LOCALIZATION.sort_by_time }) ] }
      selectedOrderBy={ 'date' }
      selectedOrder={ order }
      onChange={ handleOrderByChanged }
    />,
    {
      space : 10 ,
    }, 
    <FieldSetDateComponent
      key="FieldSetDateComponent"
      labelText={ props.intl.formatMessage({ id: LOCALIZATION.filter_by_date }) }
      input={ {
        sessionStoreKey: sessionStoreKey,
        type: FieldSetInputType.Date,
        noWrapper: true,
        allowRemoveDate: true,
        noValueText: props.intl.formatMessage({ id: LOCALIZATION.filter_by_date_off }),
        dateFormat: 'YYYY-MM-DD',
        value: {
          date: (date || null),
        },
        onChangeDate: handleChangeDate,
      } }
    />,
    {
      space :-1 ,
    }, 
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.refresh }),
      iconButton: <RefreshIcon /> ,
      onClick: handleFlushData,
    },
  ];

  const buttons2 = [
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.page_video_archive_tab_select_all_option }),
      onClick: handleSelectAll,
    },
    {
      space : 10 ,
    }, 
    {
      text: `${ props.intl.formatMessage({ id: LOCALIZATION.remove }) } ${ (selectedIDs.length) ? '(' + selectedIDs.length + ')' : '' }`,
      bordered: true,
      disabled: (selectedIDs.length === 0),
      hidden: (selectedIDs.length === 0),
      onClick: handleRemoveClicked,
    },
    // {
    //   space : 10 ,
    // }, 
    // {
    //   text: `${ props.intl.formatMessage({ id: LOCALIZATION.send_to_sync_server }) } ${ (selectedIDs.length) ? '(' + selectedIDs.length + ')' : '' }`,
    //   bordered: true,
    //   disabled: (selectedIDs.length === 0),
    //   hidden: (selectedIDs.length === 0),
    //   onClick: handleSyncVideoClicked,
    // },
    {
      space :-1 ,
    }, 
    renderPagination()
  ];


  React.useEffect(() => {
    setPage(0);
    setLimit(LIMIT[props.type]);
    // setOrder(Order.ASC);
  }, [props.type]);
  React.useEffect(() => {
    setSelectedIDs([]);
  }, [page]);
  React.useEffect(() => {
    return handleFlushData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (archive && archive?.meta?.pagination && !isNaN(archive?.meta?.pagination?.total)) {
      setTotal(archive.meta.pagination.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archive?.meta?.pagination?.total]);


  const handleArchiveEdit = async (event: Event | EventWithoutID) => {
    try {
      const epgEvent = epgEventFromEvent(event);
      if (!epgEvent || !editEpgItem) {
        handleCloseEditPopup();
        alert(props.intl.formatMessage({ id: LOCALIZATION.connection_error }));
        return;
      }
  
      await mutateConfig(
        {
          archives:[{
            type: props.type,
            startAt: editEpgItem.startAt,// use start time from original non edited event
            event: epgEvent,
          }],
          type: ActionType.Patch,
        }, {
          onSuccess: () => {
            handleFlushData();
            handleCloseEditPopup();
          },
        },
      );
    } catch (error) {
      alert(error);
      handleFlushData();
      handleCloseEditPopup();
    }
  };

  const handleCloseEditPopup = () => {
    setEditItem(undefined);
    setEditEpgItem(undefined)
  };

  const renderItem = (item: Archive) => (
    <VideoArchiveItem
      fileType={ props.type }
      id={ item.meta.event.startAt }
      checked={ !!selectedIDs.find((id: string) => (id === item.meta.event.startAt)) }
      hideDate={ (!!props.date) }
      item={ item }
      onSelect={ handleItemClicked }
      onEdit={ handleItemEditClicked }
      onSendToServer={ handleItemSendToServerClicked }
      onDelete={ handleItemDeleteClicked }
      intl={ props.intl }
    />
  );

  const renderPanel = () => (
    <div>
      <Box sx= {{
        marginBottom : '15px'
      }}
      >
        <ButtonsInRowElement
          id = "buttonsRow1"
          buttons={ buttons1 }
        />
      </Box>
      <ButtonsInRowElement
        id = "buttonsRow2"
        buttons={ buttons2 }
      />
    </div>
  );

  return (
    <>
      { renderPanel() }
      {
        (status === 'loading' || archive === undefined) ?
          <>
          <br /><br />         
          <Spinner
            isHidden={ false }
          /><br /><br />
          </>
          :
          (archive?.data?.length) ?
          <Box sx={{ padding: '30px 0' }}>
            <Grid
              container
              spacing={ '42px' }
              rowSpacing={ '48px' }
            >
              { (archive?.data || EMPTY_ARRAY).map(renderItem) }
            </Grid>
          </Box>
          :
          <>
          <br /><br />         
          <Grid
           sx={ {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          } }
          >
          { props.intl.formatMessage({ id: LOCALIZATION.page_video_archive_empty_list }) }
          </Grid>
          <br /><br />
          </>
      }
      {/* { (status !== 'loading' && archive !== undefined && (archive?.data || EMPTY_ARRAY).length !== 0) && renderPanel() } */}
      {
        (editItem) &&
          <AddEventPopup
            archiveEventEditMode={ true }
            item={ editItem }
            // page={ page }
            onSubmit={ handleArchiveEdit }
            onClose={ handleCloseEditPopup }
          />
      }
    </>
  );
};


export default injectIntl(VideoArchiveContent);
