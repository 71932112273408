// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import InputAnyForm from '../../../_Layout/InputAnyForm/View'
import MetricsChartOutputPeriodSelector from './MetricsChartOutputPeriodSelector'
import { MetricOutputPeriodInterface } from '../../../../Data/Camera/Metrics/Types'

export default function InputOutputPeriodForm(props: {
    period: MetricOutputPeriodInterface,
    name: string,
    onChanged: (mode: MetricOutputPeriodInterface) => void}) {
    return (
        <InputAnyForm name={props.name} form={(
            <MetricsChartOutputPeriodSelector
                period = { props.period }
                onChanged = { props.onChanged }/>
        )}/>
    )
}
