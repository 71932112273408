// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import User from '../User';
import Order from '../../_Networking/ReactQuery/Order';
import { fetchJavaManyJSONData } from '../../_Networking/ReactQuery/fetch';
import { PAGE_LIMIT } from '../../../constants';
import { JAVA_BACKEND_URL } from '../../../constants';
import { queryCache, queryClient } from '../../../AppProviders';
import ResponseMany from '../../_Networking/ReactQuery/ResponseMany';
import { Result, usePagedItems } from '../../_Networking/ReactQuery/template';
import Camera from '../../Camera/ActiveCamera/Camera';
import { Action, ActionType } from './useMutation';
import ResponseSingle from '../../_Networking/ReactQuery/ResponseSingle';
import UserWithoutID, { UserCameraAccess, UserRole, UserTeamRefAccess } from '../UserWithoutID';
import fetchJSONData from '../../_Networking/ReactQuery/fetch';
import { intl } from '../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../Localization';
import { EMPTY_ARRAY } from '../../../constants';

type FetchProps = Readonly<{
  page: number;
  limit: number;
  order?: Order;
  orderBy?: keyof User;
  filterByCameraID?: string;
  filterByTeamRefID?: string;
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'users';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.page,
  props.limit,
  props.orderBy,
  props.order,
  props.filterByCameraID,
  props.filterByTeamRefID,
]);

const getAllCached = () => (
  queryClient.getQueryData<User[]>(getKeyWithoutPage())
);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<ResponseMany<User[]>>(getKey(props))
);

const flushCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};



const getUserCameras = async (userID: string): Promise<Camera[] | undefined> => {
  try {
    const data = await fetchJSONData<Camera[]>(
      `${JAVA_BACKEND_URL}/user/${userID}/cameras`,
      {
        method: 'GET',
      }
    );

    // if (data.error) {
    //   throw new Error(data.error);
    // } else {
      return data;
    // }
  } catch (error) {
    return undefined
  }
};


/*
• admin account can create new admin and user accounts.
• super_admin can create new super_admin, admin and user accounts.
Server accounts create server accounts. Local accounts create local accounts.
For server account login MUST be valid email. Password for server account is generated by server.
If successfully create server accounts then email will be send with generated password.
 */
const addUser = async (user?: UserWithoutID): Promise<Action | Error> => {
  try {
    if (user && user.login && user.role && user.name) {
      const body: Partial<User> = {
        login: user.login,
        role: user.role,
        name: user.name,
      };

      if (user.password && user.password.length !== 0) {
        body.password = user.password;
      }

      const data = await fetchJSONData<User>(
        `${JAVA_BACKEND_URL}/user/add`,
        {
          method: 'POST',
          body: JSON.stringify(body),
        }
      );

      console.log('data:', data);

      if (user.cameraAccess && user.cameraIdList) {
        await setCameraAccess(data.id, user.cameraAccess, user.cameraIdList);
      }

      return ({
        type: ActionType.Add,
        user: data,
      });
    } else {
      throw new Error('Invalid User Fields');
    }
  } catch (error) {
    throw error;
  }
};

/*
Modify user fields:
• Account with greater access level can modify other account.
• Account can modify itself.
• Account can modify account if it has access to all cameras or if they share access to the same camera.
If accounts camera list did not intersect then accounts have no access to each other.
 */
const patchUser = async (originalUser: User, changes?: Partial<User>): Promise<Action | Error> => {
  try {
    if (changes && originalUser.id) {
      if (changes.name) {
        await changeUserName(originalUser.id, changes.name);
      }

      if (
        // originalUser.password && originalUser.password.length !== 0 && User with big access level can chnage lower users password without oldPassword
        changes.password && changes.password.length !== 0
      ) {
        await changeUserPassword(originalUser.id, originalUser.password, changes.password);
      }
      if (changes.role) {
        await changeUserRole(originalUser.id, changes.role);
      }
      console.log('changes:', changes, 'originalUser:', originalUser);
      if (changes.cameraAccess || changes.cameraIdList) {
        const cameraAccess = changes.cameraAccess || originalUser.cameraAccess;
        const cameraIdList = (cameraAccess === UserCameraAccess.List) ?
            changes.cameraIdList || originalUser.cameraIdList || EMPTY_ARRAY
          :
          undefined;
        await setCameraAccess(originalUser.id, cameraAccess, cameraIdList);
      }

      if (changes.teamRefAccess || changes.teamRefIdList) {
        const teamRefAccess = changes.teamRefAccess || originalUser.teamRefAccess;
        const teamRefIdList = (teamRefAccess === UserTeamRefAccess.List) ?
            changes.teamRefIdList || originalUser.teamRefIdList || EMPTY_ARRAY
          :
          undefined;
        await setTeamRefAccess(originalUser.id, teamRefAccess, teamRefIdList);
      }

      return ({
        type: ActionType.Patch,
      });
    } else {
      throw new Error('Invalid User Fields');
    }
  } catch (error) {
    throw error;
  }
};

const changeUserField = async <T>(url: string, body?: object, method: string = 'POST'): Promise<T | Error> => {
  try {
    const data = await fetchJSONData<T>(
      url,
      {
        method,
        body: JSON.stringify(body),
      }
    );

    return data as T;
  } catch (error) {
    throw error;
  }
};

const changeUserName = async (id: string, name: string): Promise<boolean | Error> => {
  try {
    return await changeUserField(`${JAVA_BACKEND_URL}/user/${id}`, { name }, 'PATCH');
  } catch (error) {
    throw error;
  }
};

type PasswordResponse = Readonly<{
  success: true;
}>;

const changeUserPassword = async (id: string, password?: string, newPassword?: string): Promise<PasswordResponse | Error> => {
  try {
    return await changeUserField<PasswordResponse>(`${JAVA_BACKEND_URL}/user/${id}/password`, { password, newPassword });
  } catch (error: any) {
    if (error.message === 'InvalidInputParams') {
      throw new Error(intl().formatMessage({ id: LOCALIZATION.invalid_current_password }))
    }

    throw error;
  }
};

export type SuccessResponse = Readonly<{
  success: boolean;
  needManualTokenRefresh: boolean;
  newAccessToken: string;
}>;

const isSuccessResponse = (response: SuccessResponse | Error): response is SuccessResponse => (response as SuccessResponse).success !== undefined;

const changeUserRole = async (id: string, role: UserRole): Promise<SuccessResponse | Error> => {
  try {
    return await changeUserField<SuccessResponse>(`${JAVA_BACKEND_URL}/user/${id}/role`, { role });
  } catch (error) {
    throw error;
  }
};

const setCameraAccess = async (id: string, cameraAccess: UserCameraAccess, cameraIdList?: string[]): Promise<SuccessResponse | Error> => {
  try {
    const body: any = { cameraAccess }

    if (cameraIdList && cameraIdList.length !== 0) {
      body.cameraIdList = cameraIdList;
    }

    return await changeUserField<SuccessResponse>(`${JAVA_BACKEND_URL}/user/${id}/cameras/access`, body);
  } catch (error) {
    throw error;
  }
};

const setTeamRefAccess = async (id: string, teamRefAccess: UserTeamRefAccess, teamRefIdList?: string[]): Promise<SuccessResponse | Error> => {
  try {
    const body: any = { teamRefAccess }

    if (teamRefIdList && teamRefIdList.length !== 0) {
      body.teamRefIdList = teamRefIdList;
    }

    return await changeUserField<SuccessResponse>(`${JAVA_BACKEND_URL}/user/${id}/teamref/access`, body);
  } catch (error) {
    throw error;
  }
};

const resetPassword = async (userID: string): Promise<SuccessResponse | Error> => {
  try {
    return await changeUserField<SuccessResponse>(`${JAVA_BACKEND_URL}/user/${userID}/password/reset`) as SuccessResponse;
  } catch (error) {
    throw error;
  }
};

/*
Remove user:
• Account with greater access level can remove other account.
• Account can remove itself.
• Account can remove account if it has access to all cameras or if they share access to the same camera.
If accounts camera list did not intersect then accounts have no access to each other.
 */
const deleteUser = async (user: User): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<{ success: boolean }>>(
      `${JAVA_BACKEND_URL}/user/${user.id}`,
      {
        method: 'DELETE',
      }
    );

    if (!data?.error) {
      return ({
        user,
        type: ActionType.Delete,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Delete User');
    }
  } catch (error) {
    throw error;
  }
};


const fetchData = async (props: FetchProps): Promise<ResponseMany<User[]>> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  const offset = (props.page * props.limit);
  let params = `?max=${props.limit}&first=${offset}`;
  let sort: string | undefined = undefined;

  switch (props.orderBy) {
    case 'name': {
      sort = 'name';
      break;
    }
    case 'role': {
      sort = 'role.accessLevel';
      break;
    }
    case 'lastLogin': {
      sort = 'lastLoginTime';
      break;
    }
  }

  if (sort) {
    params += `&direction=${props.order}&sort=${sort}`;
  }
  if (props.filterByCameraID) {
    params += `&filter_cameraAccess=all&filter_cameraList.cameraId=${props.filterByCameraID}`;
  }
  if (props.filterByTeamRefID) {
    params += `&filter_teamRefAccess=all&filter_teamRefList.id=${props.filterByTeamRefID}`;
  }
  if (props.filterByCameraID || props.filterByTeamRefID) {
    params += `&filter_by_or=true`;
  }
  return await fetchJavaManyJSONData<User>(
    `/users${params}`,
    'users',
    {
      limit: props.limit,
      offset,
    },
    {
      method: 'GET',
    },
  );

  // return await fetchJavaManyJSONData<User>(
  //   `${JAVA_BACKEND_URL}/users${params}`,
  //   {
  //     limit: props.limit,
  //     offset,
  //   },
  //   {
  //     method: 'GET',
  //   },
  // );
};


type UseFetchProps = Readonly<{
  enableToFetch: boolean;
  page: number;
  limit?: number;
  order?: Order;
  orderBy?: keyof User;
  filterByCameraID?: string;
  filterByTeamRefID?: string;
}>;


// type Result<Item> = Readonly<{
//   data: Array<Item> | undefined;
//   error: any | undefined;
//   status: 'success' | 'error' | 'loading' | 'idle';
//   isSuccess: boolean;
//   isLoading: boolean;
//   isError: boolean;
// }>;


const useFetch = (props: UseFetchProps): Result<User> => {
  const { enableToFetch = true, page: pg = 0, limit = PAGE_LIMIT } = props;
  const fetchTemplate = async (queryKey: QueryKey, page: number = 0) => fetchData({
    page,
    limit,
    order: props.order,
    orderBy: props.orderBy,
    filterByCameraID: props.filterByCameraID,
    filterByTeamRefID: props.filterByTeamRefID,
  });

  return usePagedItems<User>({
    page: pg,
    fetch: fetchTemplate,
    keepPreviousData: false,
    queryKey: getKey({
      page: pg,
      limit,
      order: props.order,
      orderBy: props.orderBy,
      filterByCameraID: props.filterByCameraID,
      filterByTeamRefID: props.filterByTeamRefID,
    }),
    config: {
      enabled: !!enableToFetch,
    },
  });
};


export {
  getKey,
  addUser,
  patchUser,
  deleteUser,
  resetPassword,
  getUserCameras,
  isSuccessResponse,
  useFetch,
  getCached,
  flushCache,
  getAllCached,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
