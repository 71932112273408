// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';


type Props = Readonly<{
  src: string;
}>;


const TeamLogo: React.FC<Props> = (props: Props) => (
  <div
    style={
      {
        display: 'inline-block',
        objectFit: 'contain',
        maxWidth: '100%',
        maxHeight: '100%',
        height: '63px',
        width: '63px',
        padding: '4px',
        marginTop: '9px',
        marginBottom: '9px',
        borderRadius: '6px',
        border: 'solid 1px #C2CFE0',
      }
    }
  >
    <img
      alt="Logo"
      src={ props.src }
      style={
        {
          display: 'inline-block',
          objectFit: 'contain',
          maxWidth: '100%',
          maxHeight: '100%',
          height: '63px',
          width: '63px',
        }
      }
    />
  </div>
);


export default TeamLogo;
