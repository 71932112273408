// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import SortedTableBody from './SortedTableBody';
import { RenderCellContent, RowClassName } from './SortedTableBodyRow';
import Order from '../../Data/_Networking/ReactQuery/Order';
import SortedTableHead, { HeadCell } from './SortedTableHead';
import ResponseMany from '../../Data/_Networking/ReactQuery/ResponseMany';
import { EMPTY_ARRAY } from '../../constants';
import { SxProps, Table, TableContainer, TablePagination, Theme } from '@mui/material';
import { useIntl } from 'react-intl';
import LOCALIZATION from '../../Localization';
import { AddDropdownProps } from '../_BaseUI/AddDropdownButton';
import { MenuDropdownAction } from '../_BaseUI/MenuDropdownButton';


export type TypeID = Readonly<number |string>;
type Props<T> = Readonly<{
  noTopPaddingWhenOnlyOnePage?: boolean;
  sx?: SxProps<Theme>;
  stickyHeader?: boolean;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  orderBy: keyof T;
  order: Order;
  selected?: T[];
  headCells: HeadCell<T>[];
  manyItems: ResponseMany<T[]>;
  renderCellContent?: RenderCellContent<T>;
  rowClassName?: (row: T, allItems: T[]) => RowClassName | null;
  onPageChange: (page: number) => void;
  onPeerPageChange: (limit: number) => void;
  onRowClick?: (item: T) => void;
  onSelect?: (selected: T[]) => void;
  onRequestSort?: (property: keyof T) => void;
  showRemoveIconButton?: (item: T) => boolean;
  onRemoveIconButtonClicked?: (selected: T) => void;
  onMenuEdit?: (selected: T) => void;
  onMenuCopy?: (selected: T) => void;
  onMenuDelete?: (selected: T) => void;
  customActions?: MenuDropdownAction<T>[];
  showAddIconButton?: (item: T) => boolean;
} & AddDropdownProps<T>>;


const SortedTable = <T extends { id: TypeID }, >(props: Props<T>): JSX.Element => {
  const intl = useIntl();
  const numSelected = (props?.selected) ? props.selected.length : undefined;
  const rowCount = props.manyItems.meta.pagination?.total || 0;
  // const { rowsPerPageOptions = [] } = props;
  const { rowsPerPageOptions = [5, 10, 25, 50, 100] } = props; 

  const handleChangePage = (event: unknown, newPage: number) => {
    props.onPageChange(newPage);
  };
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    if (props?.onRequestSort) {
      props.onRequestSort(property);
    }
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onPeerPageChange(parseInt(event.target.value, 10));
    props.onPageChange(0);
  };
  const handleSelectItem = (selectedValue: T[]): void => {
    if (props.onSelect) {
      props.onSelect(selectedValue);
    }
  };
  const handleSelectAll = () => {
    if (props.selected && props.onSelect) {
      if (props.selected.length === props.manyItems.data.length) {
        props.onSelect([]);
      } else {
        props.onSelect((props.manyItems?.data || EMPTY_ARRAY));
      }
    }
  };

  // sx?: SxProps<Theme>;
  const paginationBtnRoundStyle = {
    '&:before': {
      content : '""',
      position: 'absolute',
      left    : '20%',
      top     : '20%',
      height  : '50%',
      width   : '50%',
      borderRadius: '50%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'info',
    },
  }

  const renderTablePagination = (
    (rowCount > props.rowsPerPage) ?
    <TablePagination
      rowsPerPageOptions={ rowsPerPageOptions }
      component="div"
      count={ rowCount }
      rowsPerPage={ props.rowsPerPage }
      page={ props.page }
      labelRowsPerPage={ `${intl.formatMessage({ id: LOCALIZATION.table_rows_per_page })}:` }
      labelDisplayedRows={ ({ from, to, count }) => (
        `${from}-${to} ${intl.formatMessage({ id: LOCALIZATION.table_of })} ${count}`
      ) }
      onPageChange={ handleChangePage }
      onRowsPerPageChange={ handleChangeRowsPerPage }
      nextIconButtonProps={{
        color: 'info',
        sx: paginationBtnRoundStyle 
      }}
      backIconButtonProps={{
        color: 'info',
        sx: paginationBtnRoundStyle
      }}
    />
    :
    (props.noTopPaddingWhenOnlyOnePage) ? null : <br/>
  );

  return (
    <>
      { renderTablePagination }
      <TableContainer
        sx={ props.sx }
      >
        <Table
        stickyHeader={ props.stickyHeader }
        sx = {{
          "tbody > tr:last-child > td": {
            borderBottom: 0,
          },
          "tbody > tr:last-child > th": {
            borderBottom: 0,
          },
        }}
        >
          <SortedTableHead<T>
            addAddButton={ !!props.getAddMenuItems }
            addRemoveButton={ !!props.onRemoveIconButtonClicked }
            addMenuButton= { !!props.onMenuCopy || !!props.onMenuDelete || !!props.onMenuEdit || !!props.customActions?.find((action) => (!!action.onMenuClick)) }
            order={ props.order }
            orderBy={ props.orderBy }
            headCells={ props.headCells }
            numSelected={ numSelected }
            rowCount={ rowCount }
            onSelectAll={ props.onSelect ? handleSelectAll : undefined }
            onRequestSort={ props?.onRequestSort ? handleRequestSort : undefined }
          />
          <SortedTableBody<T>
            showAddIconButton={ props.showAddIconButton }
            getAddMenuItems={ props.getAddMenuItems }
            onAddMenuItemSelect={ props.onAddMenuItemSelect }
            showRemoveIconButton={ props.showRemoveIconButton }
            onRemoveIconButtonClicked={ props.onRemoveIconButtonClicked }
            onMenuCopy={ props.onMenuCopy }
            onMenuDelete={ props.onMenuDelete }
            onMenuEdit={ props.onMenuEdit }
            customActions={ props.customActions }
            headCells={ props.headCells }
            manyItems={ props.manyItems }
            order={ props.order }
            orderBy={ props.orderBy }
            page={ props.page }
            rowsPerPage={ props.rowsPerPage }
            selected={ props.selected }
            renderCellContent={ props.renderCellContent }
            rowClassName={ props.rowClassName }
            onRowClick={ props.onRowClick }
            onSelect={ props?.onSelect ? handleSelectItem : undefined }
          />
        </Table>
      </TableContainer>
      {/* { renderTablePagination } */}
    </>
  );
};


export default SortedTable;
