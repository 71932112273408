// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import {
  UseFetchProps,
  useFetchStatDistancePerMinute,
  flushCache,
  getCached,
} from '../fetch';
import { AnalyticsStatDistancePerMinute } from '../AnalyticsStatDistancePerMinute';
import { ResultStatus } from '../../../_Networking/ReactQuery/template';


type Result = Readonly<{
  status: ResultStatus;
  data: AnalyticsStatDistancePerMinute | undefined;
  handleFlushData: () => void;
}>;


export const useAnalyticsStatDistancePerMinute = (props: UseFetchProps): Result => {
  const fetchedArchive = useFetchStatDistancePerMinute(props);
  const data = getCached(props);

  return ({
    status: fetchedArchive.status as ResultStatus,
    data: data?.data,
    handleFlushData: flushCache,
  });
};

