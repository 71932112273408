// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';

import { Colors } from '../../../Configuration/Styles/Colors';
import AnyChildren from '../../../Tools/AnyChildren';


type Props = Readonly<{
  children: AnyChildren;
}>;


const SX = {
  display: 'flex',
  minHeight: '100vh',
  background: Colors.secondaryBackground,
};

const Container: React.FC<Props> = (props: Props) => (
  <Grid
    sx={ SX }
  >
    { props.children }
  </Grid>
);


export default Container;
