// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Popup from '../../Components/_Layout/Popup';
import LOCALIZATION from '../../Localization';
import AnalyticsGameArchive from '../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import HorizGrid from '../../Components/_Layout/HorizGrid';
import CameraStreamPreview, { VideoPlayerCommands } from '../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import { makeAnalyticsVideoImageUrl, makeAnalyticsVideoPlayerUrl } from '../../Data/Analytics/AnalyticsConfig/AnalyticsConfig';
import { FileType } from '../../Data/VideoArchive';
import { useAnalyticsConfig } from '../../Data/Analytics/AnalyticsConfig/hook';
import useFetchAllPagesTeamRefs from '../../Data/Analytics/AnalyticsTeamRef/hook';
import VertGrid from '../../Components/_Layout/VertGrid';
import AnalyticsEventsTabsCard from './AnalyticsEventsTabsCard';
import AnalyticsMintesTabsCard, { AnalyticsMintesTabsCommands } from './AnalyticsMintesTabsCard';
import { MAIN_CARD_SX } from '../../Components/_Layout/Cards/Main/styles';
import { AnalyticsGameEvent } from '../../Data/Analytics/AnalyticsGameEvent/AnalyticsGameEvent';
import useGameEventsPagedData from '../../Data/Analytics/AnalyticsGameEvent/hook';
import { ResultStatus } from '../../Data/_Networking/ReactQuery/template';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';

type Props = Readonly<{
  game?: AnalyticsGameArchive;
  // page: number;
  onClose: () => void;
} & WrappedComponentProps>;

const AnalyticsGameReportVideoDetailsPopup: React.FC<Props> = (props: Props) => {    
  const theme = useTheme();
  const useHorisLayout = useMediaQuery(theme.breakpoints.up('lg'));

  const { data: analyticsConfig } = useAnalyticsConfig({});
  const { allCached: teams, } = useFetchAllPagesTeamRefs();

  const [videoPlayerCommands, setVideoPlayerCommands] = React.useState<VideoPlayerCommands | undefined>(undefined);
  const [minutesTabsCommands, setMinutesTabsCommands] = React.useState<AnalyticsMintesTabsCommands | undefined>(undefined);

  const [events, setEvents] = React.useState<AnalyticsGameEvent[] | undefined>(undefined);
  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);

  // streaming/settings.json load
  const gameEventsFetchState = useGameEventsPagedData({
    parentGameId: props.game?.id
  });
  React.useEffect(() => {
    if (gameEventsFetchState.status === ResultStatus.Success) {
      setIsLoaderShowing(false);
      const loadedEvents = gameEventsFetchState.data?.data;
      if (!loadedEvents?.length) {
        setEvents(undefined);
      }
      else {
        setEvents(loadedEvents);
      }
    }
    else if (gameEventsFetchState.status === ResultStatus.Loading) {
      setIsLoaderShowing(true);
    }
    else if (gameEventsFetchState.status === ResultStatus.Error) {
      setIsLoaderShowing(false);
      setEvents(undefined);
    }
    // eslint-disable-next-line
  }, [gameEventsFetchState.status, gameEventsFetchState.data?.data]);
  
  const getTeamShortNameByExternalId = (externalTeamId: string | undefined, teamIndex: number): string => {
    if (externalTeamId) {
      const foundTeam = teams?.data.find((team) => (team.externalTeamId === externalTeamId))
      if (foundTeam?.shortName) {
        return foundTeam?.shortName;
      }
    }
    return props.intl.formatMessage({ id: (teamIndex === 0) ? LOCALIZATION.event_home_team : LOCALIZATION.event_guest_team })
  };
  const team1Name = getTeamShortNameByExternalId(props.game?.team1, 0);
  const team2Name = getTeamShortNameByExternalId(props.game?.team2, 1);

  const renderEventsTabsCard = () => {
    return (
      <AnalyticsEventsTabsCard
        sx = {{
        }}
        key={ 'EventsTabsCard' }
        gameInfo={ props.game }
        team1Name={ team1Name }
        team2Name={ team2Name }
        videoPlayerCommands={ videoPlayerCommands }
        events={ events }
      />
    )
  }

  const renderMintesTabsCard = () => {
    return (
      <AnalyticsMintesTabsCard
        sx = {{
          height: "50%",
          minHeight: "280pt"
        }}
        key={ 'MintesTabsCard' }
        gameInfo={ props.game }
        team1Name={ team1Name }
        team2Name={ team2Name }
        videoPlayerCommands={ videoPlayerCommands }
        onCommandsUpdate={(commands) => {
          setMinutesTabsCommands(commands)
        }}
        events={ events }
      />
    )
  }

  const renderCameraPreview = (noUseAspectRatio?: boolean) => {
    return (
      <CameraStreamPreview
        initialStartPositionSec={ props.game?.video_start }
        noUseAspectRatio = { noUseAspectRatio }
        previewImageUrl={ makeAnalyticsVideoImageUrl(analyticsConfig, props.game?.preview) }
        videoUrl={ makeAnalyticsVideoPlayerUrl(analyticsConfig, props.game?.video) }
        fileType={ FileType.Panorama }
        bigPreview={true}
        preventControlsHide={ true }
        onPlayerCommandsUpdate={(commands: VideoPlayerCommands | undefined)=> {
          setVideoPlayerCommands(commands)
        }}
        onPlaybackTimeChangeRoundedToSec={(player, timeSec: number | undefined) => {
          minutesTabsCommands?.updateVideoPlaybackPos(timeSec)
        }}
      />
    )
  }

  const renderGameEdit = () => {
    return (
      <VertGrid
        spacing={ 2 }
        sx={{
          height: "100%"
        }}
      >
        {(useHorisLayout) ?
            <Box
              sx={{
                position: "relative",
                height: "50%",
                minHeight: "280pt"
              }}
            >
              <HorizGrid
                spacing={ 2 }
                alignItems="flex-start"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <Box
                  sx={{
                    ...MAIN_CARD_SX,
                    width: "50%",
                    height: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                  }}
                >
                  { renderCameraPreview(true) }
                </Box>
                <Box
                  sx={{
                    ...MAIN_CARD_SX,
                    width: "50%",
                    height: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  { renderEventsTabsCard() }
                </Box>
              </HorizGrid>
          </Box>
            :
          <>
            { renderCameraPreview() }
            <Box
              sx={{
                ...MAIN_CARD_SX,
                minHeight: "200pt",
                overflow: "hidden",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              { renderEventsTabsCard() }
            </Box>
          </>
        }

        <Box
          sx={{
            ...MAIN_CARD_SX,
            height: "100%",
            maxHeight: "100%",
            // overflow: "hidden",
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          { renderMintesTabsCard() }
        </Box>
      </VertGrid>
    )
  }

  if (isLoaderShowing) {
    return (
      <>
      <br /><br /><Spinner /><br /><br />
      </>
    )
  }
  
  return (
    <Popup
      isFullScreen={true}
      headerTitle={ props.intl.formatMessage({ id: LOCALIZATION.video_analytics }) }
      closeTitle={ props.intl.formatMessage({ id: LOCALIZATION.close_btn }) }
      onClose={ props.onClose }
      disableScroll={true}
    >
      { renderGameEdit() }
    </Popup>
  );
};


export default injectIntl(AnalyticsGameReportVideoDetailsPopup);
