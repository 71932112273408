// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import Order from '../../../_Networking/ReactQuery/Order';
import AnalyticsVideoArchive from '../AnalyticsVideoArchive';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import {
  useFetch,
  flushCache,
  getCached,
} from '../fetch';
import { ResultStatus } from '../../../_Networking/ReactQuery/template';

type Props = Readonly<{
  itemId?: string;
  page?: number;
  limit?: number;
  order?: Order;
  orderBy?: 'date';
  filterByDate?: string;
  filterByCameraId?: string;
  showShared?: boolean;
}>;

type Result = Readonly<{
  status: ResultStatus;
  data: ResponseMany<AnalyticsVideoArchive[]> | undefined;
  handleFlushData: () => void;
}>;


const usePagedData = (props: Props): Result => {
  const fetchedArchive = useFetch({
    itemId: props.itemId,
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    filterByDate: props.filterByDate,
    filterByCameraId: props.filterByCameraId,
    enableToFetch: true,
  });
  const data = getCached({
    itemId: props.itemId,
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    filterByDate: props.filterByDate,
    filterByCameraId: props.filterByCameraId,
  });

  return ({
    status: fetchedArchive.status,
    data,
    handleFlushData: flushCache,
  });
};


export default usePagedData;
