// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useCallback, useState } from 'react';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import CameraCardLocal from './CameraCardLocal';
import CameraCardServer from './CameraCardServer';
import isServer from '../../Data/AccountUsers/tools/isServer';
import useCurrentUser from '../../Data/AccountUsers/hook/useCurrentUser';
import { clearCamerasCache } from '../../Data/Camera/ActiveCamera/fetch';
import { TableViewContext } from './TableView.context';
import { ViewType } from '../../Components/_BaseUI/ViewSwitcher/ViewType.enum';
import { PaginationContext } from './Pagination.context';
import { RemoteCamerasFilter } from './RemoteCamerasFilter.context';
import { RemoteCameraStateValue } from '../../Data/Camera/RemoteCameraState';
import Order from '../../Data/_Networking/ReactQuery/Order';

const CamerasPage: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const user = useCurrentUser();
  const isServerUser = isServer(user);
  const [currentView, setCurrentView] = useState<ViewType>(ViewType.LIST);

  /**
   * Pagination state
   */
  const [offset, setOffset] = useState<number>(0);
  const [state, setState] = useState<RemoteCameraStateValue | undefined>();
  const [order, setOrder] = useState<Order | undefined>(Order.ASC);
  /**
   * Count of elements on the page
   */
  const [limit] = useState<number>(14);
  const setPage = useCallback((value: number) => {
    setOffset(value * limit)
  }, [limit])
  React.useEffect(() => {
    clearCamerasCache();
  }, []);



  if (isServerUser) {
    return (
      <TableViewContext.Provider value={{ currentView, setCurrentView }}>
        <PaginationContext.Provider value={{ offset, limit, setPage, setOrder, orderByDate: order }}>
          <RemoteCamerasFilter.Provider value={{ state, setState }}>
            <CameraCardServer />
          </RemoteCamerasFilter.Provider>
        </PaginationContext.Provider>
      </TableViewContext.Provider>

    )
  }

  return (
      <CameraCardLocal />
  )
};

export default injectIntl(CamerasPage);
