// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import CameraStreamPreview from '../../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview'
import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import CircledLabel  from '../../../../Components/_BaseUI/CircledLabel/CircledLabel'
import MainButton from '../../../../Components/_BaseUI/MainButton'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content'
import LOCALIZATION from '../../../../Localization';
import { FileType } from '../../../../Data/VideoArchive';
import { getLabelFromState } from '../../../CamerasPage/CameraCardBlock/CameraPreviewInfo/helpers';
import { activeEpgEventStatesInfo, steamingStatesInfo } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsStreamingState';
import { useCameraSettings } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks';
import VertGrid from '../../../../Components/_Layout/VertGrid';
import { getMenuLink } from '../../../../Menu/Data/MenuStructure';
import { MenuID } from '../../../../Menu/Data/MenuElement';
import { useNavigate } from 'react-router-dom';
import AuthorisationManager from '../../../../Data/Auth/AuthorisationManager';
import { isUserRoleMinimum, UserRole } from '../../../../Data/AccountUsers/UserWithoutID';
import { useAppConfig } from '../../../../Localization/AppContextProvider/helpers';

const OperatorStreamCard: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const cameraSettings = useCameraSettings();
  const user = AuthorisationManager.shared.user
  const { localization: { locale } } = useAppConfig();

  const isNotProxyMode = (cameraSettings?.activeOptions?.isProxyMode !== "true")

  const [activeEventStateString, activeEventState] = activeEpgEventStatesInfo(cameraSettings?.activeEpgEvent, locale);

  const [storeStateString, storeState] = steamingStatesInfo(cameraSettings?.streamingState?.operatorOutputs, 'mp4File', true)

  const [streamStateString, streamState] = steamingStatesInfo(cameraSettings?.streamingState?.operatorOutputs, 'rtmpStream', true)
  
  const navigate = useNavigate();

  const handleConfigure = () => {
    const menuLink = getMenuLink(MenuID.Streaming);
    if (menuLink) {
      navigate(menuLink);
    }
  }

  const activeProfileId = cameraSettings?.streamingState?.activeProfileId
  let activeProfileName = cameraSettings?.streamingState?.activeProfileName
  if (!activeProfileName && activeProfileId === 'No-Streaming') {
    activeProfileName = props.intl.formatMessage({ id: LOCALIZATION.no_stream })
  }

  return (
    <MainCard>
      <RegularCardHeader title={ props.intl.formatMessage({ id: LOCALIZATION.operator_stream }) }/>
      <MainCardContent>
        <VertGrid>
          <CameraStreamPreview
            key="preview"
            previewImageUrl={ cameraSettings?.urls?.liveOperatorImage }
            videoUrl={ (isNotProxyMode) ? cameraSettings?.urls?.liveOperatorVideoPlayer : undefined }
            fileType={ FileType.Operator }
          />
          <VertGrid
            sx={ {
              marginTop: '15px',
            } }
          >
            <Grid
              container
              spacing={ 1 }
              justifyContent="space-between"
              sx={ { flexGrow: 1 } }
              direction="column"
            >
              {(activeProfileName) &&
                <Grid item xs={ 12 }>
                  <CircledLabel
                    labelType={ getLabelFromState(streamState) }
                    title={ props.intl.formatMessage({ id: LOCALIZATION.stream_profile }) }
                    stateText={ activeProfileName }
                  />
                </Grid>
              }
              {(isNotProxyMode) &&
                <>
                  <Grid item xs={ 12 }>
                    <CircledLabel
                      labelType={ getLabelFromState(streamState) }
                      title={ props.intl.formatMessage({ id: LOCALIZATION.streaming }) }
                      stateText={ streamStateString }
                    />
                  </Grid>
                  <Grid item xs={ 12 }>
                    <CircledLabel
                      labelType={ getLabelFromState(storeState) }
                      title={ props.intl.formatMessage({ id: LOCALIZATION.recording_to_storage }) }
                      stateText={ storeStateString }
                    />
                  </Grid>
                </>
              }
              <Grid item xs={ 12 }>
                <CircledLabel
                  labelType={ getLabelFromState(activeEventState) }
                  title={ props.intl.formatMessage({ id: LOCALIZATION.active_epg_event }) }
                  stateText={ activeEventStateString }
                />
              </Grid>
            </Grid>
            { (isUserRoleMinimum(user?.role, UserRole.Admin)) &&
              <Grid container spacing={ 1 } 
                direction="row"
                justifyContent="flex-end" 
                sx={ {
                  marginTop: '15px',
                } }
              >
                <Grid item>
                  <MainButton onClicked={ handleConfigure } title={ props.intl.formatMessage({ id: LOCALIZATION.configure }) } />
                </Grid>
              </ Grid>
            }
          </VertGrid>
        </VertGrid>
      </MainCardContent>
    </MainCard>
  );
};


export default injectIntl(OperatorStreamCard);
