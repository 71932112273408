// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>


import { QueryKey, useQuery, useMutation } from 'react-query';

import { ResultSingle } from '../../_Networking/ReactQuery/template';
import { JAVA_BACKEND_URL } from '../../../constants';
import fetchJSONData from '../../_Networking/ReactQuery/fetch';
import ResponseSingle from '../../_Networking/ReactQuery/ResponseSingle';
import { queryCache, queryClient } from '../../../AppProviders';

export type CameraSettingsSyncConfig = Readonly<{
    // Is syncthing now can sync data.
    syncEnabled: boolean;
    // Sync default speed in bit/sec. Default used if streaming not active and maximum speed. Value from 0 to 200000000 rounded to 1000. 0 - unlimited speed.
    defaultSyncBitrate: number;
    // Sync speed in bit/sec when streaming active. Value from 0 to 200000000 rounded to 1000. 0 - unlimited speed.
    syncWhileStreamingBitrate: number;
    // Sync speed in bit/sec when streaming active and network can't stream all data. Value from 0 to 200000000 rounded to 1000. 0 - unlimited speed.
    syncWhileLowNetworkSpeedBitrate: number;

    // Sync speed in bit/sec when time between maximumSyncStartTime and maximumSyncEndTime. Value from 0 to 200000000 rounded to 1000. 0 - unlimited speed.
    maximumSyncBitrate: number;
    // Start time like '23:59' when sync use maximumSyncBitrate. Time always in 24h format with minutes. To disable maximum sync work make value equal to maximumSyncEndTime.
    maximumSyncStartTime: string;
    // End time like '23:59' when sync stop use maximumSyncBitrate. Time always in 24h format with minutes.
    maximumSyncEndTime: string;
}>;



const PREFIX_OF_A_COMPOSITE_KEY = 'sync-config';

const getKeySyncConfig = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getCachedSyncConfig = (): ResponseSingle<CameraSettingsSyncConfig> | undefined => (
  queryClient.getQueryData<ResponseSingle<CameraSettingsSyncConfig>>(getKeySyncConfig())
);

const flushCacheSyncConfig = () => {
  const queries = queryCache.findAll(getKeySyncConfig());

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};


const fetchDataSyncConfig = async (): Promise<ResponseSingle<CameraSettingsSyncConfig>> => {
  const cacheData = getCachedSyncConfig();

  if (cacheData) { return cacheData; }

  return await fetchJSONData<ResponseSingle<CameraSettingsSyncConfig>>(
    `${JAVA_BACKEND_URL}/settings/sync`,
    {
      method: 'GET',
    }
  );
};

const patchDataSyncConfig = async (settings: Partial<CameraSettingsSyncConfig>): Promise<ResponseSingle<CameraSettingsSyncConfig>> => {
  try {
    return await fetchJSONData<ResponseSingle<CameraSettingsSyncConfig>>(
        `${JAVA_BACKEND_URL}/settings/sync`,
        {
          method: 'PATCH',
          body: JSON.stringify(settings),
        }
    );
  } catch (error) {
    throw error;
  }
};

const useFetchSyncConfig = (): ResultSingle<CameraSettingsSyncConfig> => (
    useQuery<ResponseSingle<CameraSettingsSyncConfig>>({
      queryKey: getKeySyncConfig(),
      queryFn: () => fetchDataSyncConfig(),
      // refetchInterval: 1000,
      // staleTime: 0,
      // cacheTime: 0,
      // notifyOnChangeProps: ['data', 'isLoading', 'error'],
    }) as ResultSingle<CameraSettingsSyncConfig>
);

export enum ActionType {
    Patch = 'patch',
}
  
export type Action = Readonly<{
   config?: Partial<CameraSettingsSyncConfig>;
   type: ActionType;
}>;
  
  
const useMutationSyncConfig = () => {
    return useMutation(
        (action: Action) => {
            switch (action.type) {
                case ActionType.Patch: {
                    return patchDataSyncConfig(action.config as Partial<CameraSettingsSyncConfig>);
                }
                default:
                    return Promise.reject(new Error('Invalid Settings Mutation Action'));
            }
        },
        {
            onSuccess: (data) => {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    queryClient.setQueryData(
                        getKeySyncConfig(),
                        data,
                    );
                }
            },
        },
    );
};

export const bitrateToMbit = (bitrate?: number): number => (
    (bitrate !== undefined && bitrate >= 0) ? bitrate / 1000000.0 : 0
); 

export const mbitToBitrate = (mbit?: number): number => (
    (mbit !== undefined && mbit >= 0) ? Math.round(mbit * 1000000) : 0
); 

export {
  getKeySyncConfig,
  useFetchSyncConfig,
  patchDataSyncConfig,
  getCachedSyncConfig,
  flushCacheSyncConfig,
  useMutationSyncConfig,
  PREFIX_OF_A_COMPOSITE_KEY,
};
