// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { CameraAzymuthMetric } from '../../../../../../Data/Camera/Metrics/Collection'
import { useSingleMetricValue } from '../../../../../../Data/Camera/Metrics/MetricsValuesHook'

export function useCameraAzymuth(): number | undefined {
    const value = useSingleMetricValue(CameraAzymuthMetric, 'ValuesDirectionsAzymuth')
    return value
    // if (value !== undefined) {
    //     return 360 - value
    // } else {
    //     return undefined
    // }
}
