// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import { Property } from 'csstype';


type Props = Readonly<{
    ref?: React.Ref<unknown>;
    key?: string | number | null | undefined;
    sx?: SxProps<Theme>;
    alignItems?: Property.AlignItems | undefined;
    alignContent?: Property.AlignContent | undefined;
    justifyContent?: Property.JustifyContent | undefined;
    justifyItems?: Property.JustifyItems | undefined;
    spacing?:number|string
    children: any;
}>;


const VertGrid: React.FC<Props> = (props: Props) => {

    return (
    <Box
        ref = {props.ref}
        key = {props.key}
        sx={ {
        display: 'flex',
        flexDirection: 'column',
        alignItems: (props.alignItems) ?? 'stretch',
        alignContent: props.alignContent,
        justifyContent: props.justifyContent,
        justifyItems: props.justifyItems,
        rowGap: props.spacing,
        ...props.sx,
        } }
    >
        { props.children }
    </Box>
    )
};


export default VertGrid;
