// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import TeamCell from '../EventsList/TeamCell';
import EPGEvent from '../../../Data/EPG/EPGEvents/EPGEvent';
import { RenderCellContentValue } from '../../../Components/SortedTable/SortedTableBodyRow';

import { dayjsFrom, dayjsFromDateStr, localizedGameTypeById, localizedSportNameById, priorityColorCharById } from '../AddEvent/helpers';
import { Link } from '@mui/material';
import { intl } from '../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../Localization';
import { getMenuLink } from '../../../Menu/Data/MenuStructure';
import { MenuID } from '../../../Menu/Data/MenuElement';
import { Link as RouterLink } from 'react-router-dom';

const DayHeaderDeletedMarker = 1000

const renderEventsTabCell = (item: EPGEvent, cellName: keyof EPGEvent): RenderCellContentValue => {
  try {
    if (item.deleted === DayHeaderDeletedMarker) { // DAY HEADER 
      if (cellName === 'gameType' as keyof EPGEvent) {
        const title = dayjsFromDateStr(item.startAt).format("dddd l").capitalizeFirstLetter();

        return ({
          cellProps: {
            align: 'center'
          },
          content: (
            <div>
              <b>{ title }</b>
            </div>
          ),
        });
      }

      return ({
        content: null,
      });
    }
    if (cellName === 'name' as keyof EPGEvent) {
      if (item.subTitle) {
        return ({
          content: (
            <div>
              { item.name }<br />
              <i style={{ fontSize: '90%' }}>{ item.subTitle }</i>
            </div>
          ),
        });
      }
      return ({
        content: (
          item.name
        ),
      });
    } else if (cellName === 'priority' as keyof EPGEvent) {
      return ({
        content: (
          priorityColorCharById(item.priority)
        ),
      });
    } else if (cellName === 'gameType' as keyof EPGEvent) {
      return ({
        content: (
          <div>
            { localizedGameTypeById(item.gameType) }<br />
            <b>{ localizedSportNameById(item.sportType) }</b>
          </div>
        ),
      });
    } else if (cellName === 'homeTeamID' as keyof EPGEvent) {
      if (item.homeTeamID === null && item.guestTeamID === null) {
        return ({
          // cellProps: {
          //   align: ('center' as any),
          // },
          content: (
            <div>—</div>
          ),
        });
      }

      return ({
        content: (
          <div>
            {
              (item.homeTeamID === null) ?
                '—'
                :
                <>
                  <TeamCell teamID={ item.homeTeamID } /><br />
                </>
            }
            {
              (item.guestTeamID === null) ?
                '—'
                :
                <TeamCell teamID={ item.guestTeamID } />
            }
          </div>
        ),
      });
    } else if (cellName === 'startAt' as keyof EPGEvent) {
      const start = dayjsFrom("start", item.startAt, null, item.endAt, null)
      const end = dayjsFrom("endDate", item.startAt, null, item.endAt, null)

      return ({
        content: (
          <div>
            { start?.format("LT") } — { end?.format("LT") }
          </div>
        ),
      });
    } else if (cellName === 'records' as keyof EPGEvent) {
      if (!item.streamingName) {
        return ({
          content: (
            <div>—</div>
          ),
        }); 
      }
      const start = dayjsFrom("start", item.startAt, null, item.endAt, null)?.format("YYYY-MM-DD")
      const menuLink = getMenuLink(MenuID.VideoArchive);
      const linkUrl = `${menuLink}`

      return ({
        content: (
          <div>
            <Link component={RouterLink} to={linkUrl} state={{filterDate: start}}>{ intl().formatMessage({ id: LOCALIZATION.page_video_archive_heading }) }</Link>
          </div>
        ),
      });
    }

    return ({
      content: (item?.[cellName] || '') as string,
    });
  } catch (ignore) {
    return ({
      content: (item?.[cellName] || '') as string,
    });
  }
};


export default renderEventsTabCell;
