// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import cn from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import { createStyles, makeStyles } from '@mui/styles';
import { Grid, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import QuestionTooltip from '../QuestionTooltip';
import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Colors } from '../../../Configuration/Styles/Colors';


type Props = Readonly<{
  label?: string;
  labelSx?: SxProps<Theme>;
  tooltipQuestionText?: string;
  helperText?: string;
  multiLine?: boolean;
  children?: JSX.Element;
  hideSeparator?: boolean
}>;


const useStyles = makeStyles(() => createStyles({
  fieldSet: {
    padding: '14px 0',
    minHeight: '54px',
  },
  fieldSetWithBorder: {
    padding: '14px 0',
    minHeight: '54px',
    borderBottom: 'solid #C2CFE0 1px',
    '&:last-child': {
      borderBottom: 'none',
    }
  },
  fieldSetMultiLine: {
    display: 'block !important',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '5px',
    fontFamily: Fonts.main,
    fontSize: Sizes.titleFiled,
    fontWeight: Sizes.regularWeight,
    color: Colors.mainTitle,
  },
}));

const FieldSetWrapper: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid
      container
      className={
        cn( (props.hideSeparator) ? classes.fieldSet : classes.fieldSetWithBorder, {
          [classes.fieldSetMultiLine]: (props.multiLine === true),
        })
      }
    >
      <Grid
        item
        xs={ (props.multiLine) ? 12 : (props.children) ? ((largeScreen) ? 6 : 6) : 12 }
        className={ cn({ [classes.label]: (props.multiLine !== true) }) }
        sx={{...props.labelSx}}
      >
        { props.label || '' }
        { (props.tooltipQuestionText) &&
          <QuestionTooltip
            toolTip={ props.tooltipQuestionText }
          />
        }
      </Grid>
      <Tooltip
        arrow
        open={ !!props.helperText }
        title={ props.helperText || '' }
        PopperProps={ {
          sx: {
            zIndex: 1300,
            '& .MuiTooltip-tooltip': {
              fontSize: '14px',
              color: '#fff',
              backgroundColor: '#EB5757',
            },
            '& .MuiTooltip-arrow:before': {
              backgroundColor: '#EB5757',
            },
          }
        } }
      >
        <Grid
          item
          xs={ (props.multiLine !== true) ? ((largeScreen) ? 6 : 6) : 12 }
          sx={ {
            position: 'relative',
          } }
        >
          { props.children }
        </Grid>
      </Tooltip>
    </Grid>
  );
};


export default FieldSetWrapper;
