// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { CSSProperties } from "react";
import { Colors } from "../../../Configuration/Styles/Colors";
import { Fonts } from "../../../Configuration/Styles/Fonts";
import { Sizes } from "../../../Configuration/Styles/Sizes";
import { SvgInHtml } from "../../../Icons/Icons";

export type BarChartItemValue = Readonly<{
    value: number;
    ratio?: number;
    label?: string;
    name?: string;
}>;

export type BarChartItem = Readonly<{
    label: string;
    value1: BarChartItemValue;
    value2: BarChartItemValue;
    normBase?: number;
    units?: string;
}>;


export type BarChartDataArrayItemVal = Readonly<{
    label: string | undefined;
    valueLabel?: string | JSX.Element;
    valueName?: string | JSX.Element;
    value: number | undefined;
    ratio: number | undefined;
}>;
  
 
export type BarChartData = {
    label: string | undefined;
    units?: string;
    val1_data?: BarChartDataArrayItemVal,// contain main data for val1
    val2_data?: BarChartDataArrayItemVal,// contain main data for val1
};
  
export function XAxisTickLeftAligntLabel({ x, y, payload }: any) {
    return (
      <g transform={`translate(${0},${y})`}>
        <text x={0} y={0} textAnchor="start" fill={ Colors.black } fontSize={ Sizes.title }  fontFamily= {Fonts.main} fontWeight = { Sizes.regularWeight } >
          {payload.value}
        </text>
      </g>
    );
}
  
function valueWithParts( 
    label: string,
    normBase: number, 
    part: BarChartItemValue, 
    valueLabel?: string | JSX.Element, 
    parentName?: string, 
    ratio?: number): BarChartDataArrayItemVal 
{
  const value = (!part.value) ? 0 : part.value;
  // to prevent value==0 did not have space and thus no label. make it have fake value 0.05. in this case it will show small progress but 0 label on it
  // normally affect on Score. other values 99% time have non 0 value
  const newRatio = (ratio !== undefined) ? ratio : (part.ratio === undefined ? ((value || 0.05) / normBase) * 100 : part.ratio);
    return {
        label,
        valueLabel,
        valueName: (parentName && part.name) ? parentName + " - " + part.name : part.name,
        value,
        ratio: newRatio
    }
}
  
function updateDataWithValue( outData: BarChartData, normBase: number, keyPrefix: string, item: BarChartItem, value?: BarChartItemValue) {
    if (value) {// add all existing parts
        type BarChartDataVal = {
            [key: string|number]: any
        }
        const dataMap = outData as BarChartDataVal;

        const valueLabel = (item.units) ? value.value + item.units : value.value + "";

        dataMap[keyPrefix] = valueWithParts(item.label, normBase, value, valueLabel);

    }
}
  
function processsBarChartItemValue( item: BarChartItem, normBase: number): BarChartData {
    const outData: BarChartData = {
        label: item.label,
    }

    updateDataWithValue(outData, normBase, "val1_data", item, item.value1);
    updateDataWithValue(outData, normBase, "val2_data", item, item.value2);

    return outData;
}

function normlizeValues( values: number[], normBase: number | undefined ): number {
    return normBase || Math.max(...values) || 1;
}

export function createVertBarChartSkinDataArr(data: BarChartItem[]): BarChartData[] {
    // create out arr
    let outArr: BarChartData[] = [];

    // process input data
    for (let index = 0; index < data.length; index++) {
        const item = data[index];

        const normBase = normlizeValues( [item.value1.value, item.value2.value], item.normBase );
        const valueData = processsBarChartItemValue(item, normBase);
        outArr.push(valueData);
    }

    return outArr;
}

export type VertBarChartTeamConfig = Readonly<{
  dataKeyPrefix: string;// key to get value from data
  fillColor?: string;
  fillOpacity?: number;
  stackId?: string;// used to group bars with same label in one row (stack)
  icon?: SvgInHtml;
}>;

// skin config to ver chart bar
export const vertBarChartTeamConfig = (team1Color: string | undefined, team2Color: string | undefined): VertBarChartTeamConfig[] => ([
    {
      fillColor: team1Color,
      fillOpacity: 1,
      dataKeyPrefix: "val1_data",
      stackId: "val1",
    },

    {
      fillColor: team2Color,
      fillOpacity: 1,
      dataKeyPrefix: "val2_data",
      stackId: "val1"
    },
  ])


type LabelListCoontentIconProps = Readonly<{
  index?: number;
  data?: BarChartData[]
  padding?: number;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  style?: CSSProperties | undefined;
  position?: string;
  value?: number | string;
}>;

export const LabelListContentText: React.FC<LabelListCoontentIconProps> = (props: LabelListCoontentIconProps) => {
  const { x = 0, y = 0, width = 0, height = 0, style, index, padding = 0, data, position = "center", value } = props;
  if (!width || !height || index === undefined || width < height) {
    return null;
  }

  const label = (data && index < data.length) ? data[index].label : value

  const fullWidth = x + width;
  let newX = x
  let newWidth = width
  let textAnchor="stat" 
  switch (position) {
    case 'center': {
      newX = fullWidth / 2;
      newWidth = fullWidth;
      textAnchor="middle" 
      break
    } 
    case 'left': {
      newX = x
      textAnchor="stat" 
      break
    } 
    case 'right': {
      newX = x + width - padding * 2
      textAnchor="end" 
      break
    }
  }

  return (
      // <g transform={`translate(${0},${y})`}>
        <text 
          x={ newX + padding } 
          y={ y + padding - 10} 
          width={ newWidth - padding * 2 } 
          height={ height - padding * 2 } 
          textAnchor={ textAnchor }
          fill={ Colors.black } 
          fontSize={ Sizes.heading }  
          fontFamily= {Fonts.main} 
          fontWeight = { Sizes.regularWeight } 
          style={ style } 
          >
          { label }
        </text>
      // </g>
  );
};

export const swapValue1and2InBarChartArray = (data: BarChartItem[] | undefined): BarChartItem[] => {
  return data?.map((item) => {
    return {
      ...item,
      value1: item.value2,
      value2: item.value1,
    }
  }) || []
}