// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useState } from 'react'
import { useGpioPressActionData } from '../../../../Data/Camera/Gpio/Hooks'
import { JetsonHardwareButtonsAvailability, JetsonHardwareButtonsFormInterface, JetsonHardwareButtonType, JetsonId } from '../../../../Data/Camera/Jetson/Types'
import { JetsonTools } from '../../../../Tools/Tools'

export const useHardwareButtonsForm = (submitForm: (form: JetsonHardwareButtonsFormInterface) => Promise<void>, initialForm: JetsonHardwareButtonsFormInterface) => {
    const [form, setForm] = useState(initialForm)

    const onChangeButtonState: (buttonType: JetsonHardwareButtonType, value: boolean) => void = (buttonType, value) => {
        setForm({ ...form, states: { ...form.states, [buttonType]: value } })
    }

    const onChangeDuration: (duration: number) => void = (duration) => {
        setForm({ ...form, duration: duration })
    }

    const onSubmit: () => void = async () => {
        await submitForm(form)
    }

    return {
        onChangeButtonState,
        onChangeDuration,
        onSubmit,
        form
    }
}

export const useButtonsAvailability: (id: JetsonId) => JetsonHardwareButtonsAvailability = id => {
    const gpioPressActions = useGpioPressActionData()

    const availability: JetsonHardwareButtonsAvailability = {
        power: JetsonTools.getIsAvailablePower(gpioPressActions, id),
        recovery: JetsonTools.getIsAvailableFactoryReset(gpioPressActions, id),
        reset: JetsonTools.getIsAvailableReset(gpioPressActions, id)
    }

    return availability
}
