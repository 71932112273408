// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { TablePagination } from '@mui/material';
import { intl } from '../../Localization/LocalizationProvider';
import LOCALIZATION from '../../Localization';


type Props = Readonly<{
  totalPages: number;
  currentPage: number;
  boundaryCount?: number;
  onClick?: (page: number) => void;
}>;


const PaginationComponent: React.FC<Props> = (props: Props) => {

  const handleChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    props.onClick?.(page);
  };

  const paginationBtnRoundStyle = {
    '&:before': {
      content : '""',
      position: 'absolute',
      left    : '20%',
      top     : '20%',
      height  : '50%',
      width   : '50%',
      borderRadius: '50%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'info',
    },
  }

  if (props.totalPages <= 1) {
    return (
      <></>
    )
  }

  return (
    <TablePagination
      rowsPerPageOptions={ [] }
      component="div"
      count={ props.totalPages }
      rowsPerPage={ 1 }
      page={ props.currentPage }
      labelRowsPerPage={ `${intl().formatMessage({ id: LOCALIZATION.table_rows_per_page })}:` }
      labelDisplayedRows={ ({ from, to, count }) => (
        `${from} ${intl().formatMessage({ id: LOCALIZATION.table_of })} ${count}`
      ) }
      onPageChange={ handleChange }
      // onRowsPerPageChange={ handleChangeRowsPerPage }
      nextIconButtonProps={{
        color: 'info',
        sx: paginationBtnRoundStyle
      }}
      backIconButtonProps={{
        color: 'info',
        sx: paginationBtnRoundStyle
      }}
    />);
};


export default PaginationComponent;
