// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useMutation } from 'react-query';

import { deleteGame, patchGame, addGame, patchGameTeamsMapping, postStartAnalytics, PREFIX_OF_A_COMPOSITE_KEY } from '.';
import { queryCache, queryClient } from '../../../../AppProviders';
import AnalyticsGameArchive, { AnalyticsGameTeamMapping } from '../AnalyticsGameArchive';


export enum ActionType {
  Add = 'add',
  Delete = 'delete',
  Patch = 'patch',
  PatchTeamMapping = 'patchTeamMapping',
  PostStartAnalytics = 'postStartAnalytics',
}

export type ArchiveForActionProps = Readonly<{
  id: string;
  game?: AnalyticsGameArchive; // used by ActionType.Patch
  gameTeamMapping?: AnalyticsGameTeamMapping; // used by ActionType.PatchTeamMapping
}>;

export type Action = Readonly<{
  archive?: ArchiveForActionProps;
  type: ActionType;
}>;


const useMutationGame = () => {
  return useMutation(
    (action: Action) => {
      switch (action.type) {
        case ActionType.Add: {
          if (action.archive) {//{ archive={type: "operator", startAt: "2022-01-02T12:30", event?: EPGEvent} }
            return addGame(action.archive);
          }

          return Promise.reject(new Error('Not all required fields are initialized'));
        }
        case ActionType.Delete: {
          if (action.archive) {//{ archive={type: "operator", startAt: "2022-01-02T12:30", event?: EPGEvent} }
            return deleteGame(action.archive);
          }

          return Promise.reject(new Error('Not all required fields are initialized'));
        }
        case ActionType.Patch: {
          if (action.archive?.game) {//{ archive={type: "operator", startAt: "2022-01-02T12:30", event?: EPGEvent} }
            return patchGame(action.archive);
          }

          return Promise.reject(new Error('Not all required fields are initialized'));
        }
        case ActionType.PatchTeamMapping: {
          if (action.archive?.gameTeamMapping) {//{ archive={type: "operator", startAt: "2022-01-02T12:30", event?: EPGEvent} }
            return patchGameTeamsMapping(action.archive);
          }

          return Promise.reject(new Error('Not all required fields are initialized'));
        }
        case ActionType.PostStartAnalytics: {
          if (action.archive?.id) {
            return postStartAnalytics(action.archive);
          }

          return Promise.reject(new Error('Not all required fields are initialized'));
        }
        default:
          return Promise.reject(new Error('Invalid Account Mutation Action'));
      }
    },
    {
      onSuccess: () => {
        const queries = queryCache.findAll([[PREFIX_OF_A_COMPOSITE_KEY]]);

        if (queries && queries.length) {
          queries.forEach(({ queryKey }) => {
            queryClient.removeQueries(queryKey);
          })
        }
      },
    },
  );
};


export default useMutationGame;
