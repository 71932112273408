// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import Team from '../Team/Team';
import Event from '../EpgSchedule/Event';
import EventWithoutID, { EventDestStreamOverride, EventExtraJson, EventPriority, GameType } from '../EpgSchedule/EventWithoutID';
import { dayjsFrom } from '../../../Pages/EventsPage/AddEvent/helpers';


type EPGEvent = Readonly<{
  id: number;
  name: string;
  subTitle: string | null;
  description: string | null;
  priority: EventPriority;
  gameType: GameType;
  sportType: string;
  homeTeamID: Team['id'] | null;
  guestTeamID: Team['id'] | null;
  startAt: string;
  endAt: string;
  streamingID: string | null;
  streamOverride: EventDestStreamOverride | undefined | null;
  immediateMarkupPromocode: string | null;
  extraJson: EventExtraJson | undefined | null;
  streamingName: string | null;
  parentEventId: number | null;
  deleted?: number | null;
  completed?: boolean | null;
}>;

export const epgEventFromEvent = (event?: Event | EventWithoutID): EPGEvent | undefined => {
  if (!event) {
    return undefined;
  }

  const startAt = dayjsFrom("start", event.startDate, event.startTime, event.endDate, event.endTime)
  const endAt = dayjsFrom("end", event.startDate, event.startTime, event.endDate, event.endTime)
  
  const out: EPGEvent = {
    id: ("id" in event) ? event.id : 0,
    name: event.name,
    subTitle: event.subTitle,
    description: event.description,
    priority: event.priority,
    gameType: event.gameType,
    sportType: event.sportType,
    homeTeamID: event.homeTeamID,
    guestTeamID: event.guestTeamID,
    streamingID: event.streamProfileID,
    streamOverride: event.streamOverride,
    immediateMarkupPromocode: event.immediateMarkupPromocode,
    extraJson: event.extraJson,
    streamingName: null,
    parentEventId: ("id" in event) ? event.id : null,
    startAt: startAt?.format() || "",
    endAt: endAt?.format() || "",
  }
  return out;
}

export default EPGEvent;
