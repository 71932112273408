// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { // Menu Icons
  StatusIcon, CamerasIcon, HardwareIcon, CameraAPIIcon, AdministrationHeaderIcon, ConfigurationHeaderIcon, AdministrationUsersIcon,
  EventsIcon, StorageIcon, StreamingIcon, VideoArchiveIcon, LogoutIcon, AnalyticsGamesReportsIcon, AnalyticsTeamsIcon, AnalyticsVideoArchiveIcon  //CameraIcon
  //AnalyticsOverallIcon,
} from './MenuIcons';

// React Pages
import CamerasPage from '../../Pages/CamerasPage';
import StatusPage from '../../Pages/StatusPage';
import EventsPage from '../../Pages/EventsPage';
import AnalyticsTeamsListPage from '../../Pages/AnalyticsTeamsListPage';
import AnalyticsVideoArchivePage from '../../Pages/AnalyticsVideoArchivePage';
import AnalyticsGamesArchivePage from '../../Pages/AnalyticsGamesArchivePage';
// import PanoramaPage from '../../Pages/Panorama/PanoramaToolPreview/PanoramaToolPreview';
import VideoArchivePage from '../../Pages/VideoArchivePage';
import HardwarePage from '../../Pages/HardwarePage';
import CameraAPIPage from '../../Pages/CameraAPIPage';
import StoragePage from '../../Pages/StoragePage';
import StreamingPage from '../../Pages/StreamingPage';
import UsersPage from '../../Pages/UsersPage';
import AuthorisationManager from '../../Data/Auth/AuthorisationManager';


import { getCachedActiveCamera, isCameraAProxy, isNoActiveCamera } from '../../Data/Camera/ActiveCamera/fetch';
import { getCameraName } from '../../Pages/CamerasPage/CameraCardBlock/helper';
import isSuperAdmin from '../../Data/AccountUsers/tools/isSuperAdmin';

import { AppLocale } from '../../Localization/AppContextProvider/helpers';
import { MenuEl, MenuElement, MenuElEnv, MenuElType, MenuID, MenuIDArr  } from './MenuElement';
import { intl } from '../../Localization/LocalizationProvider';
import { PlatformConfiguration } from '../../Configuration/PlatformConfiguration';
import LOCALIZATION from '../../Localization';
import { UserRole, UserTeamRefAccess } from '../../Data/AccountUsers/UserWithoutID';
import { isUserHaveCameraAccess, isUserHaveServerAnalyticsAccess } from '../../Data/AccountUsers/User';
import AnalyticsGameReport from '../../Pages/AnalyticsGameReport';
import AnalyticsGameReportMainDetails from '../../Pages/AnalyticsGameReportMainDetails';
import AnalyticsGameReportPhysicalDetails from '../../Pages/AnalyticsGameReportPhysicalDetails';
import AnalyticsGameReportTacticalDetails from '../../Pages/AnalyticsGameReportTacticalDetails';
import AnalyticsVideoArchiveDetails from '../../Pages/AnalyticsVideoArchiveDetails';

export const getCamerasTitle = (formatMessage: any): string => {
  try {
    const activeCameraData = getCachedActiveCamera();
    const { user } = AuthorisationManager.shared;

    if (isNoActiveCamera(activeCameraData)) {
      return formatMessage({ id: LOCALIZATION.no_active_camera });
    }

    if (activeCameraData) {
      return getCameraName(activeCameraData, isSuperAdmin(user), !activeCameraData.localCamera);
    }

    return `${PlatformConfiguration.host}` || formatMessage({ id: LOCALIZATION.cameras });
  } catch (ignore) {
    return formatMessage({ id: LOCALIZATION.cameras });
  }
};

const getAdministrationSectionTitle = (): string => {
  const user = AuthorisationManager.shared.user
  if (user) {
    if (user.name && user.name.length > 0) 
      return user.name
    if (user.login && user.login.length > 0) 
      return user.login
  }
  return '';
}

const getMenuElStructure = (formatMessage: any): MenuEl[] => ([
  {
    id: MenuID.Cameras, // /Cameras
    title: getCamerasTitle(formatMessage),
    icon: CamerasIcon,
    Component: CamerasPage,
    isUseActiveCamera: false,
  },
  {
    id: MenuID.Status, // /Status
    title: formatMessage({ id: LOCALIZATION.page_status_title }),
    icon: StatusIcon,
    Component: StatusPage,
  },
  {
    id: MenuID.Events, // /Events
    title: formatMessage({ id: LOCALIZATION.page_events_title }),
    icon: EventsIcon,
    Component: EventsPage,
  },
  {
    id: MenuID.VideoArchive, // /VideoArchive
    title: formatMessage({ id: LOCALIZATION.page_video_archive_heading }),
    icon: VideoArchiveIcon,
    Component: VideoArchivePage,
    isHiddenForProxy: true,
  },

  // Configuration Section
  {
    id: MenuID.Configuration,
    type: MenuElType.Section,
    title: formatMessage({ id: LOCALIZATION.configure }),
    icon: ConfigurationHeaderIcon,
    minUserRole: UserRole.Admin,
    items: [
      // {
      //   id: MenuID.Panorama,
      //   title: formatMessage({ id: LOCALIZATION.page_panorama_title }),
      //   icon: CameraIcon,
      //   Component: PanoramaPage,
      // },
      {
        id: MenuID.Hardware, // /Configuration/Hardware
        title: formatMessage({ id: LOCALIZATION.page_hardware_title }),
        icon: HardwareIcon,
        Component: HardwarePage,
        minUserRole: UserRole.Admin,
        isHiddenForProxy: true,
      },
      {
        id: MenuID.CameraAPI, // /Configuration/CameraAPI
        title: formatMessage({ id: LOCALIZATION.page_camera_api_title }),
        icon: CameraAPIIcon,
        Component: CameraAPIPage,
        minUserRole: UserRole.SuperAdmin,
        isHiddenForProxy: true,
      },
      {
        id: MenuID.Storage, // /Configuration/Storage
        title: formatMessage({ id: LOCALIZATION.page_sync_settings_title }),
        icon: StorageIcon,
        Component: StoragePage,
        minUserRole: UserRole.Admin,
        isHiddenForProxy: true,
      },
      {
        id: MenuID.Streaming, // /Configuration/Streaming
        title: formatMessage({ id: LOCALIZATION.page_streaming_api_heading }),
        icon: StreamingIcon,
        Component: StreamingPage,
        minUserRole: UserRole.Admin,
      },
    ],
  },
  // Analytics Section
  {
    id: MenuID.Analytics,
    type: MenuElType.Section,
    title: formatMessage({ id: LOCALIZATION.analytics }),
    icon: StatusIcon,
    minUserRole: UserRole.User,
    items: [
      {
        id: MenuID.AnalyticsVideoArchive, // /Analytics/AnalyticsVideoArchive
        title: formatMessage({ id: LOCALIZATION.page_analytics_video_archive_title }),
        icon: AnalyticsVideoArchiveIcon,
        Component: AnalyticsVideoArchivePage,
        minUserRole: UserRole.User,
        isUseOnlyAnalytics: true,
        items: [ // not visible in current 2 level menu. used for routing
          {
            id: MenuID.AnalyticsVideoArchiveDetails, // /Analytics/AnalyticsVideoArchive/:AnalyticsVideoArchiveDetails
            isDynamicPathId: true,
            Component: AnalyticsVideoArchiveDetails,
            minUserRole: UserRole.User,
            isUseOnlyAnalytics: true,
          },
        ],
      },
      {
        id: MenuID.AnalyticsGamesArchive, // /Analytics/AnalyticsGamesArchive
        title: formatMessage({ id: LOCALIZATION.page_analytics_games_archive_title }),
        icon: AnalyticsGamesReportsIcon,
        Component: AnalyticsGamesArchivePage,
        minUserRole: UserRole.User,
        isUseOnlyAnalytics: true,
        items: [ // not visible in current 2 level menu. used for routing
          {
            id: MenuID.AnalyticsGameReport, // /Analytics/AnalyticsGamesArchive/:AnalyticsGameReport
            isDynamicPathId: true,
            Component: AnalyticsGameReport,
            minUserRole: UserRole.User,
            isUseOnlyAnalytics: true,
            items: [
              {
                id: MenuID.AnalyticsGameReportDetails, // /Analytics/AnalyticsGamesArchive/:AnalyticsGameReport/AnalyticsGameReportDetails
                Component: AnalyticsGameReportMainDetails,
                minUserRole: UserRole.User,
                isUseOnlyAnalytics: true,
                title: formatMessage({ id: LOCALIZATION.details }),
              },
              {
                id: MenuID.AnalyticsGameReportTactical, // /Analytics/AnalyticsGamesArchive/:AnalyticsGameReport/AnalyticsGameReportTactical
                Component: AnalyticsGameReportTacticalDetails,
                minUserRole: UserRole.User,
                isUseOnlyAnalytics: true,
                title: formatMessage({ id: LOCALIZATION.team_tactical_report }),
              },
              {
                id: MenuID.AnalyticsGameReportPhysical, // /Analytics/AnalyticsGamesArchive/:AnalyticsGameReport/AnalyticsGameReportPhysical
                Component: AnalyticsGameReportPhysicalDetails,
                minUserRole: UserRole.User,
                isUseOnlyAnalytics: true,
                title: formatMessage({ id: LOCALIZATION.team_physical_report }),
              },
            ],
          },
        ],
      },
      {
        id: MenuID.AnalyticsTeamsList, // /Analytics/AnalyticsTeamsList
        title: formatMessage({ id: LOCALIZATION.page_analytics_teams_title }),
        icon: AnalyticsTeamsIcon,
        Component: AnalyticsTeamsListPage,
        minUserRole: UserRole.User,
        isUseActiveCamera: false,
        isUseCameraAccess: false,
        isUseTeamRefList: true,
      },
    ],
  },

  // Administration Section
  {
    id: MenuID.Administration,
    type: MenuElType.Section,
    title: getAdministrationSectionTitle(),
    icon: AdministrationHeaderIcon,
    items: [
      {
        id: MenuID.AdministrationUsers, // /Administration/AdministrationUsers
        title: formatMessage({ id: LOCALIZATION.page_users_administration_title }),
        icon: AdministrationUsersIcon,
        Component: UsersPage,
        isUseActiveCamera: false,
        isUseCameraAccess: false,
      },
      {
        id: MenuID.Logout, // /Administration/Logout
        type: MenuElType.Special,
        title: formatMessage({ id: LOCALIZATION.menu_logout }),
        icon: LogoutIcon,
        isUseActiveCamera: false,
        isUseCameraAccess: false,
      },
    ],
  },
]);

/// method sinply convert MenuEl type to MenuElement class
const getMenuStructure = (locale: AppLocale): MenuElement[] => {
  try {
    const { user } = AuthorisationManager.shared;
    var menuStructure: MenuElement[] = []
    const baseMenuStructure: MenuEl[] = getMenuElStructure(intl(locale).formatMessage);

    const activeCameraData = getCachedActiveCamera();
    const env: MenuElEnv = {
      userHaveActiveCamera: !isNoActiveCamera(activeCameraData), 
      userHaveCamerasAccess: isUserHaveCameraAccess(user), 

      userHaveAvailableTeamRefList: !!user?.userAvailableTeamRefIdList?.length || user?.teamRefAccess === UserTeamRefAccess.All,
      userHaveAnalyticsAccess: isUserHaveServerAnalyticsAccess(user),

      cameraIsProxy: isCameraAProxy(activeCameraData),

      curUserRole: user?.role,
    }

    for (let i = 0; i < baseMenuStructure.length; i += 1) {
      const menuElement = MenuElement.createFromMenuEl(baseMenuStructure[i], env);
      if (menuElement) {
        menuStructure.push(menuElement)
      }
    }

    return menuStructure;
  } catch (ignore) {
    return [];
  }
};

/// return link to open menuId
export const getMenuLink = (menuId: MenuID, dynamicIds?:string | string[]): string | undefined => {
  try {
    const baseMenuStructure: MenuEl[] = getMenuElStructure(intl().formatMessage);
    const dynamicIdArr: string[] = (dynamicIds) ? (Array.isArray(dynamicIds) ? dynamicIds : [dynamicIds]) : []
    return getMenuLinkInt(menuId, baseMenuStructure, dynamicIdArr);
  } catch (ignore) {
  }
  return undefined
};
const getMenuLinkInt = (menuId: MenuID, baseMenuStructure: MenuEl[], dynamicIds:string[]): string | undefined => {
  try {

    for (const item of baseMenuStructure) {
      if (item.id === menuId) {
        const outMenuId: string = (item.isDynamicPathId) ? ((dynamicIds.length > 0) ? dynamicIds[0] : (item.dynamicPathIdCache || "NoDynamicId")) : item.id;
        return `/${ outMenuId }`
      }
      else if (!!item.items?.length) {
        var newDynamicIds = [...dynamicIds];
        var outMenuId: string = item.id;
        if (item.isDynamicPathId) {
          const dynamicId = newDynamicIds.shift()
          outMenuId = dynamicId || item.dynamicPathIdCache || "NoDynamicId";
        }

        const childPath = getMenuLinkInt(menuId, item.items, newDynamicIds);
        if (childPath) {
          return `/${ outMenuId }${ childPath }`
        }
      }
    }
  } catch (ignore) {
  }
  return undefined
};

export type MenuElPathArr = (MenuEl | undefined)[] | undefined

/// return array with menuEl match defined path. if some element in path have Dynamicid then use value to define it like {"1234413-adf": "Some Name"}
/// if some element in path did nit match anything then used undefined.
/// path matched against real menu hierarhy so fake path will not work
export const getMenuArrayFromUrlPath = (path :string | undefined, dynamicIdTitlesDict?:{ [id: string]: string }): MenuElPathArr => {
  try {
    if (path) {
      const pathArr = path.split("/").filter((val) => (!!val.length));
      var outArr: MenuElPathArr = []
      var menuElArr: MenuEl[] | undefined = getMenuElStructure(intl().formatMessage);
      // const allMenuIdArr = Object.keys(MenuID)

      for (const val of pathArr) {
        const foundMenu: MenuEl | undefined = menuElArr?.find((menu) => ( menu.id === val));
        if (foundMenu) {
          outArr.push(foundMenu);
          menuElArr = foundMenu.items;
        }
        else {// menu not found -> try find with dynamic id
          if (MenuIDArr.find((id) => (id === val))) {// if this id exist in MenuIDs then we miss something. replace to undefined
            outArr.push(undefined);
          }
          else {
            const dynamicMenuArr = menuElArr?.filter((menu) => (menu.isDynamicPathId));
            if (dynamicMenuArr && dynamicMenuArr.length === 1) {// if only one deynamic id then match it
              var menuEl = {...dynamicMenuArr[0]}
              menuEl.dynamicPathIdCache = val;
              menuEl.title = dynamicIdTitlesDict?.[val];
              outArr.push(menuEl);
              menuElArr = menuEl.items;
            }
            else {
              outArr.push(undefined);
            }
          }
        }
      }
      return outArr;
    }
  } catch (ignore) {
  }
  return undefined
};

// create link from MenuElPathArr made by getMenuArrayFromUrlPath
export const getMenuLinkFromMenuArray = (menuElArr: MenuElPathArr | undefined, lastMenuId?: MenuID): string | undefined => {
  if (!menuElArr?.length) {
    return undefined
  }
  var outLink =""
  for (const menu of menuElArr) {
    outLink += "/" + ((!!menu?.isDynamicPathId) ? (menu?.dynamicPathIdCache || "NoDynamicId") : menu?.id);
    if (menu?.id === lastMenuId) {
      break
    }
  }
  return outLink;
}

export default getMenuStructure;
