// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { CameraTiltMetric } from '../../../../../../Data/Camera/Metrics/Collection'
import { useSingleMetricValue } from '../../../../../../Data/Camera/Metrics/MetricsValuesHook'
import { TiltSource } from './Types'

export function useCameraTilt(source: TiltSource): number | undefined {
    return useSingleMetricValue(CameraTiltMetric(source), 'ValuesDirectionsTilt')
}
