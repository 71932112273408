// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import SortOrderSelector, { SortOrderSelector_StoredValue } from '../../Components/_BaseUI/SortOrderSelector';
import GameArchiveItem from './GameArchiveItem';
import Order from '../../Data/_Networking/ReactQuery/Order';
import ButtonsInRowElement from '../../Components/_BaseUI/ButtonsInRowElement';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import AnalyticsGameArchive, { getGameTitle } from '../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import useGamesPagedData from '../../Data/Analytics/AnalyticsGameArchive/hook';
import useMutationGame, { ActionType, ArchiveForActionProps } from '../../Data/Analytics/AnalyticsGameArchive/fetch/useMutation';
import FieldSetDateComponent from '../../Components/_BaseUI/FieldSet/FieldSetDateComponent';
import { FieldSetInputType } from '../../Components/_BaseUI/FieldSet';
import Pagination from '../../Components/PaginationControl';
import LOCALIZATION from '../../Localization';
import { EMPTY_ARRAY } from '../../constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Grid } from '@mui/material';
import EditTitleSubtitlePopup, { EditTitleSubtitleFields } from '../../Components/_PopupControls/EditTitleSubtitlePopup';
import { AnalyticsType } from '../../Data/Analytics/AnalyticsTypes';
import { getResponseManyDataArrayDef } from '../../Data/_Networking/ReactQuery/ResponseMany';
import { useAnalyticsConfig } from '../../Data/Analytics/AnalyticsConfig/hook';
import AnalyticsGameMarkupIdPopup from '../AnalyticsGameMarkupIdPopup';
import { removeAllQueryCache } from '../../AppProviders';
import AnalyticsGameStartAnalyticsPopup from '../AnalyticsGameStartAnalyticsPopup';
import { getMenuLink } from '../../Menu/Data/MenuStructure';
import { useNavigate, useNavigationType, NavigationType } from 'react-router-dom';
import { MenuID } from '../../Menu/Data/MenuElement';
import TeamRefWithImageSelect, { TeamRefWithImageSelect_StoredValue } from '../../Components/_BaseUI/TeamRefWithImageSelect';
import FieldSetSelectComponent, { FieldSetSelectComponent_StoredValue } from '../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import { ANALYTICS_ANALYTICS_STATUS_ARR, AnalyticsAnalyticsStatus, analyticsStatusNameForFilter } from '../../Data/Analytics/AnalyticsStatuses';
import { useEffectAfterMount } from '../../Tools/useEffectAfterMount';

export type GamesArchiveContentProps = Readonly<{
  date: string | null;
  onChangeDate: (date: string | null) => void;
} & WrappedComponentProps>;

export type SortBy = Readonly<{
  date: string;
}>;

export const sessionStoreKey = "GamesArchive";

function storedPageNumber(navigationType: NavigationType): number {
  try {
    if (sessionStoreKey?.length) {// if sessionStoreKey enabled then recover stored value from session storage only if no active value
      if (navigationType === NavigationType.Push) { // if direct open page = PUSH then always use page 0 and reset stored value
        window.sessionStorage.setItem(sessionStoreKey + "-pageNumber", "0");
        return 0;
      }
      else {
        const storedValue = window.sessionStorage.getItem(sessionStoreKey + "-pageNumber");
        if (storedValue !== null) {
          return parseInt(storedValue, 10);
        }
      }
    }
  } catch(ignore) {}
  return 0;
}

const GamesArchiveContent: React.FC<GamesArchiveContentProps> = (props: GamesArchiveContentProps) => {
  const itemsOnPageCount = () => ( 9 )

  const { date } = props;
  const navigateType = useNavigationType();

  const [page, setPage] = React.useState(() => (storedPageNumber(navigateType)) );
  const [limit] = React.useState( itemsOnPageCount() );
  const [total, setTotal] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>(() => (SortOrderSelector_StoredValue(sessionStoreKey) || Order.DESC));
  const [filterByExternalTeamID, setFilterByExternalTeamID] = React.useState<string|undefined>(() => (TeamRefWithImageSelect_StoredValue(sessionStoreKey)));
  const [filterByAnalyticsStatus, setFilterByAnalyticsStatus] = React.useState<string|undefined>(() => (FieldSetSelectComponent_StoredValue(sessionStoreKey)));
  const { mutateAsync: mutateConfig } = useMutationGame();

  const navigate = useNavigate();

  const [renameItem, setRenameItem] = React.useState<AnalyticsGameArchive | undefined>(undefined);
  const [showMarkupIdPopupForGame, setShowMarkupIdPopupForGame] = React.useState<AnalyticsGameArchive | undefined>(undefined);
  const [showStartAnalyticsPopupForGame, setShowStartAnalyticsPopupForGame] = React.useState<AnalyticsGameArchive | undefined>(undefined);

  const {
    status,
    data: archive,
    handleFlushData,
  } = useGamesPagedData({
    page,
    limit,
    order,
    filterByDate: date || undefined,
    filterByAnalyticsStatus: (filterByAnalyticsStatus !== "none") ? filterByAnalyticsStatus : undefined,
    filterByTeamExternalId: (filterByExternalTeamID !== "none") ? filterByExternalTeamID : undefined,
    orderBy: 'date',
  });

  const {
    status: analyticsConfigStatus,
    data: analyticsConfig
  } = useAnalyticsConfig({});

  useEffectAfterMount(() => { // use AfterMount to prevent race when view mounted
    try {
      window.sessionStorage.setItem(sessionStoreKey + "-pageNumber", page+"");
    } catch(ignore) {}
  }, [page]);

  const handleChangeDate = (date: string|null) => {
    props.onChangeDate(date)
    setPage(0);
  };

  const handleDelete = async (IDs: string[]) => {
    try {
      const archives: ArchiveForActionProps[] = (getResponseManyDataArrayDef(archive?.data, []))
        .filter((item: AnalyticsGameArchive) => (IDs.includes(item.id)))
        .map((item: AnalyticsGameArchive) => ({
          id: item.id,
          archive: item,
        }));

      await mutateConfig(
        {
          archive: archives.length > 0 ? archives[0] : undefined,
          type: ActionType.Delete,
        },
        {
          onSuccess: () => {
            handleFlushData();
          },
        }
      );
    } catch (error) {
      alert(error);
    }
  };
  const handleItemEditClicked = (clickedItemId: string, item?: AnalyticsGameArchive) => {
    const menuLink = getMenuLink(MenuID.AnalyticsVideoArchiveDetails, [item?.record_id ?? ""]);
    if (menuLink) {
      navigate(menuLink);
    }
  };
  const handleItemRenameClicked = (clickedItemId: string, item?: AnalyticsGameArchive) => {
    setRenameItem(item);
  };
  const handleItemDeleteClicked = (clickedItemStartAt: string) => {
    if (clickedItemStartAt && clickedItemStartAt.length !== 0) {
      handleDelete([clickedItemStartAt]);
    }
  };

  const handleOrderByChanged = (orderBy: keyof SortBy, order: Order) => {
    setOrder(order);
  };
  const renderPagination = () => {
    if (total) {
      const totalPages = Math.ceil(total / limit);

      if (totalPages > 0) {
        return (
          <Pagination
            totalPages={ totalPages }
            currentPage={ page }
            onClick={ setPage }
          />
        );
      }
    }

    return <></>;
  };

  const filterPriorityOptions = ["none", ...ANALYTICS_ANALYTICS_STATUS_ARR].map((status) => {
    return ({
      id: status,
      name: (status === "none") ? props.intl.formatMessage({ id: LOCALIZATION.filter_no_filter}) : analyticsStatusNameForFilter(status as AnalyticsAnalyticsStatus),
    });
  });

  const handleFilterPriorityChange = (value: string) => {
    setPage(0);
    setFilterByAnalyticsStatus((value === "none") ? undefined : value as AnalyticsAnalyticsStatus);
  };

  const buttons1 = [
    <SortOrderSelector<SortBy>
      sessionStoreKey={ sessionStoreKey } 
      key="sort"
      orderBy={ [ 'date' ] }
      orderByTitle={ [ props.intl.formatMessage({ id: LOCALIZATION.sort_by_time }) ] }
      selectedOrderBy={ 'date' }
      selectedOrder={ order }
      onChange={ handleOrderByChanged }
    />,
    {
      space : 10 ,
    }, 
    <FieldSetDateComponent
      key="FieldSetDateComponent"
      labelText={ props.intl.formatMessage({ id: LOCALIZATION.filter_by_date }) }
      input={ {
        sessionStoreKey: sessionStoreKey,
        type: FieldSetInputType.Date,
        noWrapper: true,
        allowRemoveDate: true,
        noValueText: props.intl.formatMessage({ id: LOCALIZATION.filter_no_filter }),
        dateFormat: 'YYYY-MM-DD',
        value: {
          date: (date || null),
        },
        onChangeDate: handleChangeDate,
      } }
    />,
    {
      space : 10 ,
    }, 
    <TeamRefWithImageSelect 
      sessionStoreKey={ sessionStoreKey }
      label={ props.intl.formatMessage({ id: LOCALIZATION.filter_by_team }) }
      noneValueText= { props.intl.formatMessage({ id: LOCALIZATION.filter_no_filter }) }
      selectedExternalTeamID={ filterByExternalTeamID }
      onSelect={(teamId) => {
        setPage(0);
        setFilterByExternalTeamID(teamId || undefined);
      }} 
    />,
    {
      space : 10 ,
    }, 
    <FieldSetSelectComponent
      input={ {
        sessionStoreKey: sessionStoreKey,
        showResetIfSelected: true,
        type: FieldSetInputType.Select,
        label: props.intl.formatMessage({ id: LOCALIZATION.filter_by_analytics_status }),
        value: filterByAnalyticsStatus || "none",
        options: filterPriorityOptions,
        onChange: handleFilterPriorityChange,
      } }
    />,
    {
      space :-1 ,
    }, 
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.refresh }),
      iconButton: <RefreshIcon /> ,
      onClick: handleFlushData,
    },
  ];

  const buttons2 = [
    {
      space :-1 ,
    }, 
    renderPagination()
  ];

  React.useEffect(() => {
    return handleFlushData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (archive && archive?.meta?.pagination && !isNaN(archive?.meta?.pagination?.total)) {
      setTotal(archive.meta.pagination.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archive?.meta?.pagination?.total]);


  const handleArchiveEdit = async (editedItem: EditTitleSubtitleFields) => {
    try {
      await mutateConfig(
        {
          archive:{
            id: editedItem.id || "",
            game: { 
              id: editedItem.id,
              type: renameItem?.type || AnalyticsType.Game,
              title: editedItem.title,
              subtitle: editedItem.subtitle,
             },
          },
          type: ActionType.Patch,
        }, {
          onSuccess: () => {
            handleFlushData();
            handleCloseRenamePopup();
          },
        },
      );
    } catch (error) {
      alert(error);
      handleFlushData();
      handleCloseRenamePopup();
    }
  };

  const handleCloseRenamePopup = () => {
    setRenameItem(undefined);
  };

  const handleStartTeamMarkupClicked = (clickedItemId: string, item?: AnalyticsGameArchive) => {
    setShowMarkupIdPopupForGame(item);
  };
  const handleStartAnalyticsClicked = (clickedItemId: string, item?: AnalyticsGameArchive) => {
    setShowStartAnalyticsPopupForGame(item);
  };

  const handleMarkupIdSetSuccess = async (game: AnalyticsGameArchive) => {
    try {
      setShowMarkupIdPopupForGame(undefined);
      removeAllQueryCache();
    } catch (error) {
      alert(error);

      handleFlushData();
      handleCloseMarkupIdPopup();
    }
  };
  const handleCloseMarkupIdPopup = () => {
    setShowMarkupIdPopupForGame(undefined);
  };

  const handleStartAnalyticsSuccess = async (game: AnalyticsGameArchive | undefined) => {
    try {
      setShowStartAnalyticsPopupForGame(undefined);
      handleFlushData();
    } catch (error) {
      alert(error);

      handleFlushData();
      handleCloseMarkupIdPopup();
    }
  };
  const handleStartAnalyticsPopup = () => {
    setShowStartAnalyticsPopupForGame(undefined);
  };

  const renderItem = (item: AnalyticsGameArchive) => (
    <GameArchiveItem
      id={ item.id }
      hideDate={ (!!props.date) }
      item={ item }
      analyticsConfig={ analyticsConfig }
      onEdit={ handleItemEditClicked }
      onRename={ handleItemRenameClicked }
      onDelete={ handleItemDeleteClicked }
      onStartTeamMarkup={ handleStartTeamMarkupClicked }
      onStartAnalytics={ handleStartAnalyticsClicked }
      intl={ props.intl }
    />
  );

  const renderPanel = () => (
    <div>
      <Box sx= {{
        marginBottom : '15px'
      }}
      >
        <ButtonsInRowElement
          id = "buttonsRow1"
          buttons={ buttons1 }
        />
      </Box>
      <ButtonsInRowElement
        id = "buttonsRow2"
        buttons={ buttons2 }
      />
    </div>
  );

  return (
    <>
      { renderPanel() }
      {
        (status === 'loading' || analyticsConfigStatus === 'loading' || archive === undefined) ?
          <>
          <br /><br />         
          <Spinner
            isHidden={ false }
          /><br /><br />
          </>
          :
          (Array.isArray(archive?.data)) ?
          <Box sx={{ padding: '30px 0' }}>
            <Grid
              container
              spacing={ '42px' }
              rowSpacing={ '48px' }
            >
              { (archive?.data || EMPTY_ARRAY).map(renderItem) }
            </Grid>
          </Box>
          :
          <>
          <br /><br />         
          <Grid
           sx={ {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          } }
          >
          { props.intl.formatMessage({ id: LOCALIZATION.page_video_archive_empty_list }) }
          </Grid>
          <br /><br />
          </>
      }
      {/* { (status !== 'loading' && archive !== undefined && (archive?.data || EMPTY_ARRAY).length !== 0) && renderPanel() } */}
      {
        (renameItem) &&
          <EditTitleSubtitlePopup
            popupTitle={ props.intl.formatMessage({ id: LOCALIZATION.rename }) }
            item={ {
              id: renameItem.id,
              title: getGameTitle(renameItem),
              subtitle: renameItem.subtitle,
            } }
            onSubmit={ handleArchiveEdit }
            onClose={ handleCloseRenamePopup }
          />
      }
      {
        (showMarkupIdPopupForGame) &&
          <AnalyticsGameMarkupIdPopup
            game={ showMarkupIdPopupForGame }
            onSubmit={ handleMarkupIdSetSuccess }
            onClose={ handleCloseMarkupIdPopup }
          />
      }
      {
        (showStartAnalyticsPopupForGame) &&
          <AnalyticsGameStartAnalyticsPopup
            game={ showStartAnalyticsPopupForGame }
            onSubmit={ handleStartAnalyticsSuccess }
            onClose={ handleStartAnalyticsPopup }
          />
      }
    </>
  );
};


export default injectIntl(GamesArchiveContent);
