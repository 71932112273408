// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

enum Order {
  ASC = 'asc',
  DESC = 'desc',
}


export default Order;
