// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { ChartingColors, Colors } from '../../../Configuration/Styles/Colors'
import { JetsonId } from '../Jetson/Types'
import { MetricName, MetricTypeInterface, SensorPlaceID, TemperatureControllerID } from './Types'
import { JetsonTools } from '../../../Tools/Tools'
import { TiltSource } from '../../../Pages/HardwarePage/Cards/Directions/Components/CameraTilt/Types'
import { intl } from '../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../Localization';


export const PowerInputVoltageMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_voltage_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.supply }) },
    unit: 'V',
    dataKey: 'piv',
    color: Colors.cicrcleRed,
    identificationParameters: {
        filter_name: 'Voltage',
        filter_sensorName: 'INA219'
    }
}

// MARK: PI

export const PiCpuLoadMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_cpu_load_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.cpu }) },
    unit: '%',
    dataKey: 'pcl',
    color: ChartingColors.load1,
    identificationParameters: {
        filter_name: 'CpuUtilization',
        filter_sensorPlace: SensorPlaceID.Pi
    }
}

export const PiCpuTemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_cpu_temp_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.cpu }) },
    unit: '˚C',
    dataKey: 'pct',
    color: ChartingColors.temperature1,
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: SensorPlaceID.Pi,
        filter_sensorName: 'linuxCPUTemp'
    }
}

// MARK: Jetsons
export namespace Jetson {
    export function CpuTemperatureMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_cpu_temp_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.cpu }) },
            unit: '˚C',
            dataKey: JetsonTools.chooseObject(id, ['j1ct', 'j2ct']),
            color: ChartingColors.temperature1,
            identificationParameters: {
                filter_name: 'Temperature',
                filter_sensorPlace: id,
                filter_sensorName: 'linuxCPUTemp'
            }
        }
    }

    export function GpuTemperatureMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_gpu_temp_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.gpu }) },
            unit: '˚C',
            dataKey: JetsonTools.chooseObject(id, ['j1gt', 'j2gt']),
            dash: '6 3',
            color: ChartingColors.temperature2,
            identificationParameters: {
                filter_name: 'Temperature',
                filter_sensorPlace: id,
                filter_sensorName: 'linuxGPUTemp'
            }
        }
    }

    export function BoardTemperatureMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_board_temp_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.board }) },
            unit: '˚C',
            dataKey: JetsonTools.chooseObject(id, ['j1bt', 'j2bt']),
            color: ChartingColors.temperature3,
            identificationParameters: {
                filter_name: 'Temperature',
                filter_sensorPlace: id,
                filter_sensorName: 'linuxBoardTemp'
            }
        }
    }

    export function CpuLoadMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_cpu_load_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.cpu }) },
            unit: '%',
            dataKey: JetsonTools.chooseObject(id, ['j1cl', 'j2cl']),
            color: ChartingColors.load1,
            identificationParameters: {
                filter_name: 'CpuUtilization',
                filter_sensorPlace: id
            }
        }
    }

    export function GpuLoadMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_gpu_load_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.gpu }) },
            unit: '%',
            dataKey: JetsonTools.chooseObject(id, ['j1gl', 'j2gl']),
            color: ChartingColors.load3,
            identificationParameters: {
                filter_name: 'GpuUtilization',
                filter_sensorPlace: id
            }
        }
    }

    export function VolumeMetric(id: JetsonId, isRoot: boolean): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.used_volume }) + (!isRoot ? ` (${intl().formatMessage({ id: LOCALIZATION.ssd })})` : '') },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.volume }) },
            unit: '%',
            dataKey: JetsonTools.chooseObject(id, [isRoot ? 'j1rv' : 'j1sv', isRoot ? 'j2rv' : 'j2sv']),
            color: ChartingColors.dontmatter,
            identificationParameters: {
                filter_name: 'Volume',
                filter_sensorPlace: id,
                filter_sensorName: isRoot ? '/' : '/mnt/ssd'
            }
        }
    }

    export function TotalPowerConsumptionMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.total_power }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.total }) },
            unit: 'W',
            dataKey: JetsonTools.chooseObject(id, ['j1tpc', 'j2tpc']),
            color: ChartingColors.power1,
            identificationParameters: {
                filter_name: 'Power',
                filter_sensorPlace: id,
                filter_sensorName: 'jetsonPowerTotal'
            }
        }
    }

    export function CpuPowerConsumptionMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_cpu_power_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.cpu }) },
            unit: 'W',
            dataKey: JetsonTools.chooseObject(id, ['j1cpupc', 'j2cpupc']),
            color: ChartingColors.power2,
            identificationParameters: {
                filter_name: 'Power',
                filter_sensorPlace: id,
                filter_sensorName: 'jetsonCPU'
            }
        }
    }

    export function CpuVoltageMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_cpu_voltage_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.cpu }) },
            unit: 'V',
            dataKey: JetsonTools.chooseObject(id, ['j1cpuv', 'j2cpuv']),
            color: ChartingColors.voltage1,
            identificationParameters: {
                filter_name: 'Voltage',
                filter_sensorPlace: id,
                filter_sensorName: 'jetsonCPU'
            }
        }
    }

    export function GpuPowerConsumptionMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_gpu_power_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.gpu }) },
            unit: 'W',
            dataKey: JetsonTools.chooseObject(id, ['j1gpupc', 'j2gpupc']),
            color: ChartingColors.power3,
            dash: '6 3',
            identificationParameters: {
                filter_name: 'Power',
                filter_sensorPlace: id,
                filter_sensorName: 'jetsonGPU'
            }
        }
    }

    export function SocPowerConsumptionMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_soc_power_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.soc }) },
            unit: 'W',
            dataKey: JetsonTools.chooseObject(id, ['j1socpc', 'j2socpc']),
            color: ChartingColors.power4,
            dash: '12 3',
            identificationParameters: {
                filter_name: 'Power',
                filter_sensorPlace: id,
                filter_sensorName: 'jetsonSOC'
            }
        }
    }

    export function Sys5VPowerConsumptionMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_sys5v_power_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.sys5v }) },
            unit: 'W',
            dataKey: JetsonTools.chooseObject(id, ['j1s5vpc', 'j2s5vpc']),
            color: ChartingColors.power5,
            identificationParameters: {
                filter_name: 'Power',
                filter_sensorPlace: id,
                filter_sensorName: 'jetsonSYS5V'
            }
        }
    }

    export function Sys5VVoltageMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_sys5v_voltage_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.sys5v }) },
            unit: 'V',
            dataKey: JetsonTools.chooseObject(id, ['j1s5vv', 'j2s5vv']),
            color: ChartingColors.voltage2,
            identificationParameters: {
                filter_name: 'Voltage',
                filter_sensorPlace: id,
                filter_sensorName: 'jetsonSYS5V'
            }
        }
    }

    export function CVPowerConsumptionMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_cv_power_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.cv }) },
            unit: 'W',
            dataKey: JetsonTools.chooseObject(id, ['j1cvpc', 'j2cvpc']),
            color: ChartingColors.power6,
            dash: '18 3',
            identificationParameters: {
                filter_name: 'Power',
                filter_sensorPlace: id,
                filter_sensorName: 'jetsonCV'
            }
        }
    }

    export function VddRQConsumptionMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_vddrq_power_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.vddrq }) },
            unit: 'W',
            dataKey: JetsonTools.chooseObject(id, ['j1vddpc', 'j2vddpc']),
            color: ChartingColors.power7,
            identificationParameters: {
                filter_name: 'Power',
                filter_sensorPlace: id,
                filter_sensorName: 'jetsonVDDRQ'
            }
        }
    }

    export function VoltageMetric(id: JetsonId): MetricTypeInterface {
        return {
            get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_voltage_name }) },
            get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.voltage }) },
            unit: 'V',
            dataKey: JetsonTools.chooseObject(id, ['j1v', 'j2v']),
            color: ChartingColors.voltage3,
            identificationParameters: {
                filter_name: 'Voltage',
                filter_sensorPlace: id,
                filter_sensorName: 'MCP3008'
            }
        }
    }
}

// MARK: Other
export const InternalTemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_internal_temperature_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.internal }) },
    unit: '˚C',
    dataKey: 'it',
    color: ChartingColors.temperature1,
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: SensorPlaceID.CameraCase
    }
}

export const DewPointTemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_dew_point_temperature_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.chart_dew_point_temperature_name }) },
    unit: '˚C',
    dataKey: 'dp',
    color: ChartingColors.dewPointTemperature,
    identificationParameters: {
        filter_name: 'DewPoint',
        filter_sensorPlace: SensorPlaceID.CameraCase
    }
}

export const OutdoorTemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_outdoor_temperature_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.outdoor }) },
    unit: '˚C',
    dataKey: 'ot',
    color: ChartingColors.temperature2,
    dash: '6 3',
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: SensorPlaceID.Outdoor
    }
}

export const InternalHumidityMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_internal_humidity_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.internal }) },
    unit: '%',
    dataKey: 'ih',
    color: ChartingColors.humidity,
    identificationParameters: {
        filter_name: 'Humidity',
        filter_sensorPlace: SensorPlaceID.CameraCase
    }
}

export const DCTemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_dc_temperature_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.dc }) },
    unit: '˚C',
    dataKey: 'dct',
    dash: '12 3',
    color: ChartingColors.temperature3,
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: SensorPlaceID.DC
    }
}

export const CaseHeaterTemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_case_heater_temp_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.heater }) },
    unit: '˚C',
    dataKey: 'cht',
    color: ChartingColors.temperature4,
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: TemperatureControllerID.Heater
    }
}

export const CaseHeater1TemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_case_heater1_temp_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.heater1 }) },
    unit: '˚C',
    dataKey: 'cht1',
    color: ChartingColors.temperature4,
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: TemperatureControllerID.Heater1
    }
}

export const CaseHeater2TemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_case_heater2_temp_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.heater2 }) },
    unit: '˚C',
    dataKey: 'cht2',
    color: ChartingColors.temperature4,
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: TemperatureControllerID.Heater2
    }
}

export const CaseHeater3TemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_case_heater3_temp_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.heater3 }) },
    unit: '˚C',
    dataKey: 'cht3',
    color: ChartingColors.temperature4,
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: TemperatureControllerID.Heater3
    }
}

export const CaseHeater4TemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_case_heater4_temp_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.heater4 }) },
    unit: '˚C',
    dataKey: 'cht4',
    color: ChartingColors.temperature4,
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: TemperatureControllerID.Heater4
    }
}

export const CaseHeaterCam1TemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_case_heater_cam1_temp_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.heater_cam1 }) },
    unit: '˚C',
    dataKey: 'chtc1',
    color: ChartingColors.temperature4,
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: TemperatureControllerID.HeaterCAM1
    }
}

export const CaseHeaterCam2TemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_case_heater_cam2_temp_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.heater_cam2 }) },
    unit: '˚C',
    dataKey: 'chtc2',
    color: ChartingColors.temperature4,
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: TemperatureControllerID.HeaterCAM2
    }
}

export const CaseHeaterCam3TemperatureMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.chart_case_heater_cam3_temp_name }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.heater_cam3 }) },
    unit: '˚C',
    dataKey: 'chtc3',
    color: ChartingColors.temperature4,
    identificationParameters: {
        filter_name: 'Temperature',
        filter_sensorPlace: TemperatureControllerID.HeaterCAM3
    }
}

// MARK: Directions
export function CameraTiltMetric(source: TiltSource): MetricTypeInterface {
    const filterName: MetricName = (() => {
        switch (source) {
            case 'pitch':
                return 'Pitch'
            case 'roll':
                return 'Roll'
        }
    })()

    return {
        get name(): string { return intl().formatMessage({ id: LOCALIZATION.tilt_angle }) },
        get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.tilt }) },
        unit: '˚',
        dataKey: 'cta',
        color: ChartingColors.dontmatter,
        identificationParameters: {
            filter_name: filterName
        }
    }
}

export const CameraAzymuthMetric: MetricTypeInterface = {
    get name(): string { return intl().formatMessage({ id: LOCALIZATION.azymuth }) },
    get shortName(): string { return intl().formatMessage({ id: LOCALIZATION.azymuth }) },
    unit: '˚',
    dataKey: 'caz',
    color: ChartingColors.dontmatter,
    identificationParameters: {
        filter_name: 'Yaw' // В терминах наввигации долженг быть Pitch, но у нас датчик похоже лежит не так. Roll подходит
    }
}
