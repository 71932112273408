// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { clone } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { ItemStreamProps } from '../index';
import FieldSet, { FieldSetInputType } from '../../../../../Components/_BaseUI/FieldSet';
import { useFetchStreamingSettingsProfileOptions } from '../../../../../Data/NapiStreamingProfileOptions/hook';
import FieldSetWrapper from '../FieldSetWrapper';
import LOCALIZATION from '../../../../../Localization';
import { useCameraSettings } from '../../../../../Data/Camera/HWCameraSettings/hook/Hooks';


type Props = Readonly<{
  item: ItemStreamProps;
  onChange: (item: ItemStreamProps) => void;
} & WrappedComponentProps>;

const MainStreamFps: React.FC<Props> = (props: Props) => {
  useFetchStreamingSettingsProfileOptions();

  const cameraSettings = useCameraSettings(true);

  const cameraFps = cameraSettings?.streamingState?.mainConfig?.['frame-rate'] || 60;

  const optionsArr = () => {
    var arr = [];
    for (let rate = 1; rate <= 4; rate ++) {
        arr.push(            {
            id: `${rate}`,
            name: `${cameraFps / rate}`,
        })
    }
    return arr;
  }

  const handleChangeSource = (value: string) => {
    const encoder = clone(props.item.encoder) || {};

    const rate = parseInt(value);

    if (rate <= 1) {
        delete encoder['rate-divisor']; // because 0 and 1 mean did not divide fps so remove value. this defaults to 0
    }
    else {
        encoder['rate-divisor'] = rate;
    }

    props.onChange({
      ...props.item,
      encoder,
    });
  };

  const currentOption = () => {
    const rate = props.item.encoder?.['rate-divisor'] || 1
    return `${rate}`
  }

  return (
    <FieldSetWrapper>
      <FieldSet
        key={ 'OperatorFps' }
        label={ `${ props.intl.formatMessage({ id: LOCALIZATION.stream_fps }) }:` }
        input={ {
          type: FieldSetInputType.Select,
          value: currentOption(),
          options: optionsArr(),
          onChange: handleChangeSource,
        } }
      />
    </FieldSetWrapper>
  );
};


export default injectIntl(MainStreamFps);
