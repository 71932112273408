// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

export enum UserType {
  Local = 'local',
  Server = 'server',
}

export enum UserState {
  Valid = 'valid',
  NeedValidate = 'needValidate',
  Invalid = 'invalid',
}

export enum UserCameraAccess {
  Local = 'local',        // only one local camera. used with local accounts
  All = 'all',
  None = 'none',
  List = 'list',         // specific list defined in cameraIdList[],
}

export enum UserTeamRefAccess {
  All = 'all',
  None = 'none',
  List = 'list',         // specific list defined in teamRefIdList[],
  Guest = 'guest',         // used when need not show any teamRef but allow to access video shared in analytics
}

export enum UserRole {
  Minimal = 'minimal',
  User = 'user',
  Admin = 'admin',
  SuperAdmin = 'super_admin',
}

export const ROLES_TO_ACCESS_LEVEL: { [key in UserRole]?: number } = {
  [UserRole.Minimal]: 100,
  [UserRole.User]: 20,
  [UserRole.Admin]: 10,
  [UserRole.SuperAdmin]: 1,
};

export const userRoleAccessLevel = (role?: UserRole) : number | undefined => {
  if (!role) {
    return undefined
  }
  const access = ROLES_TO_ACCESS_LEVEL[role];
  return access
}

export const isUserRoleMinimum = (role?: UserRole, minimumRole?: UserRole) : boolean => {
  if (!role || !minimumRole) {
    return false
  }
  const userAccess = ROLES_TO_ACCESS_LEVEL[role] || 2000;
  const minAccess = ROLES_TO_ACCESS_LEVEL[minimumRole] || 1000;

  return userAccess <= minAccess
}

export const isUserAccessLeveleMinimum = (accessLevel?: UserAccessLevel, minimumRole?: UserRole) : boolean => {
  if (accessLevel === undefined || !minimumRole) {
    return false
  }
  const userAccess = accessLevel;
  const minAccess = ROLES_TO_ACCESS_LEVEL[minimumRole] || 1000;

  return userAccess <= minAccess
}

export const isUser1RoleBetterUser2Role = (role1?: UserRole, role2?: UserRole) : boolean => {
  const userAccess1 = userRoleAccessLevel(role1);
  const userAccess2 = userRoleAccessLevel(role2);
  if (userAccess1 === undefined || userAccess2 === undefined) {
    return false
  }
  return userAccess1 < userAccess2;
}

export const isUser1AccessLeveleBetterUser2AccessLevele = (accessLevel1?: UserAccessLevel, accessLevel2?: UserAccessLevel) : boolean => {
  if (accessLevel1 === undefined || accessLevel2 === undefined) {
    return false
  }
  return accessLevel1 < accessLevel2;
}

export enum UserAccessLevel {
  Minimal = 100,
  User = 20,
  Admin = 10,
  SuperAdmin = 1,
  Root = 0,       // у super_admin уровень 1 по умолчанию. но есть скрытый ото всех юзер root у которого уровень 0.так как юзеры с более низким accessLevel не видят в списках юзеров с  более высоким, то обычные super_admin не увидят root. а он нам нужен для сервисного обслуживания если что вдруг снесут всех юзеров или пароли забудут
}

interface UserWithoutID {
  name: string;
  info: string;
  avatar?: string;
  type: UserType;
  state: UserState;
  role: UserRole;
  login: string;
  cameraAccess: UserCameraAccess;
  teamRefAccess: UserTeamRefAccess;
  accessLevel: UserAccessLevel;           // усложнение тольк. просто от accesslevel я уже бекенд написал и так немьше непонтного кода
/*
userAvailableCameraIdList это рассчитываемый список всех cameraId которые тебе доступны.
если у тебя cameraAccess=all то это просто все камеры что есть.
а если cameraAccess=list то это то что указано в cameraIdList
 */
  cameraIdList?: string[];
  activeCameraId?: string[];
  userAvailableCameraIdList: string[];

  activeTeamRefId?: string;
  activeTeamRefExternalId?: string;
  teamRefIdList?: string[];
  userAvailableTeamRefIdList?: string[]; // list of teamRefId avaliable to this used. return all teamRefId if root user
  userAvailableTeamRefExternalIdList?: string[]; // list of teamRef External Id avaliable to this used. return all teamRefId if root user
  password?: string;                      // При редактировании/добавлении пользователя
}


export default UserWithoutID;
