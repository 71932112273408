// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Card from '../../../Components/_Layout/Card';
import Popup from '../../../Components/_Layout/Popup';
import Spinner from '../../../Components/_BaseUI/Spinner/Spinner';
import Camera, { CAMERA_MODELS_ARR, CameraExtraJson, CameraModelType, NewCamera } from '../../../Data/Camera/ActiveCamera/Camera';
import LOCALIZATION from '../../../Localization';
import isSuperAdmin from '../../../Data/AccountUsers/tools/isSuperAdmin';
import FieldSet, { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';
import AuthorisationManager from '../../../Data/Auth/AuthorisationManager';
import useMutationCamera, { ActionType } from '../../../Data/Camera/ActiveCamera/hook/useMutation';
import { SportType } from '../../../Data/EPG/EpgSchedule/EventWithoutID';
import { localizedSportNameById, SPORT_ID_ARR } from '../../EventsPage/AddEvent/helpers';
import { DEFAULT_SPORT_TYPE } from '../../../Data/Camera/ActiveCamera/fetch';


type Props = Readonly<{
  isServer: boolean;
  type: 'attach' | 'edit';
  camera?: Camera;
  onPostedSuccess: () => void;
  onClose: () => void;
} & WrappedComponentProps>;


const initialState = (camera?: Camera) => (): Camera | NewCamera => ({
  ...camera,
  cameraId: camera?.cameraId || '',
  name: camera?.name || '',
  addressMain: camera?.addressMain || '',
  addressJ1: camera?.addressJ1 || '',
  addressJ2: camera?.addressJ2 || '',
  teqInfo: camera?.teqInfo || '',
  notes: camera?.notes || '',
  extraJson: camera?.extraJson || '',
});

const CameraPopup: React.FC<Props> = (props: Props) => {
  const [saLogin, setAaLogin] = React.useState<string>("");
  const [saPassword, setSaPassword] = React.useState<string>("");
  const [camera, setCamera] = React.useState<Camera | NewCamera>(initialState(props.camera));
  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);
  const { user } = AuthorisationManager.shared;
  // Accounts with super_admin access can change all fields.
  // Admin and user accounts can change only name and notes.
  const isSuperAdminUser = isSuperAdmin(user);
  const { mutateAsync: mutateCamera } = useMutationCamera();

  const isAddingNewCamera = (props.type === 'attach');

  const isCameraNameEditInError = () => {
    return !camera.name || camera.name.length === 0
  }

  const isCameraIpAddressEditInError = () => {
    return (isSuperAdminUser) && (!camera.addressMain || camera.addressMain.length === 0)
  }

  const isAttachUserLoginEditInError = () => {
    return !saLogin || saLogin.length === 0
  }

  const isAttachUserPasswordEditInError = () => {
    return !saPassword || saPassword.length === 0
  }

  const getCameraModeType = (): CameraModelType => {
    if (camera?.extraJson) {
      const extraJson = JSON.parse(camera.extraJson) as CameraExtraJson;
      return extraJson?.cameraModel || CameraModelType.Cybercamera;
    }
    return CameraModelType.Cybercamera
  };

  const getCameraDefaultSportType = (): SportType => {
    if (camera?.extraJson) {
      const extraJson = JSON.parse(camera.extraJson) as CameraExtraJson;
      return extraJson?.defaultSportType || DEFAULT_SPORT_TYPE;
    }
    return DEFAULT_SPORT_TYPE
  };

  const isApplyEnabled = ((!props.isServer) || 
  (// attach
    isAddingNewCamera &&
    !isCameraIpAddressEditInError() && // !isCameraNameEditInError() && name not need when add
    !isAttachUserLoginEditInError() && !isAttachUserPasswordEditInError()
  )||
  (// edit
    !isAddingNewCamera &&
    !isCameraNameEditInError() && !isCameraIpAddressEditInError()
  )
  );


  const handleChangeCameraField = (fieldName: keyof Camera) => (value: string): void => {
    setCamera({
      ...camera,
      [fieldName]: value,
    });
  }

  const handleChangeCameraModelField = (value: string) => {
    const extraJson: CameraExtraJson = ((camera?.extraJson) ? JSON.parse(camera.extraJson) as CameraExtraJson : {}) || {};
    extraJson.cameraModel = value as CameraModelType || CameraModelType.Cybercamera;
    const extraJsonStr =  JSON.stringify(extraJson)
    
    setCamera({
      ...camera,
      extraJson: extraJsonStr,
    });
  }

  const handleChangeCameraDefaultSportType = (value: string) => {
    const extraJson: CameraExtraJson = ((camera?.extraJson) ? JSON.parse(camera.extraJson) as CameraExtraJson : {}) || {};
    extraJson.defaultSportType = value as SportType || SportType.Football;
    const extraJsonStr =  JSON.stringify(extraJson)
    
    setCamera({
      ...camera,
      extraJson: extraJsonStr,
    });
  }

  const handleSubmit = async () => {
    try {
      if (isApplyEnabled) {

        setIsLoaderShowing(true);
        await mutateCamera({
          camera,
          type: (isAddingNewCamera) ? ActionType.Add : ActionType.Patch,
          password: saPassword,
          login: saLogin,
        }, {
          onSuccess: props.onPostedSuccess,
        });
      }
    } catch (error) {
      alert(error);
      setIsLoaderShowing(false);
    }
  };

  return (
    <Popup
      isWide
      headerTitle={ props.intl.formatMessage({ id: (isAddingNewCamera) ? LOCALIZATION.add : LOCALIZATION.edit }) }
      isApplyDisabled={ (!isApplyEnabled || isLoaderShowing) }
      isCloseDisabled={ isLoaderShowing }
      onApply={ handleSubmit }
      onClose={ props.onClose }
    >
      <Grid
        item
        xs={ 12 }
      >
        <Card>
          {
            (isLoaderShowing) ?
            <>
            <br /><br /><Spinner /><br /><br />
            </>
              :
              <form
                action={ window.location.href }
                onSubmit={ handleSubmit }
              >
                { (!isAddingNewCamera || !props.isServer) && // when add camera name get from camera api. next user can edit it
                <FieldSet
                  label={ props.intl.formatMessage({ id: LOCALIZATION.camera_name }) }
                  hideSeparator={!props.isServer}
                  input={ {
                    type: FieldSetInputType.Text,
                    value: camera.name,
                    onChange: handleChangeCameraField('name'),
                  } }
                  isError={ isCameraNameEditInError() }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.add_cam_name_help })}
                  />
                }
                { (isSuperAdminUser && props.isServer) &&
                    <>
                      <FieldSet
                        key={ 'addressMain' }
                        label={ props.intl.formatMessage({ id: LOCALIZATION.camera_address_pi }) }
                        input={ {
                          type: FieldSetInputType.Text,
                          value: camera.addressMain,
                          onChange: handleChangeCameraField('addressMain'),
                        } }
                        isError={ isCameraIpAddressEditInError() }
                        tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.add_cam_ip_address })}
                      />
                      {/* <FieldSet
                        label={ props.intl.formatMessage({ id: LOCALIZATION.camera_address_j1 }) }
                        input={ {
                          type: FieldSetInputType.Text,
                          value: camera.addressJ1,
                          onChange: handleChangeCameraField('addressJ1'),
                        } }
                        onErrorTooltipText={ props.intl.formatMessage({ id: LOCALIZATION.value_incorrect }) }
                      />
                      <FieldSet
                        label={ props.intl.formatMessage({ id: LOCALIZATION.camera_address_j2 }) }
                        input={ {
                          type: FieldSetInputType.Text,
                          value: camera.addressJ2,
                          onChange: handleChangeCameraField('addressJ2'),
                        } }
                        onErrorTooltipText={ props.intl.formatMessage({ id: LOCALIZATION.value_incorrect }) }
                      /> */}
                    </>
                }
                {(isSuperAdminUser) &&
                  <FieldSet
                    key={ 'Camera Type' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.camera_type }) }
                    // helperText={ 'Short team name' }
                    tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.camera_type_hint }) }
                    input={ {
                      type: FieldSetInputType.Select,
                      value: getCameraModeType(),
                      options: CAMERA_MODELS_ARR.map((gameTypeId) => ( {id: gameTypeId, name: gameTypeId } )),
                      onChange: handleChangeCameraModelField,
                    } }
                  />
                }
                <FieldSet
                  key={ 'Default Sport Type' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.event_default_sport_type }) }
                  // helperText={ 'Short team name' }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_default_sport_type_hint }) }
                  input={ {
                    type: FieldSetInputType.Select,
                    value: getCameraDefaultSportType(),
                    options: SPORT_ID_ARR.map((sportId) => ( {id: sportId, name: localizedSportNameById(sportId) } )),
                    onChange: handleChangeCameraDefaultSportType,
                  } }
                />
                {
                  (isAddingNewCamera) &&
                    <>
                      <FieldSet
                        label={ props.intl.formatMessage({ id: LOCALIZATION.add_cam_login }) }
                        input={ {
                          type: FieldSetInputType.Text,
                          value: saLogin,
                          onChange: (value) => ( setAaLogin(value) ),
                        } }
                        isError={ isAttachUserLoginEditInError() }
                        tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.add_cam_login_pass_help })}
                        hideSeparator={ true }
                      />
                      <FieldSet
                        label={ props.intl.formatMessage({ id: LOCALIZATION.password }) }
                        input={ {
                          type: FieldSetInputType.Password,
                          value: saPassword,
                          onChange: (value) => ( setSaPassword(value) ),
                        } }
                        isError={ isAttachUserPasswordEditInError() }
                        tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.add_cam_login_pass_help })}
                      />
                    </>
                }
                {
                  (isSuperAdminUser && props.isServer) &&
                    <FieldSet
                      label={ `${props.intl.formatMessage({ id: LOCALIZATION.camera_technical_info })} (${ props.intl.formatMessage({ id: LOCALIZATION.optional }) })`  }
                      input={ {
                        type: FieldSetInputType.Text,
                        rows: 4,
                        value: camera.teqInfo,
                        onChange: handleChangeCameraField('teqInfo'),
                      } }
                      onErrorTooltipText={ props.intl.formatMessage({ id: LOCALIZATION.value_incorrect }) }
                    />
                }
                <FieldSet
                  label={ `${props.intl.formatMessage({ id: LOCALIZATION.camera_notes })} (${ props.intl.formatMessage({ id: LOCALIZATION.optional }) })` }
                  input={ {
                    type: FieldSetInputType.Text,
                    rows: 4,
                    value: camera.notes,
                    onChange: handleChangeCameraField('notes'),
                  } }
                  onErrorTooltipText={ props.intl.formatMessage({ id: LOCALIZATION.value_incorrect }) }
                />
              </form>
          }
        </Card>
      </Grid>
    </Popup>
  );
};


export default injectIntl(CameraPopup);
