// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Popup from '../../Components/_Layout/Popup';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import LOCALIZATION from '../../Localization';
import AnalyticsGameArchive from '../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import useMutationGame, { ActionType } from '../../Data/Analytics/AnalyticsGameArchive/fetch/useMutation';
import Card from '../../Components/_Layout/Card';
import { Fonts } from '../../Configuration/Styles/Fonts';
import { Sizes } from '../../Configuration/Styles/Sizes';
import { Colors } from '../../Configuration/Styles/Colors';
import { makeStyles } from '@mui/styles';
import VertGrid from '../../Components/_Layout/VertGrid';
import { Box } from '@mui/material';

type Props = Readonly<{
  game?: AnalyticsGameArchive;
  // page: number;
  onSubmit: (game: AnalyticsGameArchive | undefined) => void;
  onClose: () => void;
} & WrappedComponentProps>;

const useElementsStyles = makeStyles(() => ({
  teamImageSelectTitle: {
    fontFamily: Fonts.main,
    fontSize: Sizes.heading,
    fontWeight: Sizes.regularWeight,
    color: Colors.mainTitle,
    paddingBottom: "20pt",
  },
}));

const AnalyticsGameStartAnalyticsPopup: React.FC<Props> = (props: Props) => {    
  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);
  const elementsClasses =   useElementsStyles();
  
  const { mutateAsync: mutateGamesConfig } = useMutationGame();

  const isApplyDisabled = !(props.game && props.game.id?.length);
  const handleSaveGame = async () => {
    if (!isApplyDisabled) {
      setIsLoaderShowing(true);

      try {
        await mutateGamesConfig(
          {
            type: ActionType.PostStartAnalytics,
            archive: {
              id: props.game.id,
            }
          }, {
            onSuccess: () => {
              setIsLoaderShowing(false);
              props.onSubmit(props.game);
            },
          },
        );
      } catch (error) {
        setIsLoaderShowing(false);
        alert(error);
      }
    }
  };

  return (
    <Popup
      headerTitle={ props.game?.title || props.intl.formatMessage({ id: LOCALIZATION.analytics_ready_analysis })  }
      applyTitle={ props.intl.formatMessage({ id: LOCALIZATION.analytics_game_state_not_started }) }
      isApplyDisabled={ (isApplyDisabled || isLoaderShowing) }
      isCloseDisabled={ isLoaderShowing }
      onApply={ handleSaveGame }
      onClose={ props.onClose }
    >
      <Card>
        {
          (isLoaderShowing) ?
          <>
          <br /><br /><Spinner /><br /><br />
          </>
          : 
          <VertGrid>
            <Box className={elementsClasses.teamImageSelectTitle} >
            { props.intl.formatMessage({ id: LOCALIZATION.analytics_start_analysis_help }) }
            </Box>

          </VertGrid>
        }
      </Card>
    </Popup>
  );
};


export default injectIntl(AnalyticsGameStartAnalyticsPopup);
