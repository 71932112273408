// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid, SxProps, Theme } from '@mui/material';

import useStyles from './useStyles';
import { Colors } from '../../../Configuration/Styles/Colors';


export enum LabelType {
  Red = 'red',
  Green = 'green',
  Yellow = 'yellow',
  Olive = 'olive',
  DarkGreen = 'darkGreen',
  Grey = 'grey',
}

type Props = Readonly<{
  labelType?: LabelType;
  title: string;
  stateText?: string;
  isReversed?: boolean;
  sx?: SxProps<Theme>;
}>;


const COLOR: { [key in LabelType]: string } = {
  [LabelType.Red]: Colors.cicrcleRed,
  [LabelType.Green]: Colors.cicrcleGreen,
  [LabelType.Yellow]: Colors.cicrcleYellow,
  [LabelType.Olive]: Colors.cicrcleOlive,
  [LabelType.DarkGreen]: Colors.cicrcleDarkGreen,
  [LabelType.Grey]: Colors.lightGrey,
};

const CircledLabel: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    isReversed = false,
    labelType = LabelType.Green,
  } = props;
  const text = [props.title, props.stateText].filter(text => !!text).join(': ')
  const items = [
    <Grid item key="icon">
      <div className={ classes.iconContainer }>
        <div className={ classes.icon } style={ { background: COLOR[labelType] } }/>
      </div>
    </Grid>,
    <Grid item key="text">
      <div className={ classes.regularText }>{ text }</div>
    </Grid>,
  ]

  if (isReversed) {
    items.reverse();
  }

  return (
    <Grid
      container
      sx={ {
        flexGrow: 1,
        ...props.sx
      } }
      spacing={ 1 }
      direction="row"
      wrap="nowrap"
    >
      { items.map(item => item) }
    </Grid>
  )
};


export default CircledLabel;
