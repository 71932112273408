// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import CameraStreamPreview from '../../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview'
import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import CircledLabel  from '../../../../Components/_BaseUI/CircledLabel/CircledLabel'
import MainButton from '../../../../Components/_BaseUI/MainButton'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content';
import LOCALIZATION from '../../../../Localization';
import { FileType } from '../../../../Data/VideoArchive';
import { getLabelFromState } from '../../../CamerasPage/CameraCardBlock/CameraPreviewInfo/helpers';
import { steamingStatesInfo } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsStreamingState';
import { useCameraData } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks';
import VertGrid from '../../../../Components/_Layout/VertGrid';
import { getMenuLink } from '../../../../Menu/Data/MenuStructure';
import { MenuID } from '../../../../Menu/Data/MenuElement';
import { useNavigate } from 'react-router-dom';
import AuthorisationManager from '../../../../Data/Auth/AuthorisationManager';
import { isUserRoleMinimum, UserRole } from '../../../../Data/AccountUsers/UserWithoutID';

const PanoramaCard: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const cameraSettings = useCameraData();
  const user = AuthorisationManager.shared.user
  const isNotProxyMode = (cameraSettings?.settings?.activeOptions?.isProxyMode !== "true")

  const [storeStateString, storeState] = steamingStatesInfo(cameraSettings?.settings?.streamingState?.panoramaOutputs, 'mp4File', true)
  
  const navigate = useNavigate();

  const handleConfigure = () => {
    const menuLink = getMenuLink(MenuID.Streaming);
    if (menuLink) {
      navigate(menuLink);
    }
  }

  return (
    <MainCard>
      <RegularCardHeader title={ props.intl.formatMessage({ id: LOCALIZATION.panorama }) }/>
      <MainCardContent>
        <VertGrid>
          <CameraStreamPreview
            previewImageUrl={ cameraSettings?.settings?.urls?.livePanoramaImage }
            videoUrl={ (isNotProxyMode) ? cameraSettings?.settings?.urls?.livePanoramaVideoPlayer : undefined }
            fileType={ FileType.Panorama }
          />
          <VertGrid
            sx={ {
              marginTop: '15px',
            } }
          >
            {(isNotProxyMode) &&
              <CircledLabel
                  labelType={ getLabelFromState(storeState) }
                  title={ props.intl.formatMessage({ id: LOCALIZATION.recording_to_storage }) }
                  stateText={ storeStateString }
                />
            }
            { (isUserRoleMinimum(user?.role, UserRole.Admin)) &&
              <Grid container spacing={ 1 } 
                direction="row"
                justifyContent="flex-end" 
                sx={ {
                  marginTop: '15px',
                } }
              >
                <Grid item>
                  <MainButton onClicked={ handleConfigure } title={ props.intl.formatMessage({ id: LOCALIZATION.configure }) } />
                </Grid>
              </Grid>
            }
          </VertGrid>
        </VertGrid>
      </MainCardContent>
    </MainCard>
  );
};


export default injectIntl(PanoramaCard);
