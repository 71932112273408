// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Build } from '@mui/icons-material';

import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import LOCALIZATION from '../../../Localization';
import { getCameraSettingsCached, useCameraSettingsFetch } from '../../../Data/Camera/HWCameraSettings/fetch';
import { CameraOperationMode } from '../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature';

type Props = Readonly<{}>;


const SX = {
  wrapper: {
    verticalAlign: 'middle',
    marginRight: '15px',
  },
  text: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontFamily: Fonts.main,
    fontSize: Sizes.small,
    fontWeight: Sizes.mediumWeight
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '15px',
    marginRight: '10px',
    '& path': {
      fill: '#5DCA8A',
    },
  },
};

const MaintenanceModeStatus: React.FC<Props> = () => {
  const intl = useIntl();
  // const camera = useCameraData(); // not use here because we need some place to use react-query fetch to make dev-Tools work. also we dont need to refresh this state if we do not update settings for other purpouse
  useCameraSettingsFetch()
  const cameraSettings = getCameraSettingsCached();

  const isMaintenanceModeOn = (cameraSettings?.mode === CameraOperationMode.Maintenance)

  if (!isMaintenanceModeOn) {
    return null;
  }

  return (
    <Grid
      sx={ SX.wrapper }
    >
      <Build
        sx={ SX.icon }
      />
      <Grid
        sx={ SX.text }
      >
        <Box sx={{ display: { xs: 'none', md: 'block' }}}>
        { intl.formatMessage({ id: LOCALIZATION.maintenance_mode }) }
        </Box>
      </Grid>
    </Grid>
  );
};


export default MaintenanceModeStatus;
