// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

import en from '../en';
import ru from '../ru';
import AnyChildren from '../../Tools/AnyChildren';
import { AppLocale, getLocalStorageLocale, useAppConfig } from '../AppContextProvider/helpers';


type Props = Readonly<{
  children: AnyChildren;
}>;

type Localization = {
  [key: string]: string;
};


const TRANSLATIONS: { [key in AppLocale]: Localization } = {
  en,
  ru,
};

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache()

export const intl = (locale?: AppLocale) => {
  const lang = locale || getLocalStorageLocale();

  return (
    createIntl({
      locale: lang,
      messages: TRANSLATIONS[lang],
    }, cache)
  );
};


const LocalizationProvider: React.FC<Props> = (props: Props) => {
  const { localization: { locale } } = useAppConfig();

  return (
    <RawIntlProvider
      value={ intl(locale) }
    >
      { props.children }
    </RawIntlProvider>
  );
};


export default LocalizationProvider;
