// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import { Grid, Box } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import ErrorMessage from '../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage'
import GameListCard from './Cards/GameListCard'
import GameVideoCard from './Cards/VideoPlayerCard'
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import AnalyticsVideoPageHeader from '../../Components/_Layout/AnalyticsVideoPageHeader';
import { useParams } from 'react-router-dom';
import usePagedData from '../../Data/Analytics/AnalyticsVideoArchive/hook';
import { getResponseManyDataOne } from '../../Data/_Networking/ReactQuery/ResponseMany';
import AnalyticsVideoArchive from '../../Data/Analytics/AnalyticsVideoArchive/AnalyticsVideoArchive';
import { VideoPlayerCommands } from '../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import EditTitleSubtitlePopup, { EditTitleSubtitleFields } from '../../Components/_PopupControls/EditTitleSubtitlePopup';
import LOCALIZATION from '../../Localization';
import { AnalyticsType } from '../../Data/Analytics/AnalyticsTypes';
import useMutationArchive, { ActionType } from '../../Data/Analytics/AnalyticsVideoArchive/fetch/useMutation';

const AnalyticsVideoArchiveDetails: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  let { AnalyticsVideoArchiveDetails: recordId } = useParams();
  
  const [renameItem, setRenameItem] = React.useState<AnalyticsVideoArchive | undefined>(undefined);

  const [videoPlayerCommands, setVideoPlayerCommands] = React.useState<VideoPlayerCommands | undefined>(undefined);
  const { status: fetchStatus, data: gameRecordsData, handleFlushData } = usePagedData({ itemId: recordId });
  const gameResultInfo: AnalyticsVideoArchive | undefined = getResponseManyDataOne(gameRecordsData?.data)
  const { mutateAsync: mutateArchiveConfig } = useMutationArchive();
  
  if (fetchStatus === 'loading') {
    return (
      <>
      <br /><br /><Spinner /><br /><br />
      </>
    )
  }

  const handleArchiveRename = async (editedItem: EditTitleSubtitleFields) => {
    try {
      await mutateArchiveConfig(
        {
          archives:[{
            id: editedItem.id || "",
            archive: { 
              id: editedItem.id,
              type: renameItem?.type || AnalyticsType.VideoRecord,
              title: editedItem.title,
              subtitle: editedItem.subtitle,
             },
          }],
          type: ActionType.Patch,
        }, {
          onSuccess: () => {
            handleFlushData();
            handleCloseRenamePopup();
          },
        },
      );
    } catch (error) {
      alert(error);
      handleFlushData();
      handleCloseRenamePopup();
    }
  };

  const handleCloseRenamePopup = () => {
    setRenameItem(undefined);
  };

  return (
    <Box> 
      <Grid 
      container
      spacing={ 2 }
      >
        <Grid item xs={ 12 }>
          <AnalyticsVideoPageHeader
            onEditSecondLineClick={() => {
              setRenameItem(gameResultInfo);
            }}
          />
          <ErrorMessage/>
        </Grid>
        <Grid item xs={ 12 }>
          <Grid container spacing={ 2 } direction="row">
            <Grid item xs={ 12 }>
              <GameVideoCard 
                videoRecordInfo={gameResultInfo}
                onPlayerCommandsUpdate={(commands: VideoPlayerCommands | undefined)=> {
                  setVideoPlayerCommands(commands)
                }}
              />
            </Grid>

            <Grid item xs={ 12 }>
              <GameListCard 
                videoRecordInfo={gameResultInfo}
                videoPlayerCommands={ videoPlayerCommands }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {
        (renameItem) &&
          <EditTitleSubtitlePopup
            popupTitle={ props.intl.formatMessage({ id: LOCALIZATION.rename }) }
            item={ renameItem }
            onSubmit={ handleArchiveRename }
            onClose={ handleCloseRenamePopup }
          />
      }
    </Box>
  )
};


export default injectIntl(AnalyticsVideoArchiveDetails);
