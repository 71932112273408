// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';

import { Colors } from '../../../Configuration/Styles/Colors';
import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { useRecentError } from './ErrorMessageHook';


type Props = Readonly<{
  error?: Error;
  local?: boolean;
}>;


const useStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    padding: 20,
    color: Colors.accentBlue,
    fontFamily: Fonts.main,
    fontSize: Sizes.subtitle,
    fontWeight: Sizes.mediumWeight,
  },
  none: {
    display: 'none',
  }
}));

// Local means ignore other errors handled by ErrorMessageHelper
const ErrorMessage: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  let error = useRecentError();

  if (props.local) {
    error = props.error;
  }

  const className: string = error ? classes.root : classes.none;

  return (
    <div
      className={ className }
    >
      <div
        className={ classes.main }
      >
        { error?.message }
      </div>
    </div>
  )
};

export default ErrorMessage;
