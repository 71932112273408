// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { Key } from 'react';
import moment from 'moment';
import { WrappedComponentProps } from 'react-intl';

import Archive from '../../Data/Analytics/AnalyticsVideoArchive/AnalyticsVideoArchive';

import { Box, ButtonBase, Grid, alpha } from '@mui/material';
import { useAppConfig } from '../../Localization/AppContextProvider/helpers';
import CameraStreamPreview from '../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import { intl } from '../../Localization/LocalizationProvider';
import LOCALIZATION from '../../Localization';

import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import { getMenuLink } from '../../Menu/Data/MenuStructure';
import { MenuID } from '../../Menu/Data/MenuElement';

import dayjs from 'dayjs';
import { Fonts } from '../../Configuration/Styles/Fonts';
import { Sizes } from '../../Configuration/Styles/Sizes';
import { Colors } from '../../Configuration/Styles/Colors';
import MenuDropdownButton from '../../Components/_BaseUI/MenuDropdownButton';
import { FileType } from '../../Data/VideoArchive';
import { AnalyticsUploadStatus, analyticsUploadStatusName } from '../../Data/Analytics/AnalyticsStatuses';
import { AnalyticsConfig, makeAnalyticsVideoImageUrl } from '../../Data/Analytics/AnalyticsConfig/AnalyticsConfig';
import VertGrid from '../../Components/_Layout/VertGrid';

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
require('dayjs/locale/ru')

export type Props = Readonly<{
  id: Key;
  hideDate: boolean;
  item: Archive;
  analyticsConfig: AnalyticsConfig | undefined;
  onRename: (itemId: string, item?: Archive) => void;
  onDelete: (itemId: string, item?: Archive) => void;
} & WrappedComponentProps>;


const VideoArchiveItem: React.FC<Props> = (props: Props) => {
  const dateStart = moment(props.item.start);
  const dateEnd = moment(props.item.end);
  const { localization: { locale } } = useAppConfig();

  dateStart.locale(locale);
  dateEnd.locale(locale);
  dayjs.locale(locale);
  const handleRename = () => {
    props.onRename(props.item.id, props.item);
  };
  const handleDelete = () => {
    props.onDelete(props.item.id, props.item);
  };
  const imageUrl = () => (
    makeAnalyticsVideoImageUrl(props.analyticsConfig, props.item.preview)
  )

  // const videoUrl = () => (
  //   makeAnalyticsVideoPlayerUrl(props.analyticsConfig, props.item.video)
  // )

  // const downloadVideoSaveFileName: string = (() => {
  //   const start = moment(props.item.start);
  //   let name = `${ start.format('YYYY-MM-DD_hh-mm') }_${ props.item.title }`
  //   let name2 = name.replace(/[^\p{L}0-9.-]/giu, '_')
  //   return name2
  // })()

  // const downloadVideoUrl = () => (
  //   `${ MAIN_BACKEND_HOST_URL }${ cameraSettings?.urls?.zipArchiveFileTemplate?.replace("{archive_file_path}", props.item.video || "") }?zip_file_name=${ downloadVideoSaveFileName+'.zip' }&target_folder=${ downloadVideoSaveFileName }`
  // )

  const startAt = dayjs(props.item.start);
  const endAt = dayjs(props.item.end);

  const videoDuration: string = (() => {
    const diffMinutes = endAt.diff(startAt, 'minutes');
    return `${diffMinutes} ${intl().formatMessage({ id: LOCALIZATION.minutes })}`
  })()

  const itemState: string = (() => {
    if (!!props.item?.games_count) {
      return props.intl.formatMessage({ id: LOCALIZATION.analytics_games_added_number }, { count: props.item?.games_count });
    }
    switch (props.item?.upload_status) {
      case AnalyticsUploadStatus.Done: {
        return props.intl.formatMessage({ id: LOCALIZATION.analytics_uploaded }) + ": ";
      }
      case AnalyticsUploadStatus.Cancelled: {
        return props.intl.formatMessage({ id: LOCALIZATION.analytics_uploading_stoped }) + ": ";
      }
      case AnalyticsUploadStatus.Progress: {
        return "";
      }
    }
    return props.intl.formatMessage({ id: LOCALIZATION.unknown })
  })()

  const itemStateValue: string = (() => {
    if (!!props.item?.games_count) {
      return "";
    }
    return analyticsUploadStatusName(props.item?.upload_status, props.item?.upload_progress)
  })()

  const itemStateColor: string = (() => {
    switch (props.item?.upload_status) {
      case AnalyticsUploadStatus.Done: {
        return Colors.textBlue
      }
      case AnalyticsUploadStatus.Cancelled: {
        return Colors.mainRed
      }
      case AnalyticsUploadStatus.Progress: {
        return Colors.mainGreen
      }
      case AnalyticsUploadStatus.NotStarted: {
        return Colors.mainRed
      }
    }
    return Colors.mainRed
  })()

  const navigate = useNavigate();

  const handleItemClickBtn = () => {
    const menuLink = getMenuLink(MenuID.AnalyticsVideoArchiveDetails, [props.item.id]);
    if (menuLink) {
      navigate(menuLink);
    }
  };

  return (
    <Grid item key={ props.id } {...{ xs: 12, sm: 6, md: 6, lg: 4 }}>
      <ButtonBase 
        component="div" 
        onClick={ handleItemClickBtn }
        sx={{
          '&:hover': {
            backgroundColor: alpha(Colors.menuDefaultHover,0.15),
            border: 'none',
          },
        }}
      >
        <VertGrid>
          <Box
            sx={ {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            } }
          >
            <MenuDropdownButton<Archive>
              item={ props.item as Archive }
              menuButtonIcon= { MenuIcon }

              customActions={[{
                title: props.intl.formatMessage({ id: LOCALIZATION.rename }),
                icon: DriveFileRenameOutlineIcon,
                onMenuClick: handleRename
              }]}

              onDelete={ handleDelete }
            />
            <Box
              sx={ {
                fontFamily: Fonts.main,
                fontSize: Sizes.title,
                fontWeight: Sizes.boldWeight,
                color: Colors.headingMain,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              } }
            >
              { props.item?.title }
            </Box>
          </Box>
          <Box
            sx={ {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            } }
          >
            <CameraStreamPreview
                          fileType={ FileType.Panorama }
                          previewImageUrl={ imageUrl() }
                          // video={ videoUrl() }
                        />
          </Box>
          <Box
            sx={ {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: 1,
              fontFamily: Fonts.main,
              fontSize: Sizes.title,
              fontWeight: Sizes.regularWeight,
              color: Colors.black,
            } }
          >
            { ((props.hideDate) ? "" : `${ dateStart.format('dd l').toUpperCase() }, `) + `${ dateStart.format('LT') }, ${ videoDuration }` }
          </Box>
          { 
          (!!props.item.subtitle?.length) &&
            <Box
              sx={ {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: '1pt',
                fontFamily: Fonts.main,
                fontSize: Sizes.title,
                fontWeight: Sizes.regularWeight,
                color: Colors.black,
              } }
            >
              { (props.item.subtitle === 'Unknown') ? props.intl.formatMessage({ id: LOCALIZATION.unknown }) : props.item.subtitle }
            </Box>
          }

          <Box
            sx={ {
              fontFamily: Fonts.main,
              fontSize: Sizes.title,
              fontWeight: Sizes.boldWeight,
              paddingTop: '1pt',
          } }
          >
            <Box
              component="span"
              key={ 1 }
              sx={{
                color: Colors.black,
              }}
            >
              { itemState }
            </Box>
            <Box
              component="span"
              key={ 3 }
              sx={{
                color: itemStateColor,
              }}
            >
              { itemStateValue }
            </Box>
          </Box>
        </VertGrid>
      </ButtonBase>
    </Grid>
  );
};


export default VideoArchiveItem;
