// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react'
import InputField, { InputFieldType } from '../../../../Components/_BaseUI/InputField/InputField'
import InputAnyForm from '../../../../Components/_Layout/InputAnyForm/View'

export default function InputTextForm(props: {
    defaultValue?: string,
    isCorrect?: boolean,
    name: string,
    inputFieldType: InputFieldType,
    onTextChanged: (text: string) => void}) {
    return (
        <InputAnyForm name={props.name} form={(
            <InputField inputType = { props.inputFieldType }
                defaultValue = { props.defaultValue }
                isCorrect = {props.isCorrect}
                isDebounced = {true}
                onTextChanged = { props.onTextChanged }/>
        )}/>
    )
}
