// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Alert } from '@mui/lab';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import LOCALIZATION from '../../Localization';


type Props = Readonly<{} & WrappedComponentProps>;


const FetchErrorAlert: React.FC<Props> = (props: Props) => (
  <Alert
    severity="error"
  >
    { props.intl.formatMessage({ id: LOCALIZATION.failed_load_data }) }
  </Alert>
);


export default injectIntl(FetchErrorAlert);
