// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import User from '../User';
import Order from '../../_Networking/ReactQuery/Order';
import {
  useFetch,
  flushCache,
  getCached,
} from '../fetch';


type Props = Readonly<{
  page: number;
  limit: number;
  order: Order;
  orderBy: keyof User;
  filterByCameraID?: string;
  filterByTeamRefID?: string;
}>;


const usePagedData = (props: Props) => {
  const fetchedEvents = useFetch({
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    enableToFetch: true,
    filterByCameraID: props.filterByCameraID,
    filterByTeamRefID: props.filterByTeamRefID,
  });
  const data = getCached({
    page: props.page,
    limit: props.limit,
    order: props.order,
    orderBy: props.orderBy,
    filterByCameraID: props.filterByCameraID,
    filterByTeamRefID: props.filterByTeamRefID,
  });

  return ({
    status: fetchedEvents.status,
    data,
    handleFlushData: flushCache,
  });
};


export default usePagedData;
