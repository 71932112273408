// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useMutation } from 'react-query';
import { isArray, isEqual } from 'lodash';

import { flushCache } from '.';
import User from '../User';
import { deleteUser, addUser, patchUser } from './index';
import UserWithoutID from '../UserWithoutID';


export enum ActionType {
  Add = 'add',
  Patch = 'patch',
  Delete = 'delete',
}

export type Action = Readonly<{
  user?: User | UserWithoutID;
  originalUser?: User;
  type: ActionType;
}>;


const useMutationUser = () => {
  return useMutation(
    (action: Action) => {
      switch (action.type) {
        case ActionType.Add: {
          return addUser(action.user as UserWithoutID);
        }
        case ActionType.Patch: {
          if (action.user) {
            const user = action.user as User;
            const originalUser = action.originalUser as User;

            const changed: Partial<User> = (Object.keys(user) as (keyof typeof user)[]).reduce((diff, key) => {
              const value = user[key];
              const origianlValue = originalUser[key];
              if (isArray(value)) {
                console.log({ user, key, originalUser }, 'isEqual:', isEqual(user.cameraIdList?.sort(), originalUser.cameraIdList?.sort()));
                if (isArray(origianlValue) && isEqual(value.sort(), origianlValue.sort())) {
                  return diff;
                } else {
                  return {
                    ...diff,
                    [key]: value,
                  }
                }
              }
              if (value === originalUser[key]) return diff;
              return {
                ...diff,
                [key]: value,
              }
            }, {});

            return patchUser(originalUser, changed);
          }
          return patchUser(action.originalUser as User, action.user as Partial<User> | undefined);
        }
        case ActionType.Delete: {
          return deleteUser(action.user as User);
        }
        default:
          return Promise.reject(new Error('Invalid Account Mutation Action'));
      }
    },
    {
      onSuccess: flushCache,
    },
  );
};


export default useMutationUser;
