// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { SportType } from '../../EPG/EpgSchedule/EventWithoutID';
import RemoteCameraState from '../RemoteCameraState';

export enum CameraType {
  Local = 'local',
  Server = 'server',
  Remote = 'remote',
}

export enum CameraModelType { // stored in Camera.extraJson as CameraExtraJson
  Cybercamera = 'Cybercamera',
  Cybercamera_2_0 = 'Cybercamera v2.0',
  Cybercamera_2_1 = 'Cybercamera v2.1',
  Cybercamera_3_0 = 'Cybercamera v3.0',
  Proxy = 'Proxy',
}
export const CAMERA_MODELS_ARR = Object.values(CameraModelType)

export type CameraExtraJson = {
  cameraModel?: CameraModelType;
  defaultSportType?: SportType;
};

type Camera = Readonly<{
  id: string;
  cameraId: string;
  name: string;
  addressMain: string;
  addressJ1: string;
  addressJ2: string;
  type: CameraType;
  teqInfo: string;
  notes: string;
  extraJson?: string;
  state: RemoteCameraState;
  localCamera: boolean;
}>;

export type NewCamera = Readonly<{
  cameraId: string;
  name: string;
  addressMain: string;
  addressJ1: string;
  addressJ2: string;
  teqInfo: string;
  notes: string;
  extraJson?: string;
}>;


export default Camera;
