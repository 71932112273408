// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import SliderWrapper from './SliderWrapper';
import BoxComponent from '../../../Box';
import Audio from '../../../../../Data/NapiCameraConfig/Audio';
import FieldSet, { FieldSetInputType } from '../../../../../Components/_BaseUI/FieldSet';
import { Checkbox, Grid } from '@mui/material';
import FieldSetWrapper from '../FieldSetWrapper';
import LOCALIZATION from '../../../../../Localization';


type Props = Readonly<{
  audio?: Audio;
  onChange: (audio: Audio) => void;
} & WrappedComponentProps>;

type AudioControl = Readonly<{
  title: string;
  min: number;
  max: number;
  step: number;
  default: number;
}>;


const AudioForm: React.FC<Props> = (props: Props) => {
  const AUDIO_CONTROL: AudioControl[] = [
    {
      title: props.intl.formatMessage({ id: LOCALIZATION.stream_audio_control_rms_peak }),
      min: 0.0,
      max: 1.0,
      step: 0.1,
      default: 1,
    },
    {
      title: props.intl.formatMessage({ id: LOCALIZATION.stream_audio_control_attack_time }),
      min: 1.5,
      max: 40.0,
      step: 0.1,
      default: 1.5,
    },
    {
      title: props.intl.formatMessage({ id: LOCALIZATION.stream_audio_control_release_time }),
      min: 2.0,
      max: 800.0,
      step: 1,
      default: 300,
    },
    {
      title: props.intl.formatMessage({ id: LOCALIZATION.stream_audio_control_threshold_level }),
      min: -30.0,
      max: 0.0,
      step: 0.1,
      default: 20,
    },
    {
      title: props.intl.formatMessage({ id: LOCALIZATION.stream_audio_control_ratio }),
      min: 1.0,
      max: 20.0,
      step: 0.1,
      default: 3,
    },
    {
      title: props.intl.formatMessage({ id: LOCALIZATION.stream_audio_control_knee_radius }),
      min: 1.0,
      max: 10.0,
      step: 0.1,
      default: 1,
    },
    {
      title: props.intl.formatMessage({ id: LOCALIZATION.stream_audio_control_knee_makeup_gain }),
      min: 0.0,
      max: 24.0,
      step: 0.1,
      default: 10,
    },
  ];
  const handleChangeBitRate = (value: number | null) => {
    const bitrate = (value !== null && !isNaN(value)) ? value : 96;

    props.onChange({
      ...props.audio,
      bitrate: Math.ceil(bitrate * 1000),//96 to kbit 96000
    });
  }
  /*
      const channelsLimiterValue = getMinMaxValue(0, 2);
      const handleChangeChannels = (channelsNewValue: string) => {
          channelsLimiterValue.current = parseInt(channelsNewValue, 10);
          const channels = channelsLimiterValue.current as 0 | 1| 2;

          if (channels === 0) {
              const audio = { ...props.audio };
              delete audio.channels;

              props.onChange({
                  ...audio,
              });
          } else {
              const newAudioValue = {
                  ...props.audio,
                  channels,
              };
              props.onChange(newAudioValue);
          }
      };
  */
  const handleAudioDisabled = (event: any, isEnabled: boolean) => {
    props.onChange({
      ...props.audio,
      disable: !isEnabled,
    });
  };
  const handleCompressorDisabled = (event: any, isEnabled: boolean) => {
    props.onChange({
      ...props.audio,
      compressor: {
        ...props.audio?.compressor,
        enable: isEnabled,
      },
    });
  };
  const handleChangeSampleRate = (sampleRateValue: string) => {
    try {
      props.onChange({
        ...props.audio,
        'sample-rate': (parseInt(sampleRateValue, 10) === 44100) ? 44100 : 48000,
      });
    } catch (ignore) {
      props.onChange({
        ...props.audio,
        'sample-rate': 44100,
      });
    }
  };
  const setVolume = (volumeValue: number) => {
    props.onChange({
      ...props.audio,
      volume: (volumeValue / 100.0),
    });
  };

  const volumeValue = (props.audio?.volume) ? parseInt((props.audio.volume * 100) + '', 10) : 100;

  const setCompressorControl = (value: number, index: number) => {
    
    const control = (props.audio?.compressor?.control) ? [...props.audio.compressor.control] : AUDIO_CONTROL.map((audio) => audio.default );

    control[index] = value;

    props.onChange({
      ...props.audio,
      compressor: {
        ...props.audio?.compressor,
        control,
      },
    });
  };

  const renderAudioCompressorControl = (item: AudioControl, index: number) => {
    const handleValueChanged = (value: number) => {
      setCompressorControl(value, index);
    };

    return (
      <SliderWrapper
        key={ item.title }
        label={ `${ item.title }:` }
        value={ props.audio?.compressor?.control?.[index] || 0 }
        min={ item.min }
        max={ item.max }
        step={ item.step }
        onChange={ handleValueChanged }
      />
    );
  };

  return (
    <>
      <BoxComponent
        title={ props.intl.formatMessage({ id: LOCALIZATION.stream_audio_title }) }
      >
        <Grid
          container
          spacing={ 4 }
        >
          <FieldSetWrapper>
            <FieldSet
              key={ 'Enable' }
              label={ props.intl.formatMessage({ id: LOCALIZATION.stream_audio_enable }) }
              input={ {
                type: FieldSetInputType.Other,
                children: (
                  <Checkbox
                    sx = {{ marginLeft : -1 }}
                    defaultChecked={ !props.audio?.disable }
                    value={ !props.audio?.disable }
                    color="primary"
                    onChange={ handleAudioDisabled }
                  />
                ),
              } }
            />
          </FieldSetWrapper>

          {(props.audio?.disable) ||
            <>
              <FieldSetWrapper>
                <FieldSet
                  label={ props.intl.formatMessage({ id: LOCALIZATION.stream_audio_bitrate }) }
                  input={ {
                    type: FieldSetInputType.Float,
                    value: (props.audio?.bitrate) ? props.audio.bitrate/1000 : 96, // in kbit
                    onChange: handleChangeBitRate,
                  } }
                />
              </FieldSetWrapper>
              <FieldSetWrapper>
                <FieldSet
                  key={ 'sample_rate' }
                  label={ `${ props.intl.formatMessage({ id: LOCALIZATION.stream_audio_sample_rate }) }:` }
                  input={ {
                    type: FieldSetInputType.Select,
                    value: (props.audio?.['sample-rate'] || 44100).toString(),
                    options: [
                      {
                        id: '44100',
                        name: '44100',
                      },
                      {
                        id: '48000',
                        name: '48000',
                      },
                    ],
                    onChange: handleChangeSampleRate,
                  } }
                />
              </FieldSetWrapper>
              <SliderWrapper
                label={ `${ props.intl.formatMessage({ id: LOCALIZATION.stream_audio_volume }) }:` }
                value={ volumeValue }
                min={ 0 }
                max={ 100 }
                step={ 1 }
                onChange={ setVolume }
              />
            </>
          }
        </Grid>
      </BoxComponent>
      
     {(!props.audio?.disable) &&
        <BoxComponent
          title={ props.intl.formatMessage({ id: LOCALIZATION.stream_audio_control_title }) }
        >
          <Grid
            container
            spacing={ 4 }
          >
            <FieldSetWrapper>
              <FieldSet
                key={ 'Enable' }
                label={ props.intl.formatMessage({ id: LOCALIZATION.stream_audio_enable }) }
                input={ {
                  type: FieldSetInputType.Other,
                  children: (
                    <Checkbox
                      sx = {{ marginLeft : -1 }}
                      defaultChecked={ props.audio?.compressor?.enable }
                      value={ !props.audio?.compressor?.enable }
                      color="primary"
                      onChange={ handleCompressorDisabled }
                    />
                  ),
                } }
              />
            </FieldSetWrapper>
            { (props.audio?.compressor?.enable) && AUDIO_CONTROL.map(renderAudioCompressorControl) }
          </Grid>
        </BoxComponent>
      }
    </>
  );
};


export default injectIntl(AudioForm);
