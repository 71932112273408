// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useState } from 'react'
import { Grid } from '@mui/material';

import GeneralStatisticsChartView from './GeneralStatisticsChartView'
import RegularCardHeader from '../../../../Components/_Layout/CardHeader/Regular'
import { useStatusStatisticsTextValue } from './Hooks'
import { MetricsChartSourceInterface } from '../../../../Data/Camera/Metrics/Types'
import { sourceWithMetricsChartConfiguration } from '../../../../Components/_ChartsAndBars/Components/Configurator/Hooks'
import { MetricsChartConfigurationHelper } from '../../../../Components/_ChartsAndBars/Components/Configurator/Storage'
import { StatusGeneralStatisticCardDefaultMetricsSource } from '../../../../Data/Camera/Metrics/Sources/Collection'
import MainCard from '../../../../Components/_Layout/Cards/Main/Card'
import MainCardContent from '../../../../Components/_Layout/Cards/Main/Content'
import { MetricsDataHelper } from '../../../../Data/Camera/Metrics/Helper'
import { useIntl } from 'react-intl';
import LOCALIZATION from '../../../../Localization';


type CardHeaderProps = Readonly<{
  source: MetricsChartSourceInterface
}>;


const CardHeader: React.FC<CardHeaderProps> = (props: CardHeaderProps) => {
  const intl = useIntl();
  const statusString = useStatusStatisticsTextValue(props.source)
  const title = statusString ?? intl.formatMessage({ id: LOCALIZATION.statistics })

  return <RegularCardHeader title={ title }/>
};

const GeneralStatisticsCard: React.FC = () => {
  const defaultSource = MetricsDataHelper.filterAvailableSourceMetrics(StatusGeneralStatisticCardDefaultMetricsSource)

  // Получим source, примененный к графику
  const [source, setSource] = useState(sourceWithMetricsChartConfiguration(defaultSource, MetricsChartConfigurationHelper.getInitialConfigurableForm(defaultSource)))
  const handleSourceChanged = (source: MetricsChartSourceInterface) => {
    setSource(source);
  }
  return (
    <MainCard>
      <CardHeader source={ source } />
      <MainCardContent>
        <Grid
          sx={ {
            height: 218,
          } }
        >
          <GeneralStatisticsChartView defaultSource={ defaultSource } onSourceChanged={ handleSourceChanged } />
        </Grid>
      </MainCardContent>
    </MainCard>
  )
};


export default GeneralStatisticsCard;
