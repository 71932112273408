// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

export function toParams(query: string | undefined, hash: string | undefined): { [k: string]: string } {
  const parsedQuery = query?.replace(/^[?#]/, '');
  const queryKeyValue = Object.fromEntries(new URLSearchParams(parsedQuery));
  const parsedHash = hash?.replace(/^[?#]/, '');
  const hashKeyValue = Object.fromEntries(new URLSearchParams(parsedHash));
  const ret = {
    ...queryKeyValue,
    ...hashKeyValue
  }
  // console.log(`query=${parsedQuery}  hash=${parsedHash} ret=${JSON.stringify(ret, undefined , 2)}`)
  return ret;
}

// convert object keys,value to url querry with defined delimiter
export function toQuery(params: { [k: string]: string }, delimiter = '&'): string {
  const keys = Object.keys(params);
  return keys.reduce((str: string, key: string, index: number, array): string => {
    if (typeof params[key] === 'undefined' || params[key] === null) {
      return "";
    }
    let query = `${str}${key}=${params[key]}`;
    if (index < (keys.length - 1)) {
      query += delimiter;
    }
    return query;
  }, '');
}
