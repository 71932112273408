import React, { useContext } from 'react';
// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { injectIntl, WrappedComponentProps } from 'react-intl';

import ListOfCameras from './ListOfCameras';
import Camera from '../../Data/Camera/ActiveCamera/Camera';
import ActiveCamera from './ActiveCamera';
import LOCALIZATION from '../../Localization';
import Tabs, { TabItemInterface } from '../../Components/_Layout/Tabs';
import { getCachedActiveCamera, isNoActiveCamera, changeActiveCamera } from '../../Data/Camera/ActiveCamera/fetch';
import { useFetchActiveCamera } from '../../Data/Camera/ActiveCamera/hook';
import CameraContext from '../../Data/Camera/HWCameraSettings/fetch/Context';
import { removeAllQueryCache } from '../../AppProviders';
import PageContainer from '../../Components/_Layout/PageContainer';
import ViewSwitcher from '../../Components/_BaseUI/ViewSwitcher';
import { TableViewContext } from './TableView.context';
import { Grid } from '@mui/material';

const CameraCardServer: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const [activeCamera, setActiveCamera] = React.useState<Camera | undefined>(undefined);
  const TABS: TabItemInterface[] = [
    {
      id: 0,
      title: props.intl.formatMessage({ id: LOCALIZATION.active_camera }),
      Content: ActiveCamera,
    },
    {
      id: 1,
      title: props.intl.formatMessage({ id: LOCALIZATION.cameras }),
      Content: ListOfCameras,
    },
  ];
  const [selectedTabID, setSelectedTabID] = React.useState<number>(TABS[0].id);
  const fetchActiveCamera = useFetchActiveCamera();
  const isActiveCameraLoaded = !fetchActiveCamera.isLoading;
  const activeCameraData = getCachedActiveCamera();
  const { currentView, setCurrentView } = useContext(TableViewContext);
  const handleSelectActiveCamera = async (camera: Camera) => {
    const isActiveCameraChanged = await changeActiveCamera(camera.cameraId);

    if (isActiveCameraChanged) {
      // clearActiveCamera();
      removeAllQueryCache();
      CameraContext.reset();

      // select active camera tab
      setSelectedTabID(TABS[0].id);
    }
  };

  React.useEffect(() => {
    if (isActiveCameraLoaded) {
      setActiveCamera((!isActiveCameraLoaded || isNoActiveCamera(activeCameraData)) ? undefined : activeCameraData);
    }
  }, [isActiveCameraLoaded, activeCameraData]);
  if (fetchActiveCamera.isLoading) {
    return <></>
  }
  if (activeCamera !== undefined) {
    const Content = TABS[selectedTabID].Content;
    const properties = (selectedTabID === 0) ?
      {
        isServer: true,
        camera: activeCamera,
      }
      :
      {
        isServer: true,
        activeCamera: activeCamera,
        onSetActiveCamera: handleSelectActiveCamera,
      };

    return (
      <PageContainer>
        <Grid
          container
          style={{ margin: '0px 0px 0 20px', display: "flex", width: "100%" }}
          justifyContent={"space-between"}
        >
          <Grid item>

          <Tabs
            items={ TABS }
            selectedTabID={ selectedTabID }
            onChangeTab={ setSelectedTabID }
            />
          </Grid>
          <Grid item sm={'auto'}>
            {selectedTabID === 1 && <ViewSwitcher initialView={currentView} onChange={setCurrentView} style={{ marginLeft: "auto" }}></ViewSwitcher>}
          </Grid>

        </Grid>
        <Content
          { ...properties }
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ListOfCameras
        isServer
        activeCamera={ undefined }
        onSetActiveCamera={ handleSelectActiveCamera }
      />
    </PageContainer>
  );
};

export default injectIntl(CameraCardServer);
