// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Grid, Theme } from '@mui/material';

import AnyChildren from '../../../../Tools/AnyChildren';


type Props = Readonly<{
  children: AnyChildren;
}>;


const SX = (theme: Theme) => ({
  [theme.breakpoints.up('xs')]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
});

const ModalCard: React.FC<Props> = (props: Props) => (
  <Grid
    sx={ SX }
  >
    { props.children }
  </Grid>
);


export default ModalCard;
