// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import BaseCommandExecutor from '../../_Networking/AxiosFetch/Executor'
import { PiRestApi } from '../../_Networking/AxiosFetch/RestApi'
import { MetricRequestParametersInterface, MetricDoubleInterface, MetricDouble3DInterface } from './Types'

interface MetricsApiServiceInterface {
    get1DMetric(requestParameters: MetricRequestParametersInterface): Promise<MetricDoubleInterface[]>
    get3DMetric(requestParameters: MetricRequestParametersInterface): Promise<MetricDouble3DInterface[]>
}

export default class MetricsApiService implements MetricsApiServiceInterface {
    get1DMetric(requestParameters: MetricRequestParametersInterface): Promise<MetricDoubleInterface[]> {
        return new Promise<MetricDoubleInterface[]>((resolve, reject) => {
            const command = PiRestApi.monoMetrics(requestParameters)
            const executor = new BaseCommandExecutor<MetricDoubleInterface[]>(command)
            executor.execute(data => resolve(data), error => reject(error))
        })
    }

    // For test cases
    // get1DMetricFailed(requestParameters: MetricRequestParametersInterface): Promise<MetricDoubleInterface[]> {
    //     return new Promise<MetricDoubleInterface[]>((resolve, reject) => {
    //         const command = PiRestApi.monoMetricsFailed(requestParameters)
    //         const executor = new BaseCommandExecutor<MetricDoubleInterface[]>(command)
    //         executor.execute(Data => resolve(Data), error => reject(error))
    //     })
    // }

    get3DMetric(requestParameters: MetricRequestParametersInterface): Promise<MetricDouble3DInterface[]> {
        return new Promise<MetricDouble3DInterface[]>((resolve, reject) => {
            const command = PiRestApi.stereoMetrics(requestParameters)
            const executor = new BaseCommandExecutor<MetricDouble3DInterface[]>(command)
            executor.execute(data => resolve(data), error => reject(error))
        })
    }
}
