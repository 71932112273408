// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { useQuery, UseQueryResult } from 'react-query';

import ResponseMany from '../../_Networking/ReactQuery/ResponseMany';
import StreamingProfile from '..';
import { getKey, fetchData } from '../fetch';


export const useFetchStreamingProfiles = (enableToFetch: boolean = true): UseQueryResult<ResponseMany<StreamingProfile[]>> => {
  const queryKey = getKey();

  return useQuery<ResponseMany<StreamingProfile[]>>({
    queryKey,
    queryFn: () => fetchData(),
    enabled: !!enableToFetch,
  });
};
